import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { BiSolidEdit, BiSolidXSquare } from 'react-icons/bi';
import { HiOutlineX } from 'react-icons/hi';
import { BasicContext } from 'src/context/BasicContext';

import BadgeCmp from '../BadgeCmp';

interface IDrawerProps {
  children: ReactNode;
  isOpen: boolean;
  title: string | JSX.Element[] | JSX.Element;
  description?: string | JSX.Element[] | JSX.Element;
  classDrawer?: string;
  headerClassName?: string;
  drawerHeight?: string;
  isEditable?: boolean;
  isEditClose?: boolean;
  ignoreClose?: boolean;
  isShowHeader?: boolean;
  CloseIconClassName?: string;
  badgeclassName?: string;
  badge?: string;
  handleClose: () => void;
  handleEditable?: () => void;
  setIgnoreClose?: Dispatch<SetStateAction<boolean>>;
}

const Drawer = ({
  children,
  isOpen,
  handleClose,
  title,
  description,
  isEditable,
  isEditClose,
  handleEditable,
  classDrawer,
  drawerHeight,
  headerClassName,
  CloseIconClassName,
  badgeclassName,
  ignoreClose = false,
  isShowHeader = true,
  badge,
}: IDrawerProps) => {
  const drawerRef: any = useRef(null);
  const {
    tooltipGlobalOpen,
    setTooltipGlobalOpen,
    setActionTooltipGlobalOpen,
  } = useContext(BasicContext);

  useEffect(() => {
    let timeOutId: any;
    let handleOutsideClick: any;
    let handleEscapePress: any;

    if (isOpen) {
      setTimeout(() => {
        handleOutsideClick = (event: any) => {
          if (
            isOpen &&
            drawerRef.current &&
            !drawerRef.current.contains(event.target)
          ) {
            handleClose();
          }
        };

        handleEscapePress = (event: any) => {
          if (isOpen && event.key === 'Escape') {
            handleClose();
          }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('keydown', handleEscapePress);
      }, 0);
    }

    return () => {
      clearTimeout(timeOutId);
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isOpen, handleClose, ignoreClose]);

  useEffect(() => {
    if (isOpen) {
      setTooltipGlobalOpen(false);
      setActionTooltipGlobalOpen(false);
    }
  }, [isOpen, tooltipGlobalOpen]);

  return (
    <main
      className={`fixed overflow-hidden  bg-primarySolid bg-opacity-25 inset-0 transform ease-in-out z-[7] ${
        isOpen
          ? ' transition-opacity opacity-100 duration-500 w-auto'
          : ' delay-500 opacity-0 translate-x-full'
      }`}
    >
      <section
        ref={drawerRef}
        className={`${classDrawer} drawer right-0 absolute bg-white w-auto h-svh shadow-xl delay-400 duration-500 ease-in-out transition-all transform${
          isOpen ? ' translate-x-0 ' : ' translate-x-full '
        }`}
      >
        <article
          className={`${classDrawer} w-full relative h-full overflow-y-auto`}
        >
          {isShowHeader ? (
            <div
              className={`py-4 mx-5 flex justify-between md:items-center items-start border-b border-borderPrimary ${headerClassName}`}
            >
              <header className="flex items-center font-bold text-black text-lg">
                <div className="flex items-start">
                  <div>
                    <div className="flex flex-wrap items-center">
                      <span className="md:mr-3.5 mr-1 md:text-base text-sm">
                        {title}
                      </span>
                      {isEditable && (
                        <span className="text-primary cursor-pointer md:mr-3.5 mr-1">
                          <BiSolidEdit size={20} onClick={handleEditable} />
                        </span>
                      )}
                      {badge && (
                        <BadgeCmp
                          style="modern"
                          type="success"
                          mainClassName={badgeclassName}
                        >
                          {badge}
                        </BadgeCmp>
                      )}
                    </div>
                    {description && (
                      <p className="text-xs font-normal text-grayLight600 mt-1">
                        {description}
                      </p>
                    )}
                  </div>
                </div>
                {/* {title}
                                {isEditable && (
                                    <span className="text-primary ms-3 cursor-pointer">
                                        <BiSolidEdit size={20} onClick={handleEditable} />
                                    </span>
                                )} */}
                {isEditClose && (
                  <span className="text-primary ms-3 cursor-pointer">
                    <BiSolidXSquare size={20} onClick={handleEditable} />
                  </span>
                )}
              </header>
              <div
                className={`rounded-md transition-all delay-100 cursor-pointer hover:bg-border300 ${CloseIconClassName}`}
                onClick={handleClose}
              >
                <HiOutlineX size={20} />
              </div>
            </div>
          ) : (
            ''
          )}
          <div className={`${drawerHeight} px-5 py-4`}>{children}</div>
        </article>
      </section>
    </main>
  );
};

Drawer.defaultProps = {
  isOpen: false,
  title: '',
  classDrawer: '',
  drawerHeight: '',
  isEditable: false,
};

export default Drawer;
