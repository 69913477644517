import React from 'react';

interface IProps {
  onClick?: (val?: any) => void;
  onMouseEnter?: (val?: any) => void;
  onMouseLeave?: (val?: any) => void;
  onDoubleClick?: (val?: any) => void;
  reference?: any;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  icon?: any;
  iconSide?: string;
  type?: 'submit' | 'button' | 'reset' | undefined;
}

const ButtonCmp = ({
  children,
  reference,
  onClick = undefined,
  onDoubleClick = undefined,
  onMouseEnter = undefined,
  onMouseLeave = undefined,
  className = '',
  disabled = false,
  loading = false,
  type = 'submit',
  icon = '',
  iconSide = 'left',
}: IProps) => (
  <button
    ref={reference}
    className={`btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm !outline-0 ${className} ${
      disabled ? 'cursor-default pointer-events-none opacity-60 ' : ''
    }`}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onDoubleClick={onDoubleClick}
    type={type}
  >
    {loading && (
      <span
        className={`loading loading-spinner lg:w-4 lg:h-4 h-3 w-3 ${
          loading ? 'pr-2' : 'pr-0'
        } text-xs`}
      ></span>
    )}
    {iconSide == 'left' && icon ? icon : ''}
    {children}
    {iconSide == 'right' && icon ? icon : ''}
  </button>
);

export default ButtonCmp;
