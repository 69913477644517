import {
  CalendarPlus02,
  Check,
  CheckSquareBroken,
  Plus,
  SearchMd,
  Trash01,
  UserPlus01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { createPortal } from 'react-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';
import InputText from 'src/components/InputText/InputText';
import TooltipCmp from 'src/components/TooltipCmp';
import { updateClaimDisputeTask } from 'src/services/ClaimAndDisputeService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

const claimDisputeTaskPriorityFilter = [
  { label: 'Low', value: 'low' },
  { label: 'Medium', value: 'medium' },
  { label: 'High', value: 'high' },
];

interface IProps {
  data: any;
  updateTaskToList: any;
  setIsDeleteTask: any;
  setIsDeleteID: any;
  userOptions: any;
  searchTerm: any;
  setSearchTerm: any;
  currentUser: any;
}

const DetailsAction = ({
  data,
  updateTaskToList,
  setIsDeleteTask,
  setIsDeleteID,
  userOptions,
  searchTerm,
  setSearchTerm,
  currentUser,
}: IProps) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isStatusDropdownVisible, setStatusDropdownVisible] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [taskName, setTaskName] = useState(data?.taskName || '');
  const [error, setError] = useState('');
  const [dropdownStyle, setDropdownStyle] = useState({});
  const [statusDropdownStyle, setStatusDropdownStyle] = useState({});

  const assignDropdownRef = useRef<any>(null);
  const statusDropdownRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const statusButtonRef = useRef<any>(null);
  const priorityRef = useRef<any>(null);

  const handleAssignUserClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleStatusClick = () => {
    setStatusDropdownVisible(!isStatusDropdownVisible);
  };

  const handleUpdateTask = (taskID: number, param: any) => {
    updateClaimDisputeTask(taskID, param)
      .then((response: any) => {
        if (response && response.updatedAction) {
          const responseData = response.updatedAction;

          if (responseData) {
            updateTaskToList(responseData);
          }
        } else {
          console.log('error');
        }
      })
      .catch(console.error);
  };

  const validateAndCloseInput = () => {
    setTaskName(taskName);
    setIsEditing(false);
  };

  const handleClickOutside = (event: any) => {
    if (
      assignDropdownRef.current &&
      !assignDropdownRef.current.contains(event.target)
    ) {
      setDropdownVisible(false);
    }

    if (
      statusDropdownRef.current &&
      !statusDropdownRef.current.contains(event.target)
    ) {
      setStatusDropdownVisible(false);
    }

    if (inputRef.current && !inputRef.current.contains(event.target)) {
      validateAndCloseInput();
    }
  };

  const handleDropdownClick = (event: any) => {
    event.stopPropagation();
  };

  const updateDropdownPosition = () => {
    if (assignDropdownRef.current && statusButtonRef.current) {
      const { top, left, width, height } =
        statusButtonRef.current.getBoundingClientRect();
      setDropdownStyle({
        position: 'absolute',
        top: top + height + window.scrollY,
        left: left + window.scrollX,
        width: width,
        zIndex: 9999,
      });
    }

    if (statusDropdownRef.current && priorityRef.current) {
      const { top, left, width, height } =
        priorityRef.current.getBoundingClientRect();
      setStatusDropdownStyle({
        position: 'absolute',
        top: top + height + window.scrollY,
        left: left + window.scrollX,
        width: width,
        zIndex: 9999,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', updateDropdownPosition, true);
    window.addEventListener('resize', updateDropdownPosition);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', updateDropdownPosition, true);
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, [isDropdownVisible, isStatusDropdownVisible]);

  useEffect(() => {
    updateDropdownPosition();
  }, [isDropdownVisible, isStatusDropdownVisible]);

  const handleUserClick = (taskID: number, userId: number) => {
    setDropdownVisible(false);
    handleUpdateTask(taskID, {
      assignedTo: userId,
      assignedBy: currentUser.id,
    });
  };

  const handleStatusTagClick = (taskID: number, status: any) => {
    setStatusDropdownVisible(false);
    handleUpdateTask(taskID, { urgency: status });
  };

  const handleDateChange = (taskID: number, date: any) => {
    console.log(date);
    handleUpdateTask(taskID, { dueDate: moment(date).format('YYYY-MM-DD') });
  };

  const handleTaskNameClick = () => {
    setIsEditing(true);
  };

  const handleTaskNameKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.value !== '') {
        handleUpdateTask(data?.id, { taskName });
        setIsEditing(false);
      } else {
        setError('Task name is required');
      }
    }
  };

  const filteredPriorities = claimDisputeTaskPriorityFilter.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCompleteTask = (taskID: number, isCompleted: boolean) => {
    handleUpdateTask(taskID, { isCompleted });
  };

  const renderDropdown = (content: JSX.Element, style: any) =>
    createPortal(
      <div style={style} ref={assignDropdownRef} onClick={handleDropdownClick}>
        {content}
      </div>,
      document.body
    );

  return (
    <>
      <td className="px-5 py-4 min-w-64 w-64 max-w-64">
        <div className="flex items-center gap-1.5">
          <div>
            <CheckBox
              classes="checkbox-fgSuccessPrimary checked:border-fgSuccessPrimary"
              onChangeFunc={() => {
                handleCompleteTask(data.id, true);
              }}
              disabled={
                data.isCompleted ||
                !data.assignedBy ||
                !data.assignedTo ||
                !data.urgency ||
                !data.dueDate
              }
              checked={data.isCompleted}
            />
          </div>
          {isEditing ? (
            <div ref={inputRef}>
              <InputText
                inputName="taskName"
                placeholder="Add task name"
                value={taskName}
                onChangeFunc={(e: any) => {
                  setTaskName(e.target.value);
                  setError('');
                }}
                onKeyPress={handleTaskNameKeyPress}
                className="!shadow-none border-0 placeholder:text-gray500 disabled:text-gray500 text-black/90 disabled:bg-gray50 pl-0"
                parentClassName="w-32"
                errorText={error}
                autoFocus={true}
              />
            </div>
          ) : (
            <p
              className={`truncate ${
                data.isCompleted
                  ? 'line-through'
                  : 'cursor-pointer h-9 flex items-center'
              }`}
              onClick={() => (data.isCompleted ? '' : handleTaskNameClick())}
            >
              {taskName}
            </p>
          )}
        </div>
      </td>
      <td className="px-5 py-4 max-w-64 min-w-[20%] w-[20%] relative">
        <div
          className={`flex items-center gap-3 ${
            data.isCompleted ? '' : 'cursor-pointer'
          }`}
          onClick={() => (data.isCompleted ? '' : handleAssignUserClick())}
          ref={statusButtonRef}
        >
          {data?.assignedTo && data?.assignedTo != '' ? (
            <>
              {data?.image ? (
                <img
                  src={data?.imageUrl + data?.image}
                  className={`rounded-full w-8 h-8 object-cover ${
                    data.isCompleted ? 'opacity-50' : ''
                  }`}
                  alt=""
                />
              ) : (
                <div
                  className={`w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none ${
                    data.isCompleted ? 'opacity-50' : ''
                  }`}
                >
                  {getShortName(`${data?.name}`)}
                </div>
              )}
              <TooltipCmp message={data?.name}>
                <p
                  className={`w-32 truncate text-grayLight900 font-medium ${
                    data.isCompleted ? 'line-through' : ''
                  }`}
                >
                  {data?.name}
                </p>
              </TooltipCmp>
            </>
          ) : (
            <>
              <UserPlus01 className="h-4 w-4" />
              <p className="w-32 truncate text-grayLight900 font-medium">
                Assign User
              </p>
            </>
          )}
          {isDropdownVisible &&
            renderDropdown(
              <div
                id="dropdownDelay"
                ref={assignDropdownRef}
                onClick={handleDropdownClick}
                className=" min-w-[262px] z-20 bg-white w-full border border-borderPrimary shadow-md my-1.5 rounded-[10px]"
              >
                <InputText
                  inputName="searchTeam"
                  placeholder="Search"
                  value={userSearchTerm}
                  className="pl-8 placeholder:text-secondaryTxtColor"
                  parentClassName="px-2 pt-[9px]"
                  icon={
                    <SearchMd className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-5 w-5" />
                  }
                  isClearable={true}
                  onChangeFunc={(e) => {
                    setUserSearchTerm(e.target.value);
                    // handleGetUserList(e.target.value);
                  }}
                />
                <ul
                  className="mt-1.5 mb-[9px]"
                  aria-labelledby="dropdownDelayButton"
                >
                  {userOptions.length > 0 ? (
                    userOptions.map((user: any) => (
                      <li
                        className={
                          'mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s] '
                        }
                        onClick={() => {
                          if (
                            !(data?.assignedTo && data?.name === user?.name)
                          ) {
                            handleUserClick(data?.id, user?.id);
                          }
                        }}
                        key={user?.id}
                      >
                        <div className="flex justify-between items-center align-middle">
                          <div className="flex justify-center items-center">
                            {user?.image ? (
                              <img
                                src={user?.imageUrl + user?.image}
                                className="rounded-full w-5 h-5 mr-2 object-cover"
                                alt=""
                              />
                            ) : (
                              <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none  mr-2">
                                {getShortName(`${user.name}`)}
                              </div>
                            )}

                            <span className="font-semibold mr-2">
                              {user.name}
                            </span>
                          </div>
                          {data?.assignedTo && data?.name === user?.name && (
                            <Check className="w-4 h-4 ml-2 text-primary" />
                          )}
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="mx-2 p-2 text-xs rounded-md text-gray-500">
                      No users found
                    </li>
                  )}
                </ul>
              </div>,
              dropdownStyle
            )}
        </div>
      </td>
      <td className="px-5 py-4 text-xs max-w-64 min-w-[20%] w-[20%] relative">
        <div
          className={`flex items-center gap-1.5 ${
            data.isCompleted ? 'opacity-50' : 'cursor-pointer'
          }`}
          onClick={() => (data.isCompleted ? '' : handleStatusClick())}
          ref={priorityRef}
        >
          {data?.urgency && data?.urgency != '' ? (
            <BadgeCmp
              style="modern"
              type={`${
                data?.urgency === 'high'
                  ? 'error'
                  : data?.urgency === 'medium'
                  ? 'warning'
                  : 'pink'
              }`}
              mainClassName="capitalize"
            >
              {data?.urgency}
            </BadgeCmp>
          ) : (
            <>
              <Plus className="h-4 w-4" />
              <p className="w-32 truncate text-grayLight900 font-medium">Add</p>
            </>
          )}
          {isStatusDropdownVisible &&
            renderDropdown(
              <div
                id="dropdownDelay"
                ref={statusDropdownRef}
                onClick={handleDropdownClick}
                className=" min-w-[262px] z-20 bg-white w-full border border-borderPrimary shadow-md my-1.5 rounded-[10px]"
              >
                <InputText
                  inputName="searchTeam"
                  placeholder="Search"
                  value={searchTerm}
                  className="pl-8 placeholder:text-secondaryTxtColor"
                  parentClassName="px-2 pt-[9px]"
                  icon={
                    <SearchMd className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-5 w-5" />
                  }
                  isClearable={true}
                  onChangeFunc={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
                <ul
                  className="mt-1.5 mb-[9px]"
                  aria-labelledby="dropdownDelayButton"
                >
                  {filteredPriorities && filteredPriorities.length > 0 ? (
                    filteredPriorities.map((item: any, index: number) => (
                      <li
                        className={
                          'mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s]'
                        }
                        onClick={() =>
                          handleStatusTagClick(data?.id, item?.value)
                        }
                        key={index}
                      >
                        <div className="flex justify-between items-center align-middle">
                          <div className="flex justify-center items-center">
                            <span className="font-semibold mr-2">
                              {item.label}
                            </span>
                          </div>

                          {data?.urgency && data?.urgency === item?.value && (
                            <Check className="w-4 h-4 ml-2 text-primary" />
                          )}
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="mx-2 p-2 text-xs rounded-md text-gray-500">
                      No results found
                    </li>
                  )}
                </ul>
              </div>,
              statusDropdownStyle
            )}
        </div>
      </td>
      <td className="px-5 py-4  min-w-44 max-w-44 relative">
        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          minDate={moment().add(1, 'day').toDate()}
          selected={data.dueDate ? moment(data.dueDate).toDate() : null}
          customInput={
            data.dueDate ? (
              <span
                className={` ${
                  data.isCompleted ? 'line-through' : 'cursor-pointer'
                }`}
                dangerouslySetInnerHTML={{
                  __html: data.dueDate
                    ? getDateWithSuffixFormat(
                        getFormattedDate(
                          data.dueDate,
                          `MMM Do - YYYY`,
                          false,
                          false
                        )
                      )
                    : '-',
                }}
              />
            ) : (
              <CalendarPlus02 className="h-5 w-5" />
            )
          }
          onChange={(date) => handleDateChange(data?.id, date)}
          disabled={data.isCompleted}
          popperProps={{ strategy: 'fixed' }}
        />
      </td>
      <td className="px-5 py-4 max-w-32 w-32 min-w-32">
        {data.isCompleted ? (
          <div className="flex justify-center gap-2">
            <CheckSquareBroken className="w-4 h-4 text-fgSuccessPrimary" />
          </div>
        ) : (
          <div className="flex justify-center gap-2">
            <Trash01
              className="w-4 h-4 cursor-pointer"
              onClick={() => {
                setIsDeleteTask(true);
                setIsDeleteID(data.id);
              }}
            />
          </div>
        )}
      </td>
    </>
  );
};

export default DetailsAction;
