import React from 'react';

interface IProps {
  topContent?: any;
  leftContent?: any;
  title: any;
  desc?: string;
  rightSideContent?: any;
  labelClassName?: string;
  labelTextColor?: string;
  mainContainerClassName?: string;
  countBadge?: any;
  rightClassName?: string;
  leftClassName?: string;
}

const Header = ({
  topContent,
  leftContent,
  title,
  desc,
  rightSideContent,
  mainContainerClassName,
  countBadge,
  rightClassName = '',
  leftClassName,
}: IProps) => (
  <>
    {/* <ul className="mb-4 flex flex-wrap items-center gap-x-2 pb-1">
            <li className="p-1 text-borderPrimary">
                <a href="#">
                    <Home01 className="w-4 h-4" />
                </a>
            </li>
            <li className="text-grayLight600 text-sm font-medium before:[''] before:w-5 before:h-5 before:bg-[url('../../assets/img/chevron-right.svg')] before:inline-block before:align-middle before:bg-contain before:mr-2 flex items-center">
                <a href="#" className="px-2">
                    Customer
                </a>
            </li>
            <li className="text-grayLight600 text-sm font-medium before:[''] before:w-5 before:h-5 before:bg-[url('../../assets/img/chevron-right.svg')] before:inline-block before:align-middle before:bg-contain before:mr-2 flex items-center">
                <a href="#" className="px-2">
                    Customer Detail
                </a>
            </li>
        </ul> */}
    {topContent}

    <div
      className={`flex flex-wrap lg:items-center lg:flex-row md:flex-col flex-row items-start justify-between sm:gap-3 gap-2 border-b border-borderPrimary pb-4
					${mainContainerClassName}
				`}
    >
      <div className={`md:w-auto w-full ${leftClassName}`}>
        <div className="flex items-center md:gap-2 gap-1 flex-wrap w-full">
          <h1 className="text-gray-900 text-[22px] font-semibold leading-6">
            {title}
          </h1>
          {leftContent}
          {countBadge}
        </div>
        {desc && (
          <p className="text-grayLight600 text-sm font-normal leading-normal mt-1">
            {desc}
          </p>
        )}
      </div>
      {rightSideContent && (
        <div
          className={`flex flex-wrap sm:flex-row flex-col md:justify-end lg:gap-4 gap-3 flex-none md:w-auto w-full ${rightClassName}`}
        >
          {rightSideContent}
        </div>
      )}
    </div>
  </>
);

export default Header;
