import 'cropperjs/dist/cropper.css';

import { Crop02 } from '@untitled-ui/icons-react/build/cjs';
import React, { createRef, useEffect, useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';

import CommonModal from '../CommonModal';
import TabButton from '../TabButton';

export type ICropType = 'Rounded' | 'Square' | 'Rectangular';
export interface IPops {
  imageUpload: any;
  imageUrl: any;
  setUpload: any;
  setCropData: any;
  isCropType?: boolean;
  defaultCropType?: ICropType;
  isLocation?: boolean;
}

const CropOrganizationImage = ({
  imageUpload,
  setCropData,
  imageUrl,
  setUpload,
  modalTitle,
  modalDesc,
  defaultImage,
  defaultCropType = 'Rounded',
  isCropType = true,
  isSetToModalClose = true,
  primaryBtnLoading = false,
}: any) => {
  const cropTypeArray = [
    {
      value: 'Rounded',
      name: 'Rounded',
    },
    {
      value: 'Square',
      name: 'Square',
    },
    {
      value: 'Rectangular',
      name: 'Rectangular',
    },
  ];
  // const cropTypeArray: ICropType[] = ['Rounded', 'Square', 'Rectangular'];
  const [zoomLevel, setZoomLevel] = useState<any>(0.5);
  const [cropType, setCropType] = useState<ICropType>(defaultCropType);
  const cropperRef = createRef<ReactCropperElement>();
  const zoomSliderRef = useRef<HTMLInputElement>(null);
  const [naturalAspectRatio, setNaturalAspectRatio] = useState<any>(null);

  const getCropData = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (cropperRef.current?.cropper) {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas({
        rounded: true,
        imageSmoothingEnabled: true,
      });
      const imageData = croppedCanvas.toDataURL();

      if (croppedCanvas) {
        setCropData(imageData);

        if (isSetToModalClose) {
          setUpload(false);
        }

        if (imageUpload) {
          imageUpload(imageData as any);
        }
      } else {
        console.error('Failed to get cropped canvas.');
      }
    } else {
      console.error('Cropper instance not available.');
    }
  };

  useEffect(() => {
    if (
      cropperRef.current &&
      typeof cropperRef.current?.cropper.zoomTo === 'number'
    ) {
      cropperRef.current?.cropper.zoomTo(Number(zoomSliderRef.current!.value));
    }
  }, [zoomLevel]);

  useEffect(() => {
    console.log('object :>> ', isCropType);
  }, [isCropType]);

  const handleCropType = (type: ICropType) => {
    setCropType(type);
    cropperRef.current?.cropper.reset();

    if (type === 'Rectangular') {
      cropperRef.current?.cropper.setAspectRatio(naturalAspectRatio);
    } else {
      const aspectRatio: number = 1 / 1;
      cropperRef.current?.cropper.setAspectRatio(aspectRatio);
    }
  };

  const handleZoomChange = (e: any) => {
    const newZoomLevel = parseFloat(e.target.value);
    setZoomLevel(newZoomLevel);

    if (cropperRef.current) {
      cropperRef.current?.cropper.zoomTo(Number(newZoomLevel));
    }
  };

  const handleImageLoaded = (e: any) => {
    const naturalWidth = e.target.naturalWidth;
    const naturalHeight = e.target.naturalHeight;
    const aspectRatio = naturalWidth / naturalHeight;
    setNaturalAspectRatio(aspectRatio);
  };

  return (
    <>
      <CommonModal
        title={modalTitle}
        titleDesc={modalDesc}
        handleClose={() => {
          setUpload(false);
          setCropData(defaultImage);
        }}
        headerIcon={<Crop02 className="w-5 h-5" />}
        size={'max-w-[580px]'}
        primaryBtnText="Confirm"
        secondaryBtnText="Cancel"
        primaryBtnOnClick={getCropData}
        secondaryBtnOnClick={() => {
          setUpload(false);
          setCropData(defaultImage);
        }}
        primaryBtnLoading={primaryBtnLoading}
      >
        <div className="p-5">
          {
            <>
              {isCropType && (
                <>
                  <TabButton
                    tabArray={cropTypeArray}
                    tabParentClassName="w-full mb-3"
                    parentClassName="p-1 rounded-[10px] border border-gray100 bg-gray50 w-full whitespace-nowrap overflow-x-auto scrollbar-hide"
                    className="!border-0 !bg-gray50 rounded-md w-1/3"
                    activeClassName="!bg-white shadow-md"
                    isActive={cropType}
                    handleOnClick={(e: any) => {
                      handleCropType(e?.target?.dataset?.value);
                    }}
                  />
                </>
              )}
            </>
          }
          <div className="h-[320px] mb-6 overflow-hidden rounded-3xl app-logo-img-block bg-baseBg">
            <Cropper
              ready={handleImageLoaded}
              ref={cropperRef}
              dragMode="move"
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={false}
              checkOrientation={true}
              cropBoxMovable={false}
              cropBoxResizable={false}
              // autoCropArea={0.8}
              // autoCrop={true}
              aspectRatio={1 / 1}
              style={{ height: 320, width: '100%' }}
              src={imageUrl}
              disabled={true}
              highlight={false}
              guides={false}
              zoomTo={0.5}
              zoomOnTouch={false}
              zoomOnWheel={false}
              preview=".img-preview"
              className={`${cropType === 'Rounded' ? 'imageCropper' : ''}`}
            />
          </div>
          <div className="Crop-Controls w-full ">
            <div className="flex w-full items-center custom-range-slider">
              <input
                ref={zoomSliderRef}
                className="w-full"
                id="zoomSlider"
                type="range"
                min="0"
                max="3"
                step="0.01"
                value={zoomLevel}
                onChange={handleZoomChange}
              />
            </div>
            <div className="w-full flex justify-end">
              <h1 className="text-[14px] leading-[140%] -tracking-[0.18px] ">
                {Math.round((zoomLevel / 3) * 100)}%
              </h1>
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default CropOrganizationImage;
