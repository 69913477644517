import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const CustomersAllList = async () => {
  const response = await axiosInterceptor.get(API.CUSTOMER.LIST);

  return response;
};

export const CustomerList = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(API.CUSTOMER.GET, {
    params,
    signal,
  });

  return response;
};

export const updateCustomerDetail = async (customerId: string, data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CUSTOMER.UPDATE_CUSTOMER}/${customerId}`,
    data
  );

  return response;
};

export const GetTopCustomerList = async () => {
  const response = await axiosInterceptor.get(API.CUSTOMER.TOP_CUSTOMERS);

  return response;
};

export const CustomerKeyMetrics = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(API.CUSTOMER.ORDER_KEY_METRICS, {
    params,
    signal,
  });

  return response;
};

export const listCustomerSalesRepHistory = async (
  id: string | number,
  params: any,
  signal?: any
) => {
  const response = await axiosInterceptor.get(
    `${API.CUSTOMER.LIST_SALES_REP_HISTORY}/${id}`,
    { params, signal }
  );

  return response;
};

export const CustomerDetailById = async (params: any) => {
  const response = await axiosInterceptor.get(API.CUSTOMER.DETAIL, { params });

  return response;
};

export const CustomerSegment = async () => {
  const response = await axiosInterceptor.get(API.CUSTOMER.SEGMENT);

  return response;
};

export const updateCustomerImage = async (id: string | number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.CUSTOMER.UPDATE_IMAGE}/${id}`,
    data
  );

  return response;
};

export const setCurrentSalesRepHistory = async (id: any, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.CUSTOMER.SALES_REP}/${id}`,
    data
  );

  return response;
};

export const removeSalesRepHistory = async (id: any) => {
  const response = await axiosInterceptor.delete(
    `${API.CUSTOMER.SALES_REP}/${id}`
  );

  return response;
};

export const updateSalesRepHistory = async (id: any, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.CUSTOMER.UPDATE_SALES_REP}/${id}`,
    data
  );

  return response;
};

export const reassignCustomersSalesRep = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CUSTOMER.REASSIGN_SALES_REP}`,
    data
  );

  return response;
};

// customer contacts

export const customerContactsList = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(API.CUSTOMER.CONTACTS.LIST, {
    params,
    signal,
  });

  return response;
};

export const createCustomerContact = async (customerId: string, data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CUSTOMER.CONTACTS.GET}/${customerId}`,
    data
  );

  return response;
};

export const removeContact = async (customerContactId: any) => {
  const response = await axiosInterceptor.delete(
    `${API.CUSTOMER.CONTACTS.DELETE_CONTACT}/${customerContactId}`
  );

  return response;
};

// Customer Credit

export const customerCreditOrdersList = async (
  customerId: string,
  params: any,
  signal: any
) => {
  const response = await axiosInterceptor.get(
    `${API.CUSTOMER.CREDIT.CREDIT_ORDER_LIST}/${customerId}`,
    {
      params,
      signal,
    }
  );

  return response;
};

export const getCustomerCreditDetails = async (customerId: string) => {
  const response = await axiosInterceptor.get(
    `${API.CUSTOMER.CREDIT.CUSTOMER_CREDIT_DETAIL}/${customerId}`
  );

  return response;
};

// Customer Groups

export const CustomerGroupList = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(
    `${API.CUSTOMER.GROUP.CUSTOMER_GROUP_LIST}`,
    {
      params,
      signal,
    }
  );

  return response;
};

export const createCustomerGroup = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CUSTOMER.GROUP.CREATE_UPDATE_CUSTOMER_GROUP}`,
    data
  );

  return response;
};

export const removeCustomerGroup = async (customerGroupId: any) => {
  const response = await axiosInterceptor.delete(
    `${API.CUSTOMER.GROUP.DELETE_CUSTOMER_GROUP_LIST}/${customerGroupId}`
  );

  return response;
};

export const updateCustomerGroup = async (
  customerGroupId: string | number,
  data: any
) => {
  const response = await axiosInterceptor.patch(
    `${API.CUSTOMER.GROUP.CREATE_UPDATE_CUSTOMER_GROUP}/${customerGroupId}`,
    data
  );

  return response;
};
