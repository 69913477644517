import {
  ArrowLeft,
  ArrowRight,
  CalendarDate,
  ChevronDown,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import { useSelector } from 'react-redux';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';

import { IGetCustomerTargetDataParams } from './Target.interface';

const TargetSetFilterModal = ({
  params,
  handleClose,
  setFilterCount,
  setParams,
}: any) => {
  const [month, setMonth] = useState<any>(params?.targetMonth ?? new Date());
  const datePickerRef = useRef<HTMLDivElement>(null);

  const [dateRange, setDateRange] = useState<any>(
    params.targetMonth ?? new Date()
  );
  const [isOpenMonthPicker, setIsOpenDropDown] = useState<boolean>(false);

  useEffect(() => {
    setMonth(moment(dateRange, 'YYYY-MM').toDate());
  }, [dateRange]);

  const handleSave = () => {
    let fCount = 0;

    if (dateRange) {
      fCount += 1;
    }
    setFilterCount(fCount);
    setParams((old: IGetCustomerTargetDataParams) => ({
      ...old,
      targetMonth: dateRange,
    }));

    handleClose();
  };

  const getFilterRange = (type: string) => {
    if (type === 'prev') {
      const prevMonth = moment(dateRange, 'YYYY-MM').subtract(1, 'months');
      setDateRange(prevMonth.format('YYYY-MM'));
    }

    if (type === 'next') {
      const nextMonth = moment(dateRange, 'YYYY-MM')
        .add(1, 'months')
        .format('YYYY-MM');
      setDateRange(nextMonth);
    }
  };

  return (
    <CommonModal
      size={'smd:max-w-[490px] max-w-[336px] overflow-unset'}
      handleClose={handleClose}
      title="Filters"
      primaryBtnText="Save"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnOnClick={handleSave}
      titleDesc="Please change as per your requirement."
      isOverflow={false}
    >
      <div className="p-5">
        <div className="rounded-lg border border-utilityGray200 p-3 flex flex-col gap-3">
          <div className="smd:flex flex-wrap items-center flex-1">
            <label className={`form_label smd:mb-0 mb-1.5 w-24`}>
              Created Date
            </label>
            <div className="w-full xl:px-3.5 px-3 lg:py-[9px] py-2 rounded-lg shadow border border-gray-300 lg:h-[36px] h-[34px]  justify-between items-center gap-1.5 flex bg-white flex-1">
              <ArrowLeft
                className="xs:w-4 xs:h-4 w-3 h-3 cursor-pointer flex-none"
                onClick={() => getFilterRange('prev')}
              />

              <div
                className="flex flex-1 justify-center items-center"
                onClick={() => {
                  setIsOpenDropDown(true);
                }}
              >
                <CalendarDate className="w-4 h-4 mr-2" />
                <div className="text-textSecondary text-xs font-medium leading-none">
                  {' '}
                  {moment(dateRange, 'YYYY-MM').format('MMMM YYYY')}
                </div>
              </div>

              <ArrowRight
                className="xs:w-4 xs:h-4 w-3 h-3 cursor-pointer flex-none"
                onClick={() => getFilterRange('next')}
              />
            </div>
            {isOpenMonthPicker && (
              <div
                className="shadow-lg mt-2.5 mb-2 z-[2] rounded-lg border border-utilityGray200 absolute bg-white datepicker-v2"
                ref={datePickerRef}
              >
                <DateSelect
                  inputName="pickupDate"
                  selected={month}
                  showMonthYearPicker
                  dateFormat="MMMM-yyyy"
                  onChangeFunc={(e: any) => {
                    setMonth(moment(e.value, 'MMM YYYY').toDate());
                    setDateRange(moment(e.value, 'MMM YYYY').format('YYYY-MM'));
                    setIsOpenDropDown(false);
                  }}
                  inline={isOpenMonthPicker}
                  icon={<ChevronDown className="w-5 h-5" />}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </CommonModal>
  );
};
export default TargetSetFilterModal;
