import {
  ArrowDown,
  ArrowUp,
  SearchLg,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { CustomerKeyMetrics } from 'src/services/CustomerService';
import { orderList } from 'src/services/OrderService';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

import OverviewTabOrderLoadingRaw from './OverviewTabOrderLoadingRaw';
import OverviewTabOrderRaw from './OverviewTabOrderRaw';

const initParams: any = {
  sortType: 'desc',
  sortField: 'orderCreatedAt',
  page: 1,
  search: '',
  segment: '',
  limit: 25,
};

interface IProps {
  customerDetail: any;
  customerId: string | undefined;
}

const CustomerDetailOverviewTab = ({ customerId }: IProps) => {
  const [params, setParams] = useState(initParams);
  const [orders, setOrders] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [customerOrdersData, setCustomerOrdersData] = useState<any>([]);
  const [isLatestOrdersLoading, setIsLatestOrdersLoading] = useState(true);
  const [dayFilter, setDayFilter] = useState<any>(30);

  const headCells = useMemo(
    () => [
      {
        id: 'fullId',
        name: 'Order ID',
        width: 'w-[30%]',
        sortable: true,
      },
      {
        id: 'orderStatus',
        name: 'Order Status',
        width: 'w-[30%]',
        sortable: true,
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        width: 'w-[20%]',
        sortable: true,
      },
      {
        id: 'combinedCost',
        name: 'Total Cost',
        width: 'w-[10%]',
        sortable: true,
      },
      {
        id: 'combinedMargin',
        name: 'Margin',
        width: 'w-[10%]',
        sortable: true,
      },
      {
        id: 'combinedRevenue',
        name: 'Total Price',
        width: 'w-[10%]',
        sortable: true,
      },
      {
        id: 'orderCommissionees',
        name: 'Sales Representative',
        width: 'w-[10%]',
        sortable: true,
      },
    ],
    []
  );

  const recordsPerPageArray = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  useEffect(() => {
    const keyMetricsApiController = new AbortController();
    const keyMetricsApiSignal = keyMetricsApiController.signal;
    setCustomerOrdersData([]);
    setIsLoading(true);
    CustomerKeyMetrics(
      { customerId: customerId, timeFrame: dayFilter },
      keyMetricsApiSignal
    )
      .then((response: any) => {
        setCustomerOrdersData(response.data ?? []);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      keyMetricsApiController.abort();
    };
  }, [customerId, dayFilter]);

  useEffect(() => {
    const ordersApiController = new AbortController();
    const ordersApiSignal = ordersApiController.signal;
    setOrders([]);
    setIsLatestOrdersLoading(true);
    orderList({ ...params, customerId: customerId }, ordersApiSignal)
      .then((response: any) => {
        setOrders(response.data ?? []);
        setTotal(response.total ?? 0);
        setIsLatestOrdersLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLatestOrdersLoading(false);
      });

    return () => {
      ordersApiController.abort();
    };
  }, [params]);

  const handleSearch = (event: any) => {
    const { value } = event.target;
    setParams((old: any) => ({ ...old, ...{ search: value, page: 1 } }));
  };

  return (
    <>
      <div className="flex items-center justify-between mt-8 flex-wrap">
        <div>
          <h6 className="text-grayLight900 text-lg font-semibold sm:mb-0 mb-1">
            Key Metrics
          </h6>
        </div>

        <div className="p-1 bg-gray-50 rounded-[10px] overflow-auto text-nowrap border border-utilityGray200 cursor-pointer justify-start items-center gap-1 flex sm:w-auto w-full">
          <div
            className={`sm:basis-auto basis-full px-3 py-1.5 justify-center items-center gap-2 flex ${
              dayFilter === 30
                ? 'bg-white rounded-md font-semibold  shadow text-grayLight900'
                : 'text-gray400'
            }`}
            onClick={() => setDayFilter(30)}
          >
            <div className="text-xs leading-tight">30 Days</div>
          </div>
          <div
            className={`sm:basis-auto basis-full px-3 py-1.5 justify-center items-center gap-2 flex ${
              dayFilter === 90
                ? 'bg-white rounded-md font-semibold  shadow text-grayLight900'
                : 'text-gray400'
            }`}
            onClick={() => setDayFilter(90)}
          >
            <div className=" text-xs leading-tight">90 Days</div>
          </div>
          <div
            className={`sm:basis-auto basis-full px-3 py-1.5 rounded-md justify-center items-center gap-2 flex ${
              dayFilter === 180
                ? 'bg-white rounded-md font-semibold shadow text-grayLight900'
                : 'text-gray400'
            }`}
            onClick={() => setDayFilter(180)}
          >
            <div className="text-xs leading-tight">180 Days </div>
          </div>
          <div
            className={`sm:basis-auto basis-full px-3 py-1.5 rounded-md justify-center items-center gap-2 flex ${
              dayFilter === 360
                ? 'bg-white rounded-md shadow text-grayLight900'
                : 'text-gray400'
            }`}
            onClick={() => setDayFilter(360)}
          >
            <div className="text-xs leading-tight">360 Days </div>
          </div>
          <div
            className={`sm:basis-auto basis-full px-3 py-1.5 rounded-md justify-center items-center gap-2 flex ${
              dayFilter === 'all'
                ? 'bg-white rounded-md shadow text-grayLight900'
                : 'text-gray400'
            }`}
            onClick={() => setDayFilter('all')}
          >
            <div className="text-xs leading-tight">All Time </div>
          </div>
        </div>
      </div>
      <ul className="flex -m-2 flex-wrap mt-1.5">
        <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="w-full justify-between flex items-start">
              <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                Total Orders
              </h6>
            </div>
            <div className="w-full flex justify-between pt-2 ">
              <div className="w-full flex flex-col justify-end">
                <div className={` ${isLoading ? 'w-fit custom-loading' : ''}`}>
                  <span className="font-semibold text-2xl">
                    {customerOrdersData?.totalOrders > 0
                      ? customerOrdersData?.totalOrders
                      : 0}
                  </span>
                </div>

                <div className="w-full flex  justify-between ">
                  <div
                    className={`w-fit flex justify-center items-center mt-2 ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {customerOrdersData?.totalOrdersDiff >= 0 ? (
                      <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                    ) : (
                      <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                    )}

                    <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                      <span className="xxl:text-sm text-xs">
                        {customerOrdersData?.totalOrdersDiff
                          ? Math.abs(customerOrdersData?.totalOrdersDiff)
                          : 0}{' '}
                        %
                      </span>{' '}
                      &nbsp;vs. Last {dayFilter} Days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="w-full justify-between flex items-start">
              <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                Total Margin
              </h6>
            </div>
            <div className="w-full flex justify-between pt-2 ">
              <div className="w-full flex flex-col justify-end">
                <div className={` ${isLoading ? 'w-fit custom-loading' : ''}`}>
                  <span className="font-semibold text-2xl">
                    {customerOrdersData?.totalMargin > 0
                      ? getFormattedNumber(customerOrdersData?.totalMargin)
                      : 0}
                  </span>
                  <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
                    $
                  </sup>
                </div>
                <div className="w-full flex  justify-between ">
                  <div
                    className={`w-fit flex justify-center items-center mt-2 ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {customerOrdersData?.totalMarginDiff >= 0 ? (
                      <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                    ) : (
                      <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                    )}

                    <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                      <span className="xxl:text-sm text-xs">
                        {customerOrdersData?.totalMarginDiff
                          ? Math.abs(customerOrdersData?.totalMarginDiff)
                          : 0}{' '}
                        %
                      </span>{' '}
                      &nbsp;vs. Last {dayFilter} Days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="w-full justify-between flex items-start">
              <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                Margin Percentage
              </h6>
            </div>
            <div className="w-full flex justify-between pt-2 ">
              <div className="w-full flex flex-col justify-end">
                <div className={` ${isLoading ? 'w-fit custom-loading' : ''}`}>
                  <span className="font-semibold text-2xl">
                    {customerOrdersData?.avgMarginPercentage > 0
                      ? customerOrdersData?.avgMarginPercentage
                      : 0}
                  </span>
                  <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
                    %
                  </sup>
                </div>
                <div className="w-full flex  justify-between ">
                  <div
                    className={`w-fit flex justify-center items-center mt-2 ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {customerOrdersData?.avgMarginPercentageDiff >= 0 ? (
                      <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                    ) : (
                      <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                    )}

                    <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                      <span className="xxl:text-sm text-xs">
                        {customerOrdersData?.avgMarginPercentageDiff
                          ? Math.abs(
                              customerOrdersData?.avgMarginPercentageDiff
                            )
                          : 0}{' '}
                        %
                      </span>{' '}
                      &nbsp;vs. Last {dayFilter} Days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="w-full justify-between flex items-start">
              <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                Average Order Value
              </h6>
            </div>
            <div className="w-full flex justify-between pt-2 ">
              <div className="w-full flex flex-col justify-end">
                <div className={` ${isLoading ? 'w-fit custom-loading' : ''}`}>
                  <span className="font-semibold text-2xl">
                    {customerOrdersData?.avgOrderValue > 0
                      ? getFormattedNumber(customerOrdersData?.avgOrderValue)
                      : 0}
                  </span>
                  <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
                    $
                  </sup>
                </div>
                <div className="w-full flex  justify-between ">
                  <div
                    className={`w-fit flex justify-center items-center mt-2 ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {customerOrdersData?.avgOrderValueDiff >= 0 ? (
                      <>
                        <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                      </>
                    ) : (
                      <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                    )}

                    <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                      <span className="xxl:text-sm text-xs">
                        {customerOrdersData?.avgOrderValueDiff
                          ? Math.abs(customerOrdersData?.avgOrderValueDiff)
                          : 0}
                        %
                      </span>
                      &nbsp;vs. Last {dayFilter} Days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      {/* {customerDetail?.about && (
        <>
          <h6 className="text-grayLight900 text-lg font-semibold mb-1 mt-5">
            About
          </h6>
          <div className="flex flex-col gap-4">
            <p className="text-grayLight600 text-sm font-normal">
              {customerDetail?.about}
            </p>
          </div>
        </>
      )} */}

      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col mt-6 overflow-hidden">
        <div className="w-full flex-col flex">
          <div className="table-top-header flex-wrap ">
            <div className="table-left-wrap ">
              <div className="table-title-wrap">
                <h5 className="table-title">Latest Orders</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={`${
                    isLatestOrdersLoading ? 'custom-loading' : ''
                  }`}
                >
                  {total} {total === 1 || total === 0 ? 'order' : 'orders'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View complete order history for this customer.
              </p>
            </div>
            <div className="table-right-wrap max-mdm:w-full">
              <InputText
                inputName="searchCustomer"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={params.search}
                isClearable={true}
                parentClassName="table-searchInput max-mdm:flex-1"
                inputType="text"
                onChangeFunc={handleSearch}
              />
              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArray}
                  onChangeFunc={(event: any) => {
                    setParams((old: any) => ({
                      ...old,
                      limit: event.value,
                      page: 1,
                    }));
                  }}
                  isSearchable={false}
                  value={recordsPerPageArray.find(
                    (val: any) => val.value === params.limit
                  )}
                />
              </div>
            </div>
          </div>
          <div className="w-full bg-white overflow-x-auto custom-scrollbar scrollbar-hide text-nowrap">
            <TableCmp
              headCells={headCells}
              params={params}
              setParams={setParams}
              tableDataArr={orders}
              TableLoaderRowCmp={OverviewTabOrderLoadingRaw}
              TableRowCmp={OverviewTabOrderRaw}
              isTableDataLoading={isLatestOrdersLoading}
              numberOfSkeletonRows={15}
            />
          </div>
          {!isLatestOrdersLoading && !orders?.length && (
            <NotFoundUI
              title="No latest orders found"
              desc="There are no Latest orders found."
              containerClassName="min-h-[160px] !h-auto"
            />
          )}
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={total}
            currentPage={params.page}
            handlePagination={(page: number) => {
              setParams((old: any) => ({ ...old, page }));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerDetailOverviewTab;
