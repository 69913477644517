import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React from 'react';

import ErrorMsg from '../errorMsg';

const DynamicCKEditor = ({ content, onChange, errorText }: any) => (
  <>
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          '|',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote',
          '|',
          'insertTable',
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          '|',
          'undo',
          'redo',
        ],
      }}
      data={content}
      onChange={(event, editor) => {
        const data = editor.getData();

        if (onChange) {
          onChange(data);
        }

        return true;
      }}
    />
    <div className="[&:empty]:mt-0 mt-1.5">
      {errorText && <ErrorMsg errorText={errorText} />}
    </div>
  </>
);

export default DynamicCKEditor;
