import { Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import csv from 'src/assets/img/csv.png';
import doc from 'src/assets/img/doc.png';
import pdf from 'src/assets/img/pdf-icon.png';
import txt from 'src/assets/img/txt.png';
import xls from 'src/assets/img/xls.png';
import xlsx from 'src/assets/img/xlsx.png';
import { convertToGBMBKB, onError } from 'src/utils/CommonFunctions';

const StatementOnboarding = ({ data, setAction }: any) => {
  const validImageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

  const getExtention = () => {
    const filename = data?.fileOriginalName;
    const parts = filename?.split('.');
    if (parts) return parts[parts?.length - 1] ?? '';

    return '';
  };

  const getImgSrc = () => {
    const fileType = getExtention();

    if (validImageExtensions.includes(fileType)) {
      return data.fileUrl + data.fileName;
    } else if (fileType === 'pdf') {
      return pdf;
    } else if (fileType === 'xls') {
      return xls;
    } else if (fileType === 'xlsx') {
      return xlsx;
    } else if (fileType === 'csv') {
      return csv;
    } else if (fileType === 'txt') {
      return txt;
    } else {
      return doc;
    }
  };

  return (
    <>
      <td className="px-5 py-4 max-w-[1px]">
        <div className="flex items-center gap-3">
          <div className="w-8 h-10 flex-none">
            <img
              src={getImgSrc()}
              onError={onError}
              className="w-full h-full object-contain"
            />
          </div>
          <div className="truncate">
            <h6 className="text-grayLight900 font-medium truncate ">
              {data?.fileOriginalName}
            </h6>
            <p>{convertToGBMBKB(data.size)}</p>
          </div>
        </div>
      </td>
      <td className="px-5 py-4">
        <span>{data.year}</span>
      </td>
      <td className="px-5 py-4">
        <span>{`${data.quarter}`}</span>
      </td>
      <td className="px-5 py-4 w-8">
        <Trash01
          className="w-4 h-4 cursor-pointer"
          // onClick={() => removeRaw(data?.id, 'financeStatement')}
          onClick={() =>
            setAction((old: any) => ({
              ...old,
              ...{ mode: 'delete', id: data?.id },
            }))
          }
        />
      </td>
    </>
  );
};

export default StatementOnboarding;
