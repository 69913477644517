import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import { PATH } from 'src/constants/path';
import { listDispute } from 'src/services/DisputeService';

import CustomerDetailDisputesLoadingRaw from './CustomerDetailDisputesLoadingRaw';
import CustomerDetailDisputesRaw from './CustomerDetailDisputesRaw';

interface IProps {
  customerId: string;
}

const CustomerDetailDisputesTab = ({ customerId }: IProps) => {
  const navigate = useNavigate();

  const initParams: any = {
    search: '',
    sortType: 'desc',
    sortField: 'createdAt',
    page: 1,
    limit: 10,
    filter: {
      status: 'all',
      customerId: customerId,
    },
  };

  const [params, setParams] = useState(initParams);
  const [disputes, setDisputes] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [isCustomerDisputeLoading, setIsCustomerDisputeLoading] =
    useState(true);

  useEffect(() => {
    const disputesApiController = new AbortController();
    const disputesApiSignal = disputesApiController.signal;

    setDisputes([]);
    setIsCustomerDisputeLoading(true);
    listDispute(params, disputesApiSignal)
      .then((response: any) => {
        setDisputes(response.data ?? []);
        setTotal(response.total ?? 0);
        setIsCustomerDisputeLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerDisputeLoading(false);
      });

    return () => {
      disputesApiController.abort();
    };
  }, [params]);

  const handleSearch = (event: any) => {
    const { value } = event.target;
    setParams((old: any) => ({ ...old, ...{ search: value, page: 1 } }));
  };

  const headCells = useMemo(
    () => [
      {
        id: 'disputeId',
        name: 'Dispute ID',
        sortable: false,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'orderId',
        name: 'Order ID',
        sortable: false,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'status',
        name: 'Status',
        sortable: false,
        visible: true,
        rowClassName: 'text-xs ',
      },
      {
        id: 'createdAt',
        name: 'Dispute Created',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'carrier',
        name: 'Carrier',
        sortable: false,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'totalPrice',
        name: 'Total Price',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'disputeAmount',
        name: 'Dispute Amount',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'timer',
        name: 'Timer',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
    ],
    []
  );

  return (
    <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col mt-6 overflow-hidden">
      <div className="w-full flex-col flex">
        <div className="table-top-header flex-wrap ">
          <div className="table-left-wrap ">
            <div className="table-title-wrap">
              <h5 className="table-title">Customer Disputes</h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={`${
                  isCustomerDisputeLoading ? 'custom-loading' : ''
                }`}
              >
                {total} {total === 1 || total === 0 ? 'dispute' : 'disputes'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              View complete disputes history for this customer.
            </p>
          </div>
          <div className="table-right-wrap max-mdm:w-full">
            <InputText
              inputName="searchCustomer"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={params.search}
              isClearable={true}
              parentClassName="table-searchInput max-mdm:flex-1"
              inputType="text"
              onChangeFunc={handleSearch}
            />
          </div>
        </div>
        <div className="w-full bg-white overflow-x-auto custom-scrollbar scrollbar-hide text-nowrap">
          <TableCmp
            headCells={headCells}
            params={params}
            setParams={setParams}
            tableDataArr={disputes}
            TableLoaderRowCmp={CustomerDetailDisputesLoadingRaw}
            TableRowCmp={CustomerDetailDisputesRaw}
            isTableRowClickable={true}
            tableRowCmpProps={{
              onRowClick: (data: any) => {
                navigate(`${PATH.DISPUTE_DETAILS}/${data?.id}`);
              },
            }}
            isTableDataLoading={isCustomerDisputeLoading}
            numberOfSkeletonRows={15}
          />
        </div>
        {!isCustomerDisputeLoading && !disputes?.length && (
          <NotFoundUI
            title="No dispute found"
            desc="There are no dispute found."
            containerClassName="min-h-[160px] !h-auto"
          />
        )}
        <CustomPagination
          recordsPerPage={params.limit}
          totalRecords={total}
          currentPage={params.page}
          handlePagination={(page: number) => {
            setParams((old: any) => ({ ...old, page }));
          }}
        />
      </div>
    </div>
  );
};

export default CustomerDetailDisputesTab;
