import {
  AlertCircle,
  Copy04,
  Eye,
  Plus,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';

import ButtonCmp from '../../../../../components/ButtonCmp';
import InputText from '../../../../../components/InputText/InputText';
import SelectBox from '../../../../../components/SelectBox/SelectBox';
import { getFreightClass } from '../../../../../services/QuoteService';
import VisualizeUnit from '../../SmartQuote/VisualizeUnit';

const Items = (props: any) => {
  const [isShowVisualUnit, setIsShowVisualUnit] = useState(false);
  const {
    formData,
    validForm,
    initFormData,
    initValidForm,
    handlingUnitOptions,
    setFormData,
    setValidForm,
    handleActionType,
    lengthMeasurement,
    weightMeasurement,
  } = props;

  const addItems = () => {
    setFormData((old: any) => [...old, initFormData]);
    setValidForm((old: any) => [...old, initValidForm]);
  };

  const duplicateItems = () => {
    if (formData.length > 0) {
      setFormData((old: any) => [...old, formData[formData.length - 1]]);
      setValidForm((old: any) => [...old, validForm[validForm.length - 1]]);
    } else {
      setFormData((old: any) => [...old, initFormData]);
      setValidForm((old: any) => [...old, initValidForm]);
    }
  };

  const removeItems = (id: any) => () => {
    const form = formData.filter((formDataField: any, index: number) => {
      console.log(formDataField);

      return index !== id;
    });
    setFormData(form);
  };

  const handleToggle = (index: number) => (event: any) => {
    const { name, checked } = event.target;
    setFormData((old: any) => {
      const oldData = [...old];
      const commodity = {
        commodityId: oldData[index].commodityId,
        sub: oldData[index].sub,
        description: oldData[index].description,
      };

      if (name === 'isCommodity' && !checked) {
        commodity.commodityId = null;
        commodity.sub = null;
        commodity.description = null;
      }
      oldData[index] = {
        ...oldData[index],
        ...commodity,
        [name]: checked,
      };

      return oldData;
    });
    // setForms((old) => [...old, { ...old[index], [name]: checked }]);
  };

  const handleSelectChange = (index: number, name: string) => (event: any) => {
    const value = event.value;
    setFormData((old: any) => {
      const oldData = [...old];
      oldData[index] = {
        ...oldData[index],
        [name]: value,
      };
      setValidForm((oldValidFormVal: any) => {
        const oldValidData = [...oldValidFormVal];
        oldValidData[index] = {
          ...oldValidData[index],
          [name]: {
            ...oldValidData[index][name],
            required: Boolean(oldData[index][name]),
          },
        };

        return oldValidData;
      });

      return oldData;
    });
  };

  const handleInputChange = (index: number) => (event: any) => {
    const { target, type: eventType } = event;
    let { name, value, type } = target;
    value =
      type === 'number'
        ? value
          ? parseFloat(value)
          : eventType === 'blur'
          ? 0
          : value
        : value;
    setFormData((old: any) => {
      const oldData = [...old];
      let totalWeight = oldData[index].totalWeight;

      if (name === 'weight') {
        totalWeight = value * oldData[index].handlingUnitNo;
      } else if (name === 'handlingUnitNo') {
        totalWeight = value * oldData[index].weight;
      }
      oldData[index] = {
        ...oldData[index],
        [name]: value,
        totalWeight,
      };

      setValidForm((oldValidFormVal: any) => {
        const oldValidData = [...oldValidFormVal];
        oldValidData[index] = {
          ...oldValidData[index],
          [name]: {
            ...oldValidData[index][name],
            required: Boolean(oldData[index][name]),
          },
        };

        return oldValidData;
      });

      return oldData;
    });
  };

  const checkFreightClass = (index: number) => async (event: any) => {
    const { target, type: eventType } = event;
    let { name, value, type } = target;
    value =
      type === 'number'
        ? value
          ? parseFloat(value)
          : eventType === 'blur'
          ? 0
          : value
        : value;

    const oldData = [...formData];
    let freightClass = oldData[index].freightClass;
    let weight = oldData[index].weight;
    let itemLength = oldData[index].itemLength;
    let width = oldData[index].width;
    let height = oldData[index].height;

    if (weight && itemLength && width && height) {
      freightClass = await getFreightClass({
        itemLength: itemLength || 0,
        width: width || 0,
        height: height || 0,
        weight: weight || 0,
        lengthUnit: lengthMeasurement,
        weightUnit: weightMeasurement,
      })
        .then((data: any) => data?.data?.freightClass || 0)
        .catch(() => false);
    } else {
      freightClass = 0;
    }

    oldData[index] = {
      ...oldData[index],
      [name]: value,
      freightClass,
    };

    setFormData(oldData);
  };

  return (
    <>
      {formData.map((form: any, index: number) => (
        <div
          key={index}
          className="w-full xxl:p-5 p-4 mb-4 border border-gray200 rounded-lg flex flex-col gap-4 text-nowrap"
        >
          <div className="flex flex-wrap xlm:flex-row flex-col xxl:justify-between xxl:gap-4 gap-2">
            <div className="flex flex-wrap flex-1 xxl:gap-4 gap-3">
              <div className=" relative xxl:w-[calc(12.5%_-_14px)] xl:w-[calc(12.5%_-_11px)] lg:w-[calc(25%_-_9px)] md:w-[calc(33.33%_-_8px)] w-[calc(50%_-_6px)]">
                <label
                  htmlFor="handlingUnit"
                  className="text-[11px] align-super text-textSecondary"
                >
                  Handling unit <span className="text-red-500">*</span>
                </label>
                <SelectBox
                  isSearchable
                  name="handlingUnit"
                  id="handlingUnit"
                  className="form_control"
                  classComp={`mt-0 ${
                    !validForm[index].handlingUnit.required ||
                    !validForm[index].handlingUnit.valid
                      ? 'border border-red-500 border-solid rounded-[10px]'
                      : ''
                  }`}
                  options={handlingUnitOptions}
                  icon={
                    !validForm[index].handlingUnit.required ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500 bg-white" />
                    ) : null
                  }
                  onChangeFunc={handleSelectChange(index, 'handlingUnit')}
                  value={handlingUnitOptions.find(
                    (handlingUnitOption: any) =>
                      handlingUnitOption.value ===
                        parseInt(form.handlingUnit) || ''
                  )}
                  parentClassName="relative"
                />
              </div>
              <div className="relative xxl:w-[calc(12.5%_-_14px)] xl:w-[calc(12.5%_-_11px)] lg:w-[calc(25%_-_9px)] md:w-[calc(33.33%_-_8px)] w-[calc(50%_-_6px)]">
                <label className="text-[11px] align-super text-textSecondary">
                  № of handling units <span className="text-red-500">*</span>
                </label>
                <InputText
                  inputType="number"
                  inputName="handlingUnitNo"
                  min="0"
                  className={`${
                    !validForm[index].handlingUnitNo.required
                      ? 'border border-red-500 border-solid'
                      : !validForm[index].handlingUnitNo.valid &&
                        'border border-red-500 border-solid'
                  }`}
                  icon={
                    !validForm[index].handlingUnitNo.required ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  value={form.handlingUnitNo || ''}
                  errorText={
                    !validForm[index].handlingUnitNo.valid
                      ? ' № of handling units is required'
                      : null
                  }
                  onChangeFunc={handleInputChange(index)}
                  onBlur={handleInputChange(index)}
                />

                {
                  // !validForm[index].handlingUnitNo.required ? (
                  //     <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">It's required</div>
                  // ) :
                  // !validForm[index].handlingUnitNo.valid && (
                  //     <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">
                  //         Cannot exceed go over {HANDLING_UNIT_NUMBER}
                  //     </div>
                  // )
                }
              </div>
              <div className="relative xxl:w-[calc(12.5%_-_14px)] xl:w-[calc(12.5%_-_11px)] lg:w-[calc(25%_-_9px)] md:w-[calc(33.33%_-_8px)] w-[calc(50%_-_6px)]">
                <label className="text-[11px] align-super text-textSecondary">
                  Length ({lengthMeasurement?.toLowerCase()}){' '}
                  <span className="text-red-500">*</span>
                </label>

                <InputText
                  inputType="number"
                  inputName="itemLength"
                  min="0"
                  className={`${
                    !validForm[index].itemLength.required
                      ? 'border border-red-500 border-solid'
                      : !validForm[index].itemLength.valid &&
                        'border border-red-500 border-solid'
                  }`}
                  icon={
                    !validForm[index].itemLength.required ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  value={form.itemLength || ''}
                  // errorText={!validForm[index].itemLength.valid ? 'Item length is required' : null}
                  onChangeFunc={handleInputChange(index)}
                  onBlur={checkFreightClass(index)}
                />
                {/* </div> */}
                {
                  // !validForm[index].itemLength.required ? (
                  //     <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">It's required</div>
                  // ) :
                  // !validForm[index].itemLength.valid && (
                  //     <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">
                  //         Over {validateMeasurement.length} {lengthMeasurement?.toLowerCase()}
                  //     </div>
                  // )
                }
              </div>
              <div className="relative xxl:w-[calc(12.5%_-_14px)] xl:w-[calc(12.5%_-_11px)] lg:w-[calc(25%_-_9px)] md:w-[calc(33.33%_-_8px)] w-[calc(50%_-_6px)]">
                <label className="text-[11px] align-super text-textSecondary">
                  Width ({lengthMeasurement?.toLowerCase()}){' '}
                  <span className="text-red-500">*</span>{' '}
                </label>
                <InputText
                  inputType="number"
                  inputName="width"
                  min="0"
                  className={`${
                    !validForm[index].width.required
                      ? 'border border-red-500 border-solid'
                      : !validForm[index].width.valid &&
                        'border border-red-500 border-solid'
                  }`}
                  icon={
                    !validForm[index].width.required ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  value={form.width || ''}
                  // errorText={!validForm[index].width.required ? 'Item width is required' : null}
                  onChangeFunc={handleInputChange(index)}
                  onBlur={checkFreightClass(index)}
                />

                {
                  // !validForm[index].width.required ? (
                  //     <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">It's required</div>
                  // ) :
                  // !validForm[index].width.valid && (
                  //     <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">
                  //         {validateMeasurement.width} {lengthMeasurement?.toLowerCase()} maximum
                  //     </div>
                  // )
                }
              </div>
              <div className="relative xxl:w-[calc(12.5%_-_14px)] xl:w-[calc(12.5%_-_11px)] lg:w-[calc(25%_-_9px)] md:w-[calc(33.33%_-_8px)] w-[calc(50%_-_6px)]">
                <label className="text-[11px] align-super text-textSecondary">
                  Height ({lengthMeasurement?.toLowerCase()}){' '}
                  <span className="text-red-500">*</span>
                </label>
                <InputText
                  inputType="number"
                  inputName="height"
                  min="0"
                  className={`${
                    !validForm[index].height.required
                      ? 'border border-red-500 border-solid'
                      : !validForm[index].height.valid &&
                        'border border-red-500 border-solid'
                  }`}
                  icon={
                    !validForm[index].height.required ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  value={form.height || ''}
                  // errorText={!validForm[index].height.required ? 'Item height is required' : null}
                  onChangeFunc={handleInputChange(index)}
                  onBlur={checkFreightClass(index)}
                />
                {/* </div> */}
                {
                  // !validForm[index].height.required ? (
                  //     <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">It's required</div>
                  // ) :
                  // !validForm[index].height.valid && (
                  //     <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">
                  //         {validateMeasurement.height} {lengthMeasurement?.toLowerCase()} maximum
                  //     </div>
                  // )
                }
              </div>
              <div className="relative xxl:w-[calc(12.5%_-_14px)] xl:w-[calc(12.5%_-_11px)] lg:w-[calc(25%_-_9px)] md:w-[calc(33.33%_-_8px)] w-[calc(50%_-_6px)]">
                {/* <div
                                className={`bg-white py-2 pl-5 px-3 leading-none border rounded-full border-borderDark
                                ${!validForm[index].weight.required && 'border border-red-500 border-solid'}`}
                            > */}
                <label className="text-[11px] align-super text-textSecondary">
                  Weight per unit ({form.weightMeasurement?.toLowerCase()}){' '}
                  <span className="text-red-500">*</span>
                </label>
                <InputText
                  inputType="number"
                  inputName="weight"
                  min="0"
                  className={`${
                    !validForm[index].weight.required
                      ? 'border border-red-500 border-solid'
                      : !validForm[index].weight.valid &&
                        'border border-red-500 border-solid'
                  }`}
                  icon={
                    !validForm[index].height.required ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  value={form.weight || ''}
                  // errorText={!validForm[index].weight.required ? 'Item weight is required' : null}
                  onChangeFunc={handleInputChange(index)}
                  onBlur={checkFreightClass(index)}
                />

                {/* </div> */}
                {/* {!validForm[index].weight.required && (
                                <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">It's required</div>
                            )} */}
              </div>
              <div className="relative xxl:w-[calc(12.5%_-_14px)] xl:w-[calc(12.5%_-_11px)] lg:w-[calc(25%_-_9px)] md:w-[calc(33.33%_-_8px)] w-[calc(50%_-_6px)]">
                <label className="text-[11px] align-super text-textSecondary">
                  Total weight ({form.weightMeasurement?.toLowerCase()})
                </label>
                <InputText
                  inputName="totalWeight"
                  value={form?.totalWeight?.toFixed()}
                  className={`${
                    !validForm[index].weight.required
                      ? 'border border-red-500 border-solid'
                      : !validForm[index].weight.valid &&
                        'border border-red-500 border-solid'
                  }`}
                  icon={
                    !validForm[index].height.required ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  readOnly
                />
              </div>
              <div className="relative xxl:w-[calc(12.5%_-_14px)] xl:w-[calc(12.5%_-_11px)] lg:w-[calc(25%_-_9px)] md:w-[calc(33.33%_-_8px)] w-[calc(50%_-_6px)]">
                <label className="text-[11px] align-super text-textSecondary">
                  Freight Class
                </label>
                <InputText
                  inputName="freightClass"
                  value={form.freightClass}
                  readOnly
                  className={`${
                    !validForm[index].weight.required
                      ? 'border border-red-500 border-solid'
                      : !validForm[index].weight.valid &&
                        'border border-red-500 border-solid'
                  }`}
                  icon={
                    !validForm[index].height.required ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                />
              </div>
            </div>
            <div className="flex items-center gap-4 xlm:mt-[26px] mt-2 md:justify-start justify-center">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  name="isStack"
                  className="sr-only peer"
                  checked={form.isStack}
                  onChange={handleToggle(index)}
                />
                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-primary"></div>
                <span className="ml-2 text-xs font-medium text-black ">
                  Do not stack
                </span>
              </label>
              {/* <label className="relative mb-4 inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										name="isCommodity"
										className="sr-only peer"
										checked={form.isCommodity}
										onChange={handleToggle(index)}
									/>
									<div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-primary"></div>
									<span className="ml-3 text-xs font-medium text-black ">Specify Commodity</span>
								</label> */}

              <div className="delete cursor-pointer p-[5px] bg-utilitySuccess100 rounded-[4px] flex justify-center items-center text-primary">
                <Eye
                  className="w-4 h-4 text-fgSuccessPrimary"
                  onClick={() => setIsShowVisualUnit(true)}
                />
              </div>

              {formData.length > 1 && (
                <div className="delete cursor-pointer p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary">
                  <Trash01 className="w-4 h-4" onClick={removeItems(index)} />
                </div>
              )}
            </div>
          </div>
          {Boolean(form.isCommodity) && (
            <div className="flex gap-4">
              <div className="w-1/3 relative">
                <div
                  className={`bg-white py-2 pl-5 px-3 leading-none border rounded-full border-borderDark
                                    ${
                                      !validForm[index].commodityId.required &&
                                      'border border-red-500 border-solid'
                                    }`}
                >
                  <label className="text-[12px] block">NMFC Class *</label>
                  <input
                    readOnly
                    type="text"
                    name="commodityId"
                    placeholder="Select"
                    value={form.commodityName || ''}
                    onClick={handleActionType('commodity', index)}
                    className="w-full text-sm focus:ring-0 focus:shadow-none text-black"
                  />
                </div>
                {/* {!validForm[index].commodityId.required && (
                                    <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">It's required</div>
                                )} */}
              </div>
              <div className="w-1/3 relative">
                <div
                  className={`bg-white py-2 pl-5 px-3 leading-none border rounded-full border-borderDark
                                    ${
                                      !validForm[index].sub.required &&
                                      'border border-red-500 border-solid'
                                    }`}
                >
                  <label className="text-[12px] block">Sub *</label>
                  <input
                    type="text"
                    name="sub"
                    placeholder="Enter here"
                    value={form.sub || ''}
                    onChange={handleInputChange(index)}
                    className="w-full text-sm focus:ring-0 focus:shadow-none text-black"
                  />
                </div>
                {/* {!validForm[index].sub.formData.customerId === "" || formData.customerId === null && (
                                    <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">It's required</div>
                                )} */}
              </div>
              <div className="w-1/3 relative">
                <div
                  className={`bg-white py-2 pl-5 px-3 leading-none border rounded-full border-borderDark
                                    ${
                                      !validForm[index].description.required &&
                                      'border border-red-500 border-solid'
                                    }`}
                >
                  <label className="text-[12px] block">Description</label>
                  <input
                    type="text"
                    name="description"
                    placeholder="Enter here"
                    value={form.description || ''}
                    onChange={handleInputChange(index)}
                    className="w-full text-sm focus:ring-0 focus:shadow-none text-black"
                  />
                </div>
                {/* {!validForm[index].description.required && (
                                    <div className="error-text text-[12px] text-red-500 absolute -bottom-5 ml-2">It's required</div>
                                )} */}
              </div>
            </div>
          )}
        </div>
      ))}
      <div className="flex justify-end mb-4 gap-4 ">
        <ButtonCmp
          type="submit"
          className="btn_primary"
          onClick={addItems}
          icon={<Plus className="text-white w-4 h-4" />}
        >
          Add Items
        </ButtonCmp>
        <ButtonCmp
          type="submit"
          className="btn_secondary_black"
          onClick={duplicateItems}
          icon={<Copy04 className="w-4 h-4" />}
        >
          Duplicate
        </ButtonCmp>
      </div>
      {isShowVisualUnit && (
        <VisualizeUnit
          handleClose={() => setIsShowVisualUnit(false)}
          weightMeasurement={weightMeasurement}
        />
      )}
    </>
  );
};

export default Items;
