import {
  Container,
  Minimize02,
  Route,
  SearchLg,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import TooltipCmp from 'src/components/TooltipCmp';

import avatar from '../../../assets/img/Avatar1.png';

const ActiveShipments = ({ handleClose }: any) => {
  console.log('ActiveShipments :>> ');

  return (
    <div className="fixed bottom-10 right-10 w-[500px] h-[calc(100vh_-_198px)] overflow-y-auto bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] custom-scrollbar-v2">
      <div className="flex items-start border-b border-utilityGray200 p-4 gap-2 sticky top-0 z-[1] bg-white">
        <div className="rounded-md border border-utilityGray200 shadow-xs p-[9px] mt-0.5">
          <Route className="w-4 h-4" />
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight900 text-base font-semibold">
            In-Transit Shipments
          </h6>
          <p className="text-gray500 text-xs font-normal">
            Here is an overview of your lane details.
          </p>
        </div>
        <ButtonCmp className="!p-1.5 btn_secondary_black">
          <Minimize02
            className="w-4 h-4 text-primary cursor-pointer"
            onClick={() => {
              console.log('button :>> ');
              handleClose();
            }}
          />
        </ButtonCmp>
      </div>
      <div className="p-4 flex flex-col">
        <InputText
          inputName="searchQuote"
          placeholder="Search"
          className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
          icon={
            <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
          }
          value={''}
          inputType="text"
          isClearable={true}
          parentClassName="mb-4"
        />
        <AccordionCmp
          title={
            <div className="flex items-center gap-1.5 w-full">
              <div className="rounded-md border border-utilityGray200 shadow-xs p-[3px] ">
                <Container className="w-3 h-3" />
              </div>
              <div className="flex gap-x-1.5 flex-1">
                <h6 className="text-primary text-sm font-medium  underline">
                  #WAL-DMIF-1
                </h6>
                <div className="text-grayLight600 text-[10px] font-medium flex items-center gap-x-1">
                  (
                  <TooltipCmp message="Montreal">
                    <p className="max-w-14 truncate">Montreal </p>
                  </TooltipCmp>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                  >
                    <path
                      d="M10.3536 4.35355C10.5488 4.15829 10.5488 3.84171 10.3536 3.64645L7.17157 0.464466C6.97631 0.269204 6.65973 0.269204 6.46447 0.464466C6.2692 0.659728 6.2692 0.976311 6.46447 1.17157L9.29289 4L6.46447 6.82843C6.2692 7.02369 6.2692 7.34027 6.46447 7.53553C6.65973 7.7308 6.97631 7.7308 7.17157 7.53553L10.3536 4.35355ZM0 4.5H10V3.5H0V4.5Z"
                      fill="#475467"
                    />
                  </svg>
                  <TooltipCmp message="Montreal">
                    <p className="max-w-14 truncate">Houston</p>
                  </TooltipCmp>
                  )
                </div>
              </div>
              <BadgeCmp style="modern" type="success">
                LTL
              </BadgeCmp>
              <BadgeCmp style="modern" type="primary">
                FTL
              </BadgeCmp>
              <BadgeCmp style="modern" type="info">
                Volume LTL
              </BadgeCmp>
            </div>
          }
          className="shadow-sm mb-4"
          contentClassName="!p-0"
          contentParentClass="border-utilityGray200"
        >
          <ul className="flex flex-wrap gap-y-2 -mx-2">
            <li className="px-2 text-grayLight600 text-xs font-normal w-1/2 flex">
              <span className="flex-none">Cutsomer Name:&nbsp;</span>
              <div className="flex items-center gap-x-0.5">
                <img
                  src={avatar}
                  className="w-4 h-4  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                />
                {/* IF NO IMAGE FOUND */}
                {/* <div className="w-4 h-4 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none text-[8px]">
                  WA
                </div> */}
                <p className="text-grayLight900 font-semibold w-28 truncate">
                  Olivia Rhye
                </p>
              </div>
            </li>
            <li className="px-2 text-grayLight600 text-xs font-normal w-1/2">
              Total Cost:&nbsp;
              <span className="text-grayLight900 font-semibold">$752.00</span>
            </li>
            <li className="px-2 text-grayLight600 text-xs font-normal w-1/2 flex">
              <span className="flex-none">Sales Rep:&nbsp;</span>
              <div className="flex items-center gap-x-0.5">
                <img
                  src={avatar}
                  className="w-4 h-4  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                />
                {/* IF NO IMAGE FOUND */}
                {/* <div className="w-4 h-4 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none text-[8px]">
                  WA
                </div> */}
                <p className="text-grayLight900 font-semibold w-28 truncate">
                  Koray Okumas
                </p>
              </div>
            </li>
            <li className="px-2 text-grayLight600 text-xs font-normal w-1/2">
              Total Price:&nbsp;
              <span className="text-grayLight900 font-semibold">$905.80</span>
            </li>
            <li className="px-2 text-grayLight600 text-xs font-normal w-1/2">
              Est. Delivery:&nbsp;
              <span className="text-grayLight900 font-semibold">
                8hrs23mins
              </span>
            </li>
            <li className="px-2 text-grayLight600 text-xs font-normal w-1/2">
              Margin:&nbsp;
              <span className="text-grayLight900 font-semibold">$56.00</span>
            </li>
          </ul>
        </AccordionCmp>
      </div>
    </div>
  );
};

export default ActiveShipments;
