import { Minimize02, Phone, Route } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import TabButton from 'src/components/TabButton';

import avatar from '../../../assets/img/Avatar1.png';
import star from '../../../assets/img/Star.svg';

const filterArr = [
  {
    value: 'lane_history',
    name: 'Lane History',
  },
  {
    value: 'top_carriers',
    name: 'Top Carriers',
  },
];

const LaneOverview = ({ handleClose, searchResult }: any) => {
  const [activeTab, setActiveTab] = useState('lane_history');

  return (
    <div className="fixed bottom-10 right-10 w-[500px] h-[calc(100vh_-_198px)] overflow-y-auto bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] custom-scrollbar-v2">
      <div className="flex items-start border-b border-utilityGray200 p-4 gap-2 sticky top-0 z-[1] bg-white">
        <div className="rounded-md border border-utilityGray200 shadow-xs p-[9px] mt-0.5">
          <Route className="w-4 h-4" />
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight900 text-base font-semibold">
            Lane Overview
          </h6>
          <p className="text-gray500 text-xs font-normal">
            Here is an overview of your lane details.
          </p>
        </div>
        <ButtonCmp className="!p-1.5 btn_secondary_black">
          <Minimize02
            className="w-4 h-4 text-primary cursor-pointer"
            onClick={handleClose}
          />
        </ButtonCmp>
      </div>
      <div className="p-4 flex flex-col gap-y-6">
        <ul className="flex flex-wrap">
          <li className="w-1/4 pr-2">
            <h6 className="text-gray500 text-xs font-normal">Mileage</h6>
            <p className="text-grayLight600 text-xs font-semibold mt-1">
              {searchResult.distance}
            </p>
          </li>
          <li className="w-1/4 pr-2">
            <h6 className="text-gray500 text-xs font-normal">Duration</h6>
            <p className="text-grayLight600 text-xs font-semibold mt-1">
              {searchResult.duration}
            </p>
          </li>
          <li className="w-1/4 pr-2">
            <h6 className="text-gray500 text-xs font-normal">Total cost</h6>
            <p className="text-grayLight600 text-xs font-semibold mt-1">
              $5,234
            </p>
          </li>
          <li className="w-1/4">
            <h6 className="text-gray500 text-xs font-normal">Cost per mile</h6>
            <p className="text-grayLight600 text-xs font-semibold mt-1">
              $20 <span className="text-gray500 text-[11px]">($26)</span>
            </p>
          </li>
        </ul>
        <AccordionCmp
          title={
            <div className="py-3 pl-3 flex-1 flex gap-2 flex-wrap justify-between">
              <p className="text-grayLight900 text-xs font-semibold">
                Key Cities Along the Route
              </p>
              <p className="text-grayLight600 text-xs font-normal">
                Search Radius:{' '}
                <span className="text-textSecondary font-medium">
                  {searchResult?.radius}km
                </span>
              </p>
            </div>
          }
          className=" mb-0 !p-0 overflow-hidden"
          AccordionIconClass="mr-3"
          contentClassName="!p-0"
          contentParentClass="border-utilityGray200 !pt-0 !mt-0"
        >
          {searchResult?.status === 'loading' ? (
            <p className="p-3 text-sm">{searchResult?.message}</p>
          ) : searchResult?.status === 'error' ? (
            <p className="text-red-500 p-3 text-sm">{searchResult.error}</p>
          ) : searchResult?.cities?.length > 0 ? (
            <ul className="px-3 py-1 max-h-[145px] overflow-y-auto custom-scrollbar-v2 ">
              {searchResult.cities.map((city: any, index: number) => (
                <li
                  key={index}
                  className="flex gap-2 justify-between items-center text-grayLight600 text-xs leading-[18px] font-semibold py-2 last:border-b-0 border-b border-utilityGray200"
                >
                  <div>
                    <p className="text-primary500">{city.fullName}</p>
                    <p className="font-normal">
                      {city.population !== undefined
                        ? ` Population: ${city.population.toLocaleString()}`
                        : ' Population: Unknown'}
                    </p>
                  </div>

                  <span className="text-primary">
                    {Math.round(city.distance)}km
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="p-3 text-sm">
              No cities found within the specified radius.
            </p>
          )}
        </AccordionCmp>

        <div>
          <TabButton
            tabArray={filterArr}
            tabParentClassName="mb-2.5"
            parentClassName="p-1 rounded-[10px] border border-gray100 bg-gray50 w-full whitespace-nowrap overflow-x-auto scrollbar-hide gap-[1px]"
            className="!border-0 !bg-gray50 rounded-md w-1/2"
            activeClassName="!bg-white shadow-md"
            handleOnClick={(e: any) => {
              setActiveTab(e?.target?.dataset?.value);
            }}
            isActive={activeTab}
          />
          {activeTab === 'lane_history' ? (
            <ul className="flex flex-col gap-2.5">
              <li className="rounded-md border border-utilityGray200 px-2 py-3 shadow-none transition-all duration-[0.3s] hover:shadow-lg cursor-pointer">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="w-6 h-6 rounded border border-gray-200"
                    />
                    <p className="text-grayLight900 text-sm font-medium">
                      XPO Logistics Freight, Inc.
                    </p>
                    <div className="flex items-center gap-x-0.5 rounded-md border border-utilityGray200 px-1 py-0.5 ">
                      <p className="text-gray500 text-xs font-normal mt-[1px]">
                        4.5
                      </p>
                      <img src={star} />
                    </div>
                  </div>
                  <h6 className="text-primary text-xs font-medium underline">
                    #WAL-DMIF-1
                  </h6>
                  {/* <Share03 className="w-4 h-4 text-primary cursor-pointer" /> */}
                </div>

                <div className="flex flex-wrap gap-y-1 text-xs text-gray500 font-normal">
                  <div className="w-1/2 even:pr-0 pr-1">
                    Total Weight:&nbsp;
                    <span className="font-medium text-textSecondary">
                      20lbs
                    </span>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Margin:&nbsp;
                    <span className="font-medium text-textSecondary">$56</span>
                  </div>
                  <div className="w-1/2  even:pr-0 pr-1">
                    Total Cost:&nbsp;
                    <span className="font-medium text-textSecondary">$752</span>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Total Price:&nbsp;
                    <span className="font-medium text-textSecondary">
                      $905.80
                    </span>
                  </div>
                </div>
              </li>
              <li className="rounded-md border border-utilityGray200 px-2 py-3 shadow-none transition-all duration-[0.3s] hover:shadow-lg cursor-pointer">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="w-6 h-6 rounded border border-gray-200"
                    />
                    <p className="text-grayLight900 text-sm font-medium">
                      XPO Logistics Freight, Inc.
                    </p>
                    <div className="flex items-center gap-x-0.5 rounded-md border border-utilityGray200 px-1 py-0.5 ">
                      <p className="text-gray500 text-xs font-normal mt-[1px]">
                        4.5
                      </p>
                      <img src={star} />
                    </div>
                  </div>
                  <h6 className="text-primary text-xs font-medium underline">
                    #WAL-DMIF-1
                  </h6>
                  {/* <Share03 className="w-4 h-4 text-primary cursor-pointer" /> */}
                </div>

                <div className="flex flex-wrap gap-y-1 text-xs text-gray500 font-normal">
                  <div className="w-1/2 even:pr-0 pr-1">
                    Total Weight:&nbsp;
                    <span className="font-medium text-textSecondary">
                      20lbs
                    </span>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Margin:&nbsp;
                    <span className="font-medium text-textSecondary">$56</span>
                  </div>
                  <div className="w-1/2  even:pr-0 pr-1">
                    Total Cost:&nbsp;
                    <span className="font-medium text-textSecondary">$752</span>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Total Price:&nbsp;
                    <span className="font-medium text-textSecondary">
                      $905.80
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          ) : (
            <ul className="flex flex-col gap-2.5">
              <li className="rounded-md border border-utilityGray200 px-2 py-3">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="w-6 h-6 rounded border border-gray-200"
                    />
                    <p className="text-grayLight900 text-sm font-medium">
                      XPO Logistics Freight, Inc.
                    </p>
                  </div>

                  <div className="rating rating-sm rating-half">
                    {/* <input
                type="radio"
                name="rating-10"
                className="rating-hidden"
              /> */}
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-1 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-2 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-1 bg-primary even:ml-0 ml-1"
                      defaultChecked
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-2 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-1 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-2 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-1 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-2 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-1 bg-primary even:ml-0 ml-1"
                    />
                    <input
                      type="radio"
                      name="rating-10"
                      className="mask mask-star-2 mask-half-2 bg-primary even:ml-0 ml-1"
                    />
                  </div>
                </div>

                <div className="flex flex-wrap gap-y-1 text-xs text-gray500 font-normal">
                  <div className="w-1/2 even:pr-0 pr-1 flex items-center gap-1">
                    <Phone className="w-3 h-3 text-gray500" />
                    <a href="tel:18004092269" className="text-gray500">
                      1-800-409-2269
                    </a>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Total Loads:&nbsp;
                    <span className="font-medium text-textSecondary">768</span>
                  </div>
                  <div className="w-1/2  even:pr-0 pr-1">
                    Average Price:&nbsp;
                    <span className="font-medium text-textSecondary">$542</span>
                  </div>
                  <div className="w-1/2 even:pr-0 pr-1">
                    Last Order:&nbsp;
                    <span className="font-medium text-textSecondary">
                      #WAL-DMIF-1
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default LaneOverview;
