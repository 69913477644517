import { SearchLg, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';

import CommonModal from '../../../../../components/CommonModal';

import CustomerListTableRow from './CustomerSplitDetailRow';
import CustomerSplitDetailRowLoader from './CustomerSplitDetailRowLoader';
// import CustomerSplitDetailTableFooterRow from './CustomerSplitDetailTableFooterRow';

interface IProps {
  customerList: any[];
  isLoading: boolean;
  handleClose: () => void;
}

const CustomerSplitDetailTable = ({
  customerList,
  handleClose,
  isLoading,
}: IProps) => {
  const [sortData, setSortData] = useState({
    sortField: 'profit',
    sortType: 'desc',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [searchInput, setSearchInput] = useState('');
  const [pageItem, setPageItem] = useState([...customerList]);
  // const [totalObj, setTotalObj] = useState<any>({});

  const [filteredCustomerList, setFilteredCustomerList] = useState([
    ...customerList,
  ]);
  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Customer',
        sortable: true,
      },
      {
        id: 'salesRepFullName',
        name: 'Sales Representative',
        sortable: true,
      },
      {
        id: 'sales',
        name: 'Total Revenue',
        sortable: true,
      },
      {
        id: 'cost',
        name: 'Cost',
        sortable: true,
      },
      {
        id: 'orderCount',
        name: 'Total Orders',
        sortable: true,
      },
      {
        id: 'profit',
        name: 'Gross Profit',
        sortable: true,
      },
      {
        id: 'profitPercentage',
        name: 'Gross Profit %',
        sortable: true,
      },
    ],
    []
  );

  const recordsPerPageArr = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  useEffect(() => {
    // setSortData({ sortField: 'name', sortType: 'asc' });

    if (searchInput) {
      const filterList = customerList.filter((item: any) =>
        ['name', 'salesRepFullName'].some(
          (key) =>
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
        )
      );
      setFilteredCustomerList([...filterList]);
    } else {
      setFilteredCustomerList([...customerList]);
    }
  }, [searchInput, customerList]);

  // useEffect(() => {
  //   let tempTotals: any = {
  //     customersCount: filteredCustomerList.length,
  //   };
  //   const pageItems = filteredCustomerList.slice(
  //     (currentPage - 1) * recordsPerPage,
  //     currentPage * recordsPerPage
  //   );

  //   pageItems.forEach((customerRecords) => {
  //     Object.entries(customerRecords).forEach(([key, value]) => {
  //       if (key !== 'name' && key !== 'city') {
  //         if (!tempTotals[key]) {
  //           tempTotals[key] = 0;
  //         }

  //         if (typeof value === 'number') {
  //           tempTotals[key] += value;
  //         }
  //       }
  //     });
  //   });

  //   setTotalObj(tempTotals);
  // }, [filteredCustomerList, currentPage, recordsPerPage]);

  useEffect(() => {
    if (filteredCustomerList.length > recordsPerPage) {
      const pageItems = filteredCustomerList?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
      );
      setPageItem([...pageItems]);
    } else {
      setPageItem([...filteredCustomerList]);
    }
  }, [filteredCustomerList, currentPage, recordsPerPage]);

  const handleSorting = () => {
    const { sortField, sortType } = sortData;
    const sortedCustomerList = filteredCustomerList.sort((a: any, b: any) => {
      if (sortField === 'name') {
        const nameA = a.name.toUpperCase(); // to ensure case-insensitive comparison
        const nameB = b.name.toUpperCase(); // to ensure case-insensitive comparison

        if (sortType === 'asc') {
          return nameA.localeCompare(nameB);
        }

        return nameB.localeCompare(nameA);
      } else {
        if (a[sortField] < b[sortField]) {
          return sortType === 'asc' ? -1 : 1;
        }

        if (a[sortField] > b[sortField]) {
          return sortType === 'asc' ? 1 : -1;
        }
      }

      return 0;
    });

    setFilteredCustomerList([...sortedCustomerList]);
  };

  useEffect(() => {
    handleSorting();
  }, [sortData]);

  return (
    <CommonModal size="max-w-7xl" handleClose={() => {}}>
      <div className="p-5">
        <div className=" min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header flex-wrap ">
            <div className="table-left-wrap">
              <div className="table-title-wrap flex-wrap">
                <h5 className="table-title">Customers</h5>
                <BadgeCmp style="modern" type="success">
                  {filteredCustomerList.length}{' '}
                  {filteredCustomerList.length > 1 ? 'Customers' : 'Customer'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">Here is your customer details</p>
            </div>
            <div className="table-right-wrap ">
              <InputText
                inputName="searchOrder"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={searchInput}
                inputType="text"
                isClearable={true}
                onChangeFunc={(e: any) => setSearchInput(e.target.value)}
                parentClassName="w-[320px]"
              />
              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArr}
                  isSearchable={false}
                  onChangeFunc={(event: any) => {
                    setRecordsPerPage(event.value);
                    setCurrentPage(1);
                  }}
                  value={recordsPerPageArr.find(
                    (val: any) => val.value === recordsPerPage
                  )}
                />
              </div>
              <ButtonCmp
                className="btn_secondary_black lg:!px-[9px] !px-2"
                icon={<XClose className="w-4 h-4" />}
                iconSide="left"
                onClick={handleClose}
              >
                <></>
              </ButtonCmp>
            </div>
          </div>
          <div className="max-h-[calc(100vh_-_415px)] w-full flex flex-col">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                params={sortData}
                setParams={setSortData}
                headCells={headCells}
                tableDataArr={isLoading ? [] : pageItem}
                TableRowCmp={CustomerListTableRow}
                TableLoaderRowCmp={CustomerSplitDetailRowLoader}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={recordsPerPage}
                tableHeaderClass="sticky top-0 z-[1] shadow-[inset_0_-1px_0_rgba(234,236,240,1)] [&>tr]:border-0"
                // TableFooterRowCmp={CustomerSplitDetailTableFooterRow}
                // footerDataArr={[totalObj]}
              />
            </div>
            {!isLoading && !filteredCustomerList.length && (
              <NotFoundUI
                title="No Customer Data Found"
                desc="There are no data for customer split related data."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
            <div className="w-full">
              {!isLoading && filteredCustomerList.length > recordsPerPage && (
                <CustomPagination
                  recordsPerPage={recordsPerPage}
                  totalRecords={filteredCustomerList.length}
                  currentPage={currentPage}
                  handlePagination={setCurrentPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default CustomerSplitDetailTable;
