import React from 'react';

interface IProps {
  header: any;
  children: any;
  contentClassName?: string;
  headerClassName?: string;
}

const PageSectionLayout = ({
  header,
  children,
  contentClassName,
  headerClassName,
}: IProps) => (
  <section className="md:rounded-tl-3xl main__layout_section flex flex-col bg-white border-l border-utilityGray200 border-t">
    <div
      className={`${headerClassName} md:rounded-tl-3xl xxl:px-6 px-5 xxl:pt-5 pt-4 bg-white sticky top-0 z-[6] `}
    >
      {header}
    </div>
    <div
      className={`xxl:px-6 px-5 py-4 flex-grow w-full flex flex-col content-baseline xxl:gap-4 gap-3 ${contentClassName} `}
    >
      {children}
    </div>
  </section>
);

export default PageSectionLayout;
