import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listTeam = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.TEAM.LIST, {
    params,
    signal,
  });

  return response;
};

export const listAllTeam = async () => {
  const response = await axiosInterceptor.get(API.TEAM.LIST_ALL);

  return response;
};

export const TeamDetailById = async (id: any) => {
  const response = await axiosInterceptor.get(`${API.TEAM.DETAIL}/${id}`);

  return response;
};

export const createTeam = async (data: any) => {
  const response = await axiosInterceptor.post(API.TEAM.CREATE, data);

  return response;
};

export const updateTeam = async (data: any, id: number) => {
  const response = await axiosInterceptor.post(
    `${API.TEAM.UPDATE}/${id}`,
    data
  );

  return response;
};

export const removeTeam = async (id: number) => {
  const response = await axiosInterceptor.delete(`${API.TEAM.DELETE}/${id}`);

  return response;
};

export const getOrganizationChart = async () => {
  const response = await axiosInterceptor.get(API.TEAM.ORG_CHART);

  return response;
};
