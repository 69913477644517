import moment from 'moment';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { FILE_ORDER_STATUS } from 'src/constants/common';
import {
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const CustomerOrdersTableRow = ({ data }: any) => {
  console.log('🚀 ~ CustomerOrdersTableRow ~ data:', data);

  const getFormattedDate = (date: any) => {
    const momentDate = moment(date);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');

    if (momentDate.isSame(today, 'day')) {
      return `Today, ${momentDate.format('MMMM Do, YYYY')}`;
    } else if (momentDate.isSame(yesterday, 'day')) {
      return `Yesterday, ${momentDate.format('MMMM Do, YYYY')}`;
    } else if (momentDate.isSame(tomorrow, 'day')) {
      return `Tomorrow, ${momentDate.format('MMMM Do, YYYY')}`;
    } else {
      return momentDate.format('MMMM Do, YYYY');
    }
  };

  const getOrderStatusTypeColor = (orderStatus: string) => {
    if (orderStatus === FILE_ORDER_STATUS.NEW) {
      return 'primary';
    } else if (orderStatus === FILE_ORDER_STATUS.NO_QUICK_QUOTE) {
      return 'primary';
    } else if (orderStatus === FILE_ORDER_STATUS.NO_QUOTE) {
      return 'primary';
    } else if (orderStatus === FILE_ORDER_STATUS.QUICK_QUOTED) {
      return 'primary';
    } else if (orderStatus === FILE_ORDER_STATUS.QUOTED) {
      return 'primary';
    } else if (orderStatus === FILE_ORDER_STATUS.SAVED) {
      return 'lightblue';
    } else if (orderStatus === FILE_ORDER_STATUS.SPOT_QQ_REQUESTED) {
      return 'pink';
    } else if (orderStatus === FILE_ORDER_STATUS.CANCELLED) {
      return 'red';
    } else if (orderStatus === FILE_ORDER_STATUS.DRAFT_QUICK_QUOTE) {
      return 'pink';
    } else if (orderStatus === FILE_ORDER_STATUS.CLAIM) {
      return 'yellow';
    } else if (orderStatus === FILE_ORDER_STATUS.ARCHIVED) {
      return 'gray';
    } else if (orderStatus === FILE_ORDER_STATUS.INVOICE_SENT) {
      return 'gray';
    } else if (orderStatus === FILE_ORDER_STATUS.INVOICE_PAID) {
      return 'gray';
    } else if (orderStatus === FILE_ORDER_STATUS.DISPATCHED) {
      return 'info';
    } else if (orderStatus === FILE_ORDER_STATUS.INVOICE_CREATED) {
      return 'success';
    } else if (orderStatus === FILE_ORDER_STATUS.DELIVERED) {
      return 'success';
    } else if (orderStatus === FILE_ORDER_STATUS.IN_TRANSIT) {
      return 'success';
    } else {
      return 'gray'; // Default color for unknown statuses
    }
  };

  return (
    <>
      <td className="flex flex-col px-5 py-4 w-60 min-w-60 max-w-60 text-pretty">
        <div className=" flex items-center gap-3">
          {data.orderCommissioneesImage ? (
            <img
              src={`${data.orderCommissioneesImageUrl}${data.orderCommissioneesImage}`}
              className="w-8 h-8 rounded-md text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
              alt={data.orderCommissionees}
              title={data.orderCommissionees}
              onError={onError}
            />
          ) : (
            data?.orderCommissionees && (
              <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                {getShortName(`${data?.orderCommissionees}`)}
              </div>
            )
          )}
          {data?.orderCommissionees?.length > 35 ? (
            <TooltipCmp
              message={data.orderCommissionees}
              parentClassName="xs:w-80 w-64"
            >
              <span className="truncate block max-w-64 text-sm whitespace-nowrap font-semibold ">
                {data.orderCommissionees}
              </span>
            </TooltipCmp>
          ) : (
            <div className="max-w-32">
              <h6 className="text-grayLight900 font-medium leading-tight truncate">
                {data.orderCommissionees}
              </h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #{data.orderFullId ?? ''}
              </p>
            </div>
          )}
        </div>
      </td>
      <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
        {data.deliveryDateActual
          ? getFormattedDate(data.deliveryDateActual)
          : '-'}
      </td>
      <td className="px-5 py-4 min-w-40">
        <div className="flex">
          {data?.orderStatus ? (
            <BadgeCmp
              style="modern"
              type={getOrderStatusTypeColor(data.orderStatus)}
            >
              {data.orderStatus ? data.orderStatus : '-'}
            </BadgeCmp>
          ) : (
            '-'
          )}
        </div>
      </td>
      <td className="px-5 py-4">${getFormattedNumber(data.combinedRevenue)}</td>
      <td className="px-5 py-4 w-28 min-w-28 max-w-28">
        ${getFormattedNumber(data.combinedCost)}
      </td>
      <td className="px-5 py-4">
        {data.combinedMargin > 0
          ? `$${getFormattedNumber(data.combinedMargin, true, true)}`
          : getFormattedNumber(data.combinedMargin, true, true)}
      </td>
      <td className="px-5 py-4">
        {data.combinedMarginPercentage > 0
          ? `${parseFloat(data?.combinedMarginPercentage).toFixed(2)}%`
          : `${0}%`}
      </td>
    </>
  );
};

export default CustomerOrdersTableRow;
