import { CheckSquareBroken, XClose } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';

// import Streaks from '../../assets/img/streaks.svg';

const CreditIncreaseRow = () => {
  console.log();

  return (
    <>
      <td className="px-5 py-4 w-64 min-w-64 max-w-64">
        <div className="flex items-center gap-3">
          <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none custom-loading">
            WA
          </div>
          <div className="max-w-44  flex flex-col custom-loading">
            <TooltipCmp
              message={'Olivia Rhye'}
              childrenClassName="text-grayLight900 font-medium custom-loading"
            >
              <p className="truncate">Olivia Rhye</p>
            </TooltipCmp>
            <TooltipCmp message={'#WAL-DMIF-1'}>#WAL-DMIF-1</TooltipCmp>
          </div>
        </div>
      </td>
      <td className="px-5 py-4">
        <div className="flex flex-wrap gap-1">
          <BadgeCmp
            style="modern"
            type="success"
            mainClassName="custom-loading"
          >
            Approved
          </BadgeCmp>
        </div>
      </td>
      <td className="px-5 py-4">
        <span className="custom-loading">$20,000</span>
      </td>
      <td className="px-5 py-4">
        <span className="custom-loading">$5,000</span>
      </td>
      <td className="px-5 py-4">
        <span className="custom-loading">$25,000</span>
      </td>
      <td className="px-5 py-4">
        <div className="justify-center items-center gap-2 flex ">
          <div className="w-4 h-4 custom-loading">
            <CheckSquareBroken className="w-3.5 h-3.5 text-fgSuccessPrimary" />
          </div>
          <div className="w-4 h-4 custom-loading">
            <XClose className="w-4 h-4 text-fgErrorPrimary" />
          </div>
        </div>
      </td>
    </>
  );
};
export default CreditIncreaseRow;
