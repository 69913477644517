import { UserPlus01 } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import { useContext, useEffect, useState } from 'react';

import CommonModal from '../../../components/CommonModal';
import Loader from '../../../components/Loader';
import { AuthContext } from '../../../context/AuthContext';
import { listPermission } from '../../../services/PermissionService';
import { listRole } from '../../../services/RoleService';
import {
  createUser,
  getUserDetail,
  updateUserPermission,
} from '../../../services/TeamMemberService';
import WalToast from '../../../utils/WalToast';

import ModulePermissionCard from './ModulePermissionCard';

interface IProps {
  handleClose: () => void;
  team: any;
  userCreateFormData?: any;
  setIsRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccessControlModal = ({
  handleClose,
  team,
  userCreateFormData = {},
  setIsRefresh,
}: IProps) => {
  const { reloadUser } = useContext(AuthContext);
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(true);
  const [isRoleLoading, setIsRoleLoading] = useState(true);
  const [isUserDetailLoading, setIsUserDetailLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userPermissionList, setUserPermissionList] = useState({});
  const [teamPermissionsFromUserDetail, setTeamPermissionsFromUserDetail] =
    useState<number[]>([]);
  const [allowedPermissionAsPerRole, setAllowedPermissionAsPerRole] = useState(
    []
  );

  useEffect(() => {
    if (!(Object.keys(team).length === 0 && team.constructor === Object)) {
      setIsUserDetailLoading(true);
      getUserDetail(team.id)
        .then((response: any) => {
          const permissionArr = response.data?.permissions.map(
            (permission: any) => permission.id
          );
          setTeamPermissionsFromUserDetail(permissionArr);
        })
        .catch(console.log)
        .finally(() => setIsUserDetailLoading(false));
    } else {
      setTeamPermissionsFromUserDetail(userCreateFormData?.permissions);
    }

    listPermission()
      .then((response: any) => {
        setPermissions(response.data);
      })
      .catch(console.log)
      .finally(() => setIsPermissionsLoading(false));

    listRole()
      .then((response: any) => {
        let roleId: any;

        if (
          !(
            Object.keys(userCreateFormData).length === 0 &&
            userCreateFormData.constructor === Object
          )
        ) {
          roleId = userCreateFormData.role;
        } else if (
          !(Object.keys(team).length === 0 && team.constructor === Object)
        ) {
          roleId = team?.role?.id;
        }
        const roleRec = response.data.find(
          (resRole: any) => resRole.id === roleId
        );
        setAllowedPermissionAsPerRole(
          roleRec.permissions.map((prm: any) => prm.id)
        );
      })
      .catch(console.log)
      .finally(() => setIsRoleLoading(false));
  }, []);

  const handleSaveUserWithPermission = () => {
    const permissionsArray = Object.values(userPermissionList).reduce(
      (acc: any, val) => acc.concat(val),
      []
    );

    if (
      !(
        Object.keys(userCreateFormData).length === 0 &&
        userCreateFormData.constructor === Object
      )
    ) {
      // here create form data
      const newUserCreateFormData = {
        ...userCreateFormData,
        permissions: permissionsArray?.toString(),
      };
      const appendFormData: any = new FormData();
      Object.entries(newUserCreateFormData).forEach((data) => {
        const [key, value] = data;

        if (key === 'team') {
          return;
        }
        appendFormData.append(key, value);
      });

      setIsLoading(true);

      // pushing image to last
      const imageValueOfFormToAppend = appendFormData.get('image');
      appendFormData.delete('image');
      appendFormData.append('image', imageValueOfFormToAppend);

      createUser(appendFormData)
        .then(() => {
          setIsRefresh(true);
          reloadUser();
          handleClose();
          WalToast.success(
            `${userCreateFormData?.firstName} ${userCreateFormData?.lastName} has added to WALHQ`
          );
        })
        .finally(() => setIsLoading(false))
        .catch((e: any) => {
          console.log(e);
          WalToast.error(e?.response?.data?.message ?? 'New member not added');
        });
    } else if (
      !(Object.keys(team).length === 0 && team.constructor === Object)
    ) {
      setIsLoading(true);
      updateUserPermission(team.id, { permission: permissionsArray })
        .then(() => {
          setIsRefresh(true);
          handleClose();
          WalToast.success('User permissions updated');
        })
        .finally(() => setIsLoading(false))
        .catch((e: any) => {
          WalToast.error(
            e?.response?.data?.message ??
              'Member permission not updated Successfully'
          );
        });
    }
  };

  return (
    <CommonModal
      title="Manage Member Permissions"
      titleDesc="Select the modules and submodules permissions to assign to the member."
      handleClose={handleClose}
      headerIcon={<UserPlus01 />}
      size={'max-w-3xl max-h-[700px]'}
      primaryBtnText="Save"
      primaryBtnOnClick={handleSaveUserWithPermission}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      primaryBtnLoading={isLoading}
    >
      <div className="h-full">
        <div className="flex flex-col justify-between h-full">
          <div className="w-full p-5">
            {/* <div className="pb-5 border-b border-utilityGray200 justify-start items-start gap-2.5">
                            <div className="text-grayLight900 text-[18px] font-semibold leading-7">Set User Permissions</div>
                        </div> */}
            {isPermissionsLoading || isUserDetailLoading || isRoleLoading ? (
              <div className="relative h-72">
                <Loader />
              </div>
            ) : (
              <div className="">
                {permissions.map((permission: any, index: number) => (
                  <ModulePermissionCard
                    key={`ModulePermissionCard_${index}_${
                      permission.id ?? 'key'
                    }`}
                    teamId={team.id}
                    permission={permission}
                    setUserPermissionList={setUserPermissionList}
                    teamPermissionsFromUserDetail={
                      teamPermissionsFromUserDetail
                    }
                    allowedPermissionAsPerRole={allowedPermissionAsPerRole}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default AccessControlModal;
