import 'react-tippy/dist/tippy.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tippy';
import { BasicContext } from 'src/context/BasicContext';

interface IProps {
  message?: any;
  children: any;
  type?: 'light' | 'dark';
  openOnClick?: boolean;
  parentClassName?: string;
  isUseChildTopPos?: boolean;
  followCursor?: boolean;
  isOpenChildTopPosOnTop?: boolean;
  childrenClassName?: string;
  isArrow?: boolean;
}

const TooltipCmp = ({
  message,
  children,
  type = 'dark',
  openOnClick,
  parentClassName = '',
  childrenClassName = '',
  isUseChildTopPos,
  isOpenChildTopPosOnTop,
  followCursor,
  isArrow = true,
}: IProps) => {
  const uiState = useSelector((state: any) => state.MobileView);
  const tooltipRef = useRef<any>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [delayTimeout, setDelayTimeout] = useState<NodeJS.Timeout | null>(null);
  const [delayCloseTimeout, setDelayCloseTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const {
    tooltipGlobalOpen,
    setTooltipGlobalOpen,
    setActionTooltipGlobalOpen,
  } = useContext(BasicContext);
  const [topPos, setTopPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (tooltipOpen || tooltipGlobalOpen) {
        setTooltipOpen(false);
        setTooltipGlobalOpen(false);
      }
    };

    const scrollableContainer = document.querySelector('.main__layout_section');
    scrollableContainer?.addEventListener('scroll', handleScroll);

    const allScrollableContainers =
      document.querySelectorAll('.overflow-x-auto');

    allScrollableContainers.forEach((element) => {
      element.addEventListener('scroll', handleScroll);
    });

    const allScrollableYContainers =
      document.querySelectorAll('.overflow-y-auto');

    allScrollableYContainers.forEach((element) => {
      element.addEventListener('scroll', handleScroll);
    });

    return () => {
      scrollableContainer?.removeEventListener('scroll', handleScroll);
      allScrollableContainers.forEach((element) => {
        element?.removeEventListener('scroll', handleScroll);
      });
      allScrollableYContainers.forEach((element) => {
        element.removeEventListener('scroll', handleScroll);
      });
    };
  }, [tooltipOpen, message, children]);

  // This function used to render floating tooltip on bottom instead of top
  const handleToggleTooltip = (e: any) => {
    const targetHeight =
      e.target.clientHeight && e.target.clientHeight !== 0
        ? e.target.clientHeight
        : 20;
    const extraPadding = 15;
    setTopPos(targetHeight + extraPadding);
    setTooltipOpen((prev: any) => !prev);
    setActionTooltipGlobalOpen(false);
  };

  const handleMouseEnter = () => {
    if (delayTimeout) {
      clearTimeout(delayTimeout);
    }

    setDelayTimeout(
      setTimeout(() => {
        setTooltipOpen(true);
        setTooltipGlobalOpen(true);
        setActionTooltipGlobalOpen(false);
      }, 200)
    ); // Adjust the delay time as needed (200ms in this example)
  };

  const handleMouseLeave = () => {
    if (delayTimeout) {
      clearTimeout(delayTimeout);
    }
    setTooltipGlobalOpen(false);
    setTooltipOpen(false);
  };

  useEffect(() => {
    if (!uiState.isMobile && !isUseChildTopPos) {
      return;
    }

    if (tooltipGlobalOpen) {
      return;
    }

    if (delayCloseTimeout) {
      clearTimeout(delayCloseTimeout);
    }

    setDelayCloseTimeout(
      setTimeout(() => {
        setTooltipOpen(false);
        setTooltipGlobalOpen(false);
      }, 300)
    ); // Adjust the delay time as needed (300ms in this example)
  }, [tooltipGlobalOpen]);

  return (
    <>
      {/* @ts-ignore */}
      <Tooltip
        ref={tooltipRef}
        html={
          <div
            style={
              isUseChildTopPos
                ? isOpenChildTopPosOnTop
                  ? { bottom: `0px` }
                  : { top: `${topPos}px` }
                : {}
            }
            className={`${
              isUseChildTopPos
                ? 'absolute left-1/2 transform -translate-x-[80%]'
                : ''
            } shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${parentClassName} ${
              type === 'light'
                ? 'bg-white border border-borderPrimary shadow-md'
                : 'bg-black border border-black text-grayLight '
            }`}
          >
            {message}
          </div>
        }
        followCursor={!openOnClick && followCursor}
        theme={type}
        duration={0}
        open={message ? tooltipOpen : false}
        arrow={isArrow}
        onShown={() => {
          setTooltipOpen(true);
          setTooltipGlobalOpen(true);
          setActionTooltipGlobalOpen(false);
        }}
        onHidden={() => {
          setTooltipOpen(false);
          setTooltipGlobalOpen(false);
        }}
        hideOnClick={false}
        delay={200}
        trigger={`${
          uiState.isMobile ? 'manual' : openOnClick ? 'click' : 'mouseenter'
        }`}
      >
        <div
          className={`${childrenClassName} inline`}
          onClick={openOnClick ? handleToggleTooltip : () => {}}
          onMouseEnter={openOnClick ? () => false : handleMouseEnter}
          onMouseLeave={openOnClick ? () => false : handleMouseLeave}
        >
          {children}
        </div>
      </Tooltip>
    </>
  );
};

export default TooltipCmp;
