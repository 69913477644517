import { Plus, Trash03 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Controller } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';

const TradeReferenceItem = ({
  errors,
  control,
  tradeReferenceArr,
  addRaw,
  removeRaw,
}: any) => (
  <div className="rounded-lg border border-utilityGray200 shadow-xs p-4 flex flex-col gap-y-4">
    {tradeReferenceArr.map((data: any, index: number) => (
      <div className="flex w-full gap-2 " key={index}>
        <div className="flex-1 flex flex-wrap max-sm:flex-col max-md:gap-y-3 sm:-mx-1.5">
          <Controller
            name={`tradeReferences.${index}.company_name`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Company name"
                inputName={`tradeReferences.${index}.company_name`}
                placeholder="Enter Name"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                required={true}
                onChangeFunc={onChange}
                errorText={
                  errors.tradeReferences?.[index]?.company_name?.message ?? null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
          <Controller
            name={`tradeReferences.${index}.city`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="City/ State"
                inputName={`tradeReferences.${index}.city`}
                placeholder="Enter city/state"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                required={true}
                onChangeFunc={onChange}
                errorText={
                  errors.tradeReferences?.[index]?.city?.message ?? null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
          <Controller
            name={`tradeReferences.${index}.phone_number`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Phone Number"
                inputName={`tradeReferences.${index}.phone_number`}
                placeholder="Enter number"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                required={true}
                onChangeFunc={onChange}
                errorText={
                  errors.tradeReferences?.[index]?.phone_number?.message ?? null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
          <Controller
            name={`tradeReferences.${index}.email`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Email"
                inputName="email"
                placeholder="Enter email"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                required={true}
                onChangeFunc={onChange}
                errorText={
                  errors.tradeReferences?.[index]?.email?.message ?? null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
        </div>
        <div className="flex justify-center items-end gap-2 h-[58px]">
          <ButtonCmp
            className="btn_primary cursor-pointer !bg-primary100 border-primary100  text-primary rounded lg:px-[9px] px-2"
            onClick={() => addRaw('trade_reference')}
          >
            <Plus className="w-4 h-4" />
          </ButtonCmp>
          {index > 0 ? (
            <ButtonCmp
              className="btn_primary cursor-pointer !bg-primary100 border-primary100  text-primary rounded lg:px-[9px] px-2"
              onClick={() => removeRaw(index, 'trade_reference')}
            >
              <Trash03 className="w-4 h-4" />
            </ButtonCmp>
          ) : (
            <span className="w-9 h-9">&nbsp;</span>
          )}
        </div>
      </div>
    ))}
  </div>
);

export default TradeReferenceItem;
