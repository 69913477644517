import React from 'react';

import PageSectionLayout from '../../components/PageSectionLayout';

const Forbidden = () => (
  <PageSectionLayout header={<></>}>
    <div>
      <div className="flex items-center">
        <h1 className="md:text-lg xxl:text-[28px] font-bold leading-none">
          403
        </h1>
        <span className="mx-3 text-grayText">Forbidden</span>
      </div>
      {`You are not able to see the details. Because You don't have permission.`}
    </div>
  </PageSectionLayout>
);

export default Forbidden;
