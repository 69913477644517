import { debounce } from 'lodash';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import { HiMiniMagnifyingGlass } from 'react-icons/hi2';
import { LuDownload, LuEye } from 'react-icons/lu';

import pdfIcon from '../../../../../assets/img/pdf-icon.png';
import CustomPagination from '../../../../../components/CustomPagination';
import CustomRowPerPage from '../../../../../components/CustomRowPerPage';
import Drawer from '../../../../../components/Drawer';
import SelectBox from '../../../../../components/SelectBox/SelectBox';
import {
  PDF_LANGUAGE_OPTIONS,
  PDF_LANGUAGE,
} from '../../../../../constants/common';
import { listPDF } from '../../../../../services/QuoteService';
import { downloadPdf } from '../../../../../utils/CommonFunctions';

const initParams = {
  search: '',
  sortDirection: 'DESC',
  sortBy: 'createdAt',
  page: 1,
  limit: 10,
};

const PDF = ({ isOpen, id, handleClose }: any) => {
  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');
  const [totals, setTotals] = useState(0);
  const [pdfs, setPdfs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectPdfLanguage, setSelectPdfLanguage] = useState<any>({
    value: 'English',
    label: 'English',
  });
  const [pdfLanguage, setPdfLanguage] = useState<any>('English');

  const [ignoreClose, setIgnoreClose] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      listPDF(id, params)
        .then((response: any) => {
          setPdfs(response.data);
          setTotals(response.total);
        })
        .finally(() => setIsLoading(false))
        .catch(console.error);
    }
  }, [isOpen, params]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) => setParams((old) => ({ ...old, ...{ debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'search') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  const handleSorting = (sortBy: string) => () => {
    const sortDirection = params.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setParams((old) => ({ ...old, sortDirection, sortBy }));
  };

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  const handleLimit = (limit: number) => {
    setParams((old) => ({ ...old, limit }));
  };

  const viewPdf = (pdfData: any) => () => {
    if (pdfData?.url && pdfData?.file) {
      let url: string = pdfData?.url + pdfData?.file;

      if (
        pdfLanguage === PDF_LANGUAGE.FRE &&
        pdfData?.frenchUrl &&
        pdfData?.frenchFile
      ) {
        url = pdfData?.frenchUrl + pdfData?.frenchFile;
      }
      window.open(url);
    }
  };

  const download = (pdfData: any) => () => {
    if (pdfData?.url && pdfData?.file) {
      let url: string = pdfData?.url + pdfData?.file;
      let name: string = `${pdfData?.name}.pdf`;

      if (
        pdfLanguage === PDF_LANGUAGE.FRE &&
        pdfData?.frenchUrl &&
        pdfData?.frenchFile
      ) {
        url = pdfData?.frenchUrl + pdfData?.frenchFile;
      }
      downloadPdf(url, name);
    }
  };

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        sortable: false,
      },
      {
        id: 'currency',
        name: 'Currency',
        sortable: false,
      },
      {
        id: 'createdAt',
        name: 'Creation Date',
        sortable: true,
      },
      {
        id: 'action',
      },
    ],
    []
  );

  return (
    <Drawer
      isOpen={isOpen}
      handleClose={handleClose}
      title="Invoicing History"
      classDrawer="max-w-4xl"
      ignoreClose={ignoreClose}
      setIgnoreClose={setIgnoreClose}
    >
      <div className="w-full flex items-center gap-2">
        <div
          className={
            process.env.REACT_APP_MODE !== 'production' ? 'w-3/4' : 'w-full'
          }
        >
          <div className="searchbox relative">
            <input
              name="search"
              type="text"
              className="w-full border-none bg-inputBg rounded-full px-5 py-3 text-grayDark sm:text-sm m-0 focus:outline-none focus:ring-0"
              placeholder="Search file"
              value={search}
              onChange={handleFilter}
            />
            <HiMiniMagnifyingGlass
              className="absolute top-3 right-3 text-grayText"
              size={20}
            />
          </div>
        </div>
        {process.env.REACT_APP_MODE !== 'production' && (
          <div className="w-1/4">
            <SelectBox
              isSearchable={false}
              isClearable={false}
              name="role"
              placeholder="Select Language"
              className="form_control"
              classNamePrefix="lang"
              labelClassName="form_label"
              value={selectPdfLanguage}
              options={PDF_LANGUAGE_OPTIONS}
              onChangeFunc={(e: any) => {
                setIgnoreClose(true);
                setSelectPdfLanguage(e);
                setPdfLanguage(e?.value);
              }}
            />
          </div>
        )}
      </div>
      <section className="min-h-full flex flex-col justify-between py-2 px-2">
        <div className="overflow-y-auto mt-5">
          <table className="table table-xs table-pin-rows table-pin-cols">
            <thead>
              <tr className="spot-quote-th-bg text-center">
                {headCells.map((head) => (
                  <th key={head.id} className="bg-white">
                    <div className="flex items-center">
                      <span>{head.name}</span>
                      {head.sortable && (
                        <span className="mx-2">
                          {params.sortBy === head.id ? (
                            params.sortDirection === 'ASC' ? (
                              <FaAngleUp
                                size={10}
                                className="cursor-pointer"
                                onClick={handleSorting(head.id)}
                              />
                            ) : (
                              <FaAngleDown
                                size={10}
                                className="cursor-pointer"
                                onClick={handleSorting(head.id)}
                              />
                            )
                          ) : (
                            <>
                              <FaAngleUp
                                size={10}
                                className="cursor-pointer"
                                onClick={handleSorting(head.id)}
                              />
                              <FaAngleDown
                                size={10}
                                className="cursor-pointer"
                                onClick={handleSorting(head.id)}
                              />
                            </>
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pdfs.length ? (
                pdfs.map((pdf: any, index: number) => (
                  <tr key={index} className="bg-white border-none">
                    <td className="py-2">
                      <div className="flex items-center">
                        <img
                          src={pdfIcon}
                          className="w-6 h-auto mr-3"
                          alt={pdf.name}
                          title={pdf.name}
                        />
                        <span className="text-base text-black">
                          {pdf.name}
                          {'.pdf'}
                        </span>
                      </div>
                    </td>
                    <td className="py-2 text-base text-black">
                      {pdf.pdfCurrency}
                    </td>
                    <td className="py-2 text-base text-black">
                      {moment(pdf.createdAt).format('DD MMM YYYY HH:mm:ss')}
                    </td>
                    <td className="py-2">
                      <div className="flex justify-end gap-3">
                        <div className="w-10 h-10 border flex items-center justify-center border-border300 cursor-pointer hover:bg-grayLight bg-white rounded-md">
                          <LuEye size={24} onClick={viewPdf(pdf)} />
                        </div>
                        <div className="w-10 h-10 border flex items-center justify-center border-border300 cursor-pointer hover:bg-grayLight bg-white rounded-md">
                          <LuDownload size={24} onClick={download(pdf)} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="bg-white border-none text-center">
                  <td className="py-2" colSpan={3}>
                    {isLoading ? 'Please wait...' : 'Data not found'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pdfs.length > 0 && (
          <div className="flex justify-between">
            <div className="">
              <CustomRowPerPage
                limit={params.limit}
                handleLimit={handleLimit}
              />
            </div>
            <div className="text-right">
              <CustomPagination
                recordsPerPage={params.limit}
                totalRecords={totals}
                currentPage={params.page}
                handlePagination={handlePagination}
              />
            </div>
          </div>
        )}
      </section>
    </Drawer>
  );
};
export default memo(PDF);
