import { Attachment01, FilePlus02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const CreditOnboarding = ({ data, setAction }: any) => (
  <>
    <td className="px-5 py-4">
      <span>{data.company_name}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.phone_number}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.email}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.credit_amount}</span>
    </td>
    <td className="px-5 py-4">
      <div className="flex gap-2">
        <Attachment01
          className="w-4 h-4 cursor-pointer"
          onClick={() =>
            setAction((old: any) => ({
              ...old,
              ...{ mode: 'add-attachment', id: data?.id },
            }))
          }
        />
        <FilePlus02
          className="w-4 h-4 cursor-pointer"
          onClick={() =>
            setAction((old: any) => ({
              ...old,
              ...{ mode: 'add-notes', id: data?.id },
            }))
          }
        />
      </div>
    </td>
  </>
);

export default CreditOnboarding;
