import { Mail02, Phone, Settings01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

import defaultImage from '../../assets/img/default-image.jpg';
import FreightcomIcon from '../../assets/img/frieghtcom.png';
import BadgeCmp from '../../components/BadgeCmp';
import ButtonCmp from '../../components/ButtonCmp';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  onError,
  usePhone,
} from '../../utils/CommonFunctions';

interface IProps {
  carrier: any;
  handleViewDetail: () => void;
  handleConfigureCarrier: () => void;
  handleCarrierConnection: () => void;
}

const serviceAreaList: any = { 1: 'CA - CA', 2: 'US - US', 3: 'CA - US' };

const CarrierGalleryCard = ({
  carrier,
  handleConfigureCarrier,
  handleCarrierConnection,
}: IProps) => {
  const { getFormatPhone } = usePhone();

  const showServiceArea = (serviceAreaString: string) => {
    if (serviceAreaString) {
      let selectedServiceArea = serviceAreaString.split(',');

      return <>{selectedServiceArea.map((val: any) => serviceAreaList[val])}</>;
    } else {
      return null;
    }
  };

  return (
    <ul className="card-wrap  xl:w-1/3 mdm:w-2/4 w-full">
      <li className="card-box shadow-xs flex flex-col h-full">
        <div className="xlm:p-5 p-4 flex-col gap-4 flex flex-1">
          <div className="gap-3 flex justify-between items-start">
            <div className="flex gap-2 items-center ">
              <div className="h-11 w-11 rounded-lg bg-white shadow-xs overflow-hidden relative flex-none border border-utilityGray200">
                {carrier.image ? (
                  <img
                    src={`${carrier.imageUrl}${carrier.image}`}
                    className="object-covert h-full w-full"
                    alt={carrier.name}
                    title={carrier.name}
                    onError={onError}
                  />
                ) : (
                  <img
                    className="object-cover h-full w-full"
                    src={defaultImage}
                    alt={carrier.name}
                    title={carrier.name}
                  />
                )}
                {!!carrier.isFreightcom && (
                  <div className="absolute bottom-0 right-0 w-4 h-4 flex justify-center items-center bg-white rounded-lg">
                    <img className="" src={FreightcomIcon} alt="FC"></img>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-grayLight900 text-xl font-semibold inline">
                  {carrier.name}
                  {/* <span
                                        className={`w-2 h-2 relative inline-block ms-[9px] ${carrier.apiStatus === "active" ? "bg-successSecondary" : "bg-red-600"} rounded-full border border-white`}
                                    /> */}
                </div>
                {/* <BadgeCmp style="modern" type="error" mainClassName="w-fit">
                                    Unavailable
                                </BadgeCmp> */}
                {/* <BadgeCmp style="modern" type="success" mainClassName="w-fit">
                                    Live
                                </BadgeCmp> */}

                {/* {(carrier.isFreightcom || carrier.isFreightcom === 1) && (
                                    <div className="flex items-center text-xs">
                                        <img className="w-4 h-4 relative rounded-full  border-[0.75px] border-black/[0.08]" src={FreightcomIcon} alt="FC" />
                                        <div className="pl-3 font-normal">Freightcom</div>
                                    </div>
                                )}
                                {(carrier.isBanyan || carrier.isBanyan === 1) && (
                                    <div className="flex items-center text-xs">
                                        <img className="w-4 h-4 relative rounded-full  border-[0.75px] border-black/[0.08]" src={BanyanIcon} alt="BANYAN" />
                                        <div className="pl-3 font-normal">Banyan</div>
                                    </div>
                                )}
                                {!carrier.isBanyan && !carrier.isFreightcom && (
                                    <div className="flex items-center text-sm">
                                        <img
                                            className="w-4 h-4 relative rounded-full  border-[0.75px] border-black/[0.08]"
                                            src={carrier.image ? `${carrier.imageUrl}${carrier.image}` : defaultImage}
                                            alt="CR"
                                        />
                                        <div className="pl-3 font-medium">Direct</div>
                                    </div>
                                )} */}
              </div>
            </div>
            {/* <div className="cursor-pointer flex text-[11px] items-center text-primary font-semibold flex-none" onClick={handleViewDetail}>
                            View Details <ArrowUpRight className="h-4 w-4" />
                        </div> */}

            {/* <a href="/#" className="justify-center items-center gap-1 flex">
                            <div className="text-gray500 text-[11px] font-medium leading-tight">freightcom.com</div>
                            <LinkExternal01 className="text-gray500 h-3 w-3" />
                        </a> */}
          </div>

          <div className="flex sm:flex-row sm:gap-0 gap-2.5 flex-col flex-wrap">
            {carrier.phone && (
              <div className="flex gap-1 items-center pe-5">
                <Phone className="h-4 w-4 text-gray500" />
                <div className="text-gray500 text-xs font-medium">
                  {getFormatPhone(carrier.phone)}
                </div>
              </div>
            )}
            {carrier.email && (
              <div className="flex gap-1 items-center">
                <Mail02 className="h-4 w-4 text-gray500" />
                <div className="text-gray500 text-xs font-medium break-all">
                  {carrier.email}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="justify-between items-center flex flex-wrap gap-2 xlm:px-5 px-4 py-2.5 border-t border-utilityGray200">
          <div className="text-textSecondary font-medium text-[10px] leading-[18px] rounded-md border border-borderPrimary shadow-xs px-1.5 py-0.5">
            {showServiceArea(carrier.serviceArea)}
          </div>
          <div className="flex gap-1.5 ">
            <span
              className="cursor-pointer text-gray500 flex items-center hidden"
              onClick={handleConfigureCarrier}
            >
              <Settings01 />
            </span>
            <TooltipCmp
              type="dark"
              message={
                <div className="text-left text-xs font-semibold p-1.5">
                  <p className="flex items-center gap-1 text-xs font-bold mb-1 capitalize ">
                    <span
                      className={`w-2 h-2 ${
                        carrier.apiStatus === 'active'
                          ? 'bg-successSecondary'
                          : 'bg-red-600'
                      } rounded-full`}
                    ></span>
                    {carrier.apiStatus === 'active' ? 'Live' : 'Unavailable'}
                  </p>
                  {carrier.apiLastCheck && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: carrier.apiLastCheck
                          ? getDateWithSuffixFormat(
                              getFormattedDate(
                                carrier.apiLastCheck,
                                `MMM Do - YYYY hh:mm:ss A`,
                                true
                              )
                            )
                          : '-',
                      }}
                    />
                  )}
                </div>
              }
            >
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName="lg:!py-[9px] !py-2 "
              >
                Connected
              </BadgeCmp>
            </TooltipCmp>

            <ButtonCmp
              className="btn_primary hidden"
              onClick={handleCarrierConnection}
            >
              Connect
            </ButtonCmp>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default CarrierGalleryCard;
