import React, { useEffect, useRef, useState } from 'react';

import CustomPagination from '../../../../components/CustomPagination';
import TeamMemberTableRow from '../TeamMemberTableRow';

interface IProps {
  headCells: any;
  memberList: any;
  isPaginateRecords?: boolean;
  recordsPerPage: number;
  handleActionType: (
    action: any,
    mode?: any,
    teamManager?: any,
    team?: any
  ) => () => void;
}

const TeamMembersTable = ({
  headCells,
  memberList,
  handleActionType,
  isPaginateRecords,
  recordsPerPage,
}: IProps) => {
  const dropdownRef = useRef(null); // Ref for the dropdown
  const [currentPage, setCurrentPage] = useState(1);
  const [isDisplayAction, setIsDisplayAction] = useState<boolean>(false);

  function useOutsideAlerter(ref: any, onOutsideClick: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          onOutsideClick();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, onOutsideClick]);
  }

  const closeDropdown = () => setIsDisplayAction(false);
  useOutsideAlerter(dropdownRef, closeDropdown);

  useEffect(() => {
    const handleEvent = () => setIsDisplayAction(false);

    if (isDisplayAction) {
      window.addEventListener('scroll', handleEvent, true);
      window.addEventListener('resize', handleEvent);
    }

    return () => {
      window.removeEventListener('scroll', handleEvent, true);
      window.removeEventListener('resize', handleEvent);
    };
  }, [isDisplayAction]);

  return (
    <>
      <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
        <table className="w-full text-sm text-left text-nowrap whitespace-nowrap">
          <thead className="text-xs text-grayLight600 font-medium  leading-[18px]  border-b border-utilityGray200">
            <tr className="[&>:nth-child(2)]:w-32 [&>:nth-child(3)]:w-72 [&>:nth-child(4)]:w-16 [&>:nth-child(5)]:w-10 ">
              {headCells.map((head: any) => (
                <th
                  scope="col"
                  className="px-5 py-3 bg-gray50 [&:first-child]:rounded-t-xl [&:last-child]:rounded-r-xl font-medium "
                  key={head.id}
                >
                  <div className="flex items-center">
                    <span>{head.name}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {memberList.length > 0 ? (
              <>
                {isPaginateRecords
                  ? memberList
                      .slice(
                        (currentPage - 1) * recordsPerPage,
                        (currentPage - 1) * recordsPerPage + recordsPerPage
                      )
                      .map((memberRecord: any) => (
                        <TeamMemberTableRow
                          key={memberRecord.firstName}
                          memberRecord={memberRecord}
                          handleActionType={handleActionType}
                        />
                      ))
                  : memberList.map((memberRecord: any) => (
                      <TeamMemberTableRow
                        key={memberRecord.firstName}
                        memberRecord={memberRecord}
                        handleActionType={handleActionType}
                      />
                    ))}
              </>
            ) : (
              <tr>
                <td className=" py-8" colSpan={100}></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isPaginateRecords && memberList.length > 0 && (
        <div className="w-full z-[1] relative hidden">
          <CustomPagination
            recordsPerPage={recordsPerPage}
            totalRecords={memberList?.length}
            currentPage={currentPage}
            handlePagination={setCurrentPage}
          />
        </div>
      )}
      {/* <ul className="flex flex-wrap -m-3">
        {memberList.length > 0 ? (
          <>
            {isPaginateRecords
              ? memberList
                  .slice(
                    (currentPage - 1) * recordsPerPage,
                    (currentPage - 1) * recordsPerPage + recordsPerPage
                  )
                  .map((memberRecord: any) => (
                    <TeamMemberTableRow
                      key={memberRecord.firstName}
                      memberRecord={memberRecord}
                      handleActionType={handleActionType}
                    />
                  ))
              : memberList.map((memberRecord: any) => (
                  <TeamMemberTableRow
                    key={memberRecord.firstName}
                    memberRecord={memberRecord}
                    handleActionType={handleActionType}
                  />
                ))}
          </>
        ) : (
          <tr>
            <td className=" py-8" colSpan={100}></td>
          </tr>
        )}
      </ul> */}
    </>
  );
};

export default TeamMembersTable;
