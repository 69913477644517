import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import avatar from 'src/assets/img/Avatar1.png';
import pdf from 'src/assets/img/pdf-icon.png';
import TooltipCmp from 'src/components/TooltipCmp';

const LoadInsuranceFilesLoading = () => (
  <>
    <td className="px-5 py-4 w-[33.33%] max-w-96">
      <div className="flex items-center gap-3 ">
        <div className="w-8 h-10 flex-none custom-loading">
          <img src={pdf} className="w-full h-full object-contain" />
        </div>

        <div className="truncate custom-loading">
          <h6 className="text-grayLight900 font-medium truncate">
            Tech requirements.pdf
          </h6>
          <span className="font-normal text-grayLight600">200 KB</span>
        </div>
      </div>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">Jan 4, 2024</span>
    </td>

    <td className="px-5 py-4 max-w-96 w-[33.33%]">
      <div className="flex items-center gap-3">
        <div className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary  flex-none custom-loading">
          <img src={avatar} className="h-full w-full" />
          {/* <label className="h-full w-full flex items-center justify-center text-sm">
                        WA
                      </label> */}
        </div>
        <div className="custom-loading">
          <TooltipCmp message={'Kate Morrison'}>
            <p className="w-32 truncate text-grayLight900 font-medium">
              Kate Morrison
            </p>
          </TooltipCmp>
          <span className="font-normal">Sales Rep.</span>
        </div>
      </div>
    </td>
  </>
);

export default LoadInsuranceFilesLoading;
