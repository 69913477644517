import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const FeedbackLoading = () => (
  <>
    <td>
      <span className="custom-loading">01</span>
    </td>
    <td>
      <span className="custom-loading">Jun 11, 2024 03:26 PM</span>
    </td>
    <td>
      <span className="custom-loading">Kishan Dodiya</span>
    </td>
    <td>
      <BadgeCmp
        style="modern"
        type="info"
        isHidePillDot
        mainClassName="custom-loading"
      >
        SQ-NK-100214
      </BadgeCmp>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">dsf ds fdsf sdfd</span>
    </td>
    <td className="px-5 py-4">
      <div className="text-left custom-loading custom-loading-image">-</div>
    </td>
    <td className="px-5 py-4 cursor-pointer">
      <BadgeCmp style="modern" mainClassName="text-nowrap custom-loading">
        solved
      </BadgeCmp>
    </td>
    <td className="px-5 py-4">
      <div className="dropdown dropdown-bottom dropdown-end custom-loading">
        124
      </div>
    </td>
  </>
);

export default FeedbackLoading;
