import mapboxgl, { SourceSpecification } from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import SearchBox from 'src/components/SearchBox';
import { GetTopCustomerList } from 'src/services/CustomerService';
import { getPopulationData } from 'src/services/GeographyService';
import { getTime } from 'src/utils/CommonFunctions';

import defaultImage from '../../../assets/img/default-image.jpg';

const MapBox = ({
  setHasSearched,
  setSearchResult,
  isDisplayCustomers,
  setIsDisplayDetail,
  setCustomers,
}: any) => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  // const googleMapsApiKey = window?.GOOGLE_API_KEY;

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  // const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-95, 55],
        zoom: 4,
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());

      mapRef.current.on('load', () => {
        setMapLoaded(true);
        const layers = mapRef.current?.getStyle()?.layers;
        const labelLayer = layers?.find(
          (layer: any) => layer.type === 'symbol' && layer.layout['text-field']
        );

        if (labelLayer && mapRef.current) {
          mapRef.current.addLayer(
            {
              id: 'add-3d-buildings',
              source: 'composite',
              'source-layer': 'building',
              filter: ['==', 'extrude', 'true'],
              type: 'fill-extrusion',
              minzoom: 15,
              paint: {
                'fill-extrusion-color': '#aaa',
                'fill-extrusion-height': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'height'],
                ],
                'fill-extrusion-base': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'min_height'],
                ],
                'fill-extrusion-opacity': 0.6,
              },
            },
            labelLayer.id
          );
        }

        if (mapRef.current) {
          mapRef.current.addSource('points', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [-77.03238901390978, 38.913188059745586],
                  },
                  properties: {
                    title: 'Mapbox DC',
                  },
                },
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [-122.414, 37.776],
                  },
                  properties: {
                    title: 'Mapbox SF',
                  },
                },
              ],
            },
          });
        }
      });
    }
  }, []);

  // Function to calculate distance between two points using Haversine formula
  const getDistance = (
    point1: [number, number],
    point2: [number, number]
  ): number => {
    const [lon1, lat1] = point1;
    const [lon2, lat2] = point2;
    const R = 6371; // Earth's radius in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  // Function to get population from Google Places API using latitude and longitude
  const getPopulation = async (lat: number, lng: number) => {
    try {
      const apiParams = { lat, lng };

      try {
        const population = await getPopulationData(apiParams)
          .then((response) => response?.data?.population || 0)
          .catch((error) => {
            console.error('Error fetching population data:', error);

            return 0; // Return 'Unknown' on error
          });

        return population;
      } catch (error) {
        console.error('Error in getPopulation function:', error);

        return 0;
      }
    } catch (error) {
      console.error('Error fetching population data:', error);

      return 'Unknown';
    }
  };

  // Function to geocode an address to coordinates
  const geocodeAddress = async (
    address: string
  ): Promise<[number, number, string]> => {
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    const coordinates = json.features[0].center as [number, number];
    const placeId = json.features[0].id as string;

    return [coordinates[0], coordinates[1], placeId];
  };

  // Function to find cities along the route within a specified radius
  const getCitiesAlongRoute = async ({
    route,
    radius,
  }: {
    route: [number, number][];
    radius: number;
  }) => {
    const cities: any = [];
    const checkedCities = new Set();

    const numSamples = Math.min(500, route.length);
    const sampleInterval = Math.max(1, Math.floor(route.length / numSamples));

    for (let i = 0; i < route.length; i += sampleInterval) {
      const [lon, lat] = route[i];

      try {
        const query = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?types=place&limit=5&access_token=${mapboxgl.accessToken}`
        );
        const json = await query.json();

        if (json.features && json.features.length > 0) {
          for (const feature of json.features) {
            const cityName = feature.text;
            const fullName = feature.place_name;
            const cityCoords = feature.center;

            if (!checkedCities.has(fullName)) {
              const straightLineDistance = getDistance(route[i], cityCoords);

              if (straightLineDistance <= radius) {
                const population = await getPopulation(lat, lon);
                const cityInfo = {
                  name: cityName,
                  fullName: fullName,
                  distance: straightLineDistance,
                  population: population !== null ? population : 'Unknown',
                  coordinates: cityCoords,
                };

                cities.push(cityInfo);
                checkedCities.add(fullName);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching place data:', error);
      }
    }

    const sortedCities = cities
      .sort((a: any, b: any) => (b.population || 0) - (a.population || 0))
      .slice(0, 10);

    return sortedCities;
  };

  // Handle the search functionality
  const handleSearch = useCallback(
    async (from: string, to: string, radius: number) => {
      if (!mapLoaded || !mapRef.current) {
        console.warn('Map not fully loaded yet');
        setSearchResult({
          status: 'error',
          error: 'Map not fully loaded yet. Please try again.',
        });

        return;
      }

      try {
        setHasSearched(true);
        setIsDisplayDetail(true);
        setIsDisplayDetail(true);
        setSearchResult({ status: 'loading', message: 'Fetching route...' });

        const [fromLongitude, fromLatitude] = await geocodeAddress(from);
        const [toLongitude, toLatitude] = await geocodeAddress(to);

        if (mapRef.current?.getLayer('route')) {
          mapRef.current.removeLayer('route');
        }

        if (mapRef.current?.getSource('route')) {
          mapRef.current.removeSource('route');
        }

        const query = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${fromLongitude},${fromLatitude};${toLongitude},${toLatitude}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`,
          { method: 'GET' }
        );
        const json = await query.json();

        if (!json.routes || json.routes.length === 0) {
          throw new Error('No route found between the specified locations.');
        }

        const data = json.routes[0];
        const route = data.geometry.coordinates;
        const geojson: SourceSpecification = {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: route,
            },
          },
        };

        mapRef.current?.addSource('route', geojson);
        mapRef.current?.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#444CE7',
            'line-width': 3,
            'line-opacity': 0.75,
          },
        });

        const bounds = new mapboxgl.LngLatBounds(route[0], route[0]);

        for (const coord of route) {
          bounds.extend(coord);
        }
        mapRef.current?.fitBounds(bounds, { padding: 50 });

        const cities = await getCitiesAlongRoute({ route, radius });

        setSearchResult({
          status: 'success',
          from,
          to,
          distance: `${Math.round(data.distance / 1000)} km`,
          duration: `${getTime(Math.round(data.duration))}`,
          cities,
          radius,
        });
      } catch (error: any) {
        console.error('Error in handleSearch:', error);
        setSearchResult({
          status: 'error',
          error: `Failed to fetch route: ${error.message}`,
        });
      }
    },
    [mapLoaded]
  );

  const getCustomer = async () => {
    // setIsCustomerLoading(true);

    try {
      setHasSearched(false);
      setSearchResult(null);
      const result = await GetTopCustomerList();

      if (result.data && result.data.length) {
        const filteredData = result.data.filter((data: any) => !data.isDeleted);
        setCustomers(filteredData);

        for (const customer of filteredData) {
          const cityCoords = await geocodeAddress(customer.fullAddress);

          const el = document.createElement('div');
          el.className = 'marker';
          const parentDiv = document.createElement('div');
          parentDiv.className = 'parent-marker'; // Assign a class for additional styling

          const csutomerImageDiv = document.createElement('div');
          csutomerImageDiv.className = 'customer-image'; // Assign a class for additional styling

          // Set marker image based on customer data
          if (customer.image) {
            el.style.backgroundImage = `url(${
              customer.imageUrl + customer.image
            })`;
          } else {
            el.style.backgroundImage = `url(${defaultImage})`;
          }
          csutomerImageDiv.appendChild(el); // Append the marker to the parent div
          parentDiv.appendChild(csutomerImageDiv); // Append the marker to the parent div

          // Create marker
          new mapboxgl.Marker(parentDiv)
            .setLngLat([cityCoords[0], cityCoords[1]])
            .addTo(mapRef.current!);

          // Add click event listener to the marker
          // marker.getElement().addEventListener('click', (event: any) => {});

          // Add zoom out functionality to the zoom out button
          document
            .getElementById(`zoomOutButton_${customer.id}`)
            ?.addEventListener('click', function (event) {
              event.preventDefault(); // Prevent default link behavior
              // Assuming you have a map object, apply zoom out
              if (mapRef?.current)
                mapRef?.current.setZoom(mapRef?.current.getZoom() + 2); // Adjust this method according to your map library
            });

          document
            .getElementById(`backButton_${customer.id}`)
            ?.addEventListener('click', function (event) {
              event.preventDefault(); // Prevent default link behavior
              let ele = document.getElementById(`customer_${customer.id}`);

              if (ele) {
                ele.classList.add('d-none'); // Toggle the 'd-none' class to show/hide the card box
              }
            });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setIsCustomerLoading(false);
    }
  };

  useEffect(() => {
    if (isDisplayCustomers) {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
      initializeMap();
      getCustomer();
    } else {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
      initializeMap();
    }
  }, [isDisplayCustomers]);

  return (
    <div className="relative h-full w-full geography-map">
      <div
        id="map"
        ref={mapContainerRef}
        style={{ width: '100%', height: '100%' }}
      ></div>
      <SearchBox onSearch={handleSearch} />
    </div>
  );
};

export default MapBox;
