import React, { useEffect, useMemo, useRef, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import {
  listCustomerSalesRepHistory,
  removeSalesRepHistory,
} from 'src/services/CustomerService';
import WalToast from 'src/utils/WalToast';

import AddAllocationHistoryModal from './AddAllocationHistoryModal';
import AllocationHistoryLoaderRow from './AllocationHistoryLoaderRow';
import AllocationHistoryRow from './AllocationHistoryRow';
import EditAllocationHistoryModal from './EditAllocationHistoryModal';

const initParams: any = {
  sortType: 'desc',
  sortField: 'startDate',
  page: 1,
  limit: 25,
};

interface IProps {
  customerId: string;
}

const CustomerDetailAllocationHistoryTab = ({ customerId }: IProps) => {
  const [params, setParams] = useState(initParams);
  const [orders, setOrders] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [
    isCustomerSalesRepHistoryLoading,
    setIsCustomerSalesRepHistoryLoading,
  ] = useState(true);
  const [editAllocationHistoryModal, setEditAllocationHistoryModal] =
    useState<any>(undefined);
  const [deleteAllocationHistoryModal, setDeleteAllocationHistoryModal] =
    useState<any>(undefined);
  const [
    isCustomerSalesRepHistoryDeleteLoading,
    setIsCustomerSalesRepHistoryDeleteLoading,
  ] = useState(false);

  const salesRepUpdateButtonRef = useRef<any>(null);
  const [isAddSalesRepModal, setIsAddSalesRepModal] = useState(false);
  const [isRefresh, setIsRefresh] = useState(true);

  const headCells = useMemo(
    () => [
      {
        id: 'salesRepFullName',
        name: 'Sales Rep.',
        sortable: true,
      },
      {
        id: 'startDate',
        name: 'Start  Date',
        sortable: true,
        rowClassName: '!pr-2',
      },
      {
        id: 'endDate',
        name: 'End Date',
        sortable: true,
        rowClassName: '!pr-2',
      },
      {
        id: 'orderCount',
        name: 'Total orders',
        sortable: true,
        rowClassName: '!pr-2',
      },
      {
        id: 'totalMargin',
        name: 'Total GP',
        sortable: true,
        rowClassName: '!pr-0',
      },
      {
        id: 'averageMarginPercentage',
        name: 'GP %',
        sortable: true,
        rowClassName: '!pr-2 ',
      },
      {
        id: 'averageOrderValue',
        name: 'Avg. order value',
        sortable: true,
        rowClassName: '!pr-2 !pl-2',
      },
      {
        id: 'action',
        name: '',
        sortable: false,
        rowClassName: '!pl-2',
      },
    ],
    []
  );

  useEffect(() => {
    setIsRefresh(true);
  }, [params]);

  useEffect(() => {
    if (!isRefresh) {
      return;
    }

    setIsCustomerSalesRepHistoryLoading(true);
    setOrders([]);
    setTotal(0);

    const allocationHistoryApiController = new AbortController();
    const allocationHistoryApiSignal = allocationHistoryApiController.signal;

    listCustomerSalesRepHistory(customerId, params, allocationHistoryApiSignal)
      .then((response: any) => {
        setOrders(response.data ?? []);
        setTotal(response.total ?? 0);
        setIsCustomerSalesRepHistoryLoading(false);
        setIsRefresh(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerSalesRepHistoryLoading(false);
        setIsRefresh(false);
      });

    return () => {
      allocationHistoryApiController.abort();
    };
  }, [params, isRefresh]);

  const handleDeleteConfirmationAction = (status = false) => {
    if (status) {
      setIsCustomerSalesRepHistoryDeleteLoading(true);
      removeSalesRepHistory(deleteAllocationHistoryModal?.id)
        .then((response: any) => {
          WalToast.success(response?.message);
          setDeleteAllocationHistoryModal(undefined);
          setIsRefresh(true);
        })
        .finally(() => {
          setIsCustomerSalesRepHistoryDeleteLoading(false);
        })
        .catch(console.log);
    } else {
      setDeleteAllocationHistoryModal(undefined);
    }
  };

  return (
    <>
      <div className="w-full bg-white rounded-xl border border-utilityGray200 shadow flex mt-9">
        <div className="w-full flex-col flex">
          <div className="table-top-header flex-wrap ">
            <div className="table-left-wrap flex-none max-mdm:flex-[0_0_100%]">
              <div className="table-title-wrap flex-wrap">
                <h5 className="table-title">Allocation History</h5>

                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={`${
                    isCustomerSalesRepHistoryLoading ? 'custom-loading' : ''
                  }`}
                >
                  {total} History
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View and manage this customers sales allocation history
              </p>
            </div>
            <div className="relative" id="salesRepList">
              <ButtonCmp
                reference={salesRepUpdateButtonRef}
                className="btn-outline-primary"
                onClick={() => setIsAddSalesRepModal(true)}
              >
                Update
              </ButtonCmp>
            </div>
          </div>
          <div className="w-full border-t border-gray100 flex flex-col flex-1">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
              <TableCmp
                headCells={headCells}
                params={params}
                setParams={setParams}
                tableDataArr={orders}
                TableLoaderRowCmp={AllocationHistoryLoaderRow}
                TableRowCmp={AllocationHistoryRow}
                isTableDataLoading={isCustomerSalesRepHistoryLoading}
                tableRowCmpProps={{
                  handleEditAllocationHistory: (data: any) => {
                    setEditAllocationHistoryModal(data);
                  },
                  handleDeleteAllocationHistory: (data: any) => {
                    setDeleteAllocationHistoryModal(data);
                  },
                }}
                numberOfSkeletonRows={4}
              />
            </div>
            {!isCustomerSalesRepHistoryLoading && !orders?.length && (
              <NotFoundUI
                title="No orders Found"
                desc="There are no orders found."
                containerClassName="min-h-[200px] my-0 flex-1 !h-auto"
              />
            )}
          </div>
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={total}
            currentPage={params.page}
            handlePagination={(page: number) => {
              setParams((old: any) => ({ ...old, page }));
            }}
          />
        </div>
      </div>

      {editAllocationHistoryModal && (
        <EditAllocationHistoryModal
          editAllocationHistoryModal={editAllocationHistoryModal}
          handleClose={(status: boolean) => {
            setEditAllocationHistoryModal(undefined);

            if (status) {
              setIsRefresh(true);
            }
          }}
        />
      )}

      {isAddSalesRepModal && customerId && (
        <AddAllocationHistoryModal
          handleClose={() => setIsAddSalesRepModal(false)}
          setIsRefresh={setIsRefresh}
          customerId={customerId}
        />
      )}

      {deleteAllocationHistoryModal && (
        <CommonModal
          title="Remove Customer Sales Rep Allocation History"
          titleDesc="This action will remove sales rep allocation history and unassign orders."
          handleClose={() =>
            isCustomerSalesRepHistoryDeleteLoading
              ? false
              : handleDeleteConfirmationAction(false)
          }
          secondaryBtnOnClick={() => handleDeleteConfirmationAction(false)}
          dangerBtnText="Remove"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleDeleteConfirmationAction(true)}
          primaryBtnLoading={isCustomerSalesRepHistoryDeleteLoading}
          primaryBtnDisabled={isCustomerSalesRepHistoryDeleteLoading}
          secondaryBtnDisabled={isCustomerSalesRepHistoryDeleteLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}
    </>
  );
};

export default CustomerDetailAllocationHistoryTab;
