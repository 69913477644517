import {
  CheckCircleBroken,
  PackagePlus,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
// import ButtonCmp from 'src/components/ButtonCmp';
import CheckBox from 'src/components/CheckBox';
import { CURRENCY } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

import ServiceDetail from './ServiceDetail';

interface ReqQuotedRowProps {
  data: any;
  requestSpotQuote: any;
  currency: string;
  currentUser: any;
  handleActionType: any;
  selectedCarrierRates: any;
  allChecked: boolean;
  setAllChecked: any;
}

const ReqQuotedRow: React.FC<ReqQuotedRowProps> = ({
  data,
  // requestSpotQuote,
  currency,
  currentUser,
  handleActionType,
  selectedCarrierRates,
  allChecked,
  setAllChecked,
}) => (
  <>
    <td className="px-5 py-4">
      <div className="flex items-center">
        {data.image ? (
          <img
            src={`${data.imageUrl}${data.image}`}
            className="w-8 h-8 rounded-md border-[0.75px] border-black/[0.08]"
            alt={data.name}
            title={data.name}
            onError={onError}
          />
        ) : (
          <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
            {getShortName(`${data.name}`)}
          </div>
        )}

        <div className="pl-3 w-[calc(100%_-_58px)]">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            {data.name}
          </h6>
          <p className="text-grayLight600 font-normal leading-tight">
            <span className="inline align-middle">{data.email}</span>
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4">-</td>
    <td className="px-5 py-4">
      <span
        dangerouslySetInnerHTML={{
          __html: data.projectedDeliveryDate
            ? getDateWithSuffixFormat(
                moment.utc(data.projectedDeliveryDate).format(`MMM Do YYYY`)
              )
            : '-',
        }}
      />
    </td>
    <td className="px-5 py-4">
      {data.totalCharge === 0
        ? '-'
        : currency === CURRENCY.CAD
        ? `$${data.totalCharge.toFixed(2)}`
        : `$${data.totalChargeUSD.toFixed(2)}`}
    </td>
    {data.additionalCharges > 0 ? (
      <td className="px-5 py-4">
        <ActionTooltip
          openOnHover={true}
          tooltipPosition="center"
          isOpenChildTopPosOnTop={true}
          type="dark"
          message={<ServiceDetail carrierServices={data} />}
        >
          {data.additionalCharges === 0
            ? '-'
            : currency === CURRENCY.CAD
            ? `$${getFormattedNumber(data.additionalCharges)}`
            : `$${getFormattedNumber(data.additionalChargesUSD)}`}
        </ActionTooltip>
      </td>
    ) : (
      <td className="px-5 py-4">
        {data.additionalCharges === 0
          ? '-'
          : currency === CURRENCY.CAD
          ? `$${getFormattedNumber(data.additionalCharges)}`
          : `$${getFormattedNumber(data.additionalChargesUSD)}`}
      </td>
    )}
    <td className="px-5 py-4">
      {data.carrierQuoteId
        ? currency === CURRENCY.CAD
          ? `$${data.finalCharge.toFixed(2)}CAD`
          : `$${data.finalChargeUSD.toFixed(2)}USD`
        : '-'}
    </td>
    {/* <td className="px-5 py-4">
      {process.env.REACT_APP_MODE !== 'production' &&
        (currentUser.doNotAskSpotQuote ? (
          <ButtonCmp
            type="submit"
            className={`btn_secondary_black`}
            onClick={() =>
              requestSpotQuote(data.id, currentUser.doNotAskSpotQuote)
            }
            disabled={data.isSpotRequestSent}
          >
            {data.isSpotRequestSent ? 'Request Sent' : 'Request Spot'}
          </ButtonCmp>
        ) : data.isSpotRequestSent ? (
          <ButtonCmp
            className={`btn-outline-primary w-full`}
            onClick={() => handleActionType('addQuoteDetail', '', data)()}
          >
            Add Quote Details
          </ButtonCmp>
        ) : (
          <ButtonCmp
            type="submit"
            className={`btn_secondary_black w-full ${
              data.isSpotRequestSent ? 'bg-opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={() =>
              handleActionType('spotQuoteConfirmation', 'view', data)()
            }
            disabled={data.isSpotRequestSent}
          >
            {data.isSpotRequestSent ? 'Request Sent' : 'Request Spot'}
          </ButtonCmp>
        ))}
    </td> */}
    <td className="px-5 py-4">
      {data?.spotQuoteEmail &&
        process.env.REACT_APP_MODE !== 'production' &&
        (currentUser.doNotAskSpotQuote ? (
          <CheckBox
            checked={
              selectedCarrierRates.some((form: any) => form.id === data.id) ||
              allChecked
            }
            // onChangeFunc={handleActionType('spotquoteConfirmation', 'view', data)}
            onChangeFunc={() => {}}
            parentClassName=""
          />
        ) : data.isSpotRequestSent ? (
          <div className="flex gap-2">
            <PackagePlus
              onClick={() => handleActionType('addQuoteDetail', '', data)()}
            />
            <CheckCircleBroken className="text-success" />
          </div>
        ) : (
          <CheckBox
            checked={
              selectedCarrierRates.some((form: any) => form.id === data.id) ||
              allChecked
            }
            // onChangeFunc={handleActionType('spotquoteConfirmation', 'view', data)}
            onChangeFunc={(e: any) => {
              if (!e.target.checked) {
                setAllChecked(false);
              }
            }}
            parentClassName=""
          />
        ))}
    </td>
  </>
);

export default ReqQuotedRow;
