import React from 'react';

const BusinessOnboarding = ({ data }: any) => (
  <>
    <td className="px-5 py-4">
      <span>{data.city}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.state}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.postal}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.country}</span>
    </td>
  </>
);

export default BusinessOnboarding;
