import { XClose } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';

interface IProps {
  handleClose: any;
}

const DeclineModal = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Decline Credit Request'}
    titleDesc={'Provide a Reason for declining the request.'}
    handleClose={() => handleClose(false)}
    headerIcon={<XClose />}
    size={'max-w-[688px]'}
    isOverflow={true}
    modalClassName=""
    primaryBtnText="Confirm"
    secondaryBtnText="Cancel"
  >
    <div className="w-full p-5 mb-1.5">
      <InputText
        placeholder="Enter Explanation"
        label="Decline Explanation"
        labelClassName="block mb-1.5"
        className="min-h-[100px]"
        asTextarea={'textarea'}
      />
    </div>
  </CommonModal>
);

export default DeclineModal;
