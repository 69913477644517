import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { BasicContext } from 'src/context/BasicContext';

import { APP_FULL_NAME } from '../constants/common';
import { ROUTES } from '../constants/routes';
import { AuthContext } from '../context/AuthContext';

const PublicRoute = ({ component: Component, title, ...props }: any) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { appearance } = useContext(BasicContext);
  const { isIgnoreAuthentication } = props;

  useEffect(() => {
    document.title = `${title} - ${appearance.web_title ?? APP_FULL_NAME}`;
    const favicon = document.getElementById('favicon') as HTMLLinkElement;

    if (favicon) {
      favicon.href = appearance.faviconUrl + appearance.favicon;
    }
  }, [title]);

  return isAuthenticated && !isIgnoreAuthentication ? (
    <Navigate to={ROUTES.HOME} />
  ) : (
    <Component {...props} />
  );
};

export default PublicRoute;
