import mapboxgl from 'mapbox-gl';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import SearchBox from 'src/components/SearchBox';
import { BasicContext } from 'src/context/BasicContext';
import { GetTopCustomerList } from 'src/services/CustomerService';
import { getPopulationData } from 'src/services/GeographyService';
import { getTime } from 'src/utils/CommonFunctions';

import defaultImage from '../../../assets/img/default-image.jpg';

const TrimbleMap = ({
  setHasSearched,
  setSearchResult,
  isDisplayCustomers,
  setIsDisplayDetail,
  setCustomers,
}: any) => {
  const { trimbleKey } = useContext(BasicContext);

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const [mapLoaded, setMapLoaded] = useState(false);

  const initializeMap = () => {
    window.TrimbleMaps.APIKey = trimbleKey;
    const center = [-106.346771, 56.130366];
    mapRef.current = new window.TrimbleMaps.Map({
      container: mapContainerRef.current, // container id
      style: window.TrimbleMaps.Common.Style.TRANSPORTATION, // hosted style id
      center: center, // starting position
      zoom: 4, // starting zoom
      pitch: 60,
      antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
    });

    const customLayer: any = {
      id: '3d-model',
      type: 'custom',
      renderingMode: '3d',
      render: function () {},
    };

    if (mapRef.current) {
      mapRef.current.on('style.load', function () {
        if (mapRef.current && mapRef.current.getLayer(customLayer.id) == null) {
          mapRef.current.addLayer(customLayer);
        }
      });
      mapRef.current.on('load', function () {});
    }

    setMapLoaded(true);
  };

  // Function to calculate distance between two points using Haversine formula
  const getDistance = (
    point1: [number, number],
    point2: [number, number]
  ): number => {
    const [lon1, lat1] = point1;
    const [lon2, lat2] = point2;
    const R = 6371; // Earth's radius in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  // Function to get population from Google Places API using latitude and longitude
  const getPopulation = async (lat: number, lng: number) => {
    try {
      const apiParams = { lat, lng };

      try {
        const population = await getPopulationData(apiParams)
          .then((response) => response?.data?.population || 0)
          .catch((error) => {
            console.error('Error fetching population data:', error);

            return 0; // Return 'Unknown' on error
          });

        return population;
      } catch (error) {
        console.error('Error in getPopulation function:', error);

        return 0;
      }
    } catch (error) {
      console.error('Error fetching population data:', error);

      return 'Unknown';
    }
  };

  // Function to geocode an address to coordinates
  const geocodeAddress = async (
    address: string
  ): Promise<[number, number, string]> => {
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    const coordinates = json.features[0].center as [number, number];
    const placeId = json.features[0].id as string;

    return [coordinates[0], coordinates[1], placeId];
  };

  // Function to find cities along the route within a specified radius

  const getCitiesAlongRoute = async ({
    route,
    radius,
  }: {
    route: [number, number][];
    radius: number;
  }) => {
    const cities = [];
    const checkedCities = new Set();
    const numSamples = Math.min(100, route.length);

    const sampleInterval = Math.max(1, Math.floor(route.length / numSamples));

    for (let i = 0; i < route.length; i += sampleInterval) {
      const [lon, lat] = route[i];

      try {
        const query = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?types=place&limit=5&access_token=${mapboxgl.accessToken}`
        );
        const json = await query.json();

        if (json.features && json.features.length > 0) {
          for (const feature of json.features) {
            const cityName = feature.text;
            const fullName = feature.place_name;
            const cityCoords = feature.center;

            if (!checkedCities.has(fullName)) {
              const straightLineDistance = getDistance(route[i], cityCoords);

              if (straightLineDistance <= radius) {
                const population = await getPopulation(lat, lon);
                const cityInfo = {
                  name: cityName,
                  fullName: fullName,
                  distance: straightLineDistance,
                  population: population !== null ? population : 'Unknown',
                  coordinates: cityCoords,
                };
                cities.push(cityInfo);
                checkedCities.add(fullName);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching place data:', error);
      }
    }

    const sortedCities = cities
      .sort((a, b) => (b.population || 0) - (a.population || 0))
      .slice(0, 10);

    return sortedCities;
  };

  // Handle the search functionality
  const handleSearch = useCallback(
    async (from: string, to: string, radius: number) => {
      try {
        setHasSearched(true);
        setIsDisplayDetail(true);
        setIsDisplayDetail(true);
        setSearchResult({ status: 'loading', message: 'Fetching route...' });

        const [fromLongitude, fromLatitude] = await geocodeAddress(from);
        const [toLongitude, toLatitude] = await geocodeAddress(to);

        const response = await fetch(
          `https://pcmiler.alk.com/apis/rest/v1.0/Service.svc/route/routeReports?stops=${fromLongitude},${fromLatitude};${toLongitude},${toLatitude}&authToken=${trimbleKey}&reports=RoutePath`
        );
        const json = await response.json();

        if (!json || json.length === 0) {
          throw new Error('No route found between the specified locations.');
        }

        const data = json[0];
        const route = data.geometry.coordinates[0];

        const myRoute = new window.TrimbleMaps.Route({
          routeId: 'myRoute',
          stops: [
            new window.TrimbleMaps.LngLat(fromLongitude, fromLatitude),
            new window.TrimbleMaps.LngLat(toLongitude, toLatitude),
          ],
          routeColor: '#444CE7', // optional routeColor
        });

        if (mapRef.current) {
          myRoute.addTo(mapRef.current);
        }

        const cities = await getCitiesAlongRoute({ route, radius });
        setSearchResult({
          status: 'success',
          from,
          to,
          distance: `${Math.round(data.TDistance)} miles`,
          duration: `${getTime(Math.round(data.TMinutes))}`,
          cities,
          radius,
        });
      } catch (error: any) {
        console.error('Error in handleSearch:', error);
        setSearchResult({
          status: 'error',
          error: `Failed to fetch route: ${error.message}`,
        });
      }
    },
    [mapLoaded]
  );

  const getCustomer = async () => {
    try {
      setHasSearched(false);
      setSearchResult(null);
      const result = await GetTopCustomerList();

      if (result.data && result.data.length) {
        const filteredData = result.data.filter((data: any) => !data.isDeleted);
        setCustomers(filteredData);

        for (const customer of filteredData) {
          const cityCoords = await geocodeAddress(customer.fullAddress);

          const el = document.createElement('div');
          el.className = 'marker';
          const parentDiv = document.createElement('div');
          parentDiv.className = 'parent-marker'; // Assign a class for additional styling

          const csutomerImageDiv = document.createElement('div');
          csutomerImageDiv.className = 'customer-image'; // Assign a class for additional styling

          // Set marker image based on customer data
          if (customer.image) {
            el.style.backgroundImage = `url(${
              customer.imageUrl + customer.image
            })`;
          } else {
            el.style.backgroundImage = `url(${defaultImage})`;
          }
          csutomerImageDiv.appendChild(el); // Append the marker to the parent div
          parentDiv.appendChild(csutomerImageDiv); // Append the marker to the parent div
          // Create marker
          new window.TrimbleMaps.Marker(parentDiv)
            .setLngLat([cityCoords[0], cityCoords[1]])
            .addTo(mapRef.current!);

          // Add click event listener to the marker
          // marker.getElement().addEventListener('click', (event: any) => {});
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setIsCustomerLoading(false);
    }
  };

  useEffect(() => {
    if (isDisplayCustomers) {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
      initializeMap();
      getCustomer();
    } else {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
      initializeMap();
    }
  }, [isDisplayCustomers]);

  return (
    <div className="relative h-full w-full geography-map">
      <div
        id="map"
        ref={mapContainerRef}
        style={{ width: '100%', height: '100%' }}
      ></div>
      <SearchBox onSearch={handleSearch} />
    </div>
  );
};

export default TrimbleMap;
