import React from 'react';

const CustomerGroupOrderLoadingRaw = () => (
  <>
    <td className="px-5 py-4 min-w-36 max-w-36">
      <h6 className="text-grayLight900 font-semibold leading-tight truncate h-8 flex items-center custom-loading">
        WAL-DMIF-1
      </h6>
    </td>
    <td className="px-5 py-4 min-w-36 max-w-36">
      <span className="custom-loading">Jun 7, 2023</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$321.31</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$432.33</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$0.00</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$901.06 CAD</span>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center min-w-92 custom-loading">
        {/* if no image */}
        <div className="w-5 h-5 leading-[18px] bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase">
          WA
        </div>
        <div className="pl-2">
          <h6 className="text-grayLight900 font-medium leading-tight max-w-28 truncate">
            Andrew Waugh
          </h6>
        </div>
      </div>
    </td>
  </>
);

export default CustomerGroupOrderLoadingRaw;
