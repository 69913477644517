/* eslint-disable max-lines-per-function */
import moment from 'moment';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { CgCheck } from 'react-icons/cg';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { HiPlus } from 'react-icons/hi';
import { LuEqual } from 'react-icons/lu';
import {
  RiEditFill,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';
import WalToast from 'src/utils/WalToast';

import leftBracket from '../../../../../assets/img/left-bracket.svg';
import rightBracket from '../../../../../assets/img/right-bracket.svg';
import AccordionCmp from '../../../../../components/AccordionCmp';
import ButtonCmp from '../../../../../components/ButtonCmp';
import CommonModal from '../../../../../components/CommonModal';
import ConfirmModal from '../../../../../components/ConfirmModal';
import Drawer from '../../../../../components/Drawer';
import InputText from '../../../../../components/InputText/InputText';
import TabsCmp from '../../../../../components/TabsCmp';
import {
  CARRIERS,
  MARGIN_TYPE,
  ROSE_ROCKET,
  CURRENCY,
} from '../../../../../constants/common';
import { getCarrierServicePrices } from '../../../../../services/CarrierService';
import {
  createPDF,
  updatePDF,
  updateStatus,
} from '../../../../../services/QuoteService';
import { downloadPdf } from '../../../../../utils/CommonFunctions';

const initEditableFormData = {
  transitTime: false,
  projectedDeliveryDate: false,
  margin: false,
  totalCharge: false,
};

const initAction = {
  closedQuote: false,
  informationModel: false,
  orderSuccessModel: false,
};

const initColumns = {
  isDisplayCarrier: false,
  isDisplayTransit: true,
};

const initRequiredFields = [
  'margin',
  'totalCharge',
  'finalCharge',
  'transitTime',
  'projectedDeliveryDate',
];

const Pricing = ({
  isOpen,
  mode,
  quoteID,
  selectedCarrierRates,
  setIsRefresh,
  handleClose,
  currency,
  dimensions,
  services,
}: any) => {
  const [isValid, setIsValid] = useState<any>({});
  const [isValidNonQuote, setIsValidNonQuote] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [resetFormData, setResetFormData] = useState<any>({});
  const [editableFormData, setEditableFormData] =
    useState(initEditableFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [action, setAction] = useState(initAction);
  const [requiredFields, setRequiredFields] = useState(initRequiredFields);
  const [nonQuoteRequiredFields, setNonQuoteRequiredFields] = useState([]);
  const [columns, setColumns] = useState(initColumns);
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [carrierUrl, setCarrierUrl] = useState('');
  const [rossRocketId, setRossRocketId] = useState('');
  const [rossRocketPublicId, setRossRocketPublicId] = useState('');

  useEffect(() => {
    if (isOpen) {
      setIsDisable(mode === 'bol' || mode === 'view');
      setIsEditable(mode === 'update');
      setEditableFormData(initEditableFormData);
      setColumns(initColumns);
    }
  }, [isOpen]);

  useEffect(() => {
    const existingFormData: any = {};
    const existingNonQuoteRequiredFields: any = [];
    const existingRequiredFields = [...initRequiredFields];
    selectedCarrierRates.forEach((selectedCarrierRate: any) => {
      const quotedServices: any = [];
      const nonQuotedServices: any = [];
      const priceData: any = {};

      if (selectedCarrierRate.services && selectedCarrierRate.services.length) {
        selectedCarrierRate.services.forEach((service: any) => {
          if (service.isQuoted) {
            quotedServices.push({
              [service.serviceId.slug]: {
                name: service.serviceId.name,
                value: service.value,
                serviceId: service.serviceId.id,
              },
            });
          } else {
            nonQuotedServices.push({
              [service.serviceId.slug]: {
                name: service.serviceId.name,
                value: service.value,
                serviceId: service.serviceId.id,
              },
            });
            existingNonQuoteRequiredFields.push(service.serviceId.slug);
          }
          existingRequiredFields.push(service.serviceId.slug);
        });
      }

      getCarrierServicePrices(selectedCarrierRate.carrierId)
        .then((response: any) => {
          if (response.data && response.data.length) {
            response.data.forEach(
              (data: any) =>
                (priceData[data.serviceId] =
                  currency === CURRENCY.CAD ? data.priceCAD : data.priceUSD)
            );
          }
          selectedCarrierRate.carrierPriceData = priceData;
        })
        .catch(console.log);

      existingFormData[selectedCarrierRate.id] = {
        transitTime: selectedCarrierRate.transitTime,
        projectedDeliveryDate: selectedCarrierRate.projectedDeliveryDate,
        margin:
          selectedCarrierRate.margin > 0
            ? selectedCarrierRate.margin.toFixed(2)
            : selectedCarrierRate.margin,
        marginUnit:
          selectedCarrierRate.marginUnit > 0
            ? selectedCarrierRate.marginUnit.toFixed(2)
            : selectedCarrierRate.marginUnit,
        additionalCharges:
          selectedCarrierRate.additionalCharges > 0
            ? selectedCarrierRate.additionalCharges.toFixed(2)
            : 0,
        finalCharge:
          selectedCarrierRate.finalCharge > 0
            ? selectedCarrierRate.finalCharge.toFixed(2)
            : 0,
        totalCharge:
          selectedCarrierRate.totalCharge > 0
            ? selectedCarrierRate.totalCharge.toFixed(2)
            : 0,
        marginType: selectedCarrierRate.marginType,
        carrierPriceData: priceData,
        quotedServices,
        nonQuotedServices,
      };
    });
    setRequiredFields(existingRequiredFields);
    setNonQuoteRequiredFields(existingNonQuoteRequiredFields);
    setFormData(existingFormData);
    setResetFormData(existingFormData);
  }, [selectedCarrierRates]);

  useEffect(() => {
    const existingValid: any = {};
    const existingValidNonQuote: any = {};
    Object.entries(formData).forEach(([key, value]: any) => {
      const isValid1 = requiredFields.every(
        (requiredField) =>
          value[requiredField] !== '' && value[requiredField] !== null
      );
      let isValid2 = true;

      if (value.nonQuotedServices && value.nonQuotedServices.length) {
        isValid2 = value.nonQuotedServices.every((nonQuotedService: any) =>
          nonQuoteRequiredFields.every((requiredField) => {
            if (nonQuotedService[requiredField]) {
              return (
                nonQuotedService[requiredField].value !== '' &&
                nonQuotedService[requiredField].value !== null
              );
            }

            return true;
          })
        );
      }
      existingValidNonQuote[key] = isValid2;
      existingValid[key] = isValid1 && isValid2;
    });
    setIsValid(existingValid);
    setIsValidNonQuote(existingValidNonQuote);
  }, [formData]);

  const handleInputChange =
    (id: number, isService = false) =>
    (event: any) => {
      const { target, type } = event;
      const { name, value } = target;

      const regex = /^[0-9.]*$/;

      if (!regex.test(value)) {
        return;
      }

      const existingFormData: any = {
        ...formData[id],
      };

      const newValue =
        type === 'blur' && !value
          ? 0
          : type === 'focus' && value === '0'
          ? ''
          : value;

      if (!isService) {
        existingFormData[name] = newValue;
      }
      const parseValue =
        type === 'blur' && !value ? 0 : value ? parseFloat(value) : 0;

      let additionalCharges = 0;
      existingFormData.quotedServices.forEach(
        (quoteService: any, index: number) => {
          Object.entries(quoteService).forEach(([slug, service]: any) => {
            additionalCharges +=
              name === slug ? parseValue : parseFloat(service.value);
            existingFormData.quotedServices[index][slug] = {
              ...service,
              value: name === slug ? newValue : parseFloat(service.value),
            };
          });
        }
      );
      existingFormData.nonQuotedServices.forEach(
        (nonQuoteService: any, index: number) => {
          Object.entries(nonQuoteService).forEach(([slug, service]: any) => {
            additionalCharges +=
              name === slug
                ? parseValue
                : service.value
                ? parseFloat(service.value)
                : 0;
            existingFormData.nonQuotedServices[index][slug] = {
              ...service,
              value:
                name === slug
                  ? newValue
                  : service.value !== null
                  ? parseFloat(service.value)
                  : null,
            };
          });
        }
      );
      existingFormData.additionalCharges = additionalCharges.toFixed(2);

      const total =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges);

      const margin =
        existingFormData.marginType === MARGIN_TYPE.PERCENTAGE
          ? (total / 100) * parseFloat(existingFormData.marginUnit || 0)
          : parseFloat(existingFormData.marginUnit || 0);

      existingFormData.margin = margin;

      if (name === 'finalCharge') {
        if (type === 'blur') {
          existingFormData[name] =
            parseValue < total ? total.toFixed(2) : parseValue.toFixed(2);
        }
        let newMargin = parseValue - total;

        if (existingFormData.marginType === MARGIN_TYPE.PERCENTAGE) {
          newMargin = ((parseValue - total) / total) * 100;
        }
        existingFormData.margin =
          parseValue < total ? 0 : newMargin ? newMargin.toFixed(2) : newMargin;
      } else {
        existingFormData.finalCharge = (total + margin).toFixed(2);
      }

      setFormData((old: any) => ({ ...old, [id]: existingFormData }));
    };

  const handleSubmit =
    (id: number, isPdf = false) =>
    () => {
      if (mode === 'bol') {
        setAction((old) => ({ ...old, informationModel: true }));
        // setAction((old) => ({ ...old, closedQuote: true }));
        const [selectedCarrierRate] = selectedCarrierRates;
        let url;

        if (selectedCarrierRate.freightServiceId) {
          url = CARRIERS.FREIGHTCOM.BOL_URL;
        } else {
          switch (selectedCarrierRate.slug) {
            case CARRIERS.ARC_BEST.SLUG:
              url =
                CARRIERS.ARC_BEST.BOL_URL + selectedCarrierRate.carrierQuoteId;
              break;
            case CARRIERS.POLARIS.SLUG:
              url = CARRIERS.POLARIS.BOL_URL;
              break;
            case CARRIERS.DAY_ROSS.SLUG:
              url =
                CARRIERS.DAY_ROSS.BOL_URL + selectedCarrierRate.carrierQuoteId;
              break;
            case CARRIERS.FREIGHTCOM.SLUG:
              url =
                CARRIERS.FREIGHTCOM.BOL_URL +
                selectedCarrierRate.carrierQuoteId;
              break;
            case CARRIERS.MARITIME_ONTARIO.SLUG:
              url = CARRIERS.MARITIME_ONTARIO.BOL_URL;
              break;
            case CARRIERS.CSA.SLUG:
              url = CARRIERS.CSA.BOL_URL;
              break;
            default:
              break;
          }
        }

        if (url) {
          setCarrierUrl(url);
          // window.open(url);
        }

        return;
      }
      setIsLoading(true);

      if (mode === 'create') {
        createPDF(quoteID, { carrierPrices: formData, columns, currency })
          .then((response: any) => {
            handleClose();
            setIsRefresh(true);
            const { data } = response;
            const url = `${data.url}${data.file}`;
            setTimeout(() => {
              downloadPdf(url, `${data.name} - ${data.file}`);
            }, 2000);
          })
          .finally(() => setIsLoading(false))
          .catch(console.error);
      } else if (mode === 'update') {
        const data: any = {
          currency,
          columns,
          isPdf,
          margin: formData[id].margin,
          marginUnit: formData[id].marginUnit,
          marginType: formData[id].marginType,
          transitTime: formData[id].transitTime,
          projectedDeliveryDate: formData[id].projectedDeliveryDate,
          totalCharge: formData[id].totalCharge,
          services: [
            ...formData[id].quotedServices,
            ...formData[id].nonQuotedServices,
          ],
        };
        updatePDF(id, data)
          .then((response: any) => {
            handleClose();
            setIsRefresh(true);
            WalToast.success('Price updated successfully');

            if (response?.data) {
              const url = `${response?.data.url}${response?.data.file}`;
              setTimeout(() => {
                downloadPdf(
                  url,
                  `${response?.data.name} - ${response?.data.file}`
                );
              }, 2000);
            }
          })
          .finally(() => setIsLoading(false))
          .catch(console.error);
      }
    };

  const handleEditableFormData = (name: string) => () => {
    setEditableFormData((old) => ({ ...old, [name]: true }));
  };

  const saveEditableFormData =
    (name: string, id: number, status: boolean) => () => {
      if (status) {
        const existingFormData: any = {
          ...formData[id],
        };
        setResetFormData((old: any) => ({ ...old, [id]: existingFormData }));
      } else {
        const existingFormData: any = {
          ...formData[id],
          [name]: parseFloat(resetFormData[id][name]),
        };

        if (name === 'totalCharge') {
          const total =
            parseFloat(existingFormData.totalCharge) +
            parseFloat(existingFormData.additionalCharges);
          const margin =
            existingFormData.marginType === MARGIN_TYPE.PERCENTAGE
              ? (total / 100) * parseFloat(existingFormData.margin)
              : parseFloat(existingFormData.margin);
          existingFormData.finalCharge = (total + margin).toFixed(2);
        }
        setFormData((old: any) => ({ ...old, [id]: existingFormData }));
      }
      setEditableFormData((old) => ({ ...old, [name]: false }));
    };

  const handleDateChange = (id: number, name: string) => (date: any) => {
    const existingFormData: any = {
      ...formData[id],
    };
    existingFormData[name] = date;
    setFormData((old: any) => ({ ...old, [id]: existingFormData }));
  };

  const handleMarginType = (id: number, name: string) => (event: any) => {
    const { value } = event.target;
    const existingFormData: any = {
      ...formData[id],
    };
    existingFormData[name] = value;
    const total =
      parseFloat(existingFormData.totalCharge) +
      parseFloat(existingFormData.additionalCharges);
    const margin =
      existingFormData.marginType === MARGIN_TYPE.PERCENTAGE
        ? (total / 100) * parseFloat(existingFormData.marginUnit)
        : parseFloat(existingFormData.marginUnit);

    existingFormData.margin = margin;
    existingFormData.finalCharge = (total + margin).toFixed(2);
    setFormData((old: any) => ({ ...old, [id]: existingFormData }));
  };

  const handleToggle = (event: any) => {
    const { name, checked } = event.target;
    setColumns((old) => ({ ...old, [name]: checked }));
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        if (action.informationModel) {
          setAction((old) => ({ ...old, closedQuote: true }));

          if (carrierUrl) {
            window.open(carrierUrl);
          }

          return;
        }

        if (action.closedQuote) {
          setIsLoading(true);
          setIsMainLoading(true);
          updateStatus(selectedCarrierRates[0].id)
            .then((response: any) => {
              if (response && response.data) {
                setRossRocketId(response.data.rossRocketId);
                setRossRocketPublicId(response.data.rossRocketPublicId);
                setAction((old) => ({ ...old, orderSuccessModel: true }));
                // window.open(ROSE_ROCKET.QUOTE_URL + response.data.rossRocketId);
              }
              // setIsRefresh(true);
              // handleClose();
            })
            .finally(() => {
              setIsMainLoading(false);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              WalToast.error(
                error?.response?.data?.message || 'Something went wrong'
              );
            });
        }

        if (action.orderSuccessModel) {
          setIsRefresh(true);
          handleClose();
        }
      }
    },
    [action]
  );

  // const DateCustomInput = forwardRef(({ onClick }: any) => <FaRegCalendarAlt size={18} className="text-primary ms-3 cursor-pointer" onClick={onClick} />);
  const DateCustomInput = forwardRef(function DateCustomInput({
    onClick,
  }: any) {
    return (
      <FaRegCalendarAlt
        size={18}
        className="text-primary ms-3 cursor-pointer"
        onClick={onClick}
      />
    );
  });

  const drawer = useMemo(() => {
    let drawerObj = {
      title: '',
      button: '',
    };

    switch (mode) {
      case 'create':
        drawerObj.title = 'Configure Pricing Details';
        drawerObj.button = 'Generate Quote PDF';
        break;
      case 'update':
        drawerObj.title = 'Modify pricing';
        break;
      case 'bol':
        drawerObj.title = 'Create BOL';
        drawerObj.button = 'Create BOL';
        break;
      case 'view':
        drawerObj.title = 'Pricing details';
        break;
      default:
        drawerObj.title = 'None';
        drawerObj.button = 'None';
        break;
    }

    return drawerObj;
  }, [mode]);

  return (
    <>
      {isMainLoading && (
        <div className="flex justify-center items-center flex-1 absolute top-0 left-0 right-0 bottom-0 z-50 bg-[#000]/60">
          <div className="relative">
            <div className="loader_spinner ease-linear rounded-full border-8 border-t-8 border-white h-64 w-64"></div>
            <h2 className="text-white text-lg absolute  top-[50%] bottom-0 left-[50%] -translate-x-[50%] -translate-y-[10%] w-11/12 text-center">
              Please wait...
            </h2>
          </div>
        </div>
      )}
      <Drawer
        isOpen={isOpen}
        handleClose={handleClose}
        title={drawer.title}
        classDrawer="max-w-4xl"
      >
        {Object.keys(selectedCarrierRates).length > 0 && (
          <TabsCmp>
            {selectedCarrierRates.map(
              (selectedCarrierRate: any, index: number) => (
                <TabsCmp.ItemCmp
                  key={index}
                  title={selectedCarrierRate.name}
                  isActive={index === 0}
                  className="min-w-[180px] after:min-w-[180px] p-10 pt-0"
                >
                  <div className="flex border-b pt-10">
                    <div className="pb-4 flex-1">
                      <p className="mb-3 text-grayText">Transit time</p>
                      <div className="flex items-center text-black">
                        {editableFormData.transitTime ? (
                          <>
                            <input
                              type="number"
                              name="transitTime"
                              className="text-black text-base border-0 focus:outline-none focus:ring-0 border-b border-grayText p-0 w-[90px]"
                              value={
                                formData[selectedCarrierRate.id]
                                  ? formData[selectedCarrierRate.id].transitTime
                                  : 0
                              }
                              onChange={handleInputChange(
                                selectedCarrierRate.id
                              )}
                              onBlur={handleInputChange(selectedCarrierRate.id)}
                            />
                            <span>
                              <RiCheckboxCircleFill
                                size={20}
                                className="text-primary ms-3 cursor-pointer"
                                onClick={saveEditableFormData(
                                  'transitTime',
                                  selectedCarrierRate.id,
                                  true
                                )}
                              />
                            </span>
                            <span>
                              <RiCloseCircleFill
                                size={20}
                                className="text-primary ms-3 cursor-pointer"
                                onClick={saveEditableFormData(
                                  'transitTime',
                                  selectedCarrierRate.id,
                                  false
                                )}
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            Est.{' '}
                            {formData[selectedCarrierRate.id]
                              ? formData[selectedCarrierRate.id].transitTime
                              : 0}{' '}
                            Business Days.
                            {!isDisable && isEditable && (
                              <span>
                                <RiEditFill
                                  size={18}
                                  className="text-primary ms-3 cursor-pointer"
                                  onClick={handleEditableFormData(
                                    'transitTime'
                                  )}
                                />
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="pb-4 flex-1">
                      <p className="mb-3 text-grayText">Projected delivery</p>
                      <div className="flex items-center text-black">
                        {formData[selectedCarrierRate.id] &&
                        formData[selectedCarrierRate.id].projectedDeliveryDate
                          ? moment(
                              formData[selectedCarrierRate.id]
                                .projectedDeliveryDate
                            ).format('DD MMMM, YYYY')
                          : '-'}
                        {!isDisable && isEditable && (
                          <span>
                            <ReactDatePicker
                              dateFormat="dd/MM/yyyy"
                              minDate={moment().add(1, 'day').toDate()}
                              selected={
                                formData[selectedCarrierRate.id] &&
                                formData[selectedCarrierRate.id]
                                  .projectedDeliveryDate
                                  ? moment(
                                      formData[selectedCarrierRate.id]
                                        .projectedDeliveryDate
                                    ).toDate()
                                  : moment().toDate()
                              }
                              customInput={<DateCustomInput />}
                              onChange={handleDateChange(
                                selectedCarrierRate.id,
                                'projectedDeliveryDate'
                              )}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {formData[selectedCarrierRate.id] &&
                    formData[selectedCarrierRate.id].nonQuotedServices &&
                    formData[selectedCarrierRate.id].nonQuotedServices.length >
                      0 && (
                      <div className="border-b border-gray-100">
                        <AccordionCmp
                          className="border-0"
                          title={
                            <div className="-mx-[18px]">
                              <span className="text-black font-bold">
                                Non-Quoted Services
                              </span>
                              <span className="text-grayText"> (required)</span>
                            </div>
                          }
                        >
                          {formData[
                            selectedCarrierRate.id
                          ].nonQuotedServices.map((nonQuoteService: any) =>
                            Object.entries(nonQuoteService).map(
                              ([slug, service]: any, idx: number) => (
                                <div
                                  key={idx}
                                  className="flex flex-wrap -mx-3 gap-3"
                                >
                                  <div className="w-1/2 flex-1">
                                    <div className="mb-4">
                                      <InputText
                                        disabled
                                        className="form_control"
                                        label="Service"
                                        inputName={slug}
                                        value={service.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="w-1/2 flex-1">
                                    <div className="mb-4 relative">
                                      <InputText
                                        inputType="text"
                                        className="form_control pl-8"
                                        label={`Monetary value ${
                                          selectedCarrierRate.carrierPriceData &&
                                          selectedCarrierRate.carrierPriceData[
                                            service.serviceId
                                          ]
                                            ? `($${
                                                selectedCarrierRate
                                                  .carrierPriceData[
                                                  service.serviceId
                                                ]
                                              })`
                                            : ''
                                        }`}
                                        disabled={isDisable}
                                        inputName={slug}
                                        value={
                                          service.value !== null &&
                                          service.value !== 'NaN'
                                            ? service.value
                                            : ''
                                        }
                                        onChangeFunc={handleInputChange(
                                          selectedCarrierRate.id,
                                          true
                                        )}
                                        onBlur={handleInputChange(
                                          selectedCarrierRate.id,
                                          true
                                        )}
                                        onFocus={handleInputChange(
                                          selectedCarrierRate.id,
                                          true
                                        )}
                                      />
                                      <label className="text-lg text-black leading-6 absolute top-11 left-4">
                                        $
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          )}
                        </AccordionCmp>
                      </div>
                    )}
                  {formData[selectedCarrierRate.id] &&
                    formData[selectedCarrierRate.id].quotedServices.length >
                      0 && (
                      <div className="border-b border-gray-100">
                        <AccordionCmp
                          className="border-0"
                          title={
                            <div className="-mx-[18px]">
                              <span className="text-black font-bold">
                                Quoted Services
                              </span>
                            </div>
                          }
                        >
                          {formData[selectedCarrierRate.id].quotedServices.map(
                            (quotedService: any) =>
                              Object.entries(quotedService).map(
                                ([slug, service]: any, idx: number) => (
                                  <div
                                    key={idx}
                                    className="flex flex-wrap -mx-3 gap-3"
                                  >
                                    <div className="w-1/2 flex-1">
                                      <div className="mb-4">
                                        <InputText
                                          disabled
                                          className="form_control"
                                          label="Service"
                                          inputName={slug}
                                          value={service.name}
                                        />
                                      </div>
                                    </div>
                                    <div className="w-1/2 flex-1">
                                      <div className="mb-4 relative">
                                        <InputText
                                          inputType="text"
                                          className="form_control pl-8"
                                          label={`Monetary value ${
                                            selectedCarrierRate.carrierPriceData &&
                                            selectedCarrierRate
                                              .carrierPriceData[
                                              service.serviceId
                                            ]
                                              ? `($${
                                                  selectedCarrierRate
                                                    .carrierPriceData[
                                                    service.serviceId
                                                  ]
                                                })`
                                              : ''
                                          }`}
                                          disabled={isDisable}
                                          inputName={slug}
                                          value={service.value}
                                          onChangeFunc={handleInputChange(
                                            selectedCarrierRate.id,
                                            true
                                          )}
                                          onBlur={handleInputChange(
                                            selectedCarrierRate.id,
                                            true
                                          )}
                                          onFocus={handleInputChange(
                                            selectedCarrierRate.id,
                                            true
                                          )}
                                        />
                                        <label className="text-lg text-black leading-6 absolute top-11 left-4">
                                          $
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                          )}
                        </AccordionCmp>
                      </div>
                    )}
                  <div className="total_price py-5">
                    <h1 className="text-black font-bold">Total Quote price</h1>
                    <div className="mt-5 flex items-center justify-between">
                      <div className="flex-1">
                        <div className="flex items-center">
                          <img src={leftBracket} alt="" />
                          <div className="calc text-center flex-1">
                            <p className="mb-3 text-base text-black">
                              Raw Quote
                            </p>
                            <div className="flex items-center justify-center">
                              {editableFormData.totalCharge ? (
                                <>
                                  <input
                                    type="text"
                                    name="totalCharge"
                                    className="text-black text-base border-0 w-[90px] focus:outline-none focus:ring-0 border-b border-grayText p-0"
                                    value={
                                      formData[selectedCarrierRate.id]
                                        ? formData[selectedCarrierRate.id]
                                            .totalCharge
                                        : 0
                                    }
                                    onChange={handleInputChange(
                                      selectedCarrierRate.id
                                    )}
                                    onBlur={handleInputChange(
                                      selectedCarrierRate.id
                                    )}
                                  />
                                  <span>
                                    <RiCheckboxCircleFill
                                      size={20}
                                      className="text-primary ms-3 cursor-pointer"
                                      onClick={saveEditableFormData(
                                        'totalCharge',
                                        selectedCarrierRate.id,
                                        true
                                      )}
                                    />
                                  </span>
                                  <span>
                                    <RiCloseCircleFill
                                      size={20}
                                      className="text-primary ms-3 cursor-pointer"
                                      onClick={saveEditableFormData(
                                        'totalCharge',
                                        selectedCarrierRate.id,
                                        false
                                      )}
                                    />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <h1 className="text-2xl font-bold text-black">
                                    $
                                    {formData[selectedCarrierRate.id]
                                      ? formData[selectedCarrierRate.id]
                                          .totalCharge
                                      : 0}
                                  </h1>
                                  {!isDisable && isEditable && (
                                    <span>
                                      <RiEditFill
                                        size={18}
                                        className="text-primary ms-3 cursor-pointer"
                                        onClick={handleEditableFormData(
                                          'totalCharge'
                                        )}
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="calc mx-3">
                            <HiPlus size={30} className="text-black" />
                          </div>
                          <div className="calc flex-1 text-center">
                            <p className="mb-3 text-base text-black">
                              Additional Charges
                            </p>
                            <h1 className="text-2xl flex items-center justify-center font-bold text-black">
                              $
                              {formData[selectedCarrierRate.id]
                                ? formData[selectedCarrierRate.id]
                                    .additionalCharges || 0
                                : 0}
                            </h1>
                          </div>
                          <img src={rightBracket} alt="" />
                        </div>
                      </div>
                      <div className="w-auto mx-5">
                        <HiPlus size={30} className="text-black" />
                      </div>
                      <div className="flex-1 relative">
                        <InputText
                          inputType="text"
                          inputName="marginUnit"
                          className="form_control pl-8"
                          label="Margin"
                          disabled={isDisable}
                          value={
                            formData[selectedCarrierRate.id]
                              ? formData[selectedCarrierRate.id].marginUnit
                              : 0
                          }
                          onChangeFunc={handleInputChange(
                            selectedCarrierRate.id
                          )}
                          onBlur={handleInputChange(selectedCarrierRate.id)}
                          onFocus={handleInputChange(selectedCarrierRate.id)}
                        />
                        <label className="text-lg text-black leading-6 absolute top-[45px] left-4">
                          {formData[selectedCarrierRate.id]
                            ? MARGIN_TYPE.VALUE ===
                              formData[selectedCarrierRate.id].marginType
                              ? '$'
                              : '%'
                            : '$'}
                        </label>
                        <div className="absolute right-[7px] bottom-[15px] z-20">
                          <input
                            id={`toggle-value-${selectedCarrierRate.id}`}
                            name={`marginType-${selectedCarrierRate.id}`}
                            type="radio"
                            className="toggle_icons toggle-left"
                            disabled={isDisable}
                            value={MARGIN_TYPE.VALUE}
                            checked={
                              formData[selectedCarrierRate.id]
                                ? formData[selectedCarrierRate.id]
                                    .marginType === MARGIN_TYPE.VALUE
                                : false
                            }
                            onChange={handleMarginType(
                              selectedCarrierRate.id,
                              'marginType'
                            )}
                          />
                          <label
                            htmlFor={`toggle-value-${selectedCarrierRate.id}`}
                            className="toggle_button"
                          >
                            $
                          </label>
                          <input
                            id={`toggle-percentage-${selectedCarrierRate.id}`}
                            name={`marginType-${selectedCarrierRate.id}`}
                            type="radio"
                            className="toggle_icons toggle-right"
                            disabled={isDisable}
                            value={MARGIN_TYPE.PERCENTAGE}
                            checked={
                              formData[selectedCarrierRate.id]
                                ? formData[selectedCarrierRate.id]
                                    .marginType === MARGIN_TYPE.PERCENTAGE
                                : false
                            }
                            onChange={handleMarginType(
                              selectedCarrierRate.id,
                              'marginType'
                            )}
                          />
                          <label
                            htmlFor={`toggle-percentage-${selectedCarrierRate.id}`}
                            className="toggle_button"
                          >
                            %
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-5">
                      <LuEqual size={30} className="text-black mx-auto" />
                    </div>
                    <div className="mt-5 relative">
                      <InputText
                        inputType="text"
                        inputName="finalCharge"
                        className="form_control pl-8"
                        label="Final Quote"
                        disabled={isDisable}
                        value={
                          formData[selectedCarrierRate.id]
                            ? formData[selectedCarrierRate.id].finalCharge
                            : 0
                        }
                        onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                        onBlur={handleInputChange(selectedCarrierRate.id)}
                      />
                      <label className="text-lg text-black leading-6 absolute top-[45px] left-4">
                        $
                      </label>
                    </div>
                  </div>
                  {(mode === 'create' || mode === 'update') && (
                    <div className="flex border-t py-5">
                      <div className="flex-1">
                        <label className="relative inline-flex cursor-pointer">
                          <input
                            type="checkbox"
                            name="isDisplayCarrier"
                            className="sr-only peer"
                            disabled={isDisable}
                            checked={columns.isDisplayCarrier}
                            onChange={handleToggle}
                          />
                          <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-primary"></div>
                          <span className="ml-3 text-black">
                            Display Carrier
                          </span>
                        </label>
                      </div>
                      <div className="flex-1">
                        <label className="relative inline-flex cursor-pointer">
                          <input
                            type="checkbox"
                            name="isDisplayTransit"
                            className="sr-only peer"
                            disabled={isDisable}
                            checked={columns.isDisplayTransit}
                            onChange={handleToggle}
                          />
                          <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-primary"></div>
                          <span className="ml-3 text-black">
                            Display Estimated delivery and Transit time
                          </span>
                        </label>
                      </div>
                    </div>
                  )}
                  {mode === 'update' ? (
                    <div className="flex gap-5">
                      <ButtonCmp
                        type="button"
                        loading={isLoading}
                        disabled={
                          !isValid[selectedCarrierRate.id] ||
                          isDisable ||
                          isLoading
                        }
                        onClick={handleSubmit(selectedCarrierRate.id)}
                        className="btn_primary w-1/2"
                      >
                        Save
                      </ButtonCmp>
                      <ButtonCmp
                        type="button"
                        loading={isLoading}
                        disabled={
                          !isValid[selectedCarrierRate.id] ||
                          isDisable ||
                          isLoading
                        }
                        onClick={handleSubmit(selectedCarrierRate.id, true)}
                        className="btn_secondary_black w-1/2"
                      >
                        Generate Quote PDF
                      </ButtonCmp>
                    </div>
                  ) : (
                    mode !== 'view' && (
                      <ButtonCmp
                        loading={isLoading}
                        disabled={
                          !isValid[selectedCarrierRate.id] ||
                          !selectedCarrierRate.carrierQuoteId ||
                          isLoading
                        }
                        onClick={handleSubmit(selectedCarrierRate.id)}
                        className="btn btn_primary w-full"
                      >
                        {drawer.button}
                      </ButtonCmp>
                    )
                  )}
                  {!isValidNonQuote[selectedCarrierRate.id] && (
                    <div className="bg-red-100 text-sm text-black p-2 mt-3 rounded-full text-center">
                      A non quoted service has not been specified
                    </div>
                  )}
                  {!selectedCarrierRate.carrierQuoteId && (
                    <div className="bg-red-100 text-sm text-black p-2 mt-3 rounded-full text-center">
                      Complete margin info for all selected carriers
                    </div>
                  )}
                </TabsCmp.ItemCmp>
              )
            )}
          </TabsCmp>
        )}
      </Drawer>
      {action.informationModel && (
        <CommonModal
          handleClose={handleModalClose(false)}
          title="Create BOL Instruction"
          size={'max-w-6xl'}
        >
          <div className="p-6 pt-0 ">
            {' '}
            <div className="table w-full">
              <p className="font-semibold leading-relaxed text-black">
                Dimensions & services
              </p>
              <table className="mt-3 w-full">
                <thead>
                  {dimensions.map((dimension: any, index: any) => (
                    <tr key={`dimension_${index}`}>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        Handling unit
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        № of handling units
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        {' '}
                        Weight per unit (
                        {dimension.weightMeasurement?.toLowerCase()})
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        Length ({dimension.lengthMeasurement?.toLowerCase()})
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        Width ({dimension.lengthMeasurement?.toLowerCase()})
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        Height ({dimension.lengthMeasurement?.toLowerCase()})
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        {' '}
                        Total weight (
                        {dimension.weightMeasurement?.toLowerCase()})
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {dimensions.map((dimension: any, index: any) => (
                    <tr
                      key={`dimension_${index}`}
                      className="text-center border-b border-gray-100"
                    >
                      <td className="p-3 text-black text-base">
                        {dimension.handlingUnitName}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.handlingUnitNo}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.weight}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.itemLength}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.width}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.height}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.totalWeight}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {services.length > 0 && (
                <div className="border-none shadow-nonepx-5 py-3 mt-6 rounded-lg">
                  <ul className="mb-2 text-gray-500 dark:text-gray-400 flex flex-wrap">
                    {services.map((service: any, index: any) => (
                      <li
                        key={`service_${index}`}
                        className="w-1/6 capitalize text-black flex items-center text-base mt-2"
                      >
                        <CgCheck size={24} className="text-primary mr-1" />{' '}
                        {service.serviceName}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="mt-4 ">
              <p className="font-semibold leading-relaxed text-black mb-4">
                Instruction
              </p>
              <ul className="leading-8">
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` You will be redirected to the carrier portal.`}
                </li>
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` Log in to the carrier portal using your credentials.`}
                </li>
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` Locate your quote by entering the quote ID.`}
                </li>
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` Click on the "Create BOL" button within the carrier portal.`}
                </li>
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` Once you have completed the Bill of Lading (BOL) process, return to our portal.`}
                </li>
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` Accept that you have created the quote and finalize the process on our portal.`}
                </li>
              </ul>
            </div>
            <div className="flex flex-wrap -mx-3 mt-4">
              <div className="w-1/2 px-3">
                <button
                  className="btn_primary w-full"
                  onClick={handleModalClose(true)}
                >
                  {'Proceed with Order'}
                </button>
              </div>
              <div className="w-1/2 px-3">
                <button
                  className="btn_outline_primary w-full"
                  onClick={handleModalClose(false)}
                >
                  {'Close'}
                </button>
              </div>
            </div>
          </div>
        </CommonModal>
      )}
      {action.closedQuote && (
        <ConfirmModal
          title="Close Active Quote"
          description={`Creating a BOL with ${selectedCarrierRates[0].name} will close this active quote and set its status as won, you wont be able to reopen it. Are you sure you want to proceed?`}
          button1="Yes, I am sure"
          button2="No, not now"
          handleClose={handleModalClose}
        />
      )}
      {action.orderSuccessModel && (
        <CommonModal
          handleClose={handleModalClose(false)}
          title="Order Booked"
          size={'max-w-md'}
        >
          <div className="p-6 pt-0 ">
            <div className="text-center">
              <div className="success-checkmark">
                <div className="check-icon">
                  <span className="icon-line line-tip"></span>
                  <span className="icon-line line-long"></span>
                  <div className="icon-circle"></div>
                  <div className="icon-fix"></div>
                </div>
              </div>
              <p className="text-xl font-semibold leading-relaxed text-black mt-4">
                <Link
                  to={ROSE_ROCKET.QUOTE_URL + rossRocketId}
                  target="_blank"
                  className="underline text-primary"
                >
                  {rossRocketPublicId}
                </Link>
              </p>
            </div>
            <div className="flex flex-wrap -mx-3 mt-4">
              <div className="w-full px-3">
                <button
                  className="btn_outline_primary w-full"
                  onClick={handleModalClose(true)}
                >
                  {'Close'}
                </button>
              </div>
            </div>
          </div>
        </CommonModal>
      )}
    </>
  );
};

export default memo(Pricing);
