import { CurrencyDollar, Percent02 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo, useState, useEffect } from 'react';
import { onError } from 'src/utils/CommonFunctions';

import defaultImage from '../../../../assets/img/default-image.jpg';
import Drawer from '../../../../components/Drawer';
import InputText from '../../../../components/InputText/InputText';

const PricingDetails = ({ isOpen, handleClose, quote, carrierRate }: any) => {
  const { addressDetails } = quote;

  const [services, setServices] = useState<any>([]);
  useEffect(() => {
    let serviceList: any = [];
    carrierRate?.services.forEach((service: any) => {
      serviceList.push({
        name: service.serviceId?.name,
        value: service.value,
        serviceId: service.serviceId?.id,
      });
    });
    setServices(serviceList);
  }, [isOpen]);

  return (
    <Drawer
      isOpen={isOpen}
      handleClose={handleClose}
      title="Pricing Details"
      description={`View pricing details for #${addressDetails.code}`}
      classDrawer="src/app/Customers/CustomerDetailRightSideDetailSection/TypeInfoModal.tsx"
      drawerHeight="flex-grow"
      headerClassName="!hidden"
    >
      {isOpen ? (
        <>
          <div className="border-b border-utilityGray200 flex items-center gap-2 pb-4 pt-1">
            <img
              src={
                carrierRate.image
                  ? carrierRate.imageUrl + carrierRate.image
                  : defaultImage
              }
              onError={onError}
              className="rounded border border-utilityGray200 w-5 h-5 flex-none"
              alt=""
            />
            <span className="text-grayLight900 text-base font-semibold">
              {carrierRate?.name}
            </span>
          </div>
          <div className="py-4 border-b border-utilityGray200">
            <InputText
              inputName="transitTime"
              value={`Est. ${carrierRate.transitTime} Business Days.`}
              disabled={true}
              parentClassName="mb-2"
              labelClassName="block mb-1.5"
              label="Transit time"
            />
            <InputText
              inputName="transitTime"
              value={
                carrierRate.projectedDeliveryDate
                  ? moment(carrierRate.projectedDeliveryDate).format(
                      'DD MMMM, YYYY'
                    )
                  : '-'
              }
              disabled={true}
              parentClassName="mb-2"
              labelClassName="block mb-1.5"
              label="Projected delivery"
            />
          </div>

          {services?.length > 0 && (
            <div className="py-4 border-b border-utilityGray200">
              <h6 className="text-utilityBlack text-sm font-semibold mb-3">
                Additional Services
              </h6>
              {services.map((service: any) => (
                <InputText
                  key={service.serviceId}
                  inputType="text"
                  className="pl-8"
                  disabled={true}
                  value={service.value}
                  label={service.name}
                  labelClassName="block mb-1.5 [&:not(:nth-child(2))]:mt-3"
                  icon={
                    <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                      <CurrencyDollar className="w-4 h-4" />
                    </div>
                  }
                />
              ))}
            </div>
          )}

          <div className="py-4 border-b border-utilityGray200">
            <h6 className="text-utilityBlack text-sm font-semibold mb-3">
              Total Cost
            </h6>
            <InputText
              inputType="text"
              inputName="totalCharge"
              className="pl-8"
              disabled={true}
              value={carrierRate.totalCharge}
              label="Base Quote"
              labelClassName="block mb-1.5"
              icon={
                <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                  <CurrencyDollar className="w-4 h-4" />
                </div>
              }
              parentClassName="mb-2"
            />
            <InputText
              inputType="text"
              inputName="additionalServices"
              className="pl-8"
              disabled={true}
              value={carrierRate.additionalCharges}
              label="Additional Services"
              labelClassName="block mb-1.5"
              icon={
                <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                  <CurrencyDollar className="w-4 h-4" />
                </div>
              }
            />
          </div>
          <div className="py-4">
            <h6 className="text-utilityBlack text-sm font-semibold mb-3">
              Total Quote
            </h6>
            <div className="flex sm:flex-row flex-col -m-1 mb-2">
              <InputText
                inputType="text"
                inputName="margin"
                disabled={true}
                value={carrierRate.marginUnit}
                className="pl-[40px]"
                placeholder="0"
                label="Margin"
                labelClassName="block"
                icon={
                  <div className="border-r border-gray100 bg-utilityGray100 absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                    <CurrencyDollar className="w-4 h-4" />
                  </div>
                }
                parentClassName="sm:w-1/2 p-1"
              />
              <InputText
                inputType="text"
                inputName="marginUnit"
                disabled={true}
                value={carrierRate.margin}
                className="pl-[40px]"
                label="&nbsp;"
                placeholder="0"
                labelClassName="sm:block hidden"
                icon={
                  <div className="border-r border-gray100 bg-utilityGray100 absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                    <Percent02 className="w-4 h-4" />
                  </div>
                }
                parentClassName="sm:w-1/2 p-1"
              />
            </div>
            <InputText
              inputType="text"
              inputName="finalCharge"
              className="pl-8"
              disabled={true}
              parentClassName="flex-1"
              value={carrierRate.finalCharge}
              label="Total Price"
              labelClassName="block mb-1.5"
              icon={
                <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                  <CurrencyDollar className="w-4 h-4" />
                </div>
              }
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </Drawer>
  );
};
export default memo(PricingDetails);
