import { Plus, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import {
  customerContactsList,
  removeContact,
} from 'src/services/CustomerService';
import WalToast from 'src/utils/WalToast';

import AddNewCustomerContactModal from './AddNewCustomerContactModal';
import ContactTabOrderLoadingRaw from './ContactTabOrderLoadingRaw';
import ContactTabOrderRaw from './ContactTabOrderRaw';

interface IProps {
  customerId: string;
}

const initParams: any = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 50,
};

const CustomerDetailContactsTab = ({ customerId }: IProps) => {
  const [isShowAddNewContactModal, setIsShowAddNewContactModal] =
    useState(false);
  const [customerContacts, setCustomerContacts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [params, setParams] = useState({
    ...initParams,
    customerId: customerId,
  });
  const [total, setTotal] = useState(0);
  const [isRefresh, setIsRefresh] = useState(true);
  const [deleteContactData, setDeleteContactData] = useState<any | undefined>(
    undefined
  );
  const [isCustomerContactDeleteLoading, setIsCustomerContactDeleteLoading] =
    useState(false);

  const headCellsContacts = useMemo(
    () => [
      {
        id: 'contactFullName',
        name: 'Name',
        sortable: true,
      },
      {
        id: 'contactRole',
        name: 'Role',
        sortable: true,
      },
      {
        id: 'contactPhone',
        name: 'Number',
        sortable: true,
      },
      {
        id: 'contactEmail',
        name: 'Email',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
        sortable: false,
        rowClassName: '!pl-2',
      },
    ],
    []
  );

  useEffect(() => {
    setIsRefresh(true);
  }, [params]);

  useEffect(() => {
    if (!isRefresh) {
      return;
    }
    const customerContactsApiController = new AbortController();
    const signal = customerContactsApiController.signal;

    setCustomerContacts([]);
    setIsLoading(true);
    customerContactsList(params, signal)
      .then((response: any) => {
        if (response.data) {
          setCustomerContacts(response.data);
        }
        setTotal(response.total ?? 0);
        setIsLoading(false);
        setIsRefresh(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
        setIsRefresh(false);
      });

    return () => {
      customerContactsApiController.abort();
    };
  }, [params, isRefresh]);

  const handleDeleteConfirmationAction = (status = false) => {
    if (status) {
      setIsCustomerContactDeleteLoading(true);
      removeContact(deleteContactData?.id)
        .then((response: any) => {
          WalToast.success(response?.message);
          setDeleteContactData(undefined);
          setIsRefresh(true);
        })
        .finally(() => {
          setIsCustomerContactDeleteLoading(false);
        })
        .catch(console.log);
    } else {
      setDeleteContactData(undefined);
    }
  };

  return (
    <>
      <div className="w-full bg-white rounded-xl border border-utilityGray200 shadow flex mt-9">
        <div className="w-full flex-col flex">
          <div className="table-top-header flex-wrap ">
            <div className="table-left-wrap flex-none max-mdm:flex-[0_0_100%]">
              <div className="table-title-wrap flex-wrap">
                <h5 className="table-title">Contact List</h5>

                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={`${isLoading ? 'custom-loading' : ''}`}
                >
                  {total} {total > 1 ? 'Contacts' : 'Contact'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View and manage your sales representative
              </p>
            </div>
            <div className="table-right-wrap max-mdm:w-full">
              <InputText
                inputName="searchCustomer"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={params.search}
                isClearable={true}
                parentClassName="table-searchInput max-mdm:flex-1"
                inputType="text"
                onChangeFunc={(e) =>
                  setParams({ ...params, search: e?.target?.value })
                }
              />
              <ButtonCmp
                className="btn_primary"
                icon={<Plus className="w-4 h-4" />}
                onClick={() => setIsShowAddNewContactModal(true)}
              >
                Add new contact
              </ButtonCmp>
            </div>
          </div>
          <div className="w-full border-t border-gray100 flex flex-col flex-1">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
              <TableCmp
                tableDataArr={customerContacts}
                params={params}
                setParams={setParams}
                headCells={headCellsContacts}
                TableLoaderRowCmp={ContactTabOrderLoadingRaw}
                TableRowCmp={ContactTabOrderRaw}
                tableRowCmpProps={{
                  handleDeleteContacts: (data: any) =>
                    setDeleteContactData(data),
                }}
                numberOfSkeletonRows={15}
                isTableDataLoading={isLoading}
              />
            </div>
            {!isLoading && !customerContacts?.length && (
              <NotFoundUI
                title="No orders Found"
                desc="There are no orders found."
                containerClassName="min-h-[200px] my-0 flex-1 !h-auto"
              />
            )}
          </div>
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={total}
            currentPage={params.page}
            handlePagination={(page: number) => {
              setParams((old: any) => ({ ...old, page }));
            }}
          />
        </div>
      </div>

      {isShowAddNewContactModal && customerId && (
        <AddNewCustomerContactModal
          customerId={customerId}
          handleClose={(refreshStatus: boolean) => {
            if (refreshStatus) {
              setIsRefresh(true);
            }
            setIsShowAddNewContactModal(false);
          }}
        />
      )}

      {deleteContactData && (
        <CommonModal
          title="Remove Customer Contact"
          titleDesc="This action will remove customer contact details."
          handleClose={() =>
            isCustomerContactDeleteLoading
              ? false
              : handleDeleteConfirmationAction(false)
          }
          secondaryBtnOnClick={() => handleDeleteConfirmationAction(false)}
          dangerBtnText="Remove"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleDeleteConfirmationAction(true)}
          primaryBtnLoading={isCustomerContactDeleteLoading}
          primaryBtnDisabled={isCustomerContactDeleteLoading}
          secondaryBtnDisabled={isCustomerContactDeleteLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}
    </>
  );
};

export default CustomerDetailContactsTab;
