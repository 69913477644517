import React from 'react';

const AccountsOnbording = ({ data }: any) => (
  <>
    <td className="px-5 py-4">
      <span>{data?.account_payable_name}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data?.account_payable_phone}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data?.account_payable_email}</span>
    </td>
  </>
);

export default AccountsOnbording;
