import {
  ArrowDown,
  ArrowUp,
  DownloadCloud02,
  Eye,
  SearchLg,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { HiMiniMagnifyingGlass } from 'react-icons/hi2';
import NotFoundUI from 'src/components/NotFoundUI';

import pdfIcon2 from '../../../../assets/img/pdf-icon.webp';
import ButtonCmp from '../../../../components/ButtonCmp';
import CustomPagination from '../../../../components/CustomPagination';
import Drawer from '../../../../components/Drawer';
import InputText from '../../../../components/InputText/InputText';
import SelectBox from '../../../../components/SelectBox/SelectBox';
import {
  PDF_LANGUAGE_OPTIONS,
  PDF_LANGUAGE,
} from '../../../../constants/common';
import { listPDF } from '../../../../services/QuoteService';
import {
  capitalizeFirstLetterSpace,
  downloadPdf,
} from '../../../../utils/CommonFunctions';

const initParams = {
  search: '',
  sortDirection: 'DESC',
  sortBy: 'createdAt',
  page: 1,
  limit: 10,
};

const PDF = ({ isOpen, id, handleClose, addressDetails }: any) => {
  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');
  const [totals, setTotals] = useState(0);
  const [pdfs, setPdfs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectPdfLanguage, setSelectPdfLanguage] = useState<any>({
    value: 'english',
    label: 'English',
  });
  const [pdfLanguage, setPdfLanguage] = useState<any>('english');

  const [ignoreClose, setIgnoreClose] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      setPdfs([]);
      listPDF(id, params)
        .then((response: any) => {
          setPdfs(response.data);
          setTotals(response.total);
        })
        .finally(() => setIsLoading(false))
        .catch(console.error);
    }
  }, [isOpen, params]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'search') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  const handleSorting = (sortBy: string) => () => {
    const sortDirection = params.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setParams((old) => ({ ...old, sortDirection, sortBy }));
  };

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  const viewPdf = (pdfData: any) => () => {
    if (pdfData?.url && pdfData?.file) {
      let url: string = pdfData?.url + pdfData?.file;

      if (
        pdfLanguage === PDF_LANGUAGE.FRE &&
        pdfData?.frenchUrl &&
        pdfData?.frenchFile
      ) {
        url = pdfData?.frenchUrl + pdfData?.frenchFile;
      }
      window.open(url);
    }
  };

  const download = (pdfData: any) => () => {
    if (pdfData?.url && pdfData?.file) {
      let url: string = pdfData?.url + pdfData?.file;
      let name: string = `${pdfData?.name}.pdf`;

      if (
        pdfLanguage === PDF_LANGUAGE.FRE &&
        pdfData?.frenchUrl &&
        pdfData?.frenchFile
      ) {
        url = pdfData?.frenchUrl + pdfData?.frenchFile;
      }
      downloadPdf(url, name);
    }
  };

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        sortable: false,
      },
      {
        id: 'language',
        name: 'Language',
        sortable: false,
      },
      {
        id: 'currency',
        name: 'Currency',
        sortable: false,
      },
      {
        id: 'createdAt',
        name: 'Creation Date',
        sortable: true,
      },
      {
        id: 'action',
      },
    ],
    []
  );

  return (
    <Drawer
      isOpen={isOpen}
      handleClose={handleClose}
      title="Invoicing History"
      classDrawer="mdm:min-w-[726px] sm:!w-[620px] !w-[316px] flex flex-col"
      ignoreClose={ignoreClose}
      setIgnoreClose={setIgnoreClose}
      drawerHeight="flex-grow"
      description={`View pricing details for #${addressDetails.code}`}
      badge={`${pdfs.length} ${pdfs.length > 1 ? 'Invoices' : 'Invoice'} `}
      badgeclassName={isLoading ? 'custom-loading' : ''}
    >
      <div className="table-wrap-main h-full ">
        <div className="table-header justify-end">
          <div className="flex gap-3 w-full">
            <div className="flex-1">
              <InputText
                inputName="search"
                placeholder="Search File"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                isClearable
                onChangeFunc={handleFilter}
              />
            </div>
          </div>
        </div>

        <div className="table-wrap">
          <table className="table text-nowrap whitespace-nowrap">
            <thead>
              <tr className="[&>:last-child]:w-16">
                {headCells.map((head) => (
                  <th key={head.id}>
                    <div className="flex items-center">
                      <span>{head.name}</span>
                      {head.sortable && (
                        <>
                          {params.sortBy === head.id ? (
                            params.sortDirection === 'ASC' ? (
                              <ArrowUp
                                className="w-4 h-4 flex-none ms-1"
                                onClick={handleSorting(head.id)}
                              />
                            ) : (
                              <ArrowDown
                                className="w-4 h-4 flex-none ms-1"
                                onClick={handleSorting(head.id)}
                              />
                            )
                          ) : (
                            <>
                              <ArrowUp
                                className="w-4 h-4 flex-none ms-1"
                                onClick={handleSorting(head.id)}
                              />
                              <ArrowDown
                                className="w-4 h-4 flex-none ms-1"
                                onClick={handleSorting(head.id)}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pdfs.length
                ? pdfs.map((pdf: any, index: number) => (
                    <tr key={index}>
                      <td className="w-52 min-w-52 max-w-52">
                        <div className="flex items-center gap-3">
                          <img
                            src={pdfIcon2}
                            className="w-9 h-9 flex-none"
                            alt={pdf.name}
                            title={pdf.name}
                          />
                          <p className="font-medium text-grayLight900 truncate">
                            {pdf.name}.pdf
                          </p>
                        </div>
                      </td>
                      <td>{capitalizeFirstLetterSpace(pdf.pdfLanguage)}</td>
                      <td>{pdf.pdfCurrency}</td>
                      <td>{moment(pdf.createdAt).format('DD MMM YYYY')}</td>
                      <td className="w-[115px] min-w-[115px]">
                        <div className="flex gap-1">
                          <ButtonCmp className="btn-outline-primary lg:px-[9px] px-2  ">
                            <Eye className="w-4 h-4" onClick={viewPdf(pdf)} />
                          </ButtonCmp>
                          <ButtonCmp className="btn-outline-primary lg:px-[9px] px-2  ">
                            <DownloadCloud02
                              className="w-4 h-4"
                              onClick={download(pdf)}
                            />
                          </ButtonCmp>
                        </div>
                      </td>
                    </tr>
                  ))
                : isLoading
                ? [...Array(8)].map((_, index) => (
                    <tr key={index}>
                      <td className="w-52 min-w-52 max-w-52 ">
                        <div className="flex items-center gap-3">
                          <img
                            src={pdfIcon2}
                            className="w-9 h-9 flex-none custom-loading custom-loading-image"
                          />
                          <p className="font-medium text-grayLight900 truncate custom-loading">
                            testPSd1234.pdf
                          </p>
                        </div>
                      </td>
                      <td>
                        <span className="custom-loading">Aliqua in ut </span>
                      </td>
                      <td>
                        <span className="custom-loading"> CAD </span>
                      </td>
                      <td>
                        <span className="custom-loading"> 13 jun 2024 </span>
                      </td>
                      <td className="w-[115px] min-w-[115px]">
                        <div className="flex gap-1">
                          <ButtonCmp className="btn-outline-primary lg:px-[9px] px-2 custom-loading">
                            <Eye className="w-4 h-4" />
                          </ButtonCmp>
                          <ButtonCmp className="btn-outline-primary lg:px-[9px] px-2 custom-loading">
                            <DownloadCloud02 className="w-4 h-4" />
                          </ButtonCmp>
                        </div>
                      </td>
                    </tr>
                  ))
                : ''}
            </tbody>
          </table>
          {pdfs.length <= 0 && !isLoading ? (
            <NotFoundUI
              title="No Quote Found"
              desc="There are no Pdf for this quote"
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          ) : (
            ''
          )}
        </div>
        <CustomPagination
          recordsPerPage={params.limit}
          totalRecords={totals}
          currentPage={params.page}
          handlePagination={handlePagination}
        />
      </div>

      <div className="w-full hidden items-center gap-2">
        <div className={window.MODE !== 'production' ? 'w-3/4' : 'w-full'}>
          <div className="searchbox relative">
            <input
              name="search"
              type="text"
              className="w-full border-none bg-inputBg rounded-full px-5 py-3 text-grayDark sm:text-sm m-0 focus:outline-none focus:ring-0"
              placeholder="Search file"
              value={search}
              onChange={handleFilter}
            />
            <HiMiniMagnifyingGlass
              className="absolute top-3 right-3 text-grayText"
              size={20}
            />
          </div>
        </div>
        {window.MODE !== 'production' && (
          <div className="w-1/4">
            <SelectBox
              isSearchable={false}
              isClearable={false}
              name="role"
              placeholder="Select Language"
              className="form_control"
              classNamePrefix="lang"
              labelClassName="form_label"
              value={selectPdfLanguage}
              options={PDF_LANGUAGE_OPTIONS}
              onChangeFunc={(e: any) => {
                setIgnoreClose(true);
                setSelectPdfLanguage(e);
                setPdfLanguage(e?.value);
              }}
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};
export default memo(PDF);
