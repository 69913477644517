/* eslint-disable max-lines-per-function */
import {
  SearchLg,
  XClose,
  Copy05,
  Edit05,
  Trash01,
  DotsVertical,
  RefreshCcw05,
  HelpCircle,
  Clock,
  RefreshCcw04,
  Check,
  Plus,
  FileDownload03,
  FilePlus03,
} from '@untitled-ui/icons-react/build/cjs';
import { orderBy, minBy, debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PredictivePriceChart from 'src/app/QuotingHub/PredictivePricing/PredictivePriceChart';
import BadgeCmp from 'src/components/BadgeCmp';
import CommonModal from 'src/components/CommonModal';
import LiveTimer from 'src/components/LiveTimer';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getPricingRate } from 'src/services/CommonService';
import { deleteLoadLinkPosting } from 'src/services/LoadLinkService';

import doubleCheck from '../../../../../assets/img/double-check-success.svg';
import ButtonCmp from '../../../../../components/ButtonCmp';
import CheckBox from '../../../../../components/CheckBox';
import InputText from '../../../../../components/InputText/InputText';
import NotFoundUI from '../../../../../components/NotFoundUI';
import PageSectionLayout from '../../../../../components/PageSectionLayout';
import Header from '../../../../../components/PageSectionLayout/Header/Header';
import SelectBox from '../../../../../components/SelectBox/SelectBox';
import {
  CURRENCY,
  QUOTE_CLASSIFICATION,
  STATUS,
} from '../../../../../constants/common';
import { PATH } from '../../../../../constants/path';
import { ROUTES } from '../../../../../constants/routes';
import { AuthContext } from '../../../../../context/AuthContext';
import { BasicContext } from '../../../../../context/BasicContext';
import { listCarrier } from '../../../../../services/CarrierService';
import {
  createRate,
  getQuote,
  modifyQuote,
  spotQuoteRequest,
} from '../../../../../services/QuoteService';
import { fetchJsFromCDN } from '../../../../../utils/CommonFunctions';
import WalToast from '../../../../../utils/WalToast';
import AddQuoteDetail from '../AddQuoteDetail';
import FinalizeOrderModal from '../FinalizeOrderModal';
import LaneHistory from '../LaneHistory';
import PDF from '../PDF';
import PostToLoadBoard from '../PostToLoadBoard';
import Pricing from '../Pricing';
import PricingDetails from '../PricingDetails';
import ProceedWithOrderFlow from '../ProceedWithOrderFlow';
import QuoteDetail from '../QuoteDetail';
import QuotedLoading from '../QuotedLoading';
import QuotedRow from '../QuotedRow';
import ReqQuotedRow from '../ReqQuotedRow';
import ReqQuoteLoading from '../ReqQuoteLoading';
import SpotQuoteConfirmation from '../SpotQuoteConfirmation';
import WithQuoteLoading from '../WithQuoteLoading';
import WithRateQuote from '../WithRateQuote';

import QuoteDetailTable from './QuoteDetailTable';

const initAction = {
  mode: '',
  pricing: false,
  pdf: false,
  closedReason: true,
  quoteDetail: false,
  addQuoteDetail: false,
  orderSuccessModel: false,
  pricingDetails: false,
  spotQuoteConfirmation: false,
  informationModel: false,
  carrierOrderModel: false,
};

enum QuoteType {
  QUOTED = 'quoted',
  NON_QUOTED = 'non_quoted',
  WITH_RATE = 'with _rate',
  LOAD_BOARD = 'load_board',
  LANE_HISTORY = 'lane_history',
  PREDICTIVE_PRICING = 'predictive_pricing',
}

const QuoteDetailPage = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);
  const [isRateLoading, setIsRateLoading] = useState(true);
  const [addressDetails, setAddressDetails] = useState<any>({});
  const [dimensions, setDimensions] = useState<any[]>([]);
  const [totalUnit, setTotalUnit] = useState<number | string>();
  const [totalWeight, setTotalWeight] = useState<number | string>();
  const [services, setServices] = useState<any[]>([]);
  const [rates, setRates] = useState<any>({});
  let [carrierRates, setCarrierRates] = useState<any[]>([]);
  const [action, setAction] = useState(initAction);
  const [selectedCarrierRates, setSelectedCarrierRates] = useState<any>([]);
  const carrierRatesRef = useRef(carrierRates); // Ref to store latest carrier rates

  const updateCarrierRates = (newCarrierRates: any) => {
    carrierRatesRef.current = newCarrierRates;
  };

  const [sortData, setSortData] = useState<{
    sortField: string;
    sortType: 'desc' | 'asc';
  }>({ sortField: 'finalCharge', sortType: 'asc' });

  const [activeQuoteParams, setActiveQuoteParams] = useState<{
    sortField: string;
    sortType: 'desc' | 'asc';
  }>({ sortField: 'finalCharge', sortType: 'asc' });

  const [colors, setColors] = useState<any>({});
  const [isGetRate, setIsGetRate] = useState(false);
  const [quote, setQuote] = useState<any>({});
  const [isFinish, setIsFinish] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
  const [quoteType, setQuoteType] = useState<QuoteType>(QuoteType.WITH_RATE);
  const [withRateList, setWithRateList] = useState<any[]>([]);
  const [quotedList, setQuotedList] = useState<any[]>([]);
  const [nonQuotedList, setNonQuotedList] = useState<any[]>([]);
  const [isAllCarrierRateFetched, setIsAllCarrierRateFetched] =
    useState<boolean>(true);
  const [carrierRateFetchStatus, setCarrierRateFetchStatus] = useState<any>({});
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);
  const [carrierList, setCarrierList] = useState<any>([]);
  const { currency, setCurrency } = useContext(BasicContext);
  const [search, setSearch] = useState('');
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  // load link
  const [loadLinkPostingDetail, setLoadLinkPostingDetail] = useState<
    any | undefined
  >(undefined);
  const [
    isShowLoadLinkPostingDeleteConfirmation,
    setIsShowLoadLinkPostingDeleteConfirmation,
  ] = useState(false);
  const [isLoadLinkPostingDeleteLoading, setIsLoadLinkPostingDeleteLoading] =
    useState(false);
  const [syncLiveData, setSyncLiveData] = useState<boolean>(false);
  const [isUpdateLoadPost, setIsUpdateLoadPost] = useState<boolean>(false);

  const sortDataByIsQuoted = (data: any) =>
    data.sort((a: any, b: any) => {
      const aIsQuotedFalse = a?.services.some(
        (service: any) => service.isQuoted === false
      );
      const bIsQuotedFalse = b?.services.some(
        (service: any) => service.isQuoted === false
      );

      return aIsQuotedFalse === bIsQuotedFalse ? 0 : aIsQuotedFalse ? 1 : -1;
    });

  const sortActiveCarrierRates = (
    carrierRatesValue: any,
    isSortTrue = false
  ) => {
    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];
    const quotedCarrierRates: any = [];
    const openedCarrierRates: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        carrierRate.provider = carrierRate.isBanyan
          ? 'Banyan'
          : carrierRate.isFreightcom
          ? 'FrightCom'
          : 'Direct';

        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);

          if (carrierRate.carrierStatus === STATUS.OPENED) {
            openedCarrierRates.push(carrierRate);
          } else if (carrierRate.carrierStatus === addressDetails.status) {
            quotedCarrierRates.push(carrierRate);
          }
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }
    const lowestCharge: any = minBy(validCarrierRates, 'finalCharge');
    const fastestDelivery: any = minBy(
      validCarrierRates,
      'projectedDeliveryDate'
    );
    const appendCarrierRates = validCarrierRates.map((carrierRate: any) => {
      const tags = [];

      if (carrierRate.finalCharge === lowestCharge.finalCharge) {
        tags.push('Cheapest');
      }

      if (
        carrierRate.projectedDeliveryDate ===
        fastestDelivery.projectedDeliveryDate
      ) {
        tags.push('Fastest');
      }
      carrierRate.tags = tags;

      return carrierRate;
    });
    const sortedCarrierRates = orderBy(
      appendCarrierRates,
      activeQuoteParams.sortField,
      activeQuoteParams.sortType
    );
    const allCarrierRates = sortedCarrierRates.concat(invalidCarrierRates);

    updateCarrierRates(allCarrierRates);
    setCarrierRates(allCarrierRates);
    setNonQuotedList(invalidCarrierRates);

    if (!isSortTrue) {
      setQuotedList(
        sortDataByIsQuoted(
          orderBy(
            quotedCarrierRates,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
    } else {
      setTimeout(() => {
        setQuotedList(
          sortDataByIsQuoted(
            orderBy(
              quotedCarrierRates,
              activeQuoteParams.sortField,
              activeQuoteParams.sortType
            )
          )
        );
      }, 400);
    }
  };

  const sortCarrierRates = (carrierRatesValue: any, isSortTrue = false) => {
    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];
    const quotedCarrierRates: any = [];
    const openedCarrierRates: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        carrierRate.provider = carrierRate.isBanyan
          ? 'Banyan'
          : carrierRate.isFreightcom
          ? 'FrightCom'
          : 'Direct';

        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);

          if (carrierRate.carrierStatus === STATUS.OPENED) {
            openedCarrierRates.push(carrierRate);
          } else if (carrierRate.carrierStatus === addressDetails.status) {
            quotedCarrierRates.push(carrierRate);
          }
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }
    const lowestCharge: any = minBy(validCarrierRates, 'finalCharge');
    const fastestDelivery: any = minBy(
      validCarrierRates,
      'projectedDeliveryDate'
    );
    const appendCarrierRates = validCarrierRates.map((carrierRate: any) => {
      const tags = [];

      if (carrierRate.finalCharge === lowestCharge.finalCharge) {
        tags.push('Cheapest');
      }

      if (
        carrierRate.projectedDeliveryDate ===
        fastestDelivery.projectedDeliveryDate
      ) {
        tags.push('Fastest');
      }
      carrierRate.tags = tags;

      return carrierRate;
    });
    const sortedCarrierRates = orderBy(
      appendCarrierRates,
      sortData.sortField,
      sortData.sortType
    );
    const allCarrierRates = sortedCarrierRates.concat(invalidCarrierRates);

    updateCarrierRates(allCarrierRates);
    setCarrierRates(allCarrierRates);
    setNonQuotedList(invalidCarrierRates);

    if (!isSortTrue) {
      setWithRateList(
        sortDataByIsQuoted(
          orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
        )
      );
    } else {
      setTimeout(() => {
        setWithRateList(
          sortDataByIsQuoted(
            orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
          )
        );
      }, 400);
    }
  };

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      name: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      name: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  const handleAllColors = (quoteStatus: any) => {
    const status = quoteStatus;
    const colorsObj = {
      badgeColor: ' bg-gray-100 ',
      badgeText: ' text-grayText ',
      textColor: ' text-black ',
      bgColor: '',
      borderColor: '',
    };

    switch (status) {
      case STATUS.OPENED:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.ACTIVE:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.WON:
        colorsObj.badgeColor = ' bg-green100 ';
        colorsObj.badgeText = ' text-green ';
        colorsObj.textColor = ' text-green ';
        colorsObj.bgColor = ' bg-green100 ';
        colorsObj.borderColor = ' border-green ';
        break;
      case STATUS.LOST:
      case STATUS.EXPIRED:
        colorsObj.badgeColor = ' bg-red-100 ';
        colorsObj.badgeText = ' text-danger ';
        colorsObj.borderColor = ' border-danger ';
        break;
      default:
        break;
    }
    setColors(colorsObj);
  };

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => console.error('Pricing rates error'));
  };

  useEffect(() => {
    getRate();
  }, []);

  const handleSearchFilter = () => {
    const searchTermLower = search.toLowerCase();
    const matchedVal = carrierRates.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTermLower) ||
        (item.provider &&
          item.provider.toLowerCase().includes(searchTermLower)) ||
        (item.tags &&
          item.tags.some((tag: any) =>
            tag.toLowerCase().includes(searchTermLower)
          )) ||
        (item.carrierQuoteId &&
          item.carrierQuoteId.toLowerCase().includes(searchTermLower))
    );

    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];
    const quotedCarrierRates: any = [];
    const openedCarrierRates: any = [];

    if (matchedVal?.length) {
      matchedVal.forEach((carrierRate: any) => {
        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);

          if (carrierRate.carrierStatus === STATUS.OPENED) {
            openedCarrierRates.push(carrierRate);
          } else if (carrierRate.carrierStatus === addressDetails.status) {
            quotedCarrierRates.push(carrierRate);
          }
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }

    setNonQuotedList(invalidCarrierRates);
    setWithRateList(
      orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
    );
  };

  useEffect(() => {
    handleSearchFilter();
  }, [search]);

  useEffect(() => {
    if (id) {
      localStorage.removeItem('isModifyQuoteFromQuote');
      localStorage.removeItem('isPickupDateChanged');
      localStorage.removeItem('modifyQuoteCode');

      if (isRefresh) {
        getQuote(id)
          .then((response: any) => {
            if (
              response &&
              response.data &&
              response.data.addressDetails &&
              response.data.addressDetails.status !== STATUS.PENDING
            ) {
              const responseData = response.data;
              const { additionalService } = responseData;
              setIsCustomerDeleted(
                responseData?.addressDetails.isCustomerDeleted
              );
              setQuote(responseData);

              if (responseData?.addressDetails) {
                setAddressDetails(responseData?.addressDetails);
              }

              setLoadLinkPostingDetail(responseData?.loadLinkPostingDetail);

              if (responseData?.dimensions) {
                setDimensions(responseData.dimensions);
                let unit = 0;
                let weight = 0;
                responseData.dimensions.forEach((val: any) => {
                  unit += val.handlingUnitNo || 0;
                  weight += val.totalWeight;
                });
                setTotalWeight(weight);
                setTotalUnit(unit);
              }

              if (additionalService) {
                setServices(additionalService);
              }
              setIsLoading(false);

              if (
                responseData?.carrierRates &&
                responseData?.carrierRates.length
              ) {
                sortCarrierRates(responseData?.carrierRates);
                sortActiveCarrierRates(responseData?.carrierRates);
                setIsLoading(false);
              } else {
                setIsGetRate(true);
              }

              handleAllColors(responseData?.addressDetails?.status);
            } else {
              navigate(ROUTES.HOME);
            }
          })
          .finally(() => setIsRefresh(false))
          .catch(console.error);
      }
    } else {
      navigate(ROUTES.HOME);
    }
  }, [isRefresh]);

  const connectSocket = () => {
    setIsFinish(false);

    if (!isSocketConnected) {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      ).then(([io]: any) => {
        io.sails.url = window.SERVER_URL;
        io.socket.on('connect', function socketConnected() {
          io.socket.get(
            `/subscribe/${currentUser.id}`,
            function (data: any, jwr: any) {
              if (jwr.error) {
                return;
              }
              setIsSocketConnected(true);
            }
          );
        });

        io.socket.on('disconnect', () => {
          setIsSocketConnected(false);
        });

        io.socket.on('user', function (data: any) {
          if (data.type === 'NEW_RATE') {
            if (data?.data && data?.data?.quoteId === parseInt(id)) {
              let newCarrierRates = [...carrierRatesRef.current, data.data];

              sortCarrierRates(newCarrierRates);
              sortActiveCarrierRates(newCarrierRates);
              setIsLoading(false);
            }
          } else if (data?.type === 'FINISH_LOADING') {
            carrierRateFetchStatus[data.carrier] = true;
            setCarrierRateFetchStatus((old: any) => ({
              ...old,
              [data.carrier]: true,
            }));
            // setIsFinish(true);
          }
        });
      });
    }
  };

  const handleActionType =
    (actionKey: string, mode: string = '', carrierRate: any = null) =>
    () => {
      if (carrierRate) {
        let newCarrierRate: any = {};

        if (currency === CURRENCY.USD) {
          newCarrierRate = { ...carrierRate };
          newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
          newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
          newCarrierRate.margin = carrierRate.marginUSD;
          newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

          if (newCarrierRate.services.length) {
            newCarrierRate.services = newCarrierRate.services.map(
              (item: any) => ({
                ...item,
                value: item.valueUSD,
              })
            );
          }
        } else {
          newCarrierRate = { ...carrierRate };
        }
        setSelectedCarrierRates([newCarrierRate]);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
    setSelectedCarrierRates([]);
  }, []);

  const handleSubmit = () => {
    navigate(ROUTES.HOME);
  };

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [currency]);

  useEffect(() => {
    if (isGetRate) {
      setCarrierList([]);
      listCarrier({
        search: '',
        sortDirection: 'DESC',
        sortBy: 'quoteCount',
        page: 1,
        limit: 100,
        type: 'connection',
      })
        .then((response: any) => {
          let carriers = response?.data || [];

          if (carriers?.length) {
            connectSocket();
            setIsFinish(false);
            setIsAllCarrierRateFetched(false);
            updateCarrierRates([]);
            setCarrierRates([]);
            setWithRateList([]);
            setNonQuotedList([]);
            // sortCarrierRates([]);
          }

          setCarrierList(carriers || []);
        })
        .finally(() => {
          // setIsLoading(false);
          // setIsRefresh(false);
        })
        .catch(console.log);
    } else {
      setIsFinish(true);
    }
  }, [isGetRate]);

  useEffect(() => {
    if (addressDetails?.classification === QUOTE_CLASSIFICATION.FTL) {
      setQuoteType(QuoteType.LOAD_BOARD);
    }

    if (carrierRates?.length) {
      sortCarrierRates(carrierRates, true);
    }
  }, [sortData, addressDetails]);

  useEffect(() => {
    if (carrierRates?.length) {
      sortActiveCarrierRates(carrierRates, true);
    }
  }, [activeQuoteParams, addressDetails]);

  const modifyQuoteStatus = (quoteStatusId: number) => () => {
    modifyQuote({ quoteId: quoteStatusId })
      .then((response) => {
        if (response && response.data) {
          const newQuoteId = response.data?.id;
          const isModifyQuoteFromQuote: any = quoteStatusId;
          localStorage.setItem(
            'isModifyQuoteFromQuote',
            isModifyQuoteFromQuote
          );
          localStorage.setItem('modifyQuoteCode', addressDetails?.code);

          if (response.data?.isDateChange) {
            localStorage.setItem(
              'isPickupDateChanged',
              response.data?.isDateChange
            );
          }

          if (isCustomerDeleted) {
            navigate(`${PATH.ADDRESS_DETAILS}/${newQuoteId}`);
          } else {
            navigate(`${PATH.DIMENSIONS}/${newQuoteId}`);
          }
        }
      })
      .catch(() => WalToast.error('Quote has not been modified', ''));
  };

  const updateSelectedCarrierRates = (allNonQuotedList: any) => {
    const filteredList = allNonQuotedList.filter(
      (carrierRate: any) =>
        !(carrierRate?.isSpotRequestSent || !carrierRate?.spotQuoteEmail)
    );

    return filteredList;
  };

  const ReqQuoteHeadCells = useMemo(
    () => [
      {
        id: 'Name',
        name: 'Carrier',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'DeliveryTime',
        name: 'Delivery Time',
        sortable: false,
        rowClassName: '',
      },
      // {
      //     id: "TransitTime",
      //     name: "Transit time",
      //     sortable: true,
      //     visible: true,
      // },
      {
        id: 'ProjectedDelivery',
        name: 'Projected Delivery',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'BaseQuote',
        name: 'Base Quote',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'AdditionalServices',
        name: 'Additional Services',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'Total',
        name: 'Total',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'action',
        name: (
          <div className="">
            <CheckBox
              checked={
                (allChecked ||
                  updateSelectedCarrierRates(nonQuotedList).length ===
                    selectedCarrierRates.length) &&
                selectedCarrierRates.length != 0
              }
              onChangeFunc={(e: any) => {
                setAllChecked(e.target.checked);

                if (e.target.checked) {
                  const filteredList =
                    updateSelectedCarrierRates(nonQuotedList);
                  setSelectedCarrierRates(filteredList);
                } else {
                  setSelectedCarrierRates([]);
                }
              }}
              parentClassName=""
            />
          </div>
        ),
        sortable: false,
        rowClassName: 'w-[5%]',
      },
    ],
    [allChecked, selectedCarrierRates]
  );

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [quoteType]);

  const tooltipItems = (
    <div className="">
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <Edit05 className="w-4 h-4 text-gray500" />
        Modify quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <RefreshCcw05 className="w-4 h-4 text-gray500" />
        Rollover
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <XClose className="w-4 h-4 text-gray500" />
        No quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center">
        <HelpCircle className="w-4 h-4 text-gray500" />
        Request help
      </div>
    </div>
  );

  const WithRatesHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
        rowClassName: 'min-w-64 w-[350px]',
      },
      {
        id: 'tags',
        name: 'Delivery',
        sortable: true,
        rowClassName: 'max-w-48',
      },
      {
        id: 'provider',
        name: 'Provider',
        sortable: true,
        rowClassName: 'w-48',
      },
      {
        id: 'transitTime',
        name: 'Transit Time',
        sortable: true,
        rowClassName: 'w-44',
      },
      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
        rowClassName: 'w-44',
      },
      {
        id: 'totalCharge',
        name: 'Base Quote',
        sortable: true,
        rowClassName: 'w-36',
      },

      {
        id: 'additionalChargesUSD',
        name: 'Additional Services',
        sortable: true,
        rowClassName: 'w-36',
      },
      {
        id: 'finalCharge',
        name: 'Total Price',
        sortable: true,
        rowClassName: 'w-48',
      },
      {
        id: 'action',
        rowClassName: 'text-sm w-3.5',
      },
    ],
    []
  );

  const QuotedHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
        rowClassName: '',
        visible: true,
      },
      {
        id: 'provider',
        name: 'Provider',
        sortable: true,
        rowClassName: '',
        visible: true,
      },
      {
        id: 'transitTime',
        name: 'Transit Time',
        sortable: true,
        visible: true,
      },
      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
        visible: true,
      },
      {
        id: 'totalCharge',
        name: 'Base Quote',
        sortable: true,
        visible: true,
      },

      {
        id: 'additionalChargesUSD',
        name: 'Additional Services',
        sortable: true,
        visible: true,
      },
      {
        id: `${currency === CURRENCY.CAD ? 'totalCostCAD' : 'totalCostUSD'}`,
        name: 'Total Cost',
        sortable: true,
        visible: true,
      },
      {
        id: 'margin',
        name: 'Margin',
        sortable: true,
        visible: true,
      },

      {
        id: 'finalCharge',
        name: 'Total Price',
        sortable: true,
        visible: true,
      },
      {
        id: 'action',
        visible: true,
      },
    ],
    []
  );

  const tabOptionsArr = [
    {
      value: QuoteType.QUOTED,
      label: 'Quoted',
    },
    {
      value: QuoteType.WITH_RATE,
      label: 'Direct Carrier Rates',
    },
    {
      value: QuoteType.NON_QUOTED,
      label: 'Request Spot Quote',
    },
  ];

  const requestSpotQuote = (
    sentEmailFrom?: any,
    isDoNotAskChecked: boolean = false
  ) => {
    const quoteIds = selectedCarrierRates.map((item: any) => item.id);
    const data: any = {
      doNotAskSpotQuote: isDoNotAskChecked,
      sentEmailFrom: sentEmailFrom,
      ids: quoteIds,
    };
    setSubmitLoading(true);
    spotQuoteRequest(data)
      .then(() => {
        setSubmitLoading(false);
        setSelectedCarrierRates([]);
        setAllChecked(false);
        setAction(initAction);

        if (isDoNotAskChecked) {
          setCurrentUser({
            ...currentUser,
            doNotAskSpotQuote: isDoNotAskChecked,
          });
        }
        setIsRefresh(true);
        WalToast.success('Spot quote request sent successfully', '');
      })
      .finally(() => setIsLoading(false))
      .catch(() => {
        setSelectedCarrierRates([]);
        setSubmitLoading(false);
        setAction(initAction);
      });
  };

  const handleModalClose = useCallback(
    (
        status: boolean,
        modalName?: any,
        sentEmailFrom?: any,
        isDoNotAskChecked: boolean = false
      ) =>
      () => {
        if (modalName === 'informationModel' && status) {
          setAction({
            ...initAction,
            carrierOrderModel: false,
            [modalName]: status,
          });
        }

        if (modalName) {
          setAction({ ...initAction, [modalName]: status });
        }

        if (!status) {
          setAction(initAction);
        }

        if (status) {
          if (action.informationModel) {
            setAction(initAction);
            setSelectedCarrierRates([]);
            setIsRefresh(true);
          } else if (action.spotQuoteConfirmation) {
            requestSpotQuote(isDoNotAskChecked, sentEmailFrom);
          }
        }

        if (action.orderSuccessModel) {
          setIsRefresh(true);
        } else if (action.informationModel) {
          setSelectedCarrierRates([]);
        } else if (action.addQuoteDetail || action.informationModel) {
          setSelectedCarrierRates([]);
        }
      },
    [action]
  );

  const handleQuoteClick = (carrierRate: any) => {
    if (
      (carrierRate?.isSpotRequestSent || !carrierRate?.spotQuoteEmail) &&
      quoteType === QuoteType.NON_QUOTED
    ) {
      return;
    }

    let newCarrierRate: any = {};

    if (currency === CURRENCY.USD) {
      newCarrierRate = { ...carrierRate };
      newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
      newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
      newCarrierRate.margin = carrierRate.marginUSD;
      newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

      if (newCarrierRate.services.length) {
        newCarrierRate.services = newCarrierRate.services.map((item: any) => ({
          ...item,
          value: item.valueUSD,
        }));
      }
    } else {
      newCarrierRate = { ...carrierRate };
    }

    if (
      selectedCarrierRates.filter((rate: any) => rate.id === carrierRate.id)
        .length
    ) {
      newCarrierRate = {};
      setSelectedCarrierRates((old: any) =>
        old.filter((item: any) => item.id !== carrierRate.id)
      );
    } else {
      setSelectedCarrierRates((old: any) => [...old, newCarrierRate]);
    }
  };

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  useEffect(() => {
    if (carrierRates?.length) {
      setIsGetRate(false);
    }
  }, [carrierRates]);

  // useEffect(() => {
  //   if (isSocketConnected && carrierList?.length && isGetRate) {
  //     carrierList.forEach((element: any) => {
  //       setCarrierRateFetchStatus((old: any) => ({
  //         ...old,
  //         [element.slug]: false,
  //       }));

  //       let formData = quote;
  //       formData.carrierId = element.id;
  //       createRate(formData)
  //         .then()
  //         .finally()
  //         .catch((error: any) => {
  //           console.error(error);
  //           WalToast.error(
  //             error?.response?.data?.message ||
  //               'Carrier rates have not been created',
  //             ''
  //           );
  //         });
  //     });
  //   }
  // }, [isSocketConnected]);

  useEffect(() => {
    if (isSocketConnected && carrierList?.length && isGetRate) {
      setIsRateLoading(true);

      const ratePromises = carrierList.map((element: any) => {
        setCarrierRateFetchStatus((old: any) => ({
          ...old,
          [element.slug]: false,
        }));
        let formData = quote;
        formData.carrierId = element.id;

        return createRate(formData).catch((error: any) => {
          console.error(error);
          WalToast.error(
            error?.response?.data?.message ||
              'Carrier rates have not been created',
            ''
          );
        });
      });

      Promise.all(ratePromises)
        .then(() => {
          setIsRateLoading(false);
        })
        .catch((error) => {
          console.error('Error processing rates:', error);
          setIsRateLoading(false);
        });
    }
  }, [isSocketConnected, carrierList]);

  useEffect(() => {
    let allFetched: boolean = true;

    for (const key in carrierRateFetchStatus) {
      if (!carrierRateFetchStatus[key]) {
        allFetched = false;
      }
    }
    setIsAllCarrierRateFetched(allFetched);
  }, [carrierRateFetchStatus]);

  useEffect(() => {
    if (isAllCarrierRateFetched) {
      setIsFinish(true);
      setCarrierRateFetchStatus({});
    }
  }, [isAllCarrierRateFetched]);

  const rightSideContent = (
    <div className="flex sms:flex-nowrap flex-wrap sm:justify-end justify-start items-center lg:gap-x-4 sm:gap-x-3 gap-x-2 mdm:w-auto md:w-full whitespace-nowrap">
      <TabButton
        tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
        parentClassName="w-full currency-tab quoting-currency"
        childrenClassName="test"
        activeClassName="text-grayLight900 bg-utilityGray100"
        className=""
        isTooltip={true}
        tabArray={filterArr}
        handleOnClick={(e: any) => {
          setCurrency(e.target.dataset.value);
        }}
        isActive={currency}
      />
      <div className="!hidden">
        {!isLoading && (
          <>
            <TooltipCmp
              message={tooltipItems}
              type="light"
              openOnClick
              isArrow={false}
            >
              <ButtonCmp
                type="submit"
                className="btn_secondary_black lg:!p-[9px] !p-2"
              >
                <DotsVertical className="w-4 h-4" />
              </ButtonCmp>
            </TooltipCmp>
          </>
        )}
      </div>
      <div className="flex flex-wrap  justify-end lg:gap-4 gap-3 mdm:flex-none md:flex-1 sms:flex-none flex-1">
        {!isLoading && (
          <ButtonCmp
            type="submit"
            className="btn_primary w-full xs:!text-xs !text-[11px] sm:!px-3 !px-2"
            onClick={modifyQuoteStatus(addressDetails.id)}
          >
            {addressDetails.status === STATUS.OPENED
              ? 'Modify Quote'
              : 'Duplicate Quote'}
          </ButtonCmp>
        )}
      </div>
      {addressDetails.status === STATUS.ACTIVE ||
      addressDetails.status === STATUS.WON ||
      addressDetails.status === STATUS.EXPIRED ||
      addressDetails.status === STATUS.LOST ? (
        <>
          <ButtonCmp
            type="button"
            className="btn_secondary_black mdm:flex-none md:flex-1 sms:flex-none flex-1 xs:!text-xs !text-[11px] sm:!px-3 !px-2"
            onClick={handleActionType('pdf')}
          >
            View Generated PDFs
          </ButtonCmp>
        </>
      ) : (
        !isLoading && <></>
      )}

      <ButtonCmp
        type="button"
        className="btn_secondary_black !p-2 flex-none"
        onClick={handleSubmit}
      >
        <XClose className="w-4 h-4" />
      </ButtonCmp>
    </div>
  );

  const handleLoadPostingDelete = (status: boolean) => {
    if (status) {
      // deleteLoadLinkPosting
      setIsLoadLinkPostingDeleteLoading(true);
      deleteLoadLinkPosting(loadLinkPostingDetail?.id)
        .then(() => {
          WalToast.success(
            `Load link posting was removed from WALHQ & Load Link`
          );
          setIsRefresh(true);
        })
        .catch((e) => {
          WalToast.error(
            e?.response?.data?.message ??
              'Something went wrong while deleting Load Link Posting.'
          );
        })
        .finally(() => {
          setIsLoadLinkPostingDeleteLoading(false);
          setIsShowLoadLinkPostingDeleteConfirmation(false);
        });
    } else {
      setIsShowLoadLinkPostingDeleteConfirmation(false);
    }
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={`Quote: #${addressDetails.code ?? ''}`}
            leftContent={
              <Copy05
                className="w-5 h-5 text-primary cursor-pointer font-bold"
                onClick={() => copyDebounce(addressDetails.code ?? '')}
              />
            }
            countBadge={
              isLoading ? (
                <div className="flex h-6 w-20 border justify-center items-center border-gray300 rounded-lg gap-1 custom-loading">
                  <span className="rounded-full p-1 bg-warning500"></span>
                  <span className="text-xs">Opened </span>
                </div>
              ) : (
                <BadgeCmp
                  style="modern"
                  type={
                    addressDetails.status === 'Won'
                      ? 'success'
                      : addressDetails.status === 'Active'
                      ? 'lightblue'
                      : addressDetails.status === 'Opened'
                      ? 'primary'
                      : addressDetails.status === 'Expired' ||
                        addressDetails.status === 'Lost'
                      ? 'error'
                      : 'success'
                  }
                >
                  {addressDetails.status === 'Opened'
                    ? 'Open'
                    : addressDetails.status === 'Active'
                    ? 'Quoted'
                    : addressDetails.status}
                </BadgeCmp>
              )
            }
            desc="View carrier rates and create a quote for your shipment."
            rightSideContent={rightSideContent}
            labelClassName={`${colors.badgeColor} ${colors.badgeText} ${colors.borderColor}`}
            labelTextColor={`${colors.badgeText}`}
            mainContainerClassName="xls:!flex-nowrap !flex-wrap mdm:!flex-row md:!flex-col sm:!flex-row flex-col mdm:!items-center !items-start"
            rightClassName="mdm:min-w-fit md:min-w-full sm:min-w-fit min-w-full !w-fit"
          />
        }
      >
        <QuoteDetailTable
          dimensions={dimensions}
          isLoading={isLoading}
          handleActionType={handleActionType}
          addressDetails={addressDetails}
          totalWeight={totalWeight}
          totalUnit={totalUnit}
        />

        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="w-full py-3 px-5 flex mdm:flex-row flex-col flex-wrap mdm:justify-between mdm:items-center gap-3 border-b border-utilityGray200">
            <div
              className={`w-auto bg-white text-textSecondary items-center text-center rounded-md border border-borderPrimary text-xs whitespace-nowrap font-semibold ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              <div
                className={`py-2 sm:px-4 px-3 cursor-pointer rounded-md text-xs mdm:flex-none flex-1 bg-gray50 `}
                // onClick={() => setQuoteType(QuoteType.QUOTED)} // Not needed cause we have only one tab here
              >
                Active Quotes
              </div>
            </div>

            {/* TO DO NEW QUOTING DASHBOARD FUNCTIONALITY */}

            <div className="gap-3 flex flex-wrap whitespace-nowrap">
              <ButtonCmp
                type="button"
                className="btn_secondary_black mdm:flex-none flex-1 xls:min-w-[180px] mdm:min-w-[auto] sm:w-auto md:min-w-[calc(50%_-_8px)] sm:min-w-[auto] min-w-[calc(50%_-_8px)] "
                icon={<Plus className="w-4 h-4" />}
              >
                Add Quick Quote
              </ButtonCmp>
              <TooltipCmp message={'View PDF History'}>
                <ButtonCmp
                  type="submit"
                  disabled={selectedCarrierRates.length <= -1}
                  className="btn_secondary_black lg:px-[9px] px-2"
                  onClick={handleActionType('pdf')}
                >
                  <FileDownload03 className="w-4 h-4" />
                </ButtonCmp>
              </TooltipCmp>
              <div className="relative ">
                <TooltipCmp message={'Generate PDF'}>
                  <ButtonCmp
                    type="submit"
                    disabled={selectedCarrierRates.length <= -1}
                    className="btn_secondary_black lg:px-[9px] px-2"
                  >
                    <FilePlus03 className="w-4 h-4" />
                  </ButtonCmp>
                </TooltipCmp>
                <div
                  className={` rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-normal my-1.5 z-[1] absolute w-[200px] right-0 hidden`}
                >
                  <h5 className="px-1.5 pb-[5px] font-semibold ">Language</h5>
                  <div className="flex bg-gray50 px-2 py-1.5 items-center rounded-md">
                    <p className="flex-1 text-wrap whitespace-normal max-w-[calc(100%_-_24px)]">
                      English
                    </p>
                    <Check className="w-4 h-4 ml-2 text-primary" />
                  </div>
                  <div className="flex  px-2 py-1.5 items-center rounded-md">
                    <p className="flex-1 text-wrap whitespace-normal max-w-[calc(100%_-_24px)]">
                      French
                    </p>
                    <Check className="w-4 h-4 ml-2 text-primary" />
                  </div>
                  <h5 className="px-1.5 pb-[5px] pt-2 font-semibold ">
                    Display
                  </h5>
                  <div className="flex px-2 py-1.5 items-center rounded-md">
                    <p className="flex-1 text-wrap whitespace-normal max-w-[calc(100%_-_24px)]">
                      Display Carrier Names
                    </p>
                    <Check className="w-4 h-4 ml-2 text-primary" />
                  </div>
                  <div className="flex px-2 py-1.5 items-center rounded-md">
                    <p className="flex-1 text-wrap whitespace-normal max-w-[calc(100%_-_24px)]">
                      Display Est. Delivery & Transit Time
                    </p>
                    <Check className="w-4 h-4 ml-2 text-primary" />
                  </div>
                  <div className="mx-1.5">
                    <ButtonCmp className="btn_primary w-full mt-1 ">
                      Generate
                    </ButtonCmp>
                  </div>
                </div>
              </div>

              <ButtonCmp
                type="button"
                className="btn_secondary_black lg:!px-[9px] !px-2 "
              >
                <Trash01 className="w-4 h-4" />
              </ButtonCmp>
            </div>
          </div>

          <div className="max-h-[calc(100vh_-_600px)] min-h-[205px]  w-full border-t border-gray100 flex-1 flex flex-col">
            <div className="overflow-x-auto custom-scrollbar custom-scrollbar-v2 custom-vertical-scrollbar-hide flex flex-col justify-between  ">
              <TableCmp
                headCells={QuotedHeadCells}
                tableDataArr={quotedList}
                TableLoaderRowCmp={QuotedLoading}
                TableRowCmp={QuotedRow}
                params={activeQuoteParams}
                setParams={setActiveQuoteParams}
                tableRowCmpProps={{
                  handleActionType: handleActionType,
                  currency: currency,
                  addressDetails: addressDetails,
                  isFinish: isFinish,
                  selectedCarrierRates: selectedCarrierRates,
                }}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={5}
                isTableRowClickable={false}
              />
            </div>
            {quotedList.length <= 0 && !isLoading && (
              <NotFoundUI
                iconClassName="hidden"
                title={
                  <>
                    <BadgeCmp
                      style="pill"
                      type="red"
                      mainClassName="rounded-md mb-2"
                    >
                      <div className="flex gap-1">
                        <Clock className="w-4 h-4" />
                        <LiveTimer startDate={addressDetails?.createdAt} />
                      </div>
                    </BadgeCmp>
                    <div>No Active Quotes</div>
                  </>
                }
                desc="Push a carrier rate below or add a quick quote to quote your customer and get a proof of quote."
                containerClassName=" !h-auto !my-[30px]"
              />
            )}
          </div>
        </div>

        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col flex-1">
          <div className="table-bottom-header table-tab-wrap border-b border-utilityGray200 items-center">
            <div className="table-header-bottom-left ">
              <div
                className={`w-fit bg-white text-textSecondary items-center text-center rounded-md border border-borderPrimary text-xs whitespace-nowrap font-semibold sm:flex hidden ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {addressDetails?.classification !==
                  QUOTE_CLASSIFICATION.FTL && (
                  <>
                    <div
                      className={`py-2 sm:px-4 px-3 cursor-pointer rounded-l-md text-xs mdm:flex-none flex-1 ${
                        quoteType === QuoteType.WITH_RATE ? 'bg-gray50' : ''
                      } ${
                        addressDetails.status !== STATUS.OPENED
                          ? ''
                          : 'rounded-l-md'
                      } ${window.MODE === 'production' ? 'rounded-r-md' : ''}`}
                      onClick={() => setQuoteType(QuoteType.WITH_RATE)}
                    >
                      Direct Carrier Rates
                    </div>

                    {window.MODE !== 'production' && (
                      <div
                        className={`py-2 sm:px-4 px-3 cursor-pointer rounded-r-md border-l border-borderPrimary text-xs mdm:flex-none flex-1 ${
                          quoteType === QuoteType.NON_QUOTED ? 'bg-gray50' : ''
                        }`}
                        onClick={() => setQuoteType(QuoteType.NON_QUOTED)}
                      >
                        Request Spot Quote
                      </div>
                    )}
                  </>
                )}

                {addressDetails?.classification === QUOTE_CLASSIFICATION.FTL &&
                  window.MODE !== 'production' && (
                    <div
                      className={`py-2 sm:px-4 px-3 cursor-pointer rounded-r-md rounded-l-md border-borderPrimary text-xs mdm:flex-none flex-1 ${
                        quoteType === QuoteType.LOAD_BOARD ? 'bg-gray50' : ''
                      }`}
                      onClick={() => setQuoteType(QuoteType.LOAD_BOARD)}
                    >
                      Post to Load Board{' '}
                      {loadLinkPostingDetail?.leadsCount && (
                        <BadgeCmp
                          style="pill"
                          type="primary"
                          mainClassName="ml-1"
                        >
                          {loadLinkPostingDetail?.leadsCount}
                        </BadgeCmp>
                      )}
                    </div>
                  )}

                {/* {addressDetails?.classification === QUOTE_CLASSIFICATION.FTL &&
                  window.MODE !== 'production' && (
                    <div
                      className={`py-2 sm:px-4 px-3 cursor-pointer rounded-r-md border-l border-borderPrimary text-xs mdm:flex-none flex-1 ${
                        quoteType === QuoteType.LANE_HISTORY ? 'bg-gray50' : ''
                      }`}
                      onClick={() => setQuoteType(QuoteType.LANE_HISTORY)}
                    >
                      Lane History
                    </div>
                  )}

                {addressDetails?.classification === QUOTE_CLASSIFICATION.FTL &&
                  window.MODE !== 'production' && (
                    <div
                      className={`py-2 sm:px-4 px-3 cursor-pointer rounded-r-md border-l border-borderPrimary text-xs mdm:flex-none flex-1 ${
                        quoteType === QuoteType.PREDICTIVE_PRICING
                          ? 'bg-gray50'
                          : ''
                      }`}
                      onClick={() => setQuoteType(QuoteType.PREDICTIVE_PRICING)}
                    >
                      Predictive Pricing
                    </div>
                  )} */}
              </div>
            </div>
            {quoteType !== QuoteType.LOAD_BOARD && (
              <InputText
                inputName="searchQuote"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                isClearable
                onChangeFunc={(e: any) => {
                  setSearch(e.target.value);
                }}
                parentClassName="table-searchInput"
              />
            )}
            <SelectBox
              name="role"
              placeholder="Carrier Rates"
              parentClassName="sm:hidden block w-full"
              labelClassName="form_label"
              value={tabOptionsArr.find((val: any) => val.value === quoteType)}
              onChangeFunc={(event: any) => setQuoteType(event.value)}
              options={tabOptionsArr}
            />
            {quoteType === QuoteType.WITH_RATE && (
              <>
                <ButtonCmp
                  type="submit"
                  disabled={selectedCarrierRates.length <= 0}
                  className="btn_primary  btn-heigh-auto sm:min-w-fit min-w-full"
                  onClick={handleActionType('pricing', 'create')}
                >
                  Select Rates
                </ButtonCmp>
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() => setIsGetRate(true)}
                  disabled={!isAllCarrierRateFetched}
                  loading={!isAllCarrierRateFetched}
                >
                  {isAllCarrierRateFetched && (
                    <RefreshCcw04 className="w-4 h-4" />
                  )}
                </ButtonCmp>
              </>
            )}
            {quoteType === QuoteType.NON_QUOTED && (
              <ButtonCmp
                type="submit"
                disabled={selectedCarrierRates.length <= 0}
                className="btn_primary  btn-heigh-auto sm:min-w-fit min-w-full"
                onClick={() =>
                  handleActionType('spotQuoteConfirmation', 'view')()
                }
              >
                Request Spot
              </ButtonCmp>
            )}
            {quoteType === QuoteType.LOAD_BOARD && loadLinkPostingDetail && (
              <>
                <div className="text-grayLight900 text-xs font-normal flex items-center">
                  Posted on :&nbsp;
                  <img src={doubleCheck} className="mr-0.5" />
                  <span className="font-semibold text-fgSuccessPrimary">
                    {moment(loadLinkPostingDetail?.createdAt).format(
                      'MMM D, YYYY h:mm A'
                    )}
                  </span>
                </div>

                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() => setIsUpdateLoadPost(true)}
                  disabled={isUpdateLoadPost}
                >
                  <Edit05 className="w-4 h-4" />
                </ButtonCmp>
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() =>
                    setIsShowLoadLinkPostingDeleteConfirmation(true)
                  }
                  disabled={isLoadLinkPostingDeleteLoading}
                  loading={isLoadLinkPostingDeleteLoading}
                >
                  <Trash01 className="w-4 h-4" />
                </ButtonCmp>
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() => setSyncLiveData(true)}
                  disabled={syncLiveData}
                  loading={syncLiveData}
                >
                  {!syncLiveData && <RefreshCcw04 className="w-4 h-4" />}
                </ButtonCmp>
              </>
            )}
          </div>

          {quoteType === QuoteType.NON_QUOTED && (
            <div className="h-full lg:min-h-[122px] w-full  border-t border-gray100">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                <TableCmp
                  headCells={ReqQuoteHeadCells}
                  tableDataArr={nonQuotedList}
                  TableLoaderRowCmp={ReqQuoteLoading}
                  TableRowCmp={ReqQuotedRow}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    currentUser: currentUser,
                    requestSpotQuote: requestSpotQuote,
                    selectedCarrierRates: selectedCarrierRates,
                    allChecked: allChecked,
                    setAllChecked: setAllChecked,
                  }}
                  isTableDataLoading={isLoading || !isFinish}
                  isTableDataLoadingWithData={!isAllCarrierRateFetched}
                  numberOfSkeletonRows={8}
                  isTableRowClickable={true}
                  onRowClick={handleQuoteClick}
                />
              </div>
              {nonQuotedList.length <= 0 && isFinish && !isLoading && (
                <NotFoundUI
                  title="No Quote Found"
                  desc="There are no rates for current quote"
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                />
              )}
            </div>
          )}

          {quoteType === QuoteType.WITH_RATE && (
            <div className="h-full lg:min-h-[122px] w-full border-t border-gray100">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                <TableCmp
                  headCells={WithRatesHeadCells}
                  tableDataArr={withRateList}
                  TableLoaderRowCmp={WithQuoteLoading}
                  TableRowCmp={WithRateQuote}
                  params={sortData}
                  setParams={setSortData}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    addressDetails: addressDetails,
                    isFinish: isFinish,
                    selectedCarrierRates: selectedCarrierRates,
                  }}
                  onRowClick={handleQuoteClick}
                  isTableDataLoading={!isFinish || isLoading || isRateLoading}
                  isTableDataLoadingWithData={!isAllCarrierRateFetched}
                  numberOfSkeletonRows={5}
                  isTableRowClickable={true}
                />
              </div>
              {withRateList.length <= 0 &&
                isFinish &&
                !isLoading &&
                !isRateLoading && (
                  <NotFoundUI
                    title="No Quote Found"
                    desc="There are no rates for current quote"
                    containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                  />
                )}
            </div>
          )}

          {quoteType === QuoteType.LOAD_BOARD && (
            <PostToLoadBoard
              loadLinkPostingDetail={loadLinkPostingDetail}
              addressDetails={addressDetails}
              syncLiveData={syncLiveData}
              setSyncLiveData={setSyncLiveData}
              setLoadLinkPostingDetail={setLoadLinkPostingDetail}
              isUpdateLoadPost={isUpdateLoadPost}
              setIsUpdateLoadPost={setIsUpdateLoadPost}
              setIsRefresh={setIsRefresh}
              isRefresh={isRefresh}
            />
          )}
          {quoteType === QuoteType.LANE_HISTORY && <LaneHistory />}
          {quoteType === QuoteType.PREDICTIVE_PRICING && (
            <PredictivePriceChart />
          )}
        </div>
      </PageSectionLayout>

      <PDF
        isOpen={action.pdf}
        id={id}
        handleClose={handleDrawerClose}
        addressDetails={addressDetails}
      />

      {action.informationModel && (
        <ProceedWithOrderFlow
          selectedCarrierRates={selectedCarrierRates}
          addressDetails={addressDetails}
          handleModalClose={handleModalClose}
          setIsRefresh={setIsRefresh}
        />
      )}

      <QuoteDetail
        isOpen={action.quoteDetail}
        handleClose={handleModalClose(false)}
        quote={{ addressDetails, dimensions, services, totalUnit, totalWeight }}
      />

      <PricingDetails
        isOpen={action.pricingDetails}
        handleClose={handleDrawerClose}
        quote={{ addressDetails, dimensions, services }}
        carrierRate={selectedCarrierRates[0]}
      />

      <Pricing
        isOpen={action.pricing}
        mode={action.mode}
        selectedCarrierRates={selectedCarrierRates}
        quoteID={id}
        setIsRefresh={setIsRefresh}
        handleClose={handleDrawerClose}
        currency={currency}
        dimensions={dimensions}
        services={services}
        setQuoteType={setQuoteType}
      />

      <AddQuoteDetail
        isOpen={action.addQuoteDetail}
        handleClose={handleDrawerClose}
        carrierRate={selectedCarrierRates[0]}
        currency={currency}
        setIsRefresh={setIsRefresh}
      />

      {action.spotQuoteConfirmation && (
        <SpotQuoteConfirmation
          handleClose={handleModalClose}
          services={services}
          selectedCarrierRates={selectedCarrierRates}
          dimensions={dimensions}
          addressDetails={addressDetails}
          loading={submitLoading}
        />
      )}

      {isShowLoadLinkPostingDeleteConfirmation && (
        <CommonModal
          title="Remove Load Link Posting"
          titleDesc="This action will remove load link posting from WAL HQ and Load Link."
          handleClose={() =>
            isLoadLinkPostingDeleteLoading
              ? false
              : handleLoadPostingDelete(false)
          }
          secondaryBtnOnClick={() => handleLoadPostingDelete(false)}
          dangerBtnText="Remove"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleLoadPostingDelete(true)}
          primaryBtnLoading={isLoadLinkPostingDeleteLoading}
          primaryBtnDisabled={isLoadLinkPostingDeleteLoading}
          secondaryBtnDisabled={isLoadLinkPostingDeleteLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}

      {action.carrierOrderModel && (
        <FinalizeOrderModal
          selectedCarrierRates={selectedCarrierRates}
          handleClose={handleModalClose}
          carrierRate={selectedCarrierRates[0]}
        />
      )}
    </>
  );
};

export default QuoteDetailPage;
