import React from 'react';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

interface IProps {
  data: any;
}

const CustomerSplitAsideTableFooterRow = ({ data }: IProps) => (
  <>
    <td className="px-5 py-4 max-w-44 w-44 min-w-44 text-pretty font-semibold text-sm bg-gray50 ">
      <div>Total</div>
    </td>

    <td className="px-5 py-4 font-semibold text-sm bg-gray50">
      <div>
        {getFormattedNumber(parseFloat(data.orderCount).toFixed(2), false)}
      </div>
    </td>

    <td className="px-5 py-4 font-semibold text-sm bg-gray50">
      <div>${getFormattedNumber(parseFloat(data?.profit).toFixed(2))}</div>
    </td>
  </>
);

export default CustomerSplitAsideTableFooterRow;
