import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
// import CustomPagination from 'src/components/CustomPagination';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { commissionOrderList } from 'src/services/CommissionReportService';

import ConflictsCommissionReportLoadingRow from './ConflictsCommissionReportLoadingRow';
import ConflictsCommissionReportRow from './ConflictsCommissionReportRow';
import SalesRepComissionReportLoadingRow from './SalesRepComissionReportLoadingRow';
import SalesRepCommissionReportRow from './SalesRepCommissionReportRow';
import UserCommissionReportLoadingRow from './UserCommissionReportLoadingRow';
import UserCommissionReportRow from './UserCommissionReportRow';

// import TableCmp from 'src/components/TableCmp';

export const ordersTimeFilterArray: any = [
  {
    value: 'all',
    name: 'View All',
  },
  {
    value: 'commissioned',
    name: 'Commissioned Orders',
  },
  {
    value: 'activeClaims',
    name: 'Active Claims',
  },
  {
    value: 'activeDisputes',
    name: 'Active Disputes',
  },
];

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 100,
  type: 'common',
  provider: '',
  id: '2',
  month: '2024-08',
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const SalesRepCommissionReport = () => {
  const [params, setParams] = useState(initParams);
  const [searchValue, setSearchValue] = useState('');

  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  console.log(
    '🚀 ~ SalesRepCommissionReport ~ isOrdersLoading:',
    isOrdersLoading
  );
  const [orders, setOrders] = useState<any[]>([]);

  const headCells = useMemo(
    () => [
      {
        id: 'orderId',
        name: 'Order ID',
        sortable: true,
        visible: true,
      },
      {
        id: 'status',
        name: 'Status',
        sortable: true,
        visible: true,
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        sortable: true,
        visible: true,
      },
      {
        id: 'combinedCost',
        name: 'Total Cost',
        sortable: true,
        visible: true,
      },
      {
        id: 'combinedRevenue',
        name: 'Total Revenue',
        sortable: true,
        visible: true,
      },
      {
        id: 'combinedMargin',
        name: 'Margin',
        sortable: true,
        visible: true,
      },
      {
        id: 'quoteCount',
        name: 'Total Price',
        sortable: true,
        visible: true,
      },
    ],
    []
  );

  const CommissionUserHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
      },
      {
        id: 'commissionPeriod',
        name: 'Commission period',
      },
      {
        id: 'totalmargin',
        name: 'Total Margin',
      },
      {
        id: 'totalMarginPercentage',
        name: 'Total Margin Percentage',
      },
      {
        id: 'AvgMarginPerLoad',
        name: 'Avg. Margin Per Load',
      },
      {
        id: 'numberOfLoadsProjected',
        name: 'Number of Loads Projected',
      },
    ],
    []
  );

  const WarningHeadCells = useMemo(
    () => [
      {
        id: 'orderId',
        name: 'Order ID',
      },
      {
        id: 'commissionPeriod',
        name: 'Commission period',
      },
      {
        id: 'status',
        name: 'Status',
      },
      {
        id: 'orderCreated',
        name: 'Order Created',
      },
      {
        id: 'totalCost',
        name: 'Total Cost',
      },
      {
        id: 'totalRevenue',
        name: 'Total Revenue',
      },

      {
        id: 'margin',
        name: 'Margin',
      },
      {
        id: 'totalPrice',
        name: 'Total Price',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  useEffect(() => {
    setIsOrdersLoading(true);
    setOrders([]);

    commissionOrderList(params).then((response: any) => {
      console.log('response.data', response.data);

      setOrders(response.data ?? []);
      setIsOrdersLoading(false);
    });
  }, []);

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Commission Reports"
            desc="Set and view monthly sales targets for your sales team."
          />
        }
      >
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="w-full ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
              <TableCmp
                headCells={CommissionUserHeadCells}
                tableDataArr={[{}]}
                TableLoaderRowCmp={UserCommissionReportLoadingRow}
                TableRowCmp={UserCommissionReportRow}
              />
            </div>
          </div>
        </div>
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className="table-title-wrap">
                <h5 className="table-title">Conflicts/Warnings</h5>
                <BadgeCmp style="modern" type="success" mainClassName={''}>
                  15 Conflicts
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Conflicts and Warnings Needed Timely Resolution
              </p>
            </div>
          </div>
          <div className="max-h-[calc(100vh_-_600px)] min-h-[205px]  w-full border-t border-gray100 flex-1 flex flex-col">
            <div className="overflow-x-auto custom-scrollbar custom-scrollbar-v2 custom-vertical-scrollbar-hide flex flex-col justify-between  ">
              <TableCmp
                headCells={WarningHeadCells}
                tableDataArr={[{}]}
                TableLoaderRowCmp={ConflictsCommissionReportLoadingRow}
                TableRowCmp={ConflictsCommissionReportRow}
              />
            </div>

            <NotFoundUI
              iconClassName="hidden"
              title="No Conflicts/Warnings Found"
              desc="There are no data for Conflicts/Warnings."
              containerClassName=" !h-auto !my-[30px]"
            />
          </div>
        </div>
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col flex-1">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className={`table-title-wrap`}>
                <h5 className="table-title">
                  {/* {params.type === 'common' ? 'Common LTL' : 'Private'} */}
                  Commission Order Breakdown
                </h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  // mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {/* {totals} {totals > 1 ? 'Carriers' : 'Carrier'} */}
                  15 Order
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View complete order history for this sales person.
              </p>
            </div>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <TabButton
                className="table-tab max-xxl:!px-3"
                tabArray={ordersTimeFilterArray}
                parentClassName="table-tabs"
                // isActive={timeFilter}
                handleOnClick={() => {
                  // settimeFilter(e.target.dataset.value);
                  setParams((old: any) => ({
                    ...old,
                    ...{ page: 1 },
                  }));
                }}
              />
            </div>
            <InputText
              inputName="searchOrder"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={searchValue}
              inputType="text"
              isClearable={true}
              parentClassName="table-searchInput"
              onChangeFunc={(e) => {
                setSearchValue(e.target.value);
              }}
            />

            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                placeholder="10"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>
          <div
            data-test-id="userTest"
            className="h-full lg:min-h-[122px] w-full border-t border-gray100 "
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                // tableDataArr={staticData}
                tableDataArr={orders}
                TableLoaderRowCmp={SalesRepComissionReportLoadingRow}
                TableRowCmp={SalesRepCommissionReportRow}
                params={params}
                setParams={setParams}
                // tableRowCmpProps={{
                //   handleActionType: handleActionType,
                // }}
                isTableDataLoading={false}
                // numberOfSkeletonRows={params.limit}
                isTableRowClickable={true}
              />
            </div>
            <NotFoundUI
              iconClassName="hidden"
              title="No Commission Order Breakdown Found"
              desc="There are no data for Commission Order Breakdown."
              containerClassName=""
            />
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={100}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </PageSectionLayout>
    </>
  );
};

export default SalesRepCommissionReport;
