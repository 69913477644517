import React from 'react';

const TraderOnboarding = ({ data }: any) => (
  <>
    <td className="px-5 py-4">
      <span>{data.company_name}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.city}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.phone_number}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.email}</span>
    </td>
  </>
);

export default TraderOnboarding;
