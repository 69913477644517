import { Check, ChevronUp } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getDateWithSuffixFormat,
  getLabelByValue,
} from 'src/utils/CommonFunctions';

import profile1 from '../../../../../assets/img/Avatar1.png';

const QuoteDetailTable = ({
  isLoading,
  dimensions,
  handleActionType,
  addressDetails,
  totalWeight,
  totalUnit,
}: any) => (
  <>
    <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden">
      <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
        <table className="w-full text-xs text-left text-nowrap whitespace-nowrap">
          <thead className="text-xs text-grayLight600 font-medium bg-gray50 border-b border-utilityGray200">
            <tr className="border-b border-utilityGray200 [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50">
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>User</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Customer</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium">
                <div className="flex items-center">
                  <span>Addresses</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Pickup Date</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Total Weight</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>№ of HU</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Freight Class</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>
                    Dimensions (
                    {dimensions?.length > 0
                      ? getLabelByValue(
                          dimensions[0]?.lengthMeasurement
                        )?.toLowerCase()
                      : 'lbs'}
                    )
                  </span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium min-w-32 w-32 max-w-32">
                <div className="flex items-center">
                  <span></span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {!isLoading ? (
              <tr
                className="text-grayLight600 font-normal text-xs  [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all cursor-pointer duration-[0.5s]"
                onClick={handleActionType('quoteDetail', '')}
              >
                <td className="px-5 py-4 min-w-52">
                  <div className="flex items-center">
                    <img
                      src={
                        addressDetails.image
                          ? addressDetails.imageUrl + addressDetails.image
                          : profile1
                      }
                      className="w-8 h-8  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                    />
                    <div className="pl-3 max-w-32">
                      <h6 className="text-grayLight900 font-medium leading-tight truncate">
                        {addressDetails.firstName} {addressDetails.lastName}
                      </h6>
                      <p className="text-grayLight600 font-normal leading-tight truncate">
                        #{addressDetails.code ?? ''}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-4 min-w-40 w-40 max-w-40">
                  <TooltipCmp
                    message={
                      addressDetails?.customerName?.length > 20
                        ? addressDetails?.customerName
                        : null
                    }
                    parentClassName="md:!p-3 !p-1.5 max-w-90"
                  >
                    <p className="max-w-40 truncate">
                      {addressDetails?.customerName}
                    </p>
                  </TooltipCmp>
                </td>
                <td className="px-5 py-4 text-xs ">
                  <TooltipCmp
                    message={addressDetails.shipperFullAddress}
                    parentClassName="md:!p-3 !p-1.5 max-w-90"
                  >
                    <p className="flex ">
                      <span className="text-gray500 font-normal">
                        From:&nbsp;
                      </span>
                      <span className="font-medium truncate max-w-52">
                        {addressDetails.shipperFullAddress}
                      </span>
                    </p>
                  </TooltipCmp>
                  <TooltipCmp
                    message={addressDetails.consigneeFullAddress}
                    parentClassName="md:!p-3 !p-1.5 max-w-90"
                  >
                    <p className="flex">
                      <span className="text-gray500 font-normal">
                        To:&nbsp;
                      </span>
                      <span className="font-medium truncate max-w-52">
                        {addressDetails.consigneeFullAddress}
                      </span>
                    </p>
                  </TooltipCmp>
                </td>

                <td className="px-5 py-4 ">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: addressDetails.pickupDate
                        ? getDateWithSuffixFormat(
                            moment
                              .utc(addressDetails.pickupDate)
                              .format(`MMM Do - YYYY`)
                          )
                        : '-',
                    }}
                  />
                  {/* {moment(addressDetails.pickupDate).format("DD MMMM, YYYY")} */}
                </td>
                <td className="px-5 py-4 ">
                  {totalWeight}{' '}
                  {dimensions.length > 0 &&
                    getLabelByValue(
                      dimensions[0].weightMeasurement
                    )?.toLowerCase()}
                </td>
                <td className="px-5 py-4 ">{totalUnit}</td>
                <td className="px-5 py-4 ">
                  {dimensions.length > 0 ? dimensions?.[0]?.freightClass : 0}
                </td>
                <td className="px-5 py-4 ">
                  {dimensions?.length || 0}{' '}
                  {dimensions?.length &&
                    ((dimensions?.length as number) > 1 ? 'Pieces' : 'Piece')}
                </td>
                <td className="px-5 py-4 min-w-32 w-32 max-w-32">
                  <a
                    className="text-primary underline font-semibold cursor-pointer"
                    onClick={handleActionType('quoteDetail', '')}
                  >
                    View Details
                  </a>
                </td>
              </tr>
            ) : (
              <tr className="text-grayLight600 font-normal text-xs  [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all cursor-pointer duration-[0.5s]">
                <td className="px-5 py-4 min-w-52">
                  <div className="flex items-center">
                    <img className="w-8 h-8 custom-loading rounded-full image-loading" />
                    <div className="pl-3 max-w-52">
                      <h6 className="text-grayLight900 font-medium leading-tight truncate custom-loading ">
                        Use Name Use Name{' '}
                      </h6>
                      <p className="text-grayLight600 font-normal leading-tight truncate custom-loading">
                        #SQ-L-100145
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-4 min-w-52">
                  <p className="max-w-80 truncate custom-loading">
                    Evolution Wheel{' '}
                  </p>
                </td>
                <td className="px-5 py-4 min-w-40 w-40 max-w-40">
                  <p className="flex mb-1 custom-loading">
                    <span className="text-gray500 font-normal">
                      From:&nbsp;
                    </span>
                    <span className="font-medium truncate max-w-52">
                      {addressDetails.shipperFullAddress}
                    </span>
                  </p>
                  <p className="flex custom-loading">
                    <span className="text-gray500 font-normal">To:&nbsp;</span>
                    <span className="font-medium truncate max-w-52">
                      {addressDetails.consigneeFullAddress}
                    </span>
                  </p>
                </td>

                <td className="px-5 py-4 ">
                  <span className="custom-loading">May 14th, 2024 </span>
                </td>
                <td className="px-5 py-4 ">
                  <span className="custom-loading">11</span>
                </td>
                <td className="px-5 py-4 ">
                  <span className="custom-loading"> 500 lbs </span>
                </td>
                <td className="px-5 py-4 ">
                  <span className="custom-loading"> 11 </span>
                </td>
                <td className="px-5 py-4 ">
                  <span className="custom-loading">1 Dimensions</span>
                </td>
                <td className="px-5 py-4 min-w-32 w-32 max-w-32">
                  <span className="custom-loading">View Details</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>

    <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 justify-between flex-col overflow-hidden !hidden">
      <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
        <table className="w-full text-xs text-left text-nowrap whitespace-nowrap">
          <thead className="text-xs text-grayLight600 font-medium bg-gray50 border-b border-utilityGray200">
            <tr className="border-b border-utilityGray200 [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50">
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>User</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Customer</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium">
                <div className="flex items-center">
                  <span>Addresses</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Pickup Date</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Total Weight</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Total № of HU</span>
                </div>
              </th>

              <th className="px-5 py-3 font-medium min-w-14 w-14 max-w-14">
                <div className="flex items-center">
                  <span></span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {!isLoading ? (
              <tr className="text-grayLight600 font-normal text-xs  [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all cursor-pointer duration-[0.5s]">
                <td className="px-5 py-4 flex items-center min-w-64 w-64 max-w-64">
                  <img
                    src={
                      addressDetails.image
                        ? addressDetails.imageUrl + addressDetails.image
                        : profile1
                    }
                    className="w-8 h-8  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                  />
                  <div className="pl-3 max-w-32">
                    <h6 className="text-grayLight900 font-medium leading-tight truncate">
                      {addressDetails.firstName} {addressDetails.lastName}
                    </h6>
                    <p className="text-grayLight600 font-normal leading-tight truncate">
                      #{addressDetails.code ?? ''}
                    </p>
                  </div>
                </td>
                <td className="px-5 py-4 min-w-60 w-60 max-w-60">
                  <TooltipCmp
                    message={
                      addressDetails?.customerName?.length > 30
                        ? addressDetails?.customerName
                        : null
                    }
                    parentClassName="md:!p-3 !p-1.5 max-w-90"
                  >
                    <p className="max-w-44 truncate">
                      {addressDetails?.customerName}
                    </p>
                  </TooltipCmp>
                </td>
                <td className="px-5 py-4 text-xs min-w-80 w-80 max-w-80">
                  <TooltipCmp
                    message={addressDetails.shipperFullAddress}
                    parentClassName="md:!p-3 !p-1.5 max-w-90"
                  >
                    <p className="flex mb-1">
                      <span className="text-gray500 font-normal">
                        From:&nbsp;
                      </span>
                      <span className="font-medium truncate max-w-52">
                        {addressDetails.shipperFullAddress}
                      </span>
                    </p>
                  </TooltipCmp>
                  <TooltipCmp
                    message={addressDetails.consigneeFullAddress}
                    parentClassName="md:!p-3 !p-1.5 max-w-90"
                  >
                    <p className="flex">
                      <span className="text-gray500 font-normal">
                        To:&nbsp;
                      </span>
                      <span className="font-medium truncate max-w-52">
                        {addressDetails.consigneeFullAddress}
                      </span>
                    </p>
                  </TooltipCmp>
                </td>

                <td className="px-5 py-4 ">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: addressDetails.pickupDate
                        ? getDateWithSuffixFormat(
                            moment
                              .utc(addressDetails.pickupDate)
                              .format(`MMM Do - YYYY`)
                          )
                        : '-',
                    }}
                  />
                </td>
                <td className="px-5 py-4 ">
                  {totalWeight}{' '}
                  {dimensions.length > 0 &&
                    getLabelByValue(
                      dimensions[0].weightMeasurement
                    )?.toLowerCase()}
                </td>
                <td className="px-5 py-4 ">{totalUnit}</td>

                <td className="px-5 py-4 min-w-14 w-14 max-w-14">
                  <ChevronUp className="w-4 h-4" />
                </td>
              </tr>
            ) : (
              <tr className="text-grayLight600 font-normal text-xs [&>:last-child]:w-[73px] [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all  duration-[0.5s]">
                <td className="px-5 py-4 flex items-center min-w-64 w-64 max-w-64">
                  <img className="w-8 h-8 custom-loading rounded-full image-loading" />
                  <div className="pl-3 max-w-52">
                    <h6 className="text-grayLight900 font-medium leading-tight truncate custom-loading ">
                      Use Name Use Name{' '}
                    </h6>
                    <p className="text-grayLight600 font-normal leading-tight truncate custom-loading">
                      #SQ-L-100145
                    </p>
                  </div>
                </td>
                <td className="px-5 py-4 min-w-60 w-60 max-w-60">
                  <p className="max-w-44 truncate custom-loading">
                    Evolution Wheel{' '}
                  </p>
                </td>
                <td className="px-5 py-4 text-xs  min-w-80 w-80 max-w-80">
                  <p className="flex mb-1 custom-loading">
                    <span className="text-gray500 font-normal">
                      From:&nbsp;
                    </span>
                    <span className="font-medium truncate max-w-52">
                      {addressDetails.shipperFullAddress}
                    </span>
                  </p>
                  <p className="flex custom-loading">
                    <span className="text-gray500 font-normal">To:&nbsp;</span>
                    <span className="font-medium truncate max-w-52">
                      {addressDetails.consigneeFullAddress}
                    </span>
                  </p>
                </td>

                <td className="px-5 py-4 ">
                  <span className="custom-loading">May 14th, 2024 </span>
                </td>
                <td className="px-5 py-4 ">
                  <span className="custom-loading">11</span>
                </td>
                <td className="px-5 py-4 ">
                  <span className="custom-loading"> 500 lbs </span>
                </td>
                <td className="px-5 py-4 ">
                  <span className="custom-loading"> 11 </span>
                </td>
                <td className="px-5 py-4 ">
                  <span className="custom-loading">1 Dimensions</span>
                </td>
                <td className="px-5 py-4 min-w-14 w-14 max-w-14">
                  <ChevronUp className="w-4 h-4" />
                </td>
              </tr>
            )}
          </tbody>

          <tbody className="text-xs">
            <tr
              className={`border-t border-utilityGray200 text-grayLight600 font-normal text-xs  [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all cursor-pointer duration-[0.5s]`}
            >
              <td className="px-5 py-4 min-w-64 w-64 max-w-64">
                <h6 className="text-gray500 font-normal mb-0.5">
                  Handling unit
                </h6>
                <p className="text-utilityGray800 font-semibold">Pallet</p>
              </td>

              <td className="px-5 py-4 text-xs min-w-60 w-60 max-w-60 ">
                <h6 className="text-gray500 font-normal mb-0.5">№ of HU</h6>
                <p className="text-utilityGray800 font-semibold">01</p>
              </td>

              <td className="px-5 py-4  min-w-80 w-80 max-w-80">
                <h6 className="text-gray500 font-normal mb-0.5">
                  Weight per unit
                </h6>
                <p className="text-utilityGray800 font-semibold">11 lbs</p>
              </td>

              <td className="px-5 py-4 ">
                <h6 className="text-gray500 font-normal mb-0.5">
                  Freight Class
                </h6>
                <p className="text-utilityGray800 font-semibold">50</p>
              </td>
              <td className="px-5 py-4 " colSpan={3}>
                <ul className="flex gap-6">
                  <li className="w-1/3 ">
                    <h6 className="text-gray500 font-normal mb-0.5">
                      Length (in)
                    </h6>
                    <div className="text-utilityGray800 font-semibold flex justify-between">
                      <p>48</p> <span>x</span>
                    </div>
                  </li>
                  <li className="w-1/3 ">
                    <h6 className="text-gray500 font-normal mb-0.5">
                      Width (in)
                    </h6>
                    <div className="text-utilityGray800 font-semibold flex justify-between">
                      <p>48</p> <span>x</span>
                    </div>
                  </li>
                  <li className="w-1/3 ">
                    <h6 className="text-gray500 font-normal mb-0.5">
                      Height (in)
                    </h6>
                    <div className="text-utilityGray800 font-semibold flex justify-between">
                      <p>48</p>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
            <tr
              className="text-grayLight600 font-normal text-xs  [&>:nth-child(1)]:w-50 [&>:nth-child(2)]:w-50 bg-transparent transition-all cursor-pointer duration-[0.5s]"
              onClick={handleActionType('quoteDetail', '')}
            >
              <td className="px-5 pb-4 " colSpan={100}>
                <div className="border-t border-utilityGray200 pt-4">
                  <h6 className="text-gray500 font-normal mb-1.5">
                    Additional Services
                  </h6>
                  <ul className="flex flex-wrap gap-y-1 gap-x-10">
                    <li className="flex gap-1 w-[245px]">
                      <Check className="w-3.5 h-3.5 text-primary mt-[1px]" />
                      <p className="text-utilityGray800 text-xs font-medium text-wrap whitespace-normal">
                        Tailgate Pickup
                      </p>
                    </li>
                    <li className="flex gap-1 w-[245px]">
                      <Check className="w-3.5 h-3.5 text-primary mt-[1px]" />
                      <p className="text-utilityGray800 text-xs font-medium text-wrap whitespace-normal">
                        After hours / weekend / holiday pickup
                      </p>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </>
);

export default QuoteDetailTable;
