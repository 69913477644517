import { Plus, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { BasicContext } from 'src/context/BasicContext';
import { listCarrier } from 'src/services/CarrierService';

import ContactDetails from '../Connections/ContactDetails';

import CarrierRow from './CarrierRow';
import CarriersListLoading from './CarriersListLoading';
import EditCarrier from './EditCarrier';

export const filterArr: any = [
  {
    value: 'common',
    name: 'Common LTL',
  },
  // {
  //   value: 'private',
  //   name: 'Private LTL',
  // },
  // asked by client to hide private ltl temporary
];

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 100,
  type: 'common',
  provider: '',
};

const initAction = {
  mode: null,
  carrier: false,
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const providerArr = [
  { label: 'Banyan', value: 'banyan' },
  { label: 'Freightcom', value: 'freightcom' },
  { label: 'Direct', value: 'direct' },
];

const Carriers = () => {
  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');
  const [carriers, setCarriers] = useState([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [action, setAction] = useState<{
    mode: string | null;
    carrier: boolean;
  }>(initAction);
  const [carrier, setCarrier] = useState<any>({});
  const { currency } = useContext(BasicContext);
  const [activeTab, setActiveTab] = useState('contact');

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
        visible: true,
        rowClassName: 'min-w-96 w-96 max-w-96 cursor-pointer',
      },
      {
        id: 'scope',
        name: 'Scope',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'provider',
        name: 'Provider',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'rateCount',
        name: 'Total Rates',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'quoteCount',
        name: 'Total Quotes',
        sortable: true,
        visible: true,
        rowClassName: 'cursor-pointer',
      },
      {
        id: 'action',
        visible: true,
      },
    ],
    []
  );

  const handleActionType =
    (actionKey: any, mode: any = null, carrierData: any = {}) =>
    () => {
      setCarrier(carrierData);

      if (carrierData.id === 5 && mode === 'view') {
        if (activeTab !== 'limitations') {
          setActiveTab('sub_carrier');
        }
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const getListCarrier = (signal: any) => {
    setCarriers([]);
    setIsLoading(true);
    listCarrier(params, signal)
      .then((response: any) => {
        setCarriers(response.data);
        setTotals(response.total);
        setIsLoading(false);
        setIsRefresh(false);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
        setIsRefresh(false);
      });
  };

  useEffect(() => {
    const carrierApiController = new AbortController();
    const carrierApiSignal = carrierApiController.signal;
    getListCarrier(carrierApiSignal);

    return () => {
      carrierApiController.abort();
    };
  }, [isRefresh, params]);

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
    setCarrier({});
  }, []);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchQuote') {
      setSearch(value);
      setParams((old: any) => ({ ...old, page: 1 }));
      searchDebounce(value);
    }
  };

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Carriers"
            desc="Carriers currently Integrated with Western Alliance Logistics."
            mainContainerClassName="md:flex-nowrap"
          />
        }
      >
        <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className={`table-title-wrap`}>
                <h5 className="table-title">
                  {/* {params.type === 'common' ? 'Common LTL' : 'Private'} */}
                  All Carriers
                </h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {totals} {totals > 1 ? 'Carriers' : 'Carrier'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                {params.type === 'common'
                  ? 'View all available common carriers'
                  : 'View all available private carriers'}
              </p>
            </div>
            <ButtonCmp
              type="button"
              className="btn-outline-primary flex smd:w-fit w-full"
              onClick={handleActionType('carrier', 'add')}
              iconSide="left"
              icon={<Plus className="text-primary w-4 h-4" />}
            >
              Add New Carrier
            </ButtonCmp>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <InputText
                inputName="searchQuote"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                onChangeFunc={handleFilter}
                parentClassName="table-searchInput"
                isClearable={true}
              />
            </div>
            <div className="table-statustbox">
              <SelectBox
                name="provider"
                id="provider"
                className="form_control"
                size="sm"
                placeholder="Filter by provider"
                isClearable={true}
                isSearchable={false}
                options={providerArr}
                onChangeFunc={(event: any) =>
                  setParams((old) => ({
                    ...old,
                    ...{ provider: event?.value, page: 1 },
                  }))
                }
                value={providerArr.filter(
                  (val: any) => params.provider === val.value
                )}
              />
            </div>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>
          <div
            data-test-id="userTest"
            className="h-full lg:min-h-[122px] w-full border-t border-gray100 "
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                tableDataArr={carriers}
                TableLoaderRowCmp={CarriersListLoading}
                TableRowCmp={CarrierRow}
                params={params}
                setParams={setParams}
                tableRowCmpProps={{
                  handleActionType: handleActionType,
                }}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={params.limit}
                isTableRowClickable={true}
              />
            </div>
            {carriers.length <= 0 && !isLoading && (
              <NotFoundUI
                title="No Carriers found"
                desc="There are no data for carriers."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={totals}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>

          {Object.keys(carrier).length > 0 && (
            <ContactDetails
              isOpen={action.mode === 'view'}
              carrier={carrier}
              handleClose={handleDrawerClose}
              getListCarrier={getListCarrier}
              // setIsRefreshMain={setIsRefresh}
              activeTab={activeTab}
              currency={currency}
            />
          )}
          {(action.mode === 'edit' ||
            action.mode === 'add' ||
            action.mode === 'viewCarrier') && (
            <EditCarrier
              isOpen={
                action.mode === 'edit' ||
                action.mode === 'add' ||
                action.mode === 'viewCarrier'
              }
              action={action}
              carrier={carrier}
              handleClose={() => handleDrawerClose()}
              handleActionType={handleActionType}
              getListCarrier={getListCarrier}
              // setIsRefreshMain={setIsRefresh}
              activeTab={activeTab}
              currency={currency}
            />
          )}
        </div>
      </PageSectionLayout>
    </>
  );
};

export default Carriers;
