import moment from 'moment';
import React from 'react';
import check from 'src/assets/img/double-check.svg';
import { TIMEZONE } from 'src/constants/common';
import { getShortName } from 'src/utils/CommonFunctions';

import ButtonCmp from '../ButtonCmp';
import CustomPagination from '../CustomPagination';
import NotFoundUI from '../NotFoundUI';
import TabButton from '../TabButton';

import NotificationSkeletonLoader from './NotificationSkeletonLoader';

interface NotificationProps {
  filterArr?: Array<{ value: string; name: string }>;
  params: any;
  handleOnTabButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
  numberOfSkeletonRows?: number;
  notifications: Array<any>;
  handleNotificationClick: (notification: any) => void;
  handleNotificationStatus: any;
  totals: number;
  setParams: React.Dispatch<React.SetStateAction<any>>;
  notFoundTitle: string;
  notFoundDesc: string;
  setNotFoundTitlec?: React.Dispatch<React.SetStateAction<any>>;
}

const NotificationCmp = ({
  filterArr,
  params,
  handleOnTabButtonClick,
  isLoading,
  numberOfSkeletonRows = 5,
  notifications,
  handleNotificationClick,
  handleNotificationStatus,
  totals,
  setParams,
  notFoundTitle,
  notFoundDesc,
}: NotificationProps) => {
  const getFormattedDateTime = (date: any, convertTimezone = false) => {
    let momentDate = moment(date);

    if (convertTimezone) {
      momentDate = momentDate.utc(date).tz(TIMEZONE);
    }

    return momentDate.fromNow();
  };

  const handlePagination = (page: number) => {
    setParams((old: any) => ({ ...old, page }));
  };

  return (
    <>
      <div className="p-4">
        <h6 className="text-grayLight900 text-xl font-semibold">
          Notifications
        </h6>
      </div>
      {filterArr && filterArr.length > 0 && (
        <TabButton
          tabArray={filterArr}
          tabParentClassName="mt-4 px-4 border-b border-utilityGray200"
          parentClassName=" w-full whitespace-nowrap overflow-x-auto scrollbar-hide !rounded-none"
          className="w-1/3 border-0 border-b-2 !border-r-0 border-transparent !rounded-none"
          activeClassName="border-b !border-primary"
          isActive={params.currentTab}
          handleOnClick={handleOnTabButtonClick}
        />
      )}

      <ul className="p-4 flex-1 flex flex-col gap-y-2.5 overflow-x-auto custom-scrollbar-v2">
        {isLoading &&
          Array(numberOfSkeletonRows)
            .fill(null)
            .map((_, index) => <NotificationSkeletonLoader key={index} />)}

        {notifications &&
          notifications.length > 0 &&
          notifications.map((notification: any) => (
            <li
              className="border border-utilityGray200 bg-white hover:bg-gray50 cursor-pointer rounded-lg p-4 flex gap-x-4 relative"
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
            >
              {(notification.notifier_first_name || notification.image) && (
                <div className="w-10 h-10 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary  flex-none  overflow-hidden">
                  {notification.image ? (
                    <img
                      src={notification.imageUrl + notification.image}
                      className="h-full w-full"
                    />
                  ) : (
                    <label className="h-full w-full flex items-center justify-center text-sm">
                      {getShortName(
                        `${notification.notifier_first_name} ${notification.notifier_last_name}`
                      )}
                    </label>
                  )}
                </div>
              )}
              <div>
                <h6
                  className="text-sm text-grayLight600 font-normal"
                  dangerouslySetInnerHTML={{ __html: notification.message }}
                ></h6>
                <div className="flex flex-wrap items-center gap-x-1 mt-1">
                  <p className="text-grayLight600 text-xs font-medium ">
                    {notification.subject}
                  </p>

                  <p className="w-1 h-1 bg-utilityBlack opacity-30 rounded-full"></p>
                  <p className="text-gray500 text-xs font-normal">
                    {getFormattedDateTime(notification.createdAt, true)}
                  </p>
                </div>
              </div>
              {!notification.isRead && (
                <span className="bg-successSecondary w-1.5 h-1.5 absolute rounded-full top-2 right-2"></span>
              )}
            </li>
          ))}

        {!isLoading && notifications && notifications.length === 0 && (
          <NotFoundUI
            title={notFoundTitle}
            desc={notFoundDesc}
            containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto my-auto"
            testId="wal-Claims-dataNotFoundText"
          />
        )}
      </ul>
      <div className="p-4 flex justify-between border-t border-utilityGray200">
        <ButtonCmp
          className="btn_secondary_black"
          icon={<img src={check} />}
          onClick={() => {
            if (notifications.length > 0) handleNotificationStatus(false);
          }}
          disabled={notifications && notifications.length == 0}
        >
          Mark as all read
        </ButtonCmp>
        <CustomPagination
          recordsPerPage={params.limit}
          totalRecords={totals}
          currentPage={params.page}
          handlePagination={handlePagination}
          isShowArrowAlways={true}
          parentClassName="border-t-0 !p-0 !w-auto"
          isShowPageDetail={false}
        />
      </div>
    </>
  );
};

export default NotificationCmp;
