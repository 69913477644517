import moment from 'moment';
import React from 'react';

const OverviewOnboarding = ({ data }: any) => (
  <>
    <td className="px-5 py-4 ">
      <span>{data.company_name}</span>
    </td>
    <td className="px-5 py-4 ">
      <span>
        {data.start_date && moment(data.start_date).format('DD MMMM YYYY')}
      </span>
    </td>
    <td className="px-5 py-4 ">
      <span>{data.contact_number}</span>
    </td>
    <td className="px-5 py-4 ">
      <span>{data.fax}</span>
    </td>
    <td className="px-5 py-4 ">
      <span>{data.company_email}</span>
    </td>
  </>
);

export default OverviewOnboarding;
