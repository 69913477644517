import 'cropperjs/dist/cropper.css';

import { Crop02, File06 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

import CommonModal from '../CommonModal';

const PdfPreviewModal = ({
  modalTitle,
  modalDesc,
  setUpload,
  displayPDF,
  imageUpload,
  setIsFileUploadLoading,
  isFileUploadLoading,
  displayPDFUrl,
}: any) => {
  const handleSubmit = () => {
    imageUpload(displayPDF);
    setIsFileUploadLoading(true);
  };

  return (
    <>
      <CommonModal
        title={modalTitle}
        titleDesc={modalDesc}
        handleClose={() => {
          if (isFileUploadLoading) {
            return;
          }
          setUpload(false);
        }}
        headerIcon={<Crop02 className="w-5 h-5" />}
        size={'max-w-[580px]'}
        primaryBtnText="Save"
        secondaryBtnText="Cancel"
        primaryBtnOnClick={handleSubmit}
        primaryBtnLoading={isFileUploadLoading}
        secondaryBtnOnClick={() => {
          setUpload(false);
        }}
        secondaryBtnDisabled={isFileUploadLoading}
      >
        <div className="p-5 h-[600px]">
          {displayPDFUrl && displayPDFUrl.length > 0 ? (
            <iframe
              src={displayPDFUrl}
              title="PDF Preview"
              className="w-full h-full rounded-xl"
            ></iframe>
          ) : (
            <div className="h-[320px] mb-6 overflow-hidden rounded-3xl app-logo-img-block bg-baseBg flex justify-center items-center">
              <File06 className="w-24 h-24 text-gray-500" />
            </div>
          )}
        </div>
      </CommonModal>
    </>
  );
};

export default PdfPreviewModal;
