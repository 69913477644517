import { CheckCircle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const CreditCustomerLoaderRow = () => (
  <>
    <td className={`px-5 py-4 w-60 min-w-60 max-w-60 `}>
      <div className={`flex items-center gap-3  `}>
        <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none custom-loading">
          WA
        </div>

        <div className="flex items-center gap-1 custom-loading">
          <span className="truncate block max-w-32 text-grayLight900 font-semibold leading-5">
            Camilla Moses
          </span>
        </div>
      </div>
    </td>

    <td className={`px-5 py-4 w-48 min-w-48 max-w-48`}>
      <div className="flex flex-wrap gap-1 custom-loading">
        <BadgeCmp style="modern" type="success">
          Form Completed
        </BadgeCmp>
      </div>
    </td>
    <td className={`px-5 py-4 min-w-60 w-60`}>
      <span className="custom-loading">06 September 2024 11:50 AM</span>
    </td>
    <td className={`px-5 py-4 min-w-60 w-60`}>
      <span className="custom-loading">dibifelocu@mailinator.com</span>
    </td>
    <td className={`px-5 py-4 min-w-60 w-60`}>
      <span className="custom-loading">06 September 2024 11:50 AM</span>
    </td>
    <td className={`px-5 py-4 w-14 min-w-14 max-w-14`}>
      <div className="w-4 h-4 custom-loading">
        <CheckCircle className="text-green500 w-4 h-4" />
      </div>
    </td>
  </>
);

export default CreditCustomerLoaderRow;
