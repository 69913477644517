import React from 'react';

const CustomerListTableRowLoader = () => (
  <>
    <td className="px-5 py-4">
      <div className="flex items-center gap-2 ">
        <div className="w-7 h-7 bg-primary rounded-full text-white flex items-center justify-center uppercase text-xs flex-none custom-loading">
          ts{' '}
        </div>
        <span className="truncate block max-w-64  text-sm whitespace-nowrap font-semibold custom-loading">
          Anicom Signs, Inc.
        </span>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center min-w-92 ">
        <div className="w-5 h-5 bg-primary rounded-full font-medium text-white flex items-center justify-center uppercase text-[8px] p-1 flex-none custom-loading">
          WA
        </div>
        <div className="pl-2 ">
          <h6 className="text-grayLight900 font-medium leading-tight max-w-52 truncate custom-loading">
            Usman Malik
          </h6>
        </div>
      </div>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
  </>
);

export default CustomerListTableRowLoader;
