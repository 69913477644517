import {
  CheckCircle,
  Copy06,
  LinkExternal02,
  User01,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import { PATH } from 'src/constants/path';
import WalToast from 'src/utils/WalToast';

const ReviewOnboarding = ({ handleClose, customerData, setAction }: any) => {
  console.log('dsfsd :>> ');
  const webUrl = window.WEB_URL;

  const handleSendMailBtnClick = () => {
    setAction({ mode: 'send-mail', data: customerData });
  };

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  return (
    <>
      <CommonModal
        title="Review your Onboarding URL"
        titleDesc="Onboarding completed, ready for next steps."
        handleClose={() => handleClose(false)}
        headerIcon={<User01 />}
        size={'max-w-[688px] overflow-unset'}
        secondaryBtnOnClick={() => handleClose(false)}
        modalHeaderClass=""
        primaryBtnOnClick={handleSendMailBtnClick}
        primaryBtnText="Send Mail"
        secondaryBtnText="Close"
      >
        <div className="w-full flex flex-col justify-center items p-5 gap-8">
          <div className="rounded-md border border-utilitysuccess300 bg-green25 px-3 py-2 flex gap-x-1">
            <CheckCircle className="w-4 h-4 text-success700" />
            <div className="text-xs">
              <span className="text-grayLight600 font-normal">
                A unique customer onboarding form was successfully created for{' '}
              </span>
              <span className="text-grayLight900 font-medium">
                {customerData?.name}.
              </span>
            </div>
          </div>
          <div className="">
            <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
              View Onboarding URL
            </h6>
            <div className="rounded-md border border-borderPrimary bg-utilityGray100 shadow-xs px-3 py-2 flex items-center gap-1.5">
              <p className="text-primary700 text-sm font-medium underline flex-1 truncate w-0">
                {webUrl + PATH.CUSTOMER_ONBOARDING}/{customerData?.customerId}
              </p>
              <Copy06
                className="h-4 w-4 text-primary"
                onClick={() =>
                  copyDebounce(
                    `${webUrl + PATH.CUSTOMER_ONBOARDING}/${
                      customerData?.customerId
                    }`
                  )
                }
              />
              <Link
                target="_blank"
                to={`${PATH.CUSTOMER_ONBOARDING}/${customerData?.customerId}`}
              >
                <LinkExternal02 className="h-4 w-4 text-primary " />
              </Link>
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};
export default ReviewOnboarding;
