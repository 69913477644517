import React from 'react';

const BankOnboarding = ({ data }: any) => (
  <>
    <td className="px-5 py-4">
      <span>{data.bank_name}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.bank_contact_name}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.bank_email}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.bank_phone}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.bank_address}</span>
    </td>
  </>
);

export default BankOnboarding;
