import { Grid01, List, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';

import ButtonCmp from '../../components/ButtonCmp';
import InputText from '../../components/InputText/InputText';
import NotFoundUI from '../../components/NotFoundUI';
import PageSectionLayout from '../../components/PageSectionLayout';
import Header from '../../components/PageSectionLayout/Header/Header';
import { BasicContext } from '../../context/BasicContext';
import { listCarrier } from '../../services/CarrierService';

import AddNewCarrierModal from './AddNewCarrierModal';
import CarrierColumnCard from './CarrierColumnCard';
import CarrierGalleryCard from './CarrierGalleryCard';
import CarrierLoadingCard from './CarrierLoadingCard';
import CarrierSpotQuoteModal from './CarrierSpotQuoteModal';
import ContactDetails from './ContactDetails';

const initAction = {
  actionKey: null,
  mode: null,
  carrier: false,
};

const initParams = {
  search: '',
  sortDirection: 'DESC',
  sortBy: 'quoteCount',
  page: 1,
  limit: 10,
  isFreightcom: 0,
};

const Connections = () => {
  const [carriers, setCarriers] = useState([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [action, setAction] = useState<{
    mode: string | null;
    carrier: boolean;
  }>(initAction);
  const [carrier, setCarrier] = useState<any>({});
  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');
  const [isFromRemoveSearch, setIsFromRemoveSearch] = useState(false);

  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get('_cd') !== null ? 'limitations' : 'contact'
  );
  const { currency } = useContext(BasicContext);
  const [currentListViewType, setCurrentListViewType] = useState<
    'gallery' | 'list'
  >('gallery');
  const [carrierFormData, setCarrierFormData] = useState({});

  useEffect(() => {
    setCarriers([]);
    setIsLoading(true);
    listCarrier(params)
      .then((response: any) => {
        setCarriers(response.data);
        setTotals(response.total);

        if (searchParams.get('_cd') !== null && !isFromRemoveSearch) {
          const carrierId = searchParams.get('_cd')
            ? parseInt(searchParams.get('_cd')!)
            : 0;

          let filteredCarrier = response.data.filter(
            (item: any) => item.id === carrierId
          );

          if (filteredCarrier.length && filteredCarrier[0]) {
            filteredCarrier = filteredCarrier[0];
            setCarrier(filteredCarrier);
            const mode: any = 'view';
            setTimeout(() => {
              setAction((old) => ({ ...old, filteredCarrier: true, mode }));
            }, 500);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsRefresh(false);
      })
      .catch(console.log);
  }, [isRefresh, params]);

  useEffect(() => {
    if (searchParams.get('_n') !== null) {
      const searchParam = searchParams.get('_n')!;
      setSearch(searchParam);
      setParams((old) => ({ ...old, ...{ searchParam } }));
    }
  }, []);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleActionType =
    (actionKey: any, mode: any = null, carrierData: any = {}) =>
    () => {
      setCarrier(carrierData);

      if (carrierData.id === 5 && mode === 'view') {
        if (activeTab !== 'limitations') {
          setActiveTab('sub_carrier');
        }
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
    setCarrier({});
  }, []);

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchCarrier') {
      if (searchParams.get('_n') !== null) {
        setAction(initAction);
        setCarrier({});
        setIsFromRemoveSearch(true);
      }
      setSearch(value);
      searchDebounce(value);
    }
  };

  return (
    <PageSectionLayout
      header={
        <Header
          title="Carrier Connections"
          desc="Carriers currently Integrated with Western Alliance Logistics."
          countBadge={
            <BadgeCmp style="modern" type="success">
              {totals} {totals > 1 ? 'Carriers' : 'Carrier'}
            </BadgeCmp>
          }
          rightSideContent={
            <div className="grow shrink basis-0 md:justify-end justify-start items-start gap-4 flex">
              <ButtonCmp
                onClick={() => setCurrentListViewType('gallery')}
                className={`${
                  currentListViewType === 'gallery'
                    ? 'btn_primary'
                    : 'btn_secondary_black px-3'
                } `}
              >
                <Grid01 className="h-4 w-4" />
              </ButtonCmp>
              <ButtonCmp
                onClick={() => setCurrentListViewType('list')}
                className={`${
                  currentListViewType === 'list'
                    ? 'btn_primary'
                    : 'btn_secondary_black px-3'
                } `}
              >
                <List className="h-4 w-4" />
              </ButtonCmp>
              <div>
                <InputText
                  inputName="searchCarrier"
                  placeholder="Search"
                  className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                  icon={
                    <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                  }
                  value={search}
                  isClearable
                  inputType="text"
                  onChangeFunc={handleFilter}
                />
              </div>
            </div>
          }
        />
      }
    >
      <>
        {carriers.length ? (
          <>
            {currentListViewType === 'gallery' && (
              <div className="cards-row-wrap ">
                {carriers.map((carrierData: any, index) => (
                  <CarrierGalleryCard
                    key={index}
                    carrier={carrierData}
                    handleViewDetail={handleActionType(
                      'carrier',
                      'view',
                      carrierData
                    )}
                    handleConfigureCarrier={handleActionType(
                      'carrier',
                      'configure_carrier'
                    )}
                    handleCarrierConnection={handleActionType(
                      'carrier',
                      'carrier-connect',
                      carrierData
                    )}
                  />
                ))}
              </div>
            )}
            {currentListViewType === 'list' && (
              <div className="rounded-[20px] border border-utilityGray200 w-full overflow-hidden bg-white">
                {carriers.map((carrierData: any, index) => (
                  <CarrierColumnCard
                    key={index}
                    carrier={carrierData}
                    handleViewDetail={handleActionType(
                      'carrier',
                      'view',
                      carrierData
                    )}
                    handleCarrierConnection={handleActionType(
                      'carrier',
                      'carrier-connect',
                      carrierData
                    )}
                    handleConfigureCarrier={handleActionType(
                      'carrier',
                      'configure_carrier'
                    )}
                    setAction={setAction}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <>
            {isLoading ? (
              <>
                {currentListViewType === 'gallery' ? (
                  <div className="cards-row-wrap">
                    {[...Array(9)].map((_, index) => (
                      <CarrierLoadingCard
                        key={index}
                        viewType={currentListViewType}
                      />
                    ))}
                  </div>
                ) : (
                  [...Array(9)].map((_, index) => (
                    <CarrierLoadingCard
                      key={index}
                      viewType={currentListViewType}
                    />
                  ))
                )}
              </>
            ) : (
              <div className="h-full w-full flex justify-center items-center">
                <NotFoundUI
                  title="No Carriers Found"
                  desc="There are no data for team members you can create new team member."
                />
              </div>
            )}
          </>
        )}

        {action.mode === 'carrier-connect' && (
          <AddNewCarrierModal
            carrier={carrier}
            handleClose={(carrierData?: any) => {
              if (carrierData) {
                setCarrierFormData(carrierData);
                handleActionType('carrier', 'carrier-spot-quote', carrier)();
              } else {
                handleDrawerClose();
              }
            }}
          />
        )}
        {action.mode === 'carrier-spot-quote' && (
          <CarrierSpotQuoteModal
            carrier={carrier}
            formData={carrierFormData}
            handleClose={handleDrawerClose}
          />
        )}

        {/* {Object.keys(carrier).length > 0 && (
                <ConfigureCarrierModal isOpen={action.mode === "view"} carrier={carrier} handleClose={handleDrawerClose} setIsRefreshMain={setIsRefresh} activeTab={activeTab} currency={currency} />
            )} */}

        {Object.keys(carrier).length > 0 && (
          <ContactDetails
            isOpen={action.mode === 'view'}
            carrier={carrier}
            handleClose={handleDrawerClose}
            setIsRefreshMain={setIsRefresh}
            activeTab={activeTab}
            currency={currency}
          />
        )}
      </>
    </PageSectionLayout>
  );
};

export default Connections;
