import React from 'react';

interface IRowPerPageProps {
  limit: number;
  handleLimit: (limit: number) => void;
}

const CustomRowPerPage = ({ limit, handleLimit }: IRowPerPageProps) => (
  <div className="flex items-center justify-end mt-3">
    <p className="text-grayDark text-sm mr-2">Showing</p>
    <select
      className="rounded-lg border border-gray-300 bg-white py-2 px-3 leading-tight text-gray-500 mt-1"
      value={limit}
      onChange={(e) => handleLimit(Number(e.target.value))}
    >
      {[10, 25, 50, 100].map((pageSize) => (
        <option key={pageSize} value={pageSize}>
          {pageSize}
        </option>
      ))}
    </select>
    <p className="text-grayDark text-sm ml-2">rows</p>
  </div>
);

CustomRowPerPage.defaultProps = {
  recordsPerPage: 10,
  totalRecords: 0,
  currentPage: 1,
};

export default CustomRowPerPage;
