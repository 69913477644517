import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';

import BadgeCmp from '../../../components/BadgeCmp';
import CustomPagination from '../../../components/CustomPagination';
import NotFoundUI from '../../../components/NotFoundUI';
import PageSectionLayout from '../../../components/PageSectionLayout';
import Header from '../../../components/PageSectionLayout/Header/Header';

import FileListRow from './FileListRow';
import FileLoadingRow from './FileLoadingRow';

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'uploadedDate',
  page: 1,
  limit: 10,
};

const SalesBackup = () => {
  const headCells = useMemo(
    () => [
      {
        id: 'fileNameMaster',
        name: 'Master File Name',
        sortable: false,
        rowClassName: 'xlm:w-[21.69%] xlm:min-w-[21.69%]',
      },
      {
        id: 'fileNameUploaded',
        name: 'Uploaded File Name',
        sortable: false,
        rowClassName: 'xlm:w-[15.04%] xlm:min-w-[15.04%] xlm:max-w-[15.04%]',
      },
      {
        id: 'startDate',
        name: 'Date',
        sortable: false,
      },
      {
        id: 'uploadedDate',
        name: 'Uploaded Date',
        sortable: true,
        rowClassName: 'w-[200px] min-w-[200px] max-w-[200px]',
      },
      {
        id: 'firstName',
        name: 'Uploaded By',
        sortable: false,
        rowClassName: 'xlm:w-[14.04%] xlm:min-w-[14.04%] xlm:max-w-[14.04%]',
      },
      {
        id: 'missingData',
        name: 'Warnings',
        sortable: false,
        rowClassName: 'w-32 min-w-32 max-w-32"',
      },
      {
        id: 'action',
        name: '',
        sortable: false,
      },
    ],
    []
  );

  const [params, setParams] = useState(initParams);
  const [isMasterFileListLoading, setIsMasterFileListLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);
  const [uploadMasterDataList, setUploadMasterDataList] = useState<any>({});
  const [uploadMasterDataTotal, setUploadMasterDataTotal] = useState(0);
  const [search, setSearch] = useState<string>('');

  const recordsPerPageArr = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  useEffect(() => {
    setIsRefresh(true);
  }, [params]);

  useEffect(() => {
    if (isRefresh) {
      setIsMasterFileListLoading(true);

      const tempApiFunc: any = (payload: any) => {
        console.log('test api call payload ', payload);

        return new Promise(() => {});
      };

      tempApiFunc({ ...params })
        .then((response: any) => {
          setUploadMasterDataList([]);
          setUploadMasterDataTotal(0);
          console.log(response.data);
          console.log(response.total);
        })
        .catch(console.log)
        .finally(() => {
          setIsMasterFileListLoading(false);
        });
      setIsRefresh(false);
    }
  }, [isRefresh]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchAdvance') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  return (
    <PageSectionLayout
      header={
        <Header
          title="Sales History"
          desc="Upload and process data exports into the sales dashboard."
          mainContainerClassName="!flex-nowrap mdm:!flex-row !flex-col mdm:!items-center !items-start"
          rightClassName="mdm:min-w-fit min-w-full"
        />
      }
    >
      <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
        <div className="table-top-header ">
          <div className="table-left-wrap ">
            <div className="table-title-wrap">
              <h5 className="table-title"> History</h5>
              <BadgeCmp style="modern" type="success">
                {uploadMasterDataTotal} files
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              View the historical uploaded data over time.
            </p>
          </div>
        </div>
        <div className="table-bottom-header">
          <div className="table-header-bottom-left flex-1">
            <InputText
              inputName="searchAdvance"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={search}
              inputType="text"
              isClearable={true}
              parentClassName="table-searchInput sm:min-w-[auto] min-w-full"
              onChangeFunc={handleFilter}
            />
          </div>
          <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArr}
              onChangeFunc={(event: any) => {
                setParams((old) => ({ ...old, limit: event.value }));
              }}
              isSearchable={false}
              value={recordsPerPageArr.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div>
        </div>
        <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <TableCmp
              headCells={headCells}
              tableDataArr={uploadMasterDataList}
              TableLoaderRowCmp={FileLoadingRow}
              TableRowCmp={FileListRow}
              params={params}
              setParams={setParams}
              tableRowCmpProps={{}}
              isTableDataLoading={isMasterFileListLoading}
              numberOfSkeletonRows={10}
              isTableRowClickable={false}
            />
          </div>
          {!(uploadMasterDataList.length > 0) && !isMasterFileListLoading && (
            <NotFoundUI
              title="No uploaded data found"
              desc="No Master and Uploaded file found. Please try again or upload files."
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          )}
        </div>
        {!!uploadMasterDataList.length && (
          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={uploadMasterDataTotal}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>
        )}
      </div>
    </PageSectionLayout>
  );
};

export default SalesBackup;
