import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getFormattedNumber,
  getProgressClass,
  getShortName,
} from 'src/utils/CommonFunctions';

import { IRowProps } from './Target.interface';

const TargetRow = ({ data }: IRowProps) => (
  <>
    <td className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem]">
      <div className="flex items-center gap-2">
        <div className="flex-none">
          {data.image ? (
            <img
              className="w-8 h-8 rounded-full object-contain "
              src={data.imageUrl + data.image}
              alt=""
              title=""
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
              {getShortName(`${data.userName}`)}
            </div>
          )}
        </div>
        <div className="w-32">
          <h6 className="text-grayLight900 font-semibold leading-5 truncate">
            {data.userName.length > 20 ? (
              <TooltipCmp message={data.userName}>{data.userName}</TooltipCmp>
            ) : (
              data.userName
            )}
          </h6>
          {/* <p className="truncate">Account Executive</p> */}
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[150px] w-[12%]">
      <span>
        ${getFormattedNumber(data.completedProfit, false, false, true)}
      </span>
    </td>
    <td className="px-5 py-4 min-w-[150px] w-[12%]">
      <span>${getFormattedNumber(data.targetProfit, false, false, true)}</span>
    </td>

    <td className="px-5 py-4 min-w-[200px] w-[12%]">
      {/* {data.size} */}
      <progress
        className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 ${getProgressClass(
          data.completedProfitPercentage
        )}`}
        value={data.completedProfitPercentage}
        max="100"
      ></progress>
      <span className="text-textSecondary font-medium">
        {data.completedProfitPercentage}%
      </span>
    </td>
    <td className="px-5 py-4 min-w-[100px] w-[10%]">
      <span>{data.completedLoads}</span>
    </td>
    <td className="px-5 py-4 min-w-[100px] w-[10%]">
      <span>{data.targetLoads}</span>
    </td>
    <td className="px-5 py-4 min-w-[200px] w-[12%]">
      {/* {data.asks} */}
      <progress
        className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 ${getProgressClass(
          data.completedLoadsPercentage
        )}`}
        value={data.completedLoadsPercentage}
        max="100"
      ></progress>
      <span className="text-textSecondary font-medium">
        {data.completedLoadsPercentage}%
      </span>
    </td>
    <td className="px-5 py-4 min-w-[100px] w-[10%]">
      <span>{data.customersCount}</span>
    </td>
    {/* <td className="px-5 py-4">
      <div className="flex gap-3 justify-center">
        <Link
          to={`${PATH.TARGET_SET}`}
          className="text-primary text-xs font-semibold underline decoration-1"
          onClick={() => {
            localStorage.setItem(
              TABLE_IDS.TARGET_LIST_BY_CUSTOMER,
              JSON.stringify({
                ...initParams,
                userId: data.userId,
                targetMonth,
              })
            );
          }}
        >
          <div className="px-3 text-primary text-xs font-semibold">
            {data.targetCustomersCount ? 'Edit' : 'Set'}
          </div>
        </Link>
      </div>
    </td> */}
  </>
);

export default TargetRow;
