import React from 'react';

const InternalNotesSkeletonLoader = () => (
  <li className="px-2 py-3 w-full first:border-t-0 border-t border-utilityGray200">
    <div className="flex  gap-3">
      <p className=" w-2 h-2 rounded flex-none mt-3"></p>
      <div className="flex items-center flex-wrap gap-2 w-full">
        <div className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary flex-none relative custom-loading">
          <img
            src="https://storage.googleapis.com/western-alliance-gcp/dce04a0d-73e5-4c98-a05f-531b8a08721b.jpeg"
            className="w-full h-full rounded-full"
          />
          <span className="border-[1.5px] border-white rounded-full bg-danger500 w-2.5 h-2.5 absolute bottom-0 right-0 user-status-58"></span>
        </div>
        <h6 className="text-textSecondary text-sm font-semibold flex-1 truncate ">
          <span className="custom-loading">Jaydip Sapariya</span>
        </h6>
        <span className="text-gray500 text-xs font-normal self-start custom-loading">
          <span className="custom-loading">6 minutes ago</span>
        </span>
        <div className="text-grayLight600 text-xs font-normal w-full custom-loading">
          <span className="custom-loading">
            test test test test test test test test test test
          </span>
        </div>
      </div>
    </div>
  </li>
);

export default InternalNotesSkeletonLoader;
