import moment from 'moment';
import React, { useState, useEffect } from 'react';

const LiveTimer = ({ startDate }: any) => {
  const calculateTimeDifference = () => {
    const now = moment.utc(); // Get the current time in UTC
    const duration = moment.duration(now.diff(moment.utc(startDate)));

    const days = Math.floor(duration.asDays());
    const hours = String(duration.hours()).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');
    const seconds = String(duration.seconds()).padStart(2, '0');

    if (days > 0) {
      return `${days} days, ${hours}:${minutes}:${seconds}`;
    } else {
      return `${hours}:${minutes}:${seconds}`;
    }
  };

  const [timeDifference, setTimeDifference] = useState(
    calculateTimeDifference()
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeDifference(calculateTimeDifference());
    }, 1000);

    return () => clearInterval(timer);
  }, [startDate]);

  return <p>{timeDifference}</p>;
};

export default LiveTimer;
