import {} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TabButton from 'src/components/TabButton';
import { AuthContext } from 'src/context/AuthContext';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';

import InternalChat from './InternalChat';
import InternalNotes from './InternalNotes';

let chatArr = [
  {
    value: 'messages',
    name: 'Messages',
  },
  {
    value: 'internal_notes',
    name: ({ isActive, notesCount }: any) => (
      <div className="flex items-center gap-1 ">
        <p>Internal Notes</p>
        {!!notesCount && (
          <BadgeCmp
            style="pill"
            type={isActive ? 'primary' : 'gray'}
            mainClassName={`!border-borderPrimary`}
          >
            {notesCount ?? 0}
          </BadgeCmp>
        )}
      </div>
    ),
  },
];

export const initParams = {
  search: '',
  currentTab: 'messages',
};

interface IProps {
  claim?: any;
  dispute?: any;
  isClaim: any;
  notesCount: any;
  setNotesCount: any;
  isShowChatAndNotesSideBar: any;
  setIsShowChatAndNotesSideBar: any;
}

const InternalChatAndNotes = ({
  claim,
  dispute,
  isClaim,
  notesCount,
  setNotesCount,
  isShowChatAndNotesSideBar,
}: IProps) => {
  const { currentUser } = useContext(AuthContext);

  const [params, setParams] = useState(initParams);
  const [data, setData] = useState<any>([]);

  //For Internal Note
  const [internalNotes, setInternalNotes] = useState<any[]>([]);

  //For Chat
  const [typingUsers, setTypingUsers] = useState<string[]>([]);
  const [messages, setMessages] = useState<any>([]);
  const [members, setMembers] = useState([]);

  const typingTimeouts = new Map();

  useEffect(() => {
    if (claim) {
      setData(claim);
    }

    if (dispute) {
      setData(dispute);
    }
  }, [claim, dispute]);

  useEffect(() => {
    if (data.id) {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      )
        .then(([io]: any) => {
          io.sails.url = window.SERVER_URL;

          io.socket.on('connect', function socketConnected() {
            io.socket.get(`/subscribe/${currentUser?.id}`, function (jwr: any) {
              if (jwr.error) {
                console.error('Subscription error:', jwr.error);

                return;
              }
            });

            io.socket.get(`/subscribe/globalRoom`, function (jwr: any) {
              if (jwr.error) {
                console.error('Subscription error:', jwr.error);

                return;
              }
            });
          });

          io.socket.on('connect', function socketConnected() {
            let noteChannelName = isClaim
              ? `/subscribe/claim_notes_${data.id}`
              : `/subscribe/dispute_notes_${data.id}`;

            io.socket.get(noteChannelName, (details: any) => {
              console.log(`Joined room: ${JSON.stringify(details)}`);
            });
          });

          io.socket.on('connect', function socketConnected() {
            const messageChannelName = isClaim
              ? `/subscribe/claim_${data.id}`
              : `/subscribe/dispute_${data.id}`;

            io.socket.get(messageChannelName, (details: any) => {
              console.log(`Joined room: ${JSON.stringify(details)}`);
            });
          });

          io.socket.on('internalNotes', (notes: any) => {
            setInternalNotes((prevNotes: any) => [...prevNotes, notes]);
            setNotesCount((prevCount: number) => prevCount + 1);
          });

          io.socket.on('userStatusUpdate', (userStatus: any) => {
            const userStatusElements = document.querySelectorAll(
              `.user-status-${userStatus.userId}`
            );

            if (userStatusElements.length > 0) {
              userStatusElements.forEach((element) => {
                element.classList.remove('bg-successSecondary', 'bg-danger500');

                if (userStatus.onlineStatus === 'online') {
                  element.classList.add('bg-successSecondary');
                } else {
                  element.classList.add('bg-danger500');
                }
              });
            }
          });

          io.socket.on('message', (data2: any) => {
            setTypingUsers((prevTypingUsers) => {
              const newTypingUsers = new Map(
                prevTypingUsers.map((user: any) => [user.id, user])
              );

              if (newTypingUsers.has(data2.sender.id)) {
                newTypingUsers.delete(data2.sender.id);

                if (typingTimeouts.has(data2.sender.id)) {
                  clearTimeout(typingTimeouts.get(data2.sender.id));
                  typingTimeouts.delete(data2.sender.id);
                }
              }

              return Array.from(newTypingUsers.values());
            });

            setMessages((prevMessages: any) => [...prevMessages, data2]);
          });

          io.socket.on('userStatusUpdate', (userStatus: any) => {
            const userStatusElements = document.querySelectorAll(
              `.user-status-${userStatus.userId}`
            );

            if (userStatusElements.length > 0) {
              userStatusElements.forEach((element) => {
                element.classList.remove('bg-successSecondary', 'bg-danger500');

                if (userStatus.onlineStatus === 'online') {
                  element.classList.add('bg-successSecondary');
                } else {
                  element.classList.add('bg-danger500');
                }
              });
            }

            setMembers((prevMembers: any) =>
              prevMembers?.map((member: any) =>
                member.id === userStatus.userId
                  ? { ...member, user_status: userStatus.onlineStatus }
                  : member
              )
            );
          });

          io.socket.on('typing', (typingStatus: any) => {
            setTypingUsers((prevTypingUsers) => {
              const newTypingUsers = new Map(
                prevTypingUsers.map((user: any) => [user.id, user])
              );

              if (
                typingStatus.typing &&
                typingStatus.user.id !== currentUser.id
              ) {
                newTypingUsers.set(typingStatus.user.id, {
                  ...typingStatus.user,
                  lastTyped: Date.now(),
                });

                if (typingTimeouts.has(typingStatus.user.id)) {
                  clearTimeout(typingTimeouts.get(typingStatus.user.id));
                }

                const timeoutId = setTimeout(() => {
                  setTypingUsers((prevTypingUsers2) =>
                    prevTypingUsers2.filter(
                      (user: any) => user.id !== typingStatus.user.id
                    )
                  );
                  typingTimeouts.delete(typingStatus.user.id);
                }, 10000);

                typingTimeouts.set(typingStatus.user.id, timeoutId);
              }

              return Array.from(newTypingUsers.values());
            });
          });

          // Send typing event to server
          const handleTyping = (isTypingValue: any) => {
            const typingChannelName = isClaim
              ? `/typing/claim_${data.id}`
              : `/typing/dispute_${data.id}`;

            io.socket.get(typingChannelName, {
              user: currentUser,
              typing: isTypingValue,
            });
          };

          const inputElement: any = document.getElementById('chatInput');
          inputElement.addEventListener('keypress', () => handleTyping(true));
          inputElement.addEventListener('blur', () => handleTyping(false));

          // Clean up on component unmount
          return () => {
            io.socket.off('internalNotes');
            io.socket.off('message');
            io.socket.off('typing');
            io.socket.off('userStatusUpdate');
          };
        })
        .catch((error) => {
          console.error('Failed to load Sails socket library:', error);
        });
    }
  }, [data.id]);

  return (
    <div
      className={`xl:w-[360px] w-[320px]  bg-white border-l border-utilityGray200 h-full flex flex-col transition-all duration-[0.5s] max-xl:absolute  ${
        isShowChatAndNotesSideBar
          ? 'xl:-mr-0 right-0'
          : 'xl:-mr-[383px] -right-[383px] '
      } `}
    >
      <TabButton
        tabArray={chatArr.map((tab: any) => ({
          ...tab,
          name:
            typeof tab.name === 'function'
              ? tab.name({
                  isActive: params.currentTab === tab.value,
                  notesCount: notesCount,
                })
              : tab.name,
        }))}
        tabParentClassName=""
        parentClassName="w-full whitespace-nowrap overflow-x-auto scrollbar-hide !rounded-none border-b border-utilityGray200 mt-5"
        className="border-0 border-b-2 !border-r-0 border-transparent !rounded-none"
        activeClassName="border-b !border-primary text-primary700"
        isActive={params.currentTab}
        handleOnClick={(e: any) => {
          e.stopPropagation();
          setParams((old: any) => ({
            ...old,
            ...{
              currentTab:
                e?.currentTarget?.dataset?.value ?? e?.target?.dataset?.value,
            },
          }));
        }}
      />

      {params?.currentTab === 'messages' && (
        <InternalChat
          data={data}
          isClaim={isClaim}
          className="hidden"
          typingUsers={typingUsers}
          messages={messages}
          members={members}
          setMessages={setMessages}
          setMembers={setMembers}
        />
      )}

      {params?.currentTab === 'internal_notes' && (
        <InternalNotes
          data={data}
          isClaim={isClaim}
          notesCount={notesCount}
          setNotesCount={setNotesCount}
          internalNotes={internalNotes}
          setInternalNotes={setInternalNotes}
        />
      )}
    </div>
  );
};

export default InternalChatAndNotes;
