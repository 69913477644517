import React from 'react';
import {} from '@untitled-ui/icons-react/build/cjs';

const TeamPerformanceDetailsRowLoader = () => (
  <>
    <td className="px-5 py-4">
      <div className="flex items-center gap-2 custom-loading">
        <div className="w-7 h-7 bg-primary rounded-full text-white flex items-center justify-center uppercase text-xs flex-none">
          ts{' '}
        </div>
        Test User
      </div>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$20000</span>
    </td>
    <td className="px-5 py-4 min-w-36 max-w-36 w-36">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
  </>
);

export default TeamPerformanceDetailsRowLoader;
