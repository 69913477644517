import React from 'react';
import { getShortName } from 'src/utils/CommonFunctions';

const TeamPerformanceLoadingRow = () => (
  <>
    <td className="px-5 py-4 max-w-44 w-44 min-w-44">
      <div className="flex items-center gap-2 custom-loading">
        <div className="w-8 h-8 bg-primary rounded-full text-white flex items-center justify-center uppercase text-xs flex-none">
          {getShortName('TS')}
        </div>
        Test User
      </div>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$20000</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
  </>
);

export default TeamPerformanceLoadingRow;
