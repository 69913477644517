import { yupResolver } from '@hookform/resolvers/yup';
import {
  Attachment01,
  Eye,
  Plus,
  Trash01,
  UploadCloud02,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import pdf from 'src/assets/img/pdf-icon.png';
import CommonModal from 'src/components/CommonModal';
import DeleteModal from 'src/components/DeleteModal';
import ErrorMsg from 'src/components/errorMsg';
import { AuthContext } from 'src/context/AuthContext';
import {
  creditReferenceAttachment,
  deleteAttachment,
  getCreditReferenceAttachment,
} from 'src/services/CustomerOnBoardingService';
import { convertToGBMBKB, onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

interface IProps {
  handleClose: any;
  creditReferenceId: any;
}

const initAction = {
  mode: '',
  id: 0,
};

const AttachmentModal = ({ handleClose, creditReferenceId }: IProps) => {
  const { currentUser } = useContext(AuthContext);
  const [action, setAction] = useState(initAction);

  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
  const SUPPORTED_FORMATS = [
    'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'application/vnd.ms-excel',
    // 'text/plain',
  ];
  // const [formData, setFormData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [fileList, setFileList] = useState<any>([]);

  const validationSchema = yup.object().shape({
    file: yup
      .mixed()
      .test({
        name: 'fileFormat',
        message: 'Unsupported image format.',
        test: async (value: any) => {
          if (!value || !(value instanceof File)) return true;
          console.log('value.type :>> ', value.type);

          return SUPPORTED_FORMATS.includes(value.type);
        },
      })
      .test({
        name: 'fileSize',
        message: 'File size should be less than 2MB.',
        test: async (value: any) => {
          if (!value || !(value instanceof File)) return true;

          return value.size <= MAX_FILE_SIZE;
        },
      }),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setValue('file', file, { shouldValidate: true });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
  });

  const getFileList = () => {
    getCreditReferenceAttachment(creditReferenceId)
      .then((response) => {
        setFileList(response.data);
      })
      .catch(() =>
        WalToast.error('something went wrong please try again later..!!')
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getFileList();
  }, []);

  const onSubmit = async (formData: any) => {
    setIsLoading(true);

    const appendFormData: any = new FormData();
    appendFormData.append('creditReferenceId', creditReferenceId);
    appendFormData.append('uploadedBy', currentUser?.id);

    const ids = fileList.map((item: any) => item?.id);
    appendFormData.append('fileIds', ids);

    appendFormData.append('file', formData.file);

    creditReferenceAttachment(appendFormData)
      .then((response: any) => {
        getFileList();
        // setFormData([]);
        // handleClose();
        setIsEdit(false);
        WalToast.success(response?.message);
      })
      .catch(() =>
        WalToast.error('something went wrong please try again later..!!')
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fileData: any = watch('file');

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        if (action.mode === 'delete-attachment') {
          deleteAttachment(action.id)
            .then((response: any) => {
              getFileList();
              WalToast.success(response?.message);
            })
            .catch(() =>
              WalToast.error('something went wrong please try again later..!!')
            )
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
      setAction((old: any) => ({
        ...old,
        ...{ mode: 'add-attachment', id: creditReferenceId },
      }));
    },
    [action]
  );

  return (
    <CommonModal
      title={'Add Attachments'}
      titleDesc={'Attachments for your reference and use.'}
      handleClose={() => handleClose(false)}
      headerIcon={<Attachment01 />}
      size={'max-w-[688px]'}
      modalClassName=""
      primaryBtnOnClick={
        isEdit ? handleSubmit(onSubmit) : () => setIsEdit(true)
      }
      primaryBtnDisabled={isLoading}
      primaryBtnLoading={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      primaryBtnText="Attach Files"
      isOverflow={false}
    >
      <div className="p-5">
        {isEdit ? (
          <>
            <div className="flex justify-between gap-x-3 mb-3">
              <p className="text-textSecondary text-sm font-medium">
                Upload attachment
              </p>
              {fileList.length > 0 && (
                <div
                  onClick={() => setIsEdit(false)}
                  className="flex items-center gap-x-1 text-primary font-medium text-sm cursor-pointer"
                >
                  <Eye className="w-4 h-4" />
                  View attachment
                </div>
              )}
            </div>

            <div
              className="cursor-pointer flex-auto rounded-xl border border-gray100 py-3.5 px-5 text-center bg-white"
              {...getRootProps()}
            >
              {watch('file') && !errors.file ? (
                <>
                  <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                    <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                  </div>
                  <div className="text-grayLight600 text-xs font-normal">
                    <p>{fileData && fileData?.name}</p>
                  </div>
                </>
              ) : (
                <>
                  <label>
                    <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                      <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                    </div>
                    <div className="text-grayLight600 text-xs font-normal">
                      <p>
                        <span className="text-[#2422DD] font-semibold pr-1">
                          Click to upload{' '}
                        </span>
                        or drag and drop
                      </p>
                      <p className="text-[11px] leading-[18px]">PDF</p>
                    </div>
                  </label>
                  <input accept=".pdf" {...getInputProps()} />
                </>
              )}
            </div>
            <div className="mt-1.5">
              {errors.file && <ErrorMsg errorText={errors.file.message} />}
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between gap-x-3 mb-3">
              <p className="text-textSecondary text-sm font-medium">
                Upload attachment
              </p>
              <div
                onClick={() => setIsEdit(true)}
                className="flex items-center gap-x-1 text-primary font-medium text-sm cursor-pointer"
              >
                <Plus className="w-4 h-4" />
                New attachment
              </div>
            </div>
            <div className="w-full flex flex-col gap-3">
              {fileList.map((item: any, index: any) => (
                <div
                  key={index}
                  className="flex gap-x-3 rounded-xl border border-utilityGray200 p-4 bg-white"
                >
                  <div className="w-8 h-10 flex-none">
                    <img
                      src={pdf}
                      onError={onError}
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <div className="flex-1">
                    <h6 className="text-textSecondary font-medium text-sm">
                      {item.fileOriginalName}
                    </h6>
                    <p className="text-grayLight600 font-normal text-sm">
                      {convertToGBMBKB(item.size)}
                    </p>
                  </div>

                  <Trash01
                    className="w-4 h-4  cursor-pointer"
                    onClick={() =>
                      setAction((old: any) => ({
                        ...old,
                        ...{ mode: 'delete-attachment', id: item?.id },
                      }))
                    }
                  />
                </div>
              ))}

              {action.mode === 'delete-attachment' && (
                <DeleteModal
                  moduleName="Credit Reference Attachment"
                  handleClose={handleModalClose}
                />
              )}
            </div>
          </>
        )}
      </div>
    </CommonModal>
  );
};

export default AttachmentModal;
