import React, { memo, useState } from 'react';

import CommonModal from '../../../../components/CommonModal';
import InputText from '../../../../components/InputText/InputText';
import SelectBox from '../../../../components/SelectBox/SelectBox';

const closedQuoteReasonList = [
  { value: '1', label: 'Cost too high' },
  { value: '2', label: 'Margin too high' },
  { value: '3', label: 'Too long to quote' },
  { value: '3', label: 'Quote for reference' },
  { value: 'other', label: 'Other' },
];

const CloseQuoteReason = ({ handleClose, handleSuccess }: any) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [closedReasonText, setClosedReasonText] = useState<
    string | null | undefined
  >(undefined);
  const [otherReasonText, setOtherReasonText] = useState('');

  const handleSelectChange = (event: any) => {
    setClosedReasonText(event?.value);
    setIsDisabled(event?.value === 'Other');

    if (!event?.value) {
      setIsDisabled(true);
    }
  };

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setOtherReasonText(value);
    setIsDisabled(!value);
  };

  return (
    <CommonModal
      handleClose={handleClose}
      title="Please indicate the reason for the closure"
      size={'max-w-2xl overflow-unset'}
      isOverflow={false}
      primaryBtnText="Send"
      primaryBtnOnClick={() =>
        handleSuccess({
          cancelReason:
            closedReasonText === 'Other' ? otherReasonText : closedReasonText,
        })
      }
      primaryBtnDisabled={isDisabled}
    >
      <div className="p-6 pt-0 mt-3">
        <SelectBox
          isClearable
          name="QuoteCloseReason"
          label="QuoteCloseReason"
          className={`form_control z-50`}
          labelClassName="text-black text-sm mb-2"
          options={closedQuoteReasonList}
          value={closedQuoteReasonList.filter(
            (item) => item.value === closedReasonText
          )}
          onChangeFunc={handleSelectChange}
          // menuIsOpen={true}
        />

        {closedReasonText === 'other' && (
          <div className="mt-2">
            <InputText
              inputType="textarea"
              inputName="QuoteCloseReason"
              placeholder="Specify the reason"
              onChangeFunc={handleInputChange}
            />
          </div>
        )}
      </div>
    </CommonModal>
  );
};

export default memo(CloseQuoteReason);
