import { yupResolver } from '@hookform/resolvers/yup';
import { Check } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import StepBar from 'src/components/StepBar/StepBar';
import { bookOrder } from 'src/services/QuoteService';
import { onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import defaultImage from '../../../../assets/img/default-image.jpg';
import Logo from '../../../../assets/img/logo2.svg';

interface IProps {
  selectedCarrierRates: any;
  handleClose: any;
  carrierRate: any;
}

interface FormData {
  contactName: string;
  contactEmail: string;
  contactPhoneNumber: string;
  shipperName: string;
  shipperEmail: string;
  shipperPhoneNumber: string;
  consigneeName: string;
  consigneeEmail: string;
  consigneePhoneNumber: string;
}

// const phoneRegExp = /^(\+?1?[-.\s]?)?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

const validationSchema = yup.object().shape({
  contactName: yup.string().required('Contact name is required'),
  contactEmail: yup
    .string()
    .email('Invalid email')
    .required('Contact email is required'),
  contactPhoneNumber: yup
    .string()
    // .matches(phoneRegExp, 'Invalid phone number')
    .required('Contact phone is required'),
  shipperName: yup.string().required('Shipper name is required'),
  shipperEmail: yup
    .string()
    .email('Invalid email')
    .required('Shipper email is required'),
  shipperPhoneNumber: yup
    .string()
    // .matches(phoneRegExp, 'Invalid phone number')
    .required('Shipper phone is required'),
  consigneeName: yup.string().required('Consignee name is required'),
  consigneeEmail: yup
    .string()
    .email('Invalid email')
    .required('Consignee email is required'),
  consigneePhoneNumber: yup
    .string()
    // .matches(phoneRegExp, 'Invalid phone number')
    .required('Consignee phone is required'),
});

const FinalizeOrderModal = ({
  selectedCarrierRates,
  handleClose,
  carrierRate,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const headerIcon = (
    <img
      src={Logo}
      alt="logo-light"
      className="object-covert h-full w-full rounded-[10px] border border-utilityGray200"
    />
  );

  const STEPS = [
    {
      name: 'Booking order with carrier',
      step: 1,
    },
    {
      name: 'Pushing order to RoseRocket',
      step: 2,
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const onSubmit = (data: FormData) => {
    setIsLoading(true);
    bookOrder(data, selectedCarrierRates[0].id)
      .then(() => {
        handleClose(true, 'informationModel')();
        // handleClose(response.data);
      })
      .catch(() => WalToast.error('Customer not added'))
      .finally(() => {
        handleClose(true, 'informationModel')();

        setIsLoading(false);
      });
  };

  return (
    <CommonModal
      title={`Finalize Order Creation with ${carrierRate.name}`}
      titleDesc={
        <p className="max-w-[340px] mx-auto text-wrap">
          Streamlining the final steps of order creation for faster and more
          accurate transactions with {carrierRate.name}
        </p>
      }
      handleClose={() => handleClose(false, 'carrierOrderModel')()}
      headerIcon={headerIcon}
      headerSecondIcon={
        <img
          src={`${carrierRate?.imageUrl}${carrierRate?.image}`}
          className="object-covert h-full w-full rounded-[10px] border border-utilityGray200"
          alt={defaultImage}
          title={carrierRate?.name}
          onError={onError}
        />
      }
      headerIconClass="border-0 md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-[-5px] before:-left-[69px] before:opacity-50"
      headerIconSecondClass="border-0 md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex items-center relative ml-2 after:content-[''] after:h-[26px] after:w-[26px] after:border-utilityGray200 after:border after:rounded-full after:absolute  after:bg-[url('../../assets/img/link-01.svg')] after:bg-no-repeat after:bg-center after:bg-white after:top-1/2 after:-translate-y-1/2 after:-left-[22px] before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-[-5px] before:-right-[69px] before:opacity-50"
      size={'max-w-[700px]'}
      primaryBtnText="Continue"
      secondaryBtnText="Cancel"
      modalHeaderTextClass="!block text-center flex-1"
      headerInfoClass="mt-[18px]"
      modalHeaderClass="!pt-5"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      secondaryBtnOnClick={() => handleClose(false, 'carrierOrderModel')()}
    >
      <div className="w-full p-5">
        <StepBar
          steps={STEPS}
          activeStep={1}
          stepsParentClass="!justify-start mb-6"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
              Contact Detail
            </label>
            <div className="rounded-lg border border-utilityGray200 p-3 mb-3">
              <div className="flex -mx-1 flex-wrap gap-y-2">
                <Controller
                  name="contactName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label="Name"
                      inputName="name"
                      placeholder="Enter name"
                      parentClassName="px-1 md:w-1/3 w-full"
                      labelClassName="block mb-1.5"
                      errorText={errors.contactName?.message}
                    />
                  )}
                />
                <Controller
                  name="contactEmail"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label="Email"
                      inputName="email"
                      placeholder="Enter Email"
                      parentClassName="px-1 md:w-1/3 w-full"
                      labelClassName="block mb-1.5"
                      errorText={errors.contactEmail?.message}
                    />
                  )}
                />
                <div className="px-1 md:w-1/3 w-full">
                  <label className="form_label block mb-1.5">
                    Phone number
                  </label>
                  <Controller
                    name="contactPhoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        onNumberChange={(
                          country: any,
                          phone: any,
                          code: any
                        ) => {
                          field.onChange(`+${code}${phone}`);
                        }}
                        disabled={false}
                        errors={errors.contactPhoneNumber?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
              Shipper Detail
            </label>
            <div className="rounded-lg border border-utilityGray200 p-3 mb-3">
              <div className="flex -mx-1 flex-wrap gap-y-2">
                <Controller
                  name="shipperName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label="Name"
                      inputName="name"
                      placeholder="Enter name"
                      parentClassName="px-1 md:w-1/3 w-full"
                      labelClassName="block mb-1.5"
                      errorText={errors.shipperName?.message}
                    />
                  )}
                />
                <Controller
                  name="shipperEmail"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label="Email"
                      inputName="email"
                      placeholder="Enter Email"
                      parentClassName="px-1 md:w-1/3 w-full"
                      labelClassName="block mb-1.5"
                      errorText={errors.shipperEmail?.message}
                    />
                  )}
                />
                <div className="px-1 md:w-1/3 w-full">
                  <label className="form_label block mb-1.5">
                    Phone number
                  </label>
                  <Controller
                    name="shipperPhoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        onNumberChange={(
                          country: any,
                          phone: any,
                          code: any
                        ) => {
                          field.onChange(`+${code}${phone}`);
                        }}
                        disabled={false}
                        errors={errors.shipperPhoneNumber?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
              Consignee Detail
            </label>
            <div className="rounded-lg border border-utilityGray200 p-3 mb-3">
              <div className="flex -mx-1 flex-wrap gap-y-2">
                <Controller
                  name="consigneeName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label="Name"
                      inputName="name"
                      placeholder="Enter name"
                      parentClassName="px-1 md:w-1/3 w-full"
                      labelClassName="block mb-1.5"
                      errorText={errors.consigneeName?.message}
                    />
                  )}
                />
                <Controller
                  name="consigneeEmail"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      {...field}
                      label="Email"
                      inputName="email"
                      placeholder="Enter Email"
                      parentClassName="px-1 md:w-1/3 w-full"
                      labelClassName="block mb-1.5"
                      errorText={errors.consigneeEmail?.message}
                    />
                  )}
                />
                <div className="px-1 md:w-1/3 w-full">
                  <label className="form_label block mb-1.5">
                    Phone number
                  </label>
                  <Controller
                    name="consigneePhoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        onNumberChange={(
                          country: any,
                          phone: any,
                          code: any
                        ) => {
                          field.onChange(`+${code}${phone}`);
                        }}
                        disabled={false}
                        errors={errors.consigneePhoneNumber?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
              Additional service not included
            </label>
            <ul className="flex flex-wrap rounded-lg border border-utilityGray200 p-3 gap-y-2">
              {carrierRate?.services?.map(
                (service: any, index: any) =>
                  !service?.isQuoted && (
                    <li
                      className="flex gap-x-1 md:w-1/3 sm:w-1/2 w-full pr-1"
                      key={index}
                    >
                      <Check className="w-4 h-4 text-primary" />
                      <p className="text-grayLight600 text-xs font-medium">
                        {service?.serviceId?.name}
                      </p>
                    </li>
                  )
              )}
            </ul>
          </div>
        </form>
      </div>
    </CommonModal>
  );
};

export default FinalizeOrderModal;
