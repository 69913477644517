import React from 'react';
import avatar from 'src/assets/img/Avatar1.png';

const InternalChatsSkeletonLoader = () => (
  <>
    <li className="flex items-center my-4">
      <span className="h-[1px] bg-utilityGray200 w-full"></span>
      <p className="text-grayLight600 text-sm text-center px-2 custom-loading">
        Today
      </p>
      <span className="h-[1px] bg-utilityGray200 w-full"></span>
    </li>

    <li className="flex justify-end mt-4 gap-3 ">
      <div className="xl:max-w-[305px] max-w-[230px]">
        <div className="flex justify-between gap-2 items-center w-full mb-1.5">
          <p className="text-textSecondary text-xs custom-loading">You</p>
          <p className="text-grayLight600 font-normal text-xs custom-loading">
            10 mins ago
          </p>
        </div>

        <div className="rounded-lg rounded-tr-none border border-utilityGray200 px-3.5 py-2.5 font-normal text-sm bg-primary text-white custom-loading">
          Hey Anita, I’ve had a read through and made some notes:
          https://docs.google.com/docu...
        </div>
      </div>
    </li>
    <li className="flex mt-4 gap-3 max-w-[305px]">
      <div className="w-8 h-8 flex-none relative">
        <img
          src={avatar}
          className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary custom-loading image-loading"
        />
        <span className="border-[1.5px] border-white rounded-full bg-successSecondary w-2.5 h-2.5 absolute bottom-0 right-0 custom-loading"></span>
      </div>
      <div>
        <div className="flex justify-between gap-2 items-center w-full mb-1.5">
          <p className="text-textSecondary text-xs custom-loading">
            Anita Cruz
          </p>
          <p className="text-grayLight600 font-normal text-xs custom-loading">
            Just now
          </p>
        </div>

        <div className="rounded-lg rounded-tl-none border border-utilityGray200 p-2.5 bg-white text-grayLight900 font-normal text-sm mb-2 custom-loading">
          Thank you for the quick turnaround. Looking now.
        </div>
      </div>
    </li>
    <li className="flex justify-end mt-4 gap-3 ">
      <div className="xl:max-w-[305px] max-w-[230px]">
        <div className="flex justify-between gap-2 items-center w-full mb-1.5">
          <p className="text-textSecondary text-xs custom-loading">You</p>
          <p className="text-grayLight600 font-normal text-xs custom-loading">
            10 mins ago
          </p>
        </div>

        <div className="rounded-lg rounded-tr-none border border-utilityGray200 px-3.5 py-2.5 font-normal text-sm bg-primary text-white custom-loading">
          Hey Anita, I’ve had a read through and made some notes:
          https://docs.google.com/docu...
        </div>
      </div>
    </li>
    <li className="flex mt-4 gap-3 max-w-[305px]">
      <div className="w-8 h-8 flex-none relative">
        <img
          src={avatar}
          className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary custom-loading image-loading"
        />
        <span className="border-[1.5px] border-white rounded-full bg-successSecondary w-2.5 h-2.5 absolute bottom-0 right-0 custom-loading"></span>
      </div>
      <div>
        <div className="flex justify-between gap-2 items-center w-full mb-1.5">
          <p className="text-textSecondary text-xs custom-loading">
            Anita Cruz
          </p>
          <p className="text-grayLight600 font-normal text-xs custom-loading">
            Just now
          </p>
        </div>

        <div className="rounded-lg rounded-tl-none border border-utilityGray200 p-2.5 bg-white text-grayLight900 font-normal text-sm mb-2 w-fit custom-loading">
          <div className="flex gap-1 typing-animation min-h-[18px] items-center">
            <div className="w-1 h-1 rounded-full bg-grayLight600"></div>
            <div className="w-1 h-1 rounded-full  bg-gray400"></div>
            <div className="w-1 h-1 rounded-full bg-grayLight600"></div>
          </div>
        </div>
      </div>
    </li>
  </>
);

export default InternalChatsSkeletonLoader;
