import { Minus, Plus } from '@untitled-ui/icons-react//build/cjs';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tippy';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import {
  getFormattedNumber,
  getProgressClass,
} from 'src/utils/CommonFunctions';

import {
  ICustomerFooterRowProps,
  ITotalCustomerTargetNumbers,
} from './Target.interface';

const TargetSetFooterRow = ({
  data,
  setMiscellaneousData,
}: ICustomerFooterRowProps) => {
  const [isUpdatingValue, setIsUpdatingValue] = useState<string>('');
  const [targetProfit, setTargetProfit] = useState<number>(0);
  const [targetLoads, setTargetLoads] = useState<number>(0);
  const [percentageChange, setPercentageChange] = useState<number>(2.5);

  useEffect(() => {
    setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
      ...old,
      targetProfit: targetProfit,
    }));
  }, [targetProfit]);

  useEffect(() => {
    setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
      ...old,
      targetLoads: targetLoads,
    }));
  }, [targetLoads]);

  useEffect(() => {
    if (data.isMiscellaneous) {
      setMiscellaneousData((old: ITotalCustomerTargetNumbers) => ({
        ...old,
        targetProfit: data.targetProfit || data.prevMonthTargetProfit || 0,
        targetLoads: data.targetLoads || data.prevMonthTargetLoads || 0,
      }));
      setTargetProfit(data.targetProfit || data.prevMonthTargetProfit || 0);
      setTargetLoads(data.targetLoads || data.prevMonthTargetLoads || 0);
    }
  }, []);

  const handleProfitDecrease = () => {
    const decreaseFactor = 1 - percentageChange / 100;
    setTargetProfit(Math.round(targetProfit * decreaseFactor));
  };

  const handleProfitIncrease = () => {
    const increaseFactor = 1 + percentageChange / 100;
    setTargetProfit(Math.round(targetProfit * increaseFactor));
  };

  const handleLoadsDecrease = () => {
    const decreaseFactor = 1 - percentageChange / 100;
    setTargetLoads(Math.round(targetLoads * decreaseFactor));
  };

  const handleLoadsIncrease = () => {
    const increaseFactor = 1 + percentageChange / 100;
    setTargetLoads(Math.round(targetLoads * increaseFactor));
  };

  const handlePlusDoubleClick = () => {
    setPercentageChange((prev) => prev + 2.5);
  };

  const handleMinusDoubleClick = () => {
    setPercentageChange((prev) => Math.max(2.5, prev - 2.5));
  };

  return (
    <>
      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 min-w-[13rem] w-[13rem] max-w-[13rem]">
        <span className="h-[32.5px] flex items-center">{data.name}</span>
      </td>
      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 rounded-bl-xl min-w-[14rem] w-[14rem] max-w-[14rem]">
        <span>-</span>
      </td>
      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 min-w-[20%] w-[20%] max-w-[20%]">
        <span>
          $
          {getFormattedNumber(
            data.prevMonthCompletedProfit,
            false,
            false,
            true
          )}
        </span>
        <span>&nbsp;/&nbsp;</span>
        <span>
          ${getFormattedNumber(data.prevMonthTargetProfit, false, false, true)}
        </span>
        <span>&nbsp;/&nbsp;</span>
        <span
          className={`text-textSecondary ${getProgressClass(
            data.prevMonthCompletedProfitPercentage,
            false
          )}`}
        >
          {data.prevMonthCompletedProfitPercentage}%
        </span>
      </td>

      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 min-w-[20%] w-[20%] max-w-[20%]">
        <span>{data.prevMonthCompletedLoads}</span>
        <span>&nbsp;/&nbsp;</span>
        <span>{data.prevMonthTargetLoads}</span>
        <span>&nbsp;/&nbsp;</span>
        <span
          className={`text-textSecondary ${getProgressClass(
            data.prevMonthCompletedLoadsPercentage,
            false
          )}`}
        >
          {data.prevMonthCompletedLoadsPercentage}%
        </span>
      </td>

      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 min-w-[18%] w-[18%]">
        {data.isMiscellaneous ? (
          <div className="relative max-w-32 border border-borderPrimary rounded-lg hover:border-grayText">
            <ButtonCmp
              className="!border-0 !py-0 pl-2 pr-1 text-primary absolute h-full z-[1] top-0 cursor-pointer left-0 !outline-0"
              onClick={handleProfitDecrease}
              onDoubleClick={handleMinusDoubleClick}
            >
              {/* @ts-ignore */}
              <Tooltip
                delay={300}
                arrow={true}
                html={
                  <div
                    style={{ bottom: `0px` }}
                    className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                  >
                    {`-${percentageChange}%`}
                  </div>
                }
              >
                <Minus className="w-4 h-4" />
              </Tooltip>
            </ButtonCmp>
            <InputText
              inputName="targetProfit"
              className={`border-0 h-[34px] bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs px-7 text-center ${
                isUpdatingValue ? '' : ''
              }`}
              value={
                (isUpdatingValue === 'targetProfit'
                  ? targetProfit
                  : `$${getFormattedNumber(targetProfit)}`) || ''
              }
              inputType={isUpdatingValue === 'targetProfit' ? 'number' : 'text'}
              placeholder=""
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChangeFunc={(e: any) =>
                setTargetProfit(
                  isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                )
              }
              parentClassName="max-w-full"
              onBlur={() => setIsUpdatingValue('')}
              onFocus={() => setIsUpdatingValue('targetProfit')}
            />

            <ButtonCmp
              className="!border-0 !py-0 pr-2 pl-1 text-primary absolute h-full z-[1] top-0 right-0 !outline-0"
              onClick={handleProfitIncrease}
              onDoubleClick={handlePlusDoubleClick}
            >
              {/* @ts-ignore */}
              <Tooltip
                delay={300}
                arrow={true}
                html={
                  <div
                    style={{ bottom: `0px` }}
                    className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                  >
                    {`+${percentageChange}%`}
                  </div>
                }
              >
                <Plus className="w-4 h-4" />
              </Tooltip>
            </ButtonCmp>
          </div>
        ) : (
          <div>
            ${getFormattedNumber(data.targetProfit, false, false, true)}
          </div>
        )}
      </td>
      <td className="px-5 py-[14px] font-semibold text-sm bg-gray50 min-w-[18%] w-[18%]">
        {data.isMiscellaneous ? (
          <div className="relative max-w-32 border border-borderPrimary rounded-lg hover:border-grayText">
            <ButtonCmp
              className="!border-0 !py-0 pl-2 pr-1 text-primary absolute h-full z-[1] top-0 cursor-pointer left-0 !outline-0"
              onClick={handleLoadsDecrease}
              onDoubleClick={handleMinusDoubleClick}
            >
              {/* @ts-ignore */}
              <Tooltip
                delay={300}
                arrow={true}
                html={
                  <div
                    style={{ bottom: `0px` }}
                    className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                  >
                    {`-${percentageChange}%`}
                  </div>
                }
              >
                <Minus className="w-4 h-4" />
              </Tooltip>
            </ButtonCmp>
            <InputText
              inputName="targetLoads"
              // className="bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs"
              className={`border-0 h-[34px] bg-white focus:bg-white placeholder:text-gray500 text-black border-borderPrimary shadow-sm font-normal leading-6 text-xs px-7 text-center ${
                isUpdatingValue ? '' : ''
              }`}
              value={
                isUpdatingValue === 'targetLoads'
                  ? targetLoads || ''
                  : targetLoads || 0
              }
              // value={targetLoads || ''}
              inputType="number"
              parentClassName="max-w-full"
              placeholder=""
              onKeyPress={(event: any) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              // onChangeFunc={(e: any) =>
              //   setTargetLoads(parseInt(e.target.value))
              // }
              onChangeFunc={(e: any) =>
                setTargetLoads(
                  isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                )
              }
              onBlur={() => setIsUpdatingValue('')}
              onFocus={() => setIsUpdatingValue('targetLoads')}
            />
            <ButtonCmp
              className="!border-0 !py-0 pr-2 pl-1 text-primary absolute h-full z-[1] top-0 right-0 !outline-0"
              onClick={handleLoadsIncrease}
              onDoubleClick={handlePlusDoubleClick}
            >
              {/* @ts-ignore */}
              <Tooltip
                delay={300}
                arrow={true}
                html={
                  <div
                    style={{ bottom: `0px` }}
                    className={`shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${'bg-black border border-black text-grayLight'}`}
                  >
                    {`+${percentageChange}%`}
                  </div>
                }
              >
                <Plus className="w-4 h-4" />
              </Tooltip>
            </ButtonCmp>
          </div>
        ) : (
          <span>{data.targetLoads}</span>
        )}
      </td>
    </>
  );
};

export default TargetSetFooterRow;
