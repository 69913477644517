import { yupResolver } from '@hookform/resolvers/yup';
import {
  Check,
  FilePlus01,
  SearchMd,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { addLoadInsurance } from 'src/services/LoadInsurance';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

interface ILoadInsurance {
  handleClose: any;
  orderData: any;
}

const RequestLoadInsurance = ({ handleClose, orderData }: ILoadInsurance) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orderList] = useState<any>(orderData);
  const [filterOrderList, setFilteredOrderList] = useState<any>(orderData);
  const [orderFullID, setOrderFullID] = useState('');
  const [showOrdersSearchValue, setShowOrdersSearchValue] = useState('');
  const [isShowOrders, setIsShowOrders] = useState(false);
  const validationSchema = yup.object().shape({
    orderID: yup.string().required('orderId is required.'),
    commodity: yup.string().required('orderId is required.'),
    commercial: yup.string().required('commercial is required.'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      orderID: '',
      commodity: '',
      commercial: '',
    },
  });

  const getShowFilteredMemberList = (
    searchValue: string,
    originalList: any
  ) => {
    const filteredList = originalList.filter((originalData: any) =>
      originalData.fullId
        .toLowerCase()
        .includes(searchValue.toLowerCase().trim())
    );

    return [...filteredList] as any;
  };

  useEffect(() => {
    if (showOrdersSearchValue) {
      const filteredList = getShowFilteredMemberList(
        showOrdersSearchValue,
        orderList
      );
      setFilteredOrderList(filteredList);
    } else {
      setFilteredOrderList(orderList);
    }
  }, [showOrdersSearchValue]);

  // useEffect(() => {
  //   getOrders()
  //     .then((response) => {
  //       // const orders = getSelectBoxOptions(response.data, 'id', 'fullId');
  //       setOrderList(response.data);
  //       setFilteredOrderList(response.data);
  //     })
  //     .catch(() => WalToast.error('Load Insurance request not added'))
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, []);

  const onSubmit = async (formData: any) => {
    setIsLoading(true);
    addLoadInsurance(formData)
      .then(() => {
        handleClose();
        setIsLoading(false);
        WalToast.success('Load Insurance request added successfully');
      })
      .catch(() => WalToast.error('Load Insurance request not added'))
      .finally(() => {
        setIsLoading(false);
      });
    // handleClose();
  };

  return (
    <CommonModal
      title="Request Load Insurance"
      titleDesc="Here you can request load insurance for your claims"
      handleClose={() => handleClose(false)}
      headerIcon={<FilePlus01 className="w-5 h-5" />}
      size={'max-w-[500px] md:min-h-[auto] min-h-[90vh] max-h-[668px]'}
      secondaryBtnOnClick={() => handleClose(false)}
      modalHeaderClass=""
      headerInfoClass="!gap-0 [&>.text-xs]:text-sm"
      closeBtnClass="[&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full"
      modalClassName="flex flex-col "
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnDisabled={isLoading}
      primaryBtnLoading={isLoading}
      primaryBtnText="Save"
      secondaryBtnText="Cancel"
    >
      <div className=" flex md:flex-row flex-col flex-1 overflow-auto p-5">
        <div className="flex-1 md:order-[0] order-2 flex flex-col gap-4">
          {/* <div className="xls:max-w-[770px] flex-1 xls:w-[calc(100%_-_333px)] w-full"> */}
          <div className="w-full relative">
            <Controller
              name="orderID"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputText
                    label="Order Id"
                    inputName="orderID"
                    placeholder="Order Id"
                    className="bg-white focus:bg-white placeholder:text-gray500 shadow-sm font-normal search-input"
                    value={orderFullID}
                    readOnly
                    onFocus={() => {
                      setIsShowOrders(true);
                    }}
                  />
                  {isShowOrders && (
                    <div
                      id="dropdownDelay"
                      className={`min-w-[262px] z-10 absolute top-15 bg-white w-full border border-borderPrimary shadow-md my-1.5 rounded-[10px] left-0 top-full`}
                    >
                      <InputText
                        inputName="searchTeam"
                        placeholder="Search"
                        className="pl-8 placeholder:text-secondaryTxtColor"
                        parentClassName="px-2 pt-[9px]"
                        icon={
                          <SearchMd className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-5 w-5" />
                        }
                        value={showOrdersSearchValue}
                        onChangeFunc={(e: any) => {
                          setShowOrdersSearchValue(e.target.value);
                        }}
                        onFocus={() => {
                          setIsShowOrders(true);
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setIsShowOrders(false);
                          }, 200);
                        }}
                        isClearable={true}
                      />
                      <ul
                        className="mt-1.5 mb-[9px]"
                        aria-labelledby="dropdownDelayButton"
                      >
                        {filterOrderList?.length > 0 ? (
                          filterOrderList
                            .slice(0, 1000)
                            .map((order: any, index: number) => (
                              <li
                                key={index}
                                className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s] ${
                                  order.fullId === value ? 'bg-gray50' : ''
                                }`}
                                onClick={() => {
                                  onChange(order?.fullId);
                                  setOrderFullID(order?.fullId);
                                  setShowOrdersSearchValue('');
                                  setIsShowOrders(false);
                                }}
                              >
                                <div className="flex justify-between items-center align-middle">
                                  {' '}
                                  <div className="flex justify-center items-center">
                                    <span className="font-medium mr-2">
                                      #{order.fullId}
                                    </span>
                                  </div>
                                  {order.id === value ? (
                                    <Check className="w-4 h-4 ml-2 text-primary" />
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </li>
                            ))
                        ) : (
                          <li
                            className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s] `}
                          >
                            {' '}
                            <span className="font-semibold mr-2">#{}</span>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                  {/* {orderList?.length > 0 && (
                    <div
                      className={`mt-3 w-full max-h-[235px] team-user-select overflow-auto py-4 px-2 scrollbar-hide rounded-[10px] shadow-xxl border border-utilityGray200 top-22 bg-white z-10 ${
                        isShowOrders ? 'absolute' : 'hidden'
                      }`}
                    >
                      {filterOrderList.map((order: any) => (
                        <div
                          key={`member_select_list_${order.id}`}
                          className={`w-full flex justify-between rounded-lg cursor-pointer hover:bg-gray50 mb-2 member_select_list_${
                            order.id
                          }${order.id === value ? 'bg-gray50' : ''}`}
                          onClick={() => {
                            onChange(order?.id);
                            setShowOrdersSearchValue(order?.fullId);
                            setIsShowOrders(false);
                          }}
                        >
                          <div className="px-3.5 py-2.5 justify-start gap-2 flex items-center">
                            <div className="flex-col justify-start items-start flex ">
                              <div className="text-grayLight900 text-xs font-medium leading-tight">
                                ${order.fullId}
                              </div>
                            </div>
                          </div>
                          {order.id === value ? (
                            <div className="flex-col justify-center items-center flex ">
                              <Check className="w-5 h-5 text-primary m-2" />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      ))}
                    </div>
                  )} */}
                </>
              )}
            />
          </div>
          {/* </div> */}

          <Controller
            name="commodity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Commodity"
                inputName="commodity"
                placeholder="Commodity"
                parentClassName=""
                className=""
                value={value}
                onChangeFunc={onChange}
                errorText={errors.commodity ? errors.commodity.message : null}
                labelClassName="block mb-1.5"
              />
            )}
          />

          <Controller
            name="commercial"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Commercial"
                inputName="commercial"
                inputType="number"
                placeholder="Commercial"
                parentClassName=""
                className=""
                value={value}
                onChangeFunc={onChange}
                errorText={errors.commercial ? errors.commercial.message : null}
                labelClassName="block mb-1.5"
              />
            )}
          />
        </div>
      </div>
    </CommonModal>
  );
};
export default RequestLoadInsurance;
