import {
  FilterLines,
  Minimize02,
  SearchLg,
  User01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import { getShortName, onError } from 'src/utils/CommonFunctions';

import avatar from '../../../assets/img/Avatar1.png';

const Customers = ({ handleClose, customers }: any) => {
  console.log('customers :>> ', customers);

  return (
    <div className="fixed bottom-10 right-10 w-[500px] h-[calc(100vh_-_198px)] overflow-y-auto bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] custom-scrollbar-v2">
      <div className="flex items-start border-b border-utilityGray200 p-4 gap-2 sticky top-0 z-[1] bg-white">
        <div className="rounded-md border border-utilityGray200 shadow-xs p-[9px] mt-0.5">
          <User01 className="w-4 h-4" />
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight900 text-base font-semibold">
            All Customers
          </h6>
          <p className="text-gray500 text-xs font-normal">
            Here is an overview of your customer details.
          </p>
        </div>
        <ButtonCmp className="!p-1.5 btn_secondary_black">
          <Minimize02
            className="w-4 h-4 text-primary cursor-pointer"
            onClick={handleClose}
          />
        </ButtonCmp>
      </div>
      <div className="p-4 flex flex-col gap-y-6">
        <div className="flex gap-2 w-full">
          <InputText
            inputName="searchQuote"
            placeholder="Search"
            className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
            icon={
              <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
            }
            value={'search'}
            inputType="text"
            parentClassName="flex-1 "
            isClearable={true}
          />
          <ButtonCmp className="lg:!px-[9px] px-2 btn_secondary_black">
            <FilterLines className="w-4 h-4 " />
          </ButtonCmp>
        </div>
        <div className="rounded-md border border-utilityGray200 bg-white p-3">
          <div className="">
            <img
              src={avatar}
              className="rounded w-9 h-9 border border-utilityGray200"
            />

            {/* IF NO IMAGE FOUND */}
            {/* <div className="w-9 h-9 rounded bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
            WA
          </div> */}
          </div>
        </div>
        {customers &&
          customers.map((customer: any, index: number) => (
            <div
              className="flex flex-col gap-1 rounded-md border border-utilityGray200 bg-white p-3"
              key={index}
            >
              <div className="flex gap-2">
                {customer.image ? (
                  <img
                    className="w-8 h-8 text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                    src={customer.imageUrl + customer.image}
                    alt=""
                    title=""
                    onError={onError}
                  />
                ) : (
                  <div className="w-8 h-8 bg-utilityGray100 text-primary text-xs flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                    {getShortName(`${customer.name}`)}
                  </div>
                )}
                <div className="flex items-center gap-1">
                  <span className="text-grayLight600 text-xs leading-5">
                    {customer.name}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Customers;
