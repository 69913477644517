/* eslint-disable padding-line-between-statements */
import {} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import csv from 'src/assets/img/csv.png';
import doc from 'src/assets/img/doc.png';
import pdf from 'src/assets/img/pdf-icon.png';
import txt from 'src/assets/img/txt.png';
import xls from 'src/assets/img/xls.png';
import xlsx from 'src/assets/img/xlsx.png';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  convertToGBMBKB,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const LoadInsuranceFiles = ({ data }: any) => {
  const validImageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

  const getExtention = () => {
    const filename = data?.fileOriginalName;
    const parts = filename?.split('.');
    if (parts) return parts[parts?.length - 1] ?? '';

    return '';
  };
  const getImgSrc = () => {
    const fileType = getExtention();
    if (validImageExtensions.includes(fileType)) {
      return data.fileUrl + data.fileName;
    } else if (fileType === 'pdf') {
      return pdf;
    } else if (fileType === 'xls') {
      return xls;
    } else if (fileType === 'xlsx') {
      return xlsx;
    } else if (fileType === 'csv') {
      return csv;
    } else if (fileType === 'txt') {
      return txt;
    } else {
      return doc;
    }
  };

  return (
    <>
      <td className="px-5 py-4 w-[33.33%] max-w-96">
        <div className="flex items-center gap-3 ">
          <div className="w-8 h-10 flex-none  ">
            <img
              src={getImgSrc()}
              onError={onError}
              className="w-full h-full object-contain"
            />
          </div>

          <div className="truncate  ">
            <h6 className="text-grayLight900 font-medium truncate">
              {data.fileOriginalName}
            </h6>
            <span className="font-normal text-grayLight600">
              {convertToGBMBKB(data.size)}
            </span>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 ">
        <span className=" ">
          {moment(data.uploadedDate).format('MMM DD, YYYY')}
        </span>
      </td>

      <td className="px-5 py-4 max-w-96 w-[33.33%]">
        <div className="flex items-center gap-3">
          {/* <div className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary  flex-none  "> */}
          {data.uploadedBy?.image ? (
            <img
              src={data.uploadedBy?.imageUrl + data.uploadedBy?.image}
              className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary  flex-none  "
              onError={onError}
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
              {getShortName(
                `${data?.uploadedBy?.firstName} ${data?.uploadedBy?.lastName}`
              )}
            </div>
          )}
          {/* </div> */}
          <div className=" ">
            <TooltipCmp
              message={`${data?.uploadedBy?.firstName} ${data?.uploadedBy?.lastName}`}
            >
              <p className="w-32 truncate text-grayLight900 font-medium">
                {`${data?.uploadedBy?.firstName} ${data?.uploadedBy?.lastName}`}
              </p>
            </TooltipCmp>
            <span className="font-normal">{data?.uploadedBy?.jobRole}</span>
          </div>
        </div>
      </td>
    </>
  );
};

export default LoadInsuranceFiles;
