import {
  CurrencyDollar,
  InfoCircle,
  PackageCheck,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';

import avatar from '../../../../../assets/img/Avatar1.png';

interface IProps {
  handleClose: any;
}

const MoveToActive = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Move to Active Quote'}
    titleDesc={'Review rate and margin details to create an active quote.'}
    handleClose={() => handleClose(false)}
    headerIcon={<PackageCheck />}
    size={'max-w-[688px]'}
    isOverflow={true}
    modalClassName=""
    primaryBtnText="Confirm"
    secondaryBtnText="Cancel"
  >
    <div className="w-full p-5 flex flex-col gap-4">
      <div className="rounded-md bg-utilityError50 border border-utilityError300 px-3 py-2 flex gap-x-1 items-center font-medium text-xs">
        <InfoCircle className="text-textSecondary w-4 h-4" />
        Final price not defined
      </div>
      <div className="w-full">
        <div className="flex -m-1 ">
          <div className="p-1 w-1/3">
            <label className="form_label block mb-1.5">Carrier</label>
            <div className="rounded-lg border border-borderPrimary bg-gray50 shadow-xs px-3 lg:py-[9px] py-2 flex gap-x-1.5 text-gray500 font-medium text-xs pointer-events-none">
              <div>
                <img src={avatar} className="rounded-full w-4 h-4 " />
              </div>
              <p className="">ABF Frieght</p>
            </div>
          </div>
          <InputText
            label="Transit time"
            inputName="transitTime"
            placeholder="Add Carrier"
            className=""
            labelClassName="block mb-1.5"
            value={'Est. 3 working days'}
            parentClassName="p-1 w-1/3"
            disabled
          />
          <InputText
            label="Projected delivery"
            inputName="projectdelivery"
            placeholder="Projected delivery"
            className=""
            labelClassName="block mb-1.5"
            value={'01 February, 2024'}
            parentClassName="p-1 w-1/3"
            disabled
          />
        </div>
      </div>
      <InputText
        label="Base Quote"
        inputName="basequote"
        placeholder="Base Quote"
        className="pl-8"
        labelClassName="block mb-1.5"
        value={'550.52'}
        icon={
          <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
        }
      />
      <InputText
        label="Margin"
        inputName="basequote"
        placeholder="Base Quote"
        className="pl-8"
        labelClassName="block mb-1.5"
        value={'10.00'}
        icon={
          <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-gray500 h-3.5 w-3.5" />
        }
        disabled
      />
      <InputText
        label="Final Price"
        inputName="finalprice"
        placeholder="Final Price"
        className="pl-8"
        labelClassName="block mb-1.5"
        value={'10.00'}
        icon={
          <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
        }
      />
    </div>
  </CommonModal>
);

export default MoveToActive;
