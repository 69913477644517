import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../redux/store';

export interface ErrorType {
  message: string;
  code: string;
  data?: any;
}

export interface IInitialState {
  data: any;
  loading: boolean;
  error:
    | {
        message: string | undefined;
        status?: string | undefined;
      }
    | undefined
    | null;
}

const initialState: IInitialState = {
  data: null,
  loading: false,
  error: null,
};

export const teamSlice = createSlice({
  name: 'shop/location',
  initialState,
  reducers: {
    setListing: (state, action) => {
      state.data = action.payload;
    },
  },
});
export const { setListing } = teamSlice.actions;

export const selectListing = (state: RootState) => state.Team.data;
export const selectLoading = (state: RootState) => state.Team.loading;
export const selectError = (state: RootState) => state.Team.error;
