import { UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import BadgeCmp from 'src/components/BadgeCmp';
import CropperModal from 'src/components/CropperModal/CropperModal';
import DeleteModal from 'src/components/DeleteModal';
import ErrorMsg from 'src/components/errorMsg';
import NotFoundUI from 'src/components/NotFoundUI';
import PdfPreviewModal from 'src/components/PdfPreviewModal/PdfModal';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { MAX_FILE_SIZE } from 'src/constants/common';
import { AuthContext } from 'src/context/AuthContext';
import {
  addClaimAttachmentFile,
  addDisputeAttachmentFile,
  deleteClaimDisputeAttachmentFile,
  getClaimDisputeFileList,
} from 'src/services/ClaimService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import { FilesTab } from '../ClaimsDisputes.const';

import FileUploadLoaderRow from './FileUploadLoaderRow';
import FileUploadRow from './FileUploadRow';

interface IProps {
  claimId?: number;
  disputeId?: number;
}

const FileUpload = ({ claimId, disputeId }: IProps) => {
  const [fileAttachmentFilter, setFileAttachmentFilter] = useState('all');
  const [upload, setUpload] = useState(false);
  const [displayImage, setDisplayImage] = useState<any>('');
  const [displayPDF, setDisplayPDF] = useState<any>('');
  const [displayPDFUrl, setDisplayPDFUrl] = useState<any>('');
  const [fileName, setFileName] = useState<any>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);

  const [attachmentFileList, setAttachmentFileList] = useState<any>([]);
  const [isFileListLoading, setIsFileListLoading] = useState(false);
  const [filesTotal, setFilesTotal] = useState<any>([]);
  const [isDeleteFile, setIsDeleteFile] = useState(false);
  const [isDeleteID, setIsDeleteID] = useState(null);

  const { currentUser } = useContext(AuthContext);

  const getFileAttachmentListData = () => {
    setIsFileListLoading(true);
    setAttachmentFileList([]);

    getClaimDisputeFileList({
      claimId: claimId,
      disputeId: disputeId,
      filterType: fileAttachmentFilter,
    })
      .then((response: any) => {
        if (response && response?.claimDisputeAttachments) {
          const responseData = response?.claimDisputeAttachments;
          setAttachmentFileList(responseData);
          setFilesTotal(response?.total ?? 0);
          setIsFileListLoading(false);
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    getFileAttachmentListData();
  }, [fileAttachmentFilter]);

  const uploadFile = (file: any) => {
    const appendFormData = new FormData();
    appendFormData.append('name', fileName);
    appendFormData.append('file', file.convertedFile);
    appendFormData.append('userId', currentUser?.id);

    if (claimId) {
      addClaimAttachmentFile(claimId, appendFormData)
        .then((response: any) => {
          if (response && response.data) {
            setUpload(false);
            setIsFileUploadLoading(false);
            getFileAttachmentListData();
            WalToast.success(`Claim file added successfully`);
          }
        })
        .catch(console.error);
    } else if (disputeId) {
      addDisputeAttachmentFile(disputeId, appendFormData)
        .then((response: any) => {
          if (response && response.data) {
            setUpload(false);
            setIsFileUploadLoading(false);
            getFileAttachmentListData();
            WalToast.success(`Dispute file added successfully`);
          }
        })
        .catch(console.error);
    }
  };

  const imageUpload = (data: any) => {
    const appLogoImage = data;
    const fileInfo: any = convertBase64ToFile(appLogoImage);

    if (fileInfo?.convertedFile && fileInfo?.convertedFile != '') {
      setIsFileUploadLoading(true);
      uploadFile(fileInfo);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file?.type?.match('image') || file?.type?.match('application/pdf')) {
      const { result } = checkFileTypeValidation(acceptedFiles, MAX_FILE_SIZE, [
        'image/svg+xml',
        'image/png',
        'image/jpeg',
        'application/pdf',
      ]);

      if (result) {
        const reader = new FileReader();

        reader.onloadstart = () => {
          setDisplayImage('');
          setDisplayPDF('');
          setErrorMessage('');
          setFileName('');
        };

        reader.onloadend = () => {
          setFileName(file.name);

          if (file?.type?.match('application/pdf')) {
            setDisplayPDFUrl(URL.createObjectURL(file));
            setDisplayPDF(reader.result as any);
          } else {
            setDisplayImage(reader.result as any);
          }

          setUpload(true);
        };
        reader.readAsDataURL(file);
      } else {
        setErrorMessage('File size is invalid');
      }
    } else {
      setErrorMessage('File type is invalid');
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
      'application/pdf': ['.pdf'],
    },
  });

  const filesHeadCells = useMemo(
    () => [
      {
        id: 'filename',
        name: 'File name',
      },
      {
        id: 'dateuploaded',
        name: 'Date uploaded',
      },
      {
        id: 'lastupdated',
        name: 'Last updated',
      },
      {
        id: 'uploadedby',
        name: 'Uploaded by',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const onAttachmentRowClick = (url: any) => () => {
    console.log('url', url);

    window.open(url, '_blank');
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status && isDeleteID) {
        deleteClaimDisputeAttachmentFile(isDeleteID)
          .then(() => {
            WalToast.success(`File deleted successfully`);
            setIsDeleteFile(false);
            setIsDeleteID(null);
            getFileAttachmentListData();
          })
          .catch((e) => {
            WalToast.error(
              e?.response?.data?.message ??
                'Something went wrong while deleting file.'
            );
          });
      } else {
        setIsDeleteFile(false);
        setIsDeleteID(null);
      }
    },
    [isDeleteID]
  );

  return (
    <>
      <div className="position-relative flex-1 mt-4">
        <div className=" md:gap-4 gap-2 md:flex-row flex-col items-center rounded-xl border border-borderSecondary hover:border-primary">
          <label
            className="cursor-pointer"
            htmlFor="fileSelect"
            {...getRootProps()}
          >
            <div className="relative z-[0] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl py-3.5 px-5 text-center bg-white">
              <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                <UploadCloud02 className="h-4 w-4" />
              </div>
              <progress
                className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden"
                value="70"
                max="100"
              ></progress>
              <div className="text-grayLight600 text-xs font-normal">
                <p>
                  <span className="text-[#2422DD] font-semibold">
                    Click to upload&nbsp;
                  </span>
                  or drag and drop
                </p>
                <p className="text-[11px] leading-[18px]">
                  SVG, PNG, JPG or PDF
                </p>
              </div>
            </div>
          </label>
          <input
            type="file"
            name="image"
            id="fileSelect11"
            accept="image/*"
            className="hidden"
            {...getInputProps()}
          />
        </div>
      </div>
      {errorMessage && <ErrorMsg errorText={errorMessage} />}

      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col mb-5 mt-4">
        <div className="table-top-header ">
          <div className="table-left-wrap ">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">Files & Attachments</h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={`${isFileListLoading ? 'custom-loading' : ''}`}
              >
                {filesTotal} {filesTotal > 1 ? 'Files' : 'File'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              {`Files and assets that have been attached to this 
                ${claimId ? 'claim' : ''}${disputeId ? 'dispute' : ''}
              .`}
            </p>
          </div>
          <TabButton
            tabArray={FilesTab}
            parentClassName="w-full"
            isActive={fileAttachmentFilter}
            handleOnClick={(e: any) =>
              setFileAttachmentFilter(e.target.dataset.value)
            }
          />
        </div>
        <div className="h-full w-full border-t border-gray100 ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <TableCmp
              headCells={filesHeadCells}
              tableDataArr={attachmentFileList}
              TableLoaderRowCmp={FileUploadLoaderRow}
              TableRowCmp={FileUploadRow}
              tableRowCmpProps={{
                getList: getFileAttachmentListData,
                setIsDeleteFile,
                setIsDeleteID,
                onRowClick: onAttachmentRowClick,
              }}
              isTableDataLoading={isFileListLoading}
            />
          </div>
        </div>
        {!isFileListLoading && attachmentFileList?.length <= 0 && (
          <NotFoundUI
            title={'No file attachment found'}
            desc={`There are no file attachment found`}
            containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            testId="wal-Claims-dataNotFoundText"
          />
        )}
      </div>

      {upload &&
        (displayPDF && displayPDF != '' ? (
          <PdfPreviewModal
            modalTitle={`${claimId ? 'Claim' : ''}${
              disputeId ? 'Dispute' : ''
            } task PDF preview`}
            modalDesc={`${claimId ? 'Claim' : ''}${
              disputeId ? 'Dispute' : ''
            } task PDF preview`}
            setUpload={setUpload}
            displayPDF={displayPDF}
            imageUpload={imageUpload}
            setIsFileUploadLoading={setIsFileUploadLoading}
            isFileUploadLoading={isFileUploadLoading}
            displayPDFUrl={displayPDFUrl}
          />
        ) : (
          <CropperModal
            modalTitle={`Edit ${claimId ? 'claim' : ''}${
              disputeId ? 'dispute' : ''
            } task Image`}
            modalDesc={`Edit ${claimId ? 'claim' : ''}${
              disputeId ? 'dispute' : ''
            } task Image as per your requirement`}
            imageUpload={imageUpload}
            imageUrl={displayImage}
            setUpload={setUpload}
            defaultImage={''}
            setCropData={setDisplayImage}
            isCropType={false}
            defaultCropType="Square"
            isSetToModalClose={false}
            primaryBtnLoading={isFileUploadLoading}
          />
        ))}
      {isDeleteFile && (
        <DeleteModal
          moduleName={`file from ${claimId ? 'claim' : ''}${
            disputeId ? 'dispute' : ''
          }`}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
};

export default FileUpload;
