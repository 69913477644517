import { Edit05, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import avatar from 'src/assets/img/Avatar1.png';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';
import TooltipCmp from 'src/components/TooltipCmp';

const DetailsActionLoading = () => (
  <>
    <td className="px-5 py-4 min-w-64 w-64 max-w-64">
      <div className="flex items-center gap-3 ">
        <div className="custom-loading">
          <CheckBox
            classes="checkbox-fgSuccessPrimary checked:border-fgSuccessPrimary"
            onChangeFunc={() => {}}
          />
        </div>

        <p className="truncate line-through custom-loading">
          Lorem Ipsum is simply dummy text
        </p>
      </div>
    </td>
    <td className="px-5 py-4 max-w-64 min-w-[20%] w-[20%]">
      <div className="flex items-center gap-3">
        <div className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary  flex-none custom-loading">
          <img src={avatar} className="h-full w-full" />
        </div>
        <TooltipCmp message={'Kate Morrison'}>
          <p className="w-32 truncate text-grayLight900 font-medium custom-loading">
            Kate Morrison
          </p>
        </TooltipCmp>
      </div>
    </td>
    <td className="px-5 py-4 text-xs max-w-64 min-w-[20%] w-[20%]">
      <BadgeCmp style="modern" type="pink" mainClassName="custom-loading">
        High
      </BadgeCmp>
    </td>
    <td className="px-5 py-4  min-w-44 max-w-44">
      <span className="custom-loading ">18 Jun, 2024 09:34 PM</span>
    </td>
    <td className="px-5 py-4 max-w-32 w-32 min-w-32">
      <div className="flex gap-2">
        <Edit05 className="w-3.5 h-3.5 cursor-pointer custom-loading" />
        <Trash01 className="w-4 h-4 cursor-pointer custom-loading" />
      </div>
    </td>
  </>
);

export default DetailsActionLoading;
