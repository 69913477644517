import React, { useContext, useEffect, useRef } from 'react';
import { BasicContext } from 'src/context/BasicContext';

interface MapProps {
  address: any;
  id: string;
  onReverseAddressFunc?: any;
  draggable?: boolean;
  height?: number;
  className?: string;
}

const initialMapAddressVal = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
};

const TrimbleMap: React.FC<MapProps> = ({
  address,
  id,
  className,
  onReverseAddressFunc,
  height,
  draggable,
}) => {
  // console.log(onReverseAddressFunc, draggable);

  const mapRef = useRef(null);

  const latitude = parseFloat(address?.latitude);
  const longitude = parseFloat(address?.longitude);
  const { trimbleKey } = useContext(BasicContext);

  const getAddress = async (lat: number, lng: number) => {
    // console.log(lat, lng);

    const response = await fetch(
      `https://pcmiler.alk.com/apis/rest/v1.0/Service.svc/locations/reverse?Coords=${encodeURIComponent(
        `${lng},${lat}`
      )}&authToken=${trimbleKey}&matchNamedRoadsOnly=true&maxCleanupMiles=20&region=NA&dataset=Current`
    );
    const data = await response.json();
    // console.log(data);
    let addressObject = data.Address;

    if (addressObject.Zip) {
      addressObject.Zip = addressObject.Zip.split('-')[0];
    }

    let fullAddress = [];

    if (addressObject.StreetAddress) {
      fullAddress.push(addressObject.StreetAddress);
    }

    if (addressObject.City) {
      fullAddress.push(addressObject.City);
    }

    if (addressObject.State) {
      fullAddress.push(addressObject.State);
    }

    if (addressObject.CountryAbbreviation) {
      fullAddress.push(
        `${addressObject.CountryAbbreviation} ${addressObject.Zip}`
      );
    }

    if (addressObject) {
      const addressObj = {
        fullAddress: fullAddress.join(', '),
        address1: addressObject.StreetAddress || addressObject.City,
        city: addressObject.City,
        state: addressObject.StateName,
        country: addressObject.Country,
        postal: addressObject.Zip,
        latitude: data.Coords.Lat,
        longitude: data.Coords.Lon,
        stateCode: addressObject.State,
        countryCode: addressObject.CountryAbbreviation,
      };
      onReverseAddressFunc(addressObj);
    } else {
      onReverseAddressFunc(initialMapAddressVal);
    }
  };

  const initializeMap = () => {
    window.TrimbleMaps.APIKey = trimbleKey;
    const center = [
      !isNaN(longitude) ? longitude : -106.346771,
      !isNaN(latitude) ? latitude : 56.130366,
    ];
    const map = new window.TrimbleMaps.Map({
      container: id, // container id
      style: window.TrimbleMaps.Common.Style.SATELLITE, // hosted style id
      center: center, // starting position
      zoom: !isNaN(latitude) && !isNaN(longitude) ? 14 : 2, // starting zoom
    });

    if (!isNaN(longitude) && !isNaN(latitude)) {
      const marker = new window.TrimbleMaps.Marker({
        draggable: draggable,
      })
        .setLngLat(center)
        .addTo(map);

      if (draggable) {
        marker.on('dragend', function (e: any) {
          const lngLat = e.target.getLngLat();
          // console.log(lngLat);
          getAddress(lngLat.lat, lngLat.lng);
        });
      }
    }
  };

  useEffect(() => {
    initializeMap();
  }, [address]);

  return (
    <div
      className={className}
      id={id}
      ref={mapRef}
      style={{ width: '100%', height: `${height}px` }}
    />
  );
};

TrimbleMap.defaultProps = {
  address: {},
  id: '',
  draggable: true,
  height: 220,
};

export default TrimbleMap;
