import { Check } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo } from 'react';
import {
  getDateWithSuffixFormat,
  getLabelByValue,
} from 'src/utils/CommonFunctions';

import Drawer from '../../../../components/Drawer';

const QuoteDetail = ({ quote, isOpen, handleClose }: any) => {
  const { addressDetails, services, dimensions, totalUnit, totalWeight } =
    quote;

  const getAddressSecondPart = (address: string) => {
    if (address) {
      const commaLength = address?.split(', ').length - 1;

      if (commaLength >= 3) {
        const parts = address?.split(', ');

        return parts.slice(1).join(', ');
      }
    }

    return address;
  };

  const drawerTitle = (
    <>
      <span className="text-grayLight900 text-base font-semibold">
        Quote:&nbsp;
      </span>
      <span className="text-grayLight900 text-base font-semibold ">
        {' '}
        #{addressDetails.code}
      </span>
    </>
  );

  return (
    <Drawer
      isOpen={isOpen}
      handleClose={handleClose}
      title={drawerTitle}
      classDrawer="max-w-[700px] sm:!w-[380px] !w-[304px]"
    >
      <div className="flex-col flex gap-4">
        {/* <div className="flex items-center">
                    <img
                        src={addressDetails.image ? addressDetails.imageUrl + addressDetails.image : profile1}
                        className="w-8 h-8 flex-none rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08]"
                    />
                    <div className="pl-[10px] text-sm break-all">
                        <h6 className="text-textSecondary font-semibold	 leading-tight">
                            {addressDetails.firstName} {addressDetails.lastName}
                        </h6>
                        <a href={`mailto:${addressDetails.email}`} className="text-grayLight600 font-normal leading-tight break-all">
                            {addressDetails.email}
                        </a>
                    </div>
                </div> */}
        <h6 className="text-grayLight900 text-base font-semibold">Customer</h6>
        <div className="flex flex-col gap-1.5 w-full justify-between items-start pb-4 border-b border-borderPrimary ">
          <div className="flex justify-between text-sm w-full gap-x-3">
            <p className="text-gray500  font-normal">Name</p>
            <span className="text-utilityGray800 font-semibold text-right">
              {addressDetails.customerName}
            </span>
          </div>
        </div>
        <h6 className="text-grayLight900 text-base font-semibold">
          Address Details
        </h6>
        <div className="flex flex-col gap-1.5 w-full justify-between items-start pb-4 border-b border-borderPrimary">
          <div className="flex justify-between text-sm w-full gap-x-3">
            <p className="text-gray500  font-normal">Origin</p>
            <span className="text-utilityGray800 font-semibold text-right">
              {getAddressSecondPart(addressDetails.shipperFullAddress)}
            </span>
          </div>
          <div className="flex justify-between text-sm w-full gap-x-3">
            <p className="text-gray500  font-normal">Destination</p>
            <span className="text-utilityGray800 font-semibold text-right">
              {getAddressSecondPart(addressDetails.consigneeFullAddress)}
            </span>
          </div>
          <div className="flex justify-between text-sm w-full gap-x-3">
            <p className="text-gray500  font-normal">Pickup Date</p>
            <span
              className="text-utilityGray800 font-semibold text-right"
              dangerouslySetInnerHTML={{
                __html: addressDetails.pickupDate
                  ? getDateWithSuffixFormat(
                      moment
                        .utc(addressDetails.pickupDate)
                        .format(`MMM Do YYYY`)
                    )
                  : '-',
              }}
            ></span>
          </div>
          <div className="flex justify-between text-sm w-full gap-x-3">
            <p className="text-gray500  font-normal">Total Piece</p>
            <span className="text-utilityGray800 font-semibold text-right">
              {totalUnit}
            </span>
          </div>
          <div className="flex justify-between text-sm w-full gap-x-3">
            <p className="text-gray500  font-normal">Total Weight</p>
            <span className="text-utilityGray800 font-semibold text-right">
              {totalWeight}
              {dimensions.length > 0 &&
                getLabelByValue(
                  dimensions[0]?.weightMeasurement
                )?.toLowerCase()}
            </span>
          </div>
        </div>
        <h6 className="text-grayLight900 text-base font-semibold">
          Dimensions
        </h6>
        {dimensions.map((val: any, index: any) => (
          <>
            <div
              className={`flex flex-col gap-1.5 w-full justify-between items-start pb-4 border-b border-borderPrimary ${
                index + 1 < dimensions.length ? 'border-b' : ''
              }`}
            >
              <div className="flex justify-between text-sm w-full gap-x-3">
                <p className="text-gray500  font-normal">Handling unit </p>
                <span className="text-utilityGray800 font-semibold text-right">
                  {val.handlingUnitName}
                </span>
              </div>
              <div className="flex justify-between text-sm w-full gap-x-3">
                <p className="text-gray500  font-normal">№ of HU </p>
                <span className="text-utilityGray800 font-semibold text-right">
                  {val.handlingUnitNo}
                </span>
              </div>
              <div className="flex justify-between text-sm w-full gap-x-3">
                <p className="text-gray500  font-normal">Weight per unit</p>
                <span className="text-utilityGray800 font-semibold text-right">
                  {val.weight}
                  {val.weightMeasurement &&
                    getLabelByValue(val.weightMeasurement)?.toLowerCase()}
                </span>
              </div>
              <div className="flex justify-between text-sm w-full gap-x-3">
                <p className="text-gray500  font-normal">
                  Dimensions (
                  {getLabelByValue(val.lengthMeasurement)?.toLowerCase()})
                </p>
                <span className="text-utilityGray800 font-semibold text-right">
                  {`${val.itemLength}L x ${val.width}W x ${val.height}`}H
                </span>
              </div>
              <div className="flex justify-between text-sm w-full gap-x-3">
                <p className="text-gray500  font-normal">Total weight</p>
                <span className="text-utilityGray800 font-semibold text-right">
                  {val.totalWeight}
                  {val.weightMeasurement &&
                    getLabelByValue(val.weightMeasurement)?.toLowerCase()}
                </span>
              </div>
              <div className="flex justify-between text-sm w-full gap-x-3">
                <p className="text-gray500  font-normal">Freight Class</p>
                <span className="text-utilityGray800 font-semibold text-right">
                  {val.freightClass}
                </span>
              </div>
              <div className="flex justify-between text-sm w-full gap-x-3">
                <p className="text-gray500  font-normal">Item Class</p>
                <span className="text-utilityGray800 font-semibold text-right">
                  {val.itemClassForDayross}
                </span>
              </div>
            </div>
          </>
        ))}
        {services.length ? (
          <>
            <h6 className="text-grayLight900 text-base font-semibold">
              Selected services
            </h6>
            <div className="flex-col gap-4 flex">
              {services.map((val: any) => (
                <div
                  key={val?.serviceName}
                  className=" items-center flex gap-1"
                >
                  <Check className="w-4 h-4 text-primary" />
                  <div className="text-grayLight600 text-sm font-medium">
                    {val.serviceName}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </Drawer>
  );
};

export default memo(QuoteDetail);
