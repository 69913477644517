import { yupResolver } from '@hookform/resolvers/yup';
import { AlertCircle, Recording01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import * as yup from 'yup';

interface IProps {
  handleClose: () => void;
}

const ConnectionModal = ({ handleClose }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required.'),
    password: yup.string().required('Password is required.'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    setIsLoading(false);
    console.log('data', data);
  };

  return (
    <div>
      <CommonModal
        title="Get Connection"
        titleDesc="Create new carrier for free in less than 5 minutes."
        headerIcon={<Recording01 />}
        size="max-w-lg"
        handleClose={handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full px-5 pb-4 bg-white rounded-xl flex-col flex">
            <div className="w-full mb-3">
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    label="Email"
                    inputName="email"
                    placeholder="Enter Email"
                    icon={
                      errors.email ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    value={value}
                    onChangeFunc={onChange}
                    errorText={errors.email ? errors.email.message : null}
                  />
                )}
              />
            </div>
            <div className="w-full mb-3">
              <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    label="Password"
                    inputName="password"
                    placeholder="Enter Password"
                    icon={
                      errors.password ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    value={value}
                    onChangeFunc={onChange}
                    errorText={
                      errors.password ? errors?.password.message : null
                    }
                  />
                )}
              />
            </div>
            <div className="w-full justify-end items-start flex">
              <div className="justify-center items-center gap-1.5 flex">
                <ButtonCmp className="btn_primary  flex-1" disabled={isLoading}>
                  Grant Permissions
                </ButtonCmp>
              </div>
            </div>
          </div>
        </form>
      </CommonModal>
    </div>
  );
};

export default ConnectionModal;
