import { yupResolver } from '@hookform/resolvers/yup';
import {
  CalendarCheck01,
  UploadCloud02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import StepBar from 'src/components/StepBar/StepBar';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import CheckBox from 'src/components/CheckBox';
import DateSelect from 'src/components/DateSelect/DateSelect';
import DeleteModal from 'src/components/DeleteModal';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { orgTypeArr } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { usePhone } from 'src/hooks/usePhone';
import {
  businessCreditApplication,
  businessCreditApplicationDetail,
  deleteStatementFile,
  updateOnboarding,
} from 'src/services/CustomerOnBoardingService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import LogoDark from '../../assets/img/logo-dark.png';

import CreditReferenceItem from './CreditReferenceItem';
import OfficersItem from './OfficersItem';
import StatementModal from './StatementModal';
import StatementOnboarding from './StatementOnboarding';
import TradeReferenceItem from './TradeReferenceItem';
import VerifyOtp from './VerifyOtp';

const initOfficers = {
  name: '',
  title: '',
  home_address: '',
  city: '',
};

const initCreditRefenerence = {
  company_name: '',
  phone_number: '',
  email: '',
  credit_amount: '',
};

const initTradeRefenerence = {
  company_name: '',
  phone_number: '',
  email: '',
  city: '',
};
const initAction = {
  mode: '',
  deleteId: 0,
};

const BusinessApplicationForm = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const verifyData: any = localStorage.getItem('verifyData');
  const [action, setAction] = useState(initAction);
  const formDataString = localStorage.getItem('formData');
  const [customerDetail, setCustomerDetail] = useState<any>();
  const formDetail: any = formDataString ? JSON.parse(formDataString) : null;
  const { isVerify, customerId } = verifyData
    ? JSON.parse(verifyData)
    : { isVerify: 0, customerId: null };

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [officersArr, setOfficersArr] = useState<any[]>(
    formDetail?.officers ?? [initOfficers]
  );
  const [financeStatementArr, setFinanceStatementArr] = useState<any[]>(
    formDetail?.financeStatement ?? []
  );
  const [creditReferenceArr, setCreditReferenceArr] = useState<any[]>(
    formDetail?.creditReferences ?? [initCreditRefenerence]
  );
  const [tradeReferenceArr, setTradeReferenceArr] = useState<any[]>(
    formDetail?.tradeReferences ?? [initTradeRefenerence]
  );
  const { isPhoneValid } = usePhone();
  const [isVerifyOtp, setIsVerifyOtp] = useState(
    isVerify === '1' && id == customerId ? true : false
  );

  useEffect(() => {
    console.log('customerDetail :>> ', customerDetail);
  }, [customerDetail]);

  const updateFormViewDate = (custId: number) => {
    updateOnboarding({
      id: custId,
      form_view_date: moment().format('YYYY-MM-YY'),
    })
      .then(() => {})
      .catch(() => WalToast.error('something went wrong please try again'));
  };

  const getDetail = () => {
    setIsLoading(true);
    businessCreditApplicationDetail(id)
      .then((response: any) => {
        if (response) {
          setCustomerDetail(response?.data?.personal_detail);
          setFinanceStatementArr(response?.data?.financeStatement);
          updateFormViewDate(response?.data?.personal_detail?.id);
          setIsLoading(false);
        }
      })
      .catch((e: any) =>
        WalToast.error(
          e?.response?.data?.message ?? 'something went wrong please try again'
        )
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getDetail();
  }, []);

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        deleteStatementFile(action?.deleteId)
          .then(() => {
            getDetail();
            WalToast.success('Statement Deleted successfully');
          })
          .catch((e: any) =>
            WalToast.error(
              e?.response?.data?.message ??
                'something went wrong please try again'
            )
          )
          .finally(() => {
            setIsLoading(false);
          });
      }
      setAction(initAction);
    },
    [action]
  );

  const validationSchema = yup.object().shape({
    company_name: yup.string().required('Company is required.'),
    start_date: yup.date().required('Start date is required.'),
    // contact_number: yup
    //   .string()
    //   .test({
    //     message: 'Invalid phone number',
    //     test: function (value: any) {
    //       if (value === '') {
    //         return true;
    //       }
    //       const { phone_country_code: phoneCountryCode } = this.parent;
    //       const isValid = isPhoneValid(value, phoneCountryCode);

    //       return isValid;
    //     },
    //   })
    //   .required('Contact number is required.'),
    contact_number: yup
      .string()
      .test('isValidPhone', 'Invalid phone number', function (value) {
        if (value === '') {
          return true;
        }
        const { phone_country_code: phoneCountryCode } = this.parent;

        return isPhoneValid(value, phoneCountryCode);
      })
      .required('Contact number is required.'),
    phone_country_code: yup.string().required('Country code is required.'),
    fax: yup.string(),
    company_email: yup
      .string()
      .email('Must be a valid email')
      .required('Email is required.'),
    address: yup.string().required('Address is required.'),
    city: yup.string().required('City is required.'),
    state: yup.string().required('State is required.'),
    postal: yup.string().required('Postal code is required.'),
    country: yup.string().required('Country is required.'),
    organization_type: yup
      .array()
      .of(yup.string())
      .min(1, 'At least one organization type must be selected')
      .required('Organization type is required.'),
    officers: yup.array().of(
      yup.object().shape({
        officer_name: yup.string().required('Name is required'),
        title: yup.string(),
        home_address: yup.string().required('Home address is required'),
        city: yup.string().required('City is required'),
      })
    ),
    creditReferences: yup.array().of(
      yup.object().shape({
        company_name: yup.string().required('Company name is required'),
        phone_number: yup
          .string()
          .required('Phone number is required')
          .matches(/^[+\-() \d]*$/, 'Invalid phone number'),
        email: yup
          .string()
          .email('Invalid email')
          .required('Email is required'),

        credit_amount: yup
          .number()
          .positive('Credit amount must be greater than 0')
          .min(0.01, 'Credit amount must be greater than 0')
          .required('Credit amount is required'),
      })
    ),
    tradeReferences: yup.array().of(
      yup.object().shape({
        company_name: yup.string().required('Company name is required'),
        phone_number: yup
          .string()
          .required('Phone number is required')
          .matches(/^[+\-() \d]*$/, 'Invalid phone number'),
        email: yup
          .string()
          .email('Invalid email')
          .required('Email is required'),
        city: yup.string().required('City is required'),
      })
    ),
    financeStatement: yup.array().of(
      yup.object().shape({
        year: yup.string().required('Year is required'),
        quarter: yup.string().required('Quarter is required'),
        file: yup.string().required('file is required'),
      })
    ),
    business_start_date: yup
      .date()
      .required('Business Start date is required.'),
    federal_tax_number: yup.string(),
    business_type: yup.string(),
    d_b_number: yup.string(),
    account_payable_name: yup.string().required('Name is required.'),
    account_payable_phone: yup
      .string()
      .required('Phone no is required.')
      .matches(/^[+\-() \d]*$/, 'Invalid phone number'),
    account_payable_email: yup
      .string()
      .email('Invalid email')
      .required('Email is required.'),
    bank_name: yup.string().required('Bank name is required.'),
    bank_email: yup
      .string()
      .email('Invalid email')
      .required('Bank email is required.'),
    bank_address: yup.string(),
    bank_contact_name: yup.string(),
    bank_phone: yup.string().matches(/^[+\-() \d]*$/, 'Invalid phone number'),
    bank_city: yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...formDetail,
      start_date: formDetail?.start_date
        ? new Date(formDetail?.start_date)
        : new Date(),
      business_start_date: formDetail?.business_start_date
        ? new Date(formDetail?.business_start_date)
        : new Date(),
      organization_type: formDetail?.organization_type || [],
    },
    mode: 'onTouched',
  });

  const onSubmit = async (formData: any) => {
    setIsSubmitLoading(true);
    // localStorage.setItem('formData', JSON.stringify(formData));
    // navigate(`${PATH.SUBMISSION}/${id}`, { state: { formData } });
    businessCreditApplication(id, formData)
      .then(() => {
        WalToast.success('Customer added successfully');
        localStorage.removeItem('verifyData');
        navigate(`${ROUTES.THANK_YOU}`);
      })
      .catch(() => WalToast.error('Customer not added'))
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };
  useEffect(() => {
    console.log('∫∫getValues :>> ', getValues());
    console.log('errors :>> ', errors);
  }, [getValues, errors]);

  const countryArr = [
    { label: 'Canada', value: 'canada' },
    { label: 'USA', value: 'usa' },
  ];

  const addRaw = (type: string) => {
    if (type === 'officers') {
      setOfficersArr((old: any) => [...old, initOfficers]);
    } else if (type === 'credit_reference') {
      setCreditReferenceArr((old: any) => [...old, initCreditRefenerence]);
    } else if (type === 'trade_reference') {
      setTradeReferenceArr((old: any) => [...old, initTradeRefenerence]);
    }
  };

  const removeRaw = (removeIndex: number, type: string) => {
    console.log('remoeRow :>> ', type, ' ', removeIndex);

    if (type === 'officers') {
      const newArray = getValues('officers')?.filter(
        (_: any, index: any) => index !== removeIndex
      );
      setOfficersArr(newArray);
      setValue('officers', newArray);
    } else if (type === 'credit_reference') {
      const newArray = getValues('creditReferences')?.filter(
        (_: any, index: any) => index !== removeIndex
      );
      setCreditReferenceArr(newArray);
      setValue('creditReferences', newArray);
    } else if (type === 'trade_reference') {
      const newArray = getValues('tradeReferences')?.filter(
        (_: any, index: any) => index !== removeIndex
      );
      setTradeReferenceArr(newArray);
      setValue('tradeReferences', newArray);
    } else if (type === 'financeStatement') {
      const newArray = getValues('financeStatement')?.filter(
        (_: any, index: any) => index !== removeIndex
      );
      // setTradeReferenceArr(newArray);
      setValue('financeStatement', newArray);
    }
  };

  const StatementHeadCells = useMemo(
    () => [
      {
        id: 'file_name',
        name: 'File name',
      },
      {
        id: 'year',
        name: 'Year',
      },
      {
        id: 'quarter',
        name: 'Quarter',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const [isShowStatementModal, setIsShowStatementModal] = useState(false);
  console.log('watch financeStatement :>> ', watch('financeStatement'));

  return (
    <>
      {isVerifyOtp || customerDetail?.isMailSent === 0 ? (
        <div className="overflow-y-auto h-full p-5 bg-utilityGray50 flex flex-col">
          <div className="max-w-[1200px] rounded-xl bg-white shadow-lgc xl:my-[30px] my-5 mx-auto flex flex-col flex-1 overflow-hidden w-full">
            <div className="border-b border-borderPrimary px-5 py-3 flex flex-wrap gap-3 justify-between">
              <div className="max-w-[517px] ">
                <h6 className="text-grayLight900 text-base font-semibold">
                  Business Credit Application Form
                </h6>
                <p className="text-grayLight600 text-xs font-normal ">
                  Western Alliance Logistics requests your cooperation in
                  completing and returning this form as soon as possible so that
                  we can review your request for credit availability.
                </p>
              </div>
              <div className="flex items-center">
                <div className="max-w-[113px] ">
                  <img src={LogoDark} alt="logo-light" className="w-full" />
                </div>

                {customerDetail?.image && (
                  <div className="max-w-[128px] min-w-[50px] pl-3 ml-3 border-l border-borderPrimary">
                    <img
                      src={`${customerDetail.imageUrl}${customerDetail.image}`}
                      className="w-full h-[50px] object-cover"
                      alt="Customer"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-4 flex-1 overflow-auto custom-scrollbar-v2 p-6">
              <p className="text-grayLight900 text-sm font-semibold">
                Company Information
              </p>
              <div className="rounded-lg border border-utilityGray200 px-3 pt-3.5 pb-4 flex flex-col gap-y-4">
                <div className="flex max-sm:flex-col max-sm:gap-y-4 sm:-mx-1.5">
                  <Controller
                    name="company_name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        label="Company Name"
                        inputName="companyName"
                        placeholder="Company Name"
                        parentClassName="sm:w-1/2 sm:px-1.5"
                        value={value}
                        required={true}
                        onChangeFunc={onChange}
                        errorText={
                          errors.company_name
                            ? errors.company_name.message
                            : null
                        }
                        labelClassName="form_label block mb-1.5"
                      />
                    )}
                  />
                  <Controller
                    name="start_date"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DateSelect
                        inputName="start_date"
                        className="form_control"
                        parentClassName="sm:w-1/2 sm:px-1.5 datepicker-w-auto"
                        label="Start Date"
                        selected={value ? moment(value).toDate() : undefined}
                        value={moment(value).format('DD/MM/yyyy')} // Ensuring the value is always a Date object
                        labelClassName="block"
                        placeholder="Start Date"
                        showYearDropdown
                        required={true}
                        dateFormat="dd/MM/yyyy"
                        errorText={errors?.start_date?.message || ''}
                        onChangeFunc={(date: any) => {
                          onChange(date?.value);
                        }} // Update form value on change
                        icon={<CalendarCheck01 className="h-5 w-5" />}
                      />
                    )}
                  />
                </div>
                <div className="flex max-md:flex-col max-md:gap-y-4 md:-mx-1.5">
                  <div className="md:w-1/3 md:px-1.5">
                    <label className="form_label block mb-1.5">
                      Contact Number{' '}
                      <span className="text-red-600 leading-4">*</span>
                    </label>
                    <Controller
                      name="contact_number"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          onNumberChange={(
                            country: any,
                            phone: any,
                            code: any
                          ) => {
                            const newValue = `+${code}${phone}`;
                            setValue('contact_number', newValue);
                            setValue('phone_country_code', country);
                            onChange(newValue);

                            if (touchedFields.contactPhone) {
                              trigger('contactPhone');
                              trigger('phone_country_code');
                            }
                          }}
                          value={value}
                          name="contact_number"
                          errors={
                            errors.contact_number
                              ? errors.contact_number.message
                              : errors.phone_country_code
                              ? errors.phone_country_code.message
                              : null
                          }
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="fax"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        label="Fax"
                        inputName="fax"
                        placeholder="Fax"
                        parentClassName="md:w-1/3 md:px-1.5"
                        value={value}
                        onChangeFunc={onChange}
                        labelClassName="form_label block mb-1.5"
                      />
                    )}
                  />
                  <Controller
                    name="company_email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        label="Email"
                        inputName="company_email"
                        placeholder="Email"
                        parentClassName="md:w-1/3 md:px-1.5"
                        value={value}
                        onChangeFunc={onChange}
                        required={true}
                        errorText={
                          errors.company_email
                            ? errors.company_email.message
                            : null
                        }
                        labelClassName="form_label block mb-1.5"
                      />
                    )}
                  />
                </div>

                <Controller
                  name="address"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputText
                      label="Address"
                      inputName="address"
                      placeholder="Address"
                      parentClassName="w-full"
                      value={value}
                      required={true}
                      onChangeFunc={onChange}
                      errorText={errors.address ? errors.address.message : null}
                      labelClassName="form_label block mb-1.5"
                    />
                  )}
                />

                <div className="flex flex-wrap max-sm:flex-col max-md:gap-y-4 sm:-mx-1.5">
                  <Controller
                    name="city"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        label="City"
                        inputName="city"
                        placeholder="City"
                        parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                        value={value}
                        onChangeFunc={onChange}
                        required={true}
                        errorText={errors.city ? errors.city.message : null}
                        labelClassName="form_label block mb-1.5"
                      />
                    )}
                  />
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        label="State"
                        inputName="state"
                        placeholder="State"
                        parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                        value={value}
                        onChangeFunc={onChange}
                        required={true}
                        errorText={errors.state ? errors.state.message : null}
                        labelClassName="form_label block mb-1.5"
                      />
                    )}
                  />
                  <Controller
                    name="postal"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        label="Postal"
                        inputName="postal"
                        placeholder="Postal"
                        parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                        value={value}
                        onChangeFunc={onChange}
                        required={true}
                        errorText={errors.postal ? errors.postal.message : null}
                        labelClassName="form_label block mb-1.5"
                      />
                    )}
                  />
                  <Controller
                    name="country"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectBox
                        name="country"
                        label="Country"
                        labelClassName="form_label block mb-1.5"
                        parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                        className="form_control"
                        size="sm"
                        required={true}
                        errorText={
                          errors.country ? errors.country.message : null
                        }
                        placeholder="Country"
                        options={countryArr}
                        onChangeFunc={(e: any) => onChange(e?.value ?? '')}
                        value={countryArr.filter(
                          (val: any) => value === val?.value
                        )}
                      />
                    )}
                  />
                </div>
                <div className="">
                  <p className="form_label block mb-1.5">
                    Organization Type{' '}
                    <span className="text-red-600 leading-4">*</span>{' '}
                  </p>
                  <div className="rounded-lg border border-utilityGray200 flex flex-wrap gap-y-4 px-3 py-4">
                    <Controller
                      name="organization_type"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          {orgTypeArr.map((val: any, index: number) => (
                            <div className="flex w-1/4 pr-3" key={index}>
                              <CheckBox
                                id={`service_${val.value}`}
                                onChangeFunc={(e: any) => {
                                  const checked = e.target.checked;
                                  let updatedServices = value ? [...value] : [];

                                  if (checked) {
                                    updatedServices.push(val.value);
                                  } else {
                                    updatedServices = updatedServices.filter(
                                      (item) => item !== val.value
                                    );
                                  }
                                  onChange(updatedServices);
                                }}
                                name={val.value}
                                checked={value?.includes(val.value)}
                                value={val.label}
                                parentClassName=""
                              />
                              <label
                                htmlFor={`service_${val.value}`}
                                className="ml-2 text-textSecondary text-xs font-medium"
                              >
                                {val.label}
                              </label>
                            </div>
                          ))}
                        </>
                      )}
                    />
                  </div>
                  <span className="text-red-600 text-xs leading-4">
                    {(errors?.organization_type?.message as any) ?? null}
                  </span>
                </div>
                <div className="flex max-sm:flex-col max-sm:gap-y-4 sm:-mx-1.5">
                  <Controller
                    name="business_start_date"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DateSelect
                        inputName="business_start_date"
                        className="form_control"
                        parentClassName="sm:w-1/2 sm:px-1.5 datepicker-w-auto"
                        label="Start Date"
                        selected={value ? moment(value).toDate() : undefined}
                        value={moment(value).format('DD/MM/yyyy')} // Ensuring the value is always a Date object
                        labelClassName="block"
                        placeholder="Start Date"
                        required={true}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        errorText={
                          errors.business_start_date
                            ? errors.business_start_date.message
                            : null
                        }
                        onChangeFunc={(date: any) => {
                          onChange(date?.value);
                        }} // Update form value on change
                        icon={<CalendarCheck01 className="h-5 w-5" />}
                      />
                    )}
                  />
                  <Controller
                    name="federal_tax_number"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        label="Federal Tax ID Number"
                        inputName="federal_tax_number"
                        placeholder="Federal Tax ID Number"
                        parentClassName="sm:w-1/2 sm:w-1/2 sm:px-1.5"
                        value={value}
                        onChangeFunc={onChange}
                        labelClassName="form_label block mb-1.5"
                      />
                    )}
                  />
                </div>
                <div className="flex max-sm:flex-col max-sm:gap-y-4 sm:-mx-1.5">
                  <Controller
                    name="business_type"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        label="Type of Business"
                        inputName="business_type"
                        placeholder="Enter your Business"
                        parentClassName="sm:w-1/2 sm:w-1/2 sm:px-1.5"
                        value={value}
                        onChangeFunc={onChange}
                        labelClassName="form_label block mb-1.5"
                      />
                    )}
                  />
                  <Controller
                    name="d_b_number"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        label="D&B (DUNS) Number"
                        inputName="d_b_number"
                        placeholder="Enter number"
                        parentClassName="sm:w-1/2 sm:w-1/2 sm:px-1.5"
                        value={value}
                        onChangeFunc={onChange}
                        labelClassName="form_label block mb-1.5"
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <span className="form_label block mb-1.5 font-semibold">
                  Accounts Payable Contact
                </span>
                <div className="rounded-lg border border-utilityGray200 px-3 pt-3.5 pb-4">
                  <div className="flex flex-wrap gap-y-4 md:-mx-1.5">
                    <Controller
                      name="account_payable_name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          label="Name"
                          inputName="account_payable_name"
                          placeholder="Enter Name"
                          parentClassName="md:w-1/3 md:px-1.5"
                          value={value}
                          onChangeFunc={onChange}
                          required={true}
                          errorText={
                            errors.account_payable_name
                              ? errors.account_payable_name.message
                              : null
                          }
                          labelClassName="block mb-1.5"
                        />
                      )}
                    />
                    <Controller
                      name="account_payable_phone"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          label="Phone number"
                          inputName="account_payable_email"
                          placeholder="Enter number"
                          parentClassName="md:w-1/3 md:px-1.5"
                          value={value}
                          onChangeFunc={onChange}
                          required={true}
                          errorText={
                            errors.account_payable_phone
                              ? errors.account_payable_phone.message
                              : null
                          }
                          labelClassName="block mb-1.5"
                        />
                      )}
                    />
                    <Controller
                      name="account_payable_email"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          label="Email"
                          inputName="email"
                          placeholder="Enter email"
                          parentClassName="md:w-1/3 md:px-1.5"
                          value={value}
                          onChangeFunc={onChange}
                          required={true}
                          errorText={
                            errors.account_payable_email
                              ? errors.account_payable_email.message
                              : null
                          }
                          labelClassName="block mb-1.5"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              {/* Officers & Owners */}
              <div>
                <h6 className="text-grayLight900 text-sm font-semibold mb-1.5">
                  Officers & Owners
                </h6>
                <OfficersItem
                  officersArr={officersArr}
                  addRaw={addRaw}
                  removeRaw={removeRaw}
                  control={control}
                  errors={errors}
                />
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-semibold mb-1.5">
                  Bank Information
                </h6>
                <div className="rounded-lg border border-utilityGray200 px-3 pt-3.5 pb-4 flex flex-col gap-y-4">
                  <div className="flex-1 flex flex-wrap max-sm:flex-col gap-y-3 sm:-mx-1.5">
                    <Controller
                      name="bank_name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          label="Name"
                          inputName="bank_name"
                          placeholder="Enter Name"
                          parentClassName="sm:w-1/2 sm:px-1.5"
                          value={value}
                          onChangeFunc={onChange}
                          required={true}
                          errorText={
                            errors.bank_name ? errors.bank_name.message : null
                          }
                          labelClassName="block mb-1.5"
                        />
                      )}
                    />
                    <Controller
                      name="bank_contact_name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          label="Bank contact name"
                          inputName="bank_contact_name"
                          placeholder="Enter bank name"
                          parentClassName="sm:w-1/2 sm:px-1.5"
                          value={value}
                          onChangeFunc={onChange}
                          errorText=""
                          labelClassName="block mb-1.5"
                        />
                      )}
                    />
                    <Controller
                      name="bank_email"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          label="Email"
                          inputName="name"
                          placeholder="Enter email"
                          parentClassName="sm:w-1/2 sm:px-1.5"
                          value={value}
                          onChangeFunc={onChange}
                          required={true}
                          errorText={
                            errors.bank_email ? errors.bank_email.message : null
                          }
                          labelClassName="block mb-1.5"
                        />
                      )}
                    />
                    <Controller
                      name="bank_phone"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          label="Phone number"
                          inputName="bank_phone"
                          placeholder="Enter number"
                          parentClassName="md:w-1/2 sm:px-1.5"
                          value={value}
                          onChangeFunc={onChange}
                          errorText=""
                          labelClassName="block mb-1.5"
                        />
                      )}
                    />

                    <Controller
                      name="bank_address"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          label="Address"
                          inputName="address"
                          placeholder="Enter address"
                          parentClassName="md:w-1/2 sm:px-1.5"
                          value={value}
                          onChangeFunc={onChange}
                          errorText=""
                          labelClassName="block mb-1.5"
                        />
                      )}
                    />
                    <Controller
                      name="bank_city"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          label="City/ State/ Zip"
                          inputName="bank_city"
                          placeholder="Enter City/ State/ Zip"
                          parentClassName="md:w-1/2 sm:px-1.5"
                          value={value}
                          onChangeFunc={onChange}
                          errorText=""
                          labelClassName="block mb-1.5"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-semibold mb-1.5">
                  Credit References
                </h6>
                <CreditReferenceItem
                  creditReferenceArr={creditReferenceArr}
                  addRaw={addRaw}
                  removeRaw={removeRaw}
                  control={control}
                  errors={errors}
                />
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-semibold mb-1.5">
                  Trader References
                </h6>
                <TradeReferenceItem
                  tradeReferenceArr={tradeReferenceArr}
                  addRaw={addRaw}
                  removeRaw={removeRaw}
                  control={control}
                  errors={errors}
                />
              </div>
              <div>
                <h6 className="text-grayLight900 text-sm font-semibold mb-1.5">
                  Supporting Financial Statements
                </h6>
                <div
                  className="cursor-pointer flex-auto rounded-xl border border-gray100 py-3.5 px-5 text-center bg-white mb-4"
                  onClick={() => setIsShowStatementModal(true)}
                >
                  <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow ">
                    <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                  </div>
                  <div className="text-grayLight600 text-xs font-normal">
                    <p>
                      <span className="text-[#2422DD] font-semibold pr-1">
                        Click to upload{' '}
                      </span>
                      {/* or drag and drop */}
                    </p>
                    {/* <p className="text-[11px] leading-[18px]">PDF file</p> */}
                  </div>
                </div>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      {financeStatementArr.length ? (
                        <TableCmp
                          headCells={StatementHeadCells}
                          tableDataArr={financeStatementArr ?? []}
                          isTableDataLoading={isLoading}
                          tableRowCmpProps={{
                            // removeRaw: deleteFinanceStatement,
                            setAction: setAction,
                          }}
                          TableRowCmp={StatementOnboarding}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    {!financeStatementArr.length ? (
                      <NotFoundUI
                        title="No financial statement"
                        desc="Currently there is no financial statement has been uploaded at this time."
                        containerClassName="!h-auto"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-right sticky bottom-0 bg-white py-4 px-6 border-t border-utilityGray200">
              <ButtonCmp
                className="btn_primary min-w-[132px]"
                onClick={handleSubmit(onSubmit)}
                loading={isSubmitLoading}
                disabled={isSubmitLoading}
              >
                Next
              </ButtonCmp>
            </div>
          </div>
        </div>
      ) : (
        !isLoading && <VerifyOtp setIsVerifyOtp={setIsVerifyOtp} />
      )}

      {action.mode === 'delete' && (
        <DeleteModal
          moduleName="Fianance Statement"
          handleClose={handleModalClose}
        />
      )}

      {isShowStatementModal && (
        <StatementModal
          customerId={id}
          handleClose={() => {
            getDetail();
            setIsShowStatementModal(false);
          }}
        />
      )}
    </>
  );
};

export default BusinessApplicationForm;
