import { ArrowLeft } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import DeleteModal from 'src/components/DeleteModal';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import { orgTypeArr } from 'src/constants/common';
import { PATH } from 'src/constants/path';
// import { ROUTES } from 'src/constants/routes';
import { ROUTES } from 'src/constants/routes';
import {
  businessCreditApplication,
  businessCreditApplicationDetail,
  deleteStatementFile,
} from 'src/services/CustomerOnBoardingService';
import { getLabelByValue } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import LogoDark from '../../assets/img/logo-dark.png';

import AccountsOnbording from './AccountsOnbording';
import BankOnboarding from './BankOnboarding';
import BusinessOnboarding from './BusinessOnboarding';
import CompanyInfoOnboarding from './CompanyInfoOnboarding';
import CreditOnboarding from './CreditOnboarding';
import OfficersOnboarding from './OfficersOnboarding';
import OverviewOnboarding from './OverviewOnboarding';
import StatementOnboarding from './StatementOnboarding';
import TraderOnboarding from './TraderOnboarding';

const initReviewDetail = {
  accounts_payable: {},
  overview: {},
  business_address: {},
  company_info: {},
  officers: {},
  creditReferences: {},
  tradeReferences: {},
  bank_info: {},
};
const initAction = {
  mode: '',
  deleteId: 0,
};

const Submission = () => {
  const navigate = useNavigate();
  const formDataString = localStorage.getItem('formData');
  const [action, setAction] = useState(initAction);
  const formData: any = formDataString ? JSON.parse(formDataString) : null;
  const { id } = useParams<{ id: any }>();
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetail, setCustomerDetail] = useState<any>();
  const [reviewDetail, setReviewDetail] = useState(initReviewDetail);
  const [financeStatementArr, setFinanceStatementArr] = useState<any[]>([]);
  const OverviewHeadCells = useMemo(
    () => [
      {
        id: 'organization_name',
        name: 'Organization Name',
      },
      {
        id: 'date',
        name: 'Date',
      },
      {
        id: 'phone_number',
        name: 'Phone Number',
      },
      {
        id: 'fax',
        name: 'Fax',
      },
      {
        id: 'email',
        name: 'Email',
      },
    ],
    []
  );

  const BusinessHeadCells = useMemo(
    () => [
      {
        id: 'city',
        name: 'City',
      },
      {
        id: 'state',
        name: 'State',
      },
      {
        id: 'postal',
        name: 'Postal',
      },
      {
        id: 'country',
        name: 'Country',
      },
    ],
    []
  );

  const CompanyInfoHeadCells = useMemo(
    () => [
      {
        id: 'organization_type',
        name: 'Organization Type',
      },
      {
        id: 'business_started_date',
        name: 'Business Started Date',
      },
      {
        id: 'federal_tax_id_number',
        name: 'Federal Tax ID Number',
      },
      {
        id: 'type_of_business',
        name: 'Type of Business',
      },
      {
        id: 'dnb_number',
        name: 'D&B (DUNS) Number',
      },
    ],
    []
  );

  const AccountsHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
      },
      {
        id: 'phone_number',
        name: 'Phone Number',
      },
      {
        id: 'email',
        name: 'Email',
      },
    ],
    []
  );

  const OfficersHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
      },
      {
        id: 'title',
        name: 'Title',
      },
      {
        id: 'address',
        name: 'Address',
      },
      {
        id: 'city_state_zip',
        name: 'City/State/Zip',
      },
    ],
    []
  );
  const BankHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
      },
      {
        id: 'bank_contact_name',
        name: 'Bank Contact Name',
      },
      {
        id: 'email',
        name: 'Email',
      },
      {
        id: 'phone_number',
        name: 'Phone Number',
      },
      {
        id: 'address',
        name: 'Address',
      },
    ],
    []
  );
  const CreditHeadCells = useMemo(
    () => [
      {
        id: 'company_name',
        name: 'Company Name',
      },
      {
        id: 'phone_number',
        name: 'Phone Number',
      },
      {
        id: 'email',
        name: 'Email',
      },
      {
        id: 'credit_amount',
        name: 'Credit Amount',
      },
    ],
    []
  );
  const TraderHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
      },
      {
        id: 'city',
        name: 'City',
      },
      {
        id: 'phone_number',
        name: 'Phone Number',
      },
      {
        id: 'email',
        name: 'Email',
      },
    ],
    []
  );

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    businessCreditApplication(id, formData)
      .then(() => {
        WalToast.success('Customer added successfully');
        localStorage.removeItem('formData');
        localStorage.removeItem('verifyData');
        navigate(`${ROUTES.THANK_YOU}`);
      })
      .catch(() => WalToast.error('Customer not added'))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getDetail = () => {
    businessCreditApplicationDetail(id)
      .then((response: any) => {
        if (response) {
          setCustomerDetail(response?.data?.personal_detail);
          setFinanceStatementArr(response?.data?.financeStatement);
          setIsLoading(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        deleteStatementFile(action?.deleteId)
          .then(() => {
            getDetail();
            WalToast.success('Statement Deleted successfully');
          })
          .catch((e: any) =>
            WalToast.error(
              e?.response?.data?.message ??
                'something went wrong please try again'
            )
          )
          .finally(() => {
            setIsLoading(false);
          });
      }
      setAction(initAction);
    },
    [action]
  );

  // const deleteFinanceStatement = (stamentId: number) => {
  //   deleteStatementFile(stamentId)
  //     .then(() => {
  //       getDetail();
  //       WalToast.success('Statement Deleted successfully');
  //     })
  //     .catch((e: any) =>
  //       WalToast.error(
  //         e?.response?.data?.message ?? 'something went wrong please try again'
  //       )
  //     )
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    getDetail();

    if (formData) {
      const orgNameArr = formData?.organization_type?.map((val: any) =>
        getLabelByValue(val, orgTypeArr)
      );
      const orgTypeName = orgNameArr?.join(', ');
      const onBoardCustomer = {
        overview: {
          company_name: formData?.company_name,
          contact_number: formData?.contact_number,
          start_date: formData?.start_date,
          fax: formData?.fax,
          company_email: formData?.company_email,
        },
        business_address: {
          city: formData?.city,
          state: formData?.state,
          country: formData?.country,
          postal: formData?.postal,
        },
        company_info: {
          organization_type: orgTypeName,
          business_start_date: formData?.business_start_date,
          federal_tax_number: formData?.federal_tax_number,
          business_type: formData?.business_type,
          d_b_number: formData?.d_b_number,
        },
        accounts_payable: {
          account_payable_name: formData?.account_payable_name,
          account_payable_email: formData?.account_payable_email,
          account_payable_phone: formData?.account_payable_phone,
        },
        bank_info: {
          bank_name: formData?.bank_name,
          bank_address: formData?.bank_address,
          bank_contact_name: formData?.bank_contact_name,
          bank_email: formData?.bank_email,
          bank_phone: formData?.bank_phone,
        },
        officers: formData?.officers,
        creditReferences: formData?.creditReferences,
        tradeReferences: formData?.tradeReferences,
      };

      setReviewDetail({ ...onBoardCustomer });
    }
    // businessCreditApplicationDetail(id)
    //   .then((response: any) => {
    //     if (response) {
    //       setReviewDetail(response?.data);
    //       console.log('response :>> ', response);
    //     }
    //   })
    //   .catch((e: any) =>
    //     WalToast.error(
    //       e?.response?.data?.message ??
    //         'something went wrong. please try again later..!!'
    //     )
    //   )
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  }, []);

  const StatementHeadCells = useMemo(
    () => [
      {
        id: 'file_name',
        name: 'File name',
      },
      {
        id: 'year',
        name: 'Year',
      },
      {
        id: 'quarter',
        name: 'Quarter',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  useEffect(() => {
    console.log('reviewDetail :>> ', reviewDetail);
  }, [reviewDetail]);

  return (
    <div className="overflow-y-auto h-full p-5 bg-utilityGray50 flex flex-col">
      <div className="max-w-[1200px] rounded-xl bg-white shadow-lgc xl:my-[30px] my-5 mx-auto flex flex-col flex-1 overflow-hidden w-full">
        <div className="border-b border-borderPrimary px-5 py-3 flex flex-wrap gap-3 justify-between">
          <div className="max-w-[517px] ">
            <h6 className="text-grayLight900 text-base font-semibold">
              Submission
            </h6>
            <p className="text-grayLight600 text-xs font-normal ">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry,
            </p>
          </div>
          <div className="flex items-center">
            <div className="max-w-[113px] ">
              <img src={LogoDark} alt="logo-light" className="w-full" />
            </div>
            {customerDetail?.image && (
              <div className="max-w-[128px] min-w-[50px] pl-3 ml-3 border-l border-borderPrimary">
                <img
                  src={`${customerDetail.imageUrl}${customerDetail.image}`}
                  className="w-full h-[50px] object-cover"
                  alt="Customer"
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4 flex-1 overflow-auto custom-scrollbar-v2 p-6">
          <div>
            <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
              Overview
            </h6>
            <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="w-full ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={OverviewHeadCells}
                    tableDataArr={[reviewDetail?.overview]}
                    isTableDataLoading={isLoading}
                    TableRowCmp={OverviewOnboarding}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
              Business Address
            </h6>
            <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="w-full ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={BusinessHeadCells}
                    tableDataArr={[reviewDetail?.business_address]}
                    TableRowCmp={BusinessOnboarding}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
              Company Information
            </h6>
            <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="w-full ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={CompanyInfoHeadCells}
                    tableDataArr={[reviewDetail?.company_info]}
                    TableRowCmp={CompanyInfoOnboarding}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
              Accounts Payable Contact
            </h6>
            <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="w-full ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={AccountsHeadCells}
                    tableDataArr={[reviewDetail?.accounts_payable]}
                    TableRowCmp={AccountsOnbording}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
              Officers & Owners
            </h6>
            <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="w-full ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={OfficersHeadCells}
                    tableDataArr={reviewDetail?.officers}
                    TableRowCmp={OfficersOnboarding}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
              Bank Information
            </h6>
            <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="w-full ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={BankHeadCells}
                    tableDataArr={[reviewDetail?.bank_info]}
                    TableRowCmp={BankOnboarding}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
              Credit References
            </h6>
            <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="w-full ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={CreditHeadCells}
                    tableDataArr={reviewDetail.creditReferences}
                    TableRowCmp={CreditOnboarding}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
              Trader References
            </h6>
            <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="w-full ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={TraderHeadCells}
                    tableDataArr={reviewDetail.tradeReferences}
                    TableRowCmp={TraderOnboarding}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
              Supporting Financial Statements
            </h6>
            <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="w-full ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={StatementHeadCells}
                    tableDataArr={financeStatementArr}
                    isTableDataLoading={isLoading}
                    tableRowCmpProps={{
                      // removeRaw: deleteFinanceStatement,
                      setAction: setAction,
                    }}
                    TableRowCmp={StatementOnboarding}
                  />
                </div>
                {!financeStatementArr.length ? (
                  <NotFoundUI
                    title="No financial statement"
                    desc="Currently there is no financial statement has been uploaded at this time."
                    containerClassName="!h-auto"
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        {action.mode === 'delete' && (
          <DeleteModal
            moduleName="Fianance Statement"
            handleClose={handleModalClose}
          />
        )}
        <div className="text-right sticky bottom-0 bg-white py-4 px-6 border-t border-utilityGray200 flex justify-between">
          <Link
            to={`${PATH.CUSTOMER_ONBOARDING}/${id}`}
            className="btn_secondary_black btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm min-w-[132px] "
          >
            <ArrowLeft className="w-4 h-4" /> Back
          </Link>
          <ButtonCmp className="btn_primary min-w-[132px]" onClick={onSubmit}>
            Submit
          </ButtonCmp>
        </div>
      </div>
    </div>
  );
};
export default Submission;
