import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  // Initialize navigate function
  const navigate = useNavigate();

  return (
    <section className="h-full flex flex-col bg-gray-100 p-7 justify-center items-center">
      <div className="flex items-center justify-center p-7 max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl flex-col m-auto text-center">
        <h1 className="text-primary font-extrabold text-5xl md:text-8xl mb-9">
          404
        </h1>
        <p className="text-gray-900 uppercase text-xl md:text-xl mb-4">
          Page Not Found
        </p>
        <p className="text-gray-600 text-sm md:text-sm font-normal leading-normal mb-6">
          {`We're sorry, but the page you were looking for doesn't exist. It may have been moved, removed, or the URL might be incorrect.`}
        </p>
        <button
          className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          onClick={() => navigate('/')}
        >
          Return to Homepage
        </button>
      </div>
    </section>
  );
};

export default PageNotFound;
