import React from 'react';

import { IRadioProps } from './Radio.interface';

const Radio = ({
  inputName,
  tabIndex,
  disabled,
  checked,
  onChangeFunc,
  onFocus,
  required,
  readOnly,
  inputClassName,
  ...props
}: IRadioProps) => (
  <label className="flex-none custom-radio w-4 h-4 cursor-pointer flex relative">
    <input
      {...props}
      className={`opacity-0 absolute cursor-pointer ${inputClassName}`}
      type="radio"
      name={inputName}
      onChange={onChangeFunc}
      onFocus={onFocus}
      tabIndex={tabIndex}
      checked={checked}
      disabled={disabled}
      required={required}
      readOnly={readOnly}
    />
    <span className="checkmark h-full w-full rounded-full absolute left-0 top-0 flex items-center justify-center after:content-[''] after:w-1.5 after:h-1.5 after:rounded-full after:bg-white after:transition-all transition-all"></span>
  </label>
);

export default Radio;
