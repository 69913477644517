import { yupResolver } from '@hookform/resolvers/yup';
import { FilePlus02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { modifyDisputeAmount } from 'src/services/DisputeService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import { handleInputChange } from '../ClaimsDisputes.const';

const modifyDisputeAmountSchema = yup.object().shape({
  disputeAmount: yup
    .number()
    .required('Dispute Amount is required.')
    .typeError('Dispute amount must be a valid number'),
});

interface IProps {
  handleClose: any;
  dispute: any;
  getDisputeDetails: any;
}

const ModifyDisputeAmount = ({
  handleClose,
  dispute,
  getDisputeDetails,
}: IProps) => {
  const { reloadCounts } = useContext(EntityCountsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(modifyDisputeAmountSchema),
    defaultValues: {
      disputeAmount: dispute?.finalDisputeAmount
        ? dispute?.finalDisputeAmount
        : dispute?.disputeAmount,
    },
  });

  const onSubmit = (formData: any) => {
    setIsLoading(true);

    // if value unchanged then not doing api call
    if (formData?.disputeAmount === dispute?.disputeAmount) {
      handleClose();

      return;
    }

    const appendFormData: any = new FormData();
    appendFormData.append('disputeID', dispute?.disputeID);
    appendFormData.append('disputeAmount', formData?.disputeAmount);

    modifyDisputeAmount(appendFormData)
      .then(() => {
        WalToast.success('Dispute explanation updated successfully');
        getDisputeDetails();
        reloadCounts();
        handleClose();
      })
      .catch((e: any) => {
        WalToast.error(
          e?.response?.data?.message ?? 'Issue modifying dispute amount'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const headerIcon = <FilePlus02 className="w-7 h-7" />;

  return (
    <CommonModal
      title={`Modify Dispute Amount`}
      titleDesc={`View and manage your dispute details`}
      handleClose={handleClose}
      size={'max-w-[688px]'}
      headerIcon={headerIcon}
      headerIconClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-left-[69px] before:opacity-50"
      primaryBtnText="Modify"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      primaryBtnDisabled={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isLoading}
    >
      <div className="p-5">
        <Controller
          name="disputeAmount"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputType="number"
              inputName="disputeAmount"
              label="Dispute Amount"
              parentClassName="mb-4"
              placeholder="Enter Dispute Explanation"
              value={`${value}`}
              onChangeFunc={(e) => handleInputChange(e, 'change', onChange)}
              onBlur={(e) => handleInputChange(e, 'blur', onChange)}
              labelClassName="block mb-1.5"
              errorText={errors?.disputeAmount?.message ?? ''}
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default ModifyDisputeAmount;
