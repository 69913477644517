import React from 'react';

import defaultImage from '../../assets/img/default-image.jpg';

const CarrierLoadingCard = ({ index, viewType }: any) => (
  // console.log('viewType :>> ', viewType);

  <>
    {viewType === 'gallery' ? (
      <ul className="card-wrap xl:w-1/3 mdm:w-2/4 w-full" key={index}>
        <li className="card-box shadow-xs flex flex-col h-full">
          <div className="xlm:p-5 p-4 flex-col gap-4 flex flex-1">
            <div className="gap-3 flex justify-between items-start">
              <div className="flex gap-3 ">
                <div className="h-12 w-12 rounded-lg custom-loading">
                  <img
                    className="object-cover h-full w-full"
                    src={defaultImage}
                    alt="Default"
                  />
                </div>
                <div className="flex flex-col gap-1 ">
                  <div className="text-grayLight900 text-base font-semibold inline custom-loading">
                    Polaris Transportation
                  </div>
                </div>
              </div>
              <div className="cursor-pointer flex text-[11px] items-center text-primary font-semibold flex-none custom-loading">
                View Details
              </div>
            </div>
            <div className="flex sm:flex-row sm:gap-0 gap-2.5 flex-col flex-wrap">
              <div className="flex gap-1 items-center pe-5 custom-loading mr-2">
                12345689797898
              </div>
              <div className="flex gap-1 items-center">
                <div className="text-gray500 text-xs font-medium break-all custom-loading">
                  tesinguser@gmail.com
                </div>
              </div>
            </div>
          </div>
          <div className="justify-between items-center flex flex-wrap gap-2 xlm:px-5 px-4 py-2.5 border-t border-utilityGray200">
            <div className="text-textSecondary text-[10px] leading-[18px]px-1.5 py-0.5 custom-loading">
              test
            </div>
            <div className="flex gap-1.5">
              <span className="custom-loading">Connected</span>
              <span className="custom-loading">Connect</span>
            </div>
          </div>
        </li>
      </ul>
    ) : (
      <div className="px-5 py-4 border-b border-utilityGray200 mdm:gap-3.5 gap-6 flex mdm:flex-row flex-col mdm:justify-between mdm:items-center">
        <div className="flex gap-3 flex-1">
          <div className="h-12 w-12 custom-loading">
            <img
              className="object-cover h-full w-full"
              src={defaultImage}
              alt="Default"
              title="Default"
            />
          </div>
          <div className="flex flex-col gap-1.5 flex-1">
            <div className=" gap-1.5 flex mdm:items-center items-start mdm:justify-normal justify-between">
              <h6 className="text-gray-900 text-lg font-semibold custom-loading">
                Polaris Transportation
              </h6>
              <div className="text-textSecondary  text-[10px] leading-[18px] px-1.5 py-0. custom-loading">
                CA-CA
              </div>
            </div>
            <div className="flex sm:flex-row xls:gap-0 gap-y-1.5 flex-col flex-wrap custom-loading">
              Unavailable
            </div>
          </div>
        </div>
        <div className="flex flex-wrap mdm:justify-normal justify-between gap-2.5">
          <a className="text-primary text-[11px] flex items-center custom-loading">
            View Details
          </a>
          <div className="flex gap-2.5">
            <span className="custom-loading">AA</span>
            <span className="lg:!py-[9px] !py-2 custom-loading">Connected</span>
            <span className="btn_primary custom-loading">Connect</span>
          </div>
        </div>
      </div>
    )}
  </>
);
export default CarrierLoadingCard;
