import { FilePlus02, Plus } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import {
  creditReferenceNote,
  getCreditReferenceNote,
} from 'src/services/CustomerOnBoardingService';
import WalToast from 'src/utils/WalToast';

interface Note {
  note: string;
}

const AddNotes = ({ creditReferenceId, handleClose }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState<Note[]>([]);
  const [noteList, setNoteList] = useState<Note[]>([]);
  const [note, setNote] = useState<any>('');
  const [errors, setErrors] = useState<any>('');

  const addNotes = () => {
    if (note !== '') {
      setNotes((old: any) => [...old, { note: note }]);
      setNoteList((old: any) => [...old, { note: note }]);
      setErrors('');
    } else {
      setErrors('note is required');
    }
    setNote('');
  };

  const getNotes = () => {
    getCreditReferenceNote(creditReferenceId)
      .then((response) => {
        setNoteList(response.data);
      })
      .catch(() =>
        WalToast.error('something went wrong please try again later..!!')
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNotes();
  }, []);

  const handleSubmit = () => {
    creditReferenceNote({ notes: notes, creditReferenceId: creditReferenceId })
      .then((response: any) => {
        // getFileList();
        setNotes([]);
        handleClose();
        WalToast.success(response?.message);
      })
      .catch(() =>
        WalToast.error('something went wrong please try again later..!!')
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CommonModal
      title={'Add Notes'}
      titleDesc={'Notes for your reference and use.'}
      handleClose={() => handleClose(false)}
      headerIcon={<FilePlus02 />}
      size={'max-w-[688px]'}
      modalClassName=""
      primaryBtnOnClick={() => handleSubmit()}
      primaryBtnDisabled={isLoading}
      primaryBtnLoading={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      primaryBtnText="Add notes"
      isOverflow={false}
    >
      <div className="p-5">
        <div className="flex gap-x-2 rounded-md border border-utilityGray200 p-3">
          <InputText
            inputName="searchTeam"
            placeholder="Add notes"
            className="placeholder:text-secondaryTxtColor"
            parentClassName="w-full"
            value={note}
            onChangeFunc={(e: any) => {
              setNote(e?.target?.value);
            }}
            errorText={errors ? 'note is required' : ''}
            isClearable={true}
          />
          <ButtonCmp
            className="btn_primary lg:px-[9px] px-2"
            onClick={() => addNotes()}
          >
            <Plus className="w-4 h-4" />
          </ButtonCmp>
        </div>
        <div className="mt-4 rounded-lg border border-utilityGray200 p-4 empty:hidden">
          {noteList.map((noteVal: any, index: number) => (
            <div
              key={index}
              className="text-grayLight600 text-sm font-normal last:pb-0 pb-4 last:mb-0 mb-4 border-b border-utilityGray200 last:border-0"
            >
              <span>{noteVal.note}</span>
            </div>
          ))}
        </div>
      </div>
    </CommonModal>
  );
};
export default AddNotes;
