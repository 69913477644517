import { ApexOptions } from 'apexcharts';

export const predictivePriceOptions: ApexOptions = {
  chart: {
    height: 350,
    type: 'line',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5,
    },
  },
  colors: ['#A4BCFD', '#3538CD', '#6172F3'], // Adjust the colors if needed to match your chart
  yaxis: {
    labels: {
      offsetX: 0,
      formatter: function (val: any) {
        return val === 0 ? val : `$${val}`;
      },
    },
    min: 0,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    title: {
      text: 'Actvie users',
      // style: {
      //   color: undefined,
      //   fontSize: '12px',
      //   fontFamily: 'Helvetica, Arial, sans-serif',
      //   fontWeight: 600,
      //   cssClass: 'apexcharts-yaxis-title',
      // },
    },
  },
  xaxis: {
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    tickPlacement: 'between',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    title: {
      text: 'Months',
      // style: {
      //   color: undefined,
      //   fontSize: '12px',
      //   fontFamily: 'Helvetica, Arial, sans-serif',
      //   fontWeight: 600,
      //   cssClass: 'apexcharts-xaxis-title',
      // },
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    offsetX: -10,
  },
};
