/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';

interface IProps {
  height: number;
  width: number;
  depth: number;
}

const ThreeJsScene = ({ height = 0, width = 0, depth = 0 }: IProps) => {
  const canvasRef = useRef<any>();
  const [isSpinning, setIsSpinning] = useState(false);
  const controls = useRef<any>(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(100, 454 / 454, 0.8, 1000);
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
      antialias: true,
      logarithmicDepthBuffer: true,
    });

    renderer.setSize(454, 454);
    renderer.setClearColor(0xffffff);
    renderer.setPixelRatio(window.devicePixelRatio);
    camera.position.z = 8;

    const geometry = new THREE.BoxGeometry(width, height, depth);
    const material = new THREE.MeshBasicMaterial({
      // map: texture,
      color: 0x444ce7,
      opacity: 0.65,
      transparent: true,
    });
    const cube = new THREE.Mesh(geometry, material);
    scene.add(cube);

    const edges = new THREE.EdgesGeometry(geometry);

    const lineMaterial = new THREE.LineBasicMaterial({
      color: 0x000000,
      linewidth: 1,
    });
    const wireframe = new THREE.LineSegments(edges, lineMaterial);
    wireframe.computeLineDistances();
    cube.add(wireframe);

    controls.current = new OrbitControls(camera, renderer.domElement);
    controls.current.enableDamping = true;

    const composer = new EffectComposer(renderer);
    const renderPass = new RenderPass(scene, camera);
    composer.addPass(renderPass);

    const fxaaPass = new ShaderPass(FXAAShader);
    fxaaPass.material.precision = 'highp';
    // eslint-disable-next-line @typescript-eslint/dot-notation
    fxaaPass.uniforms.resolution.value.set(
      1 / window.innerWidth,
      1 / window.innerHeight
    );
    composer.addPass(fxaaPass);

    const animate = () => {
      requestAnimationFrame(animate);

      if (isSpinning) {
        cube.rotation.x += 0.01;
        cube.rotation.y += 0.01;
      }
      controls.current.update();
      composer.render(); // Use composer instead of renderer
    };

    animate();

    return () => {
      geometry.dispose();
      material.dispose();
      renderer.dispose();
    };
  }, [isSpinning, height, width, depth]);

  const toggleSpin = () => {
    setIsSpinning(!isSpinning);
  };

  return (
    <div className="md:h-full w-full flex flex-col gap-2 justify-center items-center">
      <canvas ref={canvasRef} className="max-w-full !w-auto !h-auto" />
      <button className="hidden" onClick={toggleSpin}>
        {isSpinning ? 'Stop Spin' : 'Start Spin'}
      </button>
    </div>
  );
};

export default ThreeJsScene;
