import React, { useEffect, useMemo, useState } from 'react';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import { getLoadLinkPostLeads } from 'src/services/LoadLinkService';

import CreateLoadPostingFrom from './CreateLoadPostingFrom';
import { IProps, ITransportLead, LoadBoardCellId } from './LoadBoard.interface';
import LoadBoardRow from './LoadBoardRow';
import LoadBoardRowLoader from './LoadBoardRowLoader';

const initParams = {
  sortType: 'desc',
  sortField: LoadBoardCellId.Availability,
};

const PostToLoadBoard = ({
  loadLinkPostingDetail,
  addressDetails,
  syncLiveData,
  setSyncLiveData,
  setLoadLinkPostingDetail,
  isUpdateLoadPost,
  setIsUpdateLoadPost,
  setIsRefresh,
  isRefresh,
}: IProps) => {
  const [leadsData, setLeadsData] = useState<ITransportLead[]>([]);
  const [isSyncingLiveData, setIsSyncingLiveData] = useState<boolean>(true);
  const [params, setParams] = useState<any>(initParams);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getLead = () => {
    if (loadLinkPostingDetail?.id) {
      getLoadLinkPostLeads(
        { ...params, isRefresh: isSyncingLiveData },
        loadLinkPostingDetail.id
      )
        .then((res: any) => {
          setLeadsData(res.data);

          if (res?.loadLinkPostingDetail) {
            setLoadLinkPostingDetail(res?.loadLinkPostingDetail);
          }
        })
        .finally(() => {
          setIsSyncingLiveData(false);
          setIsLoading(false);
          setSyncLiveData(false);
        })
        .catch(() => {});
    } else {
      setIsSyncingLiveData(false);
      setIsLoading(false);
      setSyncLiveData(false);
    }
  };

  useEffect(() => {
    if (syncLiveData) {
      setLeadsData([]);
      setIsLoading(true);
      setIsSyncingLiveData(syncLiveData);
      setIsUpdateLoadPost(false);
    }
  }, [syncLiveData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsSyncingLiveData(true);
    }, 180000); // 3 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isSyncingLiveData) {
      setLeadsData([]);
      setIsLoading(true);
      getLead();
    }
  }, [params, isRefresh]);

  useEffect(() => {
    if (isSyncingLiveData) {
      getLead();
    }
  }, [isSyncingLiveData]);

  const LoadBoardCells = useMemo(
    () => [
      {
        id: LoadBoardCellId.Company,
        name: 'Company',
        sortable: true,
      },
      {
        id: LoadBoardCellId.PhoneNumber,
        name: 'Phone Number',
        sortable: true,
      },
      {
        id: LoadBoardCellId.DistanceFromOrigin,
        name: 'Distance from Origin',
        sortable: true,
      },
      {
        id: LoadBoardCellId.EquipmentSize,
        name: 'Equipment size',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Availability,
        name: 'Availability',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Posted,
        name: 'Posted',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Comment,
        name: 'Comments',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  return (
    <>
      {isUpdateLoadPost || !loadLinkPostingDetail ? (
        <CreateLoadPostingFrom
          addressDetails={addressDetails}
          setIsRefresh={setIsRefresh}
          loadLinkPostingDetail={loadLinkPostingDetail}
          isUpdateLoadPost={isUpdateLoadPost}
          setIsUpdateLoadPost={setIsUpdateLoadPost}
        />
      ) : (
        <div className="h-full lg:min-h-[122px] w-full border-t border-gray100">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <TableCmp
              headCells={LoadBoardCells}
              tableDataArr={leadsData}
              TableLoaderRowCmp={LoadBoardRowLoader}
              TableRowCmp={LoadBoardRow}
              params={params}
              setParams={setParams}
              isTableDataLoading={isLoading}
            />
          </div>

          {!leadsData?.length && !isLoading && (
            <NotFoundUI
              title="No Leads Found"
              desc="There are no leads for this load."
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          )}
        </div>
      )}
    </>
  );
};

export default PostToLoadBoard;
