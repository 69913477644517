import { createSlice } from '@reduxjs/toolkit';

export interface SocketConnectionState {
  isSocketConnected?: boolean;
  socketIO: any;
}

const initialState: SocketConnectionState = {
  isSocketConnected: false,
  socketIO: '',
};

export const SocketConnection = createSlice({
  name: 'socketConnected',
  initialState,
  reducers: {
    setIsSocketConnected: (state, action) => {
      state.isSocketConnected = action.payload;
    },
    setSocketIo: (state, action) => {
      state.socketIO = action.payload;
    },
  },
});
export const { setIsSocketConnected, setSocketIo } = SocketConnection.actions;
export default SocketConnection.reducer;
