/* eslint-disable max-lines-per-function */
import {
  AlertCircle,
  Cube03,
  Package,
} from '@untitled-ui/icons-react/build/cjs';
import { groupBy } from 'lodash';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import WalToast from 'src/utils/WalToast';

import ButtonCmp from '../../../../../components/ButtonCmp';
import CheckBox from '../../../../../components/CheckBox';
import ConfirmModal from '../../../../../components/ConfirmModal';
import InputText from '../../../../../components/InputText/InputText';
import PageSectionLayout from '../../../../../components/PageSectionLayout';
import Header from '../../../../../components/PageSectionLayout/Header/Header';
import Radio from '../../../../../components/Radio/Radio';
import SelectBox from '../../../../../components/SelectBox/SelectBox';
import StepBar from '../../../../../components/StepBar/StepBar';
import TooltipCmp from '../../../../../components/TooltipCmp';
import {
  FOOT,
  LBS,
  KGS,
  HANDLING_UNIT_NUMBER,
  MEASUREMENT,
  STATUS,
  CURRENCY,
} from '../../../../../constants/common';
import { PATH } from '../../../../../constants/path';
import { ROUTES } from '../../../../../constants/routes';
import { BasicContext } from '../../../../../context/BasicContext';
import {
  listHandlingUnit,
  listService,
} from '../../../../../services/CommonService';
import {
  createUpdateDimension,
  createUpdateService,
  createUpdateSummary,
  getQuote,
  getFreightClass,
} from '../../../../../services/QuoteService';
import { getSelectBoxOptions } from '../../../../../utils/CommonFunctions';
import { STEPS } from '../SmartQuote.interface';

import Items from './Items';

interface IFormData {
  id: number | null;
  handlingUnit: string | null;
  handlingUnitNo: number;
  weight: number;
  itemLength: number;
  width: number;
  height: number;
  totalWeight: number;
  freightClass: number;
  isCommodity: boolean;
  isStack: boolean;
  weightMeasurement: string;
  commodityId: string | null;
  commodityName: string | null;
  sub: string | null;
  description: string | null;
}

const initFormData: IFormData = {
  id: null,
  handlingUnit: '',
  // handlingUnitNo: 1,
  handlingUnitNo: 0,
  weight: 0,
  itemLength: 0,
  width: 0,
  height: 0,
  totalWeight: 0,
  freightClass: 0,
  isCommodity: false,
  isStack: true,
  weightMeasurement: MEASUREMENT.WEIGHT1.value,
  commodityId: null,
  commodityName: null,
  sub: null,
  description: null,
};

const initValidForm = {
  handlingUnit: {
    required: true,
    valid: true,
  },
  handlingUnitNo: {
    required: true,
    valid: true,
  },
  weight: {
    required: true,
    valid: true,
  },
  itemLength: {
    required: true,
    valid: true,
  },
  width: {
    required: true,
    valid: true,
  },
  height: {
    required: true,
    valid: true,
  },
  totalWeight: {
    required: true,
    valid: true,
  },
  isCommodity: {
    required: true,
    valid: true,
  },
  isStack: {
    required: true,
    valid: true,
  },
  commodityId: {
    required: true,
    valid: true,
  },
  commodityName: {
    required: true,
    valid: true,
  },
  sub: {
    required: true,
    valid: true,
  },
  description: {
    required: true,
    valid: true,
  },
};

const initServiceValidForm = {
  limitedAccessOther: true,
  inbondName: true,
  inbondAddress: true,
  inbondContactValue: true,
  unitedNationsNumber: true,
  goodsClass: true,
  description: true,
  emergencyContactName: true,
  emergencyContactNumber: true,
  declaredValue: true,
};

const initTotal = {
  totalWeight: 0,
  totalUnit: 0,
};

const initAction = {
  tailgate: false,
  unusual: false,
};

const initValidateMeasurement = {
  weight: LBS.WEIGHT,
  length: LBS.LENGTH,
  width: LBS.WIDTH,
  height: LBS.HEIGHT,
};

const limitedAccessList = [
  { name: 'Construction Site', value: 'construction-site' },
  { name: 'Individual (Mini) Storage Unit', value: 'mini-storage-unit' },
  { name: 'Fair/Amusement Park', value: 'fair' },
  { name: 'Place of Worship', value: 'place-of-worship' },
  { name: 'Farm/Country Club/Estate', value: 'farm' },
  {
    name: 'Secured Locations Delivery - prisons, military bases, airport',
    value: 'secured-location',
  },
  { name: 'School/University', value: 'school' },
  { name: 'Plaza/Mall with only parking lot/Street access', value: 'mall' },
  { name: 'Other', value: 'other' },
];

const inbondTypeList = [
  {
    name: '(IT) Immediate Transportation Bond',
    value: 'immediate-exportation',
  },
  {
    name: '(T&E) Transportation & Export Bond',
    value: 'transportation-and-exportation',
  },
];

const inbondContactMethodList = [
  { name: 'Email', value: 'email-address' },
  { name: 'Phone', value: 'phone-number' },
  { name: 'Fax Number', value: 'fax-number' },
];

const dangerTypeList = [
  { name: 'Limited Quantity', value: 'limited-quantity' },
  { name: '500 kg Exemption', value: 'exemption-500-kg' },
  { name: 'Fully Regulated', value: 'fully-regulated' },
];

const packagingGroupOptions = [
  { label: 'PG I', value: 'PGI' },
  { label: 'PG II', value: 'PGII' },
  { label: 'PG III', value: 'PGIII' },
];

const requiredFields = [
  'handlingUnit',
  'handlingUnitNo',
  'weight',
  'itemLength',
  'width',
  'height',
  'commodityId',
  'sub',
];

const Dimensions = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IFormData[]>([initFormData]);
  const [validForm, setValidForm] = useState([initValidForm]);
  const [validServicesForm, setServicesValidForm] =
    useState(initServiceValidForm);
  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceTypes, setServiceTypes] = useState<any>();
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const [mode, setMode] = useState<string>('ADD');

  const [isTailgate, setIsTailgate] = useState<any>([]);
  const [isTotalWeightOver, setIsTotalWeightOver] = useState<boolean>(false);
  const [isItemLengthOver, setIsItemLengthOver] = useState<boolean>(false);

  const [isLimitedMore, setIsLimitedMore] = useState<boolean>(false);
  const [limitedAccessType, setLimitedAccessType] = useState<string>(
    limitedAccessList[0].value
  );
  const [limitedAccessOther, setLimitedAccessOther] = useState<string>('');

  const [isInbondMore, setIsInbondMore] = useState<boolean>(false);
  const [inbondType, setInbondType] = useState<string>(inbondTypeList[0].value);
  const [inbondName, setInbondName] = useState<string>('');
  const [inbondAddress, setInbondAddress] = useState<string>('');
  const [inbondContactMethod, setInbondContactMethod] = useState<string>(
    inbondContactMethodList[0].value
  );
  const [inbondContactValue, setInbondContactValue] = useState<string>('');

  const [isDeclaredValueMore, setIsDeclaredValueMore] =
    useState<boolean>(false);
  const [declaredValue, setDeclaredValue] = useState<any>(0);

  const [isDangerousMore, setIsDangerousMore] = useState<boolean>(false);
  const [dangerType, setDangerType] = useState<string>(dangerTypeList[0].value);
  const [packagingGroup, setPackagingGroup] = useState<string>(
    packagingGroupOptions[0].value
  );
  const [unitedNationsNumber, setUnitedNationsNumber] = useState<string>('');
  const [goodsClass, setGoodsClass] = useState<string>('');
  const [emergencyContactName, setEmergencyContactName] = useState<string>('');
  const [emergencyContactNumber, setEmergencyContactNumber] =
    useState<string>('');
  const [emergencyContactExt, setEmergencyContactExt] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [total, setTotal] = useState(initTotal);
  const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
  const [isHandlingUnitLoading, setIsHandlingUnitLoading] = useState(true);
  const [weightMeasurement, setWeightMeasurement] = useState(
    MEASUREMENT.WEIGHT1.value
  );
  const [lengthMeasurement, setLengthMeasurement] = useState(
    MEASUREMENT.LENGTH1.value
  );
  const [validateMeasurement, setValidateMeasurement] = useState(
    initValidateMeasurement
  );
  const [action, setAction] = useState(initAction);
  const { currency } = useContext(BasicContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    listHandlingUnit()
      .then((result: any) => {
        if (result.data && result.data.length) {
          const handlingUnitSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setHandlingUnitOptions(handlingUnitSelectBoxOptions);
        }
      })
      .finally(() => setIsHandlingUnitLoading(false))
      .catch(console.error);
  }, []);

  const getListService = () => {
    listService({})
      .then((response: any) => {
        setServiceList(response.data);

        if (response.data.length) {
          let serviceByGroup: any = groupBy(response.data, (val) => val.type);
          setServiceTypes(serviceByGroup);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(console.log);
  };

  const getQuoteInfo = (quoteId: any) => {
    getQuote(quoteId)
      .then((response: any) => {
        if (response && response.data) {
          const { dimensions, addressDetails, additionalService } =
            response.data;

          if (addressDetails.status !== STATUS.PENDING) {
            // navigate(ROUTES.HOME);
          }

          if (additionalService) {
            let savedServices = additionalService.map((val: any) => {
              if (
                val.slug === 'tailgate_pickup' ||
                val.slug === 'tailgate_delivery'
              ) {
                setIsTailgate((old: any) => [...old, val.serviceId]);
              }

              if (val.slug === 'limited_access_delivery') {
                setIsLimitedMore(true);
                setLimitedAccessType(val.limitedAccessType);
                setLimitedAccessOther(val.limitedAccessOther);
              }

              if (val.slug === 'in-bond') {
                setIsInbondMore(true);
                setInbondType(val.inbondType);
                setInbondName(val.inbondName);
                setInbondAddress(val.inbondAddress);
                setInbondContactMethod(val.inbondContactMethod);
                setInbondContactValue(val.inbondContactValue);
              }

              if (val.slug === 'excess_valuation') {
                setIsDeclaredValueMore(true);

                if (currency === CURRENCY.CAD) {
                  setDeclaredValue(val.declaredValueCAD);
                } else if (currency === CURRENCY.USD) {
                  setDeclaredValue(val.declaredValueUSD);
                }
              }

              if (val.slug === 'dangerous_goods') {
                setIsDangerousMore(true);
                setDangerType(val.dangerGoodsType);
                setPackagingGroup(val.packagingGroup);
                setUnitedNationsNumber(val.unitedNationsNumber);
                setGoodsClass(val.goodsClass);
                setEmergencyContactName(val.emergencyContactName);
                setEmergencyContactNumber(val.emergencyContactNumber);
                setEmergencyContactExt(val.emergencyContactExt);
                setDescription(val.description);
              }

              return val.serviceId;
            });
            setSelectedServices(savedServices);
            setMode('EDIT');
          }

          if (dimensions) {
            let totalWeight = 0;
            setWeightMeasurement(dimensions[0].weightMeasurement);
            dimensions.forEach((dimension: any) => {
              totalWeight += dimension.totalWeight;
              const maxLengthOver =
                dimensions[0].weightMeasurement === MEASUREMENT.WEIGHT1
                  ? LBS.ITEM_LENGTH_OVER
                  : KGS.ITEM_LENGTH_OVER;

              if (dimension.itemLength >= maxLengthOver) {
                // 5 feet over
                setIsItemLengthOver(true);
              }
            });
            const isTotalWeightOverBool =
              dimensions[0].weightMeasurement === MEASUREMENT.WEIGHT1
                ? totalWeight >= LBS.MAX_WEIGHT
                : totalWeight >= KGS.MAX_WEIGHT;
            setIsTotalWeightOver(isTotalWeightOverBool);
          }
        } else {
          navigate(ROUTES.HOME);
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (!isHandlingUnitLoading) {
      if (id) {
        getListService();
        getQuoteInfo(id);
        getQuote(id)
          .then(async (response: any) => {
            if (response && response.data) {
              const { dimensions, addressDetails } = response.data;

              if (addressDetails.status !== STATUS.PENDING) {
                // navigate(ROUTES.HOME);
              }

              if (dimensions) {
                let weightMeasurementValue = MEASUREMENT.WEIGHT1.value;
                let lengthMeasurementValue = MEASUREMENT.LENGTH1.value;

                const data: any = [];

                for (const dimension of dimensions) {
                  setValidForm((old: any) => [...old, initValidForm]);
                  weightMeasurementValue = dimension.weightMeasurement;
                  lengthMeasurementValue = dimension.lengthMeasurement;
                  delete dimension.createdAt;
                  delete dimension.updatedAt;

                  let freightClass = dimension.freightClass;

                  if (!freightClass) {
                    freightClass = await getFreightClass({
                      itemLength: dimension.itemLength,
                      width: dimension.width,
                      height: dimension.height,
                      weight: dimension.weight,
                      lengthUnit: dimension.lengthMeasurement,
                      weightUnit: dimension.weightMeasurement,
                    })
                      .then((dataRes: any) => dataRes?.data?.freightClass || 0)
                      .catch(() => 0);
                  }

                  dimension.freightClass = freightClass;
                  data.push(dimension);
                }

                setFormData(data);
                setWeightMeasurement(weightMeasurementValue);
                setLengthMeasurement(lengthMeasurementValue);
              }
            } else {
              navigate(ROUTES.HOME);
            }
          })
          .catch(console.error);
      } else {
        navigate(ROUTES.HOME);
      }
    }
  }, [isHandlingUnitLoading]);

  useEffect(() => {
    let totalUnit = 0;
    let totalWeight: number | string = 0;
    let weightMeasurementValue = '';
    formData.forEach((form: any) => {
      totalUnit += form.handlingUnitNo || 0;
      totalWeight += form.totalWeight;
      weightMeasurementValue = form.weightMeasurement;
    });
    totalWeight = totalWeight.toFixed();
    setTotal((old: any) => ({ ...old, totalUnit, totalWeight }));

    const isTotalWeightOverBool =
      weightMeasurementValue === MEASUREMENT.WEIGHT1.value
        ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
        : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;
    setIsTotalWeightOver(isTotalWeightOverBool);
  }, [formData]);

  useEffect(() => {
    const form = formData.map((formDataField: any) => {
      formDataField.quoteId = id;
      formDataField.weightMeasurement = weightMeasurement;

      return formDataField;
    });
    setFormData(form);
    const validateMeasurementValue = {
      weight:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.WEIGHT
          : LBS.WEIGHT,
      length:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.LENGTH
          : LBS.LENGTH,
      width:
        weightMeasurement === MEASUREMENT.WEIGHT2.value ? KGS.WIDTH : LBS.WIDTH,
      height:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.HEIGHT
          : LBS.HEIGHT,
    };
    setValidateMeasurement((old) => ({ ...old, ...validateMeasurementValue }));
  }, [weightMeasurement]);

  const handleChange = (e: any) => {
    let { value, checked } = e.target;
    value = parseInt(value);

    if (checked) {
      setSelectedServices((old: any) => [...old, value]);

      if (
        e.target.name === 'tailgate_pickup' ||
        e.target.name === 'tailgate_delivery'
      ) {
        setIsTailgate((old: any) => [...old, value]);
      }

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(true);
      }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(true);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(true);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(true);
      }
    } else {
      let arr = selectedServices.filter((item: any) => item !== value);
      setSelectedServices(arr);
      setIsTailgate((current: any) =>
        current.filter((item: any) => item !== value)
      );

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(false);
        setLimitedAccessType(limitedAccessList[0].value);
      }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(false);
        setInbondContactMethod(inbondContactMethodList[0].value);
        setInbondType(inbondTypeList[0].value);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(false);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(false);
      }
    }
  };

  const getList = (data: any) => (
    <div className="w-full xxl:p-5 p-4 mb-4 border border-gray200 rounded-xl flex sm:flex-row flex-col xlm:[&>div]:w-[calc(25%_-_16px)] xlm:[&>div:nth-child(4n)]:w-[calc(25%_-_16px)] xl:[&>div]:w-[calc(24%_-_16px)] xl:[&>div:nth-child(4n)]:w-[calc(29%_-_6px)] sm:[&>div]:w-[calc(50%_-_6px)] sm:[&>div:nth-child(4n)]:w-[calc(50%_-_6px)] flex-wrap grid-cols-3 xxl:gap-5 xl:gap-4 gap-3">
      {data.map((val: any) => (
        <div className="flex" key={`service_${val.id}`}>
          <CheckBox
            id={`service_${val.id}`}
            onChangeFunc={handleChange}
            name={val.slug}
            value={val.id}
            checked={selectedServices.includes(val.id)}
          />
          <label
            htmlFor={`service_${val.id}`}
            className="md:ml-2 ml-1.5 text-xs text-textSecondary cursor-pointer font-medium"
          >
            {val.name}
            <div className="popover ml-2 relative inline-block align-middle">
              {val.description && (
                <TooltipCmp message={val.description}>
                  <HiOutlineExclamationCircle className="text-grayText w-3.5 h-3.5" />
                </TooltipCmp>
              )}
            </div>
          </label>
        </div>
      ))}
    </div>
  );

  const getName = (contactMethod: string) => {
    if (contactMethod === 'fax-number') {
      return 'Fax *';
    } else if (contactMethod === 'phone-number') {
      return 'Phone *';
    } else {
      return 'Email *';
    }
  };

  const handleChangeRadio = (e: any) => {
    let { value, name } = e.target;

    if (name === 'contactMethod') {
      setInbondContactMethod(value);
    }

    if (name === 'inbondName') {
      setInbondType(value);
    }

    if (name === 'limitedAccessType') {
      setLimitedAccessType(value);
    }

    if (name === 'dangerType') {
      setDangerType(value);
    }
  };

  const handleSummarySubmit = () => {
    createUpdateSummary({ quoteId: id })
      .then(() => navigate(`${PATH.MY_QUOTE}/${id}`))
      .catch(() =>
        WalToast.error(
          'Dimensions And Additional services have not been updated'
        )
      );
  };

  const submitService = () => {
    if (id) {
      if (mode === 'ADD' && selectedServices.length === 0) {
        handleSummarySubmit();
      }

      const formDataCurrent: any = {
        quoteId: parseInt(id),
        serviceId: selectedServices.join(','),
        limitedAccess: isLimitedMore,
        inbond: isInbondMore,
        dangerousGoods: isDangerousMore,
      };

      if (isLimitedMore) {
        formDataCurrent.limitedAccessType = limitedAccessType;
        formDataCurrent.limitedAccessOther = limitedAccessOther;
      }

      if (isInbondMore) {
        formDataCurrent.inbondType = inbondType;
        formDataCurrent.inbondName = inbondName;
        formDataCurrent.inbondAddress = inbondAddress;
        formDataCurrent.inbondContactMethod = inbondContactMethod;
        formDataCurrent.inbondContactValue = inbondContactValue;
      }

      if (isDangerousMore) {
        formDataCurrent.dangerGoodsType = dangerType;
        formDataCurrent.goodsClass = goodsClass;
        formDataCurrent.description = description;
        formDataCurrent.unitedNationsNumber = unitedNationsNumber;
        formDataCurrent.packagingGroup = packagingGroup;
        formDataCurrent.emergencyContactName = emergencyContactName;
        formDataCurrent.emergencyContactNumber = emergencyContactNumber;
        formDataCurrent.emergencyContactExt = emergencyContactExt;
      }

      if (isDeclaredValueMore) {
        formDataCurrent.declaredValue = declaredValue;
        formDataCurrent.isDeclaredValue = isDeclaredValueMore;
        formDataCurrent.currency = currency;
      }
      createUpdateService(formDataCurrent)
        .then(() => navigate(`${PATH.MY_QUOTE}/${id}`))
        .finally(() => setIsLoading(false))
        .catch(() =>
          WalToast.error('Additional services have not been updated')
        );
    }
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        if (action.unusual) {
          submitService();
        } else {
          // handleActionType('unusual');
          submitService();
        }
      }
    },
    [action]
  );

  const isFormValid = () => {
    const validFormData: any = [];
    formData.forEach((form: any) => {
      const validation = {
        ...initValidForm,
        handlingUnit: {
          required: Boolean(form.handlingUnit),
          valid: Boolean(form.handlingUnit),
        },
        weight: {
          required: Boolean(form.weight),
          valid: Boolean(form.weight),
        },
        handlingUnitNo: {
          required: Boolean(form.handlingUnitNo),
          valid: form.handlingUnitNo <= HANDLING_UNIT_NUMBER,
        },
        itemLength: {
          required: Boolean(form.itemLength),
          valid: form.itemLength <= validateMeasurement.length,
        },
        width: {
          required: Boolean(form.width),
          valid: form.width <= validateMeasurement.width,
        },
        height: {
          required: Boolean(form.height),
          valid: form.height <= validateMeasurement.height,
        },
        commodityId: {
          required: form.isCommodity ? Boolean(form.commodityId) : true,
          valid: form.isCommodity ? Boolean(form.commodityId) : true,
        },
        sub: {
          required: form.isCommodity ? Boolean(form.sub) : true,
          valid: form.isCommodity ? Boolean(form.sub) : true,
        },
      };
      validFormData.push(validation);
    });
    setValidForm(validFormData);
    let valid = validFormData.every((form: any) =>
      requiredFields.every(
        (requiredField) =>
          form[requiredField].required && form[requiredField].valid
      )
    );

    return valid;
  };

  const validation = () => {
    let valid = true;
    const validFormData = {
      limitedAccessOther: true,
      inbondName: true,
      inbondAddress: true,
      inbondContactValue: true,
      unitedNationsNumber: true,
      goodsClass: true,
      description: true,
      emergencyContactName: true,
      emergencyContactNumber: true,
      declaredValue: true,
    };

    if (isLimitedMore && limitedAccessType === 'other') {
      if (limitedAccessOther === '' || limitedAccessOther === null) {
        validFormData.limitedAccessOther = false;
        valid = false;
      }
    }

    if (isInbondMore) {
      if (inbondName === '' || inbondName === null) {
        validFormData.inbondName = false;
        valid = false;
      }

      if (inbondAddress === '' || inbondAddress === null) {
        validFormData.inbondAddress = false;
        valid = false;
      }

      if (inbondContactValue === '' || inbondContactValue === null) {
        validFormData.inbondContactValue = false;
        valid = false;
      }
    }

    if (isDangerousMore) {
      if (unitedNationsNumber === '' || unitedNationsNumber === null) {
        validFormData.unitedNationsNumber = false;
        valid = false;
      }

      if (goodsClass === '' || goodsClass === null) {
        validFormData.goodsClass = false;
        valid = false;
      }

      if (description === '' || description === null) {
        validFormData.description = false;
        valid = false;
      }

      if (emergencyContactName === '' || emergencyContactName === null) {
        validFormData.emergencyContactName = false;
        valid = false;
      }

      if (emergencyContactNumber === '' || emergencyContactNumber === null) {
        validFormData.emergencyContactNumber = false;
        valid = false;
      }
    }

    if (isDeclaredValueMore) {
      if (!declaredValue) {
        validFormData.declaredValue = false;
        valid = false;
      }
    }
    setServicesValidForm(validFormData);

    return valid;
  };

  const handleActionType = (actionKey: string) => () => {
    setAction((old) => ({ ...old, [actionKey]: true }));
  };

  const handleServiceActionType = (actionKey: string) => {
    setAction((old) => ({ ...old, [actionKey]: true }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!isFormValid() || !validation()) {
      return true;
    }
    createUpdateDimension({ dimensions: formData })
      .then((result) => {
        if (result) {
          if (searchParams.get('_r') !== null) {
            handleSummarySubmit();
          } else {
            if (isTailgate.length && (isTotalWeightOver || isItemLengthOver)) {
              handleServiceActionType('tailgate');
            } else {
              // handleActionType('unusual');
              submitService();
            }
          }
        }
      })
      .catch((err) => {
        console.error(err);
        WalToast.error('Dimensions have not been updated');
      });
  };

  const handleMeasurement =
    (lengthMeasurementValue: string) => (event: any) => {
      const { value } = event.target;
      setWeightMeasurement(value);
      setLengthMeasurement(lengthMeasurementValue);
    };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Quote Requests"
            desc="Track, manage and forecast your customers and orders."
            rightSideContent={<StepBar steps={STEPS} activeStep={2} />}
          />
        }
      >
        <div className="w-full justify-between items-center flex flex-wrap gap-3">
          <div className="flex sm:flex-row flex-col flex-wrap xxl:gap-4 gap-3 sm:w-auto w-full md:order-[0] order-2">
            <div className="pt-3 pb-1 px-4 flex xxl:gap-5 gap-4 items-start bg-white border border-utilityGray200 rounded-xl shadow-sm xlm:min-w-[322px] lg:min-w-52 sm:min-w-44 min-w-full">
              <div className="p-3 bg-primary50 rounded-[10px] ">
                <Package className="w-4 h-4 text-primary700" />
              </div>
              <div>
                <p className="text-xs font-medium text-grayLight600 mb-1">
                  Total Weight of Shipment
                </p>
                <h3 className="text-grayLight900 xl:text-2xl lg:text-xl font-semibold">
                  {total.totalWeight} {weightMeasurement?.toLowerCase()}
                </h3>
              </div>
            </div>
            <div className="pt-3 pb-1 px-4 flex xxl:gap-5 gap-4 items-start bg-white border border-utilityGray200 rounded-xl shadow-sm xlm:min-w-[322px] lg:min-w-52 sm:min-w-44 min-w-full">
              <div className="p-3 bg-success50 rounded-[10px]">
                <Cube03 className="w-4 h-4 text-fgSuccessPrimary" />
              </div>
              <div>
                <p className="text-xs font-medium text-grayLight600 mb-1">
                  Total handling units
                </p>
                <h3 className="text-grayLight900 xl:text-2xl lg:text-xl font-semibold">
                  {total.totalUnit}
                </h3>
              </div>
            </div>
            <div className="py-2 px-4  bg-white border border-utilityGray200 rounded-xl shadow-sm">
              <p className="text-xs font-medium text-grayLight600 lg:mb-2 mb-1">
                Measurement Units
              </p>
              <div className="switcher switch-sm bg-primary100 !flex !rounded-md !p-1">
                <input
                  type="radio"
                  name="weightMeasurement"
                  id="lbs"
                  value={MEASUREMENT.WEIGHT1.value}
                  className="switcher__input switcher__input--yin"
                  checked={weightMeasurement === MEASUREMENT.WEIGHT1.value}
                  onChange={handleMeasurement(MEASUREMENT.LENGTH1.value)}
                />
                <label
                  htmlFor="lbs"
                  className={`switcher__label !text-[11px] !leading-[28px] ${
                    weightMeasurement === MEASUREMENT.WEIGHT1.value
                      ? 'text-white'
                      : ''
                  }`}
                >
                  Imperial (lbs)
                </label>
                <input
                  type="radio"
                  name="weightMeasurement"
                  id="kgs"
                  value={MEASUREMENT.WEIGHT2.value}
                  className="switcher__input switcher__input--yang"
                  checked={weightMeasurement === MEASUREMENT.WEIGHT2.value}
                  onChange={handleMeasurement(MEASUREMENT.LENGTH2.value)}
                />
                <label
                  htmlFor="kgs"
                  className={`switcher__label !text-[11px] !leading-[28px] ${
                    weightMeasurement === MEASUREMENT.WEIGHT2.value
                      ? 'text-white'
                      : ''
                  }`}
                >
                  Metric (kgs)
                </label>
                <span className="switcher__toggle !rounded-md"></span>
              </div>
            </div>
          </div>
          <div className="gap-3 flex flex-wrap ">
            <Link
              to={`${PATH.REQ_ADDRESS_DETAILS}/${id}`}
              className="btn_secondary_black xxl:min-w-[156px] xlm:min-w-32 min-w-fit text-center "
            >
              <div className="px-4 text-textSecondary text-xs font-semibold">
                Back
              </div>
            </Link>
            <ButtonCmp
              type="submit"
              className="btn_primary xxl:min-w-[156px] xlm:min-w-32 min-w-fit"
              onClick={(e: any) => handleSubmit(e)}
            >
              Finish
            </ButtonCmp>
          </div>
        </div>
        <div className=" w-full xxl:p-5 p-4 flex flex-col bg-white rounded-xl border border-utilityGray200 ">
          <h3 className="font-semibold lg:text-lg text-base text-grayLight600 mb-4">
            Dimensions
          </h3>
          <Items
            formData={formData}
            validForm={validForm}
            initFormData={initFormData}
            initValidForm={initValidForm}
            handlingUnitOptions={handlingUnitOptions}
            validateMeasurement={validateMeasurement}
            lengthMeasurement={lengthMeasurement}
            weightMeasurement={weightMeasurement}
            setFormData={setFormData}
            setValidForm={setValidForm}
            handleActionType={handleActionType}
          />

          {serviceList.length ? (
            <form onSubmit={handleSubmit}>
              <h3 className="font-semibold lg:text-lg text-base text-grayLight600 mb-4">
                Pickup
              </h3>
              {serviceTypes.PICKUP.length && getList(serviceTypes.PICKUP)}
              {isLimitedMore && (
                <div className="w-full xxl:p-5 p-4 mb-4 border border-gray200 rounded-xl flex sm:flex-row flex-col xxl:[&>div:nth-child(3n)]:w-[calc(33.33%_-_16px)] xxl:[&>div:nth-child(3n+1)]:w-[calc(33.33%_-_16px)] xxl:[&>div:nth-child(3n+2)]:w-[calc(33.33%_-_16px)] xlm:[&>div:nth-child(3n)]:w-[calc(36%_-_16px)] xlm:[&>div:nth-child(3n+2)]:w-[calc(30%_-_16px)] xlm:[&>div:nth-child(3n+1)]:w-[calc(35%_-_16px)] xl:[&>div:nth-child(3n)]:w-[calc(42%_-_16px)] xl:[&>div:nth-child(3n+2)]:w-[calc(34%_-_16px)] xl:[&>div:nth-child(3n+1)]:w-[calc(25%_-_16px)] sm:[&>div:nth-child(3n)]:w-[calc(50%_-_6px)] sm:[&>div:nth-child(3n+2)]:w-[calc(50%_-_6px)] sm:[&>div:nth-child(3n+1)]:w-[calc(50%_-_6px)] flex-wrap grid-cols-3 xxl:gap-5 xl:gap-4 gap-3 bg-gray50">
                  {limitedAccessList.map((val: any, i) => (
                    <div className="" key={`limited_${i}`}>
                      <div
                        className="flex items-start gap-2"
                        key={`service_${val.value}`}
                      >
                        <Radio
                          id={`service_${val.value}`}
                          inputClassName="w-5 h-5 checked:bg-primary bg-white border-2 cursor-pointer"
                          onChangeFunc={handleChangeRadio}
                          inputName={'limitedAccessType'}
                          value={val.value}
                          checked={limitedAccessType === val.value}
                        />
                        <label
                          htmlFor={`service_${val.value}`}
                          className="xxl:text-xs text-[11px] text-textSecondary uppercase cursor-pointer font-medium"
                        >
                          {val.name}
                        </label>
                      </div>
                    </div>
                  ))}
                  {limitedAccessType === 'other' && (
                    <div className="mt-5 w-full">
                      <InputText
                        inputName="limitedAccessOther"
                        className={`form_control ${
                          !validServicesForm.limitedAccessOther
                            ? 'border border-red-500 border-solid'
                            : ''
                        }`}
                        label=""
                        value={limitedAccessOther}
                        // errorText={!validServicesForm.limitedAccessOther ? 'This field is required' : null}
                        onChangeFunc={(e) =>
                          setLimitedAccessOther(e.target.value)
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              <h3 className="font-semibold lg:text-lg text-base text-grayLight600 mb-4">
                Delivery
              </h3>
              {serviceTypes.DELIVERY.length && getList(serviceTypes.DELIVERY)}
              <h3 className="font-semibold lg:text-lg text-base text-grayLight600 mb-4">
                Other
              </h3>
              {serviceTypes.OTHER.length && getList(serviceTypes.OTHER)}
            </form>
          ) : (
            <div className="flex justify-center mt-4">
              {isLoading ? (
                <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
              ) : (
                false
              )}
            </div>
          )}

          {action.tailgate && (
            <ConfirmModal
              title="Tailgate Over Dimensions"
              description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
                weightMeasurement === MEASUREMENT.WEIGHT1.value
                  ? `${LBS.MAX_WEIGHT}lbs`
                  : `${KGS.MAX_WEIGHT}kgs`
              }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
              button1="Yes, I am sure"
              button2="Modify Selection"
              handleClose={handleModalClose}
            />
          )}
          {action.unusual && (
            <ConfirmModal
              title="Unusual selection"
              description="Based on the options you selected, a lot of carriers might not give a direct quote for this load. Are you sure you want to proceed?"
              button1="Yes, I am sure"
              button2="Modify Selection"
              handleClose={handleModalClose}
            />
          )}

          {isInbondMore && (
            <div className="w-full xxl:p-5 p-4 mb-4 border border-gray200 rounded-xl grid xxl:gap-5 gap-4 bg-gray50 ">
              <p className="text-utilityBlack font-medium text-xs">
                In-Bond Details connect
              </p>
              <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px]">
                {inbondTypeList.map((val: any, i) => (
                  <div className="flex gap-2" key={`inbond_${i}`}>
                    <Radio
                      id={`inbond_${i}`}
                      onChangeFunc={handleChangeRadio}
                      inputName={'inbondName'}
                      value={val.value}
                      checked={inbondType === val.value}
                    />

                    <label
                      htmlFor={`inbond_${i}`}
                      className="text-textSecondary text-xs cursor-pointer font-medium"
                    >
                      {val.name}
                    </label>
                  </div>
                ))}
              </div>
              <div className="xlm:w-2/4 xl:w-3/5 lg:w-[83%] flex flex-wrap xxl:gap-5 gap-4 items-center">
                <div className="xxl:w-[calc(50%_-_10px)] lg:w-[calc(50%_-_8px)] w-full">
                  <InputText
                    inputName="inbondName"
                    placeholder=""
                    className={`form_control ${
                      !validServicesForm.inbondName
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="Warehouse or Carrier who will cancel US Bond*"
                    labelClassName="block mb-1.5"
                    value={inbondName}
                    icon={
                      !validServicesForm.inbondName ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    // errorText={!validServicesForm.inbondName ? 'This field is required' : null}
                    onChangeFunc={(e) => setInbondName(e.target.value)}
                  />
                </div>
                <div className="xxl:w-[calc(50%_-_10px)] lg:w-[calc(50%_-_8px)] w-full ">
                  <InputText
                    inputName="inbondAddress"
                    placeholder="123 Address"
                    className={`form_control ${
                      !validServicesForm.inbondAddress
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="Address*"
                    labelClassName="block mb-1.5"
                    icon={
                      !validServicesForm.inbondName ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    value={inbondAddress}
                    // errorText={!validServicesForm.inbondAddress ? 'This field is required' : null}
                    onChangeFunc={(e) => setInbondAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="xlm:w-2/4 xl:w-3/5 lg:w-[83%] w-full flex-wrap flex gap-5 items-center">
                <div className="xxl:w-[calc(50%_-_10px)] lg:w-[calc(50%_-_8px)] w-full lg:order-[0] order-2">
                  <InputText
                    inputName="inbondContactValue"
                    placeholder=""
                    className={`form_control ${
                      !validServicesForm.inbondContactValue
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    icon={
                      !validServicesForm.inbondContactValue ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    label={getName(inbondContactMethod)}
                    labelClassName="block mb-1.5"
                    value={inbondContactValue}
                    // errorText={!validServicesForm.inbondContactValue ? 'This field is required' : null}
                    onChangeFunc={(e) => setInbondContactValue(e.target.value)}
                  />
                </div>
                <div className="xxl:w-[calc(50%_-_10px)] lg:w-[calc(50%_-_8px)] flex justify-start items-center lg:mt-[21px] mt-2 gap-5">
                  {inbondContactMethodList.map((val: any, i) => (
                    <div
                      className="flex gap-2 items-center"
                      key={`inbond_contact_${i}`}
                    >
                      <Radio
                        id={`inbond_contact_${i}`}
                        onChangeFunc={handleChangeRadio}
                        inputName={'contactMethod'}
                        value={val.value}
                        checked={inbondContactMethod === val.value}
                      />
                      <label
                        htmlFor={`inbond_contact_${i}`}
                        className="text-xs xxl:text-xs uppercase cursor-pointer font-bolder"
                      >
                        {val.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {isDangerousMore && (
            <div className="w-full xxl:p-5 p-4 mb-4 border border-gray200 rounded-xl grid xxl:gap-5 gap-4 bg-gray50 ">
              <p className="text-utilityBlack font-medium text-xs">
                Please provide the Dangerous Goods details, as these details
                will show up on the BOL. Failure to enter this data may result
                in delayed pickups.
              </p>
              <div className="flex sm:flex-row flex-col flex-wrap xxl:gap-5 xl:gap-4 gap-3">
                {dangerTypeList.map((val: any, i) => (
                  <div className="flex gap-2" key={`danger_${i}`}>
                    <Radio
                      id={`danger_${i}`}
                      onChangeFunc={handleChangeRadio}
                      inputName={'dangerType'}
                      value={val.value}
                      checked={dangerType === val.value}
                    />
                    <label
                      htmlFor={`danger_${i}`}
                      className="text-textSecondary text-xs font-medium uppercase cursor-pointer"
                    >
                      {val.name}
                    </label>
                  </div>
                ))}
              </div>
              <div className="grid sm:grid-cols-3 xxl:gap-5 xl:gap-4 gap-3">
                <InputText
                  inputName="unitedNationsNumber"
                  placeholder="UN0000"
                  className={`form_control ${
                    !validServicesForm.unitedNationsNumber
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="UN #*"
                  labelClassName="block mb-1.5"
                  value={unitedNationsNumber}
                  icon={
                    !validServicesForm.inbondName ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  // errorText={!validServicesForm.unitedNationsNumber ? 'This field is required' : null}
                  onChangeFunc={(e) => setUnitedNationsNumber(e.target.value)}
                />

                <div className="content">
                  <label className="form_label block mb-1.5">
                    Packaging Group *
                  </label>
                  <SelectBox
                    name="packagingGroup"
                    id="packagingGroup"
                    className="form_control"
                    options={packagingGroupOptions}
                    onChangeFunc={(event: any) =>
                      setPackagingGroup(event.value)
                    }
                    value={packagingGroupOptions.find(
                      (val) => val.value === packagingGroup
                    )}
                  />
                </div>

                <InputText
                  inputType="number"
                  inputName="goodsClass"
                  placeholder=""
                  className={`form_control ${
                    !validServicesForm.goodsClass
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="Class*"
                  labelClassName="block mb-1.5"
                  value={goodsClass}
                  icon={
                    !validServicesForm.goodsClass ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  // errorText={!validServicesForm.goodsClass ? 'This field is required' : null}
                  onChangeFunc={(e) => setGoodsClass(e.target.value)}
                />
              </div>
              <div className="w-full">
                <InputText
                  inputName="description"
                  placeholder=""
                  className={`form_control ${
                    !validServicesForm.description
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="Technical Name or Description*"
                  labelClassName="block mb-1.5"
                  value={description}
                  icon={
                    !validServicesForm.description ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  // errorText={!validServicesForm.description ? 'This field is required' : null}
                  onChangeFunc={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="grid lg:grid-cols-3 grid-col-1 xxl:gap-5 xl:gap-4 gap-3">
                <InputText
                  inputName="emergencyContactName"
                  placeholder=""
                  className={`form_control ${
                    !validServicesForm.emergencyContactName
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="24-hr Emergency Contact Name*"
                  labelClassName="block mb-1.5"
                  icon={
                    !validServicesForm.emergencyContactName ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  value={emergencyContactName}
                  // errorText={!validServicesForm.emergencyContactName ? 'This field is required' : null}
                  onChangeFunc={(e) => setEmergencyContactName(e.target.value)}
                />

                <InputText
                  inputType="number"
                  inputName="emergencyContactNumber"
                  placeholder=""
                  className={`form_control ${
                    !validServicesForm.emergencyContactNumber
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  icon={
                    !validServicesForm.emergencyContactNumber ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  label="Contact Phone Number*"
                  labelClassName="block mb-1.5"
                  value={emergencyContactNumber}
                  // errorText={!validServicesForm.emergencyContactNumber ? 'This field is required' : null}
                  onChangeFunc={(e) =>
                    setEmergencyContactNumber(e.target.value)
                  }
                />

                <InputText
                  inputName="emergencyContactExt"
                  placeholder=""
                  className="form_control"
                  label="Ext"
                  labelClassName="block mb-1.5"
                  value={emergencyContactExt}
                  onChangeFunc={(e) => setEmergencyContactExt(e.target.value)}
                />
              </div>
            </div>
          )}
          {isDeclaredValueMore && (
            <div className="flex justify-center py-3">
              <div className="xxl:w-full xl:w-2/5 w-full">
                <div className="bg-slate-50 px-4 pt-2 pb-4 border border-gray-200 rounded-xl w-full">
                  <p className="text-dark m-0 p-0 font-medium pt-2 pb-2 text-xs">
                    Declared Value
                  </p>
                  <div className="flex items-center">
                    <div className="mb-4 w-1/3">
                      <InputText
                        inputType="number"
                        inputName="declaredValue"
                        placeholder=""
                        className={`form_control ${
                          !validServicesForm.declaredValue
                            ? 'border border-red-500 border-solid'
                            : ''
                        }`}
                        label=""
                        value={declaredValue}
                        onChangeFunc={(e: any) =>
                          setDeclaredValue(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </PageSectionLayout>
    </>
  );
};

export default Dimensions;
