import { File02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CommonModal from 'src/components/CommonModal';

interface IProps {
  handleClose: any;
}

const TypeInfoModal = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Type Description'}
    titleDesc={'Update and mange your sales representative'}
    handleClose={() => handleClose(false)}
    headerIcon={<File02 />}
    size={'max-w-[688px]'}
    isOverflow={true}
    modalClassName=""
  >
    <div className="w-full p-5 flex flex-col gap-4">
      <div className="rounded-lg border border-borderPrimary shadow-xs py-4 px-3 text-sm">
        <div className="flex items-center gap-1">
          <h6 className="text-grayLight900 font-semibold">Type:</h6>
          <BadgeCmp
            style="modern"
            badgeDotColor="bg-yellow300"
            mainClassName=" mr-1"
          >
            Test
          </BadgeCmp>
        </div>
        <p className="text-grayLight900 font-normal mt-3">
          Lorem Ipsumis simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s
        </p>
      </div>
      <div className="rounded-lg border border-borderPrimary shadow-xs py-4 px-3 text-sm">
        <div className="flex gap-x-2">
          <h6 className="text-grayLight900 font-semibold">Type:</h6>
          <BadgeCmp
            style="modern"
            badgeDotColor="bg-[#b2ac17]"
            mainClassName=" mr-1"
          >
            Top
          </BadgeCmp>
        </div>
        <p className="text-grayLight900 font-normal mt-3">
          Lorem Ipsumis simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s
        </p>
      </div>
      <div className="rounded-lg border border-borderPrimary shadow-xs py-4 px-3 text-sm">
        <div className="flex gap-x-2">
          <h6 className="text-grayLight900 font-semibold">Type:</h6>
          <BadgeCmp
            style="modern"
            badgeDotColor="bg-utilityPink600"
            mainClassName=" mr-1"
          >
            Dormant
          </BadgeCmp>
        </div>
        <p className="text-grayLight900 font-normal mt-3">
          Lorem Ipsumis simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s
        </p>
      </div>
      <div className="rounded-lg border border-borderPrimary shadow-xs py-4 px-3 text-sm">
        <div className="flex gap-x-2">
          <h6 className="text-grayLight900 font-semibold">Type:</h6>
          <BadgeCmp style="modern" type="lightblue" mainClassName=" mr-1">
            Prospect
          </BadgeCmp>
        </div>
        <p className="text-grayLight900 font-normal mt-3">
          Lorem Ipsumis simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s
        </p>
      </div>
      <div className="rounded-lg border border-borderPrimary shadow-xs py-4 px-3 text-sm">
        <div className="flex gap-x-2">
          <h6 className="text-grayLight900 font-semibold">Type:</h6>
          <BadgeCmp
            style="modern"
            badgeDotColor="bg-fgSuccessPrimary"
            mainClassName=" mr-1"
          >
            New
          </BadgeCmp>
        </div>
        <p className="text-grayLight900 font-normal mt-3">
          Lorem Ipsumis simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s
        </p>
      </div>
      <div className="rounded-lg border border-borderPrimary shadow-xs py-4 px-3 text-sm">
        <div className="flex gap-x-2">
          <h6 className="text-grayLight900 font-semibold">Type:</h6>
          <BadgeCmp style="modern" type="primary" mainClassName=" mr-1">
            Active
          </BadgeCmp>
        </div>
        <p className="text-grayLight900 font-normal mt-3">
          Lorem Ipsumis simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s
        </p>
      </div>
      <div className="rounded-lg border border-borderPrimary shadow-xs py-4 px-3 text-sm">
        <div className="flex gap-x-2">
          <h6 className="text-grayLight900 font-semibold">Type:</h6>
          <BadgeCmp style="modern" type="error" mainClassName=" mr-1">
            Inactive
          </BadgeCmp>
        </div>
        <p className="text-grayLight900 font-normal mt-3">
          Lorem Ipsumis simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s
        </p>
      </div>
      <div className="rounded-lg border border-borderPrimary shadow-xs py-4 px-3 text-sm">
        <div className="flex gap-x-2">
          <h6 className="text-grayLight900 font-semibold">Type:</h6>
          <BadgeCmp style="modern" type="gray" mainClassName=" mr-1">
            At-Risk
          </BadgeCmp>
        </div>
        <p className="text-grayLight900 font-normal mt-3">
          Lorem Ipsumis simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s
        </p>
      </div>
    </div>
  </CommonModal>
);

export default TypeInfoModal;
