import moment from 'moment';
import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { HiPlus } from 'react-icons/hi';
import { LuEqual } from 'react-icons/lu';
import {
  RiEditFill,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
} from 'react-icons/ri';
import WalToast from 'src/utils/WalToast';

import leftBracket from '../../../../../assets/img/left-bracket.svg';
import rightBracket from '../../../../../assets/img/right-bracket.svg';
import AccordionCmp from '../../../../../components/AccordionCmp';
import ButtonCmp from '../../../../../components/ButtonCmp';
import Drawer from '../../../../../components/Drawer';
import InputText from '../../../../../components/InputText/InputText';
import TabsCmp from '../../../../../components/TabsCmp';
import { MARGIN_TYPE, CURRENCY } from '../../../../../constants/common';
import { getCarrierServicePrices } from '../../../../../services/CarrierService';
import { spotQuoteUpdate } from '../../../../../services/QuoteService';

const initEditableFormData = {
  transitTime: false,
  projectedDeliveryDate: false,
  margin: false,
  totalCharge: false,
  carrierQuoteId: false,
};

const initColumns = {
  isDisplayCarrier: false,
  isDisplayTransit: true,
};

const initRequiredFields = [
  'margin',
  'totalCharge',
  'finalCharge',
  'carrierQuoteId',
  'transitTime',
  'projectedDeliveryDate',
];

const SpotQuote = ({
  isOpen,
  mode,
  selectedCarrierRates,
  setIsRefresh,
  handleClose,
  currency,
}: any) => {
  const [isValid, setIsValid] = useState<any>({});
  const [isValidNonQuote, setIsValidNonQuote] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [resetFormData, setResetFormData] = useState<any>({});
  const [editableFormData, setEditableFormData] =
    useState(initEditableFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [requiredFields, setRequiredFields] = useState(initRequiredFields);
  const [nonQuoteRequiredFields, setNonQuoteRequiredFields] = useState([]);
  const [columns, setColumns] = useState(initColumns);

  useEffect(() => {
    if (isOpen) {
      setIsEditable(mode === 'update');
      setEditableFormData(initEditableFormData);
      setColumns(initColumns);
    }
  }, [isOpen]);

  useEffect(() => {
    const existingFormData: any = {};
    const existingNonQuoteRequiredFields: any = [];
    const existingRequiredFields = [...initRequiredFields];
    selectedCarrierRates.forEach((selectedCarrierRate: any) => {
      const quotedServices: any = [];
      const nonQuotedServices: any = [];
      const priceData: any = {};

      if (selectedCarrierRate.services && selectedCarrierRate.services.length) {
        selectedCarrierRate.services.forEach((service: any) => {
          if (service.isQuoted) {
            quotedServices.push({
              [service.serviceId.slug]: {
                name: service.serviceId.name,
                value: service.value,
                serviceId: service.serviceId.id,
              },
            });
          } else {
            nonQuotedServices.push({
              [service.serviceId.slug]: {
                name: service.serviceId.name,
                value: service.value,
                serviceId: service.serviceId.id,
              },
            });
            existingNonQuoteRequiredFields.push(service.serviceId.slug);
          }
          existingRequiredFields.push(service.serviceId.slug);
        });
      }

      getCarrierServicePrices(selectedCarrierRate.carrierId)
        .then((response: any) => {
          if (response?.data && response.data.length) {
            response.data.forEach(
              (data: any) =>
                (priceData[data.serviceId] =
                  currency === CURRENCY.CAD ? data.priceCAD : data.priceUSD)
            );
          }
          selectedCarrierRate.carrierPriceData = priceData;
        })
        .catch(console.log);

      existingFormData[selectedCarrierRate.id] = {
        transitTime: selectedCarrierRate.transitTime,
        projectedDeliveryDate: selectedCarrierRate.projectedDeliveryDate,
        margin:
          selectedCarrierRate.margin > 0
            ? selectedCarrierRate?.margin?.toFixed(2)
            : selectedCarrierRate.margin,
        marginUnit: selectedCarrierRate.marginUnit
          ? selectedCarrierRate.marginUnit.toFixed(2)
          : selectedCarrierRate.marginUnit,
        additionalCharges:
          selectedCarrierRate.additionalCharges > 0
            ? selectedCarrierRate.additionalCharges.toFixed(2)
            : 0,
        finalCharge:
          selectedCarrierRate.finalCharge > 0
            ? selectedCarrierRate.finalCharge.toFixed(2)
            : 0,
        totalCharge:
          selectedCarrierRate.totalCharge > 0
            ? selectedCarrierRate.totalCharge.toFixed(2)
            : 0,
        marginType: selectedCarrierRate.marginType,
        carrierPriceData: priceData,
        quotedServices,
        nonQuotedServices,
        carrierQuoteId:
          selectedCarrierRate.carrierQuoteId === null
            ? ''
            : selectedCarrierRate.carrierQuoteId,
        isSpotRequestSent: selectedCarrierRate.isSpotRequestSent,
      };
    });
    setRequiredFields(existingRequiredFields);
    setNonQuoteRequiredFields(existingNonQuoteRequiredFields);
    setFormData(existingFormData);
    setResetFormData(existingFormData);
  }, [selectedCarrierRates]);

  useEffect(() => {
    const existingValid: any = {};
    const existingValidNonQuote: any = {};
    Object.entries(formData).forEach(([key, value]: any) => {
      const isValid1 = requiredFields.every(
        (requiredField) =>
          value[requiredField] !== '' && value[requiredField] !== null
      );
      let isValid2 = true;

      if (value.nonQuotedServices && value.nonQuotedServices.length) {
        isValid2 = value.nonQuotedServices.every((nonQuotedService: any) =>
          nonQuoteRequiredFields.every((requiredField) => {
            if (nonQuotedService[requiredField]) {
              return (
                nonQuotedService[requiredField].value !== '' &&
                nonQuotedService[requiredField].value !== null
              );
            }

            return true;
          })
        );
      }
      existingValidNonQuote[key] = isValid2;
      existingValid[key] = isValid1 && isValid2;
    });
    setIsValid(existingValid);
    setIsValidNonQuote(existingValidNonQuote);
  }, [formData]);

  const handleInputChange =
    (id: number, isService = false) =>
    (event: any) => {
      const { target, type } = event;
      const { name, value } = target;
      const regex = /^[0-9.]*$/;

      if (!regex.test(value)) {
        return;
      }

      const existingFormData: any = {
        ...formData[id],
      };

      const newValue =
        type === 'blur' && !value
          ? 0
          : type === 'focus' && value === '0'
          ? ''
          : value;

      if (!isService) {
        existingFormData[name] = newValue;
      }
      const parseValue =
        type === 'blur' && !value ? 0 : value ? parseFloat(value) : 0;

      let additionalCharges = 0;
      existingFormData.quotedServices.forEach(
        (quoteService: any, index: number) => {
          Object.entries(quoteService).forEach(([slug, service]: any) => {
            additionalCharges +=
              name === slug ? parseValue : parseFloat(service.value);
            existingFormData.quotedServices[index][slug] = {
              ...service,
              value: name === slug ? newValue : parseFloat(service.value),
            };
          });
        }
      );
      existingFormData.nonQuotedServices.forEach(
        (nonQuoteService: any, index: number) => {
          Object.entries(nonQuoteService).forEach(([slug, service]: any) => {
            additionalCharges +=
              name === slug
                ? parseValue
                : service.value
                ? parseFloat(service.value)
                : 0;
            existingFormData.nonQuotedServices[index][slug] = {
              ...service,
              value:
                name === slug
                  ? newValue
                  : service.value !== null
                  ? parseFloat(service.value)
                  : null,
            };
          });
        }
      );
      existingFormData.additionalCharges =
        additionalCharges > 0 ? additionalCharges.toFixed(2) : 0;

      const total =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges);

      const margin =
        existingFormData.marginType === MARGIN_TYPE.PERCENTAGE
          ? (total / 100) * parseFloat(existingFormData.marginUnit || 0)
          : parseFloat(existingFormData.marginUnit || 0);

      existingFormData.margin = margin;

      if (name === 'finalCharge') {
        if (type === 'blur') {
          existingFormData[name] =
            parseValue < total
              ? total > 0
                ? total.toFixed(2)
                : 0
              : parseValue > 0
              ? parseValue.toFixed(2)
              : 0;
        }
        let newMargin = parseValue - total;

        if (existingFormData.marginType === MARGIN_TYPE.PERCENTAGE) {
          newMargin = ((parseValue - total) / total) * 100;
        }
        existingFormData.margin =
          parseValue < total ? 0 : newMargin ? newMargin.toFixed(2) : newMargin;
      } else {
        existingFormData.finalCharge = (total + margin).toFixed(2);
      }
      setFormData((old: any) => ({ ...old, [id]: existingFormData }));
    };

  const handleSubmit = (id: number) => () => {
    const data: any = {
      currency,
      columns,
      isPdf: false,
      margin: formData[id].margin,
      marginUnit: formData[id].marginUnit,
      marginType: formData[id].marginType,
      transitTime: formData[id].transitTime,
      projectedDeliveryDate: formData[id].projectedDeliveryDate,
      totalCharge: formData[id].totalCharge,
      services: [
        ...formData[id].quotedServices,
        ...formData[id].nonQuotedServices,
      ],
      carrierQuoteId: formData[id].carrierQuoteId,
      isSpotRequestSent: formData[id].isSpotRequestSent,
    };
    spotQuoteUpdate(id, data)
      .then(() => {
        handleClose();
        setIsRefresh(true);
        WalToast.success('Quote updated successfully');
      })
      .finally(() => setIsLoading(false))
      .catch(console.error);
  };

  const handleEditableFormData = (name: string) => () => {
    setEditableFormData((old) => ({ ...old, [name]: true }));
  };

  const saveEditableFormData =
    (name: string, id: number, status: boolean) => () => {
      if (status) {
        const existingFormData: any = {
          ...formData[id],
        };
        setResetFormData((old: any) => ({ ...old, [id]: existingFormData }));
      } else {
        const existingFormData: any = {
          ...formData[id],
          [name]: parseFloat(resetFormData[id][name]),
        };

        if (name === 'carrierQuoteId') {
          existingFormData.carrierQuoteId = resetFormData[id][name];
        }

        if (name === 'totalCharge') {
          const total =
            parseFloat(existingFormData.totalCharge) +
            parseFloat(existingFormData.additionalCharges);
          const margin =
            existingFormData.marginType === MARGIN_TYPE.PERCENTAGE
              ? (total / 100) * parseFloat(existingFormData.margin)
              : parseFloat(existingFormData.margin);
          existingFormData.finalCharge = (total + margin).toFixed(2);
        }
        setFormData((old: any) => ({ ...old, [id]: existingFormData }));
      }
      setEditableFormData((old) => ({ ...old, [name]: false }));
    };

  const handleDateChange = (id: number, name: string) => (date: any) => {
    const existingFormData: any = {
      ...formData[id],
    };
    existingFormData[name] = date;
    setFormData((old: any) => ({ ...old, [id]: existingFormData }));
  };

  const handleStringChange = (id: number) => (event: any) => {
    const { target, type } = event;
    const { name, value } = target;
    const existingFormData: any = {
      ...formData[id],
    };
    const newValue = type === 'blur' && !value ? '' : value;
    existingFormData[name] = newValue;
    setFormData((old: any) => ({ ...old, [id]: existingFormData }));
  };

  const handleMarginType = (id: number, name: string) => (event: any) => {
    const { value } = event.target;
    const existingFormData: any = {
      ...formData[id],
    };
    existingFormData[name] = value;
    const total =
      parseFloat(existingFormData.totalCharge) +
      parseFloat(existingFormData.additionalCharges);
    const margin =
      existingFormData.marginType === MARGIN_TYPE.PERCENTAGE
        ? (total / 100) * parseFloat(existingFormData.marginUnit)
        : parseFloat(existingFormData.marginUnit);

    existingFormData.margin = margin;
    existingFormData.finalCharge = (total + margin).toFixed(2);
    setFormData((old: any) => ({ ...old, [id]: existingFormData }));
  };

  // const DateCustomInput = forwardRef(({ onClick }: any) => <FaRegCalendarAlt size={18} className="text-primary ms-3 cursor-pointer" onClick={onClick} />);
  const DateCustomInput = forwardRef(function DateCustomInput({
    onClick,
  }: any) {
    return (
      <FaRegCalendarAlt
        size={18}
        className="text-primary ms-3 cursor-pointer"
        onClick={onClick}
      />
    );
  });

  const drawer = useMemo(() => {
    let drawerValue = {
      title: '',
      button: '',
    };

    switch (mode) {
      case 'update':
        drawerValue.title = 'Add Spot Quote Details';
        break;
      default:
        drawerValue.title = 'None';
        drawerValue.button = 'None';
        break;
    }

    return drawerValue;
  }, [mode]);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        handleClose={handleClose}
        title={drawer.title}
        classDrawer="max-w-4xl"
      >
        {Object.keys(selectedCarrierRates).length > 0 && (
          <TabsCmp>
            {selectedCarrierRates.map(
              (selectedCarrierRate: any, index: number) => (
                <TabsCmp.ItemCmp
                  isActive={true}
                  key={index}
                  title={selectedCarrierRate.name}
                >
                  <div className="flex border-b">
                    <div className="pb-4 flex-1">
                      <p className="mb-3 text-grayText">Carrier Quote Id</p>
                      <div className="flex items-center text-black">
                        {editableFormData.carrierQuoteId ? (
                          <>
                            <input
                              type="text"
                              name="carrierQuoteId"
                              className="text-black text-base border-0 focus:outline-none focus:ring-0 border-b border-grayText p-0 w-[90px]"
                              value={
                                formData[selectedCarrierRate.id]
                                  ? formData[selectedCarrierRate.id]
                                      .carrierQuoteId
                                  : ''
                              }
                              onChange={handleStringChange(
                                selectedCarrierRate.id
                              )}
                              onBlur={handleStringChange(
                                selectedCarrierRate.id
                              )}
                            />
                            <span>
                              <RiCheckboxCircleFill
                                size={20}
                                className="text-primary ms-3 cursor-pointer"
                                onClick={saveEditableFormData(
                                  'carrierQuoteId',
                                  selectedCarrierRate.id,
                                  true
                                )}
                              />
                            </span>
                            <span>
                              <RiCloseCircleFill
                                size={20}
                                className="text-primary ms-3 cursor-pointer"
                                onClick={saveEditableFormData(
                                  'carrierQuoteId',
                                  selectedCarrierRate.id,
                                  false
                                )}
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            {formData[selectedCarrierRate.id]
                              ? formData[selectedCarrierRate.id].carrierQuoteId
                                ? formData[selectedCarrierRate.id]
                                    .carrierQuoteId
                                : '-'
                              : '-'}
                            {!isDisable && isEditable && (
                              <span>
                                <RiEditFill
                                  size={18}
                                  className="text-primary ms-3 cursor-pointer"
                                  onClick={handleEditableFormData(
                                    'carrierQuoteId'
                                  )}
                                />
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="pb-4 flex-1">
                      <p className="mb-3 text-grayText">Transit time</p>
                      <div className="flex items-center text-black">
                        {editableFormData.transitTime ? (
                          <>
                            <input
                              type="number"
                              name="transitTime"
                              className="text-black text-base border-0 focus:outline-none focus:ring-0 border-b border-grayText p-0 w-[90px]"
                              value={
                                formData[selectedCarrierRate.id]
                                  ? formData[selectedCarrierRate.id].transitTime
                                  : 0
                              }
                              onChange={handleInputChange(
                                selectedCarrierRate.id
                              )}
                              onBlur={handleInputChange(selectedCarrierRate.id)}
                            />
                            <span>
                              <RiCheckboxCircleFill
                                size={20}
                                className="text-primary ms-3 cursor-pointer"
                                onClick={saveEditableFormData(
                                  'transitTime',
                                  selectedCarrierRate.id,
                                  true
                                )}
                              />
                            </span>
                            <span>
                              <RiCloseCircleFill
                                size={20}
                                className="text-primary ms-3 cursor-pointer"
                                onClick={saveEditableFormData(
                                  'transitTime',
                                  selectedCarrierRate.id,
                                  false
                                )}
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            Est.{' '}
                            {formData[selectedCarrierRate.id]
                              ? formData[selectedCarrierRate.id].transitTime
                              : 0}{' '}
                            Business Days.
                            {!isDisable && isEditable && (
                              <span>
                                <RiEditFill
                                  size={18}
                                  className="text-primary ms-3 cursor-pointer"
                                  onClick={handleEditableFormData(
                                    'transitTime'
                                  )}
                                />
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="pb-4 flex-1">
                      <p className="mb-3 text-grayText">Projected delivery</p>
                      <div className="flex items-center text-black">
                        {formData[selectedCarrierRate.id] &&
                        formData[selectedCarrierRate.id].projectedDeliveryDate
                          ? moment(
                              formData[selectedCarrierRate.id]
                                .projectedDeliveryDate
                            ).format('DD MMMM, YYYY')
                          : '-'}
                        {!isDisable && isEditable && (
                          <span>
                            <ReactDatePicker
                              dateFormat="dd/MM/yyyy"
                              minDate={moment().add(1, 'day').toDate()}
                              selected={
                                formData[selectedCarrierRate.id] &&
                                formData[selectedCarrierRate.id]
                                  .projectedDeliveryDate
                                  ? moment(
                                      formData[selectedCarrierRate.id]
                                        .projectedDeliveryDate
                                    ).toDate()
                                  : moment().toDate()
                              }
                              customInput={<DateCustomInput />}
                              onChange={handleDateChange(
                                selectedCarrierRate.id,
                                'projectedDeliveryDate'
                              )}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {formData[selectedCarrierRate.id] &&
                    formData[selectedCarrierRate.id].nonQuotedServices &&
                    formData[selectedCarrierRate.id].nonQuotedServices.length >
                      0 && (
                      <div className="border-b border-gray-100">
                        <AccordionCmp
                          className="border-0"
                          title={
                            <div className="-mx-[18px]">
                              <span className="text-black font-bold">
                                Non-Quoted Services
                              </span>
                              <span className="text-grayText"> (required)</span>
                            </div>
                          }
                        >
                          {formData[
                            selectedCarrierRate.id
                          ].nonQuotedServices.map((nonQuoteService: any) =>
                            Object.entries(nonQuoteService).map(
                              ([slug, service]: any, idx: number) => (
                                <div
                                  key={idx}
                                  className="flex flex-wrap -mx-3 gap-3"
                                >
                                  <div className="w-1/2 flex-1">
                                    <div className="mb-4">
                                      <InputText
                                        disabled
                                        className="form_control"
                                        label="Service"
                                        inputName={slug}
                                        value={service.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="w-1/2 flex-1">
                                    <div className="mb-4 relative">
                                      <InputText
                                        inputType="text"
                                        className="form_control pl-8"
                                        label={`Monetary value ${
                                          selectedCarrierRate.carrierPriceData &&
                                          selectedCarrierRate.carrierPriceData[
                                            service.serviceId
                                          ]
                                            ? `($${
                                                selectedCarrierRate
                                                  .carrierPriceData[
                                                  service.serviceId
                                                ]
                                              })`
                                            : ''
                                        }`}
                                        disabled={isDisable}
                                        inputName={slug}
                                        value={
                                          service.value !== null &&
                                          service.value !== 'NaN'
                                            ? service.value
                                            : ''
                                        }
                                        onChangeFunc={handleInputChange(
                                          selectedCarrierRate.id,
                                          true
                                        )}
                                        onBlur={handleInputChange(
                                          selectedCarrierRate.id,
                                          true
                                        )}
                                        onFocus={handleInputChange(
                                          selectedCarrierRate.id,
                                          true
                                        )}
                                      />
                                      <label className="text-lg text-black leading-6 absolute top-11 left-4">
                                        $
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          )}
                        </AccordionCmp>
                      </div>
                    )}
                  <div className="total_price py-5">
                    <h1 className="text-black font-bold">Total Quote price</h1>
                    <div className="mt-5 flex items-center justify-between">
                      <div className="flex-1">
                        <div className="flex items-center">
                          <img src={leftBracket} alt="" />
                          <div className="calc text-center flex-1">
                            <p className="mb-3 text-base text-black">
                              Raw Quote
                            </p>
                            <div className="flex items-center justify-center">
                              {editableFormData.totalCharge ? (
                                <>
                                  <input
                                    type="text"
                                    name="totalCharge"
                                    className="text-black text-base border-0 w-[90px] focus:outline-none focus:ring-0 border-b border-grayText p-0"
                                    value={
                                      formData[selectedCarrierRate.id]
                                        ? formData[selectedCarrierRate.id]
                                            .totalCharge
                                        : 0
                                    }
                                    onChange={handleInputChange(
                                      selectedCarrierRate.id
                                    )}
                                    onBlur={handleInputChange(
                                      selectedCarrierRate.id
                                    )}
                                  />
                                  <span>
                                    <RiCheckboxCircleFill
                                      size={20}
                                      className="text-primary ms-3 cursor-pointer"
                                      onClick={saveEditableFormData(
                                        'totalCharge',
                                        selectedCarrierRate.id,
                                        true
                                      )}
                                    />
                                  </span>
                                  <span>
                                    <RiCloseCircleFill
                                      size={20}
                                      className="text-primary ms-3 cursor-pointer"
                                      onClick={saveEditableFormData(
                                        'totalCharge',
                                        selectedCarrierRate.id,
                                        false
                                      )}
                                    />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <h1 className="text-2xl font-bold text-black">
                                    $
                                    {formData[selectedCarrierRate.id]
                                      ? formData[selectedCarrierRate.id]
                                          .totalCharge
                                      : 0}
                                  </h1>
                                  {!isDisable && isEditable && (
                                    <span>
                                      <RiEditFill
                                        size={18}
                                        className="text-primary ms-3 cursor-pointer"
                                        onClick={handleEditableFormData(
                                          'totalCharge'
                                        )}
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="calc mx-3">
                            <HiPlus size={30} className="text-black" />
                          </div>
                          <div className="calc flex-1 text-center">
                            <p className="mb-3 text-base text-black">
                              Additional Charges
                            </p>
                            <h1 className="text-2xl flex items-center justify-center font-bold text-black">
                              $
                              {formData[selectedCarrierRate.id]
                                ? formData[selectedCarrierRate.id]
                                    .additionalCharges || 0
                                : 0}
                            </h1>
                          </div>
                          <img src={rightBracket} alt="" />
                        </div>
                      </div>
                      <div className="w-auto mx-5">
                        <HiPlus size={30} className="text-black" />
                      </div>
                      <div className="flex-1 relative">
                        <InputText
                          inputType="text"
                          inputName="marginUnit"
                          className="form_control pl-8"
                          label="Margin"
                          disabled={isDisable}
                          value={
                            formData[selectedCarrierRate.id]
                              ? formData[selectedCarrierRate.id].marginUnit
                              : 0
                          }
                          onChangeFunc={handleInputChange(
                            selectedCarrierRate.id
                          )}
                          onBlur={handleInputChange(selectedCarrierRate.id)}
                          onFocus={handleInputChange(selectedCarrierRate.id)}
                        />
                        <label className="text-lg text-black leading-6 absolute top-[45px] left-4">
                          {formData[selectedCarrierRate.id]
                            ? MARGIN_TYPE.VALUE ===
                              formData[selectedCarrierRate.id].marginType
                              ? '$'
                              : '%'
                            : '$'}
                        </label>
                        <div className="absolute right-[7px] bottom-[15px] z-20">
                          <input
                            id={`toggle-value-${selectedCarrierRate.id}`}
                            name={`marginType-${selectedCarrierRate.id}`}
                            type="radio"
                            className="toggle_icons toggle-left"
                            disabled={isDisable}
                            value={MARGIN_TYPE.VALUE}
                            checked={
                              formData[selectedCarrierRate.id]
                                ? formData[selectedCarrierRate.id]
                                    .marginType === MARGIN_TYPE.VALUE
                                : false
                            }
                            onChange={handleMarginType(
                              selectedCarrierRate.id,
                              'marginType'
                            )}
                          />
                          <label
                            htmlFor={`toggle-value-${selectedCarrierRate.id}`}
                            className="toggle_button"
                          >
                            $
                          </label>
                          <input
                            id={`toggle-percentage-${selectedCarrierRate.id}`}
                            name={`marginType-${selectedCarrierRate.id}`}
                            type="radio"
                            className="toggle_icons toggle-right"
                            disabled={isDisable}
                            value={MARGIN_TYPE.PERCENTAGE}
                            checked={
                              formData[selectedCarrierRate.id]
                                ? formData[selectedCarrierRate.id]
                                    .marginType === MARGIN_TYPE.PERCENTAGE
                                : false
                            }
                            onChange={handleMarginType(
                              selectedCarrierRate.id,
                              'marginType'
                            )}
                          />
                          <label
                            htmlFor={`toggle-percentage-${selectedCarrierRate.id}`}
                            className="toggle_button"
                          >
                            %
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-5">
                      <LuEqual size={30} className="text-black mx-auto" />
                    </div>
                    <div className="mt-5 relative">
                      <InputText
                        inputType="text"
                        inputName="finalCharge"
                        className="form_control pl-8"
                        label="Final Quote"
                        disabled={isDisable}
                        value={
                          formData[selectedCarrierRate.id]
                            ? formData[selectedCarrierRate.id].finalCharge
                            : 0
                        }
                        onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                        onBlur={handleInputChange(selectedCarrierRate.id)}
                      />
                      <label className="text-lg text-black leading-6 absolute top-[45px] left-4">
                        $
                      </label>
                    </div>
                  </div>
                  {mode === 'update' ? (
                    <div className="flex gap-5">
                      <ButtonCmp
                        loading={isLoading}
                        disabled={
                          !isValid[selectedCarrierRate.id] ||
                          isDisable ||
                          isLoading
                        }
                        onClick={handleSubmit(selectedCarrierRate.id)}
                        className="btn_primary w-full"
                      >
                        Save
                      </ButtonCmp>
                    </div>
                  ) : (
                    mode !== 'view' && (
                      <ButtonCmp
                        loading={isLoading}
                        disabled={
                          !isValid[selectedCarrierRate.id] ||
                          !selectedCarrierRate.carrierQuoteId ||
                          isLoading
                        }
                        onClick={handleSubmit(selectedCarrierRate.id)}
                        className="btn_primary w-full"
                      >
                        {drawer.button}
                      </ButtonCmp>
                    )
                  )}
                  {!isValidNonQuote[selectedCarrierRate.id] && (
                    <div className="bg-red-100 text-sm text-black p-2 mt-3 rounded-full text-center">
                      A non quoted service has not been specified
                    </div>
                  )}
                  {/* {!selectedCarrierRate.carrierQuoteId && (
                                    <div className="bg-red-100 text-sm text-black p-2 mt-3 rounded-full text-center">
                                        Complete margin info for all selected carriers
                                    </div>
                                )} */}
                </TabsCmp.ItemCmp>
              )
            )}
          </TabsCmp>
        )}
      </Drawer>
    </>
  );
};

export default memo(SpotQuote);
