import { Check } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';

import Drawer from '../../../../../components/Drawer';

const QuoteDetail = ({ quote, isOpen, handleClose }: any) => {
  console.log('quote', quote);
  const { addressDetails, services, dimensions } = quote;
  const [totalWeight, setTotalWeight] = useState<number | string>();
  const [totalUnit, setTotalUnit] = useState<number | string>();

  useEffect(() => {
    if (dimensions) {
      let totalUnitValue = 0;
      let totalWeightValue: number | string = 0;
      dimensions.forEach((val: any) => {
        totalUnitValue += val.handlingUnitNo || 0;
        totalWeightValue += val.totalWeight;
      });
      setTotalWeight(totalWeightValue.toFixed());
      setTotalUnit(totalUnitValue);
    }
  }, [dimensions]);

  const getAddressSecondPart = (address: string) => {
    const commaLength = address.split(', ').length - 1;

    if (commaLength >= 3) {
      const parts = address.split(', ');

      return parts.slice(1).join(', ');
    }

    return address;
  };

  const drawerTitle = (
    <>
      <span className="text-grayLight900 text-base font-semibold">
        Quote ID:&nbsp;
      </span>
      <span className="text-grayLight900 text-base font-semibold ">
        {' '}
        #{addressDetails.code}
      </span>
    </>
  );

  return (
    <>
      <Drawer
        isOpen={isOpen}
        handleClose={handleClose}
        title={drawerTitle}
        classDrawer="max-w-[700px] !w-[412px]"
      >
        <div className="flex-col flex gap-4">
          <h6 className="text-grayLight900 text-sm font-semibold">
            Address Details
          </h6>
          <div className="flex flex-col gap-1.5 w-full justify-between items-start ">
            <div className="flex justify-between text-[11px] w-full">
              <p className="text-gray500  font-normal">Origin</p>
              <span className="text-utilityGray800 font-semibold">
                {getAddressSecondPart(addressDetails.shipperFullAddress)}
              </span>
            </div>
            <div className="flex justify-between text-[11px] w-full">
              <p className="text-gray500  font-normal">Destination</p>
              <span className="text-utilityGray800 font-semibold">
                {getAddressSecondPart(addressDetails.consigneeFullAddress)}
              </span>
            </div>
            <div className="flex justify-between text-[11px] w-full">
              <p className="text-gray500  font-normal">Pickup Date</p>
              <span className="text-utilityGray800 font-semibold">
                {moment(addressDetails.pickupDate).format('DD MMMM, YYYY')}
              </span>
            </div>
            <div className="flex justify-between text-[11px] w-full">
              <p className="text-gray500  font-normal">Total Piece</p>
              <span className="text-utilityGray800 font-semibold">
                {totalUnit}
              </span>
            </div>
            <div className="flex justify-between text-[11px] w-full">
              <p className="text-gray500  font-normal">Total Weight (lbs)</p>
              <span className="text-utilityGray8	00 font-semibold">
                {totalWeight}
              </span>
            </div>
          </div>
          <h6 className="text-grayLight900 text-sm font-semibold">Dimension</h6>
          {dimensions.map((val: any, index: any) => (
            <>
              <div
                className={`flex flex-col gap-1.5 w-full justify-between items-start pb-4 border-b border-borderPrimary ${
                  index + 1 < dimensions.length ? 'border-b' : ''
                }`}
              >
                <div className="flex justify-between text-[11px] w-full">
                  <p className="text-gray500  font-normal">Handling unit </p>
                  <span className="text-utilityGray800 font-semibold">
                    {val.handlingUnitName}
                  </span>
                </div>
                <div className="flex justify-between text-[11px] w-full">
                  <p className="text-gray500  font-normal">№ of HU </p>
                  <span className="text-utilityGray800 font-semibold">
                    {val.handlingUnitNo}
                  </span>
                </div>
                <div className="flex justify-between text-[11px] w-full">
                  <p className="text-gray500  font-normal">
                    Weight per unit (lbs){' '}
                  </p>
                  <span className="text-utilityGray800 font-semibold">
                    {val.weight}
                  </span>
                </div>
                <div className="flex justify-between text-[11px] w-full">
                  <p className="text-gray500  font-normal">
                    Dimensions (inches)
                  </p>
                  <span className="text-utilityGray800 font-semibold">
                    {`${val.itemLength}L x ${val.width}W x ${val.height}`}H
                  </span>
                </div>
                <div className="flex justify-between text-[11px] w-full">
                  <p className="text-gray500  font-normal">
                    Total weight (lbs)
                  </p>
                  <span className="text-utilityGray800 font-semibold">
                    {val.totalWeight}
                  </span>
                </div>
              </div>
            </>
          ))}
          <h6 className="text-grayLight900 text-sm font-semibold">
            Selected services
          </h6>
          <div className="flex-col gap-4 flex">
            {services.map((val: any) => (
              <div key={val?.serviceName} className=" items-center flex gap-1">
                <Check className="w-4 h-4 text-primary" />
                <div className="text-grayLight600 text-xs font-medium">
                  {val.serviceName}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </>
    // <CommonModal handleClose={handleClose} title={`Quote ID: #SQ-B-101662`} size={'max-w-2xl'}>
    //     <div className="p-6 pt-0 mt-3"></div>
    // </CommonModal>
  );
};

export default memo(QuoteDetail);
