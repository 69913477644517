import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { markNotificationAsRead } from 'src/services/NotificationService';

import { getEntityCount } from '../services/AuthService';

import { AuthContext } from './AuthContext';

export const EntityCountsContext = createContext<any>(null);

export const EntityCountsProvider = (props: any) => {
  const { children } = props;
  const [claimsAndDisputesCount, setClaimsAndDisputesCount] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [custOnBoardCount, setCustOnBoardCount] = useState(0);
  const [loader, setLoader] = useState(true);

  const { isAuthenticated } = useContext(AuthContext);

  const fetchCounts = async () => {
    if (!isAuthenticated) {
      return;
    }

    setLoader(true);
    getEntityCount()
      .then((response: any) => {
        if (response.data) {
          setClaimsAndDisputesCount(response.data.totalCount || 0);
          setNotificationsCount(response.data.notificationsCount || 0);
          setCustOnBoardCount(
            response.data.customerOnBoardNotificationCount || 0
          );
        }
      })
      .catch(console.error)
      .finally(() => {
        setLoader(false);
      });
  };

  const handleNotificationRead = (param: any) => {
    markNotificationAsRead(param)
      .then((response: any) => {
        if (response.message) {
          fetchCounts();
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    fetchCounts();
  }, [isAuthenticated]);

  const reloadCounts = () => {
    fetchCounts();
  };

  return (
    <EntityCountsContext.Provider
      value={{
        claimsAndDisputesCount,
        notificationsCount,
        custOnBoardCount,
        loader,
        reloadCounts,
        handleNotificationRead,
      }}
    >
      {children}
    </EntityCountsContext.Provider>
  );
};

EntityCountsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
