import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonModal from 'src/components/CommonModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
// import TabButton from 'src/components/TabButton';
import { CURRENCY } from 'src/constants/common';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

const FilterModal = ({
  teamListOptions,
  userList,
  handleClose,
  params,
  // rates,
  currency,
  // setCurrency,
  setParams,
  setFilterCount,
}: any) => {
  const uiState = useSelector((state: any) => state.MobileView);
  const [teamId, setTeamId] = useState(params?.team ?? 'all');
  const [memberId, setMemberId] = useState(params?.member ?? 'all');
  const [teamMember, setTeamMember] = useState(userList);

  // const filterArr: any = [
  //   {
  //     value: CURRENCY.CAD,
  //     name: 'CAD',
  //     tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
  //   },
  //   {
  //     value: CURRENCY.USD,
  //     name: 'USD',
  //     tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
  //   },
  // ];

  const handleSave = () => {
    let fCount = 0;

    if (
      uiState.currentBreakpoint === 'XLM' ||
      uiState.currentBreakpoint === '2XL'
    ) {
      if (currency !== CURRENCY.CAD) fCount += 1;
    } else {
      if (currency !== CURRENCY.CAD) fCount += 1;
      if (teamId !== 'all') fCount += 1;
      if (memberId !== 'all') fCount += 1;
    }
    // console.log('fCount :>> ', fCount);
    setFilterCount(fCount);
    setParams((old: any) => ({
      ...old,
      page: 1,
      segment: '',
      limit: 50,
      team: teamId,
      member: memberId,
    }));
    handleClose();
  };

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (teamId === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo: any) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo: any) => tlo.value === teamId
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setTeamMember(updatedListAllMembers);
    }
  }, [teamId, teamListOptions]);

  return (
    <CommonModal
      size={'smd:max-w-[490px] max-w-[336px] overflow-unset'}
      handleClose={handleClose}
      title="Filters"
      primaryBtnText="Save"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnOnClick={handleSave}
      titleDesc="Please change as per your requirement."
      isOverflow={false}
    >
      <div className="p-5">
        <div className="rounded-lg border border-utilityGray200 p-3 flex flex-col gap-3">
          {/* <div className="flex flex-wrap items-center">
            <label className={`form_label mb-1.5 w-24`}>Currency</label>
            <TabButton
              tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 flex-1"
              parentClassName="w-full currency-tab order-currency-tab"
              childrenClassName="test"
              activeClassName="text-grayLight900 bg-utilityGray100"
              className=""
              tabArray={filterArr}
              isTooltip={true}
              handleOnClick={(e: any) => {
                setCurrency(e.target.dataset.value);
              }}
              isActive={currency}
            />
          </div> */}
          <div className="smd:flex flex-wrap items-center  xlm:hidden">
            <label className={`form_label smd:mb-0 mb-1.5 w-24`}>Teams</label>
            <SelectBox
              name="teamListGroup"
              id="teamListGroup"
              className="form_control"
              size="sm"
              placeholder="All Teams"
              noOptionMessage="No Teams Found"
              parentClassName="smd:flex-1"
              isClearable={true}
              isSearchable={true}
              options={teamListOptions}
              onChangeFunc={(event: any) => {
                setTeamId(event?.value ?? 'all');
                setMemberId('all');
              }}
              value={
                teamId
                  ? teamListOptions.filter((val: any) => teamId === val.value)
                  : null
              }
            />
          </div>
          <div className="smd:flex flex-wrap items-center xlm:hidden">
            <label className={`form_label smd:mb-0 mb-1.5 w-24`}>Users</label>
            <SelectBox
              name="userListGroup"
              id="userListGroup"
              size="sm"
              placeholder="All Users"
              className="form_control"
              isClearable={true}
              isSearchable={true}
              options={teamMember}
              parentClassName="smd:flex-1"
              noOptionMessage="No Users Found"
              onChangeFunc={(event: any) => setMemberId(event?.value ?? 'all')}
              value={
                memberId
                  ? teamMember.filter((user: any) => memberId === user.value)
                  : null
              }
            />
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default FilterModal;
