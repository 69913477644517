import { Edit05, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import { getFormattedNumber, getShortName } from 'src/utils/CommonFunctions';

interface IProps {
  data: any;
  handleEditAllocationHistory: any;
  handleDeleteAllocationHistory: any;
}

const AllocationHistoryRow = ({
  data,
  handleEditAllocationHistory,
  handleDeleteAllocationHistory,
}: IProps) => (
  <>
    <td className="px-5 py-4 w-48 max-w-48 min-w-48">
      <div className=" flex items-center gap-3">
        {data?.image && data?.imageUrl ? (
          <img
            src={`${data?.imageUrl}${data?.image}`}
            className="w-8 h-8 rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
          />
        ) : (
          <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
            {getShortName(data?.salesRepFullName)}
          </div>
        )}
        <TooltipCmp message={data?.salesRepFullName} parentClassName="">
          <div className="max-w-32 truncate font-semibold">
            {data?.salesRepFullName}
          </div>
        </TooltipCmp>
      </div>
    </td>
    <td className="px-5 py-4 w-36 max-w-36 min-w-36">
      <span>{moment(data.startDate).format('MMM D, YYYY')}</span>
    </td>
    <td className="px-5 py-4 w-36 max-w-36 min-w-36">
      <span>
        {data.endDate ? moment(data.endDate).format('MMM D, YYYY') : 'Present'}
      </span>
    </td>
    <td className="px-5 py-4 max-w-40 w-40">
      <span>{data?.orderCount ?? 0}</span>
    </td>
    <td className="px-5 py-4 max-w-40 w-40">
      <span>{getFormattedNumber(parseInt(data?.totalMargin ?? 0), false)}</span>
    </td>
    <td className="px-5 py-4 w-28 max-w-28 min-w-28">
      <span>{getFormattedNumber(data?.averageMarginPercentage ?? 0)}%</span>
    </td>
    <td className="px-5 py-4 !pl-2 w-36 max-w-36 min-w-36">
      <span>{getFormattedNumber(data?.averageOrderValue ?? 0)}$</span>
    </td>

    <td className="px-5 py-4 pl-2 max-w-20 w-20 min-w-20">
      <div className="justify-center items-center gap-2 flex ">
        <Edit05
          className="w-4 h-3.5 relative cursor-pointer"
          onClick={() => handleEditAllocationHistory(data)}
        />
        <Trash01
          className="w-4 h-4 relative cursor-pointer"
          onClick={() => handleDeleteAllocationHistory(data)}
        />
      </div>
    </td>
  </>
);

export default AllocationHistoryRow;
