import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { CLAIM_DISPUTE_STATUS } from 'src/app/Claims&Disputes/ClaimsDisputes.const';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

const CustomerDetailClaimsRaw = ({ onRowClick, data }: any) => {
  console.log();

  return (
    <>
      <td
        className="px-5 py-4 min-w-52 w-52 max-w-52"
        onClick={() => onRowClick(data)}
      >
        <div className="flex items-center">
          {data.name &&
            (data.image ? (
              <div className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary flex-none relative">
                <img
                  className="w-full h-full rounded-full border border-black/[0.08] flex-none"
                  src={data.imageUrl + data.image}
                  alt={data.name}
                  title={data.name}
                />
                {data.hasUnreadNotification ? (
                  <span
                    className={`border-[1.5px] border-white bg-primary500 rounded-full w-2.5 h-2.5 absolute top-0 right-0`}
                  ></span>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary flex-none relative">
                <div className="w-full h-full rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white">
                  {getShortName(`${data.name}`)}
                </div>
                {data.hasUnreadNotification ? (
                  <span
                    className={`border-[1.5px] border-white bg-primary500 rounded-full w-2.5 h-2.5 absolute top-0 right-0`}
                  ></span>
                ) : (
                  ''
                )}
              </div>
            ))}

          {!data.name && !data.image && data.hasUnreadNotification ? (
            <p className="bg-primary500 w-2 h-2 rounded flex-none mt-3"></p>
          ) : (
            ''
          )}

          <div className="pl-2 text-xs font-medium max-w-28">
            <TooltipCmp
              message={data.name && data.name.length > 18 ? data.name : null}
            >
              <div className="text-grayLight900 font-medium leading-tight truncate">{`${
                data.name ?? '-'
              }`}</div>
            </TooltipCmp>
            <div className="text-grayLight600 font-normal leading-tight truncate">
              #{data.claimID}
            </div>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 " onClick={() => onRowClick(data)}>
        <BadgeCmp
          style="modern"
          type={
            data.status === CLAIM_DISPUTE_STATUS.NEW
              ? 'success'
              : data.status === CLAIM_DISPUTE_STATUS.RESOLVED
              ? 'primary'
              : data.status === CLAIM_DISPUTE_STATUS.OPENED
              ? 'lightblue'
              : data.status === CLAIM_DISPUTE_STATUS.CLOSED
              ? 'error'
              : 'success'
          }
        >
          <span data-test-id="wal-QuotingDashboard-status" className="text-xs">
            {data.status}
          </span>
        </BadgeCmp>
      </td>
      <td
        className="px-5 py-4 min-w-[18%] max-w-[18%] w-[18%]"
        onClick={() => onRowClick(data)}
      >
        <a
          href={`https://wal.roserocket.com/#/ops/orders/${data.roserocketOrderId}`}
          className="text-primary700 font-normal leading-tight truncate underline"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          #{data.orderID}
        </a>
      </td>

      <td
        className="px-5 py-4 min-w-52 w-52 max-w-52"
        onClick={() => onRowClick(data)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data?.createdAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(data?.createdAt, `MMM Do - YYYY`, true, true)
                )
              : '-',
          }}
        />
      </td>
      {/* <td
        className="px-5 py-4 min-w-36 w-36 max-w-36"
        data-test-id="wal-QuotingDashboard-totalUnits"
        onClick={() => onRowClick(data)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.orderCreatedAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.orderCreatedAt,
                    `MMM Do - YYYY`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        />
      </td> */}
      {/* <td
        className={`px-5 py-4 min-w-[9%] max-w-[9%] w-[9%]`}
        onClick={() => onRowClick(data)}
      >
        ${data.totalPrice}
      </td> */}
      <td
        className={`px-5 py-4 min-w-[17%] max-w-[17%] w-[17%]`}
        onClick={() => onRowClick(data)}
      >
        ${data.claimAmount}
      </td>

      {/* <td
        className="px-5 py-4 min-w-[8%] max-w-[8%] w-[8%]"
        onClick={() => onRowClick(data)}
      >
        {data?.createdAt ? (
          <BadgeCmp style="pill" type={type} mainClassName="rounded-md">
            <div className="flex items-center gap-1">
              <Clock className={`w-3 h-3 mt-[1px] ${clockType}`} />
              {displayTimeDifference(
                moment
                  .utc(data?.createdAt)
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')
              )}
            </div>
          </BadgeCmp>
        ) : (
          '-'
        )}
      </td> */}
    </>
  );
};

export default CustomerDetailClaimsRaw;
