import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listClaim = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.CLAIMS.LIST, {
    params,
    signal,
  });

  return response;
};

export const updateClaimStatus = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CLAIMS.CLAIM_UPDATE_STATUS,
    data
  );

  return response;
};

export const modifyClaimAmount = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CLAIMS.MODIFY_CLAIM_AMOUNT,
    data
  );

  return response;
};

export const getClaimData = async (id: number) => {
  const response = await axiosInterceptor.get(
    `${API.CLAIMS.CLAIM_DETAILS}/${id}`
  );

  return response;
};

export const getClaimDisputeFileList = async (params: any) => {
  const response = await axiosInterceptor.get(
    `${API.CLAIMS.CLAIM_FILE_UPLOAD.LIST}`,
    {
      params,
    }
  );

  return response;
};

export const addClaimAttachmentFile = async (id: number, data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CLAIMS.CLAIM_FILE_UPLOAD.CLAIM_ADD_FILE}/${id}`,
    data
  );

  return response;
};

export const addDisputeAttachmentFile = async (id: number, data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CLAIMS.CLAIM_FILE_UPLOAD.DISPUTE_ADD_FILE}/${id}`,
    data
  );

  return response;
};

export const deleteClaimDisputeAttachmentFile = async (id: number) => {
  const response = await axiosInterceptor.delete(
    `${API.CLAIMS.CLAIM_FILE_UPLOAD.DELETE_FILE}/${id}`
  );

  return response;
};
