import { SearchLg, Zap } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import {
  customerCreditOrdersList,
  getCustomerCreditDetails,
} from 'src/services/CustomerService';
import { getFormattedNumber } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import logomark from '../../../assets/img/Logomark.svg';

import CreditIndexChart from './CreditIndexChart';
import CreditTabOrderLoadingRaw from './CreditTabOrderLoadingRaw';
import CreditTabOrderRaw from './CreditTabOrderRaw';
import ReqCreditModal from './ReqCreditModal';

const initParams: any = {
  sortType: 'desc',
  sortField: 'orderCreatedAt',
  page: 1,
  limit: 25,
  search: '',
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

interface IProps {
  customerId: string;
}

const CustomerDetailCreditTab = ({ customerId }: IProps) => {
  const [isShowReqCreditModal, setIsShowReqCreditModal] = useState(false);
  const [params, setParams] = useState(initParams);
  const [orders, setOrders] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [isCustomerCreditOrdersLoading, setIsCustomerCreditOrdersLoading] =
    useState(true);
  const [isCustomerCreditDetailLoading, setIsCustomerCreditDetailLoading] =
    useState(true);
  const [customerCreditDetail, setCustomerCreditDetail] =
    useState<any>(undefined);

  useEffect(() => {
    setIsCustomerCreditOrdersLoading(true);
    setOrders([]);

    const creditOrdersApiController = new AbortController();
    const creditOrdersApiSignal = creditOrdersApiController.signal;

    customerCreditOrdersList(customerId, params, creditOrdersApiSignal)
      .then((response: any) => {
        setOrders(response.data ?? []);
        setTotal(response.total ?? 0);
        setIsCustomerCreditOrdersLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerCreditOrdersLoading(false);
      });

    return () => {
      creditOrdersApiController.abort();
    };
  }, [params]);

  useEffect(() => {
    getCustomerCreditDetails(customerId)
      .then((response: any) => {
        console.log('getCustomerCreditDetails ', response?.data);
        let cusCreditDetails = { ...response?.data, creditUsedPercentage: 0 };

        if (
          customerCreditDetail?.totalCreditLimit &&
          cusCreditDetails?.currentAccountReceivable
        ) {
          cusCreditDetails.creditUsedPercentage =
            (cusCreditDetails?.currentAccountReceivable * 100) /
            customerCreditDetail?.totalCreditLimit;
        }
        setCustomerCreditDetail({ ...cusCreditDetails });
      })
      .catch((e: any) => {
        console.log(e);
        WalToast.error(
          e?.response?.data?.message ??
            'Something went wrong fetching customer credit details.'
        );
      })
      .finally(() => {
        setIsCustomerCreditDetailLoading(false);
      });
  }, []);

  const headCells = useMemo(
    () => [
      {
        id: 'fullId',
        name: 'Order ID',
        sortable: true,
      },
      {
        id: 'orderStatus',
        name: 'Status',
        sortable: true,
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        sortable: true,
      },
      {
        id: 'combinedCost',
        name: 'Total Cost',
        sortable: true,
      },
      {
        id: 'combinedMargin',
        name: 'Margin',
        sortable: true,
      },
      {
        id: 'combinedRevenue',
        name: 'Total Price',
        sortable: true,
      },
      {
        id: 'orderCommissionees',
        name: 'Sales Representative',
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <ul className="flex flex-wrap mt-8">
        <li className="flex-1 mr-2.5 rounded-lg border border-utilityGray200 shadow-md">
          <div className="p-3 pb-6">
            <div className="flex justify-between items-center">
              <h6 className="grayLight900 text-sm font-semibold">
                Accounts Receivable
              </h6>
              <div>
                <a
                  className={`text-primary text-xs font-semibold flex items-center ${
                    customerCreditDetail?.totalCreditLimit
                      ? 'cursor-pointer'
                      : ''
                  }`}
                  onClick={() =>
                    customerCreditDetail?.totalCreditLimit
                      ? setIsShowReqCreditModal(true)
                      : false
                  }
                >
                  <span className="pr-0.5">Request Credit Increase</span>
                  <Zap className="w-4 h-4" />
                </a>
              </div>
            </div>
            <div className="flex justify-between mb-1 mt-6">
              <p className="text-grayLight900 text-sm font-medium">$0</p>
              <p
                className={`text-grayLight900 text-sm font-medium ${
                  isCustomerCreditDetailLoading ? 'custom-loading' : ''
                }`}
              >
                $
                {getFormattedNumber(
                  customerCreditDetail?.totalCreditLimit ?? 0
                )}
              </p>
            </div>
            <div className="progress-range-container">
              <div
                className="progress-range-bar"
                style={{
                  width: `${customerCreditDetail?.creditUsedPercentage ?? 0}%`,
                }}
              >
                <div className="progress-range-thumb left-[100%]">
                  {customerCreditDetail?.creditUsedPercentage ? (
                    <div className="absolute bottom-full left-1/2 -translate-x-1/2 px-3 py-2 rounded-lg border border-utilityGray200 bg-white tooltip-custom-arrow text-xs font-semibold mb-3.5">
                      {customerCreditDetail?.creditUsedPercentage}%
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
          <ul className="border-t border-utilityGray200 [&>li:not(:last-child)]:border-r [&>li:not(:last-child)]:border-utilityGray200 flex">
            <li className="px-3 py-4 w-1/3">
              <p className="text-grayLight600 text-sm font-normal mb-0.5">
                Current Account Receivable
              </p>
              <span
                className={`textgrayLight900 text-sm font-medium ${
                  isCustomerCreditDetailLoading ? 'custom-loading' : ''
                }`}
              >
                $
                {getFormattedNumber(
                  customerCreditDetail?.currentAccountReceivable ?? 0
                )}
              </span>
            </li>

            <li className="px-3 py-4 w-1/3">
              <p className="text-grayLight600 text-sm font-normal mb-0.5">
                Credit Utilization
              </p>
              <span
                className={`textgrayLight900 text-sm font-medium ${
                  isCustomerCreditDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {customerCreditDetail?.creditUsedPercentage ?? 0}%
              </span>
            </li>
            <li className="px-3 py-4 w-1/3">
              <p className="text-grayLight600 text-sm font-normal mb-0.5">
                Total Credit Limit
              </p>
              <span
                className={`textgrayLight900 text-sm font-medium ${
                  isCustomerCreditDetailLoading ? 'custom-loading' : ''
                }`}
              >
                $
                {getFormattedNumber(
                  customerCreditDetail?.totalCreditLimit ?? 0
                )}
              </span>
            </li>
          </ul>
        </li>
        <li className="hover-text-wrap w-[360px] rounded-lg border border-utilityGray200 shadow-md p-3 ]">
          <div className="flex justify-between items-center mb-2 z-[1] relative">
            <h6 className="grayLight900 text-sm font-semibold">Credit Index</h6>
            <div className="hover-text overflow-hidden border border-utilityGray200 rounded cursor-pointer flex items-center ">
              <img src={logomark} />
              <span className="font-semibold text-[#0d442e] text-xs leading-[20px] transition-all duration-[0.5s]  w-0 opacity-0 creditsafe-text text-nowrap ">
                Powered By Creditsafe
              </span>
            </div>
          </div>
          {isCustomerCreditDetailLoading ? (
            <div className="custom-loading rounded-2xl">
              <CreditIndexChart score={100} lastUpdated="18 jun, 2024" />
            </div>
          ) : (
            <div className="z-[0] relative">
              <CreditIndexChart score={100} lastUpdated="18 jun, 2024" />
            </div>
          )}
        </li>
      </ul>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col mt-6 overflow-hidden">
        <div className="w-full flex-col flex">
          <div className="table-top-header flex-wrap ">
            <div className="table-left-wrap ">
              <div className="table-title-wrap">
                <h5 className="table-title">Active Orders</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={`${
                    isCustomerCreditOrdersLoading ? 'custom-loading' : ''
                  }`}
                >
                  {total} {total > 1 ? 'Orders' : 'Order'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View and manage your sales representative
              </p>
            </div>
            <div className="table-right-wrap max-mdm:w-full">
              <InputText
                inputName="searchCustomer"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={params.search}
                isClearable={true}
                parentClassName="table-searchInput max-mdm:flex-1"
                inputType="text"
                onChangeFunc={(e) =>
                  setParams((old: any) => ({
                    ...old,
                    ...{ search: e.target.value, page: 1 },
                  }))
                }
              />
              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArray}
                  isSearchable={false}
                  value={recordsPerPageArray.find(
                    (val: any) => val.value === params.limit
                  )}
                  onChangeFunc={(event: any) => {
                    setParams((old: any) => ({
                      ...old,
                      limit: event.value,
                      page: 1,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full bg-white overflow-x-auto custom-scrollbar scrollbar-hide text-nowrap">
            <TableCmp
              params={params}
              setParams={setParams}
              tableDataArr={orders}
              headCells={headCells}
              TableLoaderRowCmp={CreditTabOrderLoadingRaw}
              TableRowCmp={CreditTabOrderRaw}
              numberOfSkeletonRows={15}
              isTableDataLoading={isCustomerCreditOrdersLoading}
            />
          </div>
          {!isCustomerCreditOrdersLoading && !orders?.length && (
            <NotFoundUI
              title="No credit orders found"
              desc="There are no credit orders found."
              containerClassName="min-h-[160px] !h-auto"
            />
          )}
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={total}
            currentPage={params.page}
            handlePagination={(page: number) => {
              setParams((old: any) => ({ ...old, page }));
            }}
          />
        </div>
      </div>

      {isShowReqCreditModal && (
        <ReqCreditModal handleClose={() => setIsShowReqCreditModal(false)} />
      )}
    </>
  );
};

export default CustomerDetailCreditTab;
