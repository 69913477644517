import { Edit05, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import ButtonCmp from 'src/components/ButtonCmp';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import Map from 'src/components/Map/Map';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';

const ShipperAddress = ({
  formData,
  setFormData,
  initShipperAddress,
  initValidAddressForm,
  setValidAddressForm,
  shipperOptions,
  validAddressForm,
  handleSelectChange,
  handleActionType,
  saveShipperDetails,
  isShipperBtnDisable,
  handleAutocompleteChange,
  handleInputChange,
  shipperAddress,
  isValidAddress,
  setIsValidAddress,
  status,
}: any) => {
  console.log('shipperAddress', shipperAddress);

  const addItems = () => {
    setFormData((oldData: any) => ({
      ...oldData, // Spread the existing data to avoid losing other fields
      shipper: [...oldData.shipper, initShipperAddress], // Append the new shipper address to the existing array
    }));
    const newValidForm = validAddressForm.shipper;
    newValidForm.push(initValidAddressForm.shipper[0]);
    console.log(
      'validFormNew',
      newValidForm,
      ' ',
      initValidAddressForm.shipper
    );
    setValidAddressForm({ ...validAddressForm, ...{ shipper: newValidForm } });

    const updatedValidAddress = [...isValidAddress];
    updatedValidAddress.push({ shipperAddress: true });
    setIsValidAddress(updatedValidAddress);
  };

  const removeItems = (id: any) => () => {
    const form = formData.shipper.filter(
      (formDataField: any, index: number) => {
        console.log(formDataField);

        return index !== id;
      }
    );
    setFormData((oldData: any) => ({
      ...oldData, // Spread the existing data to avoid losing other fields
      shipper: form, // Append the new shipper address to the existing array
    }));
  };

  return (
    <>
      <div className="w-full xl:p-5 sm:p-4 pt-2 flex flex-wrap xl:gap-[40px] gap-6 sm:bg-white rounded-xl sm:border sm:border-utilityGray200 shadow-sm">
        {/* <div className="w-full xl:p-5 sm:p-4 pt-2 flex flex-wrap xl:gap-[51px] gap-6 sm:bg-white rounded-xl sm:border sm:border-utilityGray200 shadow-sm"> */}
        <div className="w-full flex justify-between">
          <div className="lg:w-1/2 w-full p-0">
            <h6 className="text-textSecondary text-sm font-bold">
              Shipper Address
            </h6>
            <p className=" text-grayLight600 text-xs font-normal">
              you can add details here
            </p>
          </div>
          <div className="flex justify-end">
            <ButtonCmp
              type="submit"
              className="btn_primary"
              onClick={addItems}
              icon={<Plus className="text-white w-4 h-4" />}
            >
              Add Items
            </ButtonCmp>
          </div>
        </div>
        {formData.shipper.map((form: any, index: number) => (
          <>
            <div
              className="w-full flex flex-col rounded-xl border sm:border-utilityGray200 shadow-sm "
              key={index}
            >
              {formData.shipper.length > 1 && (
                <div className="flex justify-end items-end p-2">
                  <div className="delete cursor-pointer p-[5px] w-[3%] bg-danger50 rounded-[4px] flex justify-center items-center text-danger">
                    <Trash01 className="w-4 h-4" onClick={removeItems(index)} />
                  </div>
                </div>
              )}
              <div
                className={`flex ${
                  formData.shipper.length > 1 ? 'px-5 pb-5 ' : 'p-5'
                }`}
              >
                <div className="lg:w-1/2 w-full">
                  <div className="mb-5 relative">
                    <SelectBox
                      isClearable
                      isSearchable
                      name="shipperId"
                      label="Shippers"
                      className={`form_control ${
                        !validAddressForm.shipper[index].shipperId
                          ? 'border border-red-500 rounded-[10px] mb-1 border-solid '
                          : ''
                      }`}
                      labelClassName="form_label mb-1.5 block"
                      classComp=""
                      options={shipperOptions}
                      onChangeFunc={handleSelectChange('shipperId', index)}
                      value={shipperOptions.filter(
                        (item: any) => item.value === form.shipperId
                      )}
                      errorText={
                        !validAddressForm.shipper[index].shipperId
                          ? 'Shipper is required'
                          : null
                      }
                    />
                    <div className="absolute right-0 top-0 flex">
                      {form.shipperId && (
                        <button
                          onClick={handleActionType('shipper', 'delete', index)}
                          type="button"
                          className="text-red-700 text-xs flex font-bold mr-2"
                        >
                          <TooltipCmp message="Delete Shipper">
                            {/* <BiSolidTrash size={20} /> */}
                            <Trash01 className="h-4 w-4" />
                          </TooltipCmp>
                        </button>
                      )}
                      <button
                        onClick={saveShipperDetails('shipper', index)}
                        type="button"
                        className={`text-primary700 text-xs flex font-semibold gap-2.5 ${
                          isShipperBtnDisable && 'text-opacity-50'
                        }`}
                        disabled={isShipperBtnDisable}
                      >
                        {form.shipperId ? (
                          <Edit05 className="w-4 h-4" />
                        ) : (
                          <Plus className="w-4 h-4" />
                        )}
                        {form.shipperId ? 'Update' : 'Save'} Shipper Details
                      </button>
                    </div>
                  </div>
                  <div className="mb-5">
                    <InputText
                      inputName="shipperCompanyName"
                      placeholder="Enter company name"
                      className="form_control"
                      label="Company name"
                      labelClassName="mb-1.5 block"
                      value={form.shipperCompanyName}
                      errorText={
                        !validAddressForm.shipper[index].shipperCompanyName
                          ? 'Company name is required'
                          : null
                      }
                      onChangeFunc={(e: any) => handleInputChange(e, index)}
                    />
                  </div>
                  <div className="relative">
                    {status && (
                      <AutoCompleteGoogle
                        inputName="shipperAddress"
                        placeholder="Enter address"
                        className={`form_control truncate ${
                          !validAddressForm.shipper[index].fullAddress
                            ? 'border border-red-500 mb-1 border-solid'
                            : ''
                        }`}
                        label="Address"
                        labelClassName="mb-1.5 block"
                        onChangeFunc={handleAutocompleteChange(
                          'shipperAddress',
                          index
                        )}
                        onBlur={handleAutocompleteChange(
                          'shipperAddress',
                          index
                        )}
                        errorText={
                          !validAddressForm.shipper[index].fullAddress
                            ? 'Address is required'
                            : null
                        }
                        value={
                          form.shipperAddress.fullAddress !== ''
                            ? form.shipperAddress.fullAddress
                            : '' || shipperAddress[index]
                            ? shipperAddress[index]
                            : ''
                        }
                      />
                    )}
                    <button
                      onClick={handleActionType(
                        'shipper',
                        form.shipperAddress.fullAddress
                          ? 'addressUpdate'
                          : 'addressCreate',
                        index
                      )}
                      type="button"
                      className={`text-primary700 text-xs flex font-semibold gap-2.5 absolute left-[60px] top-0`}
                      disabled={false}
                    >
                      {form.shipperAddress.fullAddress ? 'Change' : 'Select'}{' '}
                      Address
                    </button>
                  </div>
                  {!isValidAddress[index].shipperAddress && (
                    <ErrorMsg errorText="Please choose a valid address in the country, city, and postal code." />
                  )}
                </div>
                <div className="lg:flex-1 xl:ml-[30px] w-full ">
                  {form.shipperAddress &&
                    form.shipperAddress.fullAddress !== '' && (
                      <Map
                        className="rounded-[20px] bg-white"
                        address={form.shipperAddress}
                        id={'shipperMap'}
                        height={200}
                        onReverseAddressFunc={handleAutocompleteChange(
                          'shipperAddress',
                          index
                        )}
                      />
                    )}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default ShipperAddress;
