export const STEPS = [
  {
    name: 'Address Details',
    step: 1,
  },
  {
    name: 'Dimensions',
    step: 2,
  },
  {
    name: 'Finish',
    step: 3,
  },
];
