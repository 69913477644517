import { Edit05, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName, onError } from 'src/utils/CommonFunctions';

import TeamIcon1 from '../../../assets/img/Avatar7.png';

const CustomerGroupRow = ({
  data,
  handleGroupEdit,
  handleGroupDelete,
}: any) => {
  const firstFourCustomers = data?.customers.slice(0, 4);
  const restCustomerCount = data?.customers.slice(4)?.length;

  return (
    <>
      <td className="px-5 py-4 min-w-[17rem] ">
        <div className="flex items-center gap-3">
          {data.image ? (
            <img
              className="w-8 h-8  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
              src={data.imageUrl + data.image}
              alt=""
              title=""
              onError={onError}
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
              {getShortName(`${data?.groupName}`)}
            </div>
          )}

          {data?.groupName.length > 30 ? (
            <div className="flex items-center gap-1">
              <TooltipCmp
                message={data?.groupName}
                parentClassName="xs:w-80 w-64"
              >
                <span className="truncate block max-w-48 text-grayLight900 font-semibold leading-5">
                  {data?.groupName}
                </span>
              </TooltipCmp>
            </div>
          ) : (
            <div className="flex items-center gap-1">
              <span className="truncate block max-w-48 text-grayLight900 font-semibold leading-5">
                {data?.groupName}
              </span>
            </div>
          )}
        </div>
      </td>
      <td className="px-5 py-3 min-w-[270px]">
        <div className="flex [&>div:not(:first-child)]:border-white [&>div:not(:first-child)]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
          {firstFourCustomers.map((groupCus: any) => (
            <>
              {groupCus.image && groupCus?.imageUrl ? (
                <div className="rounded-full bg-white">
                  <img
                    src={`${groupCus?.imageUrl}${groupCus.image}`}
                    className="w-8 h-8 border-gray-200 border-[1px] flex-none rounded-full "
                  />
                </div>
              ) : (
                <div className="rounded-full bg-white">
                  <img
                    src={TeamIcon1}
                    className="w-8 h-8 border-gray-200 border-[1px] flex-none rounded-full "
                  />
                </div>
              )}
            </>
          ))}
          {restCustomerCount > 0 ? (
            <div className="rounded-full bg-utilityGray100 ">
              <p className="text-grayLight600 text-sm font-semibold w-8 h-8 flex items-center justify-center flex-none">
                +{restCustomerCount}
              </p>
            </div>
          ) : (
            false
          )}
        </div>
      </td>
      <td className="px-5 py-4 w-40 max-w-40 min-w-40">
        <div className="justify-end items-center gap-2 flex ">
          <Edit05
            className="w-3.5 h-3.5 relative cursor-pointer"
            onClick={() => handleGroupEdit(data)}
          />
          <Trash01
            className="w-4 h-4 relative cursor-pointer"
            onClick={() => handleGroupDelete(data)}
          />
        </div>
      </td>
    </>
  );
};

export default CustomerGroupRow;
