import React from 'react';

const MonthlyCommissionReportLoadingRow = () => (
  <>
    <td className="px-5 py-4 min-w-60 w-60 max-w-60">
      <div className="flex items-center custom-loading">
        <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
          WA
        </div>

        <div className="pl-2 max-w-32">
          <h6 className="text-grayLight900 font-semibold leading-5 truncate">
            Dev User
          </h6>
          <p className="text-grayLight600 font-normal leading-tight truncate">
            Administrator
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">55649.16</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">265.00</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">210</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">27.44</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">100</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">100</span>
    </td>
    <td className="px-5 py-4 ">
      <div className="border-2 border-primary text-primary font-medium rounded-full inline-flex p-1 custom-loading">
        100
      </div>
    </td>
  </>
);

export default MonthlyCommissionReportLoadingRow;
