import { yupResolver } from '@hookform/resolvers/yup';
import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import * as yup from 'yup';

import ThreeJsScene from './ThreeJsScene';

const VisualizeUnit = ({ handleClose, weightMeasurement }: any) => {
  const validationSchema = yup.object().shape({
    unit: yup.string().required('Unit is required.'),
    total_weight: yup.string().required('Total_weight is required.'),
    length: yup.string().required('Length is required.'),
    height: yup.string().required('Height is required.'),
    width: yup.string().required('Width is required.'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    console.log('data', data);
  };

  const unitArr: any[] = [
    {
      label: 'Inches',
      value: 'inches',
    },
  ];

  return (
    <CommonModal
      headerIcon={<PackagePlus />}
      size={'max-w-[954px]'}
      handleClose={handleClose}
      title="Visualize Unit"
      titleDesc="Drag the map so the pin matches the exact location of your business location."
      primaryBtnText="Save Changes"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnOnClick={handleSubmit}
    >
      <div className="p-5">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex md:flex-row flex-col items-center w-full"
        >
          <div className="flex flex-col gap-5 md:flex-1 md:mr-5 w-full md:mb-0 mb-5">
            <Controller
              name="unit"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="unit"
                  label="Select Unit"
                  className={`form_control`}
                  labelClassName="form_label block mb-1.5"
                  id="unit"
                  size="sm"
                  options={unitArr}
                  onChangeFunc={onChange}
                  isSearchable={false}
                  value={unitArr.find((val: any) => val.value === value)}
                  errorText={errors.unit ? errors.unit.message : null}
                />
              )}
            />

            <Controller
              name="total_weight"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label={`Total weight (${weightMeasurement.toLowerCase()})`}
                  inputName="total_weight"
                  placeholder="Enter Total weight"
                  parentClassName="flex-1"
                  className="p-2 flex-1 h-[unset]"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={
                    errors.total_weight ? errors.total_weight.message : null
                  }
                  labelClassName="form_label block mb-1.5"
                />
              )}
            />

            <Controller
              name="length"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label="Length"
                  inputName="length"
                  placeholder="Enter length"
                  parentClassName="flex-1"
                  className="p-2 flex-1 h-[unset]"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={errors.length ? errors.length.message : null}
                  labelClassName="form_label block mb-1.5"
                />
              )}
            />

            <Controller
              name="width"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label="width"
                  inputName="width"
                  placeholder="Enter width"
                  parentClassName="flex-1"
                  className="p-2 flex-1 h-[unset]"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={errors.width ? errors.width.message : null}
                  labelClassName="form_label block mb-1.5"
                />
              )}
            />

            <Controller
              name="height"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label="Height"
                  inputName="height"
                  placeholder="Enter height"
                  parentClassName="flex-1"
                  className="p-2 flex-1 h-[unset]"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={errors.height ? errors.height.message : null}
                  labelClassName="form_label block mb-1.5"
                />
              )}
            />
          </div>
          <div className="md:w-[50%] w-full h-auto px-5">
            <div className="h-full w-full">
              <ThreeJsScene
                height={Number(getValues('height')) / 10}
                width={Number(getValues('width')) / 10}
                depth={Number(getValues('length')) / 10}
              />
            </div>
          </div>
        </form>
        <div className="w-full flex sm:flex-row flex-col -mx-2.5 sm:my-4">
          <div className="sm:w-1/3 rounded-lg border border-borderPrimary shadow-xs px-6 py-2 text-center mx-2.5 sm:my-0 my-2">
            <h6 className="text-textSecondary text-xs font-normal mb-1">
              Total Volume of Shipment
            </h6>
            <p className="text-primary text-xs font-semibold">5.09 cubic ft.</p>
          </div>
          <div className="sm:w-1/3 rounded-lg border border-borderPrimary shadow-xs px-6 py-2 text-center mx-2.5 sm:my-0 my-2">
            <h6 className="text-textSecondary text-xs font-normal mb-1">
              Total Density of Shipment
            </h6>
            <p className="text-primary text-xs font-semibold">
              3.93 lbs/cubic ft.
            </p>
          </div>
          <div className="sm:w-1/3 rounded-lg border border-borderPrimary shadow-xs px-6 py-2 text-center mx-2.5 sm:my-0 my-2">
            <h6 className="text-textSecondary text-xs font-normal mb-1">
              Freight Density Class
            </h6>
            <p className="text-primary text-xs font-semibold">250</p>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default VisualizeUnit;
