import React, { useEffect, useRef, useState } from 'react';
import {} from '@untitled-ui/icons-react/build/cjs';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  capitalizeFirstLetterSpace,
  getFormattedNumber,
  getShortName,
} from 'src/utils/CommonFunctions';

const TeamPerformanceDetailsRow = ({ data, currentFilterType }: any) => {
  const textRef = useRef<any>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(
          textRef?.current?.scrollWidth > textRef?.current?.clientWidth
        );
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <>
      <td className={`px-5 py-4`} key={currentFilterType}>
        <div className="flex items-center gap-2">
          {data.image && data.imageUrl ? (
            <img
              className="w-7 h-7 rounded-full object-cover flex-none"
              src={`${data.imageUrl}${data.image}`}
              alt=""
            />
          ) : (
            <div className="w-7 h-7 bg-primary rounded-full text-white flex items-center justify-center uppercase text-xs flex-none">
              {getShortName(data.name)}
            </div>
          )}

          <TooltipCmp
            message={isOverflowing ? capitalizeFirstLetterSpace(data.name) : ''}
          >
            <div className="max-w-24 truncate" ref={textRef}>
              {capitalizeFirstLetterSpace(data.name)}
            </div>
          </TooltipCmp>
        </div>
      </td>
      <td className="px-5 py-4">
        ${getFormattedNumber(Math.round(data?.totalMargin ?? 0), false)}
      </td>
      <td className="px-5 py-4 min-w-36 max-w-36 w-36">
        <span className="">${data?.averageDailyMargin}</span>
      </td>
      <td className="px-5 py-4">
        <span className="">{data?.marginPercentage}%</span>
      </td>
      <td className="px-5 py-4">{data?.orderCount}</td>
      <td className="px-5 py-4">
        <span className="">{data?.customerCount}</span>
      </td>
      <td className="px-5 py-4">
        <span className="">{data?.newCustomers}</span>
      </td>
    </>
  );
};

export default TeamPerformanceDetailsRow;
