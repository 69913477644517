import { ClockFastForward, Edit01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import { useRolePermission } from 'src/utils/CommonFunctions';

const ClaimLoaderRow = () => {
  const { hasRoleV2 } = useRolePermission();

  return (
    <>
      <td className="px-5 py-4 min-w-44 w-44 max-w-44">
        <div className="flex items-center custom-loading">
          <div className="text-xs font-medium max-w-28">
            <div className="text-grayLight600 font-normal leading-tight truncate">
              #SQ-L-100502
            </div>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 min-w-[10%] max-w-[10%] w-[10%]">
        <a
          className="text-primary700 font-normal leading-tight truncate underline custom-loading"
          target="_blank"
        >
          #WAL-1OID-20
        </a>
      </td>
      <td className="px-5 py-4 max-w-32 w-32 min-w-32">
        <BadgeCmp
          style="modern"
          type={'success'}
          mainClassName={'custom-loading'}
        >
          <span data-test-id="wal-QuotingDashboard-status" className="text-xs">
            Opened
          </span>
        </BadgeCmp>
      </td>
      <td className="px-5 py-4 min-w-36 w-36 max-w-36">
        <span className="custom-loading">
          Apr 18<sup>th</sup> - 2024
        </span>
      </td>
      <td className="px-5 py-4 min-w-[150px] w-[150px] max-w-[150px]">
        <p className="truncate custom-loading">Kishan Dodiya</p>
      </td>
      <td className="px-5 py-4 min-w-36 w-36 max-w-36">
        <span className="custom-loading">
          Apr 18<sup>th</sup> - 2024
        </span>
      </td>
      <td className="px-5 py-4 min-w-[9%] max-w-[9%] w-[9%]">
        <span className="custom-loading">$2023.69</span>
      </td>

      <td className="px-5 py-4 min-w-[9%] max-w-[9%] w-[9%]">
        <span className="custom-loading">$500.00</span>
      </td>

      <td className="px-5 py-4 min-w-[8%] max-w-[8%] w-[8%]">
        <BadgeCmp style="pill" type="success" mainClassName="custom-loading">
          <ClockFastForward className="mr-1 h-3 w-3 text-lg text-success500" />
          9d ago
        </BadgeCmp>
      </td>
      {hasRoleV2('admin') ? (
        <td className="px-5 py-4 w-16 max-w-16 min-w-16">
          <div className="justify-center items-center gap-2 flex custom-loading">
            <Edit01 className="w-4 h-4 relative cursor-pointer" />
          </div>
        </td>
      ) : (
        ''
      )}
    </>
  );
};

export default ClaimLoaderRow;
