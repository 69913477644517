import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { onError } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import defaultImage from '../../assets/img/default-image.jpg';
import Logo from '../../assets/img/logo2.svg';
import CommonModal from '../../components/CommonModal';
import InputText from '../../components/InputText/InputText';

interface IProps {
  carrier: any;
  handleClose: (val: any) => void;
}

const AddNewCarrierModal = ({ carrier, handleClose }: IProps) => {
  const headerIcon = (
    <img
      src={Logo}
      alt="logo-light"
      className="object-covert h-full w-full p-1"
    />
  );

  const createSchema = yup.object().shape({
    apiKey: yup.string().required('Api key is required.'),
    userName: yup.string().required('Username is required.'),
    password: yup.string().required('Password is required.'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createSchema),
  });

  const onSubmit = async (data: any) => {
    // console.log('data :>> ', data);
    handleClose(data);
  };
  // console.log('carrier :>> ', carrier);

  return (
    <CommonModal
      title={`Connect WAL to ${carrier.name}`}
      titleDesc={
        <p>
          Add the API Key to your {carrier.name}
          <br />
          account to setup the connection.
        </p>
      }
      handleClose={handleClose}
      headerIcon={headerIcon}
      headerSecondIcon={
        carrier.image ? (
          <img
            src={`${carrier.imageUrl}${carrier.image}`}
            className="object-covert h-full w-full p-1"
            alt={carrier.name}
            title={carrier.name}
            onError={onError}
          />
        ) : (
          <img
            className="object-cover h-full w-full"
            src={defaultImage}
            alt={carrier.name}
            title={carrier.name}
          />
        )
      }
      headerIconClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-left-[69px] before:opacity-50"
      headerIconSecondClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex items-center relative ml-2 after:content-[''] after:h-[26px] after:w-[26px] after:border-utilityGray200 after:border after:rounded-full after:absolute  after:bg-[url('../../assets/img/link-01.svg')] after:bg-no-repeat after:bg-center after:bg-white after:top-1/2 after:-translate-y-1/2 after:-left-[22px] before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-right-[69px] before:opacity-50"
      size={'max-w-[580px]'}
      primaryBtnText="Continue"
      secondaryBtnText="Canceled"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      secondaryBtnOnClick={() => handleClose(false)}
      modalHeaderTextClass="!block text-center flex-1"
      headerInfoClass="mt-[18px]"
      modalHeaderClass="!pt-5"
    >
      <div className="w-full p-5">
        <Controller
          name="apiKey"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="apiKey"
              onChangeFunc={onChange}
              value={value}
              placeholder=""
              parentClassName="mb-5"
              required
              label="API Key"
              labelClassName="mb-1.5 block"
              errorText={errors.apiKey ? errors.apiKey.message : null}
            />
          )}
        />
        <Controller
          name="userName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              placeholder="userName"
              onChangeFunc={onChange}
              value={value}
              parentClassName="mb-5"
              label="Username"
              required
              labelClassName="mb-1.5 block"
              errorText={errors.userName ? errors.userName.message : null}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              onChangeFunc={onChange}
              value={value}
              placeholder="password"
              parentClassName="mb-5"
              required
              label="Password"
              labelClassName="mb-1.5 block"
              errorText={errors.password ? errors.password.message : null}
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default AddNewCarrierModal;
