import React from 'react';
import { ToastContainer, ToastTransition, cssTransition } from 'react-toastify';

const fade: ToastTransition = cssTransition({
  enter: 'Toastify__slide-enter-common',
  exit: 'Toastify__slide-exit-common',
});

const ToastBodyContainer = () => (
  <ToastContainer
    position="top-right"
    transition={fade}
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    style={{ whiteSpace: 'pre-line' }}
    className="!w-fit  !top-0 !right-0 !left-[unset]"
  />
);

export default ToastBodyContainer;
