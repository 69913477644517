import { Edit05, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

const AllocationHistoryLoaderRow = () => (
  <>
    <td className="px-5 py-4 w-48 max-w-48 min-w-48">
      <div className=" flex items-center gap-3">
        <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none custom-loading">
          WA
        </div>
        <TooltipCmp message="Kate Morrison" parentClassName="">
          <div className="max-w-32 truncate font-semibold custom-loading">
            {' '}
            Kate Morrison Kate Morrison Kate Morrison
          </div>
        </TooltipCmp>
      </div>
    </td>
    <td className="px-5 py-4 w-36 max-w-36 min-w-36">
      <span className="custom-loading">Jun 7, 2023</span>
    </td>
    <td className="px-5 py-4 w-36 max-w-36 min-w-36">
      <span className="custom-loading">Jun 7, 2023</span>
    </td>
    <td className="px-5 py-4 max-w-40 w-40">
      <span className="custom-loading">136</span>
    </td>
    <td className="px-5 py-4 max-w-40 w-40">
      <span className="custom-loading">82,362</span>
    </td>
    <td className="px-5 py-4 w-28 max-w-28 min-w-28">
      <span className="custom-loading">100</span>
    </td>
    <td className="px-5 py-4 !pl-2 w-36 max-w-36 min-w-36">
      <span className="custom-loading">50.03$</span>
    </td>
    <td className="px-5 py-4 pl-2 max-w-20 w-20 min-w-20">
      <div className="justify-center items-center gap-2 flex ">
        <TooltipCmp
          message="Edit"
          type="dark"
          childrenClassName="custom-loading "
        >
          <Edit05 className="w-4 h-4 relative cursor-pointer" />
        </TooltipCmp>

        <TooltipCmp
          message="Delete"
          type="dark"
          childrenClassName="custom-loading"
        >
          <Trash01 className="w-4 h-4 relative cursor-pointer" />
        </TooltipCmp>
      </div>
    </td>
  </>
);

export default AllocationHistoryLoaderRow;
