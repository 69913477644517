import { Mail02, Phone, Settings01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

import defaultImage from '../../assets/img/default-image.jpg';
import BadgeCmp from '../../components/BadgeCmp';
import ButtonCmp from '../../components/ButtonCmp';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  onError,
  usePhone,
} from '../../utils/CommonFunctions';

interface IProps {
  carrier: any;
  handleViewDetail: () => void;
  handleConfigureCarrier: () => void;
  handleCarrierConnection: () => void;
  setAction: any;
}

const serviceAreaList: any = { 1: 'CA - CA', 2: 'US - US', 3: 'CA - US' };

const CarrierColumnCard = ({
  handleViewDetail,
  carrier,
  handleCarrierConnection,
  handleConfigureCarrier,
}: IProps) => {
  const { getFormatPhone } = usePhone();

  const showServiceArea = (serviceAreaString: string) => {
    if (serviceAreaString) {
      let selectedServiceArea = serviceAreaString.split(',');

      return <>{selectedServiceArea.map((val: any) => serviceAreaList[val])}</>;
    } else {
      return null;
    }
  };

  return (
    <div className="px-5 py-4  border-b border-utilityGray200 mdm:gap-3.5 gap-6 flex mdm:flex-row flex-col mdm:justify-between mdm:items-center">
      <div className="flex gap-3 flex-1">
        <div className="h-12 w-12 rounded-lg bg-white border border-utilityGray200 shadow-xs overflow-hidden relative flex-none">
          {carrier.image ? (
            <img
              src={`${carrier.imageUrl}${carrier.image}`}
              className="object-cover h-full w-full"
              alt={carrier.name}
              title={carrier.name}
              onError={onError}
            />
          ) : (
            <img
              className="object-cover h-full w-full"
              src={defaultImage}
              alt={carrier.name}
              title={carrier.name}
            />
          )}
          {/* {carrier.isFreightcom && (
                                <div className="absolute bottom-0 right-0 w-4 h-4 flex justify-center items-center bg-white rounded-lg">
                                    <img className="" src={FreightcomIcon} alt="FC"></img>
                                </div>
                            )} */}
        </div>

        <div className="flex flex-col gap-1.5 flex-1">
          <div className=" gap-1.5 flex mdm:items-center items-start mdm:justify-normal justify-between">
            <h6 className="text-gray-900 text-lg font-semibold">
              {carrier.name}
            </h6>
            <div className="text-textSecondary font-medium text-[10px] leading-[18px] rounded-md border border-borderPrimary shadow-xs px-1.5 py-0.5 flex-none">
              {showServiceArea(carrier.serviceArea)}
            </div>
          </div>
          <div className="flex sm:flex-row xls:gap-0 gap-y-1.5 flex-col flex-wrap">
            {/* <BadgeCmp style="modern" type="error" mainClassName="w-fit me-2">
                            Unavailable
                        </BadgeCmp> */}
            {/* <BadgeCmp style="modern" type="success" mainClassName="w-fit me-2">
                                    Live
                                </BadgeCmp> */}
            {carrier.phone && (
              <div className="flex gap-1 items-center pe-4">
                <Phone className="h-4 w-4 text-gray500" />
                <div className="text-gray500 text-xs font-medium">
                  {getFormatPhone(carrier.phone)}
                </div>
              </div>
            )}
            {carrier.email && (
              <div className="flex gap-1 items-center">
                <Mail02 className="h-4 w-4 text-gray500" />
                <div className="text-gray500 text-xs font-medium break-all">
                  {carrier.email}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap mdm:justify-normal justify-between gap-2.5">
        <a
          className="text-primary text-[11px] font-semibold  cursor-pointer flex items-center hidden"
          onClick={handleViewDetail}
        >
          View Details
        </a>

        <div className="flex gap-2.5">
          <span
            className="cursor-pointer text-gray-500 flex items-center hidden"
            onClick={handleConfigureCarrier}
          >
            <Settings01 />
          </span>
          <TooltipCmp
            type="dark"
            message={
              <div className="text-left text-xs font-semibold p-1.5">
                <p className="flex items-center gap-1 text-xs font-bold mb-1 capitalize ">
                  <span
                    className={`w-2 h-2 ${
                      carrier.apiStatus === 'active'
                        ? 'bg-successSecondary'
                        : 'bg-red-600'
                    } rounded-full`}
                  ></span>
                  {carrier.apiStatus === 'active' ? 'Live' : 'Unavailable'}
                </p>
                {carrier.apiLastCheck && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: carrier.apiLastCheck
                        ? getDateWithSuffixFormat(
                            getFormattedDate(
                              carrier.apiLastCheck,
                              `MMM Do - YYYY hh:mm:ss A`,
                              true
                            )
                          )
                        : '-',
                    }}
                  />
                )}
              </div>
            }
          >
            <BadgeCmp
              style="modern"
              type="success"
              mainClassName="lg:!py-[9px] !py-2 "
            >
              Connected
            </BadgeCmp>
          </TooltipCmp>
          <ButtonCmp
            className="btn_primary hidden"
            onClick={handleCarrierConnection}
          >
            Connect
          </ButtonCmp>
        </div>
      </div>
    </div>
  );
};

export default CarrierColumnCard;
