import React from 'react';
import avatar from 'src/assets/img/Avatar1.png';

const NotificationSkeletonLoader = () => (
  <li className="border border-utilityGray200 bg-white hover:bg-gray50 cursor-pointer rounded-lg p-4 flex gap-x-4 relative">
    <div className="w-10 h-10 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary  flex-none  overflow-hidden">
      <img
        src={avatar}
        className="h-full w-full custom-loading image-loading"
      />
    </div>
    <div>
      <h6 className="text-sm text-grayLight600 font-normal custom-loading">
        <span className="text-grayLight900 font-medium">Anita Cruz</span>
        &nbsp;has claimed quote on&nbsp;
        <span className="text-grayLight900 font-medium">
          01 Jul 2024 at 09:30AM
        </span>
      </h6>
      <div className="flex flex-wrap items-center gap-x-1 mt-1">
        <p className="text-grayLight600 text-xs font-medium custom-loading">
          Claimed Quote
        </p>

        <p className="w-1 h-1 bg-utilityBlack opacity-30 rounded-full custom-loading"></p>
        <p className="text-gray500 text-xs font-normal custom-loading">
          2 hours ago
        </p>
      </div>
    </div>
    <span className="bg-successSecondary w-1.5 h-1.5 !absolute rounded-full top-2 right-2 custom-loading"></span>
  </li>
);

export default NotificationSkeletonLoader;
