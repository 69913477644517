import {
  CheckSquareBroken,
  Share03,
  XClose,
} from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
// import BadgeCmp from 'src/components/BadgeCmp';

// import user from '../../../../assets/img/carrier1.png';

const ConflictsCommissionReportRow = () => (
  <>
    <td className="px-5 py-4 min-w-52">
      <h6 className=" h-8 text-primary underline flex items-center font-medium hover:text-primary700 cursor-pointer">
        WAL-DRGH-1
      </h6>
    </td>
    <td className="px-5 py-4 ">
      <div className="flex items-center">
        August 2024{' '}
        <Share03 className="ml-2 text-gray500 w-4 h-4 cursor-pointer hover:text-primary" />
      </div>
    </td>
    <td className="px-5 py-4 ">
      <div className="flex gap-1 flex-wrap">
        <BadgeCmp style="modern" type="success">
          New
        </BadgeCmp>
        <BadgeCmp style="modern" type="primary">
          Dispatched
        </BadgeCmp>
        <BadgeCmp style="modern" type="warning">
          Invoice- created
        </BadgeCmp>
      </div>
    </td>
    <td className="px-5 py-4 ">
      <span>Jun 7, 2023</span>
    </td>
    <td className="px-5 py-4 ">
      <span>$321.31</span>
    </td>
    <td className="px-5 py-4 ">
      <span>$432.33</span>
    </td>
    <td className="px-5 py-4 ">
      <span>$0.00</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="font-semibold">$901.06 CAD</span>
    </td>
    <td className="px-5 py-4 ">
      <div className="justify-center items-center gap-3 flex ">
        <CheckSquareBroken className="w-4 h-4 text-fgSuccessPrimary" />
        <XClose className="w-4 h-4 text-fgErrorPrimary" />
      </div>
    </td>
  </>
);

export default ConflictsCommissionReportRow;
