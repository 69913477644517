import { Check, Mail02 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';

import ButtonCmp from '../../../../../components/ButtonCmp';
import CommonModal from '../../../../../components/CommonModal';
import { CARRIERS } from '../../../../../constants/common';

const SpotQuoteConfirmation = ({
  handleClose,
  dimensions,
  services,
  selectedCarrierRates,
  addressDetails,
}: any) => {
  const [doNotAskChecked] = useState<boolean>(false);
  const [serviceNames, setServiceNames] = useState<any[]>([]);

  const getApiName = (selectedCarrierRate: any, service: any) => {
    let apiName;

    if (selectedCarrierRate.freightServiceId) {
      apiName = service.freightcomName ?? '';
    } else {
      switch (selectedCarrierRate.slug) {
        case CARRIERS.ARC_BEST.SLUG:
          apiName = service.arcName ?? '';
          break;
        case CARRIERS.POLARIS.SLUG:
          apiName = service.polarisName ?? '';
          break;
        case CARRIERS.DAY_ROSS.SLUG:
          apiName = service.dayRossName ?? '';
          break;
        case CARRIERS.FREIGHTCOM.SLUG:
          apiName = service.freightcomName ?? '';
          break;
        case CARRIERS.MARITIME_ONTARIO.SLUG:
          apiName = service.maritimeOnName ?? '';
          break;
        case CARRIERS.CSA.SLUG:
          apiName = service.csaName ?? '';
          break;
        case CARRIERS.KINDERSLEY.SLUG:
          apiName = service.kindersleyName ?? '';
          break;
        default:
          break;
      }
    }

    return apiName;
  };

  useEffect(() => {
    const findNames: any[] = [];

    if (services.length > 0) {
      services.forEach((service: any) => {
        const serviceName = getApiName(
          selectedCarrierRates.length > 0 ? selectedCarrierRates[0] : [],
          service
        );

        if (serviceName) {
          findNames.push(serviceName);
        }
      });
    }
    setServiceNames(findNames);
  }, []);
  const headerIcon = <Mail02 className="text-textSecondary w-4 h-4" />;
  console.log('selectedCarrierRates[0]', selectedCarrierRates[0]);

  return (
    <CommonModal
      handleClose={handleClose(false)}
      titleDesc="Create new carrier for free in less than 5 minutes."
      headerIcon={headerIcon}
      title="Spot Quote Email"
      size={'max-w-3xl'}
    >
      <div className="w-full p-6">
        <div className="flex-col justify-start items-start gap-2 flex pb-3">
          <div className="items-center gap-8 flex">
            <div className="text-gray500 text-sm font-semibold leading-normal">
              From:
            </div>
            <div className="items-center gap-2 flex text-textSecondary text-xs font-semibold leading-tight">
              <img
                alt=""
                src={
                  selectedCarrierRates.length > 0
                    ? selectedCarrierRates[0].imageUrl +
                      selectedCarrierRates[0].image
                    : '-'
                }
                className="w-7 h-7 relative rounded-full border border-black border-opacity-10"
              />
              <span>
                {selectedCarrierRates.length > 0
                  ? selectedCarrierRates[0].name
                  : '-'}
              </span>
              <span className="underline">
                {selectedCarrierRates.length > 0
                  ? selectedCarrierRates[0].email
                  : '-'}
              </span>
            </div>
          </div>
          {selectedCarrierRates.length > 0 &&
            selectedCarrierRates[0].spotQuoteEmail && (
              <div className="items-center gap-8 flex">
                <div className="text-gray500 text-sm font-semibold leading-normal">
                  To:
                </div>
                <div className="ml-4">
                  <div className="p-2 bg-gray100 rounded-md text-grayLight600 text-xs font-normal leading-tight">
                    {selectedCarrierRates.length > 0
                      ? selectedCarrierRates[0].spotQuoteEmail
                      : '-'}
                  </div>
                </div>
              </div>
            )}
          <div className="items-center gap-6 text-xs flex">
            <div className="text-gray500 font-semibold leading-normal">
              Subject :{' '}
            </div>
            <div className="text-grayLight600 font-normal">
              WAL Spot Quote Request - Shipment Details Enclosed
            </div>
          </div>
        </div>
        <div className="items-center gap-5 flex border-t py-2">
          <div className="flex items-center text-xs">
            <b className="mr-1 text-grayLight900">Pickup Date:</b>
            <span className="font-normal text-grayLight600">
              {moment(addressDetails.pickupDate).format('YYYY-MM-DD')}
            </span>
          </div>
        </div>
        <div className="w-full justify-start items-start py-4 pt-2 gap-10 inline-flex">
          <div className="flex-col flex">
            <div className="text-black text-sm font-semibold leading-normal">
              SHIPPER DETAILS
            </div>
            <div className="mt-2">
              <span className="text-gray500 text-xs font-medium leading-[18px]">
                Company Name:
              </span>
              <span className="text-gray-800 text-xs font-medium leading-[18px] ml-1">
                {addressDetails?.shipperCompanyName}
              </span>
            </div>
            <div>
              <span className="text-gray500 text-xs font-medium leading-[18px]">
                City:
              </span>
              <span className="text-gray-800 text-xs font-medium leading-[18px] ml-1">
                {addressDetails?.shipperCity}
              </span>
            </div>
            <div>
              <span className="text-gray500 text-xs font-medium leading-[18px]">
                Province:
              </span>
              <span className="text-gray-800 text-xs font-medium leading-[18px] ml-1">
                {addressDetails?.shipperStateCode}
              </span>
            </div>
            <div>
              <span className="text-gray500 text-xs font-medium leading-[18px]">
                PostalCode:{' '}
              </span>
              <span className="text-gray-800 text-xs font-medium leading-[18px] ml-1">
                {addressDetails?.shipperPostal}
              </span>
            </div>
            <div>
              <span className="text-gray500 text-xs font-medium leading-[18px]">
                Country:
              </span>
              <span className="text-gray-800 text-xs font-medium leading-[18px] ml-1">
                {' '}
                {addressDetails?.shipperCountryCode}
              </span>
            </div>
          </div>
          <div className="flex-col flex">
            <div className="text-black text-sm font-semibold leading-normal">
              SHIPPER DETAILS
            </div>
            <div className="mt-2">
              <span className="text-gray500 text-xs font-medium leading-[18px]">
                Company Name:
              </span>
              <span className="text-gray-800 text-xs font-medium leading-[18px] ml-1">
                {addressDetails?.consigneeCompanyName}
              </span>
            </div>
            <div>
              <span className="text-gray500 text-xs font-medium leading-[18px]">
                City:
              </span>
              <span className="text-gray-800 text-xs font-medium leading-[18px] ml-1">
                {addressDetails?.consigneeCity}
              </span>
            </div>
            <div>
              <span className="text-gray500 text-xs font-medium leading-[18px]">
                Province:
              </span>
              <span className="text-gray-800 text-xs font-medium leading-[18px] ml-1">
                {addressDetails?.consigneeStateCode}
              </span>
            </div>
            <div>
              <span className="text-gray500 text-xs font-medium leading-[18px]">
                PostalCode:{' '}
              </span>
              <span className="text-gray-800 text-xs font-medium leading-[18px] ml-1">
                {addressDetails?.consigneePostal}
              </span>
            </div>
            <div>
              <span className="text-gray500 text-xs font-medium leading-[18px]">
                Country:
              </span>
              <span className="text-gray-800 text-xs font-medium leading-[18px] ml-1">
                {' '}
                {addressDetails?.consigneeCountryCode}
              </span>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto mt-2 rounded-xl shadow border border-utilityGray200">
          <table className="w-full text-sm text-left ">
            <thead className="text-xs leading-[18px] text-grayLight600">
              <tr>
                <td className="px-4 py-2">QUANTITY</td>
                <td className="px-4 py-2">DESCRIPTION</td>
                <td className="px-4 py-2">
                  DIMENSIONS (INCHES) (
                  {dimensions.length > 0 && dimensions[0].lengthMeasurement})
                </td>
                <td className="px-4 py-2">CLASS</td>
                <td className="px-4 py-2">TOTAL WEIGHT</td>
              </tr>
            </thead>
            <tbody>
              {dimensions.map((dimension: any, index: number) => (
                <tr
                  className="text-xs leading-4 text-grayLight600 border-t bg-gray50"
                  key={`spot_dim_${index}`}
                >
                  <td className="px-4 py-2 ">{dimension.handlingUnitNo}</td>
                  <td className="px-4 py-2 ">{dimension.handlingUnitName}</td>
                  <td className="px-4 py-2 ">
                    Length: {dimension.width} Width: {dimension.height} Height:{' '}
                    {dimension.itemLength}
                  </td>
                  <td className="px-4 py-2">{dimension.class ?? '-'}</td>
                  <td className="px-4 py-2">
                    {dimension.totalWeight} ({dimension.weightMeasurement})
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {serviceNames.length > 0 && (
          <div className="border-t mt-3">
            <div className="mt-3 ">Additional Services Details</div>
            <div className="grid grid-cols-2">
              {serviceNames.map((service: any) => (
                <div
                  key={service}
                  className="justify-start items-center flex py-1.5"
                >
                  <Check className="w-4 h-4 text-primary" />
                  <div className="ml-1 text-grayLight600 text-xs font-medium leading-tight">
                    {service}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-wrap -mx-3 mt-4 border-t">
          <div className="w-full px-3 pt-2 flex justify-end">
            <ButtonCmp
              className="btn_primary btn-heigh-auto "
              onClick={handleClose(true, doNotAskChecked)}
            >
              Submit
            </ButtonCmp>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default memo(SpotQuoteConfirmation);
