import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';
import { code } from '../../services/AuthService';

const Callback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleCallback = (message: string) => {
    window.opener.postMessage(message, window.location.origin);
  };

  useEffect(() => {
    const data = {
      code: searchParams.get('code'),
      state: searchParams.get('state'),
      verifier: localStorage.getItem('verifier'),
    };

    if (data.code && data.state && data.verifier) {
      code(data)
        .then(() => handleCallback('loginSuccess'))
        .catch(() => handleCallback('loginFailure'))
        .finally(() => localStorage.removeItem('verifier'));
    } else {
      navigate(ROUTES.HOME);
    }
  }, []);

  return <Loader />;
};

export default Callback;
