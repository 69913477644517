import React, { useContext, useEffect, useRef } from 'react';
// eslint-disable-next-line import/order
import { Link } from 'react-router-dom';

import './login.scss';

import WalToast from 'src/utils/WalToast';

import MsIcon from '../../assets/img/microsoft-icon.svg';
import LogoWhite from '../../assets/img/wal-hq-header-logo.png';
import { AuthContext } from '../../context/AuthContext';
import { login } from '../../services/AuthService';

const Login = () => {
  const { setIsVerifyCode } = useContext(AuthContext);
  let newWindow: any;
  const newWindowRef = useRef<any>(null);

  const handleWindowClose = (message: any) => {
    // console.log(message);

    if (message === 'loginSuccess') {
      setIsVerifyCode(true);
    } else if (message === 'loginFailure') {
      WalToast.error('Email has not been registered as a user in WALHQ.');
    }
    // newWindow?.close();
    newWindowRef.current?.close();
  };

  useEffect(() => {
    window.addEventListener('message', (event: any) => {
      if (event.source === newWindowRef.current) {
        handleWindowClose(event.data);
      }
    });

    return () => window.removeEventListener('message', handleWindowClose);
  }, []);

  const signIn = () => {
    login()
      .then((response) => {
        const url = response.data.url;
        const height = 600;
        const width = 500;
        const leftPosition = window.screen.width / 2 - (width / 2 + 10);
        const topPosition = window.screen.height / 2 - (height / 2 + 50);
        newWindow = window.open(
          url,
          'Window2',
          `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`
        );
        newWindowRef.current = newWindow;
      })
      .catch(console.log);
  };

  return (
    <div className="h-screen  bg-no-repeat bg-cover flex flex-col justify-center items-center wal__auth_banner-bg bg-center">
      <div className="sm:w-[360px] w-full sm:max-w-full max-w-[343px] text-center px-4 sm:px-0">
        <img
          src={LogoWhite}
          alt="logo"
          className="sm:mb-14 mb-11 mx-auto sm:w-80 w-56"
        />
        {/* <h3 className="text-white text-3xl my-8">Login</h3> */}
        <button
          type="button"
          className="flex items-center bg-white hover:bg-slate-200  text-base rounded-lg shadow border border-borderPrimary  transition-all delay-200 focus:outline-none focus:ring-4 focus:ring-blue justify-center font-semibold w-full px-4 py-2.5 mb-2 text-textSecondary"
          onClick={signIn}
        >
          <img src={MsIcon} alt="logo" className="me-3 w-[21px] h-[21px]" />{' '}
          Login with Microsoft
        </button>
      </div>
      <footer className="bg-transparent fixed bottom-0 sm:p-8 p-4 flex sm:justify-between justify-center sm:flex-row flex-col items-center w-full font-normal text-xs leading-[18px] text-utilityGray100">
        <div className="copyright_content sm:pb-0 pb-4">
          © 2020 Western Alliance Inc.
        </div>
        <div className="nav-link">
          <ul className="flex">
            <li className="pe-3">
              <Link to="#">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="#">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Login;
