export const STEPS = [
  {
    name: 'Address Details',
    step: 1,
  },
  {
    name: 'Dimensions',
    step: 2,
  },
  {
    name: 'Finish',
    step: 3,
  },
];

export const quoteDetail = {
  addressDetails: {
    createdAt: '2023-10-05 12:09:25',
    updatedAt: '2024-04-01 11:26:48',
    id: 5,
    code: 'SQ-L-100005',
    userId: 2,
    customerId: 2363,
    rossRocketId: null,
    rossRocketPublicId: null,
    pickupDate: '2023-10-06 12:09:25',
    shipperId: 5,
    shipperCompanyName: 'Candadian Company test 1',
    shipperFullAddress: '100 Rue Laurier, Gatineau, QC K1A 0M8, Canada',
    shipperAddress1: ' 100 Rue Laurier Hull ',
    shipperCity: 'Gatineau',
    shipperState: 'Québec',
    shipperStateCode: 'QC',
    shipperCountry: 'Canada',
    shipperCountryCode: 'CA',
    shipperPostal: 'K1A 0M8',
    shipperLatitude: '45.4290411',
    shipperLongitude: '-75.70921659999999',
    consigneeId: 6,
    consigneeCompanyName: 'Candadian Company test 2',
    consigneeFullAddress: '2015 Calypso St, Limoges, ON K0A 2M0, Canada',
    consigneeAddress1: ' 2015 Calypso St ',
    consigneeCity: 'Limoges',
    consigneeState: 'Ontario',
    consigneeStateCode: 'ON',
    consigneeCountry: 'Canada',
    consigneeCountryCode: 'CA',
    consigneePostal: 'K0A 2M0',
    consigneeLatitude: '45.3220821',
    consigneeLongitude: '-75.2282228',
    status: 'Opened',
    cancelReason: null,
    isDisplayCarrier: 0,
    isDisplayTransit: 0,
    cadRate: 1.34,
    usdRate: 0.75,
    customerName: 'Sinobec Trading',
    isCustomerDeleted: 0,
    shipperName: null,
    consigneeName: null,
  },
  dimensions: [
    {
      createdAt: '2023-10-05 12:09:36',
      updatedAt: '2023-10-05 12:09:36',
      id: 5,
      quoteId: 5,
      handlingUnit: 3,
      handlingUnitNo: 2,
      weight: 30,
      itemLength: 40,
      width: 45,
      height: 41,
      totalWeight: 60,
      isCommodity: 0,
      isStack: 0,
      weightMeasurement: 'LBS',
      lengthMeasurement: 'INCHES',
      commodityId: null,
      sub: null,
      description: null,
      freightClass: 0,
      handlingUnitName: 'Bag',
      handlingUnitCode: 'BAG',
      commodityName: null,
      commodityClass: null,
    },
  ],
  carrierRates: [
    {
      createdAt: '2024-04-01 11:26:51',
      updatedAt: '2024-04-01 11:26:51',
      id: 2915,
      quoteId: 5,
      carrierQuoteId: null,
      additionalCharges: 0,
      margin: 0,
      totalCharge: 0,
      finalCharge: 0,
      marginType: 'VALUE',
      projectedDeliveryDate: null,
      expirationDate: null,
      error:
        'There are missing or invalid credentials to access the Rate Quote API.  Please email api@abf.com if assistance is needed.',
      transitTime: 0,
      carrierStatus: 'Opened',
      additionalChargesUSD: 0,
      marginUSD: 0,
      totalChargeUSD: 0,
      finalChargeUSD: 0,
      freightServiceId: null,
      freightServiceName: null,
      carrierId: 1,
      marginUnit: 0,
      marginUnitUSD: 0,
      isSpotRequestSent: 1,
      name: 'ABF Freight',
      image: null,
      imageUrl: null,
      email: 'contact@newyork.com',
      phone: '12525550126',
      slug: 'ARC_BEST',
      spotQuoteEmail: 'dev@westernalliancelogistics.com',
      services: [],
    },
    {
      createdAt: '2024-04-01 11:26:51',
      updatedAt: '2024-04-01 11:26:51',
      id: 2916,
      quoteId: 5,
      carrierQuoteId: null,
      additionalCharges: 0,
      margin: 0,
      totalCharge: 0,
      finalCharge: 0,
      marginType: 'VALUE',
      projectedDeliveryDate: null,
      expirationDate: null,
      error: 'Location: Polaris does not do shipping CA to CA',
      transitTime: 0,
      carrierStatus: 'Opened',
      additionalChargesUSD: 0,
      marginUSD: 0,
      totalChargeUSD: 0,
      finalChargeUSD: 0,
      freightServiceId: null,
      freightServiceName: null,
      carrierId: 2,
      marginUnit: 0,
      marginUnitUSD: 0,
      isSpotRequestSent: 0,
      name: 'Polaris Transportation',
      image: null,
      imageUrl: null,
      email: 'contact@initech.com',
      phone: '13652037772',
      slug: 'POLARIS',
      spotQuoteEmail: 'dev@westernalliancelogistics.com',
      services: [],
    },
    {
      createdAt: '2024-04-01 11:27:03',
      updatedAt: '2024-04-01 11:27:03',
      id: 2917,
      quoteId: 5,
      carrierQuoteId: '560622',
      additionalCharges: 0,
      margin: 0,
      totalCharge: 141.66,
      finalCharge: 141.66,
      marginType: 'VALUE',
      projectedDeliveryDate: '2024-04-02 00:00:00',
      expirationDate: null,
      error: null,
      transitTime: 1,
      carrierStatus: 'Opened',
      additionalChargesUSD: 0,
      marginUSD: 0,
      totalChargeUSD: 106.25,
      finalChargeUSD: 106.25,
      freightServiceId: null,
      freightServiceName: null,
      carrierId: 3,
      marginUnit: 0,
      marginUnitUSD: 0,
      isSpotRequestSent: 0,
      name: 'Day & Ross',
      image: null,
      imageUrl: null,
      email: 'contact@plexzap.com',
      phone: '16586664248',
      slug: 'DAY_ROSS',
      spotQuoteEmail: 'dev@westernalliancelogistics.com',
      services: [],
    },
  ],
  additionalService: [],
};
