export interface IFeedbackFormData {
  title: string | undefined;
  quoteId: string[] | undefined | null;
  description: string | undefined;
  expectedOutcome: string | undefined;
  currentOutcome: string | undefined;
  links: string | undefined;
  image: any;
  feedbackType: FeedbackType | '';
}

export enum FeedbackType {
  GENERAL = 'general',
  QUOTE = 'quote',
}

export interface IFeedbackValidForm {
  quoteId?: boolean;
  description: boolean;
  feedbackType: boolean;
}

export interface IFeedbackKey extends Array<keyof IFeedbackFormData> {}
