import {
  DotsVertical,
  Edit05,
  Lock02,
  Trash01,
  User01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';

import {
  getShortName,
  onError,
  useRolePermission,
} from '../../../utils/CommonFunctions';

interface IProps {
  data: any;
  roleInTeam: string;
  isTeamEdit: boolean;
  handleActionType: (action: any, mode?: any, team?: any) => () => void;
}

const TeamDetailRow = ({
  data,
  handleActionType,
  roleInTeam,
  isTeamEdit,
}: IProps) => {
  const { hasPermissionV2 } = useRolePermission();

  const tooltipItems = (
    <div className="menu bg-base-100 rounded-box w-36">
      <div
        onClick={handleActionType('team_management', 'view_profile', data)}
        className="py-2"
      >
        <div className="flex items-center">
          <User01 className="w-4 h-4 relative mr-3" /> View profile
        </div>
      </div>
      {hasPermissionV2('team_member_delete') && (
        <div
          onClick={handleActionType(
            'team_management',
            'user-remove-from-team',
            data
          )}
          className="py-2"
        >
          <div className="flex items-center">
            <Trash01 className="w-4 h-4 relative mr-3" />
            Remove
          </div>
        </div>
      )}
      {hasPermissionV2('team_member_update') && (
        <div
          onClick={handleActionType('team_management', 'user-update', data)}
          className="py-2"
        >
          <div className="flex items-center">
            <Edit05 className="w-4 h-4 relative mr-3" /> Edit
          </div>
        </div>
      )}
      {hasPermissionV2('team_member_update') && (
        <div
          onClick={handleActionType('team_management', 'access-control', data)}
          className="py-2"
        >
          <div className="flex items-center">
            <Lock02 className="w-4 h-4 relative mr-3" />
            Permissions
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      {/* <tr className="text-grayLight600 font-normal  [&:not(:last-child)]:border-b border-utilityGray200"> */}
      <td className="flex items-center px-5 py-4 text-xs w-72 min-w-72 max-w-72">
        {data.imageUrl ? (
          <img
            className="w-8 h-8 rounded-full border border-black/[0.08] flex-none"
            src={data?.imageUrl + data?.image}
            alt=""
            title=""
            onError={onError}
          />
        ) : (
          <label className="w-8 h-8 relative bg-primary text-white text-sm rounded-full font-semibold border border-black/[0.08] flex items-center justify-center uppercase flex-none">
            <div className="">
              {getShortName(`${data?.firstName} ${data?.lastName}`)}
            </div>
          </label>
        )}

        <div className="pl-3">
          <div className="text-grayLight900 font-medium leading-tight w-48 truncate">{`${data?.firstName} ${data?.lastName}`}</div>
          <div className="text-grayLight600 font-normal leading-tight w-48 truncate">
            {data?.email}
          </div>
        </div>
      </td>
      <td className="px-5 py-4 text-xs min-w-24 max-w-48 truncate">
        {data?.jobRole}
      </td>
      <td className="px-5 py-4 text-xs">
        {' '}
        <div className="flex">
          {data.role && roleInTeam === 'Manager' ? (
            <BadgeCmp
              style="modern"
              type="success"
              mainClassName=" flex-shrink"
            >
              {roleInTeam}
            </BadgeCmp>
          ) : roleInTeam === 'User' ? (
            <BadgeCmp style="modern" type="info" mainClassName=" flex-shrink">
              {roleInTeam}
            </BadgeCmp>
          ) : (
            <BadgeCmp style="modern" type="error" mainClassName=" flex-shrink">
              {roleInTeam}
            </BadgeCmp>
          )}
        </div>
      </td>
      <td className="px-5 py-4 text-xs">
        {data?.lastLoginAt
          ? `${moment(data?.lastLoginAt).format('MMM DD, YYYY')}`
          : '-'}
      </td>
      <td className="px-5 py-4 text-xs relative">
        {isTeamEdit ? (
          <div className="dropdown dropdown-bottom dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="p-0 text-grayText rounded-md transition-all ms-3 border-none bg-transparent"
            >
              <ActionTooltip
                message={isTeamEdit ? tooltipItems : null}
                isOpenChildTopPosOnTop={true}
                type="light"
              >
                <DotsVertical className="cursor-pointer w-5 h-5" />
              </ActionTooltip>
            </div>
          </div>
        ) : (
          ''
        )}
      </td>
      {/* </tr> */}
    </>
  );
};

export default TeamDetailRow;
