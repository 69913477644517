import {
  Mail01,
  Minus,
  Plus,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../../assets/img/Avatar7.png';
import ButtonCmp from '../../../components/ButtonCmp';
import CommonModal from '../../../components/CommonModal';
import Loader from '../../../components/Loader';
import { getUserDetail } from '../../../services/TeamMemberService';
import {
  capitalizeFirstLetterSpace,
  getShortName,
  onError,
} from '../../../utils/CommonFunctions';

interface IProps {
  handleClose: () => void;
  teamMemberId: string;
}

const MemberProfile = ({ handleClose, teamMemberId }: IProps) => {
  const [isUserDetailLoading, setIsUserDetailLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<any>(undefined);
  const [isShowMorePermission, setIsShowMorePermission] = useState(true);

  const loadUserDetails = () => {
    setIsUserDetailLoading(true);
    getUserDetail(teamMemberId)
      .then((response: any) => {
        setUserDetails(response?.data);
      })
      .catch(() => {
        WalToast.error('Something went wrong.');
        handleClose();
      })
      .finally(() => setIsUserDetailLoading(false));
  };

  useEffect(() => {
    loadUserDetails();
  }, []);

  return (
    <CommonModal handleClose={handleClose} size={'max-w-[580px]'}>
      <div className="min-h-96">
        {!isUserDetailLoading ? (
          <div className="pt-5 px-5">
            <div className="flex">
              <div className="relative w-12 h-12 me-3.5 flex-none">
                {userDetails.imageUrl ? (
                  <img
                    className="w-12 h-12 rounded-full object-cover border border-black border-opacity-[0.08]"
                    src={userDetails.imageUrl + userDetails.image}
                    alt=""
                    title=""
                    onError={onError}
                  />
                ) : (
                  <label className="w-12 h-12 relative bg-primary text-white text-sm rounded-full font-medium border border-black border-opacity-[0.08] flex items-center justify-center uppercase">
                    <div className="text-2xl">
                      {getShortName(
                        `${userDetails.firstName} ${userDetails.lastName}`
                      )}
                    </div>
                  </label>
                )}
              </div>
              <div className="px-1 me-3.5 flex-auto">
                <h4 className="text-grayLight900 text-base font-semibold mb-1 break-all">{`${userDetails.firstName} ${userDetails.lastName}`}</h4>
                <div className="flex">
                  {/* <Mail01 className="w-4 h-4 me-1.5 text-grayLight600 mt-0.5 flex-none" />
                  <a
                    href={`mailto:${userDetails?.jobRole}`}
                    className="text-grayLight600 text-sm font-normal leading-tight break-all"
                  > */}
                  <span className="text-grayLight600 text-sm font-normal leading-tight break-all">
                    {capitalizeFirstLetterSpace(userDetails?.jobRole)}{' '}
                  </span>
                  {/* </a> */}
                </div>
              </div>
              <XClose
                className="w-5 h-5 text-gray400 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="md:flex">
              <div className="md:w-1/2 w-full py-4">
                <h5 className="text-textSecondary text-sm font-semibold leading-normal mb-1">
                  Account type
                </h5>
                <p className="text-gray500 text-sm font-normal leading-[20px]">
                  {userDetails?.role?.displayName}
                </p>
              </div>
              <div className="md:w-1/2 w-full md:border-t-0 border-t border-borderPrimary py-4">
                <h5 className="text-textSecondary text-sm font-semibold leading-normal mb-1">
                  Role
                </h5>
                <p className="text-gray500 text-sm font-normal leading-[20px]">
                  {userDetails?.jobRole}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-5 px-5">
            <div className="flex">
              <div className="relative w-12 h-12 me-3.5 rounded-full custom-loading">
                <img
                  className="w-12 h-12 rounded-full object-cover border border-black border-opacity-12"
                  src=""
                  alt=""
                  title=""
                  onError={onError}
                />
              </div>
              <div className="px-1 me-3.5 flex-auto">
                <div className="text-grayLight900 text-base font-semibold mb-1 custom-loading">
                  ZZZZZZZZZ ZZZZZZZ
                </div>
                <div className="flex items-center custom-loading">
                  <Mail01 className="w-4 h-4 me-1.5 text-grayLight600 mt-0.5" />
                  <a className="text-grayLight600 text-sm font-normal leading-tight">
                    ZZZZZZZ@ZZZZZZZZ.com
                  </a>
                </div>
              </div>
              <XClose
                className="w-5 h-5 text-gray400 cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="md:flex">
              <div className="md:w-1/2 w-full py-4">
                <h5 className="text-textSecondary text-sm font-semibold leading-normal mb-1 custom-loading">
                  Account type
                </h5>
                <p className="text-gray500 text-sm font-normal leading-[20px] custom-loading">
                  ZZZZZZ
                </p>
              </div>
              <div className="md:w-1/2 w-full md:border-t-0 border-t border-borderPrimary py-4">
                <h5 className="text-textSecondary text-sm font-semibold leading-normal mb-1 custom-loading">
                  Role
                </h5>
                <p className="text-gray500 text-sm font-normal leading-[20px] custom-loading">
                  ZZZZZZ
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="pt-2 px-5">
          {isUserDetailLoading ? (
            <div className="relative h-72 pt-6 pb-[5px] border-t border-borderPrimary">
              <Loader />
            </div>
          ) : (
            <>
              {userDetails?.permissions?.length > 0 && (
                <div className="pt-4 pb-2 border-t border-borderPrimary">
                  <h4 className="text-textSecondary font-semibold mb-2.5">
                    Permissions
                  </h4>
                  <ul className="flex flex-wrap">
                    {isShowMorePermission ? (
                      <>
                        {userDetails?.permissions
                          .slice(0, 6)
                          .map((perms: any) => (
                            <li
                              key={`${perms?.subModuleName} ${perms?.displayName}`}
                              className="h-[34px] text-xs px-1.5 sm:py-2 border border-gray300 rounded-md sm:me-[11px] sm:mb-[11px] py-1.5 mb-2 me-2"
                            >
                              {perms?.subModuleName} {perms?.displayName}
                            </li>
                          ))}
                      </>
                    ) : (
                      <>
                        {userDetails?.permissions.map((perms: any) => (
                          <li
                            key={`${perms?.subModuleName} ${perms?.displayName}`}
                            className="h-[34px] text-xs px-1.5 sm:py-2 border border-gray300 rounded-md sm:me-[11px] sm:mb-[11px] py-1.5 mb-2 me-2"
                          >
                            {perms?.subModuleName} {perms?.displayName}
                          </li>
                        ))}
                      </>
                    )}
                    {userDetails?.permissions.length > 6 && (
                      <li
                        onClick={() =>
                          setIsShowMorePermission((curValue) => !curValue)
                        }
                        className="h-[34px] text-xs px-1.5 sm:py-2 sm:me-[11px] sm:mb-[11px] py-1.5 mb-2 me-2 cursor-pointer"
                      >
                        <div className="flex justify-center items-center">
                          {isShowMorePermission ? (
                            <Plus className="text-gray300 w-4 h-4 mr-1" />
                          ) : (
                            <Minus className="text-gray300 w-4 h-4" />
                          )}
                          {isShowMorePermission
                            ? userDetails?.permissions.length - 6
                            : ''}
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              )}
              {userDetails?.teams?.length > 0 && (
                <div className="pt-4 pb-4 border-t border-borderPrimary">
                  {userDetails?.teams.map((teamRec: any) => (
                    <div
                      key={teamRec?.name}
                      className="flex bg-utilityGray100 rounded-[10px] items-center p-3 py-2  [&:not(:last-child)]:mb-3"
                    >
                      {teamRec?.iconUrl && teamRec?.icon ? (
                        <img
                          className="w-8 h-8 rounded-full border-black/[0.08] flex-none"
                          src={`${teamRec?.iconUrl}${teamRec?.icon}`}
                          alt="House Team"
                          title="House Team"
                        />
                      ) : (
                        <img
                          className="w-8 h-8 rounded-full border-black/[0.08] flex-none"
                          src={TeamIcon1}
                          alt="House Team"
                          title="House Team"
                        />
                      )}
                      <div className="mx-2">
                        <h4 className="text-textSecondary text-sm font-semibold leading-tight break-words">
                          {teamRec?.name}
                        </h4>
                        <span className="text-grayLight600 text-sm font-normal leading-tight">{`${
                          teamRec.managers?.length + teamRec.users?.length
                        } member`}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {userDetails?.managers?.length > 0 && (
                <div className="pt-5 pb-8 border-t border-borderPrimary flex flex-col gap-4">
                  <h4 className="text-textSecondary font-semibold">Managers</h4>
                  {userDetails?.managers?.map((managerRec: any) => (
                    <div
                      key={`${managerRec.firstName} ${managerRec.lastName}`}
                      className="flex bg-utilityGray100 rounded-[10px] items-center p-3"
                    >
                      {managerRec.imageUrl ? (
                        <img
                          className="w-8 h-8 rounded-full object-contain border border-black/[0.08] flex-none"
                          src={managerRec.imageUrl + managerRec.image}
                          alt=""
                          title=""
                          onError={onError}
                        />
                      ) : (
                        <label className="w-8 h-8 relative bg-primary text-white text-xl rounded-full font-medium border border-black/[0.08] flex items-center justify-center uppercase flex-none">
                          <div className="text-sm">
                            {getShortName(
                              `${managerRec.firstName} ${managerRec.lastName}`
                            )}
                          </div>
                        </label>
                      )}
                      <div className="mx-2">
                        <h4 className="text-textSecondary text-sm font-semibold break-all">{`${managerRec.firstName} ${managerRec.lastName}`}</h4>
                        <p className="text-grayLight600 text-sm font-normal break-all">
                          {managerRec?.email}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="p-4 border-t border-utilityGray200 text-end">
        <ButtonCmp className="btn_secondary_black" onClick={handleClose}>
          Cancel
        </ButtonCmp>
      </div>
    </CommonModal>
  );
};

export default MemberProfile;
