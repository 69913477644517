import moment from 'moment';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import {
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const OverviewTabOrderRaw = ({ data }: any) => (
  <>
    <td className="px-5 py-4 min-w-36 max-w-36">
      <h6 className="text-grayLight900 font-semibold leading-tight truncate h-8 flex items-center">{`${data.fullId}`}</h6>
    </td>
    <td className="px-5 py-4 min-w-36 max-w-36">
      {(data.orderStatus === 'new' ||
        data.orderStatus === 'saved' ||
        data.orderStatus === 'quoted' ||
        data.orderStatus === 'spot-qq-requested' ||
        data.orderStatus === 'spot-quote-requested' ||
        data.orderStatus === 'pending' ||
        data.orderStatus === 'draft-quick-quote' ||
        data.orderStatus === 'quick-quoted' ||
        data.orderStatus === 'reject' ||
        data.orderStatus === 'archived' ||
        data.orderStatus === 'expired') && (
        <BadgeCmp style="modern" type="gray">
          {data.orderStatus}
        </BadgeCmp>
      )}
      {(data.orderStatus === 'no-quote' ||
        data.orderStatus === 'no-quick-quote' ||
        data.orderStatus === 'cancelled') && (
        <BadgeCmp style="modern" type="pink">
          {data.orderStatus}
        </BadgeCmp>
      )}
      {(data.orderStatus === 'tender' ||
        data.orderStatus === 'booked' ||
        data.orderStatus === 'invoice-paid' ||
        data.orderStatus === 'delivered') && (
        <BadgeCmp style="modern" type="lightblue">
          {data.orderStatus}
        </BadgeCmp>
      )}
      {(data.orderStatus === 'invoice-created' ||
        data.orderStatus === 'invoice-sent') && (
        <BadgeCmp style="modern" type="error">
          {data.orderStatus}
        </BadgeCmp>
      )}
      {/* Tags */}
      {data.orderStatus === 'dispatched' && (
        <BadgeCmp style="modern" type="pink">
          {data.orderStatus}
        </BadgeCmp>
      )}
    </td>
    <td className="px-5 py-4 font-normal ">
      {data.orderCreatedAt &&
        moment(data.orderCreatedAt).format('MMM DD, YYYY')}
    </td>
    <td className="px-5 py-4 font-normal ">
      ${getFormattedNumber(data.combinedCost ?? 0)}
    </td>
    <td className="px-5 py-4 font-normal ">
      {data.combinedMargin >= 0
        ? `$${getFormattedNumber(data.combinedMargin, true, true)}`
        : getFormattedNumber(data.combinedMargin, true, true)}
    </td>
    <td className="px-5 py-4 font-semibold">
      ${getFormattedNumber(data.combinedRevenue ?? 0)}
    </td>
    <td className="px-5 py-4 font-normal ">
      {data.orderCommissioneeFirstName && data.orderCommissioneeLastName ? (
        <div className="flex items-center min-w-92">
          {data.orderCommissioneeImage ? (
            <img
              className="w-5 h-5 rounded-full border-[0.75px] border-black/[0.08]"
              src={data.orderCommissioneeImageUrl + data.orderCommissioneeImage}
              alt={data.orderCommissioneeFirstName}
              title={data.orderCommissioneeFirstName}
              onError={onError}
            />
          ) : (
            <div className="w-[18px] h-[18px] leading-[18px] bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase">
              {getShortName(
                `${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`
              )}
            </div>
          )}
          <div className="pl-2">
            <h6 className="text-grayLight900 font-medium leading-tight max-w-28 truncate">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
          </div>
        </div>
      ) : (
        <></>
      )}
    </td>
  </>
);

export default OverviewTabOrderRaw;
