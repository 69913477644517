import { DownloadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import { LOAD_INSURANCE_STATUS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { getShortName, onError } from 'src/utils/CommonFunctions';

const LoadInsuranceRow = ({ data, dowloadPdf }: any) => {
  const navigate = useNavigate();

  return (
    <>
      <td
        className="px-5 py-4 min-w-52 w-52 max-w-52"
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        {data.orderCommissionees?.split(',').length === 1 &&
        data.orderCommissioneeFirstName &&
        data.orderCommissioneeLastName ? (
          <div className="flex items-center">
            {data.orderCommissioneeImage ? (
              <img
                className="w-7 h-7 rounded-full border border-black/[0.08] flex-none"
                src={
                  data.orderCommissioneeImageUrl + data.orderCommissioneeImage
                }
                alt={data.orderCommissioneeFirstName}
                title={data.orderCommissioneeFirstName}
                onError={onError}
              />
            ) : (
              <div className="w-7 h-7 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white">
                {getShortName(
                  `${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`
                )}
              </div>
            )}
            <div className="pl-2 text-xs font-medium max-w-32">
              <h6 className="text-grayLight900 font-medium leading-tight truncate flex">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #{data.load_insurance_id}
              </p>
            </div>
          </div>
        ) : data.orderCommissionees?.split(',').length > 1 ? (
          <div className="text-xs font-medium max-w-32">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {data.orderCommissionees?.split(',').length} Sales Reps.
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #{data.load_insurance_id}
            </p>
          </div>
        ) : data.orderCommissionees ? (
          <div className="flex items-center">
            <div className="w-7 h-7 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white">
              {getShortName(data.orderCommissionees)}
            </div>
            <div className="pl-2 text-xs font-medium max-w-32">
              <h6 className="text-grayLight900 font-medium leading-tight truncate flex gap-1">
                <span className="inline-block max-w-32 truncate">
                  {data.orderCommissionees}
                </span>
                {/* <TooltipCmp
                message={`${data.orderCommissionees} is not an active user on WALHQ`}
              >
                <InfoCircle className="h-3.5 w-3.5" />
              </TooltipCmp> */}
              </h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #{data.load_insurance_id}
              </p>
            </div>
          </div>
        ) : (
          <div className="pl-2 text-xs font-medium max-w-32">
            <p>-</p>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #{data.load_insurance_id}
            </p>
          </div>
        )}
      </td>
      <td
        className="px-5 py-4"
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        <span>
          <a
            href={`https://wal.roserocket.com/#/ops/orders/${data.roserocketOrderId}`}
            onClick={(e) => {
              e.stopPropagation(); // Stop event propagation to prevent `td` click
            }}
            className="text-primary700 font-normal leading-tight truncate underline"
            target="_blank"
          >
            #{data.fullId}
          </a>
        </span>
      </td>
      <td
        className="px-5 py-4"
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        <div className="flex flex-wrap gap-1">
          {data.status === LOAD_INSURANCE_STATUS.APPROVED && (
            <BadgeCmp style="modern" type="success">
              Approved
            </BadgeCmp>
          )}
          {data.status === LOAD_INSURANCE_STATUS.OPEN && (
            <BadgeCmp style="modern" type="primary">
              Open
            </BadgeCmp>
          )}

          {data.status === LOAD_INSURANCE_STATUS.DECLINED && (
            <BadgeCmp style="modern" type="error">
              Declined
            </BadgeCmp>
          )}
        </div>
      </td>

      <td
        className="px-5 py-4"
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        <span>{data.commodity}</span>
      </td>
      <td
        className="px-5 py-4"
        onClick={() => navigate(`${PATH.LOAD_INSURANCE}/${data?.id}`)}
      >
        <span>$ {data.commercial}</span>
      </td>
      <td className="px-5 py-4">
        <div className="flex gap-2">
          <DownloadCloud02
            className="w-4 h-4"
            onClick={() => dowloadPdf(data?.id)}
          />
          {/* <Link to={`${PATH.LOAD_INSURANCE}/${data.id}`}>
            <Eye className="w-4 h-4" />
          </Link> */}
        </div>
      </td>
    </>
  );
};

export default LoadInsuranceRow;
