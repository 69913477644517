import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listHandlingUnit = async () => {
  const response = await axiosInterceptor.get(API.HANDLING_UNIT.LIST);

  return response;
};

export const listService = async (params: any) => {
  const response = await axiosInterceptor.get(API.SERVICES.LIST, { params });

  return response;
};

export const getPricingRate = async () => {
  const response = await axiosInterceptor.get(API.CURRENCY_RATE.GET);

  return response;
};
