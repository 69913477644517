import {
  Check,
  DownloadCloud02,
  Edit05,
  UploadCloud02,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import Loader from 'src/components/Loader';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TableCmp from 'src/components/TableCmp';
import { LOAD_INSURANCE_STATUS } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/context/AuthContext';
import {
  downloadPdf,
  getLoadInsuranceFiles,
  loadInsuranceDetail,
  updateLoadInsurance,
  uploadLoadInsurance,
} from 'src/services/LoadInsurance';
import { capitalizeFirstLetter } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import LoadInsuranceFiles from './LoadInsuranceFiles';
import LoadInsuranceFilesLoading from './LoadInsuranceFilesLoading';
// import LoadInsuranceFilesLoading from './LoadInsuranceFilesLoading';

const initAction = {
  isEdit: false,
  mode: '',
  data: {
    name: '',
    value: '',
  },
};

const LoadInsuranceDetails = () => {
  const { id } = useParams<{ id: any }>();
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [loadInsurance, setLoadInsurance] = useState<any>();
  const [isFileUploading, setIsFileUploading] = useState(false);
  // const [uploadFile, setUploadFile] = useState<any>();
  const [action, setAction] = useState(initAction);
  const [isLoading, setIsLoading] = useState(false);

  const getFiles = () => {
    setIsLoading(true);
    getLoadInsuranceFiles(id)
      .then((response: any) => {
        if (response) {
          setFileList(response?.data);
          setTotal(response?.total);
          setIsLoading(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getDetail = () => {
    setIsLoading(true);
    loadInsuranceDetail(id)
      .then((response: any) => {
        if (response) {
          setLoadInsurance(response?.data);
          setIsLoading(false);
          getFiles();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAcion = (name: string, value: any) => {
    setAction((old) => ({
      ...old,
      ...{
        isEdit: true,
        mode: name,
        data: {
          name: name,
          value: value,
        },
      },
    }));
  };

  const handleInputChange = (e: any) => {
    console.log('e :>> ', e);
    setAction((old) => ({
      ...old,
      ...{
        data: {
          name: e?.target?.name,
          value: e?.target?.value,
        },
      },
    }));
  };

  const updateStatus = (status: string) => {
    updateLoadInsurance({ status: status }, id)
      .then((response: any) => {
        if (response) {
          getDetail();
          WalToast.success(
            `Load Insurance ${capitalizeFirstLetter(status)} successfully`
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEdit = () => {
    const params: any = {
      [action?.data?.name]: action?.data?.value,
    };
    updateLoadInsurance(params, id)
      .then((response: any) => {
        if (response) {
          setAction(initAction);
          getDetail();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    console.log('action :>> ', action);
  };

  useEffect(() => {
    getDetail();
  }, []);

  const FilesheadCells = useMemo(
    () => [
      {
        id: 'filename',
        name: 'File name',
      },
      {
        id: 'dateuploaded',
        name: 'Date uploaded',
      },

      {
        id: 'uploadedby',
        name: 'Uploaded by',
      },
    ],
    []
  );

  const dowloadLadInsurancePdf = (LId: number) => {
    downloadPdf(LId)
      .then((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = `load_insurance_${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(pdfUrl);
      })
      .catch((e: any) => console.error(e?.response?.message))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setIsFileUploading(true);
    const file = acceptedFiles[0];

    // setUploadFile({ ...file });
    const appendFormData: any = new FormData();
    appendFormData.append('loadInsuranceId', id);
    appendFormData.append('uploadedBy', currentUser?.id);
    appendFormData.append('fileOriginalName', file?.name);
    appendFormData.append('size', file?.size);
    appendFormData.append('file', file);
    uploadLoadInsurance(appendFormData)
      .then((response: any) => {
        if (response) {
          getFiles();
          setTotal(response.total);
          setIsFileUploading(false);
        }
      })
      .catch((e: any) =>
        WalToast.error(
          e?.response?.data?.message ?? 'something went wrong please try again'
        )
      )
      .finally(() => {
        setIsFileUploading(false);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
  });

  return (
    <PageSectionLayout
      header={
        <Header
          title={`Load Insurance ID: #${loadInsurance?.load_insurance_id}`}
          desc="View and manage files for load insurance"
          mainContainerClassName="md:flex-nowrap"
          rightSideContent={
            <div className="flex gap-3 flex-wrap">
              <ButtonCmp
                className="btn_primary"
                onClick={() => updateStatus(LOAD_INSURANCE_STATUS.APPROVED)}
              >
                Approve
              </ButtonCmp>
              <ButtonCmp
                className="btn-outline-primary"
                onClick={() => updateStatus(LOAD_INSURANCE_STATUS.DECLINED)}
              >
                Decline
              </ButtonCmp>
              <ButtonCmp
                className="btn_secondary_black lg:px-[9px] px-2"
                onClick={() => {
                  navigate(ROUTES.LOAD_INSURANCE);
                }}
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
              {/* <ButtonCmp className="btn_secondary_black">
            <DownloadCloud02 className="w-4 h-4" />
            Proof of insurance
          </ButtonCmp> */}
            </div>
          }
        />
      }
    >
      <div className="flex justify-between items-center">
        <h6 className="text-grayLight900">Overview</h6>
      </div>
      <ul className="flex flex-wrap gap-x-10 mb-2">
        <li className="min-w-[160px]">
          <p className="text-grayLight600 text-xs font-normal mb-1">Order ID</p>
          <div className=" text-primary700 font-medium text-sm ">
            {loadInsurance?.fullId ? (
              <Link to={'#'}>#{loadInsurance?.fullId}</Link>
            ) : (
              '-'
            )}
          </div>
        </li>
        <li className="min-w-[160px]">
          <p className="text-grayLight600 text-xs font-normal mb-1">Claim ID</p>
          <div className=" text-primary700 font-medium text-sm ">
            {loadInsurance?.claimId ? (
              <Link to={'#'}>#{loadInsurance?.claimID}</Link>
            ) : (
              '-'
            )}
          </div>
        </li>
        <li className="min-w-[160px]">
          <p className="text-grayLight600 text-xs font-normal mb-1">
            Dispute ID
          </p>
          <div className=" text-primary700 font-medium text-sm ">
            {loadInsurance?.disputeId ? (
              <Link to={'#'}>#{loadInsurance?.disputeID}</Link>
            ) : (
              '-'
            )}
          </div>
        </li>
        <li className="min-w-[160px] relative">
          <p className="text-grayLight600 text-xs font-normal mb-1">
            Load Insurance
          </p>

          <div className="flex items-center">
            {action.isEdit && action.mode === 'load_insurance_id' ? (
              <>
                <InputText
                  inputName="load_insurance_id"
                  placeholder="Load Insurance Id"
                  onChangeFunc={handleInputChange}
                  value={action?.data?.value}
                  labelClassName="block mb-1.5"
                />
                <div
                  className="bg-primary p-2 mx-1 rounded-md"
                  onClick={handleEdit}
                >
                  <Check className="w-4 h-4 text-white cursor-pointer" />
                </div>
                <div
                  className="bg-danger p-2 rounded-md"
                  onClick={() => setAction(initAction)}
                >
                  <XClose className="w-4 h-4 text-white cursor-pointer" />
                </div>
              </>
            ) : (
              <>
                <p className="text-primary700 text-sm font-medium">
                  #{loadInsurance?.load_insurance_id}
                </p>
                <p className="font-medium text-primary text-xs cursor-pointer">
                  <Edit05
                    className="w-3.5 h-3.5 ml-1 text-grayLight600 cursor-pointer"
                    onClick={() =>
                      handleAcion(
                        'load_insurance_id',
                        loadInsurance?.load_insurance_id
                      )
                    }
                  />
                </p>
              </>
            )}
          </div>
        </li>
        <li className="min-w-[160px] relative">
          <p className="text-grayLight600 text-xs font-normal mb-1">
            Commodity
          </p>

          <div className="flex items-center">
            {action.isEdit && action.mode === 'commodity' ? (
              <>
                <InputText
                  inputName="commodity"
                  placeholder="Commodity"
                  onChangeFunc={handleInputChange}
                  value={action?.data?.value}
                  labelClassName="block mb-1.5"
                />
                <div
                  className="bg-primary p-2 mx-1 rounded-md"
                  onClick={handleEdit}
                >
                  <Check className="w-4 h-4 text-white cursor-pointer" />
                </div>
                <div
                  className="bg-danger p-2 rounded-md"
                  onClick={() => setAction(initAction)}
                >
                  <XClose className="w-4 h-4 text-white cursor-pointer" />
                </div>
              </>
            ) : (
              <>
                <p className="text-grayLight900 text-sm font-medium">
                  {loadInsurance?.commodity}
                </p>
                <p className="font-medium text-primary text-xs cursor-pointer">
                  <Edit05
                    className="w-3.5 h-3.5 ml-1 text-grayLight600 cursor-pointer"
                    onClick={() =>
                      handleAcion('commodity', loadInsurance?.commodity)
                    }
                  />
                </p>
              </>
            )}
          </div>
        </li>
        <li className="min-w-[160px] relative">
          <p className="text-grayLight600 text-xs font-normal mb-1">
            Commercial value
          </p>

          <div className="flex items-center">
            {action.isEdit && action.mode === 'commercial' ? (
              <>
                <InputText
                  inputName="commercial"
                  inputType="number"
                  placeholder="commercial"
                  onChangeFunc={handleInputChange}
                  value={action?.data?.value}
                  labelClassName="block mb-1.5"
                />
                <div
                  className="bg-primary p-2 mx-1 rounded-md"
                  onClick={handleEdit}
                >
                  <Check className="w-4 h-4 text-white cursor-pointer" />
                </div>
                <div
                  className="bg-danger p-2 rounded-md"
                  onClick={() => setAction(initAction)}
                >
                  <XClose className="w-4 h-4 text-white cursor-pointer" />
                </div>
              </>
            ) : (
              <>
                <p className="text-grayLight900 text-sm font-medium">
                  ${loadInsurance?.commercial}
                </p>
                <p className="font-medium text-primary text-xs cursor-pointer">
                  <Edit05
                    className="w-3.5 h-3.5 ml-1 text-grayLight600 cursor-pointer"
                    onClick={() =>
                      handleAcion('commercial', loadInsurance?.commercial)
                    }
                  />
                </p>
              </>
            )}
          </div>
        </li>
      </ul>
      <div className="flex gap-3">
        <label className="w-1/2 cursor-pointer rounded-xl border border-gray100 py-3.5 px-5 text-center bg-white">
          {isFileUploading ? (
            <div className="w-full h-[100px] flex flex-col justify-center bg-white">
              <Loader
                spinnerClass={'h-8 w-8 !text-primary'}
                spinnerWrapClass="relative !translate-x-[none] !translate-y-[none] !left-0 !top-0"
                spinnerParentClass="!max-w-full"
              />
              <p className="text-sm mt-2">File is Uploading</p>
            </div>
          ) : (
            <div className="cursor-pointer" {...getRootProps()}>
              <label>
                <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                  <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                </div>
                <div className="text-grayLight600 text-xs font-normal">
                  <p>
                    <span className="text-[#2422DD] font-semibold pr-1">
                      Click to upload{' '}
                    </span>
                    or drag and drop
                  </p>
                  <p className="text-[11px] leading-[18px]">PDF</p>
                </div>
              </label>
              <input accept=".pdf" {...getInputProps()} />
            </div>
          )}
        </label>
        <label
          className="w-1/2 cursor-pointer rounded-xl border border-gray100 py-3.5 px-5 text-center bg-white"
          onClick={() => dowloadLadInsurancePdf(id)}
        >
          <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
            <DownloadCloud02 className="w-4 h-4 text-textSecondary" />
          </div>
          <div className="text-grayLight600 text-xs font-normal">
            <p>
              <span className="text-[#2422DD] font-semibold pr-1">
                Click to download{' '}
              </span>
              proof of insurance
            </p>
          </div>
        </label>
      </div>

      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
        <div className="table-top-header ">
          <div className="table-left-wrap ">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">Files & Attachments</h5>
              <BadgeCmp style="modern" type="success">
                {total} {total > 1 ? 'Files' : 'File'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              Here is your files for load insurance
            </p>
          </div>
        </div>
        <div className="h-full min-h-[122px] w-full border-t border-gray100">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide ">
            <TableCmp
              headCells={FilesheadCells}
              tableDataArr={fileList}
              isTableDataLoading={isLoading}
              TableLoaderRowCmp={LoadInsuranceFilesLoading}
              TableRowCmp={LoadInsuranceFiles}
            />
          </div>
          {!fileList.length && !isLoading && (
            <NotFoundUI
              title={'No file attachment found'}
              desc={`There are no file attachment found`}
              containerClassName=" !h-auto"
            />
          )}
        </div>
      </div>
    </PageSectionLayout>
  );
};

export default LoadInsuranceDetails;
