import React, { useId } from 'react';

interface IProps {
  children: any;
}

interface IItemProps {
  children: any;
  title: string;
  tabId?: string;
  isActive?: boolean;
  className?: string;
}

const TabsCmp = ({ children }: IProps) => {
  const tabId = useId();

  return (
    <div role="tablist" className="tabs tabs-bordered w-full">
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { tabId: `my-tab-${tabId}` })
      )}
    </div>
  );
};

const TabsItemCmp = ({
  children,
  title,
  tabId,
  isActive,
  className,
}: IItemProps) => (
  <>
    <input
      type="radio"
      name={`my-tab-${tabId}`}
      role="tab"
      className={`tab ${className}`}
      aria-label={title}
      defaultChecked={isActive}
    />
    <div role="tabpanel" className="tab-content">
      {children}
    </div>
  </>
);

TabsCmp.ItemCmp = TabsItemCmp;

export default TabsCmp;
