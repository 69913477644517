import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const SalesRepCommissionReportRow = ({ data }: any) => {
  console.log('commission report row data');

  return (
    <>
      <td className="px-5 py-4 ">
        <h6 className=" h-8 text-primary underline flex items-center font-medium hover:text-primary700 cursor-pointer">
          {data.orderId}
        </h6>
      </td>
      <td className="px-5 py-4 ">
        <div className="flex gap-1 flex-wrap">
          <BadgeCmp style="modern" type="success">
            New
          </BadgeCmp>
          <BadgeCmp style="modern" type="primary">
            Dispatched
          </BadgeCmp>
          <BadgeCmp style="modern" type="warning">
            Invoice- created
          </BadgeCmp>
        </div>
      </td>
      <td className="px-5 py-4 ">
        <span>{data.orderCreatedAt}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data.combinedCost}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data.combinedRevenue}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data.combinedMargin}</span>
      </td>
      <td className="px-5 py-4 ">
        <span className="font-semibold">$901.06 CAD</span>
      </td>
    </>
  );
};

export default SalesRepCommissionReportRow;
