import React from 'react';
import { toast, cssTransition, ToastTransition } from 'react-toastify';

import ToastComp from '../components/ToastComp';

const slideInZoomOut: ToastTransition = cssTransition({
  enter: 'Toastify__slide-enter',
  exit: 'Toastify__slide-exit',
});

const success = (
  title: string,
  subTitle: string = '',
  className: string = ''
) => {
  toast.success(<ToastComp title={title} desc={subTitle} type="success" />, {
    className: `${className} !absolute sm:w-96 w-screen sm:top-4 sm:right-4 top-0 right-0 !shadow-lg !p-4 border border-borderPrimary`,
    icon: false,
    hideProgressBar: true,
  });
};

const copy = (
  title: string,
  subTitle: string = '',
  className: string = '',
  position: (typeof toast.POSITION)[keyof typeof toast.POSITION] = toast
    .POSITION.TOP_CENTER
) => {
  toast.success(
    <ToastComp title={title} desc={subTitle} type="success" isIcon={false} />,
    {
      className: `${className} w-[190px] mx-auto !bg-primary500 !text-white !min-h-fit !pl-2.5 !pr-4 top-2 !mb-3 !shadow-lg`,
      icon: false,
      hideProgressBar: true,
      position: position,
      transition: slideInZoomOut,
    }
  );
};

const error = (
  title: string,
  subTitle: string = '',
  className: string = ''
) => {
  toast.error(<ToastComp title={title} desc={subTitle} type="error" />, {
    className: `${className} test-toast !absolute sm:w-96 w-screen sm:top-4 sm:right-4 top-0 right-0 !shadow-lg !p-4 border border-borderPrimary`,
    icon: false,
    hideProgressBar: true,
  });
};

const info = (title: string, subTitle: string = '', className: string = '') => {
  toast.info(<ToastComp title={title} desc={subTitle} type="info" />, {
    className: `${className} !absolute sm:w-96 w-screen sm:top-4 sm:right-4 top-0 right-0 !shadow-lg !p-4 border border-borderPrimary`,
    icon: false,
    hideProgressBar: true,
    autoClose: false,
  });
};

const WalToast = { success, error, info, copy };

export default WalToast;
