export const API = {
  USER: {
    LOGIN: '/user/login',
    LOGOUT: '/user/logout',
    CODE: '/user/code',
    GET: '/user/get',
    LIST: '/user',
    CREATE: '/user',
    UPDATE: '/user',
    DELETE: '/user',
    PERMISSION: '/user/permissions',
    GET_DETAIL: '/user/get-detail',
    REMOVE_FROM_TEAM: '/user/remove-from-team',
    USER_STATUS: '/user/status',
    ENTITY_COUNT: '/entity-counts',
  },
  TEAM: {
    LIST: '/team',
    LIST_ALL: '/team/list',
    CREATE: '/team/create',
    UPDATE: '/team/update',
    DELETE: '/team',
    DETAIL: '/team',
    ORG_CHART: '/ORGANIZATIONAL-chart',
  },
  CARRIER: {
    LIST: '/carrier',
    CREATE: '/carrier',
    UPDATE: '/carrier',
    DELETE: '/carrier',
    UPDATE_IMAGE: '/carrier/image',
    GET_SERVICE_PRICES: '/carrier-service-prices',
    UPDATE_SERVICE_PRICES: '/carrier-service-prices',
  },
  CARRIER_CONTACT: {
    LIST: '/carrier-details',
    CREATE: '/carrier-details',
    UPDATE: '/carrier-details',
    DELETE: '/carrier-details',
  },
  QUOTE_EMAIL: {
    GET: '/quote-email',
    SEND_REPLY: '/send-reply',
    SET_EMAIL_READ: '/set-message-read',
    FETCH_EMAIL_DETAILS: '/fetch-email-details',
    GET_OUTLOOK_INTEGRATION: '/get-outlook-integration',
    INTEGRATE_OUTLOOK: '/integrate-outlook',
    DELETE_QUOTE_EMAIL: 'quote-email/delete',
  },
  QUOTE: {
    GET: '/quote',
    GET_USER_QUOTES: '/user/quotes',
    LIST: '/quote',
    CREATE: '/quote/address',
    UPDATE: '/quote/address',
    CREATE_UPDATE_DIMENSION: '/quote/dimension',
    CREATE_UPDATE_SERVICE: '/quote/service',
    CREATE_UPDATE_SUMMARY: '/quote/summary',
    CREATE_RATE: '/quote/rate',
    UPDATE_RATE: '/quote/rate',
    CANCEL: '/quote/cancel',
    CREATE_PDF: '/quote/pdf',
    UPDATE_PDF: '/quote/pdf',
    LIST_PDF: '/quote/pdf',
    UPDATE_STATUS: '/quote/status',
    PUSH_TO_ROSE_ROCKET: '/quote/roserocket-order',
    BOOK_ORDER: '/quote/book-order',
    DELETE: '/quote',
    MODIFY_QUOTE: '/quote/modifyquote',
    SPOT_QUOTE: '/quote/spot-quote-request',
    SPOT_QUOTE_UPDATE: '/quote/update-spot-quote',
    GET_FREIGHT_CLASS: '/quote/dimension/get-freight-class',
    GET_PREDICTIVE_PRICING: '/quote/predictive-pricing',
    MAKE_NO_QUOTE: '/quote/make-no-quote',
    LOAD_LINK: {
      GET_VEHICLE_TYPE: '/load-link/vehicle_type',
      GET_VEHICLE_SIZE: '/load-link/vehicle_size',
      GET_VEHICLE_ATTRIBUTE: '/load-link/vehicle_attribute',
      CREATE_POSTING: '/load-link/create-load-link-post',
      DELETE_POSTING: '/load-link/delete-load-link-post',
      GET_LEADS: '/load-link/leads',
    },
  },
  SHIPPER: {
    LIST: '/shipper',
    CREATE: '/shipper',
    DELETE: '/shipper',
  },
  CUSTOMER: {
    LIST: '/customer',
    UPDATE_CUSTOMER: '/customer/update-customer',
    TOP_CUSTOMERS: '/top-customers',
    GET: 'customer-list',
    DETAIL: 'customer-detail',
    SEGMENT: 'customer-segment-total',
    DELETE: '/customer',
    UPDATE_IMAGE: '/customer/image',
    ORDER_KEY_METRICS: 'customer-detail/key-metrics',
    LIST_SALES_REP_HISTORY: '/customer/sales-rep-history',
    SALES_REP: 'customer/sales-rep',
    UPDATE_SALES_REP: 'customer/update-sales-rep',
    REASSIGN_SALES_REP: 'customer/reassign-sales-rep',
    CONTACTS: {
      GET: '/customer/contacts',
      LIST: '/customer/contacts',
      DELETE_CONTACT: '/customer/contacts',
    },
    CREDIT: {
      CUSTOMER_CREDIT_DETAIL: '/customer/credit-details',
      CREDIT_ORDER_LIST: '/customer/list-credit-orders',
    },
    GROUP: {
      CUSTOMER_GROUP_LIST: '/customer/groups',
      DELETE_CUSTOMER_GROUP_LIST: '/customer/groups',
      CREATE_UPDATE_CUSTOMER_GROUP: '/customer/groups',
    },
  },
  CUSTOMER_ONBOARDING: {
    CREATE: '/customer-onboarding',
    SEND_MAIL: '/customer-onboarding/send-mail',
    RESEND_MAIL: '/customer-onboarding/resend-mail',
    VERIFY_OTP: '/customer-onboarding/verify-otp',
    UPDATE: '/customer-onboarding/update',
    DELETE_STATEMENT: '/customer-onboarding/delete-statement',
    UPLOAD_FINANCE_STATEMENT: '/customer-onboarding/upload-finance-statement',
    BUSINESS_CREDIT_APPLICATION:
      '/customer-onboarding/business-credit-application',
    BUSINESS_CREDIT_APPLICATION_DETAIL:
      '/customer-onboarding/business-credit-application-detail',
    CREDIT_REFERENCE_ATTACHMENT:
      '/customer-onboarding/credit-reference-attachment',
    DELETE_ATTACHMENT: '/customer-onboarding/delete-attachment',
    GET_CREDIT_REFERENCE_ATTACHMENT:
      '/customer-onboarding/get-credit-reference-attachment',
    CREDIT_REFERENCE_NOTE: '/customer-onboarding/create-credit-reference-note',
    GET_CREDIT_REFERENCE_NOTE:
      '/customer-onboarding/get-credit-reference-notes',
  },
  GEOGRAPHY: {
    GET: '/population-data',
  },
  HANDLING_UNIT: {
    LIST: '/handling-unit',
  },
  ROLE: {
    LIST: '/role',
  },
  PERMISSION: {
    LIST: '/permissions',
  },
  SERVICES: {
    LIST: '/service',
  },
  CURRENCY_RATE: {
    GET: '/currency/rate',
  },
  FEEDBACK: {
    CREATE: '/user/feedback',
    GET: '/user/feedback',
    UPDATE_STATUS: '/user/feedback-status',
  },
  CREDIT_DASHBOARD: {
    LIST: '/customer-onboarding',
  },
  LOAD_INSURANCE: {
    LIST: '/load-insurance',
    CREATE: '/load-insurance/create',
    GET: '/load-insurance',
    UPDATE: '/load-insurance/update',
    UPLOAD: '/load-insurance/upload-files',
    GETFILES: '/load-insurance/get-files',
    DOWNLOADPDF: '/load-insurance/download',
  },
  SALES_DASHBOARD: {
    UPLOAD: '/sales/upload-data',
    ASSIGN_SALES_REPS: '/sales/assign-sales-reps',
    UPLOAD_LIST: '/sales/get-upload-history',
    TEAM_PERFORMANCE_LIST: '/sales/team-performance-list',
    SALES_DATA: '/sales/get-data',
    SALES_ANALYTICS_DATA: '/sales/get-sales-data',
    SALES_GRAPH: '/sales/sales-graph-data',
    INDIVIDUAL_GRAPH: '/sales/graph-data',
    TEAM_LIST: 'sales/team-list',
    DELETE_DATA: 'sales/reset-data',
    DOWNLOAD_FILE: 'sales/download-file',
    CONFIRM_UPLOAD: 'sales/confirm-upload-data',
    SEND_DOWNLOAD_OTP: '/sales/send-download-otp',
    VERIFY_DOWNLOAD_OTP: '/sales/verify-download-otp',
    LAST_SYNC: '/sales/upload-detail',
    CHECK_PENDING_UPLOAD: '/sales/check-pending-upload',
  },
  ORDERS: {
    GET_UNASSIGNED_ORDERS_BY_FILE: '/orders/get-unassigned-orders-by-file',
    LIST: '/orders/get-orders-list',
    GET_ORDERS: '/orders/get-orders',
  },
  INTEGRATIONS: {
    LIST: '/integrations',
    TYPE: '/integrations/type',
    MAP_SERVICE: '/integrations/map-service',
    SET_CONFIGURATIONS: '/integrations/configurations',
    ENABLE_INTEGRATION: '/integrations/enable',
  },

  TARGET: {
    LIST: '/sales/target-list',
    SET: '/sales/set-target',
    CUSTOMER_LIST: '/sales/customer-list',
  },

  CLAIMS: {
    LIST: '/claims-list',
    CLAIM_UPDATE_STATUS: '/claim/update-status',
    MODIFY_CLAIM_AMOUNT: '/claim/modify-claim-amount',
    CLAIM_DETAILS: '/claim',

    CLAIM_FILE_UPLOAD: {
      LIST: '/claim-dispute/attachment',
      DELETE_FILE: '/claim-dispute/attachment',
      CLAIM_ADD_FILE: '/claim-dispute/claim-attachment-create',
      DISPUTE_ADD_FILE: '/claim-dispute/dispute-attachment-create',
    },
  },

  DISPUTES: {
    LIST: '/disputes-list',
    DISPUTE_UPDATE_STATUS: '/dispute/update-status',
    MODIFY_DISPUTE_AMOUNT: '/dispute/modify-dispute-amount',
    DISPUTE_DETAILS: '/dispute',
  },

  CLAIMS_AND_DISPUTE: {
    CHAT_MESSAGE: {
      LIST: '/chat-messages',
      SEND_MESSAGE: '/chat-messages/send',
    },
    INTERNAL_NOTES: {
      LIST: '/claim-and-dispute/get-internal-notes',
      CREATE: '/claim-and-dispute/create-internal-notes',
    },
    CLAIMS_DISPUTE_TASK: {
      LIST: '/claim-dispute/action-item',
      ADD_TASK: '/claim-dispute/action-item',
      UPDATE_TASK: '/claim-dispute/action-item',
      DELETE_TASK: '/claim-dispute/action-item',
    },
  },

  NOTIFICATION: {
    LIST: '/notification',
    MARK_AS_READ_ALL: '/notifications/mark-as-read-all',
    READ_NEW_CLAIM_DISPUTE_NOTIFICATION:
      '/notifications/update-claim-and-dispute-status',
  },

  COMMISSION: {
    COMMISSION_ORDER_LIST: '/commission/orders',
    COMMISSION_ORDER_STATS: '/commission/orders/stats',
    MONTHLY_COMMISSION_REPORT: '/commission/monthly-report',
    ALL_MONTH_REPORT: '/commission/all-month',
  },
  APPEARANCE: {
    CREATE: '/appearance/create',
    GET: '/appearance/get',
  },
};
