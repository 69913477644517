import React from 'react';

import PageSectionLayout from '../../../components/PageSectionLayout';
import Header from '../../../components/PageSectionLayout/Header/Header';

const RateShopping = () => (
  <PageSectionLayout
    header={
      <Header
        title="Rate Shopping"
        desc="Track, manage and forecast your customers and orders."
      />
    }
  >
    <div className="h-full w-full">Rate Shopping</div>
  </PageSectionLayout>
);

export default RateShopping;
