/* eslint-disable max-lines-per-function */
import {
  Eye,
  FilterLines,
  MessageChatCircle,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { orderBy, minBy } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HiOutlineX } from 'react-icons/hi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FeedbackCreate from 'src/app/Feedback/FeedbackCreate';
import CustomPagination from 'src/components/CustomPagination';
import WalToast from 'src/utils/WalToast';

import defaultImage from '../../../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../../../assets/img/frieghtcom.png';
import truckLoader from '../../../../../assets/img/truckloader.gif';
import ButtonCmp from '../../../../../components/ButtonCmp';
import CheckBox from '../../../../../components/CheckBox';
import CommonModal from '../../../../../components/CommonModal';
import ConfirmModal from '../../../../../components/ConfirmModal';
import InputText from '../../../../../components/InputText/InputText';
import NotFoundUI from '../../../../../components/NotFoundUI';
import PageSectionLayout from '../../../../../components/PageSectionLayout';
import Header from '../../../../../components/PageSectionLayout/Header/Header';
import { CURRENCY, ROSE_ROCKET, STATUS } from '../../../../../constants/common';
import { ROUTES } from '../../../../../constants/routes';
import { AuthContext } from '../../../../../context/AuthContext';
import { BasicContext } from '../../../../../context/BasicContext';
import { listCarrier } from '../../../../../services/CarrierService';
import {
  createRate,
  spotQuoteRequest,
  updateStatus,
} from '../../../../../services/QuoteService';
import { fetchJsFromCDN, onError } from '../../../../../utils/CommonFunctions';
import PDF from '../Quote/PDF';
import { quoteDetail } from '../SmartQuote.interface';

import Closed from './Closed';
import Pricing from './Pricing';
import QuoteDetail from './QuoteDetail';
import SpotQuote from './SpotQuote';
import SpotQuoteConfirmation from './SpotQuoteConfirmation';

const initAction = {
  mode: '',
  pricing: false,
  pdf: false,
  closedQuote: false,
  closedReason: false,
  informationModel: false,
  quoteDetail: false,
  orderSuccessModel: false,
  spotQuoteModel: false,
  spotQuoteConfirmation: false,
};

const MyQuote = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);
  const [addressDetails, setAddressDetails] = useState<any>({});
  const [dimensions, setDimensions] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  let [carrierRates, setCarrierRates] = useState<any[]>([]);
  const [action, setAction] = useState(initAction);
  const [selectedCarrierRates, setSelectedCarrierRates] = useState<any>([]);
  const [sortBy] = useState('pricing');
  const [colors, setColors] = useState<any>({});
  const [isGetRate, setIsGetRate] = useState(false);
  const [quote, setQuote] = useState<any>({});
  const [isFinish, setIsFinish] = useState(true);
  const [rossRocketId, setRossRocketId] = useState('');
  const [rossRocketPublicId, setRossRocketPublicId] = useState('');
  const [isMainLoading, setIsMainLoading] = useState(false);
  const { currency } = useContext(BasicContext);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  // const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isDisplayReqSpotQuote, setIsDisplayReqSpotQuote] =
    useState<boolean>(false);
  // const [displayQuote, setDisplayQuote] = useState<any[]>([]);
  const [isShowFeedbackForm, setIsShowFeedbackForm] = useState<boolean>(false);
  const [isAllCarrierRateFetched, setIsAllCarrierRateFetched] =
    useState<boolean>(true);
  const [carrierRateFetchStatus, setCarrierRateFetchStatus] = useState<any>({});
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);
  const [carrierList, setCarrierList] = useState<any>([]);

  const sortCarrierRates = (carrierRatesValue: any) => {
    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }
    const lowestCharge: any = minBy(validCarrierRates, 'finalCharge');
    const fastestDelivery: any = minBy(
      validCarrierRates,
      'projectedDeliveryDate'
    );
    const appendCarrierRates = validCarrierRates.map((carrierRate: any) => {
      const tags = [];

      if (carrierRate.finalCharge === lowestCharge.finalCharge) {
        tags.push('Cheapest');
      }

      if (
        carrierRate.projectedDeliveryDate ===
        fastestDelivery.projectedDeliveryDate
      ) {
        tags.push('Fastest');
      }
      carrierRate.tags = tags;

      return carrierRate;
    });

    const sortedCarrierRates = orderBy(
      appendCarrierRates,
      sortBy === 'pricing' ? 'finalCharge' : 'projectedDeliveryDate',
      'asc'
    );
    // console.log("sortedCarrierRates", sortedCarrierRates, " ", isDisplayReqSpotQuote);
    // if (!isDisplayReqSpotQuote) {
    //     setDisplayQuote(sortedCarrierRates);
    // } else {
    //     setDisplayQuote(invalidCarrierRates);
    // }

    const allCarrierRates = sortedCarrierRates.concat(invalidCarrierRates);

    setCarrierRates(allCarrierRates);
  };

  const handleAllColors = (addressDetailsObj: any) => {
    const { status } = addressDetailsObj;
    const colorsObj = {
      badgeColor: ' bg-gray-100 ',
      badgeText: ' text-grayText ',
      textColor: ' text-black ',
      bgColor: '',
      borderColor: '',
    };

    switch (status) {
      case STATUS.OPENED:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.ACTIVE:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.WON:
        colorsObj.badgeColor = ' bg-green100 ';
        colorsObj.badgeText = ' text-green ';
        colorsObj.textColor = ' text-green ';
        colorsObj.bgColor = ' bg-green100 ';
        colorsObj.borderColor = ' border-green ';
        break;
      case STATUS.LOST:
      case STATUS.EXPIRED:
        colorsObj.badgeColor = ' bg-red-100 ';
        colorsObj.badgeText = ' text-danger ';
        colorsObj.borderColor = ' border-danger ';
        break;
      default:
        break;
    }
    setColors(colorsObj);
  };

  useEffect(() => {
    if (id) {
      localStorage.removeItem('isModifyQuoteFromQuote');
      localStorage.removeItem('isPickupDateChanged');

      if (isRefresh) {
        setAddressDetails(quoteDetail?.addressDetails);
        setDimensions(quoteDetail?.dimensions);
        setQuote(quoteDetail);
        setServices(quoteDetail?.additionalService);
        // setServices(additionalService);

        setIsLoading(false);

        if (quoteDetail?.carrierRates && quoteDetail?.carrierRates.length) {
          sortCarrierRates(quoteDetail?.carrierRates);
          setIsLoading(false);
        } else {
          setIsGetRate(true);
        }
        handleAllColors(quoteDetail?.addressDetails);
      }
    } else {
      navigate(ROUTES.HOME);
    }
  }, [isRefresh]);

  const connectSocket = () => {
    setIsFinish(false);

    fetchJsFromCDN(
      'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
      ['io']
    ).then(([io]: any) => {
      io.sails.url = process.env.REACT_APP_SERVER_URL;
      io.socket.on('connect', function socketConnected() {
        io.socket.get(
          `/subscribe/${currentUser.id}`,
          function (data: any, jwr: any) {
            if (jwr.error) {
              return;
            }
            setIsSocketConnected(true);
          }
        );
      });

      io.socket.on('user', function (data: any) {
        if (data.type === 'NEW_RATE') {
          if (data?.data && data?.data?.quoteId === parseInt(id)) {
            carrierRates = [...carrierRates, data.data];
            sortCarrierRates(carrierRates);
            setIsLoading(false);
          }
        } else if (data?.type === 'FINISH_LOADING') {
          carrierRateFetchStatus[data.carrier] = true;
          setCarrierRateFetchStatus((old: any) => ({
            ...old,
            [data.carrier]: true,
          }));
          // setIsFinish(true);
        }
      });
    });
  };

  const handleActionType =
    (actionKey: string, mode: string = '', carrierRate: any = null) =>
    () => {
      console.log(
        'action:: ',
        action,
        'actionKey:: ',
        actionKey,
        'mode:: ',
        mode,
        'carrierRate',
        carrierRate
      );

      if (carrierRate) {
        let newCarrierRate: any = {};

        if (currency === CURRENCY.USD) {
          newCarrierRate = { ...carrierRate };
          newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
          newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
          newCarrierRate.margin = carrierRate.marginUSD;
          newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

          if (newCarrierRate.services.length) {
            newCarrierRate.services = newCarrierRate.services.map(
              (item: any) => ({
                ...item,
                value: item.valueUSD,
              })
            );
          }
        } else {
          newCarrierRate = { ...carrierRate };
        }
        setSelectedCarrierRates([newCarrierRate]);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
    setSelectedCarrierRates([]);
  }, []);

  const handleSubmit = () => {
    navigate(ROUTES.HOME);
  };

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [currency]);

  useEffect(() => {
    if (isGetRate) {
      listCarrier({
        search: '',
        sortDirection: 'DESC',
        sortBy: 'quoteCount',
        page: 1,
        limit: 100,
        isFreightcom: 0,
      })
        .then((response: any) => {
          let carriers = response?.data || [];

          if (carriers?.length) {
            connectSocket();
            setIsFinish(false);
            setIsAllCarrierRateFetched(false);
          }

          setCarrierList(carriers || []);
        })
        .finally(() => {
          // setIsLoading(false);
          // setIsRefresh(false);
        })
        .catch(console.log);
    }
  }, [isGetRate]);

  useEffect(() => {
    if (carrierRates?.length) {
      sortCarrierRates(carrierRates);
    }
  }, [sortBy, isDisplayReqSpotQuote]);

  const modifyQuoteStatus = (quoteStatusId: number) => () => {
    console.log('quoteStatusId', quoteStatusId);
  };

  useEffect(() => {
    if (carrierRates?.length) {
      setIsGetRate(false);
    }
  }, [carrierRates]);

  useEffect(() => {
    if (isSocketConnected && carrierList?.length && isGetRate) {
      carrierList.forEach((element: any) => {
        setCarrierRateFetchStatus((old: any) => ({
          ...old,
          [element.slug]: false,
        }));

        let formData = quote;
        formData.carrierId = element.id;
        createRate(formData)
          .then()
          .finally()
          .catch((error: any) => {
            console.error(error);
            WalToast.error(
              error?.response?.data?.message ||
                'Carrier rates have not been created'
            );
          });
      });
    }
  }, [isSocketConnected]);

  useEffect(() => {
    let allFetched: boolean = Object.keys(carrierRateFetchStatus).length !== 0;

    for (const key in carrierRateFetchStatus) {
      if (!carrierRateFetchStatus[key]) {
        allFetched = false;
      }
    }
    setIsAllCarrierRateFetched(allFetched);
  }, [carrierRateFetchStatus]);

  useEffect(() => {
    if (isAllCarrierRateFetched) {
      setIsFinish(true);
      setCarrierRateFetchStatus({});
    }
  }, [isAllCarrierRateFetched]);

  const ReqQuoteHeadCells = useMemo(
    () => [
      {
        id: 'Name',
        name: 'Carrier',
        sortable: true,
        visible: true,
      },

      {
        id: 'DeliveryTime',
        name: 'Delivery Time',
        sortable: true,
        visible: true,
      },
      {
        id: 'TransitTime',
        name: 'Transit time',
        sortable: true,
        visible: true,
      },

      {
        id: 'ProjectedDelivery',
        name: 'Projected delivery',
        sortable: true,
        visible: true,
      },
      {
        id: 'BaseQuote',
        name: 'Base Quote',
        sortable: true,
        visible: true,
      },
      {
        id: 'AdditionalServices',
        name: 'Additional services',
        sortable: true,
        visible: true,
      },
      {
        id: 'Total',
        name: 'Total',
        sortable: true,
        visible: true,
      },
      {
        id: 'action',
        visible: true,
      },
    ],
    []
  );

  const CarrierRatesHeadCells = useMemo(
    () => [
      {
        id: 'Name',
        name: 'Name',
        sortable: true,
        visible: true,
      },
      {
        id: 'Delivery',
        name: 'Delivery',
        sortable: true,
        visible: true,
      },
      {
        id: 'Provider',
        name: 'Provider',
        sortable: true,
        visible: true,
      },

      {
        id: 'ProjectedDelivery',
        name: 'Projected delivery',
        sortable: true,
        visible: true,
      },
      {
        id: 'BaseQuote',
        name: 'Base Quote',
        sortable: true,
        visible: true,
      },
      {
        id: 'AdditionalServices',
        name: 'Additional services',
        sortable: true,
        visible: true,
      },
      {
        id: 'TransitTime',
        name: 'Transit time',
        sortable: true,
        visible: true,
      },
      {
        id: 'Total',
        name: addressDetails.status === STATUS.OPENED ? 'Total' : 'Final Quote',
        sortable: true,
        visible: true,
      },
      {
        id: 'action',
        visible: true,
      },
    ],
    []
  );

  const requestSpotQuote = (
    quoteID: number,
    isDoNotAskChecked: boolean = false
  ) => {
    const data: any = {
      doNotAskSpotQuote: isDoNotAskChecked,
    };
    spotQuoteRequest(data)
      .then(() => {
        if (isDoNotAskChecked) {
          setCurrentUser({
            ...currentUser,
            doNotAskSpotQuote: isDoNotAskChecked,
          });
        }
        setIsRefresh(true);
        WalToast.success('Spot quote request sent successfully');
      })
      .finally(() => setIsLoading(false))
      .catch(console.error);
  };

  const handleModalClose = useCallback(
    (status: boolean, isDoNotAskChecked: boolean = false) =>
      () => {
        setAction(initAction);

        if (status) {
          if (action.closedQuote) {
            setAction((old) => ({ ...old, closedReason: true }));
          } else if (action.informationModel) {
            setIsMainLoading(true);
            updateStatus(selectedCarrierRates[0].id)
              .then((response: any) => {
                if (response && response.data) {
                  setRossRocketId(response.data.rossRocketId);
                  setRossRocketPublicId(response.data.rossRocketPublicId);
                  setAction((old) => ({ ...old, orderSuccessModel: true }));
                }
              })
              .finally(() => {
                setIsMainLoading(false);
              })
              .catch((error) => {
                console.log(error);
                WalToast.error(
                  error?.response?.data?.message || 'Something went wrong'
                );
              });
          } else if (action.spotQuoteConfirmation) {
            requestSpotQuote(selectedCarrierRates[0].id, isDoNotAskChecked);
          }
        }

        if (action.orderSuccessModel) {
          setIsRefresh(true);
        }
      },
    [action]
  );

  const rightSideContent = (
    <>
      {!isLoading && (
        <>
          <ButtonCmp
            type="submit"
            className="btn_primary "
            onClick={modifyQuoteStatus(addressDetails.id)}
          >
            {addressDetails.status === STATUS.OPENED
              ? 'Modify Quote'
              : 'Duplicate Quote'}
          </ButtonCmp>
          <ButtonCmp
            type="button"
            className="btn_secondary_black !p-2"
            onClick={handleActionType('closedQuote')}
          >
            <XClose className="w-4 h-4" />
          </ButtonCmp>
        </>
      )}
      {addressDetails.status === STATUS.ACTIVE ||
      addressDetails.status === STATUS.WON ||
      addressDetails.status === STATUS.EXPIRED ||
      addressDetails.status === STATUS.LOST ? (
        <>
          <button
            className="bg-white px-3 md:min-w-[180px] border border-primary inline-block text-center py-3 ml-5 rounded-full text-sm font-semibold text-primary transition-all ease delay-75 hover:bg-primary700 hover:text-white"
            onClick={handleActionType('pdf')}
          >
            View Generated PDFs
          </button>
          <div
            className="font-semibold inline-block ml-3 px-3 text-center align-middle	cursor-pointer"
            onClick={handleSubmit}
          >
            <HiOutlineX size={40} />
          </div>
        </>
      ) : (
        !isLoading && <></>
        // addressDetails.status === STATUS.OPENED && (
        //     <>
        //         <div className="font-semibold inline-block ml-3 px-3 text-center align-middle cursor-pointer" onClick={handleSubmit}>
        //             <HiOutlineX size={40} />
        //         </div>
        //     </>
        // )
      )}
    </>
  );

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={`Quote: # ${addressDetails.code ?? ''}`}
            desc="Track, manage and forecast your customers and orders."
            rightSideContent={rightSideContent}
            labelClassName={`${colors.badgeColor} ${colors.badgeText} ${colors.borderColor}`}
            labelTextColor={`${colors.badgeText}`}
          />
        }
      >
        {isMainLoading && (
          <div className="flex justify-center items-center flex-1 absolute top-0 left-0 right-0 bottom-0 z-50 bg-[#000]/60">
            <div className="relative">
              <div className="loader_spinner ease-linear rounded-full border-8 border-t-8 border-white h-64 w-64"></div>
              <h2 className="text-white text-lg absolute  top-[50%] bottom-0 left-[50%] -translate-x-[50%] -translate-y-[10%] w-11/12 text-center">
                Please wait...
              </h2>
            </div>
          </div>
        )}

        {isLoading ? (
          <div className="flex justify-center items-center flex-1 mt-4">
            <div className="relative">
              <div className="loader_spinner ease-linear rounded-full border-8 border-t-8 border-gray-200 h-14 w-14"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between items-center">
              <div className="flex justify-center items-center">
                <h1 className="text-xl font-bold">Quote Details</h1>
                <div
                  className="flex ml-2 justify-center cursor-pointer items-center text-primary"
                  onClick={handleActionType('quoteDetail', '', carrierRates)}
                >
                  <Eye className="w-4 h-4 " onClick={() => {}} />
                  <span className="text-xs ml-1">View quote details</span>
                </div>
              </div>
              <ButtonCmp
                type="submit"
                className="btn_primary  btn-heigh-auto"
                onClick={() => {}}
              >
                Select Quotes
              </ButtonCmp>
            </div>
            <div className="min-h-full w-full flex flex-col bg-white rounded-[10px] border border-utilityGray200 overflow-auto scrollbar-hide ">
              <div className="flex py-[12px] px-5 items-center justify-between">
                <div className="w-auto bg-white text-textSecondary items-center text-center rounded-md border border-borderPrimary text-xs font-semibold flex">
                  <div
                    className={`py-2 px-4 cursor-pointer rounded-l-md ${
                      !isDisplayReqSpotQuote ? 'bg-gray50' : ''
                    }`}
                    onClick={() => setIsDisplayReqSpotQuote(false)}
                  >
                    Carrier Rates
                  </div>
                  <div
                    className={`py-2 px-4 cursor-pointer rounded-r-md border-l border-borderPrimary ${
                      isDisplayReqSpotQuote ? 'bg-gray50' : ''
                    }`}
                    onClick={() => setIsDisplayReqSpotQuote(true)}
                  >
                    Request Spot Quote
                  </div>
                </div>
                <div className="flex gap-4">
                  <InputText
                    inputName="searchQuote"
                    placeholder="Search"
                    className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
                    icon={
                      <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                    }
                    value=""
                    inputType="text"
                    onChangeFunc={() => {}}
                  />

                  <div className="justify-end items-start gap-4 flex">
                    <ButtonCmp
                      className="btn_secondary_black  flex btn-sm btn-heigh-auto"
                      onClick={() => {}}
                      icon={
                        <FilterLines className="text-textSecondary h-4 w-4" />
                      }
                    >
                      Filters
                    </ButtonCmp>
                  </div>
                </div>
              </div>
              {!isFinish && (
                <div className="flex flex-col justify-center items-center flex-1 mt-4">
                  <img
                    src={truckLoader}
                    alt=""
                    width={400}
                    className="rounded-2xl"
                  />
                </div>
              )}
              <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide"></div>
                {isDisplayReqSpotQuote ? (
                  <>
                    <table className="w-full">
                      <thead>
                        <tr className="text-grayLight600 bg-gray50 text-[11px]">
                          {ReqQuoteHeadCells.map(
                            (head) =>
                              head.visible && (
                                <th
                                  scope="col"
                                  className="lg:min-w-[210px] xxl:min-w-[unset] px-5 py-3 font-medium"
                                  key={head.id}
                                >
                                  <div className="flex items-center">
                                    {head.id === 'Name' ? (
                                      <span className="pr-2.5">
                                        <CheckBox
                                          className="border-gray100 bg-gray50 checked:bg-white checked:text-gray200 rounded-sm text-black"
                                          onChangeFunc={() =>
                                            setIsChecked(!isChecked)
                                          }
                                          checked={isChecked}
                                        />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    <span> {head.name}</span>
                                  </div>
                                </th>
                              )
                          )}
                        </tr>
                      </thead>
                      {carrierRates.length ? (
                        <tbody className="text-xs">
                          {carrierRates.map(
                            (carrierRate: any, index: number) => (
                              <tr
                                key={index}
                                className="text-grayLight600 text-xs bg-white rounded-md cursor-pointer transition duration-300 hover:bg-gray-100"
                              >
                                <td className="px-5 py-4">
                                  <div className="flex items-center">
                                    <span className="pr-2.5">
                                      <CheckBox
                                        classes="border-gray100 bg-gray50 checked:bg-white checked: text-gray200 rounded-sm text-black h-3 w-3"
                                        onChangeFunc={() =>
                                          setIsChecked(!isChecked)
                                        }
                                        checked={isChecked}
                                      />
                                    </span>
                                    {carrierRate.image ? (
                                      <img
                                        src={`${carrierRate.imageUrl}${carrierRate.image}`}
                                        className="w-7 h-7 rounded-full border"
                                        alt={carrierRate.name}
                                        title={carrierRate.name}
                                        onError={onError}
                                      />
                                    ) : (
                                      <img
                                        className="w-7 h-7 rounded-full border"
                                        src={defaultImage}
                                        alt={carrierRate.name}
                                        title={carrierRate.name}
                                      />
                                    )}

                                    <div className="pl-2 text-xs font-medium">
                                      <div className="flex items-center">
                                        <span
                                          className={`mr-1 ${colors.textColor}`}
                                        >
                                          {carrierRate.freightServiceId
                                            ? `${carrierRate.name} - FC`
                                            : carrierRate.name}
                                        </span>
                                      </div>
                                      <span className="text-slate-600 text-[11px] font-mediumleading-[18px]">
                                        #{carrierRate.carrierQuoteId}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-5 py-4">-</td>
                                <td className="px-5 py-4">
                                  {carrierRate.transitTime
                                    ? `Est. ${carrierRate.transitTime} days`
                                    : '-'}
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.projectedDeliveryDate
                                    ? moment(
                                        carrierRate.projectedDeliveryDate
                                      ).format('MMM. Do - YYYY')
                                    : '-'}
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.totalCharge === 0
                                    ? '-'
                                    : currency === CURRENCY.CAD
                                    ? `$${carrierRate.totalCharge.toFixed(2)}`
                                    : `$${carrierRate.totalChargeUSD.toFixed(
                                        2
                                      )}`}
                                </td>

                                <td className="px-5 py-4">
                                  {carrierRate.totalCharge === 0
                                    ? '-'
                                    : currency === CURRENCY.CAD
                                    ? `$${carrierRate.additionalCharges.toFixed(
                                        2
                                      )}`
                                    : `$${carrierRate.additionalChargesUSD.toFixed(
                                        2
                                      )}`}
                                </td>

                                <td className="px-5 py-4">
                                  {carrierRate.carrierQuoteId
                                    ? currency === CURRENCY.CAD
                                      ? `$${carrierRate.finalCharge.toFixed(
                                          2
                                        )}CAD`
                                      : `$${carrierRate.finalChargeUSD.toFixed(
                                          2
                                        )}USD`
                                    : '-'}
                                </td>
                                <td className="px-5 py-4">
                                  {process.env.REACT_APP_MODE !==
                                    'production' &&
                                    (currentUser.doNotAskSpotQuote ? (
                                      <>
                                        <ButtonCmp
                                          type="submit"
                                          className={`btn_primary  shadow`}
                                          onClick={() =>
                                            requestSpotQuote(
                                              carrierRate.id,
                                              currentUser.doNotAskSpotQuote
                                            )
                                          }
                                          disabled={
                                            carrierRate.isSpotRequestSent
                                          }
                                        >
                                          {carrierRate.isSpotRequestSent
                                            ? 'Request Sent'
                                            : 'Request Spot'}
                                        </ButtonCmp>
                                      </>
                                    ) : (
                                      <>
                                        {' '}
                                        <ButtonCmp
                                          type="submit"
                                          className={`btn_primary  shadow   ${
                                            carrierRate.isSpotRequestSent
                                              ? ' bg-opacity-50 cursor-not-allowed'
                                              : 'hover:bg-primary700'
                                          }`}
                                          onClick={handleActionType(
                                            'spotQuoteConfirmation',
                                            'view',
                                            carrierRate
                                          )}
                                          disabled={
                                            carrierRate.isSpotRequestSent
                                          }
                                        >
                                          {carrierRate.isSpotRequestSent
                                            ? 'Request Sent'
                                            : 'Request Spot'}
                                        </ButtonCmp>
                                        {carrierRate.isSpotRequestSent ? (
                                          <ButtonCmp
                                            type="submit"
                                            className={`btn_primary  shadow`}
                                            onClick={handleActionType(
                                              'addQuoteDetail',
                                              '',
                                              carrierRates
                                            )}
                                          >
                                            Add Quote Details
                                          </ButtonCmp>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    ))}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      ) : (
                        isFinish && (
                          <tbody>
                            <tr>
                              <td colSpan={6} className="py-4 text-center">
                                {isLoading && (
                                  <>
                                    <div className="skeleton bg-gray50 rounded-none w-32 h-[32px]"></div>
                                  </>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                    </table>
                  </>
                ) : (
                  <>
                    <table className="w-full">
                      <thead>
                        <tr className="text-grayLight600 bg-gray50 text-[11px]">
                          {CarrierRatesHeadCells.map(
                            (head) =>
                              head.visible && (
                                <th
                                  scope="col"
                                  className="lg:min-w-[210px] xxl:min-w-[unset] px-5 py-3 font-medium"
                                  key={head.id}
                                >
                                  <div className="flex items-center">
                                    <span> {head.name}</span>
                                  </div>
                                </th>
                              )
                          )}
                        </tr>
                      </thead>
                      {carrierRates.length ? (
                        <tbody className="text-xs">
                          {carrierRates.map(
                            (carrierRate: any, index: number) => (
                              <tr
                                key={index}
                                className="text-grayLight600 text-xs bg-white rounded-md cursor-pointer transition duration-300 hover:bg-gray-100"
                              >
                                <td className="px-5 py-4">
                                  <div className="flex items-center">
                                    {carrierRate.image ? (
                                      <img
                                        src={`${carrierRate.imageUrl}${carrierRate.image}`}
                                        className="w-7 h-7 rounded-full border"
                                        alt={carrierRate.name}
                                        title={carrierRate.name}
                                        onError={onError}
                                      />
                                    ) : (
                                      <img
                                        className="w-7 h-7 rounded-full border"
                                        src={defaultImage}
                                        alt={carrierRate.name}
                                        title={carrierRate.name}
                                      />
                                    )}

                                    <div className="pl-2 text-xs font-medium">
                                      <div className="flex items-center">
                                        <span
                                          className={`mr-1 ${colors.textColor}`}
                                        >
                                          {carrierRate.freightServiceId
                                            ? `${carrierRate.name} - FC`
                                            : carrierRate.name}
                                        </span>
                                      </div>
                                      <span className="text-slate-600 text-[11px] font-mediumleading-[18px]">
                                        #{carrierRate.carrierQuoteId}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.tags &&
                                    (addressDetails.status === STATUS.OPENED ||
                                      addressDetails.status ===
                                        STATUS.ACTIVE) &&
                                    carrierRate.tags[0] && (
                                      <div className="px-2 py-0.5 bg-white rounded-md shadow border border-senary300 justify-start items-center gap-1 inline-flex">
                                        {carrierRate.tags[0] === 'Fastest' ? (
                                          <div className="p-1 bg-green500 rounded-full" />
                                        ) : (
                                          <div className="p-1 bg-orange400 rounded-full" />
                                        )}

                                        <div className="text-center text-textSecondary text-[11px] font-medium leading-[18px]">
                                          {carrierRate.tags[0]}
                                        </div>
                                      </div>
                                    )}
                                </td>

                                <td className="px-5 py-4">
                                  {carrierRate.freightServiceId && (
                                    <div className="py-4 justify-start items-center gap-3 flex">
                                      <div className="w-5 h-5 rounded-full justify-center items-center flex">
                                        <img
                                          className="w-5 h-5 relative rounded-full border border-black border-opacity-10"
                                          src={FreightcomIcon}
                                          alt="FC"
                                        />
                                      </div>
                                      <div className="text-slate-600 text-xs font-medium font-['Inter'] leading-tight">
                                        {carrierRate.freightServiceName}
                                      </div>
                                    </div>
                                  )}
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.projectedDeliveryDate
                                    ? moment(
                                        carrierRate.projectedDeliveryDate
                                      ).format('MMM. Do - YYYY')
                                    : '-'}
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.totalCharge === 0
                                    ? '-'
                                    : currency === CURRENCY.CAD
                                    ? `$${carrierRate.totalCharge.toFixed(2)}`
                                    : `$${carrierRate.totalChargeUSD.toFixed(
                                        2
                                      )}`}
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.totalCharge === 0
                                    ? '-'
                                    : currency === CURRENCY.CAD
                                    ? `$${carrierRate.additionalCharges.toFixed(
                                        2
                                      )}`
                                    : `$${carrierRate.additionalChargesUSD.toFixed(
                                        2
                                      )}`}
                                </td>
                                <td className="px-5 py-4">
                                  {carrierRate.transitTime
                                    ? `Est. ${carrierRate.transitTime} days`
                                    : '-'}
                                </td>
                                <td className="px-5 py-4">
                                  {' '}
                                  <span className="text-textSecondary text-xs font-bold leading-tight">
                                    {' '}
                                    {carrierRate.carrierQuoteId
                                      ? currency === CURRENCY.CAD
                                        ? `$${carrierRate.finalCharge.toFixed(
                                            2
                                          )}CAD`
                                        : `$${carrierRate.finalChargeUSD.toFixed(
                                            2
                                          )}USD`
                                      : '-'}
                                  </span>
                                </td>
                                <td className="px-5 py-4">
                                  <span className="px-3">
                                    <CheckBox
                                      classes="border-gray100 bg-senary300 checked:bg-white checked: text-gray200 rounded-md text-black"
                                      onChangeFunc={() =>
                                        setIsChecked(!isChecked)
                                      }
                                      checked={isChecked}
                                    />
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      ) : (
                        isFinish && (
                          <tbody>
                            <tr>
                              <td colSpan={100} className="py-5 text-center">
                                {isLoading && (
                                  <>
                                    <div className="skeleton bg-gray50 rounded-none w-32 h-[32px]"></div>
                                  </>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                    </table>
                  </>
                )}
              </div>
              <div className="w-full">
                <div className="flex justify-end p-3">
                  <ButtonCmp
                    className="btn_primary ml-3 px-5 py-[6px]"
                    onClick={() => {
                      setIsShowFeedbackForm(true);
                    }}
                  >
                    Feedback
                  </ButtonCmp>
                </div>
              </div>
              {!!carrierRates.length && (
                <div className="w-full bg-white rounded-b-xl">
                  <CustomPagination
                    recordsPerPage={10}
                    totalRecords={carrierRates.length}
                    currentPage={1}
                    handlePagination={() => {}}
                  />
                </div>
              )}
              {carrierRates.length <= 0 && isFinish && !isLoading && (
                <NotFoundUI
                  title="No Quote Found"
                  desc="There are no data for team members you can create new team member."
                />
              )}
            </div>
          </>
        )}
      </PageSectionLayout>

      {!action.spotQuoteModel && (
        <Pricing
          isOpen={action.pricing}
          mode={action.mode}
          selectedCarrierRates={selectedCarrierRates}
          quoteID={id}
          setIsRefresh={setIsRefresh}
          handleClose={handleDrawerClose}
          currency={currency}
          dimensions={dimensions}
          services={services}
        />
      )}
      <PDF isOpen={action.pdf} id={id} handleClose={handleDrawerClose} />
      {action.closedQuote && (
        <ConfirmModal
          title="Set quote to closed"
          description={`Confirming this will close this active quote, mark it as lost and you won't be able to reopen it. Are you sure you want to proceed?`}
          button1="Yes, I’m sure"
          button2="No, not now"
          handleClose={handleModalClose}
        />
      )}
      {action.closedReason && (
        <Closed
          id={id}
          setIsRefresh={setIsRefresh}
          handleClose={handleModalClose(false)}
        />
      )}
      {action.informationModel && (
        <CommonModal
          handleClose={handleModalClose(false)}
          title="Proceed with Order"
          size={'max-w-2xl'}
        >
          <div className="p-6 pt-0 ">
            {' '}
            <div className="mt-4">
              <ul className="leading-8">
                <li className="flex items-center">
                  <b className="mr-3">Quote ID:</b>{' '}
                  {selectedCarrierRates[0].carrierQuoteId}
                </li>
                <li className="flex items-center">
                  <b className="mr-3">Carrier Name:</b>{' '}
                  {selectedCarrierRates[0].freightServiceId
                    ? 'FreightCom -'
                    : ''}{' '}
                  {selectedCarrierRates[0].name}
                </li>
                <li className="flex items-center">
                  <b className="mr-3">Carrier Cost:</b>{' '}
                  {selectedCarrierRates[0].totalCharge} CAD
                </li>
                <li className="flex items-center">
                  <b className="mr-3">Smart Quote ID:</b> {addressDetails.code}
                </li>
              </ul>
            </div>
            <div className="flex flex-wrap -mx-3 mt-4">
              <div className="w-full px-3">
                <button
                  className="btn_primary w-full"
                  onClick={handleModalClose(true)}
                >
                  {'Proceed with Order'}
                </button>
              </div>
            </div>
          </div>
        </CommonModal>
      )}
      {action.quoteDetail && (
        <QuoteDetail
          isOpen={true}
          handleClose={handleModalClose(false)}
          quote={{ addressDetails, dimensions, services }}
        />
      )}

      {action.orderSuccessModel && (
        <CommonModal
          handleClose={handleModalClose(false)}
          title="Order Booked"
          size={'max-w-md'}
        >
          <div className="p-6 pt-0 ">
            <div className="text-center">
              <div className="success-checkmark">
                <div className="check-icon">
                  <span className="icon-line line-tip"></span>
                  <span className="icon-line line-long"></span>
                  <div className="icon-circle"></div>
                  <div className="icon-fix"></div>
                </div>
              </div>
              <p className="text-xl font-semibold leading-relaxed text-black mt-4">
                <Link
                  to={ROSE_ROCKET.QUOTE_URL + rossRocketId}
                  target="_blank"
                  className="underline text-primary"
                >
                  {rossRocketPublicId}
                </Link>
              </p>
            </div>
            <div className="flex flex-wrap -mx-3 mt-4">
              <div className="w-full px-3">
                <button
                  className="btn_outline_primary w-full"
                  onClick={handleModalClose(true)}
                >
                  {'Close'}
                </button>
              </div>
            </div>
          </div>
        </CommonModal>
      )}
      {!action.pricing && (
        <SpotQuote
          isOpen={action.spotQuoteModel}
          mode={action.mode}
          selectedCarrierRates={selectedCarrierRates}
          quoteID={id}
          setIsRefresh={setIsRefresh}
          handleClose={handleDrawerClose}
          currency={currency}
          dimensions={dimensions}
          services={services}
        />
      )}
      {action.spotQuoteConfirmation && (
        <SpotQuoteConfirmation
          handleClose={handleModalClose}
          services={services}
          selectedCarrierRates={selectedCarrierRates}
          dimensions={dimensions}
          addressDetails={addressDetails}
        />
      )}
      {isShowFeedbackForm && (
        <FeedbackCreate
          headerIcon={<MessageChatCircle className="w-4 h-4" />}
          headerTitle="Submit Feedback"
          headerIconClass=""
          isOpen={isShowFeedbackForm}
          handleClose={() => setIsShowFeedbackForm(false)}
        />
      )}
    </>
  );
};

export default MyQuote;
