import { Zap } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';

interface IProps {
  handleClose: any;
}

const ReqCreditModal = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Request Credit Increase'}
    titleDesc={'Update and mange your sales representative'}
    handleClose={() => handleClose(false)}
    headerIcon={<Zap />}
    size={'max-w-[688px]'}
    isOverflow={true}
    secondaryBtnText="Cancel"
    secondaryBtnOnClick={() => handleClose(false)}
    primaryBtnText="Request"
  >
    <div className="w-full p-5 flex flex-col gap-4">
      <InputText label="Old Credit Limit" value={'$12,000'} disabled />
      <InputText
        label="New Credit Limit"
        placeholder="Enter new credit limit"
        parentClassName="mb-2.5"
      />
    </div>
  </CommonModal>
);

export default ReqCreditModal;
