import { ArrowUpRight, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import TableCmp from 'src/components/TableCmp';

import BadgeCmp from '../../../components/BadgeCmp';
import CustomPagination from '../../../components/CustomPagination';
import InputText from '../../../components/InputText/InputText';
import NotFoundUI from '../../../components/NotFoundUI';
import SelectBox from '../../../components/SelectBox/SelectBox';

import CustomerListTableFooterRow from './CustomerListTableFooterRow';
import CustomerListTableRow from './CustomerListTableRow';
import CustomerListTableRowLoader from './CustomerListTableRowLoader';
import CustomerSplitPieChartModal from './CustomerSplitPieChartModal';
import CustomerOrdersTable from './CustomerSplitTable/CustomerOrdersTable';

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

interface IProps {
  customerList: any;
  isSalesLoading: boolean;
}

const CustomerSplit = ({ customerList, isSalesLoading }: IProps) => {
  const [filteredCustomerList, setFilteredCustomerList] = useState<any[]>([
    ...customerList,
  ]);
  const [sortData, setSortData] = useState({
    sortField: 'createdAt',
    sortType: 'desc',
  });
  const [totalObj, setTotalObj] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(100);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isShowPieChartModal, setIsShowPieChartModal] = useState(false);
  const [isShowOrdersModal, setIsShowOrdersModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [pageItem, setPageItem] = useState([...filteredCustomerList]);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Customer',
        sortable: true,
      },
      {
        id: 'salesRepFullName',
        name: 'Sales Representative',
        sortable: true,
      },
      {
        id: 'sales',
        name: 'Total Revenue',
        sortable: true,
      },
      {
        id: 'orderCount',
        name: 'Total Orders',
        sortable: true,
      },
      {
        id: 'profit',
        name: 'Gross Profit',
        sortable: true,
      },
      {
        id: 'profitPercentage',
        name: 'Gross Profit %',
        sortable: true,
      },

      {
        id: 'change',
        name: 'GP Weight',
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    setIsLoading(isSalesLoading);
  }, [isSalesLoading]);

  useEffect(() => {
    let sortedList = [...customerList];

    sortedList.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));

    if (searchInput) {
      sortedList = sortedList.filter((item: any) =>
        ['name', 'salesRepFullName'].some(
          (key) =>
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
        )
      );
    }

    setFilteredCustomerList(sortedList);
    setCurrentPage(1);
  }, [searchInput, customerList]);

  useEffect(() => {
    let tempTotals: any = {
      customersCount: filteredCustomerList.length,
    };
    const pageItems = filteredCustomerList.slice(
      (currentPage - 1) * recordsPerPage,
      currentPage * recordsPerPage
    );

    pageItems.forEach((customerRecords) => {
      Object.entries(customerRecords).forEach(([key, value]) => {
        if (key !== 'name' && key !== 'city') {
          if (!tempTotals[key]) {
            tempTotals[key] = 0;
          }

          if (typeof value === 'number') {
            tempTotals[key] += value;
          }
        }
      });
    });

    setTotalObj(tempTotals);
  }, [filteredCustomerList, currentPage, recordsPerPage]);

  useEffect(() => {
    if (filteredCustomerList.length > recordsPerPage) {
      const pageItems = filteredCustomerList?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
      );
      setPageItem([...pageItems]);
    } else {
      setPageItem([...filteredCustomerList]);
    }
  }, [filteredCustomerList, currentPage, recordsPerPage]);

  const handleSorting = () => {
    const { sortField, sortType } = sortData;
    const sortedCustomerList = filteredCustomerList.sort((a: any, b: any) => {
      if (sortField === 'name') {
        const nameA = a.name.toUpperCase(); // to ensure case-insensitive comparison
        const nameB = b.name.toUpperCase(); // to ensure case-insensitive comparison

        if (sortType === 'asc') {
          return nameA.localeCompare(nameB);
        }

        return nameB.localeCompare(nameA);
      } else if (sortField === 'createdAt') {
        if (sortType === 'asc') {
          return moment(a.createdAt).diff(moment(b.createdAt));
        }

        return moment(b.createdAt).diff(moment(a.createdAt));
      } else {
        if (a[sortField] < b[sortField]) {
          return sortType === 'asc' ? -1 : 1;
        }

        if (a[sortField] > b[sortField]) {
          return sortType === 'asc' ? 1 : -1;
        }
      }

      return 0;
    });
    setFilteredCustomerList([...sortedCustomerList]);
  };

  useEffect(() => {
    handleSorting();
    setCurrentPage(1);
  }, [sortData]);

  const handleOnRowClick = (currentCustomer: any) => {
    setSelectedCustomer(currentCustomer);
    setIsShowOrdersModal(true);
  };

  return (
    <>
      <div className="w-full flex-col flex">
        <div className="table-top-header flex-wrap ">
          <div className="table-left-wrap flex-none max-mdm:flex-[0_0_100%]">
            <div className="table-title-wrap flex-wrap">
              <h5 className="table-title">Customer Split</h5>
              <div className="flex gap-2.5 flex-wrap">
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {filteredCustomerList.length}{' '}
                  {filteredCustomerList.length > 1 ? 'Customers' : 'Customer'}
                </BadgeCmp>

                {customerList.length > 0 && (
                  <div
                    className="flex justify-between items-center cursor-pointer flex-none"
                    onClick={() => setIsShowPieChartModal(true)}
                  >
                    <div className="flex text-[11px] items-center text-primary font-semibold">
                      View Graph <ArrowUpRight className="h-4 w-4" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <p className="table-subtitle">
              View customer specific sales analytics.
            </p>
          </div>
          <div className="table-right-wrap max-mdm:w-full">
            <InputText
              inputName="searchCarrier"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={searchInput}
              isClearable={true}
              parentClassName="table-searchInput max-mdm:flex-1"
              inputType="text"
              onChangeFunc={(e: any) => setSearchInput(e.target.value)}
            />
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setRecordsPerPage(event.value);
                  setCurrentPage(1);
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === recordsPerPage
                )}
              />
            </div>
          </div>
        </div>

        <div className="w-full border-t border-gray100 flex flex-col flex-1">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
            <TableCmp
              params={sortData}
              setParams={setSortData}
              headCells={headCells}
              tableDataArr={isLoading ? [] : pageItem}
              TableRowCmp={CustomerListTableRow}
              TableLoaderRowCmp={CustomerListTableRowLoader}
              isTableDataLoading={isLoading}
              numberOfSkeletonRows={recordsPerPage}
              onRowClick={(currentCustomer: any) =>
                handleOnRowClick(currentCustomer)
              }
              isTableRowClickable={true}
              tableRowCmpProps={{
                customerList: customerList,
              }}
              TableFooterRowCmp={CustomerListTableFooterRow}
              footerDataArr={[totalObj]}
            />
          </div>
          {filteredCustomerList.length <= 0 && !isSalesLoading && (
            <NotFoundUI
              title="No Customer found"
              desc="There are no data for team members you can create new team member."
              containerClassName="min-h-[unset]"
            />
          )}
          <div className="w-full">
            {!isLoading && (
              <CustomPagination
                recordsPerPage={recordsPerPage}
                totalRecords={filteredCustomerList.length}
                currentPage={currentPage}
                handlePagination={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
      {isShowPieChartModal && (
        <CustomerSplitPieChartModal
          handleClose={() => setIsShowPieChartModal(false)}
          customerList={customerList}
        />
      )}
      {isShowOrdersModal && (
        <CustomerOrdersTable
          handleClose={() => {
            setIsShowOrdersModal(false);
            setSelectedCustomer(null);
          }}
          selectedCustomer={selectedCustomer}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default CustomerSplit;
