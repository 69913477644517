import React from 'react';

const CustomerTargetLoadingRow = () => (
  <>
    <td className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem]">
      <div className="flex items-center gap-2 ">
        <div className="flex-none">
          <div className="w-8 h-8 custom-loading rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
            A
          </div>
        </div>
        <div className="w-32 custom-loading">
          <h6 className="text-grayLight900 font-semibold leading-5 truncate custom-loading">
            #####
          </h6>
          {/* <p className="truncate">Account Executive</p> */}
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem]">
      <span className="custom-loading">0000</span>
    </td>
    <td className="px-5 py-4 min-w-[150px] w-[12%]">
      <span className="custom-loading">0000</span>
    </td>
    <td className="px-5 py-4 min-w-[150px] w-[12%]">
      <span className="custom-loading">0000</span>
    </td>
    <td className="px-5 py-4  min-w-[200px] w-[12%]">
      <progress
        className="custom-loading [&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3"
        value="10"
        max="100"
      ></progress>
      <span className="text-textSecondary font-medium custom-loading">0%</span>
    </td>
    <td className="px-5 py-4 min-w-[100px] w-[10%]">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4 min-w-[100px] w-[10%]">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4 text-fgSuccessPrimary min-w-[200px] w-[12%]">
      <progress
        className="custom-loading [&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3"
        value="20"
        max="100"
      ></progress>
      <span className="custom-loading text-textSecondary font-medium">0%</span>
    </td>
  </>
);

export default CustomerTargetLoadingRow;
