import { ArrowUp, ArrowDown } from '@untitled-ui/icons-react//build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import {
  getFormattedNumber,
  getProgressClass,
  getShortName,
} from 'src/utils/CommonFunctions';

import { ITargetSetTotalRowTableProps } from './Target.interface';

const TargetSetTotalRowTable = ({
  totalData,
  dateRange,
  isEdit,
  isLoading,
  userDetails,
}: ITargetSetTotalRowTableProps) => {
  const prevMonthTargetLoads = totalData.prevMonthTargetLoads;
  const prevMonthTargetProfit = totalData.prevMonthTargetProfit;
  const targetLoads = totalData.targetLoads;
  const targetProfit = totalData.targetProfit;

  const [targetLoadIncrement, setTargetLoadIncrement] = useState<number>(0);
  const [targetProfitIncrement, setTargetProfitIncrement] = useState<number>(0);

  useEffect(() => {
    const targetLoadIncrementValue: number =
      prevMonthTargetLoads > 0
        ? parseFloat(
            (
              ((targetLoads - prevMonthTargetLoads) / prevMonthTargetLoads) *
              100
            ).toFixed(2)
          )
        : 0;
    setTargetLoadIncrement(targetLoadIncrementValue);

    const targetProfitIncrementValue: number =
      prevMonthTargetProfit > 0
        ? parseFloat(
            (
              ((targetProfit - prevMonthTargetProfit) / prevMonthTargetProfit) *
              100
            ).toFixed(2)
          )
        : 0;
    setTargetProfitIncrement(targetProfitIncrementValue);
  }, [totalData]);

  return (
    <>
      {isEdit ? (
        <table className="w-full text-xs text-left text-nowrap whitespace-nowrap">
          <thead className="text-xs text-grayLight600 font-medium bg-gray50 border-b border-utilityGray200">
            <tr className="border-b border-utilityGray200">
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>User</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Period</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Prev. Target Margin</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium">
                <div className="flex items-center">
                  <span>Prev. Target Loads</span>
                </div>
              </th>

              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Target Margin</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium">
                <div className="flex items-center">
                  <span>Target Loads</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {!isLoading ? (
              <tr className="text-grayLight600 font-normal text-xs bg-transparent transition-all cursor-pointer duration-[0.5s]">
                <td className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem] flex items-center">
                  <div className="flex items-center gap-2">
                    {userDetails?.image ? (
                      <img
                        src={userDetails?.imageUrl + userDetails?.image}
                        className="w-8 h-8 rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                      />
                    ) : (
                      <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                        {getShortName(
                          `${userDetails?.firstName} ${userDetails?.lastName}`
                        )}
                      </div>
                    )}

                    <div className="max-w-32">
                      <h6 className="text-grayLight900 font-medium leading-tight truncate">
                        {userDetails?.firstName} {userDetails?.lastName}
                      </h6>
                      <p className="text-grayLight600 font-normal leading-tight truncate">
                        {userDetails?.jobRole}
                      </p>
                    </div>
                  </div>
                </td>

                <td className="px-5 py-4 min-w-[14rem] w-[14rem] max-w-[14rem]">
                  <span>
                    {moment(dateRange).startOf('month').format('MMM Do')} -
                    {moment(dateRange).endOf('month').format('MMM Do')}
                  </span>
                </td>
                <td className="px-5 py-3 items-center min-w-[20%] w-[20%] max-w-[20%]">
                  <span>
                    $
                    {totalData.prevMonthCompletedProfit
                      ? getFormattedNumber(
                          totalData.prevMonthCompletedProfit,
                          false,
                          false,
                          true
                        )
                      : 0}
                  </span>
                  <span>&nbsp;/&nbsp;</span>
                  <span>
                    $
                    {totalData.prevMonthTargetProfit
                      ? getFormattedNumber(
                          totalData.prevMonthTargetProfit,
                          false,
                          false,
                          true
                        )
                      : 0}
                  </span>
                  <span>&nbsp;/&nbsp;</span>
                  <span
                    className={`items-center ${getProgressClass(
                      totalData.prevMonthCompletedProfitPercentage || 0,
                      false
                    )}`}
                  >
                    {totalData.prevMonthCompletedProfitPercentage || 0}%
                  </span>
                </td>
                <td className="px-5 py-3 items-center  min-w-[20%] w-[20%] max-w-[20%]">
                  <span>{totalData.prevMonthCompletedLoads || 0}</span>
                  <span>&nbsp;/&nbsp;</span>
                  <span>{totalData.prevMonthTargetLoads || 0}</span>
                  <span>&nbsp;/&nbsp;</span>
                  <span
                    className={`items-center ${getProgressClass(
                      totalData.prevMonthCompletedLoadsPercentage || 0,
                      false
                    )}`}
                  >
                    {totalData.prevMonthCompletedLoadsPercentage || 0}%
                  </span>
                </td>
                <td className="px-5 py-4 min-w-[18%] w-[18%]">
                  <div className="flex items-center">
                    $
                    <span className="ml-1">
                      {totalData.targetProfit
                        ? getFormattedNumber(
                            totalData.targetProfit,
                            false,
                            false,
                            true
                          )
                        : 0}
                    </span>
                    <BadgeCmp
                      style="pill"
                      type={targetProfitIncrement >= 0 ? 'success' : 'error'}
                      mainClassName="ml-2 flex items-center"
                    >
                      {targetProfitIncrement >= 0 ? (
                        <>
                          <ArrowUp className="w-3 h-3" />
                          {Math.abs(targetProfitIncrement)}%
                        </>
                      ) : (
                        <>
                          <ArrowDown className="w-3 h-3" />
                          {Math.abs(targetProfitIncrement)}%
                        </>
                      )}
                    </BadgeCmp>
                  </div>
                </td>
                <td className="px-5 py-4 min-w-[18%] w-[18%]">
                  <div className="flex items-center">
                    <span className="ml-1">{totalData.targetLoads}</span>
                    <BadgeCmp
                      style="pill"
                      type={targetLoadIncrement >= 0 ? 'success' : 'error'}
                      mainClassName="ml-2 flex items-center"
                    >
                      {targetLoadIncrement >= 0 ? (
                        <>
                          <ArrowUp className="w-3 h-3" />
                          {Math.abs(targetLoadIncrement)}%
                        </>
                      ) : (
                        <>
                          <ArrowDown className="w-3 h-3" />
                          {Math.abs(targetLoadIncrement)}%
                        </>
                      )}
                    </BadgeCmp>
                  </div>
                </td>
              </tr>
            ) : (
              <tr className="text-grayLight600 font-normal text-xs  bg-transparent transition-all  duration-[0.5s]">
                <td className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem]">
                  <div className="flex items-center gap-2">
                    <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none custom-loading">
                      J D
                    </div>
                    <div className="max-w-32">
                      <h6 className="text-grayLight900 font-medium leading-tight truncate custom-loading ">
                        Use Name Use Name{' '}
                      </h6>
                      <p className="text-grayLight600 font-normal leading-tight truncate custom-loading">
                        #SQ-L-100145
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-4 min-w-[14rem] w-[14rem] max-w-[14rem]">
                  <span className="custom-loading">Aug. 1st - Aug. 31st</span>
                </td>
                <td className="px-5 py-4 min-w-[20%] w-[20%] max-w-[20%]">
                  <span className="custom-loading">$5.59</span>
                  <span className="custom-loading">&nbsp;/&nbsp;</span>
                  <span className="custom-loading">1,000</span>
                  <span className="custom-loading">&nbsp;/&nbsp;</span>
                  <span className="text-textSecondary font-medium custom-loading">
                    106.51%
                  </span>
                </td>
                <td className="px-5 py-4 min-w-[20%] w-[20%] max-w-[20%]">
                  <span className="custom-loading">$5.59</span>
                  <span className="custom-loading">&nbsp;/&nbsp;</span>
                  <span className="custom-loading">1,000</span>
                  <span className="custom-loading">&nbsp;/&nbsp;</span>
                  <span className="text-textSecondary font-medium custom-loading">
                    106.51%
                  </span>
                </td>
                {isEdit ? (
                  <>
                    <td className="px-5 py-4 min-w-[18%] w-[18%]">
                      <div className="flex items-center custom-loading">
                        $<span className="ml-1">0</span>
                        <BadgeCmp
                          style="pill"
                          type="success"
                          mainClassName="ml-2 flex items-center"
                        >
                          <ArrowUp className="w-3 h-3" />
                          NaN
                        </BadgeCmp>
                      </div>
                    </td>
                    <td className="px-5 py-4 min-w-[18%] w-[18%]">
                      <div className="flex items-center custom-loading">
                        $<span className="ml-1">0</span>
                        <BadgeCmp
                          style="pill"
                          type="success"
                          mainClassName="ml-2 flex items-center"
                        >
                          <ArrowUp className="w-3 h-3" />
                          NaN
                        </BadgeCmp>
                      </div>
                    </td>
                  </>
                ) : (
                  ''
                )}
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <table className="w-full text-xs text-left text-nowrap whitespace-nowrap">
          <thead className="text-xs text-grayLight600 font-medium bg-gray50 border-b border-utilityGray200">
            <tr className="border-b border-utilityGray200">
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>User</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Period</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Current Margin</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Target Margin</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Margin Completion</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Current Loads</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium">
                <div className="flex items-center">
                  <span>Target Loads</span>
                </div>
              </th>
              <th className="px-5 py-3 font-medium ">
                <div className="flex items-center">
                  <span>Load Comp.</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {!isLoading ? (
              <tr className="text-grayLight600 font-normal text-xs bg-transparent transition-all cursor-pointer duration-[0.5s]">
                <td className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem]">
                  <div className="flex items-center gap-2">
                    {userDetails?.image ? (
                      <img
                        src={userDetails?.imageUrl + userDetails?.image}
                        className="w-8 h-8 rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                      />
                    ) : (
                      <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                        {getShortName(
                          `${userDetails?.firstName} ${userDetails?.lastName}`
                        )}
                      </div>
                    )}

                    <div className="w-32">
                      <h6 className="text-grayLight900 font-medium leading-tight truncate">
                        {userDetails?.firstName} {userDetails?.lastName}
                      </h6>
                      <p className="text-grayLight600 font-normal leading-tight truncate">
                        {userDetails?.jobRole}
                      </p>
                    </div>
                  </div>
                </td>

                <td className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem]">
                  <div className="flex flex-wrap gap-1">
                    <span>
                      {moment(dateRange).startOf('month').format('MMM Do')} -
                      {moment(dateRange).endOf('month').format('MMM Do')}
                    </span>
                  </div>
                </td>
                <td className="px-5 py-4 min-w-[150px] w-[12%]">
                  <span>
                    $
                    {totalData.completedProfit
                      ? getFormattedNumber(
                          totalData.completedProfit,
                          false,
                          false,
                          true
                        )
                      : 0}
                  </span>
                </td>
                <td className="px-5 py-4 min-w-[150px] w-[12%]">
                  <span>
                    $
                    {getFormattedNumber(
                      totalData.targetProfit,
                      false,
                      false,
                      true
                    )}
                  </span>
                </td>
                {/* <td className="px-5 py-4 min-w-[11%] w-[11%]">
                  <span
                    className={`text-textSecondary font-medium ${getProgressClass(
                      totalData.completedProfitPercentage
                    )}`}
                  >
                    {totalData.completedProfitPercentage}%
                  </span>
                </td> */}
                <td className="px-5 py-4 min-w-[200px] w-[12%]">
                  <progress
                    className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 ${getProgressClass(
                      totalData.completedProfitPercentage || 0
                    )}`}
                    value={totalData.completedProfitPercentage || 0}
                    max="100"
                  ></progress>
                  <span className="text-textSecondary">
                    {totalData.completedProfitPercentage || 0}%
                  </span>
                </td>
                <td className="px-5 py-4 min-w-[100px] w-[10%]">
                  <span>{totalData.completedLoads}</span>
                </td>
                <td className="px-5 py-4 min-w-[100px] w-[10%]">
                  <span>{totalData.targetLoads}</span>
                </td>
                <td className="px-5 py-4 min-w-[200px] w-[12%]">
                  <progress
                    className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 ${getProgressClass(
                      totalData.completedLoadsPercentage || 0
                    )}`}
                    value={totalData.completedLoadsPercentage || 0}
                    max="100"
                  ></progress>
                  <span className="text-textSecondary">
                    {totalData.completedLoadsPercentage || 0}%
                  </span>
                </td>
                {/* <td className="px-5 py-4 min-w-[11%] w-[11%]">
                  <span
                    className={`text-textSecondary font-medium ${getProgressClass(
                      totalData.completedLoadsPercentage
                    )}`}
                  >
                    {totalData.completedLoadsPercentage}%
                  </span>
                </td> */}
              </tr>
            ) : (
              <tr className="text-grayLight600 font-normal text-xs  bg-transparent transition-all  duration-[0.5s]">
                <td className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem]">
                  <div className="flex items-center gap-2">
                    <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none custom-loading">
                      J D
                    </div>
                    <div className="max-w-32">
                      <h6 className="text-grayLight900 font-medium leading-tight truncate custom-loading ">
                        Use Name Use Name{' '}
                      </h6>
                      <p className="text-grayLight600 font-normal leading-tight truncate custom-loading">
                        #SQ-L-100145
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem]">
                  <div className="flex flex-wrap gap-1">
                    <span className="custom-loading">Aug. 1st - Aug. 31st</span>
                  </div>
                </td>
                <td className="px-5 py-4 min-w-[150px] w-[12%]">
                  <span className="custom-loading">$1060</span>
                </td>
                <td className="px-5 py-4 min-w-[150px] w-[12%]">
                  <span className="custom-loading">$106,506.59</span>
                </td>
                <td className="px-5 py-4 min-w-[200px] w-[12%]">
                  <progress
                    className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 custom-loading `}
                    value={80}
                    max="100"
                  ></progress>
                </td>
                <td className="px-5 py-4 min-w-[100px] w-[10%]">
                  <span className="custom-loading">$1060</span>
                </td>
                <td className="px-5 py-4 min-w-[100px] w-[10%]">
                  <span className="custom-loading">$106,506.59</span>
                </td>
                <td className="px-5 py-4 min-w-[200px] w-[12%]">
                  <span className="custom-loading">100%</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TargetSetTotalRowTable;
