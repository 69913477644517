import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { BasicContext } from 'src/context/BasicContext';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { lastSync } from 'src/services/SalesDashboard';

import Forbidden from '../app/Errors/403';
import { APP_FULL_NAME } from '../constants/common';
import { ROUTES } from '../constants/routes';
import { AuthContext } from '../context/AuthContext';
import { fetchJsFromCDN, useRolePermission } from '../utils/CommonFunctions';

const PrivateRoute = ({
  component: Component,
  title,
  permission,
  ...props
}: any) => {
  const { hasPermissionV2 } = useRolePermission();
  const { isAuthenticated } = useContext(AuthContext);
  const { appearance } = useContext(BasicContext);
  const { setLastSyncDateTimeActual, setAllTimeStartDateActual } =
    useContext(BasicContext);

  const dispatch = useDispatch();
  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  useEffect(() => {
    document.title = `${title} - ${appearance.web_title ?? APP_FULL_NAME}`;
    const favicon = document.getElementById('favicon') as HTMLLinkElement;

    if (favicon) {
      favicon.href = appearance.faviconUrl + appearance.favicon;
    }
  }, [title]);

  const handleLastSyncTimeUpdateData = (data: any) => {
    // console.log('GOT USER SOCKET DATA ', data);

    if (data?.data?.firstOrderDate && data?.data?.lastSyncTime) {
      setLastSyncDateTimeActual(data?.data?.lastSyncTime);
      setAllTimeStartDateActual(data?.data?.firstOrderDate);
    }
  };

  const connectSocket = () => {
    if (isSocketConnected && !!socketIO?.on?.on) {
      socketIO.removeAllListeners();
      socketIO.on.on('lastSyncTimeUpdated', function (data: any) {
        handleLastSyncTimeUpdateData(data);
      });
    } else {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      ).then(([io]: any) => {
        io.sails.url = window.SERVER_URL;
        io.socket.on('connect', function socketConnected() {
          dispatch(setIsSocketConnected(true));
          dispatch(setSocketIo(io.socket));

          io.socket.get(
            `/subscribe/globalRoom`,
            function (data: any, jwr: any) {
              // console.log('Global Room Status : ', data);

              if (jwr.error) {
                return;
              }
            }
          );
        });

        io.socket.on('lastSyncTimeUpdated', function (data: any) {
          handleLastSyncTimeUpdateData(data);
        });
      });
    }

    return;
  };

  useEffect(() => {
    if (isAuthenticated) {
      lastSync().then((response: any) => {
        setLastSyncDateTimeActual(response?.lastSyncTime);
        setAllTimeStartDateActual(response?.firstOrderDate);
      });
      connectSocket();
    }
  }, []);

  return isAuthenticated ? (
    <div className="md:ml-[var(--layout-sidebar-width)] bg-utilityGray300 overflow-hidden flex flex-col h-full">
      <p className="pt-[var(--layout-top-padding)]"></p>
      <div className="flex-grow overflow-y-auto h-full">
        {hasPermissionV2(permission) || permission === true ? (
          <Component {...props} />
        ) : (
          <Forbidden />
        )}
      </div>
    </div>
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
};

export default PrivateRoute;
