import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import outlook from 'src/assets/img/outlook.svg';
import ButtonCmp from 'src/components/ButtonCmp';
import { getQuoteEmailDetails } from 'src/services/QuoteEmailService';
import WalToast from 'src/utils/WalToast';

import EmailDetailView from './EmailDetailView';

const QuoteEmailDetailView = ({ emailContentId }: any) => {
  const [quoteEmailDetails, setQuoteEmailDetails] = useState<any>(null);
  const [isShowEmailDetailView, setIsShowEmailDetailView] =
    useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const messageId = searchParams.get('messageId') || emailContentId;

  const [isClicked, setIsClicked] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsShowEmailDetailView(true);
  };

  const handleMouseLeave = () => {
    if (!isClicked) {
      timeoutRef.current = setTimeout(() => {
        setIsShowEmailDetailView(false);
      }, 300);
    }
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    setIsShowEmailDetailView(true);
  };

  useEffect(() => {
    if (!isShowEmailDetailView) {
      setIsClicked(false);
    }
  }, [isShowEmailDetailView]);

  const fetchQuoteEmailDetails = async () => {
    try {
      const quoteEmails = (await getQuoteEmailDetails({
        messageId: messageId,
      })) as any;
      setQuoteEmailDetails(quoteEmails?.data);
    } catch (e: any) {
      console.log(e);
      WalToast.error(
        e?.message ?? e ?? 'Something went wrong while fetching quote emails.'
      );
    }
  };

  useEffect(() => {
    if (messageId) {
      fetchQuoteEmailDetails();
    }
  }, [searchParams]);

  return (
    <div className="sticky bottom-[47px] flex-1 text-right translate-y-1/2 xxl:right-5 right-4 ">
      <div
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="pt-4 inline-block"
      >
        <ButtonCmp
          className="btn-outline-primary !px-8"
          icon={<img src={outlook} className="w-4 h-4" alt="outlook icon" />}
        >
          View Email
        </ButtonCmp>
      </div>

      {isShowEmailDetailView && (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <EmailDetailView
            emailDetails={quoteEmailDetails}
            handleClose={() => setIsShowEmailDetailView(false)}
            isShowEmailDetailView={isShowEmailDetailView}
            isClicked={isClicked}
          />
        </div>
      )}
    </div>
  );
};

export default QuoteEmailDetailView;
