import { ArrowUp, ArrowDown } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

import user from '../../../../assets/img/carrier1.png';

const UserCommissionReportRow = () => (
  <>
    <td className="px-5 py-4 min-w-52">
      <div className="flex items-center gap-3">
        <img
          src={user}
          className="w-8 h-8 rounded border-[0.75px] border-black/[0.08] flex-none"
        />

        {/* If no image available below code will be used */}
        {/* <div className="w-8 h-8 rounded bg-utilityGray100 text-primary flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none">
          WA
        </div> */}
        <h6 className="text-grayLight900 font-semibold truncate max-w-44">
          Olivia Rhye
        </h6>
      </div>
    </td>
    <td className="px-5 py-4 ">
      <span>August 2024</span>
    </td>
    <td className="px-5 py-4 ">
      <div className="flex items-center">
        <span className="text-grayLight900 font-semibold">$12,253</span>
        <BadgeCmp
          style="pill"
          type="success"
          mainClassName="ml-2 flex items-center"
        >
          <ArrowUp className="w-3 h-3" />
          21%
        </BadgeCmp>
        <BadgeCmp
          style="pill"
          type="error"
          mainClassName="ml-2 flex items-center"
        >
          <ArrowDown className="w-3 h-3" />
          0%
        </BadgeCmp>
      </div>
    </td>
    <td className="px-5 py-4 ">
      <span>17.6%</span>
    </td>
    <td className="px-5 py-4 ">
      <span>350.36$</span>
    </td>
    <td className="px-5 py-4 ">
      <span>35,547</span>
    </td>
  </>
);

export default UserCommissionReportRow;
