import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listTarget = async (params: any = {}, signal?: any) => {
  const response = await axiosInterceptor.get(API.TARGET.LIST, {
    params,
    signal,
  });

  return response;
};

export const listCustomerTarget = async (params: any = {}, signal?: any) => {
  const response = await axiosInterceptor.get(API.TARGET.CUSTOMER_LIST, {
    params,
    signal,
  });

  return response;
};

export const setTarget = async (data: any) => {
  const response = await axiosInterceptor.post(API.TARGET.SET, data);

  return response;
};
