import { CheckCircle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { ONBOARDING_CUSTOMER_STATUS } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

// import Streaks from '../../assets/img/streaks.svg';

const CreditCustomerRow = ({ data, openCustomerPage, setAction }: any) => {
  console.log();

  return (
    <>
      <td
        className={`px-5 py-4 w-60 min-w-60 max-w-60  ${
          data?.status !== ONBOARDING_CUSTOMER_STATUS.FORM_PENDING &&
          data?.status !== ONBOARDING_CUSTOMER_STATUS.EXPIRED
            ? 'cursor-pointer'
            : ''
        }`}
        onClick={() => openCustomerPage(data)}
      >
        <div
          className={`flex items-center gap-3 ${
            data?.status !== ONBOARDING_CUSTOMER_STATUS.FORM_PENDING &&
            data?.status !== ONBOARDING_CUSTOMER_STATUS.EXPIRED
              ? 'cursor-pointer'
              : ''
          }`}
        >
          <div className="relative">
            {data.image ? (
              <img
                className="w-8 h-8  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                src={data.imageUrl + data.image}
                alt=""
                title=""
                onError={onError}
              />
            ) : (
              <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                {getShortName(`${data.name}`)}
              </div>
            )}

            {data.hasUnreadNotification ? (
              <span
                className={`border-[1.5px] border-white bg-primary500 rounded-full w-2.5 h-2.5 absolute top-0 right-0`}
              ></span>
            ) : (
              ''
            )}
          </div>

          {data.name.length > 20 ? (
            <div className="max-w-52  flex flex-col">
              <TooltipCmp
                message={data.name}
                childrenClassName="text-grayLight900 font-medium "
              >
                <p className="truncate">{data.name}</p>
              </TooltipCmp>
            </div>
          ) : (
            <div className="flex items-center gap-1">
              <span className="truncate block max-w-32 text-grayLight900 font-semibold leading-5">
                {data.name}
              </span>
            </div>
          )}
        </div>
      </td>
      <td
        className={`px-5 py-4 w-48 min-w-48 max-w-48 ${
          data?.status !== ONBOARDING_CUSTOMER_STATUS.FORM_PENDING &&
          data?.status !== ONBOARDING_CUSTOMER_STATUS.EXPIRED
            ? 'cursor-pointer'
            : ''
        }`}
        onClick={() => openCustomerPage(data)}
      >
        <div className="flex flex-wrap gap-1">
          {data.status === ONBOARDING_CUSTOMER_STATUS.APPROVED && (
            <BadgeCmp style="modern" type="success">
              Approved
            </BadgeCmp>
          )}
          {data.status === ONBOARDING_CUSTOMER_STATUS.FORM_COMPLETED && (
            <BadgeCmp style="modern" type="primary">
              Form Completed
            </BadgeCmp>
          )}
          {data.status === ONBOARDING_CUSTOMER_STATUS.FORM_PENDING && (
            <BadgeCmp style="modern" type="blue">
              Form Pending
            </BadgeCmp>
          )}
          {data.status === ONBOARDING_CUSTOMER_STATUS.DENIED && (
            <BadgeCmp style="modern" type="red">
              Denied
            </BadgeCmp>
          )}
        </div>
      </td>
      <td
        className={`px-5 py-4 min-w-60 w-60 max-w-60 ${
          data?.status !== ONBOARDING_CUSTOMER_STATUS.FORM_PENDING &&
          data?.status !== ONBOARDING_CUSTOMER_STATUS.EXPIRED
            ? 'cursor-pointer'
            : ''
        }`}
        onClick={() => openCustomerPage(data)}
      >
        {data.createdAt ? (
          <span
            dangerouslySetInnerHTML={{
              __html: data.createdAt
                ? getDateWithSuffixFormat(
                    getFormattedDate(
                      data.createdAt,
                      `MMM Do , hh:mm A`,
                      true,
                      true
                    )
                  )
                : '-',
            }}
          />
        ) : (
          ''
        )}
      </td>
      <td
        className={`px-5 py-4 min-w-60 w-60 max-w-60 ${
          data?.status !== ONBOARDING_CUSTOMER_STATUS.FORM_PENDING &&
          data?.status !== ONBOARDING_CUSTOMER_STATUS.EXPIRED
            ? 'cursor-pointer'
            : ''
        }`}
        onClick={() => openCustomerPage(data)}
      >
        <span>{data.email}</span>
      </td>
      <td
        className={`px-5 py-4  min-w-60 w-60 max-w-60 ${
          data?.status !== ONBOARDING_CUSTOMER_STATUS.FORM_PENDING &&
          data?.status !== ONBOARDING_CUSTOMER_STATUS.EXPIRED
            ? 'cursor-pointer'
            : ''
        }`}
        onClick={() => openCustomerPage(data)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.form_view_date
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.form_view_date,
                    `MMM Do , hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        />
      </td>
      <td className={`px-5 py-4 w-14 min-w-14 max-w-14`}>
        {data?.status === ONBOARDING_CUSTOMER_STATUS.APPROVED ? (
          <CheckCircle
            className="text-green500 w-4 h-4 cursor-pointer"
            onClick={() =>
              setAction((old: any) => ({
                ...old,
                ...{ mode: 'next-task', id: data?.id },
              }))
            }
          />
        ) : (
          <CheckCircle className="text-green500 w-4 h-4 invisible cursor-pointer" />
        )}
      </td>
    </>
  );
};
export default CreditCustomerRow;
