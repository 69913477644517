import { createSlice } from '@reduxjs/toolkit';

export interface MobileViewState {
  isMobile?: boolean;
  currentBreakpoint: any;
}

const initialState: MobileViewState = {
  isMobile: false,
  currentBreakpoint: '',
};

export const mobileViewSlice = createSlice({
  name: 'mobileView',
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setCurrentBreakPoint: (state, action) => {
      state.currentBreakpoint = action.payload;
    },
  },
});
export const { setIsMobile, setCurrentBreakPoint } = mobileViewSlice.actions;
export default mobileViewSlice.reducer;
