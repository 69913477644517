import moment from 'moment';
import React from 'react';
import CheckBox from 'src/components/CheckBox';

import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

const WithQuoteLoading = () => (
  <>
    <td className="px-5 py-4 min-w-60 w-60">
      <div className=" flex items-center ">
        <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none custom-loading">
          J D
        </div>
        <div className="pl-3 max-w-48">
          <h6 className="text-grayLight900 font-medium leading-tight custom-loading truncate">
            *****************
          </h6>
          <p className="text-grayLight600 font-normal leading-tight custom-loading">
            #11111
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="custom-loading px-2 py-0.5 bg-white rounded-md shadow border border-senary300 justify-start items-center gap-1 inline-flex">
        <div className="p-1 bg-green500 rounded-full" />
        <div className="text-center text-textSecondary text-[11px] font-medium leading-[18px]">
          #######
        </div>
      </div>
    </td>

    <td className="px-5 py-4">
      <div className="flex items-center custom-loading">
        <img
          className="w-4 h-4 relative rounded-full  border-[0.75px] border-black/[0.08]"
          src={FreightcomIcon}
          alt="FC"
        />

        <div className="pl-3 font-medium">NAME</div>
      </div>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading"> {`Est. 5 days`} </span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">
        {moment().format('MMM. Do - YYYY')}{' '}
      </span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">100000</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">100000</span>
    </td>
    <td className="px-5 py-4">
      {' '}
      <span className="text-textSecondary font-bold custom-loading">
        {' '}
        {'2000000000'}
      </span>
    </td>
    <td className="px-5 py-4">
      <CheckBox
        className="custom-loading"
        onChangeFunc={() => {}}
        parentClassName="items-center"
      />
    </td>
  </>
);

export default WithQuoteLoading;
