import {
  ChevronRightDouble,
  Clock,
  LinkExternal02,
  MessageTextCircle02,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TooltipCmp from 'src/components/TooltipCmp';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import {
  getDisputeData,
  updateDisputeStatus,
} from 'src/services/DisputeService';
import {
  displayTimeDifference,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../../../assets/img/Avatar7.png';
import doubleCheck from '../../../../assets/img/double-check.svg';
import ActionItem from '../../ActionItem';
import { CLAIM_DISPUTE_STATUS, getBadgeType } from '../../ClaimsDisputes.const';
import FileUpload from '../../FileUpload';
import InternalChatAndNotes from '../../InternalChatAndNotes';
import DisputeResolve from '../DisputeResolve';
import EditDisputeExplanation from '../EditDisputeExplanation';
import ModifyDisputeAmount from '../ModifyDisputeAmount';

const DisputeDetails = () => {
  const navigate = useNavigate();
  const { id: disputeId } = useParams<{ id: any }>();

  const [isLoading, setIsLoading] = useState(false);
  const [dispute, setDispute] = useState<any>(undefined);
  const [notesCount, setNotesCount] = useState<any>(null);
  const [userOptions, setUserOptions] = useState([]);

  const [isShowEditExplanationModal, setIsShowEditExplanationModal] =
    useState(false);

  const [isShowDisputeResolveModal, setIsShowDisputeResolveModal] =
    useState(false);
  const [isModifyDisputeAmount, setIsModifyDisputeAmount] = useState(false);
  const [isShowDisputeCloseModal, setIsShowDisputeCloseModal] = useState(false);
  const [isCloseDisputeLoading, setIsCloseDisputeLoading] = useState(false);
  const [isShowChatAndNotesSideBar, setIsShowChatAndNotesSideBar] =
    useState(true);

  const { hasRoleV2 } = useRolePermission();

  const { type, clockType } = getBadgeType(
    moment.utc(dispute?.createdAt).local().format('YYYY-MM-DD HH:mm:ss')
  );

  const getDisputeDetails = () => {
    setIsLoading(true);
    getDisputeData(disputeId)
      .then((response: any) => {
        if (response && response.data) {
          const responseData = response.data;
          setDispute(responseData);
          setUserOptions(responseData.members);
          setNotesCount(responseData.total_notes_count);
        } else {
          navigate(ROUTES.HOME);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDisputeDetails();
  }, []);

  const handleCloseDisputeConfirmationAction = (status = false) => {
    if (status) {
      setIsCloseDisputeLoading(true);
      updateDisputeStatus({
        disputeID: dispute.disputeID,
        status: CLAIM_DISPUTE_STATUS.CLOSED,
      })
        .then(() => {
          WalToast.success('Dispute closed successfully.');
          setIsShowDisputeCloseModal(false);
          getDisputeDetails();
        })
        .finally(() => {
          setIsCloseDisputeLoading(false);
        })
        .catch(console.log);
    } else {
      setIsShowDisputeCloseModal(false);
    }
  };

  return (
    <PageSectionLayout
      header={
        <Header
          leftClassName="!w-auto"
          title={
            <div className="flex gap-x-2.5 gap-y-1 flex-wrap items-center">
              Dispute:
              <span className={`${isLoading ? 'custom-loading ' : ''}`}>
                #{isLoading ? 'CL-AOI1-141' : dispute?.disputeID}
              </span>
              <div className="flex items-center [&>div:not(:first-child)]:border-white [&>div:not(:first-child)]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
                {dispute?.members?.map((groupCus: any) => (
                  <Fragment key={groupCus?.image}>
                    {groupCus.image && groupCus?.imageUrl ? (
                      <div className="rounded-full bg-white">
                        <img
                          src={`${groupCus?.imageUrl}${groupCus.image}`}
                          className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full ${
                            isLoading ? 'image-loading custom-loading' : ''
                          }`}
                        />
                      </div>
                    ) : (
                      <div className="rounded-full bg-white">
                        <img
                          src={TeamIcon1}
                          className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full ${
                            isLoading ? 'image-loading custom-loading' : ''
                          }`}
                        />
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
              {(dispute?.status || isLoading) && (
                <>
                  <BadgeCmp
                    style="modern"
                    mainClassName={`rounded-md ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    type={
                      dispute?.status === CLAIM_DISPUTE_STATUS.NEW
                        ? 'success'
                        : dispute?.status === CLAIM_DISPUTE_STATUS.RESOLVED
                        ? 'lightblue'
                        : dispute?.status === CLAIM_DISPUTE_STATUS.OPENED
                        ? 'primary'
                        : dispute?.status === CLAIM_DISPUTE_STATUS.CLOSED
                        ? 'error'
                        : 'success'
                    }
                  >
                    <p data-test-id="wal-QuotingDashboard-status">
                      {isLoading ? 'opened' : dispute?.status}
                    </p>
                  </BadgeCmp>
                </>
              )}
              {dispute?.createdAt || isLoading ? (
                <>
                  <BadgeCmp
                    style="pill"
                    type={type}
                    mainClassName={`rounded-md ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    <div className="flex items-center gap-1">
                      <Clock className={`w-3 h-3 mt-[1px] ${clockType}`} />
                      {displayTimeDifference(
                        moment
                          .utc(dispute?.createdAt)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')
                      )}
                    </div>
                  </BadgeCmp>
                </>
              ) : (
                false
              )}
            </div>
          }
          desc={`Manage, segment and view your customers here.`}
          rightSideContent={
            <div className="flex flex-wrap gap-3">
              {dispute?.status === CLAIM_DISPUTE_STATUS.OPENED && (
                <ButtonCmp
                  className={`btn-outline-primary max-sm:w-full ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                  onClick={() => setIsModifyDisputeAmount(true)}
                >
                  Modify Dispute Amount
                </ButtonCmp>
              )}

              {dispute?.status === CLAIM_DISPUTE_STATUS.OPENED &&
                (hasRoleV2('admin') || hasRoleV2('finance')) && (
                  <ButtonCmp
                    className={`btn_secondary_black max-sm:flex-1 ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    onClick={() => setIsShowDisputeResolveModal(true)}
                  >
                    Resolve Dispute
                  </ButtonCmp>
                )}

              {dispute?.status === CLAIM_DISPUTE_STATUS.RESOLVED &&
                (hasRoleV2('admin') || hasRoleV2('finance')) && (
                  <ButtonCmp
                    className={`btn_secondary_black ${
                      isCloseDisputeLoading || isLoading ? 'custom-loading' : ''
                    }`}
                    onClick={() => setIsShowDisputeCloseModal(true)}
                  >
                    Close Claim
                  </ButtonCmp>
                )}

              <ButtonCmp
                className="btn_secondary_black lg:!px-[9px] !px-2"
                onClick={() => navigate(PATH.CLAIMS_AND_DISPUTES_DETAILS)}
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
              {isShowChatAndNotesSideBar ? (
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() => setIsShowChatAndNotesSideBar(false)}
                >
                  <ChevronRightDouble className="w-4 h-4" />
                </ButtonCmp>
              ) : (
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() => setIsShowChatAndNotesSideBar(true)}
                >
                  <div className="relative">
                    <MessageTextCircle02 className="w-4 h-4" />
                    <p className="w-1.5 h-1.5 bg-successSecondary rounded-full absolute top-0 right-0"></p>
                  </div>
                </ButtonCmp>
              )}
            </div>
          }
        />
      }
      contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative"
    >
      <div className="flex h-full">
        <div className="flex-1 xls:pr-8 overflow-auto custom-scrollbar-v2 scrollbar-hide pt-5">
          {dispute?.status === CLAIM_DISPUTE_STATUS.RESOLVED && (
            <div className="rounded-md border border-utilitysuccess300 bg-green25 px-3 py-2 flex mb-3 ml-5">
              <div className="flex-none mr-1">
                <img src={doubleCheck} />
              </div>
              <div className="text-xs">
                <span className="text-grayLight900 text-xs font-medium">
                  {moment(dispute?.resolvedAt).format('Do MMMM, YYYY')}
                </span>
                <span className="text-xs font-medium text-gray500">
                  &nbsp;Resolved:&nbsp;
                </span>
                <span className="text-xs font-normal text-gray500">
                  {dispute?.resolveDescription}
                </span>
              </div>
            </div>
          )}

          <div className="flex flex-wrap justify-between items-end pl-5">
            <h6 className="text-grayLight900 text-xl font-semibold">
              Overview
            </h6>
            {dispute?.status !== CLAIM_DISPUTE_STATUS.RESOLVED && (
              <ButtonCmp
                className={`btn-outline-primary ${
                  isLoading ? 'custom-loading' : ''
                }`}
                onClick={() => setIsShowEditExplanationModal(true)}
              >
                Edit
              </ButtonCmp>
            )}
          </div>
          <p
            className={`text-grayLight600 text-sm font-normal mt-0.5 pr-16 pb-5 pl-5 border-b border-utilityGray200 ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {dispute && dispute?.disputeExplanation}
          </p>
          <div className="pt-4 flex gap-2 justify-between mb-6 pl-5 flex-wrap">
            <div>
              <h6 className="text-xs font-normal text-grayLight600 mb-1">
                Customer
              </h6>
              <a
                href={`https://wal.roserocket.com/#/ops/boards/customers?detailsTab=Details&recordPreview=${dispute?.customerId}%2Ccustomer`}
                className="text-primary700 text-sm font-medium "
                target="_blank"
              >
                <TooltipCmp
                  message={
                    dispute?.customer && dispute?.customer.length > 24
                      ? dispute?.customer
                      : null
                  }
                  parentClassName="md:!p-3 !p-1.5 max-w-90"
                >
                  <p
                    className={`xlm:max-w-56 max-w-40 truncate ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {isLoading ? 'Airtek Ontario Inc' : dispute?.customer}
                  </p>
                </TooltipCmp>
              </a>
            </div>
            <div className="sm:min-w-[17%] min-w-full">
              <h6 className="text-xs font-normal text-grayLight600 mb-1">
                Order ID
              </h6>
              <a
                href={`https://wal.roserocket.com/#/ops/orders/${dispute?.roserocketOrderId}`}
                className={`text-primary700 text-sm font-medium max-w-30 truncate block ${
                  isLoading ? 'custom-loading' : ''
                }`}
                target="_blank"
              >
                #{isLoading ? 'WAL-AOI1-21' : dispute?.orderID}
              </a>
            </div>
            <div className="sm:min-w-[17%] min-w-full">
              <h6 className="text-xs font-normal text-grayLight600 mb-1">
                Claim ID
              </h6>
              <p
                className={`text-primary700 text-sm font-medium flex items-center ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {dispute?.claimId ? (
                  <>
                    Link
                    <LinkExternal02 className="w-3.5 h-3.5 ml-0.5" />
                  </>
                ) : (
                  '-'
                )}
              </p>
            </div>
            <div className="sm:min-w-[17%] min-w-full relative">
              <h6 className="text-xs font-normal text-grayLight600 mb-1">
                Load Insurance
              </h6>

              <p
                className={`text-primary700 text-sm font-medium flex items-center ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {dispute?.load_insurance_id ? (
                  <a
                    href={`/load-insurance/${dispute?.loadInsuranceId}`}
                    className="text-primary700 text-sm font-medium max-w-30 truncate"
                    target="_blank"
                  >
                    #{dispute?.load_insurance_id}
                  </a>
                ) : (
                  '-'
                )}
              </p>
            </div>
            <div className="sm:min-w-[17%] min-w-full">
              <h6 className="text-xs font-normal text-grayLight600 mb-1">
                Dispute Amount
              </h6>
              <div className="flex gap-1">
                <p
                  className={`text-grayLight900 text-sm ${
                    dispute?.finalDisputeAmount
                      ? 'line-through font-normal'
                      : 'font-semibold'
                  } ${isLoading ? 'custom-loading' : ''}`}
                >
                  ${isLoading ? 255 : dispute?.disputeAmount}
                </p>
                {dispute?.finalDisputeAmount && (
                  <p
                    className={`text-fgSuccessPrimary text-sm font-semibold ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    ${dispute?.finalDisputeAmount}
                  </p>
                )}
              </div>
            </div>
          </div>

          <ActionItem
            disputeId={disputeId}
            dispute={dispute}
            userOptions={userOptions}
          />

          <FileUpload disputeId={disputeId} />
        </div>

        <InternalChatAndNotes
          dispute={dispute}
          isClaim={false}
          notesCount={notesCount}
          setNotesCount={setNotesCount}
          isShowChatAndNotesSideBar={isShowChatAndNotesSideBar}
          setIsShowChatAndNotesSideBar={setIsShowChatAndNotesSideBar}
        />
      </div>

      {isShowEditExplanationModal && (
        <EditDisputeExplanation
          handleClose={() => setIsShowEditExplanationModal(false)}
          dispute={dispute}
          title={'Edit Claim Explanation'}
          isEdit={true}
          getDispute={getDisputeDetails}
        />
      )}

      {isShowDisputeResolveModal && (
        <DisputeResolve
          handleClose={() => setIsShowDisputeResolveModal(false)}
          dispute={dispute}
          isDisputeListPage={false}
          reloadDisputes={getDisputeDetails}
        />
      )}

      {isModifyDisputeAmount && (
        <ModifyDisputeAmount
          dispute={dispute}
          handleClose={() => setIsModifyDisputeAmount(false)}
          getDisputeDetails={getDisputeDetails}
        />
      )}

      {isShowDisputeCloseModal && (
        <CommonModal
          title={`Close Dispute : #${dispute?.disputeID}`}
          titleDesc={`This action will close ${dispute?.disputeID} permanently.`}
          handleClose={() =>
            isCloseDisputeLoading
              ? false
              : handleCloseDisputeConfirmationAction(false)
          }
          secondaryBtnOnClick={() =>
            handleCloseDisputeConfirmationAction(false)
          }
          dangerBtnText="Close Dispute"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleCloseDisputeConfirmationAction(true)}
          primaryBtnLoading={isCloseDisputeLoading}
          primaryBtnDisabled={isCloseDisputeLoading}
          secondaryBtnDisabled={isCloseDisputeLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}
    </PageSectionLayout>
  );
};

export default DisputeDetails;
