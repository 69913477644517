import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { feedBackSlice } from '../app/Feedback/Feedback.slice';
import { teamSlice } from '../app/Teams/Teams.slice';

import { mobileViewSlice } from './MobileView.slice';
import { SocketConnection } from './SocketConnection.slice';

const rootReducer = combineReducers({
  Team: teamSlice.reducer,
  Feedback: feedBackSlice.reducer,
  MobileView: mobileViewSlice.reducer,
  SocketConnection: SocketConnection.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export default setupStore;
