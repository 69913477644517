import { ArrowRight, Mail01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';

// import SocialIcon from '../../../assets/img/Socialicon.png';

const NotFoundUI = () => {
  const navigate = useNavigate();

  const handleIntegrationsClick = () => {
    navigate('/integrations');
    // navigate('/integrations?isModalOpen=outlook');
  };

  return (
    <div className="h-full flex items-center justify-center overflow-hidden ">
      <div className="max-w-[480px] flex items-center justify-center flex-col z-[0] relative before:[''] before:bg-[url('../../assets/img/not-found-images/empty-state.png')] before:w-full before:h-[calc(100%+190px)] before:absolute before:bottom-0 before:z-[-1] before:bg-no-repeat before:bg-top ">
        <div
          className={`rounded-[10px] border border-utilityGray200 shadow-sm h-11 w-11 bg-white text-textSecondary flex items-center justify-center mb-4`}
        >
          <Mail01 className="w-5 h-5" />
        </div>
        <div className="text-center text-grayLight900 text-sm font-semibold leading-6 mb-1 bg-white">
          Setup your inbox
        </div>
        <div className="sm:w-[342px] p-1 text-center text-grayLight600 text-xs font-normal leading-normal bg-white mb-5">
          Connect to Outlook in order to access quote requests directly in WALHQ
        </div>

        <div className="flex">
          <ButtonCmp
            type="button"
            className="btn_primary"
            onClick={handleIntegrationsClick}
            icon={<ArrowRight className="text-white w-4 h-4" />}
            iconSide="right"
          >
            Go to Integrations
          </ButtonCmp>
        </div>
      </div>
    </div>
  );
};

export default NotFoundUI;
