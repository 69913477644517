import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import Toggle from 'src/components/Toggle/Toggle';
import { onError } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import defaultImage from '../../assets/img/default-image.jpg';
import Logo from '../../assets/img/logo2.svg';
import InputText from '../../components/InputText/InputText';

interface IProps {
  carrier: any;
  formData: any;
  handleClose: any;
}

const CarrierSpotQuoteModal = ({ carrier, formData, handleClose }: IProps) => {
  const createSchema = yup.object().shape({
    isSpotQuote: yup.boolean().required('Api key is required.'),
    email: yup.string().required('Email is required.'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createSchema),
    defaultValues: {
      isSpotQuote: false,
      email: '', // assuming email is a string
    },
  });

  const onSubmit = async (data: any) => {
    const mergedObject = { ...formData, ...data };
    console.log('mergedObject :>> ', mergedObject);
    handleClose();
  };
  const headerIcon = (
    <img
      src={Logo}
      alt="logo-light"
      className="object-covert h-full w-full p-1"
    />
  );

  return (
    <CommonModal
      title={`${carrier.name} Spot Quotes`}
      titleDesc={
        <p>
          Add a email address that we will use to automate <br />
          spot quoting functionality directly from WALHQ
        </p>
      }
      handleClose={handleClose}
      headerIcon={headerIcon}
      headerSecondIcon={
        carrier.image ? (
          <img
            src={`${carrier.imageUrl}${carrier.image}`}
            className="object-covert h-full w-full p-1"
            alt={carrier.name}
            title={carrier.name}
            onError={onError}
          />
        ) : (
          <img
            className="object-cover h-full w-full"
            src={defaultImage}
            alt={carrier.name}
            title={carrier.name}
          />
        )
      }
      headerIconClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-left-[69px] before:opacity-50"
      headerIconSecondClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex items-center relative ml-2 after:content-[''] after:h-[26px] after:w-[26px] after:border-utilityGray200 after:border after:rounded-full after:absolute  after:bg-[url('../../assets/img/link-01.svg')] after:bg-no-repeat after:bg-center after:bg-white after:top-1/2 after:-translate-y-1/2 after:-left-[22px] before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-right-[69px] before:opacity-50"
      size={'max-w-[580px]'}
      primaryBtnText="Continue"
      secondaryBtnText="Cancel"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      modalHeaderTextClass="!block text-center flex-1"
      headerInfoClass="mt-[18px]"
      modalHeaderClass="!pt-5"
    >
      <div className="w-full p-5">
        <Controller
          name="isSpotQuote"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <div className="flex gap-2 mb-3">
                <span className="text-sm fw-medium text-textSecondary">
                  Enable Spot Quotes
                </span>
                <Toggle
                  name="isSpotQuote"
                  labelClassName="mr-2"
                  isChecked={value}
                  onChange={onChange}
                />
              </div>
              <div className="[&:empty]:mt-0 mt-1.5">
                {errors.isSpotQuote && (
                  <ErrorMsg errorText={errors.isSpotQuote} />
                )}
              </div>
            </>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              placeholder="email"
              onChangeFunc={onChange}
              value={value}
              parentClassName="mb-5"
              label="Email"
              required
              labelClassName="mb-1.5 block"
              errorText={errors.email ? errors.email.message : null}
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default CarrierSpotQuoteModal;
