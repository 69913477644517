import {
  ChevronDown,
  SearchLg,
  ArrowLeft,
  ArrowRight,
  CalendarDate,
  // ArrowUp,
} from '@untitled-ui/icons-react//build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
// import ButtonCmp from 'src/components/ButtonCmp';
// import CustomPagination from 'src/components/CustomPagination';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import {
  CUSTOMER_SEGMENT_TYPES,
  TABLE_IDS,
  USER_ROLE,
} from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/context/AuthContext';
import { listCustomerTarget, listTarget } from 'src/services/TargetService';
import {
  capitalizeFirstLetter,
  confettiAnimation,
  getFormattedNumber,
  getProgressClass,
  getSelectBoxOptions,
  isValidJSON,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import confetti from '../../../assets/img/confetti.png';

import CustomerTargetLoadingRow from './CustomerTargetLoadingRow';
import CustomerTargetRow from './CustomerTargetRow';
import {
  SortBy,
  ITargetData,
  IGetTargetDataParams,
  ITotalTargetNumbers,
  initParamsForTargetSet,
  ICustomerData,
  IGetCustomerTargetDataParams,
  headCellsForCustomerTargets,
  ITotalCustomerTargetNumbers,
} from './Target.interface';
import TargetLoadingRow from './TargetLoadingRow';
import TargetRow from './TargetRow';
import TargetSetFooterRow from './TargetSetFooterRow';

const initParams: IGetTargetDataParams = {
  search: '',
  sortField: SortBy.CompletedProfitPercentage,
  sortType: 'desc',
  targetMonth: moment().format('YYYY-MM'),
};

const initTotalsData: ITotalTargetNumbers = {
  targetProfit: 0,
  targetLoads: 0,
  completedLoads: 0,
  completedProfit: 0,
  completedLoadsPercentage: 0,
  completedProfitPercentage: 0,
  customersCount: 0,
};

const TargetSet = () => {
  const divRef = useRef<any>(null);
  const [divHeight, setDivHeight] = useState<any>(0);
  const [month, setMonth] = useState<any>(new Date());
  const [segmentListOption, setSegmentListOption] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<any>(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.TARGET_LIST);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)?.targetMonth || initParams.targetMonth
      : initParams.targetMonth;
  });
  const [isOpenMonthPicker, setIsOpenDropDown] = useState<boolean>(false);
  const [isTotalLoading, setIsTotalLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');
  const [targetData, setTargetData] = useState<ITargetData[]>([]);
  const [customerTargetData, setCustomerTargetData] = useState<ICustomerData[]>(
    []
  );
  const [totalsData, setTotalsData] =
    useState<ITotalTargetNumbers>(initTotalsData);
  const [params, setParams] = useState<IGetTargetDataParams>(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.TARGET_LIST);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)
      : initParams;
  });
  const { currentUser } = useContext(AuthContext);
  const [paramsForCustomerData, setParamsForCustomerData] =
    useState<IGetCustomerTargetDataParams>({
      ...initParamsForTargetSet,
      onlyTarget: true,
      userId: currentUser.id,
    });
  const navigate = useNavigate();
  const { hasRoleV2 } = useRolePermission();
  const [total, setTotal] = useState<number>(0);
  const [animationPlayedForProfit, setAnimationPlayedForProfit] =
    useState<boolean>(() => {
      const profitConfetti = localStorage.getItem(
        'CONFETTI_PLAYED_FOR_PROFIT_COMPLETE'
      );

      if (profitConfetti) {
        return true;
      }

      return false;
    });
  const [animationPlayedForLoad, setAnimationPlayedForLoad] = useState<boolean>(
    () => {
      const loadConfetti = localStorage.getItem(
        'CONFETTI_PLAYED_FOR_LOAD_COMPLETE'
      );

      if (loadConfetti) {
        return true;
      }

      return false;
    }
  );
  const [footerData, setFooterData] = useState<ITotalCustomerTargetNumbers[]>(
    []
  );

  // const recordsPerPageArray = [
  //   { label: '25', value: 25 },
  //   { label: '50', value: 50 },
  //   { label: '100', value: 100 },
  // ];

  useEffect(() => {
    setIsLoading(true);
    const targetSetApiController = new AbortController();
    const targetSetApiSignal = targetSetApiController.signal;

    if (hasRoleV2(USER_ROLE.USER)) {
      setCustomerTargetData([]);
      if (paramsForCustomerData.search === '') setIsTotalLoading(true);
      listCustomerTarget(paramsForCustomerData, targetSetApiSignal)
        .then((response: any) => {
          if (response?.data) {
            setCustomerTargetData(response.data);
            setTotal(response?.total || 0);
            setTotalsData({
              targetProfit: response?.totals?.targetProfit || 0,
              targetLoads: response?.totals?.targetLoads || 0,
              completedLoads: response?.totals?.completedLoads || 0,
              completedProfit: response?.totals?.completedProfit || 0,
              completedLoadsPercentage:
                response?.totals?.completedLoadsPercentage || 0,
              completedProfitPercentage:
                response?.totals?.completedProfitPercentage || 0,
            });
            setFooterData([
              {
                name: 'Miscellaneous',
                isMiscellaneous: true,
                targetProfit: response?.miscellaneousData?.targetProfit || 0,
                targetLoads: response?.miscellaneousData?.targetLoads || 0,
                completedLoads:
                  response?.miscellaneousData?.completedLoads || 0,
                completedProfit:
                  response?.miscellaneousData?.completedProfit || 0,
                completedLoadsPercentage:
                  response?.miscellaneousData?.completedLoadsPercentage || 0,
                completedProfitPercentage:
                  response?.miscellaneousData?.completedProfitPercentage || 0,
                prevMonthTargetProfit:
                  response?.miscellaneousData?.prevMonthTargetProfit || 0,
                prevMonthTargetLoads:
                  response?.miscellaneousData?.prevMonthTargetLoads || 0,
                prevMonthCompletedLoads:
                  response?.miscellaneousData?.prevMonthCompletedLoads || 0,
                prevMonthCompletedProfit:
                  response?.miscellaneousData?.prevMonthCompletedProfit || 0,
                prevMonthCompletedLoadsPercentage:
                  response?.miscellaneousData
                    ?.prevMonthCompletedLoadsPercentage || 0,
                prevMonthCompletedProfitPercentage:
                  response?.miscellaneousData
                    ?.prevMonthCompletedProfitPercentage || 0,
              },
              {
                name: 'Total',
                isMiscellaneous: false,
                targetProfit: response?.totals?.targetProfit || 0,
                targetLoads: response?.totals?.targetLoads || 0,
                completedLoads: response?.totals?.completedLoads || 0,
                completedProfit: response?.totals?.completedProfit || 0,
                completedLoadsPercentage:
                  response?.totals?.completedLoadsPercentage || 0,
                completedProfitPercentage:
                  response?.totals?.completedProfitPercentage || 0,
                prevMonthTargetProfit:
                  response?.totals?.prevMonthTargetProfit || 0,
                prevMonthTargetLoads:
                  response?.totals?.prevMonthTargetLoads || 0,
                prevMonthCompletedLoads:
                  response?.totals?.prevMonthCompletedLoads || 0,
                prevMonthCompletedProfit:
                  response?.totals?.prevMonthCompletedProfit || 0,
                prevMonthCompletedLoadsPercentage:
                  response?.totals?.prevMonthCompletedLoadsPercentage || 0,
                prevMonthCompletedProfitPercentage:
                  response?.totals?.prevMonthCompletedProfitPercentage || 0,
              },
            ]);
          }
          setIsLoading(false);
          setIsTotalLoading(false);
        })
        .catch((e) => {
          if (e.code === 'ERR_CANCELED') {
            return;
          }
          setIsLoading(false);
          setIsTotalLoading(false);
        });
      setSearch(paramsForCustomerData?.search || '');
    } else {
      setTargetData([]);
      localStorage.setItem(TABLE_IDS.TARGET_LIST, JSON.stringify(params));
      if (params.search === '') setIsTotalLoading(true);
      listTarget(params, targetSetApiSignal)
        .then((response: any) => {
          if (response?.data) {
            setTargetData(response.data);
            setTotal(response?.total || 0);
          }

          if (params.search === '') {
            setTotalsData({
              targetProfit: response?.totals?.targetProfit || 0,
              targetLoads: response?.totals?.targetLoads || 0,
              completedLoads: response?.totals?.completedLoads || 0,
              completedProfit: response?.totals?.completedProfit || 0,
              completedLoadsPercentage:
                parseInt(response?.totals?.completedLoadsPercentage) || 0,
              completedProfitPercentage:
                parseInt(response?.totals?.completedProfitPercentage) || 0,
              customersCount: response?.totals?.customersCount || 0,
            });
          }
          setIsLoading(false);
          setIsTotalLoading(false);
        })
        .catch((e) => {
          if (e.code === 'ERR_CANCELED') {
            return;
          }
          setIsLoading(false);
          setIsTotalLoading(false);
        });
      setSearch(params?.search || '');
    }

    return () => {
      targetSetApiController.abort();
    };
  }, [params, paramsForCustomerData]);

  useEffect(() => {
    if (
      !animationPlayedForProfit &&
      totalsData?.completedProfitPercentage >= 100
    ) {
      confettiAnimation();
      localStorage.setItem('CONFETTI_PLAYED_FOR_PROFIT_COMPLETE', '1');
      setAnimationPlayedForProfit(true);
    }

    if (
      !animationPlayedForLoad &&
      totalsData?.completedLoadsPercentage >= 100
    ) {
      confettiAnimation();
      localStorage.setItem('CONFETTI_PLAYED_FOR_LOAD_COMPLETE', '1');
      setAnimationPlayedForLoad(true);
    }
  }, [totalsData]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsOpenDropDown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datePickerRef]);

  useEffect(() => {
    if (hasRoleV2(USER_ROLE.USER)) {
      setParamsForCustomerData((old: IGetCustomerTargetDataParams) => ({
        ...old,
        targetMonth: dateRange,
      }));
    } else {
      setParams((old: IGetTargetDataParams) => ({
        ...old,
        targetMonth: dateRange,
      }));
    }
    setMonth(moment(dateRange, 'YYYY-MM').toDate());
  }, [dateRange]);

  const headCells = useMemo(
    () => [
      {
        id: SortBy.UserName,
        name: 'Users',
        sortable: true,
        rowClassName: '',
      },
      {
        id: SortBy.CompletedProfit,
        name: 'Current Margin',
        sortable: true,
        rowClassName: '',
      },
      {
        id: SortBy.TargetProfit,
        name: 'Target Margin',
        sortable: true,
        rowClassName: '',
      },
      {
        id: SortBy.CompletedProfitPercentage,
        name: 'Margin Comp.',
        sortable: true,
        rowClassName: '',
      },
      {
        id: SortBy.CompletedLoads,
        name: 'Current Loads',
        sortable: true,
        rowClassName: '',
      },
      {
        id: SortBy.TargetLoads,
        name: 'Target Loads',
        sortable: true,
        rowClassName: '',
      },
      {
        id: SortBy.CompletedLoadsPercentage,
        name: 'Load Comp.',
        sortable: true,
        rowClassName: ' ',
      },
      {
        id: SortBy.CustomersCount,
        name: 'Customers',
        sortable: true,
      },
    ],
    []
  );

  const getFilterRange = (type: string) => {
    if (type === 'prev') {
      const prevMonth = moment(dateRange, 'YYYY-MM').subtract(1, 'months');

      if (hasRoleV2(USER_ROLE.USER)) {
        if (
          prevMonth.isSame(moment(), 'month') ||
          prevMonth.isSame(moment().add(1, 'month'), 'month')
        ) {
          setDateRange(prevMonth.format('YYYY-MM'));
        }
      } else {
        if (prevMonth.isSameOrAfter(moment('2024-08', 'YYYY-MM'))) {
          setDateRange(prevMonth.format('YYYY-MM'));
        }
      }
    }

    if (type === 'next') {
      const nextMonth = moment(dateRange, 'YYYY-MM').add(1, 'months');

      if (hasRoleV2(USER_ROLE.USER)) {
        if (
          nextMonth.isSame(moment(), 'month') ||
          nextMonth.isSame(moment().add(1, 'months'), 'month')
        ) {
          setDateRange(nextMonth.format('YYYY-MM'));
        }
      } else {
        setDateRange(nextMonth.format('YYYY-MM'));
      }
    }
  };

  const handleRowClick = (data: ITargetData) => {
    localStorage.setItem(
      TABLE_IDS.TARGET_LIST_BY_CUSTOMER,
      JSON.stringify({
        ...initParamsForTargetSet,
        userId: data.userId,
        targetMonth: params.targetMonth || initParams.targetMonth,
      })
    );
    navigate(ROUTES.SALES_TARGET_EDIT);
  };

  const searchDebounce = useCallback(
    debounce((debSearch: string) => {
      if (hasRoleV2(USER_ROLE.USER)) {
        setParamsForCustomerData((old: IGetCustomerTargetDataParams) => ({
          ...old,
          targetMonth: dateRange,
          search: debSearch,
          page: 1,
        }));
      } else {
        setParams((old: IGetTargetDataParams) => ({
          ...old,
          search: debSearch,
          page: 1,
        }));
      }
    }, 700),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchTargetSet') {
      setSearch(value);

      searchDebounce(value);
    }
  };

  useEffect(() => {
    const statusArray = Object.entries(CUSTOMER_SEGMENT_TYPES).map(
      (keyValue: any) => ({
        id: keyValue?.[1],
        value: capitalizeFirstLetter(keyValue?.[1]),
      })
    );
    setSegmentListOption(getSelectBoxOptions(statusArray, 'id', 'value'));

    if (divRef.current) {
      setDivHeight(`calc(100% - ${divRef?.current?.offsetHeight - 4}px)`);
    }
  }, [divRef?.current?.offsetHeight, params.targetMonth, totalsData]);

  return (
    <PageSectionLayout
      contentClassName="h-[calc(100%_-_87px)]"
      header={
        <Header
          title="Sales Targets"
          desc="Set and view monthly sales targets for your sales team."
          mainContainerClassName="!flex-nowrap mdm:!flex-row !flex-col mdm:!items-center !items-start"
          rightClassName="mdm:min-w-fit min-w-full"
        />
      }
    >
      <ul className="xls:-m-2.5 sm:-m-2 -m-1.5 flex flex-wrap" ref={divRef}>
        <li className="xls:p-2.5 sm:p-2 p-1.5 sm:w-1/2 w-full">
          <div className="rounded-xl border border-utilityGray200 bg-white shadow-xs h-full flex flex-col">
            <div className="xlm:p-6 p-4 border-b border-utilityGray200 flex-1">
              <div className="flex items-start justify-between gap-x-3 gap-y-2 max-xls:flex-col max-sm:flex-row max-smd:flex-col">
                <div>
                  <div className="flex items-center gap-1.5">
                    <h6 className="text-grayLight900 xlm:text-xl  text-lg font-semibold leading-[1.5]">
                      Total Projected Margin
                    </h6>
                    {/* <BadgeCmp style="pill" type="success">
                      <ArrowUp className="w-3.5 h-3.5 mr=[1px]" />
                      21%
                    </BadgeCmp> */}
                    {totalsData.completedProfitPercentage >= 100 && (
                      <img src={confetti} className="xl:w-5 w-4" />
                    )}
                    {/* <BadgeCmp style="pill" type="primary">
                      {moment(dateRange, 'YYYY-MM').format('MMMM, YYYY')}
                    </BadgeCmp> */}
                  </div>
                  <p className="text-grayLight600 xlm:text-sm text-xs font-normal mt-1">
                    {hasRoleV2(USER_ROLE.USER)
                      ? `View the combined total projected margin for all of your customers.`
                      : `View combined total projected margin for all of your users &
                      customers.`}
                  </p>
                </div>
                <div
                  className={`flex items-center gap-1.5 ${
                    isTotalLoading ? 'custom-loading w-44' : ''
                  }`}
                >
                  {/* {totalsData.completedProfitPercentage >= 100 && (
                    <img src={confetti} className="xl:w-5 w-4" />
                  )} */}
                  <span className="text:ext-grayLight900 text-2xl font-semibold">
                    {totalsData.completedProfit > 0 ? (
                      <>
                        {getFormattedNumber(
                          Math.round(totalsData.completedProfit),
                          false
                        )}
                        <sup className="text-xs font-semibold leading-tight -top-[0.9em]">
                          $
                        </sup>
                      </>
                    ) : (
                      <>
                        <>0</>
                        <sup className="text-xs font-semibold leading-tight -top-[0.9em]">
                          $
                        </sup>
                      </>
                    )}
                    /
                    {totalsData.targetProfit > 0 ? (
                      <>
                        {getFormattedNumber(
                          Math.round(totalsData.targetProfit),
                          false
                        )}
                        <sup className="text-xs font-semibold leading-tight -top-[0.9em]">
                          $
                        </sup>
                      </>
                    ) : (
                      <>
                        <>0</>
                        <sup className="text-xs font-semibold leading-tight -top-[0.9em]">
                          $
                        </sup>
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="xlm:px-6 xlm:py-5 p-4 flex items-center">
              <progress
                className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-full ${getProgressClass(
                  totalsData.completedProfitPercentage
                )} bg-utilityGray200 me-3`}
                value={totalsData.completedProfitPercentage}
                max="100"
              ></progress>
              <span className="text-textSecondary font-medium text-sm w-9">
                {totalsData.completedProfitPercentage}%
              </span>
            </div>
          </div>
        </li>
        <li className="xls:p-2.5 sm:p-2 p-1.5 sm:w-1/2 w-full">
          <div className="rounded-xl border border-utilityGray200 bg-white shadow-xs h-full flex flex-col">
            <div className="xlm:p-6 p-4 border-b border-utilityGray200 flex-1">
              <div className="flex items-start justify-between gap-x-3 gap-y-2 max-xls:flex-col max-sm:flex-row max-smd:flex-col">
                <div>
                  <div className="flex items-center gap-1.5">
                    <h6 className="text-grayLight900 xlm:text-xl  text-lg font-semibold leading-[1.5]">
                      Total Projected Loads
                    </h6>

                    {/* <BadgeCmp style="pill" type="error">
                      <ArrowUp className="w-3.5 h-3.5 mr=[1px]" />
                      21%
                    </BadgeCmp> */}
                    {totalsData.completedLoadsPercentage >= 100 && (
                      <img src={confetti} className="xl:w-5 w-4" />
                    )}
                    {/* <BadgeCmp style="pill" type="primary">
                      {moment(dateRange, 'YYYY-MM').format('MMMM, YYYY')}
                    </BadgeCmp> */}
                  </div>
                  <p className="text-grayLight600 xlm:text-sm text-xs font-normal mt-1">
                    {hasRoleV2(USER_ROLE.USER)
                      ? `View the combined total projected loads for all of your customers.`
                      : `View combined total projected loads for all of your users &
                      customers.`}
                  </p>
                </div>
                <div
                  className={`flex items-center gap-1.5 ${
                    isTotalLoading ? 'custom-loading w-32' : ''
                  }`}
                >
                  <span className="text:ext-grayLight900 text-2xl font-semibold">
                    {totalsData.completedLoads > 0
                      ? totalsData.completedLoads
                      : '0'}
                    /{totalsData.targetLoads > 0 ? totalsData.targetLoads : '0'}
                  </span>
                </div>
              </div>
            </div>
            <div className="xlm:px-6 xlm:py-5 p-4 flex items-center">
              <progress
                className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-full bg-utilityGray200 me-3 ${getProgressClass(
                  totalsData.completedLoadsPercentage
                )}`}
                value={totalsData.completedLoadsPercentage}
                max="100"
              ></progress>
              <span className="text-textSecondary font-medium text-sm w-9">
                {totalsData.completedLoadsPercentage}%
              </span>
            </div>
          </div>
        </li>
      </ul>

      <div
        style={{ height: divHeight }}
        key={`${divHeight}`}
        className={`w-full bg-white rounded-xl shadow border border-utilityGray200 flex flex-col`}
      >
        <div className="table-top-header flex-wrap max-xls:justify-start">
          <div className="table-left-wrap  max-xls:min-w-[auto] max-sm:flex-1">
            <div className="table-title-wrap">
              <h5 className="table-title  ">
                {hasRoleV2(USER_ROLE.USER)
                  ? 'Monthly Customer Targets'
                  : 'Monthly Targets'}
              </h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={isLoading ? 'custom-loading' : ''}
              >
                {total} {total <= 1 ? 'Target' : 'Targets'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              {hasRoleV2(USER_ROLE.USER)
                ? 'View your historical monthly customer sales targets.'
                : 'Set and view monthly targets per user'}
            </p>
          </div>
          {/* <ButtonCmp
            className="btn_secondary_black  flex smd:w-fit w-full xls:order-1 order-0"
            icon={<DownloadCloud02 className="text-textSecondary h-4 w-4" />}
          >
            Export
          </ButtonCmp> */}
          <div className="table-right-wrap  max-xls:flex-[0_0_100%]">
            <InputText
              inputName="searchTargetSet"
              placeholder="Search"
              className="bg-white focus:bg-white pr-7 pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              isClearable={true}
              // value={
              //   hasRoleV2(USER_ROLE.USER)
              //     ? paramsForCustomerData.search
              //     : params.search
              // }
              value={search}
              inputType="text"
              // onChangeFunc={(e) => {
              //   if (hasRoleV2(USER_ROLE.USER)) {
              //     setParamsForCustomerData(
              //       (old: IGetCustomerTargetDataParams) => ({
              //         ...old,
              //         targetMonth: dateRange,
              //         search: e.target.value,
              //         page: 1,
              //       })
              //     );
              //   } else {
              //     setParams((old: IGetTargetDataParams) => ({
              //       ...old,
              //       search: e.target.value,
              //       page: 1,
              //     }));
              //   }
              // }}
              onChangeFunc={handleFilter}
              parentClassName="table-searchInput max-xls:flex-1 max-sm:flex-[0_0_100%]"
            />
            <div className="table-datepicker-v2 cursor-pointer">
              <div className="w-full xl:px-3.5 px-3 lg:py-[9px] py-2 rounded-lg shadow border border-gray-300 lg:h-[36px] h-[34px]  justify-between items-center gap-1.5 flex bg-white  hover:border-grayText transition-all">
                <ArrowLeft
                  className="xs:w-4 xs:h-4 w-3 h-3 cursor-pointer flex-none"
                  onClick={() => getFilterRange('prev')}
                />

                <div
                  className="flex flex-1 justify-center items-center"
                  onClick={() => {
                    setIsOpenDropDown(true);
                  }}
                >
                  <CalendarDate className="w-4 h-4 mr-2" />
                  <div className="text-textSecondary text-xs font-medium leading-none">
                    {' '}
                    {moment(dateRange, 'YYYY-MM').format('MMMM YYYY')}
                  </div>
                </div>

                <ArrowRight
                  className="xs:w-4 xs:h-4 w-3 h-3 cursor-pointer flex-none"
                  onClick={() => getFilterRange('next')}
                />
              </div>
              {isOpenMonthPicker && (
                <div
                  className="shadow-lg mt-2.5 mb-2 z-[2] rounded-lg border border-utilityGray200 absolute bg-white datepicker-v2"
                  ref={datePickerRef}
                >
                  <DateSelect
                    inputName="pickupDate"
                    selected={month}
                    showMonthYearPicker
                    dateFormat="MMMM-yyyy"
                    onChangeFunc={(e: any) => {
                      setMonth(moment(e.value, 'MMM YYYY').toDate());
                      setDateRange(
                        moment(e.value, 'MMM YYYY').format('YYYY-MM')
                      );
                      setIsOpenDropDown(false);
                    }}
                    inline={isOpenMonthPicker}
                    icon={<ChevronDown className="w-5 h-5" />}
                    minDate={moment('2024-08-01', 'YYYY-MM-DD').toDate()}
                  />
                </div>
              )}
            </div>
            {hasRoleV2(USER_ROLE.USER) ? (
              <div className="table-statustbox mdm:block">
                <SelectBox
                  name="segmentGroup"
                  id="segmentGroup"
                  className="form_control"
                  size="sm"
                  placeholder="All Tags"
                  isClearable={true}
                  options={segmentListOption}
                  onChangeFunc={(event: any) => {
                    setParamsForCustomerData(
                      (old: IGetCustomerTargetDataParams) => ({
                        ...old,
                        tag: event?.value ?? '',
                        page: 1,
                      })
                    );
                  }}
                  value={segmentListOption.filter(
                    (val: any) => paramsForCustomerData?.tag === val?.value
                  )}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          {/* <div className="mdm:min-w-[155px] smd:min-w-[calc(50%_-_6px)] min-w-full mdm:order-none order-4">
            <div
              className=" rounded-lg shadow-sm border border-gray-300 flex text-textSecondary text-xs font-medium"
              onClick={() => {
                setIsOpenDropDown(true);
              }}
            >
              <div className="flex flex-1 items-center sm:min-w-[152px] min-w-32 justify-center lg:px-3 px-2 lg:py-[9px] py-2 gap-2">
                {moment(dateRange, 'YYYY-MM').format('MMMM YYYY')}
                <ChevronDown className="w-4 h-4 text-gray400" />
              </div>
            </div>
          </div> */}
          {/* <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArray}
              onChangeFunc={(event: any) => {
                setParams((old: IGetTargetDataParams) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={recordsPerPageArray.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div> */}
        </div>

        <div className="flex flex-col lg:min-h-[122px] w-full border-t border-gray100 flex-1">
          <div className="overflow-x-auto custom-scrollbar-v2 custom-scrollbar flex flex-col justify-between rounded-b-xl h-full ">
            {hasRoleV2(USER_ROLE.USER) ? (
              <TableCmp
                headCells={headCellsForCustomerTargets}
                tableDataArr={customerTargetData}
                TableLoaderRowCmp={CustomerTargetLoadingRow}
                TableRowCmp={CustomerTargetRow}
                params={paramsForCustomerData}
                setParams={setParamsForCustomerData}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={9}
                isTableRowClickable={false}
                tableFooterRowClass="last:border-b-0"
                footerDataArr={footerData}
                TableFooterRowCmp={TargetSetFooterRow}
              />
            ) : (
              <TableCmp
                headCells={headCells}
                tableDataArr={targetData}
                TableLoaderRowCmp={TargetLoadingRow}
                TableRowCmp={TargetRow}
                tableRowCmpProps={{ targetMonth: params.targetMonth }}
                params={params}
                setParams={setParams}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={9}
                isTableRowClickable={true}
                tableFooterRowClass="last:border-b-0"
                onRowClick={handleRowClick}
              />
            )}
          </div>
          {hasRoleV2(USER_ROLE.USER) ? (
            !customerTargetData.length && !isLoading ? (
              <NotFoundUI
                title="No targets found"
                desc="There are no targets for the user."
                containerClassName="min-h-[calc(100%_-_176.5px)] !h-auto"
              />
            ) : (
              <></>
            )
          ) : !targetData.length && !isLoading ? (
            <NotFoundUI
              title="No target set found"
              desc="There are no data for team members you can create new team member."
              containerClassName="min-h-[calc(100%_-_176.5px)] !h-auto"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </PageSectionLayout>
  );
};

export default TargetSet;
