import { Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const ContactTabOrderLoadingRaw = () => (
  <>
    <td className="px-5 py-4 w-[20%] min-w-[25%] max-w-72">
      <h6 className="truncate custom-loading">Kate Morrison</h6>
    </td>
    <td className="px-5 py-4 w-[25%] min-w-[25%] max-w-64 ">
      <span className="custom-loading">Account Executive</span>
    </td>
    <td className="px-5 py-4 w-[25%] min-w-[25%] max-w-64 ">
      <span className="custom-loading">+(415)555-0132</span>
    </td>
    <td className="px-5 py-4 w-[25%] min-w-[25%] max-w-64 ">
      <p className="truncate custom-loading">kate@walhq.com</p>
    </td>
    <td className="px-5 py-4 max-w-20 w-20 min-w-20">
      <div className="justify-center items-center gap-2 flex ">
        <span className="custom-loading">
          <Trash01 className="w-4 h-4 relative cursor-pointer" />
        </span>
      </div>
    </td>
  </>
);

export default ContactTabOrderLoadingRaw;
