import {
  Calendar,
  CurrencyDollar,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import DateSelect from 'src/components/DateSelect/DateSelect';
import { onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import defaultImage from '../../../../assets/img/default-image.jpg';
import AccordionCmp from '../../../../components/AccordionCmp';
import Drawer from '../../../../components/Drawer';
import InputText from '../../../../components/InputText/InputText';
import { MARGIN_TYPE } from '../../../../constants/common';
import { updateRate } from '../../../../services/QuoteService';

export const DATE_FORMAT: string = 'MMM DD, YYYY';

const initFormData = {
  transitTime: 0,
  carrierQuoteId: '',
  projectedDeliveryDate: moment(),
  margin: 0,
  marginUnit: 0,
  additionalCharges: 0,
  finalCharge: 0,
  totalCharge: 0,
  marginType: MARGIN_TYPE.VALUE,
  services: [],
  currency: '',
};

const initValidForm = {
  transitTime: false,
  carrierQuoteId: false,
  totalCharge: false,
};

const initEditableFormData = {
  carrierQuoteId: false,
  transitTime: false,
  projectedDeliveryDate: false,
  marginUnit: false,
  totalCharge: false,
};

const initRequiredFields = ['carrierQuoteId', 'totalCharge', 'transitTime'];

const QuoteDetail = ({
  isOpen,
  handleClose,
  currency,
  carrierRate,
  setIsRefresh,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState<any>(initValidForm);
  const [isValid, setIsValid] = useState<any>({});

  const [editableFormData, setEditableFormData] =
    useState(initEditableFormData);

  useEffect(() => {
    setIsValid(Object.values(validForm).every((value) => value));
  }, [validForm]);

  useEffect(() => {
    Object.entries(formData).forEach(([key, value]: any) => {
      if (initRequiredFields.includes(key)) {
        setValidForm({ ...validForm, [key]: value ? true : false });
      }
    });
  }, [formData]);

  useEffect(() => {
    let services: any = [];

    if (carrierRate?.services && carrierRate?.services?.length) {
      carrierRate.services.forEach((service: any) => {
        services.push({
          [service.serviceId.slug]: {
            name: service.serviceId?.name,
            value: service.value || 0,
            serviceId: service.serviceId?.id,
          },
        });
        // existingRequiredFields.push(service.serviceId.slug);
      });
    }

    setFormData({ ...formData, services: services, currency });
  }, [carrierRate]);

  // const formatNumberData = (data: any) => {
  //   Object.entries(data).forEach(([key, value]: any) => {
  //     if (
  //       [
  //         'totalCharge',
  //         'marginUnit',
  //         'margin',
  //         'additionalCharges',
  //         'finalCharge',
  //       ].includes(key)
  //     ) {
  //       if (value !== '') {
  //         // let formValue = parseFloat(value);
  //         data[key] = parseFloat(value).toFixed(2);
  //         // data[key] = Number.isInteger(formValue)
  //         //   ? formValue.toString()
  //         //   : formValue.toFixed(2);
  //       }
  //     }
  //   });

  //   return data;
  // };

  const handleInputChange = (event: any, isService = false) => {
    const { target, type } = event;
    const { name, value } = target;

    const regex = /^[0-9.]*$/;

    if (
      ['totalCharge', 'marginUnit', 'transitTime', 'margin'].includes(name) &&
      !regex.test(value)
    ) {
      return;
    }

    // if (name === "carrierQuoteId" && !editableFormData.carrierQuoteId) {
    //     return;
    // }

    if (initRequiredFields.includes(name)) {
      setValidForm({ ...validForm, [name]: value ? true : false });
    }

    const existingFormData: any = { ...formData };

    if (!isService) {
      existingFormData[name] = value;
    }

    if (name === 'transitTime') {
      existingFormData.projectedDeliveryDate = moment()
        .clone()
        .add(value, 'days')
        .toDate();
    }

    if (['totalCharge', 'marginUnit', 'margin'].includes(name) || isService) {
      const parseValue =
        type === 'blur' && !value ? 0 : value ? parseFloat(value) : 0;

      let additionalCharges = 0;

      existingFormData.services.forEach((quoteService: any, index: number) => {
        Object.entries(quoteService).forEach(([slug, service]: any) => {
          service.value = service.value || 0;
          additionalCharges +=
            name === slug ? parseValue : parseFloat(service.value);
          existingFormData.services[index][slug] = {
            ...service,
            value: name === slug ? value : parseFloat(service.value),
          };
        });
      });
      existingFormData.additionalCharges = additionalCharges.toFixed(2);
      const total: number =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges || 0);
      existingFormData.marginType = MARGIN_TYPE.PERCENTAGE;

      let margin: number = existingFormData.margin
        ? parseFloat(existingFormData.margin)
        : 0;
      let marginUnit: number = existingFormData.marginUnit
        ? parseFloat(existingFormData.marginUnit)
        : 0;

      if (name === 'marginUnit') {
        margin =
          total > 0 ? parseFloat(((total / 100) * marginUnit).toFixed(2)) : 0;
      } else {
        marginUnit =
          total > 0 ? parseFloat(((100 * margin) / total).toFixed(2)) : 0;
      }
      existingFormData.margin = margin;
      existingFormData.marginUnit = marginUnit;

      if (name === 'finalCharge') {
        if (type === 'blur') {
          existingFormData[name] =
            parseValue < total ? total.toFixed(2) : parseValue.toFixed(2);
        }
        let newMargin = parseValue - total;

        if (existingFormData.marginType === MARGIN_TYPE.PERCENTAGE) {
          newMargin = ((parseValue - total) / total) * 100;
        }
        existingFormData.margin =
          parseValue < total ? 0 : newMargin ? newMargin.toFixed(2) : newMargin;
      } else {
        existingFormData.finalCharge = (total + margin).toFixed(2);
      }

      if (name === 'marginUnit') {
        existingFormData.marginUnit = value;
      } else if (name === 'margin') {
        existingFormData.margin = value;
      }
    }

    setFormData(existingFormData);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    updateRate(carrierRate.id, formData)
      .then(() => {
        setFormData(initFormData);
        handleClose();
        setIsRefresh(true);
        WalToast.success('Rate updated successfully', '');
      })
      .finally(() => setIsLoading(false))
      .catch(console.error);
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        handleClose={handleClose}
        CloseIconClassName="hidden"
        headerClassName="!p-0 !border-0 !hidden"
        classDrawer="max-w-11/2 sm:!w-[430px] !w-[316px] flex flex-col"
        drawerHeight="pb-0 flex-1 flex flex-col"
      >
        {isOpen ? (
          <>
            <div className="flex-1">
              <div className="flex gap-1 mb-4">
                <img
                  src={
                    carrierRate.image
                      ? carrierRate.imageUrl + carrierRate.image
                      : defaultImage
                  }
                  onError={onError}
                  className="rounded border border-utilityGray200 w-5 h-5 flex-none"
                  alt=""
                />
                <h6 className="text-grayLight900 text-sm font-semibold">
                  {carrierRate.name}
                </h6>
              </div>
              <div className="pb-4 border-b border-utilityGray200">
                <InputText
                  label="Carrier quote ID"
                  labelClassName="mb-1.5 block text-textSecondary"
                  inputType="text"
                  inputName="carrierQuoteId"
                  value={formData.carrierQuoteId}
                  onChangeFunc={handleInputChange}
                  onBlur={handleInputChange}
                  parentClassName="mb-2"
                />
                <InputText
                  label="Transit time"
                  labelClassName="mb-1.5 block text-textSecondary"
                  inputType={editableFormData.transitTime ? 'number' : 'text'}
                  inputName="transitTime"
                  value={
                    editableFormData.transitTime
                      ? formData.transitTime
                      : `Est. ${
                          formData ? formData.transitTime : 0
                        } Business Days.`
                  }
                  onChangeFunc={handleInputChange}
                  onBlur={() =>
                    setEditableFormData((old: any) => ({
                      ...old,
                      transitTime: false,
                    }))
                  }
                  onFocus={() =>
                    setEditableFormData((old: any) => ({
                      ...old,
                      transitTime: true,
                    }))
                  }
                  parentClassName="mb-2"
                />
                <DateSelect
                  inputName="projectedDeliveryDate"
                  className={`form_control `}
                  label="Projected delivery"
                  labelClassName="block"
                  placeholder={DATE_FORMAT}
                  dateFormat="dd/MM/yyyy"
                  minDate={moment().toDate()}
                  selected={
                    formData && formData.projectedDeliveryDate
                      ? moment(formData.projectedDeliveryDate).toDate()
                      : null
                  }
                  errorText={''}
                  onChangeFunc={(date: any) => {
                    const daysDiff =
                      moment(moment(date?.value)).diff(moment(), 'days') + 1;

                    if (
                      daysDiff > 0 &&
                      initRequiredFields.includes('transitTime')
                    ) {
                      setValidForm({
                        ...validForm,
                        transitTime: daysDiff ? true : false,
                      });
                    }
                    setFormData({
                      ...formData,
                      projectedDeliveryDate: moment(date?.value),
                      transitTime: daysDiff,
                    });
                  }}
                  icon={<Calendar className="h-4 w-4 " />}
                  calendarIconClass="!text-primary"
                  parentClassName="fixed-width-datepicker right-side-datepicker"
                />
              </div>

              {formData?.services?.length > 0 && (
                <div className="py-4 border-b border-utilityGray200">
                  <h6 className="text-utilityBlack text-sm font-semibold mb-3">
                    Additional Services
                  </h6>
                  <AccordionCmp
                    title={
                      <h5 className="text-sm">
                        Non-Quoted Services
                        <span className="text-fgErrorPrimary">*</span>
                      </h5>
                    }
                    contentParentClass="!border-0 !mt-0"
                    className="[&:not(:last-child)]:mb-2 !px-3 !rounded-xl"
                  >
                    {formData.services.map((nonQuoteService: any) =>
                      Object.entries(nonQuoteService).map(
                        ([slug, service]: any) => (
                          <>
                            <InputText
                              inputName={slug}
                              className="pl-8"
                              parentClassName="[&:not(:last-child)]:mb-4"
                              value={service.value}
                              label={service.name}
                              labelClassName="block mb-1.5"
                              icon={
                                <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-4 w-4" />
                              }
                              onChangeFunc={(e) => handleInputChange(e, true)}
                              onBlur={(e) => handleInputChange(e, true)}
                              onFocus={(e) => handleInputChange(e, true)}
                            />
                          </>
                        )
                      )
                    )}
                  </AccordionCmp>
                </div>
              )}
              <div className="py-4 border-b border-utilityGray200">
                <h6 className="text-utilityBlack text-sm font-semibold mb-3">
                  Total Cost
                </h6>
                <InputText
                  label="Base Quote"
                  labelClassName="mb-1.5 block text-textSecondary"
                  inputType="text"
                  inputName="totalCharge"
                  value={formData.totalCharge}
                  onChangeFunc={handleInputChange}
                  onBlur={handleInputChange}
                  parentClassName="mb-2"
                />
                <InputText
                  label="Additional Services"
                  labelClassName="mb-1.5 block text-textSecondary"
                  inputType="text"
                  disabled={true}
                  inputName="additionalCharges"
                  value={formData.additionalCharges || 0}
                  parentClassName=""
                />
              </div>
              <div className="py-4 border-b border-utilityGray200">
                <h6 className="text-utilityBlack text-sm font-semibold mb-3">
                  Total Quote
                </h6>
                <div className="flex sm:flex-row flex-col -m-1 mb-2">
                  <InputText
                    inputType="text"
                    inputName="margin"
                    value={formData.margin}
                    onChangeFunc={handleInputChange}
                    onBlur={handleInputChange}
                    className="pl-[40px]"
                    label="Margin"
                    labelClassName="block"
                    icon={
                      <div className="bg-utilityGray100 absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                        <CurrencyDollar className="w-4 h-4" />
                      </div>
                    }
                    parentClassName="sm:w-1/2 p-1"
                  />
                  <InputText
                    inputType="text"
                    inputName="marginUnit"
                    value={formData.marginUnit}
                    onChangeFunc={handleInputChange}
                    onBlur={handleInputChange}
                    className="pl-[40px]"
                    label="&nbsp;"
                    labelClassName="sm:block hidden"
                    icon={
                      <div className="bg-utilityGray100 absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                        <Percent02 className="w-4 h-4" />
                      </div>
                    }
                    parentClassName="sm:w-1/2 p-1"
                  />
                </div>
                <InputText
                  inputName="finalCharge"
                  className="pl-8"
                  parentClassName="flex-1"
                  value={formData.finalCharge}
                  label="Final Price"
                  labelClassName="block mb-1.5"
                  icon={
                    <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                      <CurrencyDollar className="w-4 h-4" />
                    </div>
                  }
                />
              </div>
            </div>
            <div className="flex sm:flex-row flex-col justify-between sticky bottom-0 p-5 border-t border-borderPrimary bg-white -mx-5">
              <ButtonCmp
                type="submit"
                className="btn_primary w-full"
                loading={isLoading}
                disabled={isLoading || !isValid}
                onClick={handleSubmit}
              >
                Save Changes
              </ButtonCmp>
            </div>
          </>
        ) : (
          <></>
        )}
      </Drawer>
    </>
  );
};

export default memo(QuoteDetail);
