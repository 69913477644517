import React from 'react';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
} from 'src/utils/CommonFunctions';

const CommissionReportRow = ({ data }: any) => {
  console.log('commission report row data');

  return (
    <>
      <td className="px-5 py-4 ">
        <h6 className="h-8 text-grayLight900 font-medium flex items-center">
          {data.period}
        </h6>
      </td>

      <td className="px-5 py-4 ">
        <span
          dangerouslySetInnerHTML={{
            __html: data.periodStartDate
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.periodStartDate,
                    `MMM Do - YYYY, hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        ></span>
      </td>
      <td className="px-5 py-4">
        <span
          dangerouslySetInnerHTML={{
            __html: data.periodEndDate
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.periodEndDate,
                    `MMM Do - YYYY, hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        ></span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data.commissionedUser}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>${data.totalMargin}</span>
      </td>
      <td className="px-5 py-4">
        <span>${data.avgMargin}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data.loadsDelivered}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data.marginPercentage}%</span>
      </td>
    </>
  );
};

export default CommissionReportRow;
