import React, { useEffect, useState } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';

import CheckBox from '../../../components/CheckBox';
import Toggle from '../../../components/Toggle/Toggle';

interface IProps {
  teamId: any;
  permission: any;
  setUserPermissionList: any;
  teamPermissionsFromUserDetail: any;
  allowedPermissionAsPerRole: any;
}

const ModulePermissionCard = ({
  teamId,
  permission,
  setUserPermissionList,
  teamPermissionsFromUserDetail,
  allowedPermissionAsPerRole,
}: IProps) => {
  const [
    selectedSubModulesAndPermissions,
    setSelectedSubModulesAndPermissions,
  ] = useState<any>({});
  const [isShowSubModuleAndPermissions, setIsShowSubModuleAndPermissions] =
    useState(true);

  useEffect(() => {
    const result: any = {};
    permission.subModules.forEach((subModule: any) => {
      const subModuleName = subModule.module;
      subModule.permissions.forEach((subModulePermission: any) => {
        if (teamPermissionsFromUserDetail.includes(subModulePermission.id)) {
          if (!result[subModuleName]) {
            result[subModuleName] = [];
          }
          result[subModuleName].push(subModulePermission.id);
        }
      });
    });
    setSelectedSubModulesAndPermissions(result);
  }, [permission, teamPermissionsFromUserDetail]);

  useEffect(() => {
    const result = Object.values(selectedSubModulesAndPermissions).reduce(
      (acc: any, val) => acc.concat(val),
      []
    );
    setUserPermissionList((userPermissionList: any) => ({
      ...userPermissionList,
      [permission.module]: result,
    }));
  }, [selectedSubModulesAndPermissions]);

  const updateObject = (obj: any, keyName: any) => {
    if (obj[keyName]) {
      delete obj[keyName];
    } else {
      obj[keyName] = [];
    }

    return obj;
  };

  const updateArray = (arr: any, num: any) => {
    const index = arr.indexOf(num);

    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      arr.push(num);
    }

    return arr;
  };

  const handleSubmoduleChange = (
    subModule: string,
    permissionIds?: number[]
  ) => {
    const subModulePermissionList =
      selectedSubModulesAndPermissions?.[subModule] ?? [];

    if (permissionIds) {
      let updatedPermission = subModulePermissionList;
      permissionIds.forEach((perId) => {
        updatedPermission = updateArray(updatedPermission, perId);
      });
      const updatedPayload = {
        ...selectedSubModulesAndPermissions,
        [subModule]: updatedPermission.filter((e: any) => e),
      };
      setSelectedSubModulesAndPermissions({ ...updatedPayload });
    } else {
      const updatedPayload = updateObject(
        { ...selectedSubModulesAndPermissions },
        subModule
      );
      setSelectedSubModulesAndPermissions({ ...updatedPayload });
    }
  };

  return (
    <div className="[&:not(:first-child)]:pt-5">
      <AccordionCmp
        key={permission.module}
        isOpen={isShowSubModuleAndPermissions && !teamId}
        title={
          <div className="xxl:gap-1 gap-1 flex flex-col w-[calc(100%_-_100px)]">
            <div className="text-textSecondary text-base font-semibold">
              {permission.module}
            </div>
            <div className="text-grayLight600 text-xs font-normal">
              Add, Edit and Delete changes
            </div>
          </div>
        }
        className="shadow-sm"
        contentClassName="!p-0"
        handleClick={() => {
          setIsShowSubModuleAndPermissions(true);
        }}
      >
        <>
          {isShowSubModuleAndPermissions && (
            <div className="w-full bg-white rounded-xl">
              <div className="w-full h-full flex-col justify-start items-start flex">
                {permission.subModules.map((subModule: any) => (
                  <div
                    key={subModule.module}
                    className="w-full flex md:flex-row flex-col [&:not(:first-child)]:mt-5 items-start justify-start gap-4"
                  >
                    <div className="md:w-[30%] flex items-center justify-start gap-2">
                      <Toggle
                        id={subModule.module}
                        isChecked={
                          !!(subModule.permissions?.length > 1
                            ? selectedSubModulesAndPermissions?.[
                                subModule.module
                              ]?.length
                            : selectedSubModulesAndPermissions?.[
                                subModule.module
                              ])
                        }
                        onChange={(e: any) => {
                          if (subModule.permissions?.length > 1) {
                            if (e.target.checked) {
                              const subPermIds: number[] = [];
                              subModule.permissions.forEach((subPerm: any) => {
                                if (
                                  allowedPermissionAsPerRole.includes(
                                    subPerm?.id
                                  )
                                ) {
                                  subPermIds.push(subPerm?.id);
                                }
                              });
                              handleSubmoduleChange(subModule.module, [
                                ...subPermIds,
                              ]);
                            } else {
                              handleSubmoduleChange(subModule.module);
                            }
                          } else {
                            if (e.target.checked) {
                              handleSubmoduleChange(subModule.module, [
                                subModule.permissions?.[0].id,
                              ]);
                            } else {
                              handleSubmoduleChange(subModule.module);
                            }
                          }
                        }}
                      />
                      <div className="text-textSecondary text-xs font-medium">
                        {subModule.module}
                      </div>
                    </div>
                    {subModule.permissions?.length > 1 && (
                      <div className="md:w-[70%] sm:items-center mdm:gap-5 md:gap-2 sm:gap-3 gap-2 flex sm:flex-row flex-col flex-1">
                        {subModule.permissions.map((subModPermission: any) => (
                          <div
                            key={subModPermission.id}
                            className="flex items-center sm:justify-center sm:mr-3"
                          >
                            <CheckBox
                              id={subModPermission.id}
                              onChangeFunc={(e) => {
                                e?.stopPropagation();
                                handleSubmoduleChange(subModule.module, [
                                  subModPermission.id,
                                ]);
                              }}
                              checked={
                                selectedSubModulesAndPermissions?.[
                                  subModule.module
                                ]?.includes(subModPermission.id) ?? false
                              }
                              disabled={
                                !allowedPermissionAsPerRole.includes(
                                  subModPermission.id
                                )
                              }
                            />
                            <label
                              htmlFor={subModPermission.id}
                              className="ml-3 items-start text-textSecondary text-xs"
                            >
                              {subModPermission.displayName}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      </AccordionCmp>
    </div>
  );
};

export default ModulePermissionCard;
