import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
// import CustomPagination from 'src/components/CustomPagination';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { PATH } from 'src/constants/path';
import { allMonthCommissionReport } from 'src/services/CommissionReportService';

import CommissionReportLoadingRow from './CommissionReportLoadingRow';
import CommissionReportRow from './CommissionReportRow';
// import TableCmp from 'src/components/TableCmp';

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 100,
  year: 'all',
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const yearArr = [
  { label: '2024', value: '2024' },
  { label: '2023', value: '2023' },
  { label: '2022', value: '2022' },
];

const AllMonthCommissionReport = () => {
  const navigate = useNavigate();

  const [params, setParams] = useState(initParams);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [periodData, setPeriodData] = useState<any[]>([]);
  const [filteredPeriodData, setFilteredPeriodData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  const headCells = useMemo(
    () => [
      {
        id: 'period',
        name: 'Period',
        sortable: true,
        visible: true,
      },

      {
        id: 'periodStartDate',
        name: 'Period Start Date',
        sortable: true,
        visible: true,
      },
      {
        id: 'periodEndDate',
        name: 'Period End Date',
        sortable: true,
        visible: true,
      },
      {
        id: 'commissionedUser',
        name: 'Commission User',
        sortable: true,
        visible: true,
      },
      {
        id: 'totalMargin',
        name: 'Total Margin',
        sortable: true,
        visible: true,
      },
      {
        id: 'avgMargin',
        name: 'Avg. Margin Per Load',
        sortable: true,
        visible: true,
      },
      {
        id: 'loadsDelivered',
        name: 'No. of Loads delivered',
        sortable: true,
        visible: true,
      },
      {
        id: 'marginPercentage',
        name: 'Margin Percentage',
        sortable: true,
        visible: true,
      },
    ],
    []
  );

  useEffect(() => {
    setIsLoading(true);
    setPeriodData([]);

    allMonthCommissionReport(params).then((response: any) => {
      setPeriodData(response.data ?? []);
      setTotal(response.total ?? 0);
      setFilteredPeriodData(response.data ?? []);
      setIsLoading(false);
    });
  }, [params]);

  useEffect(() => {
    const filtered = periodData.filter((item) =>
      item?.period?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredPeriodData(filtered);
    setTotal(filtered.length);
  }, [searchValue, periodData]);

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchValue(value);
    setParams((old) => ({ ...old, page: 1 }));
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Commission Reports"
            desc="View and manage your commission reports here."
          />
        }
      >
        <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className={`table-title-wrap`}>
                <h5 className="table-title">
                  {/* {params.type === 'common' ? 'Common LTL' : 'Private'} */}
                  Commission Reports
                </h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  // mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {total} {total > 1 ? 'Reports' : 'Report'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Monthly summary of commission reports.
              </p>
            </div>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <InputText
                inputName="searchOrder"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={searchValue}
                inputType="text"
                isClearable={true}
                parentClassName="table-searchInput"
                onChangeFunc={handleSearch}
              />
            </div>
            <div className="table-selectbox">
              <SelectBox
                name="teamListGroup"
                id="teamListGroup"
                className="form_control"
                size="sm"
                placeholder="Select Year"
                noOptionMessage="No Year Found"
                isClearable={true}
                isSearchable={true}
                options={yearArr}
                onChangeFunc={(event: any) =>
                  setParams((old: any) => ({
                    ...old,
                    year: event?.value ?? 'all',
                  }))
                }
                value={yearArr.filter((val: any) => params.year === val.value)}
              />
            </div>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                placeholder="10"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>
          <div
            data-test-id="userTest"
            className="h-full lg:min-h-[122px] w-full border-t border-gray100 "
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                tableDataArr={filteredPeriodData}
                TableLoaderRowCmp={CommissionReportLoadingRow}
                TableRowCmp={CommissionReportRow}
                params={params}
                setParams={setParams}
                isTableDataLoading={false}
                // numberOfSkeletonRows={params.limit}
                isTableRowClickable={true}
                onRowClick={(rowData: any) => {
                  navigate(`${PATH.COMMISSION_REPORT}/${rowData?.id}`);
                }}
              />
            </div>
            {!periodData.length && !isLoading && (
              <NotFoundUI
                title="No User Found"
                desc="There are no data for user."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={filteredPeriodData.length}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </PageSectionLayout>
    </>
  );
};

export default AllMonthCommissionReport;
