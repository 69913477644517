import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const CustomerSplitDetailRow = ({ data }: any) => (
  <>
    <td className="flex flex-col px-5 py-4 first:w-80 first:min-w-80 first:max-w-80 text-pretty">
      <div className=" flex items-center gap-3">
        {data.image ? (
          <img
            src={`${data.imageUrl}${data.image}`}
            className="w-8 h-8 rounded-md text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
            alt={data.name}
            title={data.name}
            onError={onError}
          />
        ) : (
          <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
            {getShortName(`${data.name}`)}
          </div>
        )}
        {data.name.length > 32 ? (
          <TooltipCmp message={data.name} parentClassName="xs:w-80 w-64">
            <span className="truncate block max-w-64 text-sm whitespace-nowrap font-semibold ">
              {data.name}
            </span>
          </TooltipCmp>
        ) : (
          <span className="truncate block max-w-64  text-sm whitespace-nowrap font-semibold ">
            {data.name}
          </span>
        )}
      </div>
    </td>

    <td className="px-5 py-4 w-64 min-w-64 max-w-64">
      <div className="flex items-center min-w-92">
        {data.salesRepImage && data.salesRepImageUrl ? (
          <img
            className="w-5 h-5 rounded-full border-[0.75px] border-black/[0.08]"
            src={`${data.salesRepImageUrl}${data.salesRepImage}`}
            alt={data.salesRepFullName}
            title={data.salesRepFullName}
            onError={onError}
          />
        ) : data.salesRepFullName ? (
          <div className="w-5 h-5 bg-primary rounded-full font-medium text-white flex items-center justify-center uppercase text-[8px] p-1 flex-none">
            {getShortName(`${data.salesRepFullName}`)}
          </div>
        ) : (
          ''
        )}
        <div className="pl-2">
          <h6 className="text-grayLight900 font-medium leading-tight max-w-52 truncate">
            {data.salesRepFullName}
          </h6>
        </div>
      </div>
    </td>
    {/* <td className="px-5 py-4">{moment(data.createdAt).format("DD/MM/YYYY")} </td> */}
    <td className="px-5 py-4">${getFormattedNumber(data.sales)} </td>
    <td className="px-5 py-4">${getFormattedNumber(data.cost)} </td>
    <td className="px-5 py-4">{data.orderCount} </td>
    <td className="px-5 py-4">
      {data.profit >= 0
        ? `$${getFormattedNumber(data.profit, true, true)}`
        : getFormattedNumber(data.profit, true, true)}
    </td>
    <td className="px-5 py-4">
      {data.profitPercentage > 0
        ? `${parseFloat(data?.profitPercentage).toFixed(2)}%`
        : `${0}%`}
    </td>
  </>
);

export default CustomerSplitDetailRow;
