import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getChatMessageList = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CLAIMS_AND_DISPUTE.CHAT_MESSAGE.LIST}`,
    data
  );

  return response;
};

export const sendMessage = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CLAIMS_AND_DISPUTE.CHAT_MESSAGE.SEND_MESSAGE}`,
    data
  );

  return response;
};

// internal notes

export const getInternalNotes = async (claimId: string, params: any) => {
  const response = await axiosInterceptor.get(
    `${API.CLAIMS_AND_DISPUTE.INTERNAL_NOTES.LIST}/${claimId}`,
    {
      params,
    }
  );

  return response;
};

export const createInternalNotes = async (claimId: string, data: any) => {
  const response = await axiosInterceptor.post(
    `${API.CLAIMS_AND_DISPUTE.INTERNAL_NOTES.CREATE}/${claimId}`,
    data
  );

  return response;
};

// action item flow
export const getClaimDisputeTaskList = async (params: any) => {
  const response = await axiosInterceptor.get(
    `${API.CLAIMS_AND_DISPUTE.CLAIMS_DISPUTE_TASK.LIST}`,
    {
      params,
    }
  );

  return response;
};

export const createClaimDisputeTask = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CLAIMS_AND_DISPUTE.CLAIMS_DISPUTE_TASK.ADD_TASK,
    data
  );

  return response;
};

export const updateClaimDisputeTask = async (actionId: any, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.CLAIMS_AND_DISPUTE.CLAIMS_DISPUTE_TASK.UPDATE_TASK}/${actionId}`,
    data
  );

  return response;
};

export const deleteClaimDisputeTask = async (deleteId: any) => {
  const response = await axiosInterceptor.delete(
    `${API.CLAIMS_AND_DISPUTE.CLAIMS_DISPUTE_TASK.DELETE_TASK}/${deleteId}`
  );

  return response;
};
