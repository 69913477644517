import { Plus, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import {
  CustomerGroupList,
  removeCustomerGroup,
} from 'src/services/CustomerService';
import WalToast from 'src/utils/WalToast';

import CreateCustomerGroupModal from './CreateCustomerGroupModal';
import CustomerGroupLoaderRow from './CustomerGroupLoaderRow';
import CustomerGroupRow from './CustomerGroupRow';

const initParams: any = {
  search: '',
  sortType: 'desc',
  sortField: 'groupName',
  page: 1,
  limit: 50,
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const CustomerGroup = () => {
  const [total, setTotal] = useState(0);
  const [customerGroups, setCustomerGroups] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [params, setParams] = useState(initParams);
  const [isShowCreateCustomerGroupModal, setIsShowCreateCustomerGroupModal] =
    useState(false);
  const [isRefresh, setIsRefresh] = useState(true);

  const [deleteConfirmationModalData, setDeleteConfirmationModalData] =
    useState<any | undefined>(undefined);
  const [isCustomerGroupDeleteLoading, setIsCustomerGroupDeleteLoading] =
    useState(false);

  const [updateCustomerGroupModalData, setUpdateCustomerGroupModalData] =
    useState<any | undefined>(undefined);

  useEffect(() => {
    setIsRefresh(true);
  }, [params]);

  useEffect(() => {
    if (!isRefresh) {
      return;
    }
    const controller = new AbortController();
    const signal = controller.signal;

    setCustomerGroups([]);
    setIsLoading(true);
    CustomerGroupList(params, signal)
      .then((response: any) => {
        setCustomerGroups(response?.data ?? []);
        setTotal(response?.total ?? 0);
        setIsLoading(false);
        setIsRefresh(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [params, isRefresh]);

  const headCellsGroups = useMemo(
    () => [
      {
        id: 'groupName',
        name: 'Group Name',
        sortable: true,
      },
      {
        id: 'customersCount',
        name: 'Customers',
        sortable: true,
      },
      {
        id: 'action',
        visible: true,
      },
    ],
    []
  );

  const handleDeleteConfirmationAction = (status: boolean) => {
    if (status) {
      setIsCustomerGroupDeleteLoading(true);
      removeCustomerGroup(deleteConfirmationModalData?.id)
        .then((response: any) => {
          WalToast.success(response?.message);
          setDeleteConfirmationModalData(undefined);
          setIsRefresh(true);
        })
        .finally(() => {
          setIsCustomerGroupDeleteLoading(false);
        })
        .catch(console.log);
    } else {
      setDeleteConfirmationModalData(undefined);
    }
  };

  return (
    <>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
        <div className="table-top-header ">
          <div className="table-left-wrap sm:w-auto w-full">
            <div className="table-title-wrap">
              <h5 className="table-title">Customer Groups</h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={isLoading ? 'custom-loading' : ''}
              >
                {total} {total <= 1 ? 'Group' : 'Groups'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              Create, view and manage your customer quotes.
            </p>
          </div>

          <ButtonCmp
            className="btn-outline-primary sm:flex-none flex-1 xs:min-w-[auto] min-w-full"
            onClick={() => setIsShowCreateCustomerGroupModal(true)}
            icon={<Plus className="w-4 h-4" />}
          >
            Create new group
          </ButtonCmp>
        </div>
        <div className="table-bottom-header">
          <div className="table-header-bottom-left">
            <InputText
              inputName="searchCarrier"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={params.search}
              inputType="text"
              isClearable={true}
              onChangeFunc={(e) => {
                setParams((old: any) => ({
                  ...old,
                  ...{ search: e.target.value, page: 1 },
                }));
              }}
              parentClassName="table-searchInput"
            />
          </div>

          <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArray}
              onChangeFunc={(event: any) => {
                setParams((old: any) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={recordsPerPageArray.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div>
        </div>
        <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
            <TableCmp
              headCells={headCellsGroups}
              params={params}
              setParams={setParams}
              tableDataArr={customerGroups}
              TableLoaderRowCmp={CustomerGroupLoaderRow}
              TableRowCmp={CustomerGroupRow}
              tableRowCmpProps={{
                handleGroupEdit: (actionData: any) =>
                  setUpdateCustomerGroupModalData(actionData),
                handleGroupDelete: (actionData: any) =>
                  setDeleteConfirmationModalData(actionData),
              }}
              isTableDataLoading={isLoading}
              numberOfSkeletonRows={15}
            />
          </div>
          {!isLoading && !customerGroups.length && (
            <NotFoundUI
              title="No Customer Group Found"
              desc="There are no data for customer groups."
              containerClassName="min-h-[unset]"
            />
          )}
        </div>

        <div className="w-full bg-white rounded-b-xl">
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={total}
            currentPage={params.page}
            handlePagination={(page: number) => {
              setParams((old: any) => ({ ...old, page }));
            }}
          />
        </div>
      </div>

      {isShowCreateCustomerGroupModal && (
        <CreateCustomerGroupModal
          handleClose={(status: boolean) => {
            if (status) {
              setIsRefresh(true);
            }
            setIsShowCreateCustomerGroupModal(false);
          }}
        />
      )}

      {deleteConfirmationModalData && (
        <CommonModal
          title={`Remove ${deleteConfirmationModalData?.groupName} Group`}
          titleDesc={`This action will remove ${deleteConfirmationModalData?.groupName} permanently.`}
          handleClose={() =>
            isCustomerGroupDeleteLoading
              ? false
              : handleDeleteConfirmationAction(false)
          }
          secondaryBtnOnClick={() => handleDeleteConfirmationAction(false)}
          dangerBtnText="Remove"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleDeleteConfirmationAction(true)}
          primaryBtnLoading={isCustomerGroupDeleteLoading}
          primaryBtnDisabled={isCustomerGroupDeleteLoading}
          secondaryBtnDisabled={isCustomerGroupDeleteLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}

      {updateCustomerGroupModalData && (
        <CreateCustomerGroupModal
          isUpdateCustomerGroup={true}
          currentGroupDetail={updateCustomerGroupModalData}
          handleClose={(status: boolean) => {
            if (status) {
              setIsRefresh(true);
            }
            setUpdateCustomerGroupModalData(undefined);
          }}
        />
      )}
    </>
  );
};

export default CustomerGroup;
