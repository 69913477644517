import React from 'react';

import PageSectionLayout from '../../../components/PageSectionLayout';
import Header from '../../../components/PageSectionLayout/Header/Header';

const TrackAndTrace = () => (
  <PageSectionLayout
    header={
      <Header
        title="Track and Trace"
        desc="Manage your team members and their account permissions here."
        rightSideContent={<div>right side content</div>}
      />
    }
  >
    <div>Track and Trace</div>
  </PageSectionLayout>
);

export default TrackAndTrace;
