import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { onError } from 'src/utils/CommonFunctions';

import TooltipCmp from '../TooltipCmp';

const TabButton = ({
  tabArray,
  isTooltip,
  isActive,
  handleOnClick,
  className,
  activeClassName,
  parentClassName,
  tabTextClass,
  tabParentClassName,
  isTab,
  isScrollable = false,
}: any) => (
  <div className={`flex items-center ${tabParentClassName}`}>
    {isScrollable && (
      <ArrowLeft className="text-gray400 w-4 h-4 flex-none mb-0.5 mr-2 cursor-pointer" />
    )}

    <div
      className={`flex shadow-sm rounded-lg w-fit  ${parentClassName} ${
        isTab
          ? 'p-1 rounded-[10px] border border-utilityGray200 cursor-pointer justify-start items-center gap-1 flex sm:w-auto w-full'
          : ''
      }`}
    >
      {tabArray.map((tab: any, index: number) =>
        isTooltip ? (
          <TooltipCmp
            key={index}
            message={tab.tooltipValue}
            childrenClassName={`${className} ${
              isTab
                ? 'sm:basis-auto basis-full px-3 py-1.5 justify-center items-center gap-2 flex  '
                : '  border border-borderPrimary border-r-0 '
            } cursor-pointer text-xs font-semibold last:rounded-r-lg last:border-r first:rounded-l-lg !block text-center ${
              isActive === tab.value
                ? activeClassName ?? 'border-borderPrimary bg-gray50'
                : isTab
                ? 'text-textSecondary '
                : 'bg-white text-textSecondary '
            }`}
          >
            <div
              data-value={tab.value}
              onClick={handleOnClick}
              data-test-id={tab.name}
              className="xl:px-4 lg:px-4 sm:px-3 px-2 lg:h-[36px] h-[34px] flex items-center justify-center"
            >
              {tab.image && (
                <img
                  data-value={tab.value}
                  className="rounded border border-utilityGray200 w-5 h-5 flex-none"
                  onError={onError}
                  src={tab.image}
                />
              )}

              <span data-value={tab.value} className={`${tabTextClass} `}>
                {tab.name}
              </span>
            </div>
          </TooltipCmp>
        ) : (
          <div
            key={index}
            className={`${className} ${
              isTab
                ? 'sm:basis-auto basis-full px-3 py-1.5 justify-center items-center gap-2 flex'
                : 'lg:py-[9px] py-2 xl:px-4 lg:px-4 sm:px-3 px-2  border border-borderPrimary border-r-0 '
            } cursor-pointer text-xs font-semibold last:rounded-r-lg last:border-r first:rounded-l-lg  text-center ${
              isActive === tab.value
                ? activeClassName ?? 'border-borderPrimary bg-gray50'
                : isTab
                ? 'text-textSecondary '
                : 'bg-white text-textSecondary '
            }`}
            data-value={tab.value}
            onClick={handleOnClick}
            data-test-id={tab.name}
          >
            {tab.image && (
              <img
                data-value={tab.value}
                className="rounded border border-utilityGray200 w-5 h-5 flex-none"
                onError={onError}
                src={tab.image}
              />
            )}

            <span data-value={tab.value} className={`${tabTextClass}`}>
              {tab.name}
            </span>
          </div>
        )
      )}
    </div>
    {isScrollable && (
      <ArrowRight className="text-gray400 w-4 h-4 flex-none mb-0.5 ml-2 cursor-pointer" />
    )}
  </div>
);

export default TabButton;
