import { AlertTriangle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import { PATH } from 'src/constants/path';

interface IProps {
  handleClose: any;
  claimDisputeData: any;
}

const OrderNoSalesRepWarning = ({ handleClose, claimDisputeData }: IProps) => {
  const navigate = useNavigate();
  const isClaim = claimDisputeData?.claimAmount;

  return (
    <CommonModal
      title={`Order Ineligible for opening ${
        isClaim ? 'Claim' : 'Dispute'
      }: No Sales Rep Assigned `}
      handleClose={handleClose}
      size={'max-w-[688px]'}
      headerIcon={<AlertTriangle className="w-7 h-7" />}
      headerIconClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-left-[69px] before:opacity-50"
      primaryBtnText="Visit Customer"
      primaryBtnOnClick={() =>
        navigate(`${PATH.CUSTOMER_DETAILS}/${claimDisputeData?.customerId}`)
      }
      secondaryBtnText="Close"
      secondaryBtnOnClick={() => handleClose()}
    >
      <div className="p-5">
        <p>
          {`${
            isClaim ? 'Claimed' : 'Disputed'
          } order has no Sales rep assigned so can not open ${
            isClaim ? 'claim' : 'dispute'
          }, please update allocation history of order's customer to first.`}
        </p>
      </div>
    </CommonModal>
  );
};

export default OrderNoSalesRepWarning;
