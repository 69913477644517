import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listCarrierContact = async (id: number) => {
  const response = await axiosInterceptor.get(
    `${API.CARRIER_CONTACT.LIST}/${id}`
  );

  return response;
};

export const createCarrierContact = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CARRIER_CONTACT.CREATE,
    data
  );

  return response;
};

export const updateCarrierContact = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.CARRIER_CONTACT.UPDATE}/${id}`,
    data
  );

  return response;
};

export const deleteCarrierContact = async (id: number) => {
  const response = await axiosInterceptor.delete(
    `${API.CARRIER_CONTACT.DELETE}/${id}`
  );

  return response;
};
