import { DownloadCloud02, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { PERMISSION } from 'src/constants/common';
import {
  TimeConverter,
  convertToGBMBKB,
  onError,
  useRolePermission,
} from 'src/utils/CommonFunctions';

const FileListRow = ({
  data,
  showWarning,
  onDownloadFile,
  setSelectedRecord,
}: any) => {
  const { hasPermissionV2 } = useRolePermission();

  return (
    <>
      <td className="px-5 py-4">
        <h6 className="text-grayLight900 font-semibold ">
          {data.fileNameMaster}
        </h6>
        <p className="">Size: {convertToGBMBKB(data.uploadedFileSize)}</p>
      </td>
      <td className="px-5 py-4 ">
        {data.fileFormat !== 'master' ? (
          <>
            <h6 className="text-grayLight900 font-semibold ">
              {data.fileNameUploaded}
            </h6>
            <p className="">Size: {convertToGBMBKB(data.uploadedFileSize)}</p>
          </>
        ) : (
          '-'
        )}
      </td>
      <td className="px-5 py-4">{`${moment(data.startDate).format(
        'MMMM DD, YYYY'
      )} - ${moment(data.endDate).format('MMMM DD, YYYY')}`}</td>
      <td className="px-5 py-4">
        {moment(data.uploadedDate).format('DD/MM/YYYY')}
      </td>
      <td className="px-5 py-4">
        <div className="flex gap-3 items-center">
          <img
            className="w-7 h-7 border-[0.75px] border-black/[0.08] rounded-full object-cover"
            src={data.imageUrl + data.image}
            alt={data.firstName}
            title={data.firstName}
            onError={onError}
          />
          <div className="w-[calc(100%_-_32px)]">
            <h6 className="text-grayLight900 font-semibold truncate">
              {data.firstName && data.firstName}{' '}
              {data.lastName && data.lastName}
            </h6>
            <p className="">{TimeConverter(data.uploadedDate)}</p>
          </div>
        </div>
      </td>

      <td className="px-5 py-4">
        {data.missingData ? (
          <div
            className="inline-flex cursor-pointer"
            onClick={() => showWarning(data)}
          >
            <BadgeCmp style="pill" type="error" mainClassName="">
              {data.missingData}
            </BadgeCmp>
          </div>
        ) : (
          '-'
        )}
      </td>
      <td className="px-5 py-4">
        <div className=" gap-4 flex justify-center">
          {hasPermissionV2(PERMISSION.FILE_DOWNLOAD) && (
            <TooltipCmp message={'Download File'}>
              <DownloadCloud02
                className="w-4 h-4 relative cursor-pointer"
                onClick={() => {
                  onDownloadFile(data.fileName, data.fileNameUploaded);
                }}
              />
            </TooltipCmp>
          )}
          <TooltipCmp message={'Delete Data'}>
            <Trash01
              className="w-4 h-4 relative cursor-pointer"
              onClick={() => {
                // onDeleteData(data.id);
                setSelectedRecord(data);
              }}
            />
          </TooltipCmp>
        </div>
      </td>
    </>
  );
};

export default FileListRow;
