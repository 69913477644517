import React, { useRef, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as THREE from 'three';
// eslint-disable-next-line import/no-extraneous-dependencies
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

interface IProps {
  height: number;
  width: number;
  depth: number;
}

const ThreeJsScene = ({ height = 0, width = 0, depth = 0 }: IProps) => {
  const canvasRef = useRef<any>();
  const [isSpinning, setIsSpinning] = useState(false);
  const controls = useRef<any>(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(100, 454 / 454, 0.8, 1000);
    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });

    renderer.setSize(454, 454);
    renderer.setClearColor(0xffffff);
    camera.position.z = 5;

    const geometry = new THREE.BoxGeometry(width, height, depth);
    const material = new THREE.MeshBasicMaterial({
      // map: texture,
      color: 0x444ce7,
      opacity: 0.65,
      transparent: true,
    });
    const cube = new THREE.Mesh(geometry, material);
    scene.add(cube);

    const edges = new THREE.EdgesGeometry(geometry);

    const lineMaterial = new THREE.LineBasicMaterial({
      color: 0x000000,
      linewidth: 1,
    });
    const wireframe = new THREE.LineSegments(edges, lineMaterial);
    wireframe.computeLineDistances();
    cube.add(wireframe);

    controls.current = new OrbitControls(camera, renderer.domElement);
    controls.current.enableDamping = true;

    const animate = () => {
      if (isSpinning) {
        cube.rotation.x += 0.01;
        cube.rotation.y += 0.01;
      }

      controls.current.update();
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      geometry.dispose();
      material.dispose();
      renderer.dispose();
    };
  }, [isSpinning, height, width, depth]);

  const toggleSpin = () => {
    setIsSpinning(!isSpinning);
  };

  return (
    <div className="h-full w-full flex flex-col gap-2 justify-center items-center">
      <canvas ref={canvasRef} />
      <button className="hidden" onClick={toggleSpin}>
        {isSpinning ? 'Stop Spin' : 'Start Spin'}
      </button>
    </div>
  );
};

export default ThreeJsScene;
