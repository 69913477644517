export const FilterArray: any = [
  {
    value: 'all',
    name: 'View all',
  },
  {
    value: 'new',
    name: 'New',
  },
  {
    value: 'opened',
    name: 'Opened',
  },
  {
    value: 'resolved',
    name: 'Resolved',
  },
  {
    value: 'closed',
    name: 'Closed',
  },
];
