import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react/build/cjs';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useRolePermission } from 'src/utils/CommonFunctions';

import { yearFilterArr } from './OverView.interface';

const GrossProfitFilter = ({
  activeFilter,
  setActiveFilter,
  dateRange,
  setDateRange,
}: any) => {
  const { hasRoleV2 } = useRolePermission();
  // const [dateRange, setDateRange] = useState<any>("2020-2029");
  // const [weekQuarterArr, setWeekQuarterArr] = useState<any>("2020-2029");
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [isNextYearDisable, setIsNextYearDisable] = useState<any>(false);

  const generateQuarterSelections = () => {
    const startYear = 2000;
    const currentYear = new Date().getFullYear(); // This will dynamically get the current year
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    const selections = [];

    for (let year = startYear; year <= currentYear; year++) {
      const maxQuarter = year === currentYear ? currentQuarter : 4;

      for (let quarter = 1; quarter <= maxQuarter; quarter++) {
        selections.push(`Q${quarter}-${year}`);
      }
    }

    return selections;
  };
  const quarterArr = generateQuarterSelections();

  const getFilterRange = (type: string) => {
    if (activeFilter === 'yearly') {
      if (type === 'prev' && !isPrevYearDisable) {
        const valKey: any = _.findKey(
          yearFilterArr,
          (val) => val === dateRange
        );
        setDateRange(yearFilterArr[valKey - 1]);
        if (!yearFilterArr[valKey - 2]) setIsPrevYearDisable(true);
        setIsNextYearDisable(false);
      }

      if (type === 'next' && !isNextYearDisable) {
        const valKey: any = _.findKey(
          yearFilterArr,
          (val) => val === dateRange
        );
        const valKeyNum = +valKey;
        setDateRange(yearFilterArr[valKeyNum + 1]);
        if (!yearFilterArr[valKeyNum + 2]) setIsNextYearDisable(true);
        setIsPrevYearDisable(false);
      }
    }

    if (activeFilter === 'weekly') {
      if (type === 'prev' && !isPrevYearDisable) {
        const valKey: any = _.findKey(quarterArr, (val) => val === dateRange);
        const newKey = parseInt(valKey, 10) - 1;
        const newDateRange = quarterArr[newKey];

        if (newDateRange) {
          setDateRange(newDateRange);

          if (hasRoleV2('user')) {
            const [q, year] = newDateRange.split('-');
            const quarterNumber = parseInt(q.replace('Q', ''), 10);
            const yearNumber = parseInt(year, 10);
            const currentYear = new Date().getFullYear();
            const boundaryYear = currentYear - 1;
            const boundaryQuarter = 1;

            if (
              yearNumber < boundaryYear ||
              (yearNumber === boundaryYear && quarterNumber <= boundaryQuarter)
            ) {
              setIsPrevYearDisable(true);
            }
          } else {
            setIsPrevYearDisable(!quarterArr[newKey - 1]);
          }
        } else {
          console.error('New date range not found.');
        }
        setIsNextYearDisable(false);
      }

      if (type === 'next' && !isNextYearDisable) {
        setIsPrevYearDisable(false);
        const valKey: any = _.findKey(quarterArr, (val) => val === dateRange);
        const valKeyNum = +valKey;
        setDateRange(quarterArr[valKeyNum + 1]);
        if (!quarterArr[valKeyNum + 2]) setIsNextYearDisable(true);
        setIsPrevYearDisable(false);
      }
    }

    if (activeFilter === 'monthly') {
      const currentYear = moment().year();

      if (type === 'prev' && !isPrevYearDisable) {
        const prevMonth = moment(moment(dateRange, 'YYYY'))
          .subtract(1, 'year')
          .format('YYYY');
        setDateRange(prevMonth);

        if (hasRoleV2('user') && currentYear - parseInt(prevMonth) >= 1) {
          setIsPrevYearDisable(true);
        } else {
          setIsNextYearDisable(false);
        }
      }

      if (type === 'next' && !isNextYearDisable) {
        setIsPrevYearDisable(false);
        const nextMonth = moment(moment(dateRange, 'YYYY'))
          .add(1, 'year')
          .format('YYYY');
        setDateRange(nextMonth);

        if (nextMonth === moment().format('YYYY')) {
          setIsNextYearDisable(true);
        }
      }
    }

    if (activeFilter === 'daily') {
      const twoYearsAgo = moment().subtract(1, 'years').startOf('year');

      if (type === 'prev' && !isPrevYearDisable) {
        const prevMonth = moment(dateRange, 'MMMM-YYYY').subtract(1, 'months');
        setDateRange(prevMonth.format('MMMM-YYYY'));

        if (hasRoleV2('user')) {
          if (prevMonth.isSameOrBefore(twoYearsAgo, 'month')) {
            setIsPrevYearDisable(true);
          }
        }
        setIsNextYearDisable(false);
      }

      if (type === 'next' && !isNextYearDisable) {
        setIsPrevYearDisable(false);
        const nextMonth = moment(dateRange, 'MMMM-YYYY')
          .add(1, 'months')
          .format('MMMM-YYYY');
        setDateRange(nextMonth);
        if (nextMonth === moment().format('MMMM-YYYY'))
          setIsNextYearDisable(true);
      }
    }
  };

  useEffect(() => {
    setIsPrevYearDisable(false);
    setIsPrevYearDisable(false);

    if (activeFilter === 'yearly') {
      setDateRange('2020-2029');
      const valKey: any = _.findKey(
        yearFilterArr,
        (val) => val === '2020-2029'
      );
      if (!yearFilterArr[valKey - 2]) setIsPrevYearDisable(true);
      if (!yearFilterArr[valKey + 2]) setIsNextYearDisable(true);
    }

    if (activeFilter === 'weekly') {
      setDateRange(quarterArr[quarterArr.length - 1]);
      const valKey: any = _.findKey(
        quarterArr,
        (val) => val === quarterArr[quarterArr.length - 1]
      );
      if (!quarterArr[valKey - 1]) setIsPrevYearDisable(true);
      if (!quarterArr[valKey + 1]) setIsNextYearDisable(true);
    }

    if (activeFilter === 'daily') {
      setDateRange(moment().format('MMMM-YYYY'));
      const nextMonth = moment(dateRange).add(1, 'months').format('MMMM-YYYY');
      if (nextMonth === moment().format('MMMM-YYYY'))
        setIsNextYearDisable(true);
    }

    if (activeFilter === 'monthly') {
      setDateRange(moment().format('YYYY'));
      const nextMonth = moment(dateRange).add(1, 'year').format('YYYY');
      if (nextMonth === moment().format('YYYY')) setIsNextYearDisable(true);
    }
  }, [activeFilter]);

  return (
    <div>
      <div className="flex sm:flex-row flex-col justify-between gap-2">
        <div className="min-w-[18%] px-3 py-2.5 bg-white rounded-lg shadow border border-gray-300 items-center gap-1 justify-between flex">
          <ArrowLeft
            className={`w-4 h-4  ${
              isPrevYearDisable
                ? 'text-grayText cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            onClick={() => getFilterRange('prev')}
          />
          <div className="px-0.5 justify-center items-center flex">
            <div className="text-center text-textSecondary text-xs font-medium leading-tight">
              {dateRange}
            </div>
          </div>
          <ArrowRight
            className={`w-4 h-4 ${
              isNextYearDisable
                ? 'text-grayText cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            onClick={() => getFilterRange('next')}
          />
        </div>
        <div className="flex">
          <div className="p-1 bg-gray-50 rounded-[10px] border border-utilityGray200 cursor-pointer justify-start items-center gap-1 flex sm:w-auto w-full">
            <div
              className={`sm:basis-auto basis-full px-3 py-1.5 justify-center items-center gap-2 flex ${
                activeFilter === 'daily'
                  ? 'bg-white rounded-md shadow text-grayLight900'
                  : 'text-textSecondary'
              }`}
              onClick={() => setActiveFilter('daily')}
            >
              <div className=" text-xs font-semibold leading-tight">Day</div>
            </div>
            <div
              className={`sm:basis-auto basis-full px-3 py-1.5 justify-center items-center gap-2 flex ${
                activeFilter === 'weekly'
                  ? 'bg-white rounded-md shadow text-grayLight900'
                  : 'text-textSecondary'
              }`}
              onClick={() => setActiveFilter('weekly')}
            >
              <div className=" text-xs font-semibold leading-tight">Week</div>
            </div>
            <div
              className={`sm:basis-auto basis-full px-3 py-1.5 rounded-md justify-center items-center gap-2 flex ${
                activeFilter === 'monthly'
                  ? 'bg-white rounded-md shadow text-grayLight900'
                  : 'text-textSecondary'
              }`}
              onClick={() => setActiveFilter('monthly')}
            >
              <div className="text-xs font-semibold leading-tight">Month </div>
            </div>
            {!hasRoleV2('user') ? (
              <div
                className={`sm:basis-auto basis-full px-3 py-1.5 rounded-md justify-center items-center gap-2 flex ${
                  activeFilter === 'yearly'
                    ? 'bg-white rounded-md shadow text-grayLight900'
                    : 'text-textSecondary'
                }`}
                onClick={() => setActiveFilter('yearly')}
              >
                <div className="text-xs font-semibold leading-tight">Year</div>
              </div>
            ) : (
              ''
            )}
          </div>

          {/* <div className="ml-2 cursor-pointer flex justify-center items-center">
                                <XClose className="w-4 h-4 text-gray400" onClick={handleClose} />
                            </div> */}
        </div>
      </div>
    </div>
  );
};

export default GrossProfitFilter;
