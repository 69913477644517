import React, { useEffect, useState } from 'react';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

interface IServiceProps {
  carrierServices: any;
  carrierId?: number;
  carrierName?: string;
}

const ServiceDetail = ({ carrierServices }: IServiceProps) => {
  const [quotedSer, setQuotedSer] = useState<any>();
  const [nonQuotedSer, setNonQuotedSer] = useState<any>();
  const quotedServices: any = [];
  const nonQuotedServices: any = [];
  useEffect(() => {
    if (carrierServices && carrierServices.services.length) {
      carrierServices.services.forEach((service: any) => {
        if (service.isQuoted) {
          quotedServices.push({
            name: service.serviceId.name,
            value: service.value,
          });
        } else {
          // if (service.value)
          nonQuotedServices.push({
            name: service.serviceId.name,
            value: service.value,
          });
        }
      });

      setQuotedSer(quotedServices);
      setNonQuotedSer(nonQuotedServices);
    }
  }, []);

  return (
    <div className="text-xs sm:rounded-lg shadow max-w-[250px] ">
      <div className="flex flex-col gap-2">
        {/* <div className="w-full">
                    {quotedSer?.length > 0 &&
                        quotedSer?.map((service: any, index: number) => (
                            <p key={index} className="flex justify-start items-start gap-1 text-sm font-medium mt-1">
                                {service.name} : <span className="text-sm font-semibold"> ${service.value}</span>
                            </p>
                        ))}
                    {nonQuotedSer?.length > 0 &&
                        nonQuotedSer?.map((service: any, index: number) => (
                            <p key={index} className="flex justify-start items-start gap-1 text-sm font-medium mt-1">
                                {service.name} : <span className="text-sm font-semibold"> {service.value}</span>
                            </p>
                        ))}
                </div> */}
        {quotedSer?.length > 0 && (
          <div className="w-full flex flex-col gap-2">
            {/* <h1 className="flex justify-start items-start text-sm font-bold leading-8">Quoted Services</h1>
                        <hr /> */}
            {quotedSer &&
              quotedSer?.map((service: any, index: number) => (
                <p key={index} className="text-left text-xs font-semibold">
                  <span className="mr-1">{service?.name}:</span>{' '}
                  <span className="text-xs font-semibold">
                    {' '}
                    {service?.value > 0
                      ? `$${getFormattedNumber(service.value)}`
                      : 'Not rated'}
                  </span>
                </p>
              ))}
          </div>
        )}
        {nonQuotedSer?.length > 0 && (
          // <div className={`w-full ${quotedSer?.length > 0 ? "mt-4" : ""}`}>
          <div className={`w-full flex flex-col gap-2`}>
            {/* <h1 className="flex justify-start items-start text-sm font-bold leading-8">Non Quoted Services</h1>
                        <hr /> */}
            {nonQuotedSer &&
              nonQuotedSer?.map((service: any, index: number) => (
                <p key={index} className="text-left text-xs font-semibold">
                  <span className="mr-1"> {service?.name}:</span>{' '}
                  <span className=" text-xs font-semibold">
                    {' '}
                    {service?.value > 0
                      ? `$${getFormattedNumber(service.value)}`
                      : 'Not rated'}
                  </span>
                </p>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceDetail;
