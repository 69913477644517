import { DownloadCloud02, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import { PERMISSION } from 'src/constants/common';
import { useRolePermission } from 'src/utils/CommonFunctions';

const FileLoadingRow = () => {
  const { hasPermissionV2 } = useRolePermission();

  return (
    <>
      <td className="px-5 py-3">
        <h6
          className={`text-grayLight900 font-semibold  custom-loading
        }`}
        >
          Master file 1234
        </h6>
        <p className={`custom-loading`}>Size: 10245 KB</p>
      </td>
      <td className="px-5 py-3">
        <h6 className={`text-grayLight900 font-semibold  custom-loading`}>
          Master file 1234
        </h6>
        <p className={`custom-loading`}>Size: 10245 KB</p>
      </td>
      <td className="px-5 py-3">
        <span className={`custom-loading`}>
          January 08, 2020 - March 25, 2020
        </span>
      </td>
      <td className="px-5 py-3">
        <span className={`custom-loading`}>10/04/2024</span>
      </td>
      <td className="px-5 py-3">
        <div className={`flex gap-3 items-center custom-loading`}>
          <img
            className="w-7 h-7 border-[0.75px] border-black/[0.08] rounded-full object-cover"
            src=""
          />
          <div>
            <h6 className="text-grayLight900 font-semibold">test user</h6>
            <p className=""></p>
          </div>
        </div>
      </td>

      <td className="px-5 py-3">
        <span className={`custom-loading`}>23</span>
      </td>
      <td className="px-5 py-3">
        <div className={`gap-4 flex justify-center custom-loading`}>
          {hasPermissionV2(PERMISSION.FILE_DOWNLOAD) && (
            <TooltipCmp message={'Download File'}>
              <DownloadCloud02 className="w-4 h-4 relative cursor-pointer" />
            </TooltipCmp>
          )}
          <TooltipCmp message={'Delete Data'}>
            <Trash01 className="w-4 h-4 relative cursor-pointer" />
          </TooltipCmp>
        </div>
      </td>
    </>
  );
};

export default FileLoadingRow;
