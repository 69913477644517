import React, { memo, useEffect, useState } from 'react';
import WalToast from 'src/utils/WalToast';

import CommonModal from '../../../../../components/CommonModal';
import InputText from '../../../../../components/InputText/InputText';
import SelectBox from '../../../../../components/SelectBox/SelectBox';
import { cancelQuote } from '../../../../../services/QuoteService';

const closedQuoteReasonList = [
  { value: '1', label: 'Cost too high' },
  { value: '2', label: 'Margin too high' },
  { value: '3', label: 'Too long to quote' },
  { value: '3', label: 'Quote for reference' },
  { value: 'other', label: 'Other' },
];

const Closed = ({ id, setIsRefresh, handleClose }: any) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [closedReasonText, setClosedReasonText] = useState<
    string | null | undefined
  >(undefined);
  const [otherReasonText, setOtherReasonText] = useState('');

  const handleSubmit = () => {
    const cancelParams = {
      cancelReason:
        closedReasonText === 'Other' ? otherReasonText : closedReasonText,
    };
    cancelQuote(cancelParams, id)
      .then((response: any) => {
        if (response && response.data) {
          handleClose(false)();
          WalToast.success('Quote has been closed successfully');
        }
      })
      .finally(() => setIsRefresh(true))
      .catch(() => WalToast.error('Quote has not been close'));
  };
  useEffect(() => {
    console.log('closedReasonText', closedReasonText);
  }, [closedReasonText]);

  const handleSelectChange = (event: any) => {
    const { value } = event;
    console.log('value', event);
    setClosedReasonText(value);
    setIsDisabled(value === 'Other');

    if (!value) {
      setIsDisabled(true);
    }
  };

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setOtherReasonText(value);
    setIsDisabled(!value);
  };

  return (
    <CommonModal
      handleClose={handleClose}
      title="Please indicate the reason for the closure"
      size={'max-w-2xl'}
      isOverflow={false}
    >
      <div className="p-6 pt-0 mt-3">
        <SelectBox
          isClearable
          name="QuoteCloseReason"
          label="QuoteCloseReason"
          className={`form_control z-50`}
          labelClassName="text-black text-sm mb-2"
          options={closedQuoteReasonList}
          value={closedQuoteReasonList.filter(
            (item) => item.value === closedReasonText
          )}
          onChangeFunc={handleSelectChange}
          // errorText={!validForm.customerId ? 'Customer is required' : null}
        />

        {/* <select
                    name="QuoteCloseReason"
                    id="QuoteCloseReason"
                    className="form_control"
                    onChange={handleSelectChange}
                    value={closedReasonText || ''}
                >
                    <option value="">Choose a reason</option>
                    {closedQuoteReasonList.map((reason: any, index: number) => (
                        <option key={index} value={reason.label}>
                            {reason.label}
                        </option>
                    ))}
                </select> */}
        {closedReasonText === 'Other' && (
          <div className="mt-2">
            <InputText
              inputType="textarea"
              inputName="QuoteCloseReason"
              placeholder="Specify the reason"
              onChangeFunc={handleInputChange}
            />
          </div>
        )}
        <button
          className={`bg-primary w-full mt-3 px-8 text-center py-3 rounded-full text-sm font-semibold text-white ${
            isDisabled
              ? ' bg-opacity-50 cursor-not-allowed'
              : ' hover:bg-primary700 cursor-pointer'
          }`}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          Send
        </button>
      </div>
    </CommonModal>
  );
};

export default memo(Closed);
