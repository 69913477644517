import { Calendar } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import DatePicker from 'react-datepicker';

import ErrorMsg from '../errorMsg';

import { IDateSelectProps } from './dateSelect.interface';

const CustomDateInput = React.forwardRef(
  ({ value, onClick }: any, ref: any) => (
    <div
      className="form_control text-grayLight900 font-medium  [&.disabled]:border-borderPrimary [&.disabled]:bg-gray50 [&.disabled]:text-gray500 [&.disabled>svg]:hidden [&.disabled]:opacity-100"
      onClick={onClick}
      ref={ref}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  )
);

CustomDateInput.displayName = 'CustomDateInput';

const DateSelect = React.forwardRef(
  (
    {
      inputName,
      placeholder,
      className,
      labelClassName,
      parentClassName,
      calendarIconClass,
      errorText,
      required,
      label,
      tabIndex,
      onChangeFunc,
      ...props
    }: IDateSelectProps,
    ref: any
  ) => {
    const onChangeDate = (
      date: Date | null,
      event: React.SyntheticEvent<any, Event> | undefined
    ) => {
      let customEvent: any = event;
      customEvent = {
        event,
        ...{ value: date, id: props.id, name: inputName },
      };
      onChangeFunc(customEvent);
    };

    return (
      <div className={`${parentClassName}`}>
        <div className="flex">
          {label && (
            <label className={`form_label mb-1.5 ${labelClassName}`}>
              {label}
            </label>
          )}
          {required && <span className="text-red-600 leading-4">*</span>}
        </div>
        <div
          className={`relative w-full cursor-pointer wal-single-date-picker`}
        >
          <DatePicker
            ref={ref}
            placeholderText={placeholder}
            onChange={onChangeDate}
            className={`${className} cursor-pointer `}
            tabIndex={tabIndex}
            showFourColumnMonthYearPicker={true}
            id={props.id}
            onKeyDown={(e) => {
              if (e?.keyCode === 9 || e?.which === 9) {
                ref?.current.setOpen(false);
              }
            }}
            autoComplete="off"
            name={inputName}
            dropdownMode="select"
            showIcon={true}
            icon={<Calendar className="h-5 w-5 text-textSecondary" />}
            toggleCalendarOnIconClick
            calendarIconClassname={`icon absolute right-2 top-1/2 -translate-y-1/2 text-lg text-textSecondary ${calendarIconClass}`}
            customInput={<CustomDateInput value={props.selected} />}
            popperPlacement="top-end"
            {...props}
          />
        </div>
        {errorText && <ErrorMsg errorText={errorText} />}
      </div>
    );
  }
);

DateSelect.defaultProps = {
  inputName: '',
  placeholder: '',
  className: '',
  labelClassName: '',
  label: '',
  errorText: null,
  id: '',
};

DateSelect.displayName = 'DateSelect';

export default DateSelect;
