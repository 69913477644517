import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listNotification = async (params: any) => {
  const response = await axiosInterceptor.get(API.NOTIFICATION.LIST, {
    params,
  });

  return response;
};

export const markAsReadAllNotification = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.NOTIFICATION.MARK_AS_READ_ALL,
    data
  );

  return response;
};

export const markNotificationAsRead = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.NOTIFICATION.READ_NEW_CLAIM_DISPUTE_NOTIFICATION,
    data
  );

  return response;
};
