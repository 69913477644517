/* eslint-disable max-lines-per-function */
import { CalendarCheck01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import WalToast from 'src/utils/WalToast';

import outlook from '../../../../../assets/img/outlook.svg';
import ButtonCmp from '../../../../../components/ButtonCmp';
import DateSelect from '../../../../../components/DateSelect/DateSelect';
import DeleteModal from '../../../../../components/DeleteModal';
import InputText from '../../../../../components/InputText/InputText';
import PageSectionLayout from '../../../../../components/PageSectionLayout';
import Header from '../../../../../components/PageSectionLayout/Header/Header';
import SelectBox from '../../../../../components/SelectBox/SelectBox';
import StepBar from '../../../../../components/StepBar/StepBar';
import {
  DATE_FORMAT,
  STATUS,
  TIME_FORMAT,
} from '../../../../../constants/common';
import { PATH } from '../../../../../constants/path';
import { ROUTES } from '../../../../../constants/routes';
import { AuthContext } from '../../../../../context/AuthContext';
import { CustomersAllList } from '../../../../../services/CustomerService';
import {
  createQuote,
  getQuote,
  updateQuote,
} from '../../../../../services/QuoteService';
import {
  createShipper,
  deleteShippers,
  listShippers,
  updateShipper,
} from '../../../../../services/ShipperService';
import { getSelectBoxOptions } from '../../../../../utils/CommonFunctions';
import MailDetailView from '../MailDetailView';
import { STEPS } from '../SmartQuote.interface';

import Address from './Address';
import ConsigneeAddress from './ConsigneeAddress';
import ShipperAddress from './ShipperAddress';

const AddressDetailsForm = () => {
  interface IMapAddress {
    fullAddress: string;
    address1: string;
    city: string;
    state: string;
    country: string;
    postal: string;
    latitude: string;
    longitude: string;
    stateCode: string;
    countryCode: string;
  }
  interface IShipperMapAddress {
    shipperId: number | null;
    shipperCompanyName: string;
    shipperAddress: IMapAddress;
  }
  interface IConsigneeMapAddress {
    consigneeId: number | null;
    consigneeCompanyName: string;
    consigneeAddress: IMapAddress;
  }

  const initSelectFormData = {
    customerId: null,
    shipperId: null,
    consigneeId: null,
  };

  const initShipperAddress: IMapAddress = {
    fullAddress: '',
    address1: '',
    city: '',
    state: '',
    country: '',
    postal: '',
    latitude: '',
    longitude: '',
    stateCode: '',
    countryCode: '',
  };

  const initConsigneeAddress: IMapAddress = {
    fullAddress: '',
    address1: '',
    city: '',
    state: '',
    country: '',
    postal: '',
    latitude: '',
    longitude: '',
    stateCode: '',
    countryCode: '',
  };
  const initShipperAddress1: IShipperMapAddress = {
    shipperId: null,
    shipperCompanyName: '',
    shipperAddress: initShipperAddress,
  };

  const initConsigneeAddress1: IConsigneeMapAddress = {
    consigneeId: null,
    consigneeCompanyName: '',
    consigneeAddress: initConsigneeAddress,
  };

  // const requiredFields = ["fullAddress", "address1", "city", "state", "country", "postal", "latitude", "longitude", "stateCode", "countryCode"];
  interface IFormData {
    [key: string]: any;
    quoteClassification: string;
    customerId: any;
    shipperId: number | null;
    shipperCompanyName: string;
    shipper: IShipperMapAddress[];
    consignee: IConsigneeMapAddress[];
    shipperAddress: IMapAddress;
    pickupDate: string | undefined;
    consigneeId: number | null;
    consigneeCompanyName: string;
    consigneeAddress: IMapAddress;
  }

  const initFormData: IFormData = {
    quoteClassification: 'LTL',
    shipper: [initShipperAddress1],
    consignee: [initConsigneeAddress1],
    customerId: '',
    shipperId: null,
    shipperCompanyName: '',
    shipperAddress: initShipperAddress,
    // pickupDate: moment().toISOString(),
    pickupDate: new Date().toISOString(),
    consigneeId: null,
    consigneeCompanyName: '',
    consigneeAddress: initConsigneeAddress,
  };

  const initValidForm = {
    quoteClassification: true,
    customerId: true,
    shipperId: true,
    shipperCompanyName: true,
    shipperAddress: true,
    pickupDate: {
      valid: true,
      nextDay: true,
    },
    consigneeId: true,
    consigneeCompanyName: true,
    consigneeAddress: true,
  };

  const initAction = {
    mode: null,
    shipper: false,
    consignee: false,
  };

  const initValidAddressForm = {
    shipper: [
      {
        shipperId: true,
        shipperCompanyName: true,
        fullAddress: true,
        address1: true,
        city: true,
        state: true,
        country: true,
        postal: true,
        latitude: true,
        longitude: true,
        stateCode: true,
        countryCode: true,
      },
    ],
    consignee: [
      {
        consigneeId: true,
        consigneeCompanyName: true,
        fullAddress: true,
        address1: true,
        city: true,
        state: true,
        country: true,
        postal: true,
        latitude: true,
        longitude: true,
        stateCode: true,
        countryCode: true,
      },
    ],
  };

  const scriptId = 'google-maps-api';
  const { id } = useParams<{ id: any }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [customers, setCustomers] = useState<any>([]);
  const [formData, setFormData] = useState<IFormData>(initFormData);
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [isShipperLoading, setIsShipperLoading] = useState(true);
  const [selectFormData, setSelectFormData] = useState<any>(initSelectFormData);
  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);
  const [shipperAddress, setShipperAddress] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [consigneeAddress, setConsigneeAddress] = useState<any>([]);
  const [action, setAction] = useState(initAction);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [isConsigneeBtnDisable, setIsConsigneeBtnDisable] = useState(false);
  const [isShipperBtnDisable, setIsShipperBtnDisable] = useState(false);
  const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
  const [isValidAddress, setIsValidAddress] = useState([
    {
      shipperAddress: true,
      consigneeAddress: true,
    },
  ]);
  const [addressData, setAddressData] = useState<any>();
  const [validForm, setValidForm] = useState(initValidForm);
  const [validAddressForm, setValidAddressForm] =
    useState(initValidAddressForm);
  const [isShowViewEmailModal, setIsShowViewEmailModal] = useState(false);

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  useEffect(() => {
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  }, []);

  const handleSelectChange = (name: any, index?: number) => (event: any) => {
    const newData: any = {};
    newData[name] = event ? event.value : null;

    if (name === 'shipperId') {
      if (event && event.value) {
        const shipper = shippers.find(
          (shipperData: any) => shipperData.id === event.value
        );
        newData.shipperCompanyName = shipper.companyName;
        newData.shipperAddress = {
          fullAddress: shipper.fullAddress,
          address1: shipper.address1,
          postal: shipper.postal,
          city: shipper.city,
          state: shipper.state,
          country: shipper.country,
          longitude: shipper.longitude,
          latitude: shipper.latitude,
          stateCode: shipper.stateCode,
          countryCode: shipper.countryCode,
        };
        // setIsShipperBtnDisable(false);
      } else {
        newData.shipperCompanyName = '';
        newData.shipperAddress = initShipperAddress;
        setShipperAddress('');
      }
      setFormData((oldData: any) => ({
        ...oldData,
        shipper: oldData.shipper.map((shipper: any, idx: number) => {
          if (idx === index) {
            return { ...shipper, ...newData }; // Update shipper address at specific index
          }

          return shipper;
        }),
      }));
    } else if (name === 'consigneeId') {
      if (event && event.value) {
        const consignee = consignees.find(
          (consigneeData: any) => consigneeData.id === event.value
        );
        newData.consigneeCompanyName = consignee.companyName;
        newData.consigneeAddress = {
          fullAddress: consignee.fullAddress,
          address1: consignee.address1,
          postal: consignee.postal,
          city: consignee.city,
          state: consignee.state,
          country: consignee.country,
          longitude: consignee.longitude,
          latitude: consignee.latitude,
          stateCode: consignee.stateCode,
          countryCode: consignee.countryCode,
        };
        // setIsConsigneeBtnDisable(false);
      } else {
        newData.consigneeCompanyName = '';
        newData.consigneeAddress = initConsigneeAddress;
        setConsigneeAddress('');
      }
      // setFormData((old) => ({ ...old, ...newData }));
      setFormData((oldData: any) => ({
        ...oldData,
        consignee: oldData.consignee.map((consignee: any, idx: number) => {
          if (idx === index) {
            return { ...consignee, ...newData }; // Update shipper address at specific index
          }

          return consignee;
        }),
      }));
    } else {
      setFormData((old) => ({ ...old, ...newData }));
    }

    // setFormData((old) => ({ ...old, ...newData }));
    setSelectFormData((old: any) => ({ ...old, [name]: event }));
  };

  const handleAutocompleteChange =
    (name: string, index?: number) => (value: any) => {
      if (value.type === 'blur') {
        // if (!formData[name].fullAddress && value.target.value) {
        //     setIsValidAddress((old) => ({ ...old, [name]: false }));
        // }
        return;
      }

      if (name === 'shipperAddress') {
        if (
          value?.city === '' ||
          value?.postal === '' ||
          value.postal?.length < 4
        ) {
          if (index) {
            const updatedShipperAddress = [...shipperAddress]; // Create a copy of the shipperAddress array
            updatedShipperAddress[index] = value?.fullAddress; // Update the fullAddress at the specified index
            setShipperAddress(updatedShipperAddress);
            setIsValidAddress((oldState) => {
              const newState = [...oldState]; // Create a copy of the state array
              newState[index] = { ...newState[index], [name]: false }; // Update the specific index

              return newState; // Return the updated state
            });
          } else {
            setShipperAddress(value?.fullAddress);
            setIsValidAddress((old) => ({ ...old, [name]: false }));
          }

          // setFormData((old) => ({ ...old, [name]: value }));
          setFormData((oldData: any) => ({
            ...oldData,
            shipper: oldData.shipper.map((shipper: any, idx: number) => {
              if (idx === index) {
                return {
                  ...shipper,
                  shipperAddress: {
                    ...shipper.shipperAddress,
                    fullAddress: value?.fullAddress,
                  },
                }; // Update fullAddress at specific index
              }

              return shipper;
            }),
          }));

          return;
        }

        if (index !== undefined && index > -1) {
          const updatedShipperAddress = [...shipperAddress]; // Create a copy of the shipperAddress array
          updatedShipperAddress[index] = value; // Update the fullAddress at the specified index
          setShipperAddress(updatedShipperAddress);
          setFormData((oldData: any) => ({
            ...oldData,
            shipper: oldData.shipper.map((shipper: any, idx: number) => {
              if (idx === index) {
                return { ...shipper, shipperAddress: { ...value } }; // Update fullAddress at specific index
              }

              return shipper;
            }),
          }));
          setIsValidAddress((oldState) => {
            const newState = [...oldState]; // Create a copy of the state array
            newState[index] = { ...newState[index], [name]: true }; // Update the specific index

            return newState; // Return the updated state
          });
        } else {
          setShipperAddress(value);
          setIsValidAddress((old) => ({ ...old, [name]: true }));
        }

        // setShipperAddress(value);

        setValidForm({ ...validForm, shipperAddress: true });
      } else if (name === 'consigneeAddress') {
        if (
          value?.city === '' ||
          value?.postal === '' ||
          value.postal?.length < 4
        ) {
          if (index) {
            const updatedConsigneeAddress = [...consigneeAddress]; // Create a copy of the shipperAddress array
            updatedConsigneeAddress[index] = value?.fullAddress; // Update the fullAddress at the specified index
            setConsigneeAddress(updatedConsigneeAddress);
            setIsValidAddress((oldState) => {
              const newState = [...oldState]; // Create a copy of the state array
              newState[index] = { ...newState[index], [name]: false }; // Update the specific index

              return newState; // Return the updated state
            });
          } else {
            setConsigneeAddress(value?.fullAddress);
            setIsValidAddress((old) => ({ ...old, [name]: false }));
          }
          // setConsigneeAddress(value?.fullAddress);
          // setFormData((old) => ({ ...old, [name]: value }));
          setFormData((oldData: any) => ({
            ...oldData,
            consignee: oldData.consignee.map((consignee: any, idx: number) => {
              if (idx === index) {
                return {
                  ...consignee,
                  consigneeAddress: {
                    ...consignee.consigneeAddress,
                    fullAddress: value?.fullAddress,
                  },
                }; // Update fullAddress at specific index
              }

              return consignee;
            }),
          }));

          return;
        }

        if (index !== undefined && index > -1) {
          const updatedConsigneeAddress = [...consigneeAddress]; // Create a copy of the shipperAddress array
          updatedConsigneeAddress[index] = value; // Update the fullAddress at the specified index
          setConsigneeAddress(updatedConsigneeAddress);
          setFormData((oldData: any) => ({
            ...oldData,
            consignee: oldData.consignee.map((consignee: any, idx: number) => {
              if (idx === index) {
                return { ...consignee, consigneeAddress: { ...value } }; // Update fullAddress at specific index
              }

              return consignee;
            }),
          }));
          setIsValidAddress((oldState) => {
            const newState = [...oldState]; // Create a copy of the state array
            newState[index] = { ...newState[index], [name]: true }; // Update the specific index

            return newState; // Return the updated state
          });
        } else {
          setConsigneeAddress(value);
          setIsValidAddress((old) => ({ ...old, [name]: true }));
        }

        // setConsigneeAddress(value);
        // setIsValidAddress((old) => ({ ...old, [name]: true }));
        setValidForm({ ...validForm, consigneeAddress: true });
      }
      setFormData((old) => ({ ...old, [name]: value }));
    };

  const getCustomer = () => {
    setIsCustomerLoading(true);
    CustomersAllList()
      .then((result: any) => {
        if (result.data && result.data.length) {
          if (formData.customerId) {
            result.data = result.data.filter(
              (data: any) => !data.isDeleted || data.id === formData.customerId
            );
          } else if (!id) {
            result.data = result.data.filter((data: any) => !data.isDeleted);
          }
          result.data = result.data.map((data: any) => {
            if (data.isDeleted) {
              data.name = `${data.name} (Deleted Customer)`;
              data.id = '';
            }

            return data;
          });

          setCustomers(result.data);
          const customerSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setCustomerOptions(customerSelectBoxOptions);
          setIsCustomerLoading(false);
        }
      })
      .finally(() => setIsCustomerLoading(false))
      .catch(console.error);
  };

  const getShipper = () => {
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);
        }
      })
      .finally(() => setIsShipperLoading(false))
      .catch(console.error);
  };

  const handleInputChange = (event: any, index?: any) => {
    const { name, value } = event.target;

    // setFormData((old: any) => ({ ...old, [name]: value }));
    if (name === 'shipperCompanyName') {
      setFormData((oldData: any) => ({
        ...oldData,
        shipper: oldData.shipper.map((shipper: any, idx: number) => {
          if (idx === index) {
            return { ...shipper, [name]: value }; // Update fullAddress at specific index
          }

          return shipper;
        }),
      }));
    }

    if (name === 'consigneeCompanyName') {
      setFormData((oldData: any) => ({
        ...oldData,
        consignee: oldData.consignee.map((consignee: any, idx: number) => {
          if (idx === index) {
            return { ...consignee, [name]: value }; // Update fullAddress at specific index
          }

          return consignee;
        }),
      }));
    }
  };
  useEffect(() => {
    console.log('addressData', addressData);
  }, [addressData]);

  const handleActionType =
    (actionKey: any, mode: any = null, index: any = null) =>
    () => {
      setCurrentIndex(index);

      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        const formAddressData =
          index !== null || index !== undefined
            ? formData.shipper[index].shipperAddress
            : formData.shipperAddress;
        setAddressData(formAddressData);
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData([
          index ? formData.consignee[index] : formData.consigneeAddress,
        ]);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      customerId: true,
      shipperAddress: true,
      pickupDate: {
        valid: true,
        nextDay: true,
      },
      consigneeAddress: true,
    };

    if (formData.customerId === '' || formData.customerId === null) {
      validFormData.customerId = false;
      valid = false;
    }

    // if (formData.shipperAddress.fullAddress === undefined) {
    //     validFormData.shipperAddress = false;
    //     valid = false;
    // }

    // if (formData.shipperAddress.fullAddress === "" || formData.shipperAddress.fullAddress === null) {
    //     validFormData.shipperAddress = false;
    //     valid = false;
    // }

    if (formData.pickupDate === '' || formData.pickupDate === null) {
      validFormData.pickupDate.valid = false;
      valid = false;
    }

    // if (formData.consigneeAddress.fullAddress === undefined) {
    //     validFormData.consigneeAddress = false;
    //     valid = false;
    // }

    // if (formData.consigneeAddress.fullAddress === "" || formData.consigneeAddress.fullAddress === null) {
    //     validFormData.consigneeAddress = false;
    //     valid = false;
    // }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const isAddressFormValid = () => {
    const validShipperFormAddressData: any = [];
    formData.shipper.forEach((form: any) => {
      const sValidation = {
        shipperId:
          form.shipperId === '' || form.shipperId === null ? false : true,
        shipperCompanyName:
          form.shipperCompanyName === '' || form.shipperCompanyName === null
            ? false
            : true,
        fullAddress:
          form.shipperAddress.fullAddress === '' ||
          form.shipperAddress.fullAddress === null
            ? false
            : true,
        address1:
          form.shipperAddress.address1 === '' ||
          form.shipperAddress.address1 === null
            ? false
            : true,
        city:
          form.shipperAddress.city === '' || form.shipperAddress.city === null
            ? false
            : true,
        state:
          form.shipperAddress.state === '' || form.shipperAddress.state === null
            ? false
            : true,
        country:
          form.shipperAddress.country === '' ||
          form.shipperAddress.country === null
            ? false
            : true,
        longitude:
          form.shipperAddress.longitude === '' ||
          form.shipperAddress.longitude === null
            ? false
            : true,
        latitude:
          form.shipperAddress.latitude === '' ||
          form.shipperAddress.latitude === null
            ? false
            : true,
        stateCode:
          form.shipperAddress.stateCode === '' ||
          form.shipperAddress.stateCode === null
            ? false
            : true,
        countryCode:
          form.shipperAddress.countryCode === '' ||
          form.shipperAddress.countryCode === null
            ? false
            : true,
      };
      console.log('sValidation', sValidation);
      validShipperFormAddressData.push(sValidation);
    });

    console.log('validShipperFormAddressData', validShipperFormAddressData);

    const validConsigneeFormAddressData: any = [];
    formData.consignee.forEach((form: any) => {
      const cValidation = {
        consigneeId:
          form.consigneeId === '' || form.consigneeId === null ? false : true,
        consigneeCompanyName:
          form.consigneeCompanyName === '' || form.consigneeCompanyName === null
            ? false
            : true,
        fullAddress:
          form.consigneeAddress.fullAddress === '' ||
          form.consigneeAddress.fullAddress === null
            ? false
            : true,
        address1:
          form.consigneeAddress.address1 === '' ||
          form.consigneeAddress.address1 === null
            ? false
            : true,
        city:
          form.consigneeAddress.city === '' ||
          form.consigneeAddress.city === null
            ? false
            : true,
        state:
          form.consigneeAddress.state === '' ||
          form.consigneeAddress.state === null
            ? false
            : true,
        country:
          form.consigneeAddress.country === '' ||
          form.consigneeAddress.country === null
            ? false
            : true,
        longitude:
          form.consigneeAddress.longitude === '' ||
          form.consigneeAddress.longitude === null
            ? false
            : true,
        latitude:
          form.consigneeAddress.latitude === '' ||
          form.consigneeAddress.latitude === null
            ? false
            : true,
        stateCode:
          form.consigneeAddress.stateCode === '' ||
          form.consigneeAddress.stateCode === null
            ? false
            : true,
        countryCode:
          form.consigneeAddress.countryCode === '' ||
          form.consigneeAddress.countryCode === null
            ? false
            : true,
      };
      console.log('cValidation', cValidation);
      validConsigneeFormAddressData.push(cValidation);
    });
    console.log('validConsigneeFormAddressData', validConsigneeFormAddressData);

    setValidAddressForm({
      ...validAddressForm,
      ...{
        consignee: validConsigneeFormAddressData,
        shipper: validShipperFormAddressData,
      },
    });
    let validData: any = {
      consignee: validConsigneeFormAddressData,
      shipper: validShipperFormAddressData,
    };
    console.log('validData', validData);
    let valid = Object.keys(validData).reduce((acc: any, key: any) => {
      // acc[key] = validData[key].every((form: any) => requiredFields.every((requiredField) => form[requiredField]));
      acc[key] = validData[key].every((form: any) =>
        Object.values(form).every((value) => value === true)
      );

      return acc;
    }, {});

    console.log('valid::', valid);

    return valid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const addressValid = isAddressFormValid();

    if (!isFormValid() || !addressValid.consignee || !addressValid.shipper) {
      console.log('isFormValid', isFormValid(), isAddressFormValid());

      return true;
    }
    console.log('else');
    const pickupDate = moment(formData.pickupDate).format(DATE_FORMAT);
    const pickupTime = moment().format(TIME_FORMAT);
    const pickupDateTime = moment(
      `${pickupDate} ${pickupTime}`,
      `${DATE_FORMAT} ${TIME_FORMAT}`
    ).toISOString();
    const data: any = {
      userId: currentUser.id,
      customerId: formData.customerId,
      pickupDate: pickupDateTime,
      shipper: formData.shipper,
      consignee: formData.consignee,
      // shipperId: formData.shipperId,
      // shipperCompanyName: formData.shipperCompanyName,
      // shipperFullAddress: formData.shipperAddress.fullAddress,
      // shipperAddress1: formData.shipperAddress.address1,
      // shipperCity: formData.shipperAddress.city,
      // shipperState: formData.shipperAddress.state,
      // shipperCountry: formData.shipperAddress.country,
      // shipperPostal: formData.shipperAddress.postal,
      // shipperLatitude: formData.shipperAddress.latitude,
      // shipperLongitude: formData.shipperAddress.longitude,
      // shipperStateCode: formData.shipperAddress.stateCode,
      // shipperCountryCode: formData.shipperAddress.countryCode,
      // consigneeId: formData.consigneeId,
      // consigneeCompanyName: formData.consigneeCompanyName,
      // consigneeFullAddress: formData.consigneeAddress.fullAddress,
      // consigneeAddress1: formData.consigneeAddress.address1,
      // consigneeCity: formData.consigneeAddress.city,
      // consigneeState: formData.consigneeAddress.state,
      // consigneeCountry: formData.consigneeAddress.country,
      // consigneePostal: formData.consigneeAddress.postal,
      // consigneeLatitude: formData.consigneeAddress.latitude,
      // consigneeLongitude: formData.consigneeAddress.longitude,
      // consigneeStateCode: formData.consigneeAddress.stateCode,
      // consigneeCountryCode: formData.consigneeAddress.countryCode,
    };

    if (id) {
      updateQuote(id, data)
        .then((result: any) => {
          if (result) {
            if (searchParams.get('_r') !== null) {
              navigate(`${PATH.MY_QUOTE}/${result.data.id}`);
            } else {
              navigate(`${PATH.REQ_DIMENSIONS}/${result.data.id}`);
            }
          }
        })
        .catch((error: any) => {
          const errMsg =
            error?.response?.status === 409
              ? error?.response?.data?.message
              : 'Something went wrong! Please try again';
          WalToast.error(errMsg);
        });
    } else {
      createQuote(data)
        .then((result: any) => {
          if (result) {
            navigate(`${PATH.REQ_DIMENSIONS}/${result.data.id}`);
          }
        })
        .catch((error: any) => {
          const errMsg =
            error?.response?.status === 409
              ? error?.response?.data?.message
              : 'Something went wrong! Please try again';
          WalToast.error(errMsg);
        });
    }
  };

  const saveShipperDetails = (type: string, index: number) => () => {
    let idToSave;
    let address: any;
    let companyName;
    let typeId;

    if (type === 'shipper') {
      typeId = 1;
      idToSave = formData.shipper[index].shipperId;
      companyName = formData.shipper[index].shipperCompanyName;
      address = formData.shipper[index].shipperAddress;
    } else if (type === 'consignee') {
      typeId = 2;
      idToSave = formData.consignee[index].consigneeId;
      companyName = formData.consignee[index].consigneeCompanyName;
      address = formData.consignee[index].consigneeAddress;
    }
    const data: any = {
      ...address,
      companyName,
      type: typeId,
    };

    if (companyName === '') {
      WalToast.error(`Please enter ${type}'s company name`);

      return false;
    }

    if (address.fullAddress === '' || typeof address !== 'object') {
      WalToast.error(`Please enter ${type}'s address`);

      return false;
    }

    if (idToSave) {
      updateShipper(idToSave, data)
        .then((result: any) => {
          if (result) {
            getShipper();
            WalToast.success('Details updated successfully');
          }
        })
        .catch(console.error);
    } else {
      createShipper(data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success('Details created successfully');
          }
        })
        .catch(console.error);
    }
  };

  const checkPickupDate = (value: any) => {
    const pickupDateMoment = moment(value, 'YYYY-MM-DD');
    const currentTime = moment(new Date());
    pickupDateMoment.set({
      hour: currentTime.hours(),
      minute: currentTime.minutes(),
      second: currentTime.seconds(),
      millisecond: currentTime.milliseconds(),
    });
  };

  useEffect(() => {
    getCustomer();
    getShipper();
  }, [isCustomerDeleted]);

  useEffect(() => {
    if (!isCustomerLoading && !isShipperLoading && id) {
      getQuote(id)
        .then((response: any) => {
          if (response && response.data) {
            const { addressDetails } = response.data;

            if (addressDetails.status !== STATUS.PENDING) {
              navigate(ROUTES.HOME);
            }
            const currentFormData: any = {
              customerId: addressDetails.isCustomerDeleted
                ? ''
                : addressDetails.customerId,
              pickupDate: addressDetails.pickupDate,
              shipperId: addressDetails.shipperId,
              shipperCompanyName: addressDetails.shipperCompanyName,
              consigneeId: addressDetails.consigneeId,
              consigneeCompanyName: addressDetails.consigneeCompanyName,
              shipperAddress: {
                fullAddress: addressDetails.shipperFullAddress,
                address1: addressDetails.shipperAddress1,
                city: addressDetails.shipperCity,
                state: addressDetails.shipperState,
                country: addressDetails.shipperCountry,
                postal: addressDetails.shipperPostal,
                latitude: addressDetails.shipperLatitude,
                longitude: addressDetails.shipperLongitude,
                stateCode: addressDetails.shipperStateCode,
                countryCode: addressDetails.shipperCountryCode,
              },
              consigneeAddress: {
                fullAddress: addressDetails.consigneeFullAddress,
                address1: addressDetails.consigneeAddress1,
                city: addressDetails.consigneeCity,
                state: addressDetails.consigneeState,
                country: addressDetails.consigneeCountry,
                postal: addressDetails.consigneePostal,
                latitude: addressDetails.consigneeLatitude,
                longitude: addressDetails.consigneeLongitude,
                stateCode: addressDetails.consigneeStateCode,
                countryCode: addressDetails.consigneeCountryCode,
              },
            };
            setFormData(currentFormData);
            setIsCustomerDeleted(addressDetails.isCustomerDeleted);

            const newData: any = {};

            if (currentFormData.customerId) {
              const customer = customers.find(
                (customerOption: any) =>
                  customerOption.id === currentFormData.customerId
              );
              const customerOption = getSelectBoxOptions(
                customer,
                'id',
                'name'
              );
              newData.customerId = customerOption;
            }

            if (currentFormData.shipperId) {
              newData.shipperId = shipperOptions.find(
                (shipperOption: any) =>
                  shipperOption.value === currentFormData.shipperId
              );
              setIsShipperBtnDisable(false);
            }

            if (currentFormData.consigneeId) {
              newData.consigneeId = consigneeOptions.find(
                (consigneeOption: any) =>
                  consigneeOption.value === currentFormData.consigneeId
              );
              setIsConsigneeBtnDisable(false);
            }
            setSelectFormData((old: any) => ({ ...old, ...newData }));
            checkPickupDate(addressDetails.pickupDate);
          } else {
            navigate(ROUTES.HOME);
          }
        })
        .catch(console.error);
    } else {
      setFormData((old) => ({ ...old, pickupDate: moment().toISOString() }));
    }
  }, [id, isCustomerLoading, isShipperLoading]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any, index: number) => {
    if (action.shipper) {
      setFormData((oldData: any) => ({
        ...oldData,
        shipper: oldData.shipper.map((shipper: any, idx: number) => {
          if (idx === index) {
            return {
              ...shipper,
              shipperAddress: { ...shipper.shipperAddress, fullAddress: value },
            }; // Update fullAddress at specific index
          }

          return shipper;
        }),
      }));
      // setFormData((old) => ({ ...old, shipperAddress: value }));
      setIsValidAddress((oldState) => {
        const newState = [...oldState]; // Create a copy of the state array
        newState[index] = { ...newState[index], shipperAddress: true }; // Update the specific index

        return newState; // Return the updated state
      });
      // setIsValidAddress((old) => ({ ...old, shipperAddress: true }));
    } else if (action.consignee) {
      // setFormData((old) => ({ ...old, consigneeAddress: value }));

      setFormData((oldData: any) => ({
        ...oldData,
        consignee: oldData.consignee.map((consignee: any, idx: number) => {
          if (idx === index) {
            return { ...consignee, consigneeAddress: value }; // Update fullAddress at specific index
          }

          return consignee;
        }),
      }));

      // setIsValidAddress((old) => ({ ...old, consigneeAddress: true }));
      setIsValidAddress((oldState) => {
        const newState = [...oldState]; // Create a copy of the state array
        newState[index] = { ...newState[index], consigneeAddress: true }; // Update the specific index

        return newState; // Return the updated state
      });
    }
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete =
          currentIndex || currentIndex === 0
            ? action.shipper
              ? formData.shipper[currentIndex].shipperId
              : formData.consignee[currentIndex].consigneeId
            : '';

        if (idToDelete) {
          deleteShippers(idToDelete)
            .then((result: any) => {
              if (result) {
                const newData: any = {};
                let name = '';

                if (action.shipper) {
                  newData.shipperId = null;
                  newData.shipperCompanyName = '';
                  newData.shipperAddress = initShipperAddress;
                  setShipperAddress('');
                  name = 'shipperId';
                  setFormData((oldData: any) => ({
                    ...oldData,
                    shipper: oldData.shipper.map(
                      (shipper: any, idx: number) => {
                        if (idx === currentIndex) {
                          return { ...shipper, ...newData }; // Update fullAddress at specific index
                        }

                        return shipper;
                      }
                    ),
                  }));
                } else if (action.consignee) {
                  newData.consigneeId = null;
                  newData.consigneeCompanyName = '';
                  newData.consigneeAddress = initConsigneeAddress;
                  setConsigneeAddress('');
                  name = 'consigneeId';
                  setFormData((oldData: any) => ({
                    ...oldData,
                    consignee: oldData.consignee.map(
                      (consignee: any, idx: number) => {
                        if (idx === currentIndex) {
                          return { ...consignee, ...newData }; // Update fullAddress at specific index
                        }

                        return consignee;
                      }
                    ),
                  }));
                }
                // setFormData((old) => ({ ...old, ...newData }));

                setSelectFormData((old: any) => ({ ...old, [name]: null }));
                getShipper();
                const title = action.shipper ? 'Shipper' : 'Consignee';
                WalToast.success(`${title} deleted successfully`);
              }
            })
            .catch(console.error);
        }
      }
    },
    [action]
  );

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="SmartQuotes"
            desc="Track, manage and forecast your customers and orders."
            rightSideContent={<StepBar steps={STEPS} activeStep={1}></StepBar>}
          />
        }
      >
        <div className="w-full justify-between items-end flex flex-wrap gap-3">
          <div className="flex sm:flex-row flex-col flex-wrap xxl:gap-4 gap-3 sm:w-auto w-full md:order-[0] order-2">
            <InputText
              inputName="quoteClassification"
              className="form_control"
              label="Quote Classification"
              labelClassName="mb-1.5 block"
              value={formData.quoteClassification}
              readOnly={true}
              disabled={true}
              errorText={
                !validForm.quoteClassification
                  ? 'Quote Classification is required'
                  : null
              }
              onChangeFunc={handleInputChange}
            />
            <SelectBox
              isClearable
              name="customerId"
              label="Customers"
              className={`form_control`}
              labelClassName="form_label mb-1.5 block"
              isSearchable={true}
              isLoading={isCustomerLoading}
              options={customerOptions}
              value={selectFormData.customerId}
              classComp={`${
                !validForm.customerId
                  ? 'border border-red-500 rounded-[10px] mb-1 border-solid '
                  : ''
              }`}
              onChangeFunc={handleSelectChange('customerId')}
              errorText={!validForm.customerId ? 'Customer is required' : null}
              parentClassName="xxl:min-w-[310px] xl:min-w-64 lg:min-w-52 sm:min-w-44 min-w-full"
            />

            <DateSelect
              inputName="pickupDate"
              className={`form_control xxl:min-w-[310px] xl:min-w-64 lg:min-w-52 sm:min-w-44 min-w-full ${
                !validForm.pickupDate.valid
                  ? 'border border-red-500 mb-1 border-solid'
                  : ''
              }`}
              label="Pickup date"
              labelClassName="block"
              placeholder={DATE_FORMAT}
              dateFormat="dd/MM/yyyy"
              minDate={moment().toDate()}
              selected={
                formData.pickupDate
                  ? moment(formData.pickupDate).toDate()
                  : null
              }
              errorText={''}
              // onChangeFunc={handleDateChange}
              onChangeFunc={() => {}}
              icon={<CalendarCheck01 className="h-5 w-5" />}
            />
          </div>
          <div className="gap-3 flex flex-wrap ">
            <Link
              to={ROUTES.HOME}
              className="btn_secondary_black xxl:min-w-[156px] xlm:min-w-32 min-w-fit text-center "
            >
              <div className="px-4 text-textSecondary text-xs font-semibold">
                Back
              </div>
            </Link>
            <ButtonCmp
              type="submit"
              className="btn_primary xxl:min-w-[156px] xlm:min-w-32 min-w-fit"
              onClick={(e: any) => handleSubmit(e)}
            >
              Next
            </ButtonCmp>
          </div>
        </div>

        <ShipperAddress
          formData={formData}
          initShipperAddress={initShipperAddress1}
          setFormData={setFormData}
          setValidAddressForm={setValidAddressForm}
          initValidAddressForm={initValidAddressForm}
          validAddressForm={validAddressForm}
          shipperOptions={shipperOptions}
          handleSelectChange={handleSelectChange}
          selectFormData={selectFormData}
          handleActionType={handleActionType}
          saveShipperDetails={saveShipperDetails}
          isShipperBtnDisable={isShipperBtnDisable}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          shipperAddress={shipperAddress}
          isValidAddress={isValidAddress}
          setIsValidAddress={setIsValidAddress}
          status={status}
        />

        <ConsigneeAddress
          formData={formData}
          initShipperAddress={initConsigneeAddress1}
          setFormData={setFormData}
          setValidAddressForm={setValidAddressForm}
          initValidAddressForm={initValidAddressForm}
          consigneeOptions={consigneeOptions}
          validAddressForm={validAddressForm}
          handleSelectChange={handleSelectChange}
          selectFormData={selectFormData}
          handleActionType={handleActionType}
          saveShipperDetails={saveShipperDetails}
          isConsigneeBtnDisable={isConsigneeBtnDisable}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          consigneeAddress={consigneeAddress}
          isValidAddress={isValidAddress}
          setIsValidAddress={setIsValidAddress}
          status={status}
        />

        <div className="absolute bottom-[37px] xxl:right-5 right-4 xl:mr-5 sm:mr-4 w-44">
          <ButtonCmp
            className="btn_secondary_black absolute bottom-0 right-0"
            onClick={() => {
              setIsShowViewEmailModal(!isShowViewEmailModal);
            }}
            icon={<img src={outlook} />}
          >
            View Email
          </ButtonCmp>

          {isShowViewEmailModal && (
            <div className="overflow-y-auto scrollbar-hide max-h-[calc(100vh_-_101px)] md:w-[641px] w-[90vw] rounded-[20px] bg-white border border-borderPrimary absolute bottom-[50px] right-0 z-[99] p-5 pb-0">
              <MailDetailView />
            </div>
          )}
        </div>
      </PageSectionLayout>

      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        currentIndex={currentIndex}
        addressType={action}
        addressData={addressData}
        status={status}
      />

      {action.mode === 'delete' &&
        currentIndex !== null &&
        currentIndex !== undefined && (
          <DeleteModal
            handleClose={handleModalClose}
            moduleTitle={`Remove ${
              action.consignee
                ? formData.consignee[currentIndex].consigneeCompanyName
                : formData.shipper[currentIndex].shipperCompanyName
            } From Wal HQ `}
          />
        )}
    </>
  );
};

export default AddressDetailsForm;
