import moment from 'moment';
import React from 'react';

const CompanyInfoOnboarding = ({ data }: any) => (
  <>
    <td className="px-5 py-4 max-w-[1px]">
      <p className="truncate ">{data.organization_type}</p>
    </td>
    <td className="px-5 py-4">
      <span>
        {data.business_start_date &&
          moment(data.business_start_date).format('DD MMMM YYYY')}
      </span>
    </td>
    <td className="px-5 py-4">
      <span>{data.federal_tax_number}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.business_type}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.d_b_number}</span>
    </td>
  </>
);

export default CompanyInfoOnboarding;
