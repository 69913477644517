import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const commissionOrderList = async (params: any) => {
  const response = await axiosInterceptor.get(
    API.COMMISSION.COMMISSION_ORDER_LIST,
    {
      params,
    }
  );

  return response;
};

export const commissionOrderStats = async (params: any) => {
  const response = await axiosInterceptor.get(
    API.COMMISSION.COMMISSION_ORDER_STATS,
    {
      params,
    }
  );

  return response;
};

export const monthlyCommissionStatement = async (params: any) => {
  const response = await axiosInterceptor.get(
    API.COMMISSION.MONTHLY_COMMISSION_REPORT,
    {
      params,
    }
  );

  return response;
};

export const allMonthCommissionReport = async (params: any) => {
  const response = await axiosInterceptor.get(API.COMMISSION.ALL_MONTH_REPORT, {
    params,
  });

  return response;
};
