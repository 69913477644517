import {} from '@untitled-ui/icons-react/build/cjs';
import React, { useMemo } from 'react';
import TableCmp from 'src/components/TableCmp';

import LaneHistoryLoading from './LaneHistoryLoading';
import LaneHistoryRow from './LaneHistoryRow';

const LaneHistory = () => {
  const LoadBoardCells = useMemo(
    () => [
      {
        id: 'customers',
        name: 'Customers',
        sortable: true,
      },
      {
        id: 'address',
        name: 'Address',
        sortable: true,
      },
      {
        id: 'totalunits',
        name: 'Total units',
        sortable: true,
      },
      {
        id: 'totalweight',
        name: 'Total Weight',
        sortable: true,
      },
      {
        id: 'ordercreation',
        name: 'Order Creation',
        sortable: true,
      },
      {
        id: 'totalcost',
        name: 'Total Cost',
        sortable: true,
      },

      {
        id: 'totalprice',
        name: 'Total Price',
      },
      {
        id: 'margin',
        name: 'Margin',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  return (
    <>
      <div className="h-full lg:min-h-[122px] w-full border-t border-gray100">
        <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
          <TableCmp
            headCells={LoadBoardCells}
            tableDataArr={[{}]}
            TableLoaderRowCmp={LaneHistoryLoading}
            TableRowCmp={LaneHistoryRow}
            params={{}}
          />
        </div>

        {/* <NotFoundUI
          title="No Quote Found"
          desc="There are no rates for current quote"
          containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
        /> */}
      </div>
    </>
  );
};

export default LaneHistory;
