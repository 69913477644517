import {
  ArrowLeft,
  ArrowRight,
  CalendarDate,
  ChevronDown,
  SearchLg,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
// import CustomPagination from 'src/components/CustomPagination';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { USER_ROLE } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { monthlyCommissionStatement } from 'src/services/CommissionReportService';
import { useRolePermission } from 'src/utils/CommonFunctions';

import MonthlyCommissionReportLoadingRow from './MonthlyCommissionReportLoadingRow';
import MonthlyCommissionReportRow from './MonthlyCommissionReportRow';
// import TableCmp from 'src/components/TableCmp';

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 10,
  month: '07',
  year: '2024',
  targetMonth: moment().format('YYYY-MM'),
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const MonthlyCommissionReport = () => {
  const navigate = useNavigate();
  const { id: reportId } = useParams<{ id: any }>();
  const [params, setParams] = useState(initParams);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredUserData, setFilteredUserData] = useState<any[]>([]);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [isOpenMonthPicker, setIsOpenDropDown] = useState<boolean>(false);
  const [month, setMonth] = useState<any>(new Date());
  const [dateRange, setDateRange] = useState<any>(initParams.targetMonth);
  const { hasRoleV2 } = useRolePermission();
  const isCurrentMonth = moment(dateRange, 'YYYY-MM').isSame(moment(), 'month');
  const [total, setTotal] = useState(0);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'User',
        sortable: true,
        visible: true,
      },
      {
        id: 'scope',
        name: 'Total Margin',
        sortable: true,
        visible: true,
      },
      {
        id: 'provider',
        name: 'Avg. Margin Per Load',
        sortable: true,
        visible: true,
      },
      {
        id: 'rateCount',
        name: 'No. of Loads delivered',
        sortable: true,
        visible: true,
      },
      {
        id: 'quoteCount',
        name: 'Margin Percentage',
        sortable: true,
        visible: true,
      },
      {
        id: 'quoteCount',
        name: 'Active Claims',
        sortable: true,
        visible: true,
      },
      {
        id: 'quoteCount',
        name: 'Active Disputes',
        sortable: true,
        visible: true,
      },
      {
        id: 'quoteCount',
        name: 'Conflicts',
        sortable: true,
        visible: true,
      },
    ],
    []
  );

  useEffect(() => {
    setIsLoading(true);
    setUser([]);
    setFilteredUserData([]);

    monthlyCommissionStatement({ ...params, reportId: reportId }).then(
      (response: any) => {
        setUser(response.data ?? []);
        setTotal(response.total ?? 0);
        setFilteredUserData(response.data ?? []);
        setIsLoading(false);
      }
    );
  }, [params]);

  const updateDateRange = (newDate: moment.Moment) => {
    const formattedDate = newDate.format('YYYY-MM');
    setDateRange(formattedDate);
    setParams((prevParams) => ({
      ...prevParams,
      month: newDate.format('MM'),
      year: newDate.format('YYYY'),
      targetMonth: formattedDate,
    }));
  };

  const searchDebounce = useCallback(
    debounce(
      (search: string) =>
        setParams((old: any) => ({ ...old, ...{ search, page: 1 } })),
      700
    ),
    []
  );

  const getFilterRange = (type: string) => {
    if (type === 'prev') {
      const prevMonth = moment(dateRange, 'YYYY-MM').subtract(1, 'months');
      updateDateRange(prevMonth);
    }

    if (type === 'next') {
      const nextMonth = moment(dateRange, 'YYYY-MM').add(1, 'months');

      if (hasRoleV2(USER_ROLE.USER)) {
        if (
          nextMonth.isSame(moment(), 'month') ||
          nextMonth.isSame(moment().add(1, 'months'), 'month')
        ) {
          updateDateRange(nextMonth);
        }
      } else {
        updateDateRange(nextMonth);
      }
    }
  };

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Commission Reports"
            desc="View and manage your commission reports here."
            mainContainerClassName="md:flex-nowrap"
          />
        }
      >
        <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className={`table-title-wrap`}>
                <h5 className="table-title">
                  {/* {params.type === 'common' ? 'Common LTL' : 'Private'} */}
                  Commission Statement (August, 2024)
                </h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  // mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {total} {total > 1 ? 'Reports' : 'Report'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Hear is commission report of all users.
              </p>
            </div>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <InputText
                inputName="searchOrder"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={searchValue}
                inputType="text"
                isClearable={true}
                parentClassName="table-searchInput"
                onChangeFunc={(e) => {
                  setSearchValue(e.target.value);
                  searchDebounce(e.target.value);
                }}
              />
            </div>
            <div className="table-datepicker-v2 cursor-pointer">
              <div className="w-full xl:px-3.5 px-3 lg:py-[9px] py-2 rounded-lg shadow border border-gray-300 lg:h-[36px] h-[34px]  justify-between items-center gap-1.5 flex bg-white  hover:border-grayText transition-all">
                <ArrowLeft
                  className="xs:w-4 xs:h-4 w-3 h-3 cursor-pointer flex-none"
                  onClick={() => getFilterRange('prev')}
                />

                <div
                  className="flex flex-1 justify-center items-center"
                  onClick={() => {
                    setIsOpenDropDown(true);
                  }}
                >
                  <CalendarDate className="w-4 h-4 mr-2" />
                  <div className="text-textSecondary text-xs font-medium leading-none">
                    {' '}
                    {moment(dateRange, 'YYYY-MM').format('MMMM YYYY')}
                  </div>
                </div>

                <ArrowRight
                  className={`xs:w-4 xs:h-4 w-3 h-3 flex-none ${
                    isCurrentMonth
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (!isCurrentMonth) {
                      getFilterRange('next');
                    }
                  }}
                />
              </div>
              {isOpenMonthPicker && (
                <div
                  className="shadow-lg mt-2.5 mb-2 z-[2] rounded-lg border border-utilityGray200 absolute bg-white datepicker-v2"
                  ref={datePickerRef}
                >
                  <DateSelect
                    inputName="pickupDate"
                    selected={month}
                    showMonthYearPicker
                    dateFormat="MMMM-yyyy"
                    onChangeFunc={(e: any) => {
                      setMonth(moment(e.value, 'MMM YYYY').toDate());
                      setDateRange(
                        moment(e.value, 'MMM YYYY').format('YYYY-MM')
                      );
                      setParams((prevParams) => ({
                        ...prevParams,
                        month: moment(e.value, 'MMM YYYY').format('MM'),
                        year: moment(e.value, 'MMM YYYY').format('YYYY'),
                        targetMonth: moment(e.value, 'MMM YYYY').format(
                          'YYYY-MM'
                        ),
                      }));
                      setIsOpenDropDown(false);
                    }}
                    inline={isOpenMonthPicker}
                    icon={<ChevronDown className="w-5 h-5" />}
                    maxDate={moment('2024-09-01', 'YYYY-MM-DD').toDate()}
                  />
                </div>
              )}
            </div>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                placeholder="10"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>

            <Link
              to={ROUTES.SALES_COMMISSION_REPORT}
              className=" max-sm:w-[calc(50%_-_6px)]"
            >
              <ButtonCmp
                type="submit"
                className="btn_secondary_black sm:min-w-20 min-w-full "
              >
                Back
              </ButtonCmp>
            </Link>
          </div>
          <div
            data-test-id="userTest"
            className="h-full lg:min-h-[122px] w-full border-t border-gray100 "
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                tableDataArr={filteredUserData}
                TableLoaderRowCmp={MonthlyCommissionReportLoadingRow}
                TableRowCmp={MonthlyCommissionReportRow}
                params={params}
                setParams={setParams}
                // tableRowCmpProps={{
                //   handleActionType: handleActionType,
                // }}
                isTableDataLoading={false}
                // numberOfSkeletonRows={params.limit}
                isTableRowClickable={true}
                onRowClick={(rowData: any) => {
                  navigate(
                    `${PATH.COMMISSION_REPORT}/sales-rep/${rowData?.salesRepId}`
                  );
                }}
              />
            </div>
            {!user.length && !isLoading && (
              <NotFoundUI
                title="No User Found"
                desc="There are no data for user."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={filteredUserData.length}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </PageSectionLayout>
    </>
  );
};

export default MonthlyCommissionReport;
