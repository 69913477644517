import { Attachment01, Send01 } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import { TIMEZONE } from 'src/constants/common';
import { AuthContext } from 'src/context/AuthContext';
import {
  getChatMessageList,
  sendMessage,
} from 'src/services/ClaimAndDisputeService';
import { getShortName } from 'src/utils/CommonFunctions';

import InternalChatCard from './InternalChatCard';
import InternalChatsSkeletonLoader from './InternalChatsSkeletonLoader';

const InternalChat = ({
  data,
  isClaim,
  typingUsers,
  messages,
  members,
  setMessages,
  setMembers,
}: any) => {
  const [onlineMembers, setOnlineMembers] = useState<null | number>(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateMessageLoading, setIsCreateMessageLoading] = useState(false);
  const [isMoreLoading, setIsMoreLoading] = useState(true);
  const [groupID, setGroupID] = useState<number | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const currentUser = useContext(AuthContext);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;

      setTimeout(() => {
        const inputElement = document.getElementById('chatInput');

        if (inputElement) {
          inputElement.focus();
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (page === 2) scrollToBottom();
  }, [messages, page]);

  const memberNames = members.map((member: any) => member.firstName).join(', ');

  const { handleSubmit, control, reset, watch } = useForm({});

  const messageWatcher = watch('message');

  const getClaimAndDisputeChatMessageList = (
    orderID: number,
    entityID: number,
    pageCount: number
  ) => {
    setIsLoading(true);
    setMessages([]);

    getChatMessageList({ orderID, entityID, pageCount, isClaim })
      .then((response: any) => {
        if (response && response.data) {
          const responseData = response.data;
          setMembers(responseData.members);

          setGroupID(responseData.groupId);

          if (
            responseData.messages.length === 0 ||
            responseData.messages.length < 10
          ) {
            setIsMoreLoading(false);
          }

          setMessages((prevMessages: any) => [
            ...responseData.messages,
            ...prevMessages,
          ]);

          setPage((prevPage) => prevPage + 1);

          if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
              chatContainerRef.current.scrollHeight -
              chatContainerRef.current.clientHeight;
          }
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const countOfOnlineMembers = members?.filter(
      (member: any) => member.user_status === 'online'
    )?.length;
    setOnlineMembers(countOfOnlineMembers);
  }, [members]);

  const fetchMoreMessages = async (pageCount: any) => {
    try {
      if (!isLoading && isMoreLoading) {
        getClaimAndDisputeChatMessageList(data.orderID, data.id, pageCount);
      }
    } catch (error) {
      console.error('Failed to load older messages:', error);
    }
  };

  const debouncedFetchMoreMessages = useCallback(
    debounce(fetchMoreMessages, 300),
    [page]
  );

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const handleWheel = () => {
      if (chatContainer && chatContainer.scrollTop === 0 && !isLoading) {
        if (data.orderID) {
          debouncedFetchMoreMessages(page);
        }
      }
    };

    if (chatContainer) {
      chatContainer.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('wheel', handleWheel);
      }
      debouncedFetchMoreMessages.cancel();
    };
  }, [data.orderID, page, isLoading]);

  useEffect(() => {
    if (data.orderID) {
      getClaimAndDisputeChatMessageList(data.orderID, data.id, page);
    }
  }, [data.orderID]);

  const sendMessageToUsers = (formData: any) => {
    const id = data.id;
    setIsCreateMessageLoading(true);

    sendMessage({
      id,
      orderID: data.orderID,
      groupId: groupID,
      senderID: currentUser.currentUser.id,
      content: formData,
      type: isClaim ? 'claim' : 'dispute',
    })
      .then((response: any) => {
        if (response && response.data) {
          reset({ message: '' });
          scrollToBottom();
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsCreateMessageLoading(false);
      });
  };

  const HandleChange = async (formData: any) => {
    if (formData.message && formData.message != '') {
      sendMessageToUsers(formData);
    }
  };

  const getFormattedDateTime = (date: any, convertTimezone = false) => {
    let momentDate = moment(date);

    if (convertTimezone) {
      momentDate = momentDate.utc(date).tz(TIMEZONE);
    }

    return momentDate.fromNow();
  };

  return (
    <div className="flex flex-col flex-1 overflow-y-auto">
      <div className="p-5 border-b border-utilityGray200">
        <div className="flex items-center">
          <h6 className="mr-1 text-grayLight900 text-md font-semibold">
            Internal Chat
          </h6>
          <BadgeCmp
            style="modern"
            type="success"
            mainClassName={`${isLoading ? 'custom-loading' : ''}`}
          >
            {onlineMembers && onlineMembers} Online
          </BadgeCmp>
        </div>
        <div className="flex flex-wrap text-grayLight600 font-normal text-xs">
          {!isLoading ? (
            memberNames && memberNames
          ) : (
            <>
              <span className="custom-loading">Zaheer,</span>
              <span className="custom-loading"> Jet,</span>
              <span className="custom-loading"> Subir,</span>
              <span className="custom-loading"> Subir,</span>
              <span className="custom-loading"> Jordan</span>
            </>
          )}
        </div>
      </div>
      <div
        className="p-5 flex-1 overflow-auto custom-scrollbar-v2 flex"
        ref={chatContainerRef}
      >
        <ul className="w-full mt-auto">
          {isLoading && <InternalChatsSkeletonLoader />}

          {messages &&
            messages.length > 0 &&
            messages.map((message: any, index: number) => (
              <InternalChatCard
                internalChat={message}
                currentUser={currentUser}
                getFormattedDateTime={getFormattedDateTime}
                key={index}
              />
            ))}
          {typingUsers.length > 0 &&
            typingUsers.map((user: any, index: number) => (
              <li className="flex mt-4 gap-3 max-w-[305px]" key={index}>
                <div className="w-8 h-8 flex-none relative">
                  {user.imageUrl ? (
                    <img
                      src={user.imageUrl + user.image}
                      className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary"
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none  mr-2">
                      {getShortName(`${user?.firstName} ${user?.lastName}`)}
                    </div>
                  )}
                  <span className="border-[1.5px] border-white rounded-full bg-successSecondary w-2.5 h-2.5 absolute bottom-0 right-0"></span>
                </div>
                <div>
                  <div className="flex justify-between gap-2 items-center w-full mb-1.5">
                    <p className="text-textSecondary text-xs">
                      {user.firstName} {user.lastName}
                    </p>
                    <p className="text-grayLight600 font-normal text-xs">
                      Just now
                    </p>
                  </div>

                  <div className="rounded-lg rounded-tl-none border border-utilityGray200 p-2.5 bg-white text-grayLight900 font-normal text-sm mb-2 w-fit">
                    <div className="flex gap-1 typing-animation min-h-[18px] items-center">
                      <div className="w-1 h-1 rounded-full bg-grayLight600"></div>
                      <div className="w-1 h-1 rounded-full  bg-gray400"></div>
                      <div className="w-1 h-1 rounded-full bg-grayLight600"></div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <form onSubmit={handleSubmit(HandleChange)}>
        <div className="xl:p-4 p-3 flex gap-2 border-t border-utilityGray200">
          <Controller
            name="message"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="message"
                placeholder="Message"
                id="chatInput"
                className=""
                value={value}
                onChangeFunc={onChange}
                parentClassName="flex-1"
              />
            )}
          />
          <ButtonCmp className="btn_secondary_black lg:!px-[9px] !px-2">
            <Attachment01 className="w-4 h-4" />
          </ButtonCmp>
          <ButtonCmp
            className="btn_primary lg:!px-[9px] !px-2"
            disabled={!messageWatcher || isLoading || isCreateMessageLoading}
            loading={isCreateMessageLoading}
          >
            <Send01 className="w-4 h-4" />
          </ButtonCmp>
        </div>
      </form>
    </div>
  );
};

export default InternalChat;
