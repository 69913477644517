import { Expand03 } from '@untitled-ui/icons-react/build/cjs';
import mapboxgl from 'mapbox-gl';
import React, { useContext, useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Toggle from 'src/components/Toggle/Toggle';
import { BasicContext } from 'src/context/BasicContext';
import { getMapService } from 'src/services/IntegrationService';

import ActiveShipments from './ActiveShipments';
import Customers from './Customers';
import LaneOverview from './LaneOverview';
import MapBox from './MapBox';
import TrimbleMap from './TrimbleMap';

const MapIt = () => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  // const googleMapsApiKey = window?.GOOGLE_API_KEY;
  const { mapService, setMapService, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);

  const [customers, setCustomers] = useState<any>();
  const [searchResult, setSearchResult] = useState<any>(null);
  const [isDisplayDetail, setIsDisplayDetail] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const [mapFilters, setMapFilters] = useState<any>({
    isDisplayCustomers: false,
    isDisplayTransit: false,
  });

  const handleToggle = (event: any) => {
    const { checked, name } = event.target;
    setIsDisplayDetail(true);
    setMapFilters((old: any) => ({ ...old, [name]: checked }));
  };

  useEffect(() => {
    console.log('isDisplayDetail :>> ', isDisplayDetail);
  }, [isDisplayDetail]);

  useEffect(() => {
    getMapService().then((response: any) => {
      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });
  }, []);

  return (
    <PageSectionLayout
      header={<></>}
      // header={
      //   <Header
      //     title="Zone Management"
      //     desc="Track and visualize your shipments with precision."
      //   />
      // }
      headerClassName="!p-0"
      contentClassName="!p-0"
    >
      <div className="relative h-full w-full geography-map">
        {mapService === 'trimble_map' ? (
          <TrimbleMap
            setHasSearched={setHasSearched}
            setSearchResult={setSearchResult}
            isDisplayCustomers={mapFilters?.isDisplayCustomers}
            setIsDisplayDetail={setIsDisplayDetail}
            setCustomers={setCustomers}
          />
        ) : (
          <MapBox
            setHasSearched={setHasSearched}
            setSearchResult={setSearchResult}
            isDisplayCustomers={mapFilters?.isDisplayCustomers}
            setIsDisplayDetail={setIsDisplayDetail}
            setCustomers={setCustomers}
          />
        )}
        {isDisplayDetail ? (
          hasSearched ? (
            <LaneOverview
              handleClose={() => setIsDisplayDetail(false)}
              searchResult={searchResult}
            />
          ) : (
            <>
              {isDisplayDetail && mapFilters.isDisplayCustomers && (
                <Customers
                  customers={customers}
                  handleClose={() => setIsDisplayDetail(false)}
                />
              )}
              {isDisplayDetail && mapFilters.isDisplayTransit && (
                <ActiveShipments
                  handleClose={() => {
                    setIsDisplayDetail(false);
                  }}
                />
              )}
            </>
          )
        ) : (
          <div className="fixed w-10 p-2 z-[2] cursor-pointer rounded-md border bottom-10 right-10 bg-white">
            <Expand03
              className="w-5 h-5 text-primary cursor-pointer"
              onClick={() => setIsDisplayDetail(true)}
            />
          </div>
        )}
        <div className="rounded-lg border border-utilityGray200  p-3 shadow-lg bg-white absolute left-4 bottom-4 z-[2] min-w-[300px]">
          <div className="flex items-center justify-between gap-2 mb-[18px]">
            <span className="text-xs font-normal text-grayLight900">
              Display Customers
            </span>
            <Toggle
              id="display-customers-toggle"
              name="isDisplayCustomers"
              labelClassName="mr-2"
              isChecked={mapFilters.isDisplayCustomers}
              onChange={handleToggle}
            />
          </div>
          <div className="flex items-center justify-between gap-2">
            <span className="text-xs font-normal text-grayLight900">
              Display in-transit shipments
            </span>
            <Toggle
              id="display-transit-toggle"
              name="isDisplayTransit"
              labelClassName="mr-2"
              isChecked={mapFilters.isDisplayTransit}
              onChange={handleToggle}
            />
          </div>
        </div>
      </div>
    </PageSectionLayout>
  );
};

export default MapIt;
