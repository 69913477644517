import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import DeleteModal from 'src/components/DeleteModal';
import TableCmp from 'src/components/TableCmp';
import { AuthContext } from 'src/context/AuthContext';
import { NotificationsContext } from 'src/context/NotificationsContext';
import {
  deleteClaimDisputeTask,
  getClaimDisputeTaskList,
} from 'src/services/ClaimAndDisputeService';
import WalToast from 'src/utils/WalToast';

import DetailsAction from './DetailsAction';
import DetailsActionFooter from './DetailsActionFooter';
import DetailsActionLoading from './DetailsActionLoading';

interface IProps {
  claimId?: number;
  disputeId?: number;
  claim?: any;
  dispute?: any;
  userOptions: any;
}

export const initParams = {
  sortType: 'desc',
  sortField: 'taskName',
};

const ActionItem = ({
  claimId,
  disputeId,
  claim,
  dispute,
  userOptions,
}: IProps) => {
  const [params, setParams] = useState(initParams);
  const [claimDisputeTask, setClaimDisputeTask] = useState<any>([]);
  const [isClaimDisputeTaskLoading, setIsClaimDisputeTaskLoading] =
    useState(false);
  const [claimDisputeTotal, setClaimDisputeTotal] = useState<any>([]);
  const [isDeleteTask, setIsDeleteTask] = useState(false);
  const [isDeleteID, setIsDeleteID] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { currentUser } = useContext(AuthContext);
  const { entityType, entityId } = useContext(NotificationsContext);

  const getClaimDisputeTask = () => {
    setIsClaimDisputeTaskLoading(true);
    setClaimDisputeTask([]);

    getClaimDisputeTaskList({
      ...params,
      claimID: claimId!,
      disputeID: disputeId!,
    })
      .then((response: any) => {
        if (response && response.claimDisputeActions) {
          const responseData = response.claimDisputeActions;
          setClaimDisputeTask(responseData);
          setClaimDisputeTotal(response?.total ?? 0);
          setIsClaimDisputeTaskLoading(false);
        }
      })
      .catch((e: any) => {
        console.error(e);
        WalToast.error('Something went wrong while fetching task list');
      });
  };

  useEffect(() => {
    getClaimDisputeTask();
  }, [params]);

  const headCells = useMemo(
    () => [
      {
        id: 'taskName',
        name: 'Task Name',
        sortable: true,
      },
      {
        id: 'assignedto',
        name: 'Assigned To',
        sortable: true,
      },
      {
        id: 'urgency',
        name: 'Urgency',
        sortable: true,
      },
      {
        id: 'dueDate',
        name: 'Due Date',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  useEffect(() => {
    if (
      (entityType === 'claim_action' && entityId === claimId) ||
      (entityType === 'dispute_action' && entityId === disputeId)
    ) {
      getClaimDisputeTask();
    }
  }, [entityType, entityId]);

  const updateTaskToList = (updatedTask: any) => {
    const updatedTasks = claimDisputeTask.map((cdTask: any) =>
      cdTask.id === updatedTask.id ? { ...cdTask, ...updatedTask } : cdTask
    );
    setClaimDisputeTask(updatedTasks);
  };

  const addTaskToList = (addedTask: any) => {
    const updatedTasks = [...claimDisputeTask, addedTask];
    setClaimDisputeTask(updatedTasks);
    setClaimDisputeTotal((oldValue: number) => oldValue + 1);
  };

  const removeTaskFromList = (deletedTaskId: any) => {
    const updatedTasks = claimDisputeTask
      .map((cdTask: any) => (cdTask.id === deletedTaskId ? false : cdTask))
      .filter((e: any) => e);
    setClaimDisputeTask(updatedTasks);
    setClaimDisputeTotal((oldValue: number) => oldValue - 1);
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status && isDeleteID) {
        deleteClaimDisputeTask(isDeleteID)
          .then(() => {
            WalToast.success(`Task deleted successfully`);
            setIsDeleteTask(false);
            setIsDeleteID(null);
            removeTaskFromList(isDeleteID);
          })
          .catch((e) => {
            WalToast.error(
              e?.response?.data?.message ??
                'Something went wrong while deleting task.'
            );
          });
      } else {
        setIsDeleteTask(false);
        setIsDeleteID(null);
      }
    },
    [isDeleteID]
  );

  return (
    <>
      <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col">
        <div className="table-top-header">
          <div className="table-left-wrap">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">Action Items</h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={`${
                  isClaimDisputeTaskLoading ? 'custom-loading' : ''
                }`}
              >
                {claimDisputeTotal} {claimDisputeTotal > 1 ? 'tasks' : 'task'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              {`View and create actions items that must be completed to resolve the 
                ${claim ? 'claim' : ''}${dispute ? 'dispute' : ''}.
              `}
            </p>
          </div>
        </div>
        <div className="h-full w-full border-t border-gray100 ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <TableCmp
              headCells={headCells}
              tableDataArr={claimDisputeTask}
              TableLoaderRowCmp={DetailsActionLoading}
              TableRowCmp={DetailsAction}
              tableRowCmpProps={{
                updateTaskToList: updateTaskToList,
                setIsDeleteTask: setIsDeleteTask,
                setIsDeleteID: setIsDeleteID,
                userOptions: userOptions,
                searchTerm: searchTerm,
                setSearchTerm: setSearchTerm,
                currentUser: currentUser,
              }}
              TableFooterRowCmp={DetailsActionFooter}
              tableFooterRowCmpProps={{
                addTaskToList: addTaskToList,
                claimID: claim?.id,
                disputeID: dispute?.id,
              }}
              isTableDataLoading={isClaimDisputeTaskLoading}
              isTableFooterShow={claimDisputeTask?.length > 0 ? false : true}
              footerDataArr={[{}]}
              params={params}
              setParams={setParams}
              numberOfSkeletonRows={3}
            />
          </div>
        </div>
      </div>
      {isDeleteTask && (
        <DeleteModal
          moduleName={`task from ${claim ? 'claim' : ''}${
            dispute ? 'dispute' : ''
          }`}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
};

export default ActionItem;
