import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import {
  getInternalNotes,
  createInternalNotes,
} from 'src/services/ClaimAndDisputeService';

import InternalNoteCard from './InternalNoteCard';
import InternalNotesSkeletonLoader from './InternalNotesSkeletonLoader';

interface IProps {
  data: any;
  isClaim: boolean;
  notesCount: null | number;
  setNotesCount?: any;
  internalNotes: any;
  setInternalNotes: any;
}

const initParams = {
  page: 1,
  limit: 10,
};

const InternalNotes = ({
  data,
  isClaim,
  notesCount,
  internalNotes,
  setInternalNotes,
}: IProps) => {
  const [params, setParams] = useState({ ...initParams, isClaim });
  const [isInternalNotesLoading, setIsInternalNotesLoading] = useState(false);
  const [isCreateInternalNoteLoading, setIsCreateInternalNoteLoading] =
    useState(false);
  const [isEndReached, setIsEndReached] = useState(false);

  const { handleSubmit, control, reset, watch } = useForm({});
  const noteContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (noteContainerRef.current) {
      noteContainerRef.current.scrollTop =
        noteContainerRef.current.scrollHeight;

      setTimeout(() => {
        const inputElement = document.getElementById('noteInput');

        if (inputElement) {
          inputElement.focus();
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (params.page === 2) scrollToBottom();
  }, [internalNotes, params]);

  const getClaimInternalNotesList = (param: any) => {
    setIsInternalNotesLoading(true);
    setInternalNotes([]);

    getInternalNotes(data?.id, param)
      .then((response: any) => {
        setInternalNotes((prevNotes: any) => [...response.data, ...prevNotes]);
        setIsInternalNotesLoading(false);
        setParams((old) => ({ ...old, ...{ page: params.page + 1 } }));
        setIsEndReached(response?.data?.length < 10);
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (params)
      if (data?.id) {
        getClaimInternalNotesList(params);
      }
  }, []);

  const fetchMoreNotes = async (formData: any) => {
    try {
      if (!isInternalNotesLoading && !isEndReached) {
        getClaimInternalNotesList(formData);
      }
    } catch (error) {
      console.error('Failed to load older messages:', error);
    }
  };

  const debouncedFetchMoreMessages = useCallback(
    debounce(fetchMoreNotes, 300),
    [params]
  );

  useEffect(() => {
    const noteContainer = noteContainerRef.current;

    const handleWheel = () => {
      if (
        noteContainer &&
        noteContainer.scrollTop === 0 &&
        !isInternalNotesLoading
      ) {
        if (data.orderID) {
          debouncedFetchMoreMessages(params);
        }
      }
    };

    if (noteContainer) {
      noteContainer.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (noteContainer) {
        noteContainer.removeEventListener('wheel', handleWheel);
      }
      debouncedFetchMoreMessages.cancel();
    };
  }, [data.orderID, params, isInternalNotesLoading]);

  const handleCreateNote = (formData: any) => {
    if (formData.note && formData.note != '') {
      setIsEndReached(false);
      setIsCreateInternalNoteLoading(true);
      createInternalNotes(data?.id, {
        content: formData.note,
        isClaim: isClaim,
      })
        .then((response: any) => {
          if (response && response.data) {
            reset({ note: '' });
            scrollToBottom();
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsCreateInternalNoteLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-col flex-1 overflow-y-auto">
      <div className="p-5 border-b border-utilityGray200">
        <div className="flex items-center">
          <h6 className="mr-1 text-grayLight900 text-md font-semibold ">
            Internal Notes
          </h6>
          <BadgeCmp
            style="modern"
            type="success"
            mainClassName={`${isInternalNotesLoading ? 'custom-loading' : ''}`}
          >
            {notesCount} {notesCount && notesCount > 1 ? 'notes' : 'notes'}
          </BadgeCmp>
        </div>
      </div>
      <div
        className="flex-1 overflow-auto custom-scrollbar-v2 flex "
        ref={noteContainerRef}
      >
        <ul className="w-full mt-auto">
          {isInternalNotesLoading &&
            Array(10)
              .fill(null)
              .map((_, index) => <InternalNotesSkeletonLoader key={index} />)}

          {internalNotes?.map((internalNote: any) => (
            <InternalNoteCard
              key={internalNote?.id}
              internalNote={internalNote}
            />
          ))}
        </ul>
      </div>

      <form
        className="p-4 flex gap-3 border-t border-utilityGray200"
        onSubmit={handleSubmit(handleCreateNote)}
      >
        <Controller
          name="note"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="note"
              placeholder="Add an internal note"
              className=""
              id="noteInput"
              value={value}
              parentClassName="flex-1"
              onChangeFunc={onChange}
            />
          )}
        />
        <ButtonCmp
          className="btn_primary lg:!px-[9px] !px-2"
          disabled={!watch('note') || isCreateInternalNoteLoading}
          loading={isCreateInternalNoteLoading}
        >
          <Plus className="w-4 h-4 text-white" />
        </ButtonCmp>
      </form>
    </div>
  );
};

export default InternalNotes;
