import {
  AlertCircle,
  ImagePlus,
  UserPlus01,
} from '@untitled-ui/icons-react/build/cjs';
import { CountryCode } from 'libphonenumber-js';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ButtonCmp from 'src/components/ButtonCmp';
import CropperModal from 'src/components/CropperModal/CropperModal';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import { MAX_FILE_SIZE } from 'src/constants/common';
import WalToast from 'src/utils/WalToast';

import CommonModal from '../../components/CommonModal';
import ErrorMsg from '../../components/errorMsg';
import InputText from '../../components/InputText/InputText';
import SelectBox from '../../components/SelectBox/SelectBox';
import { listRole } from '../../services/RoleService';
import { updateUser } from '../../services/TeamMemberService';
import { listAllTeam } from '../../services/TeamService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
  getSelectBoxOptions,
  getShortName,
  onError,
  useRolePermission,
} from '../../utils/CommonFunctions';

// const formatPhoneNumber = (value: any) => {
//     if (!value) return value;

//     // Cleans the input for any non-digit characters.
//     const phoneNumber = value.replace(/[^\d]/g, "");

//     // Slices the input to match sections of the format +1 (XXX) XXX-XXXX
//     const phoneNumberLength = phoneNumber.length;
//     if (phoneNumberLength < 4) return `+1 (${phoneNumber}`;
//     if (phoneNumberLength < 7) return `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
//     return `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
// };

const initSelectFormData = {
  role: null,
};
const initFormData = {
  firstName: '',
  lastName: '',
  email: '',
  jobRole: '',
  role: null,
  image: null,
  team: null,
  phone: '',
};
const initValidForm = {
  firstName: true,
  lastName: true,
  email: true,
  isEmailValid: true,
  jobRole: true,
  role: true,
  team: true,
  phone: true,
  isPhoneValid: true,
  image: true,
  isImageSizeValid: true,
};

interface IProps {
  team: any;
  mode: any;
  handleClose: any;
  setIsRefresh: any;
  handleCreateNewTeam?: (inprogressFormData: any) => void;
  inProgressFormData?: any;
}

const CreateUpdateMemberModal = ({
  team,
  mode,
  handleClose,
  setIsRefresh,
  handleCreateNewTeam,
  inProgressFormData,
}: IProps) => {
  const { hasRoleV2, hasPermissionV2 } = useRolePermission();

  const [roleOptions, setRoleOptions] = useState([]);
  const [upload, setUpload] = useState(false);
  const [isRoleLoading, setIsRoleLoading] = useState(true);
  const [teamOptions, setTeamOptions] = useState([]);
  const [isTeamListLoading, setIsTeamListLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectFormData, setSelectFormData] = useState<any>(initSelectFormData);
  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState(initValidForm);
  const [displayImage, setDisplayImage] = useState<any>(
    team.imageUrl + team.image
  );

  const getRoleSelectBoxOptions = (
    data: Array<any>,
    permissions: string,
    value: string = 'id',
    label: string = 'name'
  ) => {
    if (!Array.isArray(data)) {
      data = [data];
    }

    return data.map((dataRec: any) => ({
      value: dataRec[value],
      label: dataRec[label],
      [permissions]: dataRec[permissions],
    }));
  };

  useEffect(() => {
    if (Object.keys(team).length) {
      setFormData({
        firstName: team.firstName || '',
        lastName: team.lastName || '',
        email: team.email || '',
        jobRole: team.jobRole || '',
        role: team.role.id || null,
        phone: team?.phone || '',
        image: team.image ? team.imageUrl + team.image : '',
      });
    }

    if (inProgressFormData) {
      setFormData(inProgressFormData);

      if (inProgressFormData.image) {
        setDisplayImage(URL.createObjectURL(inProgressFormData.image));
      }
    }
    listAllTeam()
      .then((response: any) => {
        const teamSelectBoxOptions: any = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon'
        );
        setTeamOptions(teamSelectBoxOptions);
      })
      .catch(console.log)
      .finally(() => setIsTeamListLoading(false));
    listRole()
      .then((response: any) => {
        const roleSelectBoxOptions: any = getRoleSelectBoxOptions(
          response.data,
          'permissions',
          'id',
          'displayName'
        );
        setRoleOptions(roleSelectBoxOptions);

        if (team?.role) {
          const selectedRoleOptionRec = roleSelectBoxOptions.find(
            (rSBox: any) => rSBox.label === team.role.displayName
          );
          setSelectFormData({ role: selectedRoleOptionRec });
        }
      })
      .catch(console.log)
      .finally(() => setIsRoleLoading(false));

    return () => {
      setFormData(initFormData);
      setValidForm(initValidForm);
      setSelectFormData(initSelectFormData);
      setDisplayImage(null);
    };
  }, []);

  useEffect(() => {
    if (inProgressFormData?.role) {
      setSelectFormData({
        ...selectFormData,
        role: roleOptions.find(
          (to: any) => to.value === inProgressFormData.role
        ),
      });
    }
  }, [roleOptions]);

  const imageUpload = (data: any) => {
    const appLogoImage = data;
    const fileInfo = convertBase64ToFile(appLogoImage);
    setFormData((formDataRec: any) => ({
      ...formDataRec,
      image: fileInfo?.convertedFile, // This sets the new image and keeps the rest of the formData as it is
    }));
  };

  useEffect(() => {
    if (inProgressFormData?.team) {
      setSelectFormData({
        ...selectFormData,
        team: teamOptions.find(
          (to: any) => to.value === inProgressFormData.team
        ),
      });
    }
  }, [teamOptions]);

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      firstName: true,
      lastName: true,
      email: true,
      isEmailValid: true,
      jobRole: true,
      role: true,
      team: true,
      phone: true,
      isPhoneValid: true,
      image: true,
      isImageSizeValid: validForm.isImageSizeValid,
    };
    const emailRegex = /[a-zA-Z0-9._%+-]+@westernalliancelogistics+\.com$/i;

    if (formData.firstName === '' || formData.firstName === null) {
      validFormData.firstName = false;
      valid = false;
    }

    if (formData.lastName === '' || formData.lastName === null) {
      validFormData.lastName = false;
      valid = false;
    }

    if (formData.email === '' || formData.email === null) {
      validFormData.email = false;
      valid = false;
    }

    if (formData.email && !emailRegex.test(formData.email)) {
      validFormData.isEmailValid = false;
      valid = false;
    }

    if (formData.jobRole === '' || formData.jobRole === null) {
      validFormData.jobRole = false;
      valid = false;
    }

    if (formData.role === '' || formData.role === null) {
      validFormData.role = false;
      valid = false;
    } else {
      const roleOption: any = roleOptions.find(
        (ro: any) => ro?.value === formData.role
      );

      if (roleOptions && roleOption?.label === 'Admin') {
        if (formData.phone === '' || formData.phone === null) {
          validFormData.phone = false;
          valid = false;
        } else {
          // var regex = /^\+1 \(514\) [0-9]{3}-[0-9]{4}$/; // Regex pattern for +1 (514) XXX-XXXX

          if (!validForm.isPhoneValid) {
            validFormData.isPhoneValid = validForm.isPhoneValid;
            valid = false;
          }
        }
      }
    }

    if (
      (formData.team === '' || formData.team === null) &&
      mode === 'user-create'
    ) {
      const roleOption: any = roleOptions.find(
        (ro: any) => ro?.value === formData.role
      );

      if (roleOption?.label !== 'Admin') {
        validFormData.team = false;
        valid = false;
      }
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const hasAnyFormValueFalse = (object: any) => {
    for (let key in object) {
      if (!object[key]) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!isFormValid() || !hasAnyFormValueFalse(validForm)) {
      return true;
    }
    setIsLoading(true);

    if (mode === 'user-create') {
      // closing with form data and not calling create api
      let formDataUpdated = { ...formData };
      formDataUpdated.teamId = formData?.team?.toString();
      delete formDataUpdated.team;
      const currentRoleOption: any = roleOptions.find(
        (ro: any) => ro.value === formData.role
      );
      formDataUpdated.permissions = currentRoleOption.permissions.map(
        (p: any) => p.id
      );
      handleClose(formDataUpdated);
    } else if (mode === 'user-update') {
      const appendFormData: any = new FormData();
      Object.entries(formData).forEach((data) => {
        const [key, value] = data;

        if (['team'].includes(key)) {
          return;
        }
        appendFormData.append(key, value);
      });

      // pushing image to last
      const imageValueOfFormToAppend = appendFormData.get('image');
      appendFormData.delete('image');

      if (imageValueOfFormToAppend) {
        appendFormData.append('image', imageValueOfFormToAppend);
      }

      updateUser(team.id, appendFormData)
        .then(() => {
          handleClose();
          setIsRefresh(true);
          WalToast.success(
            'Member details update successfully',
            'Your changes have been saved and your profile is live. Your team can make edits.'
          );
        })
        .finally(() => setIsLoading(false))
        .catch((e) => {
          console.log(e);
          WalToast.error(e.response.data.message ?? 'Issue updating member.');
        });
    }
  };

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (name: any) => (event: any) => {
    setSelectFormData({ ...selectFormData, [name]: event });
    setFormData({ ...formData, [name]: event ? event.value : null });
  };

  const handleNumberChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isValid: boolean
  ) => {
    var validFormData = {
      phone: true,
      isPhoneValid: true,
    };
    setFormData({ ...formData, [`phone`]: `+${code}${phone}` });

    if (phone === '') {
      validFormData.phone = false;
    }
    validFormData.isPhoneValid = isValid;
    setValidForm({ ...validForm, ...validFormData });
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setValidForm({ ...validForm, isImageSizeValid: true });
    const file = acceptedFiles[0];

    if (file?.type?.match('image')) {
      setValidForm({ ...validForm, image: true });
      const { result } = checkFileTypeValidation(acceptedFiles, MAX_FILE_SIZE);

      if (result) {
        const reader = new FileReader();

        reader.onloadstart = () => {
          setDisplayImage('');
          setUpload(false);
        };

        reader.onloadend = () => {
          setDisplayImage(reader.result as any);
          // setValue("image", reader.result, { shouldValidate: true });
          setUpload(true);
        };
        reader.readAsDataURL(file);
      } else {
        setValidForm({ ...validForm, isImageSizeValid: false });
      }
    } else {
      setValidForm({ ...validForm, image: false });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
    },
  });

  return (
    <CommonModal
      title={mode === 'user-create' ? 'Invite new member' : 'Edit Member'}
      titleDesc={
        mode === 'user-create'
          ? 'Fill out the details below to invite a new team member.'
          : 'Edit New member for free in less than 5 minutes.'
      }
      handleClose={handleClose}
      headerIcon={<UserPlus01 />}
      secondaryBtnOnClick={() => handleClose(false)}
      size={'max-w-[688px]'}
      primaryBtnText={mode === 'user-create' ? 'Continue' : 'Save changes'}
      primaryBtnOnClick={(e: any) => handleSubmit(e)}
      primaryBtnLoading={isLoading}
      secondaryBtnText="Cancel"
      isOverflow={false}
    >
      <>
        <form className="h-full" onSubmit={handleSubmit}>
          <div className="flex flex-col justify-between h-full">
            <div className="w-full px-4 py-4">
              <div className="mb-4 md:flex">
                <label className="form_label md:mb-0 form_label_center w-40">
                  First name*
                </label>
                <InputText
                  inputName="firstName"
                  placeholder="Enter first name"
                  parentClassName="flex-1"
                  icon={
                    !validForm.firstName ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  maxLength={50}
                  value={formData.firstName}
                  onChangeFunc={handleInputChange}
                  errorText={
                    !validForm.firstName ? 'First name is required' : null
                  }
                />
              </div>
              <div className="mb-4 md:flex">
                <label className="form_label md:mb-0 form_label_center w-40">
                  Last name*
                </label>
                <InputText
                  inputName="lastName"
                  placeholder="Enter last name"
                  parentClassName="flex-1"
                  icon={
                    !validForm.lastName ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  maxLength={50}
                  value={formData.lastName}
                  onChangeFunc={handleInputChange}
                  errorText={
                    !validForm.lastName ? 'Last name is required' : null
                  }
                />
              </div>
              <div className="mb-4 md:flex">
                <label className={`form_label md:mb-0 form_label_center w-40`}>
                  Account type*
                </label>
                <SelectBox
                  name="role"
                  placeholder="Select account type"
                  parentClassName="flex-1"
                  labelClassName="form_label"
                  value={selectFormData.role}
                  options={roleOptions}
                  isLoading={isRoleLoading}
                  onChangeFunc={handleSelectChange('role')}
                  errorText={
                    !validForm.role ? 'Account type is required' : null
                  }
                  isClearable
                />
              </div>
              {selectFormData?.role?.label === 'Admin' && (
                <div className="mb-4 md:flex">
                  <label className="form_label md:mb-0 form_label_center w-40">
                    Phone number*
                  </label>

                  <PhoneInput
                    onNumberChange={handleNumberChange}
                    name="phone"
                    errors={
                      !validForm.isPhoneValid
                        ? 'Invalid Number'
                        : !validForm.phone
                        ? 'Phone number is required'
                        : null
                    }
                    value={formData.phone}
                    phoneInputParentClassName="flex-1"
                  />
                </div>
              )}
              <div className="border-y mb-4">
                <div className="w-full md:flex py-4">
                  <p className="form_label md:mb-0 w-40">Profile image</p>
                  <div className="position-relative flex-1">
                    <div className="flex md:gap-4 gap-2 md:flex-row flex-col items-center">
                      {displayImage ? (
                        <img
                          className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-full object-cover"
                          src={displayImage}
                          alt={formData.firstName}
                          title={formData.firstName}
                        />
                      ) : formData.image ? (
                        <label className="cursor-pointer">
                          <img
                            className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-full object-cover"
                            src={formData.image}
                            alt={formData.firstName}
                            title={formData.firstName}
                            onError={onError}
                          />
                        </label>
                      ) : formData.firstName ? (
                        <label className="w-14 h-14 rounded-full cursor-pointer text-2xl font-medium bg-primary text-white flex items-center justify-center uppercase">
                          {getShortName(
                            `${formData.firstName} ${formData.lastName}`
                          )}
                        </label>
                      ) : (
                        <label className="w-14 h-14 border border-black/[0.08] rounded-full object-cover flex justify-center items-center bg-utilityGray100 text-gray500">
                          <ImagePlus />
                        </label>
                      )}
                      <div className="relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3.5 px-5 text-center bg-white">
                        <label
                          className="cursor-pointer"
                          htmlFor="fileSelect"
                          {...getRootProps()}
                        >
                          <progress
                            className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden"
                            value="70"
                            max="100"
                          ></progress>
                          <div className="text-grayLight600 text-xs font-normal">
                            <p>
                              <span className="text-[#2422DD] font-semibold pr-1">
                                Click to upload{' '}
                              </span>
                              or drag and drop
                            </p>
                            <p className="text-[11px] leading-[18px]">
                              SVG, PNG or JPG
                            </p>
                          </div>
                        </label>
                        <input
                          type="file"
                          name="image"
                          id="fileSelect11"
                          accept="image/*"
                          className="hidden"
                          {...getInputProps()}
                        />
                      </div>
                    </div>
                    <div className="pb-1">
                      {!validForm.image && (
                        <ErrorMsg errorText={'Image is invalid.'} />
                      )}
                      {!validForm.isImageSizeValid && (
                        <ErrorMsg
                          errorText={
                            'Image size must be less than or equal to 2MB'
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4 md:flex">
                <label className={`form_label md:mb-0 form_label_center w-40`}>
                  Email*
                </label>
                <InputText
                  inputType="email"
                  inputName="email"
                  placeholder="Enter email"
                  parentClassName="flex-1"
                  icon={
                    !validForm.email ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  maxLength={50}
                  value={formData.email}
                  disabled={
                    mode === 'user-update' &&
                    (hasRoleV2('manager') || hasRoleV2('user'))
                  }
                  onChangeFunc={handleInputChange}
                  errorText={
                    !validForm.email
                      ? 'Email is required'
                      : !validForm.isEmailValid
                      ? 'Must be a valid email address'
                      : null
                  }
                />
              </div>
              {mode === 'user-create' && (
                <div className="mb-4 md:flex">
                  <label
                    className={`form_label md:mb-0 form_label_center w-40`}
                  >
                    Select team*
                  </label>
                  <div className="flex-1">
                    <div className="flex sms:flex-row flex-col md:gap-2.5 gap-2 items-start">
                      <SelectBox
                        name="team"
                        placeholder="Select team"
                        parentClassName="md:flex-1 md:w-auto w-full"
                        labelClassName="form_label"
                        value={selectFormData.team}
                        options={teamOptions}
                        isLoading={isTeamListLoading}
                        onChangeFunc={handleSelectChange('team')}
                        errorText={!validForm.team ? 'Team is required' : null}
                        menuPlaceOption={'top'}
                        isClearable
                      />
                      {hasPermissionV2('team_create') &&
                        handleCreateNewTeam && (
                          <>
                            <ButtonCmp
                              type="submit"
                              className="btn-outline-primary sms:w-auto w-full"
                              onClick={() => handleCreateNewTeam(formData)}
                            >
                              Create new team
                            </ButtonCmp>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              )}

              <div className="mb-4 md:flex">
                <label className={`form_label md:mb-0 form_label_center w-40`}>
                  Job role
                </label>
                <InputText
                  inputName="jobRole"
                  placeholder="Enter job role"
                  parentClassName="flex-1"
                  icon={
                    !validForm.jobRole ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  maxLength={50}
                  value={formData.jobRole}
                  onChangeFunc={handleInputChange}
                  errorText={!validForm.jobRole ? 'Job role is required' : null}
                />
              </div>
            </div>
          </div>
        </form>
        {upload && (
          <CropperModal
            modalTitle="Edit team member Profile Image"
            modalDesc="Edit  team member Profile Image as per your requirement"
            imageUpload={imageUpload}
            imageUrl={displayImage}
            setUpload={setUpload}
            defaultImage={team?.image ? team.imageUrl + team.image : ''}
            setCropData={setDisplayImage}
            isCropType={false}
            defaultCropType="Square"
          />
        )}
      </>
    </CommonModal>
  );
};

export default memo(CreateUpdateMemberModal);
