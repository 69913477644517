import React from 'react';
import {
  getFormattedNumber,
  getProgressClass,
} from 'src/utils/CommonFunctions';

import { ICustomerFooterRowProps } from './Target.interface';

const TargetSetFooterRow = ({ data }: ICustomerFooterRowProps) => (
  <>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[13rem] w-[13rem] max-w-[13rem]">
      <span className="h-[32.5px] flex items-center">{data.name}</span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[13rem] w-[13rem] max-w-[13rem]">
      <span>-</span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[150px] w-[12%]">
      <span>
        ${getFormattedNumber(data.completedProfit, false, false, true)}
      </span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[150px] w-[12%]">
      <span>${getFormattedNumber(data.targetProfit, false, false, true)}</span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50  min-w-[200px] w-[12%]">
      <progress
        className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 ${getProgressClass(
          data.completedProfitPercentage
        )}`}
        value={data.completedProfitPercentage}
        max="100"
      ></progress>
      <span className="text-textSecondary font-medium">
        {data.completedProfitPercentage}%
      </span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50  min-w-[100px] w-[10%]">
      <span>{data.completedLoads}</span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[100px] w-[10%]">
      <span>{data.targetLoads}</span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[200px] w-[12%]">
      <progress
        className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 ${getProgressClass(
          data.completedLoadsPercentage
        )}`}
        value={data.completedLoadsPercentage}
        max="100"
      ></progress>
      <span className="text-textSecondary font-medium">
        {data.completedLoadsPercentage}%
      </span>
    </td>
  </>
);

export default TargetSetFooterRow;
