import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import {
  getShortName,
  onError,
  useRolePermission,
} from 'src/utils/CommonFunctions';

// import Streaks from '../../assets/img/streaks.svg';

const CustomerRow = ({
  data,
  openCustomerPage,
  selectedCustomersForSalesRepReassign,
  setSelectedCustomersForSalesRepReassign,
}: any) => {
  const { hasRoleV2 } = useRolePermission();
  const newSegmentArray = data?.new_segment
    ?.split(',')
    ?.map((item: string) => item.trim());

  return (
    <>
      <td
        className="px-5 py-4 min-w-44 max-w-44 w-44"
        onClick={() => openCustomerPage(data)}
      >
        <div className="flex items-center gap-3">
          {data.image ? (
            <img
              className="w-8 h-8  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
              src={data.imageUrl + data.image}
              alt=""
              title=""
              onError={onError}
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
              {getShortName(`${data.name}`)}
            </div>
          )}

          {data.name.length > 20 ? (
            <div className="flex items-center gap-1">
              <TooltipCmp message={data.name} parentClassName="">
                <span className="truncate block max-w-32 text-grayLight900 font-semibold leading-5">
                  {data.name}
                </span>
              </TooltipCmp>
              {/* {data?.isStreak ? <img src={Streaks} /> : false} */}
              {/* <img src={getRankDiamond(data?.order_count)} /> */}
            </div>
          ) : (
            <div className="flex items-center gap-1">
              <span className="truncate block max-w-32 text-grayLight900 font-semibold leading-5">
                {data.name}
              </span>
              {/* {data?.isStreak ? <img src={Streaks} /> : false} */}
              {/* <img src={getRankDiamond(data?.order_count)} /> */}
            </div>
          )}
        </div>
      </td>
      <td
        className="px-5 py-3 min-w-44 max-w-44 w-44"
        onClick={() => openCustomerPage(data)}
      >
        <div className="flex gap-1">
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.TEST) && (
            <BadgeCmp
              style="modern"
              badgeDotColor="bg-yellow300"
              mainClassName=""
            >
              {CUSTOMER_SEGMENT_TYPES.TEST}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.TOP) && (
            <BadgeCmp
              style="modern"
              badgeDotColor="bg-[#B2AC17]"
              mainClassName=""
            >
              {CUSTOMER_SEGMENT_TYPES.TOP}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.DORMANT) && (
            <BadgeCmp
              style="modern"
              badgeDotColor="bg-utilityPink600"
              mainClassName=""
            >
              {CUSTOMER_SEGMENT_TYPES.DORMANT}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.PROSPECT) && (
            <BadgeCmp style="modern" type="lightblue" mainClassName="">
              {CUSTOMER_SEGMENT_TYPES.PROSPECT}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.NEW) && (
            <BadgeCmp
              style="modern"
              badgeDotColor="bg-fgSuccessPrimary"
              mainClassName=""
            >
              {CUSTOMER_SEGMENT_TYPES.NEW}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.ACTIVE) && (
            <BadgeCmp style="modern" type="primary" mainClassName="">
              {CUSTOMER_SEGMENT_TYPES.ACTIVE}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.INACTIVE) && (
            <BadgeCmp style="modern" type="error" mainClassName="">
              {CUSTOMER_SEGMENT_TYPES.INACTIVE}
            </BadgeCmp>
          )}
          {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.AT_RISK) && (
            <BadgeCmp style="modern" type="gray" mainClassName="">
              {CUSTOMER_SEGMENT_TYPES.AT_RISK}
            </BadgeCmp>
          )}
        </div>
      </td>
      <td
        className="px-5 py-3 min-w-44 max-w-44 w-44"
        onClick={() => openCustomerPage(data)}
      >
        {data.orderCommissionees?.split(',').length === 1 &&
        data.orderCommissioneeFirstName &&
        data.orderCommissioneeLastName ? (
          <div className="flex items-center min-w-92">
            {data.orderCommissioneeImage ? (
              <img
                className="w-5 h-5 rounded-full border-[0.75px] border-black/[0.08] flex-none"
                src={
                  data.orderCommissioneeImageUrl + data.orderCommissioneeImage
                }
                alt={data.orderCommissioneeFirstName}
                title={data.orderCommissioneeFirstName}
                onError={onError}
              />
            ) : (
              <div className="w-[18px] h-[18px] leading-[18px] bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none">
                <span>
                  {getShortName(
                    `${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`
                  )}
                </span>
              </div>
            )}
            <div className="pl-2">
              <h6 className="text-grayLight900 font-medium leading-tight max-w-32 truncate">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
            </div>
          </div>
        ) : data.orderCommissionees?.split(',').length > 1 ? (
          <div className="text-primary font-semibold leading-tight cursor-pointer">
            {data.orderCommissionees?.split(',').length} Sales Reps.
          </div>
        ) : data.orderCommissionees ? (
          <div className="font-semibold leading-tight cursor-pointer flex gap-1">
            <p className="max-w-52 truncate">{data.orderCommissionees}</p>
            <TooltipCmp
              message={`${data.orderCommissionees} is not an active user on WALHQ`}
            >
              <InfoCircle className="h-4 w-4" />
            </TooltipCmp>
          </div>
        ) : (
          <></>
        )}
      </td>
      <td className="px-5 py-3 min-w-44 max-w-44 w-44">
        <p
          className="hover:text-primary700 font-medium"
          onClick={() => openCustomerPage(data)}
        >
          -
        </p>
      </td>
      <td
        className="px-5 py-3 font-normal min-w-44 max-w-44 w-44"
        onClick={() => openCustomerPage(data)}
      >
        <span>
          {data.last_quoted && moment(data.last_quoted).format('MMM DD, YYYY')}
        </span>
      </td>
      <td
        className="px-5 py-3 font-normal min-w-44 max-w-44 w-44"
        onClick={() => openCustomerPage(data)}
      >
        <span>
          {data.last_ordered &&
            moment(data.last_ordered).format('MMM DD, YYYY')}
        </span>
      </td>
      {hasRoleV2('admin') && (
        <td className="px-5 py-4 min-w-16 max-w-16">
          <CheckBox
            checked={selectedCustomersForSalesRepReassign.some(
              (cusData: any) => cusData.id === data.id
            )}
            onChangeFunc={(e: any) => {
              e.stopPropagation();

              if (e.target.checked) {
                setSelectedCustomersForSalesRepReassign([
                  ...selectedCustomersForSalesRepReassign,
                  data,
                ]);
              } else {
                setSelectedCustomersForSalesRepReassign(
                  selectedCustomersForSalesRepReassign.filter(
                    (cData: any) => cData.id !== data.id
                  )
                );
              }
            }}
            parentClassName=""
          />
        </td>
      )}
    </>
  );
};
export default CustomerRow;
