import { SearchLg, UserPlus01 } from '@untitled-ui/icons-react/build/cjs';
import React, { memo, useEffect, useState } from 'react';

import ButtonCmp from '../../../components/ButtonCmp';
import CommonModal from '../../../components/CommonModal';
import InputText from '../../../components/InputText/InputText';
import { listUser } from '../../../services/TeamMemberService';
import { createTeam, updateTeam } from '../../../services/TeamService';
import { getShortName, onError } from '../../../utils/CommonFunctions';
import WalToast from '../../../utils/WalToast';

interface IProps {
  mode: string;
  handleClose: () => void;
  handleNext: (teamForm?: any, isSaveChanges?: boolean) => void;
  team: any;
  updateTeamFormData: any;
  setIsRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateUpdateTeamMember = ({
  mode,
  handleClose,
  team,
  updateTeamFormData,
  handleNext,
  setIsRefresh,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMemberListSearchValue, setShowMemberListSearchValue] =
    useState('');
  const [isShowMemberList, setIsShowMemberList] = useState(false);
  const [showMemberList, setShowMemberList] = useState([]);
  const [showMemberListOriginal, setShowMemberListOriginal] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const [isSaveChanges, setIsSaveChanges] = useState(false);
  const [isUsersListLoading, setIsUsersListLoading] = useState(true);

  const getShowFilteredMemberList = (
    searchValue: string,
    selectedMembersIdList: number[],
    memberListOriginal: any
  ) => {
    const filteredList = memberListOriginal.filter(
      (originalMemberData: any) => {
        const fullName = `${originalMemberData.firstName} ${originalMemberData.lastName}`;

        const isFullNameMatched = fullName
          .toLowerCase()
          .includes(searchValue.toLowerCase().trim());
        const isFirstNameMatched = originalMemberData.firstName
          .toLowerCase()
          .includes(searchValue.toLowerCase().trim());
        const isLastNameMatched = originalMemberData.lastName
          .toLowerCase()
          .includes(searchValue.toLowerCase().trim());
        const isEmailMatched = originalMemberData.email
          .toLowerCase()
          .includes(searchValue.toLowerCase().trim());
        const isIdMatchedWithSelectedMembers = selectedMembersIdList.includes(
          originalMemberData.id
        );

        return (
          (isFullNameMatched ||
            isFirstNameMatched ||
            isLastNameMatched ||
            isEmailMatched) &&
          !isIdMatchedWithSelectedMembers
        );
      }
    );

    return [...filteredList] as any;
  };

  const loadMembersList = (userType?: string) => {
    let listUserPayload: any = {};

    if (userType) {
      listUserPayload.type = userType;
    }
    setIsUsersListLoading(true);
    listUser(listUserPayload)
      .then((response: any) => {
        // filtering response members based on current popup state
        let responseMembers = [];

        if (
          mode === 'team-add-managers' ||
          mode === 'team-add-managers-update'
        ) {
          responseMembers = response.data
            .filter(
              (resDataMember: any) =>
                resDataMember.role.roleName === 'admin' ||
                resDataMember.role.roleName === 'manager'
            )
            .filter((rec: any) => rec);
        } else if (
          mode === 'team-add-members' ||
          mode === 'team-add-members-update'
        ) {
          responseMembers = response.data;
        }

        setShowMemberListOriginal(responseMembers);
        let selectedMembersIds = [];

        if (mode === 'team-add-managers-update') {
          selectedMembersIds = team.members.map((sm: any) =>
            sm.role.name === 'manager' ? sm.id : false
          );
        } else if (mode === 'team-add-members-update') {
          selectedMembersIds = team.members.map((sm: any) =>
            sm.role.name === 'user' ? sm.id : false
          );
        }
        const filteredList = getShowFilteredMemberList(
          '',
          selectedMembersIds,
          responseMembers
        );
        setShowMemberList(filteredList);
      })
      .catch((e) => {
        WalToast.error(
          e?.response?.data?.message ?? 'Issue fetching available members',
          ''
        );
        console.log(e);
      })
      .finally(() => {
        setIsUsersListLoading(false);
      });
  };

  const handleSubmit = async (isSaveChangesFlag?: boolean) => {
    if (mode === 'team-add-managers' || mode === 'team-add-managers-update') {
      const managerIds = selectedMembers.map((e) => e.id);
      updateTeamFormData.append('managerIds', managerIds.toString());

      if (isSaveChangesFlag === true) {
        setIsSaveChanges(isSaveChangesFlag);

        try {
          setIsLoading(true);
          let toastTitle = '';

          if (mode === 'team-add-managers-update') {
            // pushing image to last
            const imageValueOfFormToAppend = updateTeamFormData.get('icon');

            if (imageValueOfFormToAppend) {
              updateTeamFormData.delete('icon');
              updateTeamFormData.append('icon', imageValueOfFormToAppend);
            }

            await updateTeam(updateTeamFormData, team.id);
            toastTitle = 'team updated Successfully';
          } else {
            // pushing image to last
            const imageValueOfFormToAppend = updateTeamFormData.get('icon');
            updateTeamFormData.delete('icon');
            updateTeamFormData.append('icon', imageValueOfFormToAppend);

            await createTeam(updateTeamFormData);
            toastTitle = `${
              updateTeamFormData.get('name') ?? 'Team'
            } was added to WALHQ`;
          }

          setIsRefresh(true);
          WalToast.success(
            toastTitle,
            'Your changes have been saved and your profile is live. You can now manage team members.'
          );
        } catch (e) {
          console.log(e);
          WalToast.error(
            `Error while creating ${
              updateTeamFormData.get('name') ?? 'team'
            }. Please try again.`
          );
        } finally {
          setIsLoading(false);
          setIsSaveChanges(false);
        }
        handleNext(updateTeamFormData, isSaveChangesFlag);
      } else {
        handleNext(updateTeamFormData, false);
      }
    } else if (mode === 'team-add-members') {
      const userIds = selectedMembers.map((e) => e.id);
      updateTeamFormData.append('userIds', userIds.toString());

      try {
        setIsLoading(true);

        // pushing image to last
        const imageValueOfFormToAppend = updateTeamFormData.get('icon');
        updateTeamFormData.delete('icon');
        updateTeamFormData.append('icon', imageValueOfFormToAppend);

        await createTeam(updateTeamFormData);
        setIsRefresh(true);
        handleNext();
        WalToast.success(
          `${updateTeamFormData.get('name') ?? 'Team'} was added to WALHQ`
        );
      } catch (e) {
        console.log(e);
        WalToast.error(
          `Error while creating ${
            updateTeamFormData.get('name') ?? 'team'
          }. Please try again.`
        );
      } finally {
        setIsLoading(false);
      }
    } else if (mode === 'team-add-members-update') {
      const userIds = selectedMembers.map((e) => e.id);
      updateTeamFormData.append('userIds', userIds.toString());

      try {
        setIsLoading(true);
        setIsLoading(true);

        // pushing image to last
        const imageValueOfFormToAppend = updateTeamFormData.get('icon');

        if (imageValueOfFormToAppend) {
          updateTeamFormData.delete('icon');
          updateTeamFormData.append('icon', imageValueOfFormToAppend);
        }

        await updateTeam(updateTeamFormData, team.id);
        setIsRefresh(true);
        handleNext();
        WalToast.success('Team updated Successfully');
      } catch (e) {
        console.log(e);
        WalToast.error('team not updated');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSecondaryButton = () => {
    if (mode === 'team-add-members' || mode === 'team-add-members-update') {
      handleClose();
    } else {
      handleSubmit(true);
    }
  };

  const handleRemove = (memberData: any) => {
    setSelectedMembers([
      ...selectedMembers.filter((sm) => sm.id !== memberData.id),
    ]);
  };

  const selectMembers = (memberData: any) => {
    setSelectedMembers([...selectedMembers, memberData]);
  };

  useEffect(() => {
    // Load all the members instead of filtered type members
    loadMembersList();

    return () => {
      setShowMemberListSearchValue('');
      setIsShowMemberList(false);
      setShowMemberList([]);
      setShowMemberListOriginal([]);
      setSelectedMembers([]);
    };
  }, [mode]);

  useEffect(() => {
    const selectedMembersIds = selectedMembers.map((sm) => sm.id);
    const filteredList = getShowFilteredMemberList(
      showMemberListSearchValue,
      selectedMembersIds,
      showMemberListOriginal
    );
    setShowMemberList(filteredList);
  }, [selectedMembers, showMemberListSearchValue]);

  useEffect(() => {
    if (mode === 'team-add-managers-update') {
      const selectedManagersRec = team.members.filter((sm: any) =>
        sm.roleInTeam === 'Manager' ? sm : false
      );
      setSelectedMembers([...selectedManagersRec]);
    } else if (mode === 'team-add-members-update') {
      const selectedMembersRec = team.members.filter((sm: any) =>
        sm.roleInTeam === 'User' ? sm : false
      );
      setSelectedMembers([...selectedMembersRec]);
    }
  }, [team, mode]);

  return (
    <CommonModal
      title={`${
        mode === 'team-add-managers' || mode === 'team-add-managers-update'
          ? `Add team managers`
          : `Add team members`
      }`}
      titleDesc={`${
        mode === 'team-add-managers' || mode === 'team-add-managers-update'
          ? `Invite team managers to ${
              team?.name ?? updateTeamFormData.get('name')
            }`
          : `Invite team members to ${
              team?.name ?? updateTeamFormData.get('name')
            }`
      }`}
      handleClose={handleClose}
      headerIcon={<UserPlus01 />}
      secondaryBtnOnClick={handleSecondaryButton}
      size={'max-w-[500px] overflow-unset'}
      primaryBtnText={
        mode === 'team-add-managers' || mode === 'team-add-managers-update'
          ? 'Continue'
          : mode === 'team-add-members'
          ? 'Create'
          : 'Update'
      }
      primaryBtnOnClick={handleSubmit}
      primaryBtnLoading={!isSaveChanges ? isLoading : false}
      secondaryBtnText={
        mode === 'team-add-managers' || mode === 'team-add-managers-update'
          ? 'Save Changes'
          : 'Cancel'
      }
      isOverflow={false}
      secondaryBtnLoading={isSaveChanges ? isLoading : false}
    >
      <div className="w-full p-5">
        {isUsersListLoading ? (
          <div className="mb-4 relative">
            <InputText
              label={'Select a user'}
              inputName="teamSearch"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={showMemberListSearchValue}
              onChangeFunc={(e: any) =>
                setShowMemberListSearchValue(e.target.value)
              }
              onFocus={() => setIsShowMemberList(true)}
              onBlur={() => {
                setTimeout(() => {
                  setIsShowMemberList(false);
                }, 200);
              }}
              isLoading={true}
            />
          </div>
        ) : (
          <div className="mb-4 relative">
            <InputText
              label={'Select a user'}
              inputName="teamSearch"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={showMemberListSearchValue}
              onChangeFunc={(e: any) =>
                setShowMemberListSearchValue(e.target.value)
              }
              onFocus={() => setIsShowMemberList(true)}
              onBlur={() => {
                setTimeout(() => {
                  setIsShowMemberList(false);
                }, 200);
              }}
            />
            {showMemberList?.length > 0 && (
              <div
                className={` mt-3 w-full max-h-[235px] team-user-select overflow-auto py-4 px-2 scrollbar-hide rounded-[10px] shadow-xxl border border-utilityGray200 top-22 bg-white z-10 ${
                  isShowMemberList ? 'absolute' : 'hidden'
                }`}
              >
                {showMemberList.map((memberList: any) => (
                  <div
                    key={`member_select_list_${memberList.id}`}
                    className="w-full rounded-lg cursor-pointer hover:bg-gray50"
                    onClick={() => selectMembers(memberList)}
                  >
                    <div className="px-3.5 py-2.5  justify-start  gap-2 flex items-center">
                      {memberList.image ? (
                        <img
                          className="w-9 h-9 rounded-full border-black/[0.08] border-[0.75px]"
                          src={memberList.imageUrl + memberList.image}
                          alt={memberList.firstName}
                          title={memberList.firstName}
                          onError={onError}
                        />
                      ) : (
                        <div className="w-9 h-9 bg-primary rounded-full text-white flex items-center justify-center uppercase border-black/[0.08] border-[0.75px] flex-none">
                          {getShortName(
                            `${memberList.firstName} ${memberList.lastName}`
                          )}
                        </div>
                      )}
                      <div className="flex-col justify-start items-start flex ">
                        <div className="text-grayLight900 text-xs font-medium leading-tight">{`${memberList.firstName} ${memberList.lastName}`}</div>
                        <div className="text-grayLight600 text-sm font-normal leading-tight break-all">
                          {memberList.email}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="flex-1 overflow-y-auto scrollbar-hide max-h-[60vh]">
          {selectedMembers.map((selectedMember) => (
            <div
              key={`${selectedMember.firstName} ${selectedMember.lastName}`}
              className="w-full py-[9px]  border-t justify-between items-center flex border-utilityGray200 last:border-b"
            >
              <div className="justify-start items-center gap-3 flex">
                <div className="w-9 h-9 relative rounded-full flex-none">
                  {selectedMember.image ? (
                    <img
                      className="w-full h-full rounded-full border-black border-opacity-10"
                      src={selectedMember.imageUrl + selectedMember.image}
                      alt={selectedMember.firstName}
                      title={selectedMember.firstName}
                      onError={onError}
                    />
                  ) : (
                    <div className="w-9 h-9 bg-primary rounded-full text-white flex items-center justify-center uppercase">
                      {getShortName(
                        `${selectedMember.firstName} ${selectedMember.lastName}`
                      )}
                    </div>
                  )}
                </div>

                <div className="flex-col justify-start items-start flex">
                  <div className="justify-center items-center gap-3 flex">
                    <div className="text-textSecondary text-xs font-semibold leading-tight">{`${selectedMember.firstName} ${selectedMember.lastName}`}</div>
                  </div>
                  <div className="text-grayLight600 text-xs font-normal leading-tight">
                    {selectedMember.email}
                  </div>
                </div>
              </div>
              <div className="justify-center items-center gap-1.5 flex">
                <ButtonCmp
                  className="text-danger700 text-sm font-semibold leading-tight cursor-pointer btn-link no-underline !shadow-none"
                  onClick={() => handleRemove(selectedMember)}
                  type="button"
                >
                  Remove
                </ButtonCmp>
              </div>
            </div>
          ))}
        </div>
      </div>
    </CommonModal>
  );
};

export default memo(CreateUpdateTeamMember);
