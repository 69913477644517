import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const createFeedback = async (data: any) => {
  const response: any = await axiosInterceptor.post(API.FEEDBACK.CREATE, data);

  return response;
};

export const listFeedback = async (params: any) => {
  const response: any = await axiosInterceptor.get(API.FEEDBACK.GET, {
    params,
  });

  return response;
};

export const updateFeedbackStatus = async (id: any, data: any) => {
  const response: any = await axiosInterceptor.patch(
    `${API.FEEDBACK.UPDATE_STATUS}/${id}`,
    data
  );

  return response;
};
