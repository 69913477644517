import { Edit05, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import ButtonCmp from 'src/components/ButtonCmp';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import Map from 'src/components/Map/Map';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';

const ConsigneeAddress = ({
  formData,
  setFormData,
  initShipperAddress,
  initValidAddressForm,
  setValidAddressForm,
  consigneeOptions,
  validAddressForm,
  handleSelectChange,
  selectFormData,
  handleActionType,
  saveShipperDetails,
  isConsigneeBtnDisable,
  handleAutocompleteChange,
  handleInputChange,
  consigneeAddress,
  setIsValidAddress,
  isValidAddress,
  status,
}: any) => {
  console.log('validAddressForm', validAddressForm);

  const addItems = () => {
    setFormData((oldData: any) => ({
      ...oldData, // Spread the existing data to avoid losing other fields
      consignee: [...oldData.consignee, initShipperAddress], // Append the new shipper address to the existing array
    }));

    const newValidForm = validAddressForm.consignee;
    newValidForm.push(initValidAddressForm.consignee[0]);
    console.log(
      'validFormNew',
      newValidForm,
      ' ',
      initValidAddressForm.consignee
    );
    setValidAddressForm({
      ...validAddressForm,
      ...{ consignee: newValidForm },
    });

    const updatedValidAddress = [...isValidAddress];
    updatedValidAddress.push({ consigneeAddress: true });
    setIsValidAddress(updatedValidAddress);
  };

  const removeItems = (id: any) => () => {
    const form = formData.consignee.filter(
      (formDataField: any, index: number) => {
        console.log(formDataField);

        return index !== id;
      }
    );
    setFormData((oldData: any) => ({
      ...oldData, // Spread the existing data to avoid losing other fields
      consignee: form, // Append the new shipper address to the existing array
    }));
  };

  return (
    <div className="w-full xl:p-5 sm:p-4 pt-2 flex flex-wrap xl:gap-[40px] gap-6 sm:bg-white rounded-xl sm:border sm:border-utilityGray200 shadow-sm">
      {/* <div className="w-full xl:p-5 sm:p-4 pt-2 flex flex-wrap xl:gap-[51px] gap-6 sm:bg-white rounded-xl sm:border sm:border-utilityGray200 shadow-sm"> */}
      <div className="w-full flex justify-between">
        <div className="lg:w-1/2 w-full p-0">
          <h6 className="text-textSecondary text-sm font-bold">
            Consignee Address
          </h6>
          <p className=" text-grayLight600 text-xs font-normal">
            you can add details here
          </p>
        </div>
        <div className="flex justify-end">
          <ButtonCmp
            type="submit"
            className="btn_primary"
            onClick={addItems}
            icon={<Plus className="text-white w-4 h-4" />}
          >
            Add Items
          </ButtonCmp>
        </div>
      </div>
      {formData.consignee.map((form: any, index: number) => (
        <div
          className="w-full flex flex-col rounded-xl border sm:border-utilityGray200 shadow-sm "
          key={index}
        >
          {/* <>{console.log('form', form)}</> */}
          {formData.consignee.length > 1 && (
            <div className="flex justify-end items-end p-2">
              <div className="delete cursor-pointer p-[5px] w-[3%] bg-danger50 rounded-[4px] flex justify-center items-center text-danger">
                <Trash01 className="w-4 h-4" onClick={removeItems(index)} />
              </div>
            </div>
          )}
          <div
            className={`flex ${
              formData.consignee.length > 1 ? 'px-5 pb-5 ' : 'p-5'
            }`}
          >
            <div className="lg:w-1/2 w-full">
              <div className="mb-5 relative">
                <SelectBox
                  isClearable
                  isSearchable
                  name="consigneeId"
                  className={`form_control ${
                    !validAddressForm.consignee[index].consigneeId
                      ? 'border border-red-500 rounded-[10px] mb-1 border-solid '
                      : ''
                  }`}
                  labelClassName="form_label mb-1.5 block"
                  classComp=""
                  label="Consignee’s"
                  options={consigneeOptions}
                  // value={selectFormData.consigneeId}
                  value={consigneeOptions.filter(
                    (item: any) => item.value === form.consigneeId
                  )}
                  onChangeFunc={handleSelectChange('consigneeId', index)}
                  errorText={
                    !validAddressForm.consignee[index].consigneeId
                      ? 'Consignee is required'
                      : null
                  }
                />
                <div className="absolute right-0 top-0 flex">
                  {selectFormData.consigneeId && (
                    <button
                      onClick={handleActionType('consignee', 'delete', index)}
                      type="button"
                      className="text-red-700 text-xs flex font-bold mr-2"
                    >
                      <TooltipCmp message="Delete Consignee">
                        <Trash01 className="h-4 w-4" />
                      </TooltipCmp>
                    </button>
                  )}
                  <button
                    onClick={saveShipperDetails('consignee', index)}
                    type="button"
                    className={`text-primary700 text-xs flex font-semibold gap-2.5  ${
                      isConsigneeBtnDisable && 'text-opacity-50'
                    }`}
                    disabled={isConsigneeBtnDisable}
                  >
                    {form.consigneeId ? (
                      <Edit05 className="w-4 h-4" />
                    ) : (
                      <Plus className="w-4 h-4" />
                    )}
                    {form.consigneeId ? 'Update' : 'Save'} Consignee Details
                  </button>
                </div>
              </div>
              <div className="mb-5">
                <InputText
                  inputName="consigneeCompanyName"
                  placeholder="Enter company name"
                  className="form_control"
                  label="Company name"
                  labelClassName="mb-1.5 block"
                  value={form.consigneeCompanyName}
                  errorText={
                    !validAddressForm.consignee[index].consigneeCompanyName
                      ? 'Company name is required'
                      : null
                  }
                  onChangeFunc={(e: any) => handleInputChange(e, index)}
                />
              </div>
              <div className="relative">
                {status && (
                  <AutoCompleteGoogle
                    inputName="consigneeAddress"
                    placeholder="Enter address"
                    className={`form_control truncate ${
                      !validAddressForm.consignee[index].fullAddress
                        ? 'border border-red-500 mb-1 border-solid'
                        : ''
                    }`}
                    label="Address"
                    labelClassName="mb-1.5 block"
                    onChangeFunc={handleAutocompleteChange(
                      'consigneeAddress',
                      index
                    )}
                    onBlur={handleAutocompleteChange('consigneeAddress', index)}
                    errorText={
                      !validAddressForm.consignee[index].fullAddress
                        ? 'Address is required'
                        : null
                    }
                    value={
                      form.consigneeAddress.fullAddress !== ''
                        ? form.consigneeAddress.fullAddress
                        : '' || consigneeAddress[index]
                        ? consigneeAddress[index]
                        : ''
                    }
                  />
                )}
                <button
                  onClick={handleActionType(
                    'consignee',
                    form.consigneeAddress.fullAddress
                      ? 'addressUpdate'
                      : 'addressCreate',
                    index
                  )}
                  type="button"
                  className={`text-primary700 text-xs flex font-semibold gap-2.5 absolute left-[60px] top-0`}
                  disabled={false}
                >
                  {form.fullAddress ? 'Change' : 'Select'} Address
                </button>
              </div>
              {!isValidAddress[index].consigneeAddress && (
                <ErrorMsg errorText="Please choose a valid address in the country, city, and postal code." />
              )}
            </div>
            <div className="lg:flex-1 xl:ml-[30px] w-full ">
              {form.consigneeAddress &&
                form.consigneeAddress.fullAddress !== '' && (
                  <Map
                    className="rounded-[20px] bg-white"
                    address={form.consigneeAddress}
                    id={'consigneeMap'}
                    height={200}
                    onReverseAddressFunc={handleAutocompleteChange(
                      'consigneeAddress',
                      index
                    )}
                  />
                )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConsigneeAddress;
