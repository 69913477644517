import { File02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';

import streaks from '../../../assets/img/streaks.svg';

interface IProps {
  handleClose: any;
}

const StreakInfoModal = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Streaks Description'}
    titleDesc={'Update and mange your sales representative'}
    handleClose={() => handleClose(false)}
    headerIcon={<File02 />}
    size={'max-w-[688px]'}
    isOverflow={true}
    modalClassName=""
  >
    <div className="w-full p-5 flex flex-col gap-4">
      <div className="rounded-lg border border-borderPrimary shadow-xs py-4 px-3 text-sm">
        <div className="flex gap-x-2">
          <div className="w-5 h-5 rounded bg-utilityGray100">
            <img src={streaks} />
          </div>
          <h6 className="text-grayLight900 font-semibold">Bronze</h6>
          <p className="text-grayLight600 font-normal">0-4 orders</p>
        </div>
        <p className="text-grayLight900 font-normal mt-3">
          Lorem Ipsumis simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industrys standard dummy text ever
          since the 1500s
        </p>
      </div>
    </div>
  </CommonModal>
);

export default StreakInfoModal;
