import { XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { memo } from 'react';

import ButtonCmp from '../ButtonCmp';
import CommonModal from '../CommonModal';

interface IConfirmModalProps {
  title: string;
  description: string;
  button1: string;
  button2: string;
  handleClose(status: boolean): any;
}

const ConfirmModal = ({
  title,
  description,
  button1,
  button2,
  handleClose,
}: IConfirmModalProps) => (
  <CommonModal handleClose={handleClose(false)}>
    <div className="p-6">
      <div className="cursor-pointer float-right">
        <XClose className="text-gray400" onClick={handleClose(false)} />
      </div>
      <div className="text-center">
        <p className="text-lg font-semibold leading-relaxed text-black mt-4">
          {title}
        </p>
        <p className="text-xs leading-relaxed text-grayText my-3">
          {description}
        </p>
      </div>
      <div className="my-3.5 mt-7">
        <ButtonCmp
          className="btn_primary w-full mt-3 btn-heigh-auto py-2.5"
          onClick={handleClose(true)}
        >
          {button1}
        </ButtonCmp>
        <ButtonCmp
          onClick={handleClose(false)}
          className="btn_secondary_black mt-3 w-full btn-heigh-auto py-2.5"
        >
          {button2}
        </ButtonCmp>
      </div>
    </div>
  </CommonModal>
);

ConfirmModal.defaultProps = {
  show: false,
  title: 'Are you sure?',
  description: '',
  button1: 'Yes',
  button2: 'No',
};

export default memo(ConfirmModal);
