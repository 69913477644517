import { SwitchHorizontal01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

import profile1 from '../../../assets/img/Avatar1.png';
import profile2 from '../../../assets/img/Avatar2.png';
import Icon1 from '../../../assets/img/Icon1.png';
import Icon2 from '../../../assets/img/Icon2.png';
import Icon3 from '../../../assets/img/Icon3.png';
import BadgeCmp from '../../../components/BadgeCmp';
import CommonModal from '../../../components/CommonModal';

interface IProps {
  handleClose(status: boolean): any;
}
const CompareQuote = ({ handleClose }: IProps) => (
  <CommonModal
    headerIcon={<SwitchHorizontal01 />}
    size={'max-w-[954px]'}
    handleClose={handleClose}
    title="Compare Quotes"
    primaryBtnText="Close"
    primaryBtnOnClick={handleClose}
    titleDesc="Drag the map so the pin matches the exact location of your business location."
  >
    <div className="flex flex-col gap-4 p-5">
      <h5 className="text-grayLight900 text-base font-semibold">
        Selected Quotes{' '}
      </h5>
      <div className="w-full gap-4 flex flex-wrap">
        <div className="px-5 py-4 rounded-[10px] border border-utilityGray200 justify-start items-center gap-3 flex">
          <div>
            <img
              src={profile1}
              className="w-7 h-7 relative rounded-full border border-black/[0.08]"
            />
          </div>
          <div className="flex-col flex text-xs leading-tight">
            <div className="text-grayLight900 font-medium ">Olivia Rhye</div>
            <div className="text-grayLight600 font-normal ">#SQ-B-101139</div>
          </div>
        </div>
        <div className="px-5 py-4 rounded-[10px] border border-utilityGray200	 justify-start items-center gap-3 flex">
          <div>
            <img
              src={profile2}
              className="w-7 h-7 relative rounded-full border  border-black/[0.08"
            />
          </div>
          <div className="flex-col flex text-xs leading-tight">
            <div className="text-grayLight900  font-medium ">Phoenix Baker</div>
            <div className="text-grayLight600  font-normal ">#SQ-B-101140</div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
        <table className="w-full text-sm text-left">
          <thead className="text-xs text-grayLight600 font-medium  leading-[18px] bg-gray50 border-b border-utilityGray200">
            <tr>
              <th className="px-5 py-3"></th>
              <th className="px-5 py-3">
                <div className="justify-start items-center gap-3 inline-flex">
                  <div>
                    <img
                      src={Icon1}
                      className="w-8 h-8 relative rounded-full border border-black/[0.08]"
                    />
                  </div>
                  <div className="flex-col justify-start items-start inline-flex text-xs font-medium">
                    <div className="text-grayLight900 leading-tight  ">
                      XPO - FC
                    </div>
                    <div className="text-grayLight600 text-[11px]">
                      #WQ085020145
                    </div>
                  </div>
                </div>
              </th>
              <th className="px-5 py-3">
                <div className="justify-start items-center gap-3 inline-flex">
                  <div>
                    <img
                      src={Icon2}
                      className="w-8 h-8 relative rounded-full border border-black/[0.08]"
                    />
                  </div>
                  <div className="flex-col justify-start items-start inline-flex text-xs font-medium">
                    <div className="text-grayLight900 leading-tight  ">
                      Polaris Transportation
                    </div>
                    <div className="text-grayLight600 text-[11px]">
                      #WQ08502016
                    </div>
                  </div>
                </div>
              </th>
              <th className="px-5 py-3">
                <div className="justify-start items-center gap-3 inline-flex">
                  <div>
                    <img
                      src={Icon3}
                      className="w-8 h-8 relative rounded-full border border-black/[0.08]"
                    />
                  </div>
                  <div className="flex-col justify-start items-start inline-flex text-xs font-medium">
                    <div className="text-grayLight900 leading-tight  ">
                      ABF Freight
                    </div>
                    <div className="text-grayLight600 text-[11px]">
                      #WQ085020145
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-xs">
            <tr className=" text-grayLight600 text-xs font-normal border-b border-utilityGray200">
              <td className="px-5 py-4 font-medium text-grayLight900">
                Total Weight
              </td>
              <td className="px-5 py-4 ">20lbs</td>
              <td className="px-5 py-4 ">16lbs</td>
              <td className="px-5 py-4 ">18lbs</td>
            </tr>
            <tr className="text-grayLight600 text-xs font-normal border-b border-utilityGray200">
              <td className="px-5 py-4 font-medium text-grayLight900">
                Quote Price
              </td>
              <td className="px-5 py-4 ">$ 905.80</td>
              <td className="px-5 py-4 ">$ 360.15</td>
              <td className="px-5 py-4 ">$ 355.47</td>
            </tr>
            <tr className="text-grayLight600 text-xs font-normal border-b border-utilityGray200">
              <td className="px-5 py-4 font-medium text-grayLight900">
                Delivery
              </td>
              <td className="px-5 py-4 ">
                <div className="inline-flex">
                  <BadgeCmp style="modern" type="success">
                    Connected
                  </BadgeCmp>
                </div>
              </td>
              <td className="px-5 py-4 ">
                <div className="inline-flex">
                  <BadgeCmp style="modern" type="warning">
                    Cheapest
                  </BadgeCmp>
                </div>
              </td>
              <td className="px-5 py-4 ">
                <div className="inline-flex">
                  <BadgeCmp style="modern" type="warning">
                    Cheapest
                  </BadgeCmp>
                </div>
              </td>
            </tr>

            <tr className="text-grayLight600 text-xs font-normal border-b border-utilityGray200">
              <td className="px-5 py-4 font-medium text-grayLight900">
                Base Quote
              </td>
              <td className="px-5 py-4 ">$497.92</td>
              <td className="px-5 py-4 ">-</td>
              <td className="px-5 py-4 ">$487.92</td>
            </tr>
            <tr className="text-grayLight600 text-xs font-normal border-b border-utilityGray200">
              <td className="px-5 py-4 font-medium text-grayLight900">
                Additional Services
              </td>
              <td className="px-5 py-4 ">-</td>
              <td className="px-5 py-4 ">-</td>
              <td className="px-5 py-4 ">
                <div className="flex flex-col">
                  <span>Protect from freeze</span>
                  <span className="font-bold">$188.41</span>
                </div>
              </td>
            </tr>
            <tr className="text-grayLight600 text-xs font-normal border-b border-utilityGray200">
              <td className="px-5 py-4 font-medium text-grayLight900 ">
                Total Price
              </td>
              <td className="font-bold px-5 py-4 ">$1403.13</td>
              <td className="font-bold px-5 py-4 ">$360.15</td>
              <td className="font-bold px-5 py-4 ">$1031.51</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </CommonModal>
);

export default CompareQuote;
