import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listFeedback, createFeedback } from 'src/services/FeedbackService';

import { RootState } from '../../redux/store';

export interface ErrorType {
  message: string;
}

export interface FeedBackState {
  error:
    | {
        message: string;
      }
    | undefined
    | null;
  loading: boolean;
  data?: any;
}

const initialState: FeedBackState = {
  error: null,
  loading: false,
  data: null,
};

export const getFeedBack = createAsyncThunk(
  'get/getFeedBack',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await listFeedback(payload);

      if (response.data) {
        return response.data;
      }

      return rejectWithValue({
        message: response.message,
      } as ErrorType);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue({
        message: err.response,
      } as ErrorType);
    }
  }
);

export const createTicketAction = createAsyncThunk(
  'get/createTicket',
  async (payload: any | undefined, { rejectWithValue }) => {
    try {
      const response = await createFeedback(payload);

      if (response.data) {
        return response.data;
      }

      return rejectWithValue({
        message: response.message,
      } as ErrorType);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue({
        message: err.response,
      } as ErrorType);
    }
  }
);

export const feedBackSlice = createSlice({
  name: 'feedBack',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFeedBack.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeedBack.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getFeedBack.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload as ErrorType;
        } else {
          state.error = action.error as ErrorType;
        }
      })
      .addCase(createTicketAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTicketAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createTicketAction.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload as ErrorType;
        } else {
          state.error = action.error as ErrorType;
        }
      });
  },
  reducers: {
    setFeedBackList: (state, action) => {
      state.data = action.payload;
    },
    clearData: (state) => {
      state.data = undefined;
    },
  },
});

export const selectError = (state: RootState) => state.Feedback.error;
export const selectLoading = (state: RootState) => state.Feedback.loading;
export const selectGetFeedback = (state: RootState) => state.Feedback.data;
export default feedBackSlice.reducer;
