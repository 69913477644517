import { yupResolver } from '@hookform/resolvers/yup';
import { CalendarCheck01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import {
  createdLoadLinkPosting,
  getLoadLinkVehicleAttribute,
  getLoadLinkVehicleSize,
  getLoadLinkVehicleType,
} from 'src/services/LoadLinkService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

const radiusOptions = [
  {
    label: '50 Miles',
    value: 50,
  },
  {
    label: '100 Miles',
    value: 100,
  },
  {
    label: '150 Miles',
    value: 150,
  },
  {
    label: '200 Miles',
    value: 200,
  },
];

const schema = yup.object().shape({
  availabilityDate: yup.date().required('Availability date is required.'),
  vehicleSize: yup.string().required('Vehicle size is required.'),
  vehicleType: yup.array().required('Vehicle type is required.'),
  vehicleAttribute: yup.array(),
  sourceRadius: yup.number().required('Source radius is required.'),
  destinationRadius: yup.number().required('Destination radius is required.'),
  comments: yup.string(),
});

interface IProps {
  addressDetails: any;
  setIsRefresh: any;
  loadLinkPostingDetail?: any;
  isUpdateLoadPost: any;
  setIsUpdateLoadPost: any;
}

const CreateLoadPostingFrom = ({
  addressDetails,
  setIsRefresh,
  loadLinkPostingDetail,
  isUpdateLoadPost,
  setIsUpdateLoadPost,
}: IProps) => {
  const [isVehicleTypeLoading, setIsVehicleTypeLoading] = useState(true);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<any[]>([]);

  const [isVehicleSizeLoading, setIsVehicleSizeLoading] = useState(true);
  const [vehicleSizeOptions, setVehicleSizeOptions] = useState<any[]>([]);

  const [isVehicleAttrLoading, setIsVehicleAttrLoading] = useState(true);
  const [vehicleAttrOptions, setVehicleAttrOptions] = useState<any[]>([]);

  const [isPostCreateLoading, setIsPostCreateLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      availabilityDate: loadLinkPostingDetail?.availabilityDate
        ? new Date(loadLinkPostingDetail?.availabilityDate)
        : new Date(),
      vehicleSize: loadLinkPostingDetail?.vehicleSize || '',
      vehicleType: loadLinkPostingDetail?.vehicleType
        ? loadLinkPostingDetail?.vehicleType?.split(',') || []
        : [],
      vehicleAttribute: loadLinkPostingDetail?.vehicleAttribute
        ? loadLinkPostingDetail?.vehicleAttribute?.split(',') || []
        : [],
      destinationRadius: loadLinkPostingDetail?.destinationRadius || 200,
      sourceRadius: loadLinkPostingDetail?.sourceRadius || 200,
      comments: loadLinkPostingDetail?.comments || '',
    },
  });

  const onSubmit = (data: any) => {
    const formDataPayload = {
      quoteId: addressDetails?.id,
      availabilityDate: moment.utc(data?.availabilityDate).format('YYYY-MM-DD'),
      destinationRadius: data?.destinationRadius,
      sourceRadius: data?.sourceRadius,
      vehicleSize: data?.vehicleSize,
      vehicleType: data?.vehicleType?.toString(),
      vehicleAttribute: data?.vehicleAttribute?.toString(),
      comments: data?.comments,
      sourceState: addressDetails?.shipperStateCode,
      sourceCity: addressDetails?.shipperCity,
      destinationState: addressDetails?.consigneeStateCode,
      destinationCity: addressDetails?.consigneeCity,
      postId: null,
    };

    if (
      isUpdateLoadPost &&
      loadLinkPostingDetail &&
      loadLinkPostingDetail?.id
    ) {
      formDataPayload.postId = loadLinkPostingDetail?.id;
    }

    setIsPostCreateLoading(true);
    createdLoadLinkPosting(formDataPayload)
      .then((response: any) => {
        WalToast.success(response?.message);
        setIsUpdateLoadPost(false);
        setIsRefresh(true);
      })
      .finally(() => {
        setIsPostCreateLoading(false);
      })
      .catch((e: any) => {
        WalToast.error('Something went wrong while creating load link post.');
        console.log(e);
      });
  };

  const getVehicleTypeOptions = () => {
    setIsVehicleTypeLoading(true);

    getLoadLinkVehicleType()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleTypeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Vehicle Type Error ', e);
      })
      .finally(() => {
        setIsVehicleTypeLoading(false);
      });
  };

  const getVehicleSizeOptions = () => {
    setIsVehicleSizeLoading(true);

    getLoadLinkVehicleSize()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleSizeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Vehicle Size Error ', e);
      })
      .finally(() => {
        setIsVehicleSizeLoading(false);
      });
  };

  const getVehicleAttributeOptions = () => {
    setIsVehicleAttrLoading(true);

    getLoadLinkVehicleAttribute()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleAttrOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Vehicle Attr Error ', e);
      })
      .finally(() => {
        setIsVehicleAttrLoading(false);
      });
  };

  useEffect(() => {
    getVehicleTypeOptions();
    getVehicleSizeOptions();
    getVehicleAttributeOptions();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-5 py-3">
      <div className="rounded-lg border border-utilityGray200 p-4 ">
        <div className=" -mx-1.5 flex flex-wrap">
          {/* first row */}
          <Controller
            name="availabilityDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateSelect
                inputName="availabilityDate"
                label="Availability date"
                labelClassName="block"
                className={`form_control`}
                parentClassName="sm:px-1.5 mb-3 sm:w-1/4 one-month-datepicker datepicker-w-auto max-sm:mt-4"
                placeholder="End Date"
                dateFormat="dd/MM/yyyy"
                icon={<CalendarCheck01 className="h-5 w-5" />}
                required={true}
                selected={value ? moment(value).toDate() : undefined}
                onChangeFunc={(event: any) => {
                  onChange(event?.value);
                }}
                errorText={
                  errors.availabilityDate
                    ? errors.availabilityDate.message
                    : null
                }
              />
            )}
          />
          <Controller
            name="vehicleSize"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectBox
                name="vehicleSize"
                placeholder="Select Vehicle Size"
                label="Vehicle Size"
                labelClassName="form_label block mb-1.5"
                parentClassName="sm:px-1.5 mb-3 sm:w-1/4"
                id="vehicleSize"
                className="form_control shadow"
                size="sm"
                options={vehicleSizeOptions}
                isClearable={true}
                isLoading={isVehicleSizeLoading}
                onChangeFunc={(event: any) => onChange(event?.value ?? '')}
                value={vehicleSizeOptions.filter(
                  (val: any) => value === val.value
                )}
                required={true}
                errorText={
                  errors.vehicleSize ? errors.vehicleSize.message : null
                }
              />
            )}
          />
          <Controller
            name="vehicleType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectBox
                name="vehicleType"
                placeholder="Select Vehicle Type"
                label="Vehicle Type"
                labelClassName="form_label block mb-1.5"
                parentClassName="sm:px-1.5 mb-3 sm:w-1/4 multi-select-fit-width"
                id="vehicleType"
                className="form_control shadow"
                size="sm"
                options={vehicleTypeOptions}
                isClearable={true}
                isMultipleSelection={true}
                isLoading={isVehicleTypeLoading}
                onChangeFunc={(event: any) => {
                  const newValue = event.map((e: any) => e.value);
                  onChange(newValue ?? []);
                }}
                value={vehicleTypeOptions.filter((item) =>
                  value?.includes(item.value)
                )}
                required={true}
                errorText={
                  errors.vehicleType ? errors.vehicleType.message : null
                }
              />
            )}
          />
          <Controller
            name="vehicleAttribute"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectBox
                name="vehicleAttribute"
                placeholder="Select Vehicle Attribute"
                label="Vehicle Attribute"
                labelClassName="form_label block mb-1.5"
                parentClassName="sm:px-1.5 mb-3 sm:w-1/4"
                id="vehicleAttribute"
                className="form_control shadow"
                size="sm"
                isLoading={isVehicleAttrLoading}
                options={vehicleAttrOptions}
                isClearable={true}
                isMultipleSelection={true}
                onChangeFunc={(event: any) => {
                  const newValue = event.map((e: any) => e.value);
                  onChange(newValue ?? []);
                }}
                value={vehicleAttrOptions.filter((item) =>
                  value?.includes(item.value)
                )}
                errorText={
                  errors.vehicleAttribute
                    ? errors.vehicleAttribute.message
                    : null
                }
              />
            )}
          />

          {/* second row */}
          <div className="w-full flex">
            <InputText
              inputName="sourceState"
              label="Source State"
              labelClassName="mb-1.5 block"
              parentClassName="sm:px-1.5 mb-3 w-1/4"
              className="form_control"
              value={addressDetails?.shipperStateCode}
              disabled={true}
              required={true}
            />
            <InputText
              inputName="sourceCity"
              label="Source City"
              labelClassName="mb-1.5 block"
              parentClassName="sm:px-1.5 mb-3 w-1/4"
              className="form_control"
              value={addressDetails?.shipperCity}
              disabled={true}
              required={true}
            />
            <Controller
              name="sourceRadius"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="sourceRadius"
                  placeholder="Select Source Radius"
                  label="Source Radius"
                  labelClassName="form_label block mb-1.5"
                  parentClassName="sm:px-1.5 mb-3 w-1/4"
                  id="sourceRadius"
                  className="form_control shadow"
                  size="sm"
                  options={radiusOptions}
                  onChangeFunc={(event: any) => onChange(event?.value ?? '')}
                  value={radiusOptions.filter(
                    (val: any) => value === val.value
                  )}
                  required={true}
                  errorText={
                    errors.sourceRadius ? errors.sourceRadius.message : null
                  }
                />
              )}
            />
          </div>

          {/* third row */}
          <div className="w-full flex">
            <InputText
              inputName="destinationState"
              label="Destination State"
              labelClassName="mb-1.5 block"
              parentClassName="sm:px-1.5 mb-3 w-1/4"
              className="form_control"
              value={addressDetails?.consigneeStateCode}
              disabled={true}
              required={true}
            />
            <InputText
              inputName="destinationCity"
              label="Destination City"
              labelClassName="mb-1.5 block"
              parentClassName="sm:px-1.5 mb-3 w-1/4"
              className="form_control"
              value={addressDetails?.consigneeCity}
              disabled={true}
              required={true}
            />
            <Controller
              name="destinationRadius"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="destinationRadius"
                  placeholder="Select Destination Radius"
                  label="Destination Radius"
                  labelClassName="form_label block mb-1.5"
                  parentClassName="sm:px-1.5 mb-3 w-1/4"
                  id="destinationRadius"
                  className="form_control shadow"
                  size="sm"
                  options={radiusOptions}
                  onChangeFunc={(event: any) => onChange(event?.value ?? '')}
                  value={radiusOptions.filter(
                    (val: any) => value === val.value
                  )}
                  required={true}
                  errorText={
                    errors.destinationRadius
                      ? errors.destinationRadius.message
                      : null
                  }
                />
              )}
            />
          </div>
        </div>
        <Controller
          name="comments"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="comments"
              label="Comment"
              labelClassName="mb-1.5 block"
              asTextarea="textarea"
              parentClassName="w-full"
              className="h-[76px]"
              value={value}
              onChangeFunc={(e) => onChange(e?.target?.value)}
              errorText={errors.comments ? errors.comments.message : null}
            />
          )}
        />
        <ButtonCmp
          className="btn-outline-primary  mt-4"
          loading={isPostCreateLoading}
          disabled={isPostCreateLoading}
        >
          Post to Load Board
        </ButtonCmp>
      </div>
    </form>
  );
};

export default CreateLoadPostingFrom;
