import { getTimeDifference } from 'src/utils/CommonFunctions';

export const getNotFoundContent = (
  isLoading: any,
  data: any,
  search: any,
  isClaims: boolean
) => {
  const typeInCaps = isClaims ? 'Claims' : 'Disputes';
  const typeInSmall = isClaims ? 'claims' : 'disputes';

  if (!isLoading && data?.length <= 0 && search) {
    return {
      title: `No ${typeInCaps} found`,
      desc: `No ${typeInSmall} were found in the system based on the given search.`,
    };
  }

  if (!isLoading && data?.length <= 0) {
    return {
      title: `No ${typeInCaps} found`,
      desc: `There are no ${typeInSmall} found`,
    };
  }

  return null;
};

export const CLAIM_DISPUTE_STATUS = {
  RESOLVED: 'Resolved',
  NEW: 'New',
  OPENED: 'Opened',
  CLOSED: 'Closed',
};

type BadgeType =
  | 'success'
  | 'error'
  | 'warning'
  | 'primary'
  | 'info'
  | 'gray'
  | 'red'
  | 'lightblue'
  | 'yellow'
  | 'orange'
  | 'pink';

export const getBadgeType = (
  createdAtDate: any
): { type: BadgeType; clockType: string } => {
  const { differenceInHours }: any = getTimeDifference(createdAtDate);

  if (differenceInHours < 72) {
    return { type: 'success', clockType: 'text-success500' };
  } else if (differenceInHours >= 72 && differenceInHours < 168) {
    return { type: 'warning', clockType: 'text-orange500' };
  } else {
    return { type: 'red', clockType: 'text-red500' };
  }
};

export const FilesTab: any = [
  {
    value: 'all',
    name: 'View all',
  },
  {
    value: 'yourfiles',
    name: 'Your files',
  },
  {
    value: 'sharedfiles',
    name: 'Shared files',
  },
];

export const handleInputChange = (e: any, type: string, onChange: any) => {
  const { target } = e;
  let { value: currentValue } = target;

  let newValue = currentValue;

  if (type === 'blur' && !currentValue) {
    newValue = 0;
  } else {
    newValue = currentValue;

    if (!newValue.includes('.')) {
      newValue = Number(newValue);
    }
  }

  if (type === 'blur') {
    newValue = parseFloat(newValue).toFixed(2);
  }

  onChange(newValue);
};
