import React from 'react';
import Confirmation from 'src/components/Confirmation';

import FloraFauna from '../../assets/img/FloraFauna.png';
import LogoDark from '../../assets/img/logo-dark.png';

const Thankyou = () => (
  <div className="overflow-y-auto h-full p-5 bg-utilityGray50 flex flex-col">
    <div className="max-w-[1200px] rounded-xl bg-white shadow-lgc xl:my-[30px] my-5 mx-auto flex flex-col flex-1 overflow-hidden w-full">
      <div className="border-b border-borderPrimary p-5 flex flex-wrap gap-3 justify-end">
        <div className="flex items-center">
          <div className="max-w-[113px] ">
            <img src={LogoDark} alt="logo-light" className="w-full" />
          </div>
          <div className="max-w-[128px] min-w-[50px] pl-3 ml-3 border-l border-borderPrimary ">
            <img src={FloraFauna} className="w-full object-cover" />
          </div>
        </div>
      </div>
      <div className="bg-gray50 h-full">
        <Confirmation
          title="Thank You!"
          desc="Thank you for submitting the form. Our team will be in contact shortly."
          containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
        />
      </div>
    </div>
  </div>
);

export default Thankyou;
