import { Plus } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState } from 'react';
import InputText from 'src/components/InputText/InputText';
import { createClaimDisputeTask } from 'src/services/ClaimAndDisputeService';

interface IProps {
  addTaskToList: any;
  claimID?: any;
  disputeID?: any;
}

const DetailsActionFooter = ({ addTaskToList, claimID, disputeID }: IProps) => {
  const [taskName, setTaskName] = useState('');

  const handleAddTask = () => {
    if (taskName.trim() !== '') {
      createClaimDisputeTask({
        taskName: taskName,
        claimID: claimID,
        disputeID: disputeID,
        dueDate: moment().format('YYYY-MM-DD'),
      })
        .then((response: any) => {
          if (response && response.actionItem) {
            addTaskToList(response.actionItem);
            setTaskName('');
          } else {
            console.log('error');
          }
        })
        .catch(console.error);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTask();
    }
  };

  return (
    <>
      <td className="px-5 py-4 min-w-64 w-64 max-w-64 ">
        <div className="flex items-center gap-3">
          <InputText
            inputName="taskName"
            placeholder="Add task name"
            value={taskName}
            onChangeFunc={(e) => {
              setTaskName(e.target.value);
            }}
            onKeyPress={handleKeyDown}
            iconLeft={
              <Plus className="h-4 w-4 cursor-pointer absolute left-0 mr-1.5 text-black/90" />
            }
            className="!shadow-none border-0 placeholder:text-gray500 disabled:text-gray500 text-black/90 disabled:bg-gray50 pl-5"
            parentClassName="w-32"
          />
        </div>
      </td>
      {/* <td className="px-5 py-4 max-w-64 min-w-[20%] w-[20%]">
        <div className="flex items-center gap-3">
          <InputText
            inputName="taskName"
            placeholder="Assign User"
            value={''}
            iconLeft={
              <UserPlus01 className="h-4 w-4 cursor-pointer absolute left-0 mr-1.5 text-black/90" />
            }
            className="!shadow-none border-0 placeholder:text-gray500 disabled:text-gray500 text-black/90 disabled:bg-gray50 pl-5"
            parentClassName="w-32"
          />
        </div>
      </td>
      <td className="px-5 py-4 " colSpan={3}>
        <div className="flex items-center gap-3">
          <InputText
            inputName="taskName"
            placeholder="Add"
            value={''}
            iconLeft={
              <Plus className="h-4 w-4 cursor-pointer absolute left-0 mr-1.5 text-black/90" />
            }
            className="!shadow-none border-0 placeholder:text-gray500 disabled:text-gray500 text-black/90 disabled:bg-gray50 pl-5"
            parentClassName="w-32"
          />
        </div>
      </td> */}
    </>
  );
};

export default DetailsActionFooter;
