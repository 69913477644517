import React from 'react';

const MessageListCloseLoading = ({ isLoadMore }: any) => (
  <>
    {Array.from({ length: isLoadMore ? 2 : 9 }).map((_, index) => (
      <div className=" border-b border-utilityGray200" key={index}>
        <div className="p-4">
          <div className="w-9 h-9 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none relative custom-loading">
            WA
          </div>
        </div>
      </div>
    ))}
  </>
);

export default MessageListCloseLoading;
