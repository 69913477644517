import React from 'react';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

interface IProps {
  data: any;
}

const CustomerListTableFooterRow = ({ data }: IProps) => (
  <>
    <td className="flex flex-col px-5 py-4 first:min-w-80 text-pretty font-semibold text-sm bg-gray50">
      <div>Total</div>
    </td>

    <td className="px-5 py-4 w-64 min-w-64 max-w-64 font-semibold text-sm bg-gray50">
      <div className="flex items-center min-w-92">&nbsp;</div>
    </td>

    <td className="px-5 py-4 font-semibold text-sm bg-gray50">
      <div>${getFormattedNumber(parseFloat(data?.sales).toFixed(2))}</div>
    </td>

    <td className="px-5 py-4 font-semibold text-sm bg-gray50">
      <div>
        {getFormattedNumber(parseFloat(data.orderCount).toFixed(2), false)}
      </div>
    </td>

    <td className="px-5 py-4 font-semibold text-sm bg-gray50">
      <div>${getFormattedNumber(parseFloat(data?.profit).toFixed(2))}</div>
    </td>

    <td className="px-5 py-4 font-semibold text-sm bg-gray50">
      <div>
        {getFormattedNumber(
          parseFloat(
            data?.customersCount
              ? data?.profitPercentage / data?.customersCount
              : data?.profitPercentage
          ).toFixed(2)
        )}
        %
      </div>
    </td>

    <td
      className={`px-5 py-4 font-semibold text-sm bg-gray50 ${
        data.change < 0 ? 'text-fgErrorPrimary' : 'text-fgSuccessPrimary'
      }`}
    >
      <div>
        {getFormattedNumber(
          (data.change >= 100 ? 100 : parseFloat(data.change)).toFixed(2)
        )}
        %
      </div>
    </td>
  </>
);

export default CustomerListTableFooterRow;
