import React from 'react';
import { getFormattedDate, getShortName } from 'src/utils/CommonFunctions';

interface IProps {
  internalChat: any;
  currentUser: any;
  getFormattedDateTime: any;
}

const InternalChatCard = ({
  internalChat,
  currentUser,
  getFormattedDateTime,
}: IProps) => (
  <>
    {internalChat.special ? (
      <li className="flex items-center my-4" key={internalChat.id}>
        <span className="h-[1px] bg-utilityGray200 w-full"></span>
        <p className="text-grayLight600 text-xs text-center px-2 text-nowrap">
          {getFormattedDate(
            internalChat.createdAt,
            'MMMM Do, YYYY',
            false,
            true
          )}
        </p>
        <span className="h-[1px] bg-utilityGray200 w-full"></span>
      </li>
    ) : internalChat.sender.id === currentUser.currentUser.id ? (
      <li className="flex justify-end mt-4 gap-3 " key={internalChat.id}>
        <div className="xl:max-w-[305px] max-w-[230px] ">
          <div className="flex justify-between gap-2 items-center w-full mb-1.5">
            <p className="text-textSecondary text-xs">You</p>
            <p className="text-grayLight600 font-normal text-xs">
              {getFormattedDateTime(internalChat.createdAt, true)}
            </p>
          </div>

          <div className="rounded-lg rounded-tr-none border border-utilityGray200 xl:p-2.5 p-2 font-normal text-sm bg-primary text-white">
            {internalChat.content}
          </div>
        </div>
      </li>
    ) : (
      <li className="flex mt-4 gap-3 max-w-[305px]">
        <div className="w-8 h-8 flex-none relative">
          {internalChat.sender.imageUrl ? (
            <img
              src={internalChat.sender.imageUrl + internalChat.sender.image}
              className="w-8 h-8 rounded-full uppercase border-gray-200 border-[1px] bg-utilityGray100 text-primary"
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none  mr-2 text-xs">
              {getShortName(
                `${internalChat.sender?.firstName} ${internalChat.sender?.lastName}`
              )}
            </div>
          )}
          <span
            className={`border-[1.5px] border-white rounded-full ${
              internalChat?.sender?.user_status === 'online'
                ? 'bg-successSecondary'
                : 'bg-danger500'
            }  w-2.5 h-2.5 absolute bottom-0 right-0 user-status-${
              internalChat?.sender?.id
            }`}
            title={internalChat?.sender?.user_status}
          ></span>
        </div>
        <div>
          <div className="flex justify-between gap-2 items-center w-full mb-1.5">
            <p className="text-textSecondary text-xs">
              {internalChat.sender.firstName} {internalChat.sender.lastName}
            </p>
            <p className="text-grayLight600 font-normal text-xs">
              {getFormattedDateTime(internalChat.createdAt, true)}
            </p>
          </div>

          <div className="rounded-lg rounded-tl-none border border-utilityGray200 p-2.5 bg-white text-grayLight900 font-normal text-sm mb-2">
            {internalChat.content}
          </div>
        </div>
      </li>
    )}
  </>
);

export default InternalChatCard;
