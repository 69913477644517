import { Plus, Trash03 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Controller } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';

const OfficersItem = ({
  errors,
  control,
  officersArr,
  addRaw,
  removeRaw,
}: any) => (
  <div className="rounded-lg border border-utilityGray200 shadow-xs p-4 flex flex-col gap-y-4">
    {officersArr.map((data: any, index: number) => (
      <div className="flex w-full gap-2" key={index}>
        <div className="flex-1 flex flex-wrap max-sm:flex-col max-md:gap-y-3 sm:-mx-1.5">
          <Controller
            name={`officers.${index}.officer_name`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Name"
                inputName={`officers.${index}.officer_name`}
                placeholder="Enter Name"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                onChangeFunc={onChange}
                required={true}
                errorText={
                  errors.officers?.[index]?.officer_name?.message ?? null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
          <Controller
            name={`officers.${index}.title`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Title"
                inputName={`officers.${index}.title`}
                placeholder="Enter Title"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                required={true}
                value={value}
                onChangeFunc={onChange}
                errorText={errors?.officers?.[index]?.title?.message ?? null}
                labelClassName="block mb-1.5"
              />
            )}
          />
          <Controller
            name={`officers.${index}.home_address`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Home Address"
                inputName={`officers.${index}.home_address`}
                placeholder="Enter home address"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                required={true}
                value={value}
                onChangeFunc={onChange}
                errorText={
                  errors?.officers?.[index]?.home_address?.message ?? null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
          <Controller
            name={`officers.${index}.city`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="City"
                inputName={`officers.${index}.city`}
                placeholder="Enter City"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                required={true}
                value={value}
                onChangeFunc={onChange}
                errorText={errors?.officers?.[index]?.city?.message ?? null}
                labelClassName="block mb-1.5"
              />
            )}
          />
        </div>
        <div className="flex justify-center items-end gap-2 h-[58px]">
          <ButtonCmp
            className="btn_primary cursor-pointer !bg-primary100 border-primary100  text-primary rounded lg:px-[9px] px-2"
            onClick={() => addRaw('officers')}
          >
            <Plus className="w-4 h-4" />
          </ButtonCmp>
          {index > 0 ? (
            <ButtonCmp
              className="btn_primary cursor-pointer !bg-primary100 border-primary100  text-primary rounded lg:px-[9px] px-2"
              onClick={() => removeRaw(index, 'officers')}
            >
              <Trash03 className="w-4 h-4" />
            </ButtonCmp>
          ) : (
            <span className="w-9 h-9">&nbsp;</span>
          )}
        </div>
      </div>
    ))}
  </div>
);

export default OfficersItem;
