import React from 'react';

const CommissionReportLoadingRow = () => (
  <>
    <td className="px-5 py-4 ">
      <h6 className="h-8 text-grayLight900 font-medium flex items-center custom-loading">
        September 2024
      </h6>
    </td>

    <td className="px-5 py-4 ">
      <span className="custom-loading">Feb 29th - 2024, 07:00 PM</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">Feb 28th - 2024, 07:00 PM</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">100</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">$270.96</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$270.96</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">1778</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">17.89%</span>
    </td>
  </>
);

export default CommissionReportLoadingRow;
