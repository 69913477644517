import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const SalesRepComissionReportLoadingRow = ({ data }: any) => {
  console.log('commission report row data');

  return (
    <>
      <td className="px-5 py-4 ">
        <h6 className=" h-8 text-primary underline flex items-center font-medium hover:text-primary700 cursor-pointer custom-loading">
          WAL-TCS4-231
        </h6>
      </td>
      <td className="px-5 py-4 ">
        <div className="flex gap-1 flex-wrap custom-loading">
          <BadgeCmp style="modern" type="success">
            New
          </BadgeCmp>
        </div>
      </td>
      <td className="px-5 py-4 ">
        <span className="custom-loading">{data.orderCreatedAt}</span>
      </td>
      <td className="px-5 py-4 ">
        <span className="custom-loading">{data.combinedCost}</span>
      </td>
      <td className="px-5 py-4 ">
        <span className="custom-loading">{data.combinedRevenue}</span>
      </td>
      <td className="px-5 py-4 ">
        <span className="custom-loading">{data.combinedMargin}</span>
      </td>
      <td className="px-5 py-4 ">
        <span className="font-semibold custom-loading">$901.06 CAD</span>
      </td>
    </>
  );
};

export default SalesRepComissionReportLoadingRow;
